import React from 'react';
import { FormGroup } from 'reactstrap';

export default function CustomDisabledText({
                                               className,
                                               labelClassName,
                                               label,
                                               value,
                                               style,
                                               newLabel,
                                               onClick,
                                               errorMessage,
                                           }) {
    return (
        <FormGroup style={style} className={newLabel && 'd-flex align-items-center'}>
            {label && !newLabel && (
                <label className={`form-control-label ${labelClassName}`}>
                    {label}
                </label>
            )}
            {newLabel && <label className="form-control-label mr-1 w-50">{newLabel}</label>}
            <div onClick={onClick} className={'w-100'}>
                <div className={`form-control ${className}`} style={{ whiteSpace: 'nowrap', backgroundColor: '#f8f9fa', border: '1px solid #ced4da', padding: '10px', borderRadius: '4px' }}>
                    {value}
                </div>
                <div className="invalid-feedback">{errorMessage}</div>
            </div>
        </FormGroup>
    );
}
