import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import actions from 'actions/index.js';
import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import InputCustom from 'views/pages/components/InputCustom';
import InputSideCustom from 'views/pages/components/InputSideCustom';
import {
  InputKeySideSelect,
  InputArraySideSelect,
} from 'views/pages/components/InputSelect';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { ButtonContainer } from "@pages/reusable-components/styled-components";
import { validateForm } from "constants/index.js";

export default function LeadCreate() {
  const notificationAlert = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isNotValid, setIsNotValid] = useState(true);
  const [token, setToken] = useState('');
  const [lead, setLead] = useState([]);
  const [dealerMembers, setDealerMembers] = useState([]);
  const [leadSources, setLeadSources] = useState([]);
  const [leadTypes, setLeadTypes] = useState('');
  const [makes, setMakes] = useState([]);
  const [makesAndModels, setMakesAndModels] = useState('');
  const [models, setModels] = useState([]);
  const [errors, setErrors] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/leads/create`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data

        setLead(data.data.item)
        setDealerMembers(data.data.dealerMembers)
        setLeadSources(data.data.leadSources)
        setLeadTypes(data.data.leadTypes)
        setMakes(data.data.makes)
        setMakesAndModels(data.data.makesAndModels)
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const goList = () => {
    history.push({
      pathname: '/admin/leads'
    })
  }
  useEffect(() => {
    const required = ['assigned_to', 'email', 'lead_make', 'lead_source_id', 'lead_type', 'name', 'phone', 'vehicle_model_id', 'lead_source_id']
    setIsNotValid(!validateForm(required, lead));
  }, [lead])

  const addLead = () => {
    axios({
      method: 'POST',
      url: constants.API_URL + '/leads',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: lead,
    })
      .then(response => {
        setErrors('')
        console.log('res :: ', response.data)
        const data = response.data;
        notify('success', 'Success', data.message)
        dispatch(actions.getNotifications(token));
        const uuid = data.data.data.uuid
        setTimeout(() => {
          history.push({
            pathname: `/admin/leads/${uuid}/edit`
          })
        }, 1500);
      })
      .catch(error => {
        setErrors('')
        console.error('error :: ', error)
        if (error.response && error.response.status === 422) {
          setErrors(error.response.data.errors)
          return
        }
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      })
  }

  return (
    <>
      <CardsHeader name="Leads" parentName="Leads Management" currentName="Create" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h2 className="mb-0">Leads Management</h2>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <h3 className="main-color">{`Create a new lead`}</h3>
                <Row className="mt-3 px-3 mt-4">
                  <Col md={6} sm={12} className="">
                    <InputSideCustom
                      required
                      checkValid={false}
                      label={`Name`}
                      value={lead && lead.name ? lead.name : ''}
                      onChange={(e) => setLead({
                        ...lead,
                        name: e.target.value,
                      })}
                      invalid={errors && errors.name ? true : false}
                      errorMessage={errors.name}
                    />
                  </Col>
                  <Col md={6} sm={12} className="">
                    <InputSideCustom
                      label={`Mobile No`}
                      checkValid={false}
                      required
                      value={lead && lead.phone ? lead.phone : ''}
                      onChange={(e) => setLead({
                        ...lead,
                        phone: e.target.value,
                      })}
                      invalid={errors && errors.phone ? true : false}
                      errorMessage={errors.phone}
                    />
                  </Col>
                  <Col md={6} sm={12} className="">
                    <InputSideCustom
                      label={`Email`}
                      required
                      checkEmail
                      checkValid={false}
                      value={lead && lead.email ? lead.email : ''}
                      onChange={(e) => setLead({
                        ...lead,
                        email: e.target.value,
                      })}
                      invalid={errors && errors.email ? true : false}
                      errorMessage={errors.email}
                    />
                  </Col>
                  <Col md={6} sm={12} className="">
                    <InputArraySideSelect
                      label={`Makes`}
                      required
                      options={makes}
                      value={lead && lead.lead_make ? lead.lead_make : ''}
                      onChange={(e) => {
                        setModels(makesAndModels[e.target.value])
                        setLead({
                          ...lead,
                          lead_make: e.target.value,
                        })
                      }}
                      valueKey={`id`}
                      labelKey={`name`}
                      defaultOption={true}
                      invalid={errors && errors.lead_make ? true : false}
                      errorMessage={errors.lead_make}
                    />
                  </Col>
                  <Col md={6} sm={12} className="">
                    <InputArraySideSelect
                      label={`Model`}
                      options={models}
                      required
                      value={lead && lead.vehicle_model_id ? lead.vehicle_model_id : ''}
                      onChange={(e) => setLead({
                        ...lead,
                        vehicle_model_id: e.target.value,
                      })}
                      valueKey={`id`}
                      labelKey={`name`}
                      defaultOption={true}
                      invalid={errors && errors.vehicle_model_id ? true : false}
                      errorMessage={errors.vehicle_model_id}
                    />
                  </Col>
                  <Col md={6} sm={12} className="">
                    <InputSideCustom
                      label={`Derivative`}
                      value={lead && lead.derivative ? lead.derivative : ''}
                      onChange={(e) => setLead({
                        ...lead,
                        derivative: e.target.value,
                      })}
                      invalid={errors && errors.derivative ? true : false}
                      errorMessage={errors.derivative}
                    />
                  </Col>
                  <Col md={6} sm={12} className="">
                    <InputArraySideSelect
                      label={`Source`}
                      options={leadSources}
                      required
                      value={lead && lead.lead_source_id ? lead.lead_source_id : ''}
                      onChange={(e) => setLead({
                        ...lead,
                        lead_source_id: e.target.value,
                      })}
                      valueKey={`id`}
                      labelKey={`name`}
                      defaultOption={true}
                      invalid={errors && errors.lead_source_id ? true : false}
                      errorMessage={errors.lead_source_id}
                    />
                  </Col>
                  <Col md={6} sm={12} className="">
                    <InputKeySideSelect
                      label={`Lead Type`}
                      options={leadTypes}
                      required
                      value={lead && lead.lead_type ? lead.lead_type : ''}
                      onChange={(e) => setLead({
                        ...lead,
                        lead_type: e.target.value,
                      })}
                      defaultOption={true}
                      invalid={errors && errors.lead_type ? true : false}
                      errorMessage={errors.lead_type}
                    />
                  </Col>
                  <Col md={6} sm={12} className="">
                    <InputArraySideSelect
                      label={`Assignee`}
                      required
                      options={dealerMembers}
                      value={lead && lead.assigned_to ? lead.assigned_to : ''}
                      onChange={(e) => setLead({
                        ...lead,
                        assigned_to: e.target.value,
                      })}
                      valueKey={`id`}
                      labelKey={`name`}
                      defaultOption={true}
                      invalid={errors && errors.assigned_to ? true : false}
                      errorMessage={errors.assigned_to}
                    />
                  </Col>
                </Row>
                <Row className="mt-3 px-3">
                  <Col md={6} sm={12} className="mt-4">
                    <InputCustom
                      type={`textarea`}
                      rows={8}
                      newLabel={`Additional vehicle information`}
                      value={lead && lead.additional_information ? lead.additional_information : ''}
                      onChange={(e) => setLead({
                        ...lead,
                        additional_information: e.target.value,
                      })}
                    />
                  </Col>
                </Row>
                <div className="d-flex flex-row justify-content-end mt-5 px-3">
                  <ButtonContainer onClick={() => goList()}>Back to list</ButtonContainer>
                  <ButtonContainer disabled={isNotValid} reverse onClick={() => addLead()}>Save</ButtonContainer>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container >
    </>
  )
}