import React, { useEffect, useRef, useState } from 'react';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from "js-cookie";

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import TableCustom from 'views/pages/components/TableCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";

export default function Defaulters() {
  const notificationAlert = useRef(null);

  const [token, setToken] = useState('');
  const [items, setItems] = useState('');
  const [defaulters, setDefaulters] = useState([]);

  const [loading, setLoading] = useState(true);

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token');
    setToken(grs_token);

    const source = axios.CancelToken.source()

    fetchData(1, source, grs_token)
  }, [])

  const fetchData = async (pageNumber, source, token) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/defaulters`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page: pageNumber
        },
        cancelToken: source.token,
      })
      console.log('response :: ', response.data)
      const data = response.data
      setDefaulters(data.data.data)
      setItems(data.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
      }
    }
  }

  return (
    <>
      <CardsHeader name="Customers" parentName="Customers Defaulters" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <div>
                    <h1 className="title">Defaulters</h1>
                    <p className="text-sm mb-0">Lookup all defaults customers in the system. Find out information about customer payments.</p>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <TableCustom 
                  loading={loading}
                  noOverflow={true}
                  items={defaulters}
                  pagination={items}
                  onChange={(pageNumber) => fetchData(pageNumber, axios.CancelToken.source(), token)}
                  columns={[
                    {
                      dataField: "full_name",
                      text: "Name",
                      sort: true
                    },
                    {
                      dataField: "driver_license_number",
                      text: "Driver license",
                      sort: true
                    },
                    {
                      dataField: "defaulters_count",
                      text: "Defaulted times",
                      sort: true
                    },
                    {
                      dataField: "dealer_name",
                      text: "Offices",
                      sort: true
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}