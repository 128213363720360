import React, {useState} from 'react';

import {
  FormGroup,
  Input,
  Label,
  Col
} from "reactstrap";
import {isEmailValid} from "../../../constants";

export default function InputSideCustom({
  type = 'text',
  className, 
  label,
  placeholder,
  defaultValue,
  value,
  min,
  max,
  rows,
  onChange,
  disabled,
  readOnly,
  invalid,
  required = false,
  checkEmail = false,
  errorMessage,
  setIsNotValid,
  checkValid = true,
}) {
  const [localErrorMessage, setLocalErrorMessage] = useState(null)
  const showIOfValid = (val) => {
    if (checkValid) {
      setIsNotValid(val)
    }
  }
  const validateInput = (e) => {
    onChange(e)
    const val = e.target.value
    if (required) {
      if (!val) {
        setLocalErrorMessage(`${label} field is required`)
        showIOfValid(true)
        return
      } else {
        showIOfValid(false)
        setLocalErrorMessage(null)
      }
    }
    if (checkEmail) {
      if (isEmailValid(val)) {
        setLocalErrorMessage(null)
        showIOfValid(false)
      } else {
        setLocalErrorMessage(`Email is not valid`)
        showIOfValid(true)
      }
    } else {
      setLocalErrorMessage(null)
    }
  }
  return (
    <FormGroup className="row">
      <Label
        className="form-control-label"
        md="3"
      >
        {label}
        {required ? <span className={'required-dot'}>*</span> : ''}
      </Label>
      <Col md="7">
        <Input
          type={type}
          className={className}
          placeholder={placeholder}
          defaultValue={defaultValue}
          value={value}
          min={min}
          max={max}
          rows={rows}
          disabled={disabled}
          readOnly={readOnly}
          onChange={validateInput}
          invalid={localErrorMessage ? !!localErrorMessage : invalid}
        />
        <div className="invalid-feedback">
          {localErrorMessage ? localErrorMessage : errorMessage}
        </div>
      </Col>
    </FormGroup>
  )
}