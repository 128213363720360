import React, {useContext} from 'react';

import {PaymentContext} from '../context';

import {
    Row,
    Col,
    CardHeader,
    CardBody
} from "reactstrap";
import {Line} from "@pages/reusable-components/styled-components";

export default function Tabs() {
    const paymentContext = useContext(PaymentContext);
    const {
        tab, setTab,
        deal
    } = paymentContext;
    return (
        <>
            <CardHeader>
                    <Row md={12} sm={12}>
                        <div className={`navbar-nav`}
                             style={{
                                 margin: 5,
                             }}>
                            <span
                                  className={'cursor-pointer'}
                                  style={{color: tab === "info" && '#ec6409', margin: 5}}
                                  onClick={() => setTab('info')}>
                                  Payments
                            </span>
                            {tab === "info" && <Line color={'#ec6409'}/>}
                        </div>
                        <div className={`navbar-nav cursor-pointer`}
                             style={{
                                 margin: 5,
                             }}>
                             <span
                                style={{color: tab === "test" && '#ec6409', margin: 5}}
                              onClick={() => setTab('test')}>
                              Automatic Settings
                             </span>
                            {tab === "test" && <Line color={'#ec6409'}/>}
                        </div>

                    </Row>
            </CardHeader>
            {deal && deal.deal_status !== 'delivered' &&
            <CardBody>
                <Row>
                    <Col md={12}>
                        <p>Payments will be automatically processed as long as the deal is delivered</p>
                    </Col>
                </Row>
            </CardBody>
            }
        </>
    );
}