import React, { useContext } from 'react';
import { Editor } from '@tinymce/tinymce-react';

import * as constants from 'constants/index.js'

import { DealersContext } from '../context';

import {
  Card,
  CardHeader,
  CardBody,
  Input,
  FormGroup,
} from "reactstrap";
import {ButtonContainer} from "@pages/reusable-components/styled-components";
import EditorComponent from "@pages/components/template";

export default function FrontendTab() {
  const dealersContext = useContext(DealersContext);
  const {
    dealerLanding,
    setDealerLanding,
    updateFront,
      editorState
  } = dealersContext;
    const onEditorStateChange = (content) => {
        setDealerLanding({
            ...dealerLanding,
            description: content
        })
    };

  return (
    <Card>
      <CardHeader>
        <h2 className="title">Frontend Layout Management</h2>
      </CardHeader>
      <CardBody>
        <FormGroup>
          <label
            className="form-control-label"
            htmlFor="example3cols2Input"
          >
            About us description
                          </label>

            <EditorComponent
                editorStateInitial={editorState}
                onEditorStateChange={onEditorStateChange}
            />
        </FormGroup>
        <FormGroup>
          <label
            className="form-control-label"
            htmlFor="example3cols2Input"
          >
            <h2>Testimonials</h2>
            <span>Please write every testimonial link comma separated</span>
          </label>
          <Input
            id="example-text-input"
            type="textarea"
            rows="4"
            value={dealerLanding.testimonials ? dealerLanding.testimonials : ''}
            onChange={(e) => setDealerLanding({
              ...dealerLanding,
              testimonials: e.target.value
            })}
          />
        </FormGroup>
        <div className="d-flex flex-row justify-content-end">
          <ButtonContainer reverse onClick={() => updateFront()}>Save</ButtonContainer>
        </div>
      </CardBody>
    </Card>
  );
}