import React, { useEffect, useState, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import axios from 'axios'
import Cookies from 'js-cookie'
import * as constants from 'constants/index.js'
import TasksList from "./components/TasksList";
import CreateNewTask from "./components/CreateNewTask";
import PdiChecklist from "../../components/PdiChecklist";
import {Notification} from "@pages/components/Notification";
import {
    ReportContainer,
    TaskDetailsContainer,
    Text,
    TextContainer,
    RangeContainer,
    LoaderContainer,
    override,
    ButtonContainer,
} from "../../reusable-components/styled-components";
import { formatDateShortGB, parseToWord} from "constants/index.js";
import { Button, Col, Label, Modal, Row } from "reactstrap";
import { useSelector } from "react-redux";
import VehicleInfo from "@pages/vehicles/VehicleInfo";
import { ClipLoader } from "react-spinners";
import NotificationAlert from "react-notification-alert"
import {hasFleetAdminPermission} from '../../../../utils/dealer';
import notify from "../../../../hooks/notify";

const workerRoles = ['Plylining', 'Valeting', 'Mechanic', 'Body Shop', 'Body-shop']
export default function VehicleDetails() {
    const notificationAlert = useRef(null)

    const location = useLocation();
    const [bodyShop, setBodyShop] = useState(1);

    const [valeting, setValeting] = useState(1);

    const [token, setToken] = useState('');
    const [canWorkOn, setCanWorkOn] = useState(false);
    const [activeDepartment, setActiveDepartment] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showCreateNew, setShowCreateNew] = useState(false);
    const [disableSaving, setDisableSaving] = useState(false);
    const [sendingEmail, setSendingEmail] = useState(false);
    const [showChecklist, setShowChecklist] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [hasDocument, setHasDocument] = useState(false);
    const [pdfModal, setPdfModal] = useState(false)
    const roles = useSelector(state => state.getState).roles;
    const isAdmin = hasFleetAdminPermission(roles);
    const [details, setDetails] = useState({});
    const [filter, setFilter] = useState({})

    const [tasksStatus, setTaskStatus] = useState(null)

    const [departments, setDepartments] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [adminTableData, setAdminTableData] = useState([]);
    const [prices, setPrices] = useState([]);
    const [keys] = useState([
        {
            key: 'title',
            text: 'Task Name',
        },
        {
            key: 'make',
            text: 'Make',
        },
        {
            key: 'model',
            text: 'Model',
        },
        {
            key: 'stock_no',
            text: 'Reg.No.',
        },
        {
            key: 'department',
            text: 'Department',
        },
        {
            key: 'status',
            text: 'Status',
        },
        {
            key: 'price',
            text: 'Cost of job',
            input: true
        },
        {
            key: 'delete',
            text: 'Delete',
        }

    ])
    const [priorities] = useState([
        {
            label: 'High',
            color: 'red',
            icon: 'ep_d-arrow-up-double.png'
        },
        {
            label: 'Medium',
            color: 'green',
            icon: 'fluent_line-equal.png'
        },
        {
            label: 'Low',
            color: 'Blue',
            icon: 'ep_arrow-down.png'
        }
    ]);
    const [statuses] = useState([
        {
            value: 'to_do',
            text: 'To do'
        },
        {
            value: 'in_progress',
            text: 'In progress'
        },
        {
            value: 'in_review',
            text: 'In review'
        },
        {
            value: 'completed',
            text: 'Completed'
        }
    ]);
    const [deleteId, setDeleteId] = useState(null);
    const [checklistData, setChecklistData] = useState([]);
    const [disabled, setDisabled] = useState(true);
    const [id, setId] = useState('');

    const [createTaskData, setCreateTaskData] = useState({});
    const workerKeys = [
        {
            key: 'title',
            label: 'Name'
        },
        {
            key: 'description',
            label: 'Description'
        },
        {
            key: 'documents',
            label: 'Image'
        },
        {
            key: 'status',
            label: 'Complete'
        },
    ]

    useEffect(() => {
        let id = location.search
        let numb = id.match(/\d/g);
        id = numb.join("");
        setId(id)
        if (roles.length) {

            const grs_token = Cookies.get('grs_token')
            setToken(grs_token)
            const source = axios.CancelToken.source()

            const fetchData = async () => {
                await getData(source, grs_token, id)
                if (roles.some(role => workerRoles.includes(role))) {
                    await getStatus(grs_token, source, id)
                }
            }
            fetchData()
            return () => {
                source.cancel()
            }
        }
    }, [roles])


    const getStatus = async (token, source, id) => {
        const checkStatus = await axios({
            method: 'GET',
            url: `${constants.FLEET_MS_API_URL}/fleet-worker/worker/fleet/${id}/activity/status`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            cancelToken: source.token,
        })
        const eventType = checkStatus.data.data.event?.hasOwnProperty('event_type') ? checkStatus.data.data.event.event_type : null;
        setCanWorkOn(checkStatus.data.data.can_work_on);
        setActiveDepartment(checkStatus.data.data.active_department);

        if (eventType !== null) {
            if (eventType === 0) {
                setTaskStatus('start')
            } else if (eventType === 1) {
                setTaskStatus('pause')
            } else if (eventType === 2) {
                setTaskStatus('end')
            }
        }

    }
    const updateTaskStatus = async (status) => {

        const response = await axios({
            method: 'GET',
            url: `${constants.FLEET_MS_API_URL}/fleet-worker/worker/fleet/${id}/activity/${status}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        if (status === 'start') {
            const source = axios.CancelToken.source()
            await getData(source, token, id)
        }
        const eventType = response.data.data.event?.hasOwnProperty('event_type') ? response.data.data.event.event_type : null
        if (eventType !== null) {
            if (eventType === 0) {
                setTaskStatus('start')
            } else if (eventType === 1) {
                setTaskStatus('pause')
            } else if (eventType === 2) {
                setTaskStatus('end')
            }
        }
    }

    const getData = async (source, grs_token, id) => {
        try {
            const response = await axios({
                method: 'GET',
                url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet/${id}`,
                headers: {
                    Authorization: `Bearer ${grs_token}`,
                },
                cancelToken: source.token,
            })
            const data = response.data?.data
            // url: `${constants.FLEET_URL}/fleet-worker/tasks?id=${data.item.id}`,
            setDetails(data.item)
            setChecklistData(data.item.options);
            setHasDocument(data?.hasInspectionDocument)

            await getTasks(source, grs_token, id)
            const departmentRequest = await axios({
                method: 'GET',
                url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet/departments`,
                headers: {
                    Authorization: `Bearer ${grs_token}`,
                },
                cancelToken: source.token,
            })
            let departments = []
            departmentRequest.data.data.departments.forEach((item, index) => {
                departments.push({
                    value: item.name,
                    text: parseToWord(item.name),
                    id: item.id,
                })
            })
            setDepartments(departments)
            setLoading(false)
        } catch (error) {
            Notification('warning', 'Warning', 'Something went wrong please try again later!',notificationAlert);
            if (axios.isCancel(error)) {
                console.log('Request canceled :: ', error)
            } else {
                console.error('error :: ', error)
            }
            setLoading(false)
        }
    }
    const openDeleteModal = (id) => {
        setDeleteId(id)
        setDeleteModal(true)
    }
    const complete = (taskId) => {
        setLoading(true)
       axios({
            method: 'POST',
            url:   `${constants.FLEET_MS_API_URL}/fleet-worker/worker/task/${taskId}/status`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                status: 'completed'
            }

        }).then(response => {
            const source = axios.CancelToken.source()
            getData(source, token, id)
        }).catch(()=>{
           Notification('warning', 'Warning', 'Something went wrong please try again later!',notificationAlert);
           setLoading(false)
        })
    }

    const deleteTask = async () => {
        setLoading(true)
        axios({
            method: 'POST',
            url:   `${constants.FLEET_MS_API_URL}/fleet-management/fleet/task/${deleteId}/archive`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(response => {
            if (response.status === 200) {
                setLoading(false)
            }
            Notification('success', 'Success', 'Deleted',notificationAlert)

            setLoading(false)
        }).catch(()=>{
            Notification('warning', 'Warning', 'Something went wrong please try again later!',notificationAlert);

            setLoading(false)
        })
    }
    const getTasks = async (source, token, id, filter = null) => {
        const fleetUrl = isAdmin ?
            `${constants.FLEET_MS_API_URL}/fleet-management/fleet/task?fleetId=${id}` :
            `${constants.FLEET_MS_API_URL}/fleet-worker/tasks?fleetId=${id}`
        const fleetResponse = await axios({
            method: 'GET',
            url: `${fleetUrl}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: filter,
            cancelToken: source.token,
        });
        let tempTasks = []
        if (fleetResponse.data) {
            tempTasks = fleetResponse.data.data
        }

        if (isAdmin) {
            let tableData = []
            let tempPrices = []
            tempTasks.forEach((item, index) => {
                tempPrices.push({
                    id: item.id,
                    price: item.price,
                    status: item.status,
                    department_id: item.department?.id,
                })
                 tableData.push({
                    title: item.title,
                    id: item.id,
                    status: item.status,
                    make: item.vehicle.make?.name,
                    stock_no: item.vehicle.stock_no,
                    model: item.vehicle.model.name,
                    price: item.price,
                    department: item.department,
                    worker_name: '-'
                })
            });
            setPrices(tempPrices);
            setAdminTableData(tableData);
        }
        setLoading(false);
        setTasks(fleetResponse.data.data);
    }
    const save = () => {
        let id = location.search;
        let numb = id.match(/\d/g);
        id = numb.join("");
        let formData = new FormData();

        formData.append('description', createTaskData.description);
        formData.append('status', createTaskData.status);
        formData.append('department_id', createTaskData.department.id);
        formData.append('title', createTaskData.title);

        for (let i = 0; i < createTaskData.images.length; i++) {
            formData.append('documents[]', createTaskData.images[i]);
        }
        formData.append('priority', createTaskData?.priority?.toLowerCase());
        axios({
            method: 'POST',
            url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet/${id}/task`,
            data: formData,
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then(() => {
            window.location.reload();
            setShowCreateNew(false)
        }).catch(()=>{
            Notification('warning', 'Warning', 'Something went wrong please try again later!',notificationAlert);
        })
    }

    const checkData = (data) => {
        setCreateTaskData(data)
        let disabled = []
        let tempData = []
        Object.keys(data).forEach(item => {
            if (item !== 'images' && item !== 'assignees') {
                tempData.push(data[item])
            }
        });
        disabled = tempData.filter(item => {
            return (Array.isArray(item) && item.length === 0) || item === ''
        });
        setDisabled(!!disabled.length);
    }

    const filterVehicle = async () => {
        const source = await axios.CancelToken.source();
        getTasks(source, token, id, filter);
    };
    const searchByText = async (e) => {
        let tempFilter = filter;
        tempFilter['search'] = e;
        setFilter(tempFilter);
        await filterVehicle(tempFilter);
    }

    const setPriorityFilter = async (e) => {
        let tempFilter = filter;
        tempFilter.priority = e.label;
        setFilter(tempFilter);
        await filterVehicle(tempFilter);
    }
    const pdfAction = () => {
        toggle();
    }
    const setData = (data) => {
        let tempPrices = [...prices];
        let foundIndex = tempPrices.findIndex(x => x.id === data.id && x.price !== data.price);
        if (foundIndex > -1) {
            tempPrices[foundIndex].price = data.price;
            setPrices(tempPrices);
        }
        let tempData = [...adminTableData];
        tempData.map((item, index) => {
            if (item.id === data.id) {
                tempData[index].price = data.price;
            }
        })
        setAdminTableData([...tempData]);
    }
    const generatePdf = async () => {
        setSendingEmail(true);
        axios({
            method: 'GET',
            url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet/download-pdf/${id}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(res => {
            if (res.data) {
                setHasDocument(true);
                Notification('success', 'Success', 'Pdf has been successfully created!',notificationAlert);
            }
            setSendingEmail(false);
        }).catch(()=>{
            setSendingEmail(false);
            Notification('warning', 'Warning', 'Something went wrong please try again later!',notificationAlert);
        })

    };

    const toggle = () => (setPdfModal(!pdfModal));
    const toggleDeleteModal = () => (setDeleteModal(!deleteModal));
    const updateDepartments = (data) => {
        let selectedDepartment = departments.find(x => x.value === data.department)
        let tempPrices = [...prices];
        let foundIndex = tempPrices.findIndex(x => x.id === data.id && x.department_id !== selectedDepartment.id);
        if (foundIndex > -1) {
            tempPrices[foundIndex].department_id = selectedDepartment.id;
            setPrices(tempPrices);
        }
        let tempData = [...adminTableData];

        tempData.forEach((item, index) => {
            if (item.id === data.id) {
                tempData[index].department.name = selectedDepartment.value;
                tempData[index].department.id = selectedDepartment.id;
            }
        })
        setAdminTableData([...tempData]);
    }
    const setStatusData = (data) => {
        let tempPrices = [...prices];
        let foundIndex = tempPrices.findIndex(x => x.id === data.id && x.status !== data.status);
        if (foundIndex > -1) {
            tempPrices[foundIndex].status = data.status;
            setPrices(tempPrices);
        }
        let tempData = [...adminTableData];
        tempData.forEach((item, index) => {
            if (item.id === data.id) {
                tempData[index].status = data.status;
            }
        })
        setAdminTableData([...tempData]);
    }
    const setDepartmentFilter = async (e) => {
        let tempFilter = filter;
        tempFilter.department = e.label;
        setFilter(tempFilter);
        await filterVehicle(tempFilter);
    }
    const saveData = async () => {
        setLoading(true)
        let tempData = {
            work_complexity_body_shop: bodyShop,
            work_complexity_valeting: valeting,
            mileage_in: details.mileage_in,
            pdi_checklist: []
        }
        checklistData.forEach(item => {
            tempData.pdi_checklist.push(...item.options)
        })
        const response = await axios({
            method: 'PUT',
            url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet/${id}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: tempData
        })
        if (response.status === 200) {
            const source = axios.CancelToken.source()
            await getData(source, token, id)
            setShowChecklist(false)
            setLoading(false)
        }
    }
    const setChecklist = (data, index) => {
        let tempData = [...checklistData]
        tempData[index].options = data
        setChecklistData(tempData)
    }
    const saveCosts = () => {
        setDisableSaving(true)
        axios({
            method: 'POST',
            url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet/edit`,
            headers: {
                Authorization: 'Bearer ' + token
            },
            data: prices,
        }).then(res => {
            Notification('success', 'Success', 'Costs Save Successfully!',notificationAlert)
            setDisableSaving(false)
        }).catch(err => {
            setDisableSaving(false)
            Notification('warning', 'Warning', 'Something went wrong please try again later!',notificationAlert)
        })
    }

    return (
        <>
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlert} />
            </div>
            <Modal
                isOpen={pdfModal}
                toggle={toggle}
                className="modal-dialog-centered"
            >
                <div className="modal-header">
                    <h6 className="modal-title main-color">Are you sure?</h6>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => { setPdfModal(false) }}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    Are you sure? You want to generate PDF   {hasDocument ? ' once its generated it can be generated again' : '.'}
                </div>
                <div className="modal-footer justify-content-end">
                    <ButtonContainer
                        onClick={() => { setPdfModal(false) }}
                    >
                        {`Close`}
                    </ButtonContainer>
                    <ButtonContainer
                        reverse
                        onClick={() => {
                            generatePdf()
                            setPdfModal(false)
                        }}
                    >
                        {`Generate`}
                    </ButtonContainer>

                </div>
            </Modal>
            <Modal
                isOpen={deleteModal}
                toggle={toggleDeleteModal}
                className="modal-dialog-centered"
            >
                <div className="modal-header">
                    <h6 className="modal-title main-color">Are you sure?</h6>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => { setDeleteModal(false) }}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    Are you sure? You want to delete task?
                </div>
                <div className="modal-footer justify-content-end">
                    <ButtonContainer
                        onClick={() => { setDeleteModal(false) }}
                    >
                        {`Close`}
                    </ButtonContainer>
                    <ButtonContainer
                        reverse
                        onClick={() => {
                            deleteTask()
                            setDeleteModal(false)
                        }}
                    >
                        {`Delete`}
                    </ButtonContainer>

                </div>
            </Modal>

            {

                loading ? <LoaderContainer>
                    <ClipLoader
                        css={override}
                        size={40}
                        color={`#7B61E4`}
                        loading={loading}
                    />
                </LoaderContainer> : showCreateNew ?
                    <CreateNewTask
                        priorities={priorities}
                        vehicle={details.vehicle}
                        departments={departments}
                        saveData={save}
                        setData={checkData}
                        isDisabled={disabled}
                        setShowCreateNew={setShowCreateNew}
                    />
                    : showChecklist ?
                        <>
                            <ReportContainer>
                                <img width={'219px'} height={'165px'}
                                    src={details.vehicle.image}
                                    alt="" />
                                <TaskDetailsContainer>
                                    <TextContainer>
                                        <Text>No.</Text>
                                        <img height='25px' width='25px'
                                            src={require(`assets/images/taskEdit/road_grey.png`)}
                                            alt="" />
                                        <img height='25px' width='25px'
                                            src={require(`assets/images/taskEdit/date_grey.png`)}
                                            alt="" />
                                    </TextContainer>
                                    <TextContainer>
                                        <Text bold>Reg. Num.:</Text>
                                        <Text bold>Mileage:</Text>
                                        <Text bold>Created Date:</Text>
                                    </TextContainer>
                                    <TextContainer>
                                        <Text>{details.vehicle.stock_no}</Text>
                                        <Text>{details.vehicle.current_mileage}</Text>
                                        <Text>{formatDateShortGB(details.created_at)}</Text>
                                    </TextContainer>

                                </TaskDetailsContainer>
                            </ReportContainer>
                            {
                                details?.vehicle && !loading &&
                                <VehicleInfo mileage={details.vehicle.current_mileage}
                                    vehicle={details.vehicle} />
                            }
                            <Row>

                                {
                                    checklistData.map((option, index) => {
                                        return <Col md={6}>
                                            <PdiChecklist
                                                option={option}
                                                setChecklist={(data) => setChecklist(data, index)}
                                            />
                                        </Col>
                                    })
                                }
                            </Row>

                            <Col className='form-group' sm={12}>
                                <Row>
                                    <Col sm={1}>
                                        <Label
                                            for="bodyShop"
                                        >
                                            Body Shop
                                        </Label>
                                    </Col>
                                    <Col sm={2}>
                                        <input
                                            id="bodyShop"
                                            min={1}
                                            max={10}
                                            step={1}
                                            name="bodyShop"
                                            value={bodyShop}
                                            onChange={event => setBodyShop(event.target.value)}
                                            type="range"
                                        />
                                    </Col>
                                    <Col sm={1}>
                                        <RangeContainer>
                                            {bodyShop}
                                        </RangeContainer>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={12}>
                                <Row>
                                    <Col sm={1}>
                                        <Label
                                            for="valeting"
                                        >
                                            Valeting
                                        </Label>
                                    </Col>
                                    <Col sm={2}>
                                        <input
                                            min={1}
                                            max={10}
                                            step={1}
                                            id="valeting"
                                            name="valeting"
                                            type="range"
                                            value={valeting}
                                            onChange={event => setValeting(event.target.value)}
                                        />
                                    </Col>
                                    <Col sm={1}>
                                        <RangeContainer>
                                            {valeting}
                                        </RangeContainer>
                                    </Col>
                                </Row>

                            </Col>
                            <Button outline={true}
                                style={{ color: '#ec6409', border: '1px solid #ec6409', float: 'right' }}
                                type="button" onClick={() => setShowChecklist(false)}>Cancel</Button>
                            <Button outline={true} style={{
                                backgroundColor: '#ec6409',
                                color: 'white',
                                border: '1px solid #ec6409',
                                float: 'right',
                                marginRight: '15px'
                            }} onClick={() => (saveData())}>Save</Button>
                        </>
                        :
                        <>



                            <TasksList
                                departments={departments}
                                enableDelete={true}
                                deleteTask={(id)=>{
                                    openDeleteModal(id)
                                    }}
                                complete={complete}
                                showChecklist={setShowChecklist}
                                initialStatus={tasksStatus}
                                setShowCreateNew={setShowCreateNew}
                                disableSaving={disableSaving}
                                sendingEmail={sendingEmail}
                                adminTableData={adminTableData}
                                tasks={tasks}
                                date={details.created_at}
                                vehicle={details.vehicle}
                                timeLine={details.worked_time}
                                priorities={priorities}
                                statuses={statuses}
                                isAdmin={isAdmin}
                                activeDepartment={activeDepartment}
                                canWorkOn={canWorkOn}
                                keys={isAdmin ? keys : workerKeys}
                                workerData={tasks}
                                hasDocument={hasDocument}
                                generatePdf={pdfAction}
                                setData={setData}
                                setStatusData={setStatusData}
                                setDepartmentsData={updateDepartments}
                                changeStatus={updateTaskStatus}
                                selectDepartment={setDepartmentFilter}
                                selectPriority={setPriorityFilter}
                                setSearchByName={searchByText}
                                saveCosts={saveCosts} />

                        </>

            }
        </>
    )
}