import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import CustomSearch from "@pages/components/CustomSearch";
import CalendarFilter from "@pages/components/CalendarFilter";
import {SelectWithoutInput} from "@pages/components/InputSelect";

const Filter = ({ fetchData, showStatusFilter = true, showTypeFilter = true }) => {
  const leadState = useSelector((state) => state.leadState, shallowEqual);
  const [filter, setFilter] = useState({});
  const [authUserTeam, setAuthUserTeam] = useState('');
  const [dealers, setDealers] = useState([]);
  const [leadsStatuses, setLeadsStatuses] = useState('');
  const [leadsTypes, setLeadsTypes] = useState('');

  useEffect(() => {
    setLeadsStatuses(leadState.leadsStatuses);
    setLeadsTypes(leadState.leadsTypes);
    setAuthUserTeam(leadState.authUserTeam);
    setFilter({
      ...leadState.filter,
      from: moment(leadState.filter.from).format('YYYY-MM-DD'),
      to: moment(leadState.filter.to).format('YYYY-MM-DD'),
    });

    if (!leadState.authUserTeam) {
      setDealers(leadState.dealers);
    }
  }, [leadState]);


  const findData = (key,val) => {
    let temp = {...filter, [key]: val,}
    fetchData(1, temp);
    setFilter(temp)
  }
  const selectDate =(date)=>{
    let temp = {...filter,...date}
    fetchData(1, temp);
    setFilter(temp)
  }
  return (
    <div>
      <Row className="m-2">
        <Col lg={10} md={10} sm={12}>
          {
            (window.innerWidth < 833 ?

                    <div className={'d-flex flex-column g-10'}>
                      <CustomSearch
                          onChange={(e) => {
                            findData('search', e.target.value,)
                          }}
                          placeholder="Name"
                      />
                      <CalendarFilter filterByDate={(data)=>{
                        selectDate(data)
                      }}
                      />
                      {!authUserTeam && ( <SelectWithoutInput
                          label={filter?.dealerId && dealers[filter?.dealerId] ? dealers[filter?.dealerId] : `Dealer`}
                          onSelect={(e) =>
                          {
                            findData('dealerId',Object.keys(dealers).find(key => dealers[key] === e))
                          }
                          }
                          options={Object.values(dealers)}
                          defaultOption={true}
                          imageLink={'group-p.png'}
                      /> )}
                      <SelectWithoutInput
                          label={filter?.statusFilter ? leadsStatuses[filter?.statusFilter] : `Status`}
                          onSelect={(e) =>
                          {
                            findData('statusFilter',Object.keys(leadsStatuses).find(key => leadsStatuses[key] === e))
                          }
                          }
                          options={Object.values(leadsStatuses)}
                          defaultOption={true}
                          imageLink={'loyalty.png'}
                      />
                      <SelectWithoutInput
                          label={filter?.typeFilter ? leadsTypes[filter?.typeFilter] : `Types`}
                          onSelect={(e) =>
                          {
                            findData('typeFilter',Object.keys(leadsTypes).find(key => leadsTypes[key] === e))
                          }
                          }
                          options={Object.values(leadsTypes)}
                          defaultOption={true}
                          imageLink={'loyalty.png'}
                      />
                    </div>
                    :

                    <Row className={'align-items-center'}>
                      <Col md={3}>
                        <CustomSearch
                            onChange={(e) => {
                              findData('search', e.target.value,)
                            }}
                            placeholder="Name"
                        />
                      </Col>
                      <Col md={4}>
                        <CalendarFilter filterByDate={(data)=>{
                          selectDate(data)
                        }}
                        />

                      </Col>
                      <Col md={!authUserTeam ? 6 : 4}>
                        <div className={'d-flex ml-3 mr-3'}>
                          {!authUserTeam && ( <SelectWithoutInput
                              label={filter?.dealerId && dealers[filter?.dealerId] ? dealers[filter?.dealerId] : `Dealer`}
                              onSelect={(e) =>
                              {
                                findData('dealerId',Object.keys(dealers).find(key => dealers[key] === e))
                              }
                              }
                              options={Object.values(dealers)}
                              defaultOption={true}
                              imageLink={'group-p.png'}
                          /> )}
                          {showStatusFilter && <SelectWithoutInput
                              fullWidth={true}

                              label={filter?.statusFilter ? leadsStatuses[filter?.statusFilter] : `Status`}
                              onSelect={(e) => {
                                findData('statusFilter', Object.keys(leadsStatuses).find(key => leadsStatuses[key] === e))
                              }
                              }
                              options={Object.values(leadsStatuses)}
                              defaultOption={true}
                              imageLink={'loyalty.png'}
                          />}
                          {showTypeFilter && <SelectWithoutInput
                              fullWidth={true}

                              label={filter?.typeFilter ? leadsTypes[filter?.typeFilter] : `Types`}
                              onSelect={(e) => {
                                findData('typeFilter', Object.keys(leadsTypes).find(key => leadsTypes[key] === e))
                              }
                              }
                              options={Object.values(leadsTypes)}
                              defaultOption={true}
                              imageLink={'loyalty.png'}
                          />}
                        </div>

                      </Col>
                    </Row>
            )
          }

        </Col>
      </Row>
    </div>
  );
};

export default React.memo(Filter);

Filter.propTypes = {
  fetchData: PropTypes.func,
  resetLeads: PropTypes.func,
};
