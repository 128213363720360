import React from 'react'

export default function CloseArrow({order = 'sc',setOrder, color = '#Fafafa'}) {
    return (
        <div className={'nested-table-arrow-container'}
            onClick={(e) => {
            e.preventDefault();
            setOrder(order === 'asc' ? 'desc' : 'asc')
        }}>
            <i className={`nested-table-arrow ${order === 'asc' ?  'nested-table-down' : 'nested-table-up'}`}
               style={{ color: color, cursor: "pointer" }}></i>
        </div>
    )
}
