import * as actionTypes from './actions';
import produce from 'immer';

const initialState = {
  tasks: [],
  categories: [],
  resources: [],
  deals: [],
  customers: [],
  vehicles: [],
  taskCreatedSuccessfully: false,
  dealerMembers: [],
  defaultStatuses: [],
  dealerAdmin: false,
  isUpdateTaskSuccess: false,
  isUpdateTaskError: false,
  getTaskSuccess: false,
  getTaskError: false,
  updateTaskErrorMessage: '',
  createCommentError: false,
  createCommentSuccess: false,
  createCommentErrorMessage: '',
  updateCommentError: false,
  updateCommentSuccess: false,
  updateCommentErrorMessage: '',
  searchTaskError: false,
  searchTaskSuccess: false,
  searchTaskErrorMessage: '',
};

const getTaskIndexById = (tasks, id) => {
  return tasks.findIndex((task) => task.id === parseInt(id));
};

const taskState = produce((draft = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_TASK_REQUEST:
      draft.tasks.push(action.task);
      draft.taskCreatedSuccessfully = false;
      break;
    case actionTypes.CREATE_TASK_SUCCESS:
      draft.tasks.push(action.task);
      draft.taskCreatedSuccessfully = true;
      break;
    case actionTypes.CREATE_TASK_ERROR:
      draft.tasks.push(action.task);
      draft.taskCreatedSuccessfully = false;
      break;
    case actionTypes.GET_TASK_CATEGORIES_SUCCESS:
      draft.categories = action.data.categories;
      draft.resources = action.data.resources;
      break;
    case actionTypes.GET_DEALS_REQUEST:
      draft.dealsdeals = [];
      break;
    case actionTypes.GET_DEALS_SUCCESS: {
      draft.deals = action.deals;
      break;
    }
    case actionTypes.GET_CUSTOMERS_REQUEST: {
      draft.customers = [];
      break;
    }
    case actionTypes.GET_CUSTOMERS_SUCCESS: {
      draft.customers = action.customers;
      break;
    }
    case actionTypes.GET_VEHICLES_SUCCESS: {
      draft.vehicles = action.vehicles;
      break;
    }
    case actionTypes.GET_TASKS_SUCCESS: {
      draft.getTaskSuccess = true
      draft.getTaskError = false;
      draft.tasks = action.tasks ?? [];
      break;
    }
    case actionTypes.GET_TASKS_ERROR: {
      draft.getTaskSuccess = false
      draft.getTaskError = true
      break;
    }
    case actionTypes.GET_TASKS_REQUEST: {
      draft.getTaskSuccess = false
      draft.getTaskError = false
      break;
    }
    case actionTypes.UPDATE_TASK_REQUEST:
      draft.isUpdateTaskSuccess = false;
      draft.isUpdateTaskError = false;
      draft.updateTaskErrorMessage = '';
      break;
    case actionTypes.UPDATE_TASK_SUCCESS:
      draft.isUpdateTaskSuccess = true;
      draft.isUpdateTaskError = false;
      draft.updateTaskErrorMessage = '';
      break;
    case actionTypes.UPDATE_TASK_ERROR:
      draft.isUpdateTaskSuccess = false;
      draft.isUpdateTaskError = true;
      draft.updateTaskErrorMessage = action.payload.message;
      break;
    case actionTypes.CREATE_COMMENT_REQUEST:
      draft.createCommentError = false;
      draft.createCommentSuccess = false;
      draft.createCommentErrorMessage = '';
      break;
    case actionTypes.CREATE_COMMENT_SUCCESS:
      draft.createCommentError = false;
      draft.createCommentSuccess = true;
      draft.createCommentErrorMessage = '';

      const taskIndex = getTaskIndexById(draft.tasks, action.task.id);
      draft.tasks[taskIndex] = {
        ...draft.tasks[taskIndex],
        ...action.task,
      };
      break;
    case actionTypes.CREATE_COMMENT_ERROR:
      draft.createCommentError = true;
      draft.createCommentSuccess = false;
      draft.createCommentErrorMessage = action.payload.message;
      break;
    case actionTypes.DELETE_COMMENT_REQUEST:
      draft.deleteCommentError = false;
      draft.deleteCommentSuccess = false;
      draft.deleteCommentErrorMessage = '';
      break;
    case actionTypes.DELETE_COMMENT_SUCCESS:
      draft.deleteCommentError = false;
      draft.deleteCommentSuccess = true;
      draft.deleteCommentErrorMessage = '';
      const deletedTaskIndex = getTaskIndexById(draft.tasks, action.taskId);
      draft.tasks[deletedTaskIndex] = {
        ...draft.tasks[deletedTaskIndex],
        comments: [
          ...draft.tasks[deletedTaskIndex]['comments'].filter(
            (comment) => comment.id !== action.commentId
          ),
        ],
      };
      break;
    case actionTypes.DELETE_COMMENT_ERROR:
      draft.deleteCommentError = true;
      draft.deleteCommentSuccess = false;
      draft.deleteCommentErrorMessage = action.payload.message;
      break;
    case actionTypes.UPDATE_COMMENT_REQUEST:
      draft.updateCommentSuccess = false;
      draft.updateCommentError = false;
      draft.updateCommentErrorMessage = '';
      break;
    case actionTypes.UPDATE_COMMENT_SUCCESS:
      draft.updateCommentSuccess = true;
      draft.updateCommentError = false;
      draft.updateCommentErrorMessage = '';
      const updatedTaskIndex = getTaskIndexById(draft.tasks, action.taskId);
      draft.tasks[updatedTaskIndex] = {
        ...draft.tasks[updatedTaskIndex],
        comments: [
          ...action.task.comments
        ],
      };
      break;
    case actionTypes.UPDATE_COMMENT_ERROR:
      draft.updateCommentSuccess = false;
      draft.updateCommentError = true;
      draft.updateCommentErrorMessage = action.payload.message;
      break;
    case actionTypes.SEARCH_TASK_REQUEST:
      draft.searchTaskError = false;
      draft.searchTaskSuccess = false;
      draft.searchTaskErrorMessage = '';
      break;
    case actionTypes.SEARCH_TASK_SUCCESS:
      draft.searchTaskError = false;
      draft.searchTaskSuccess = true;
      draft.searchTaskErrorMessage = '';
      draft.tasks = [...action.tasks];
      break;
    case actionTypes.SEARCH_TASK_ERROR:
      draft.searchTaskError = true;
      draft.searchTaskSuccess = false;
      draft.searchTaskErrorMessage = action.payload.message;
      break;
    default:
      return draft;
  }
});

export default taskState;
