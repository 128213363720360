import React, {useState} from 'react';

import {
    Form,
    FormGroup, Input, Label,
} from "reactstrap";


export default function CustomCheckbox({   id,
                                           label,
                                           checked,
                                           onChange,
                                       }) {
    return (
        <>
            <Form>
                <FormGroup
                    check
                    inline
                    className={'custom-checkbox-container'}
                >
                    <label className="custom-box">
                        <Input
                            type="checkbox"
                            checked={checked}
                            onChange={onChange}
                        />
                        <span className="checkmark"></span>

                    </label>
                    <Label check>{label}</Label>

                </FormGroup>
            </Form>
        </>
    )
}