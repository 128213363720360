import React, { useEffect, useState, useRef } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import NotificationAlert from 'react-notification-alert'
import axios from 'axios'
import Cookies from 'js-cookie'

import * as constants from 'constants/index.js'
import CardsHeader from 'layouts/Headers/CardsHeader.js'

import InputCustom from 'views/pages/components/InputCustom'
import TableCustom from 'views/pages/components/TableCustom'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Modal,
} from 'reactstrap'
import {ButtonContainer} from "@pages/reusable-components/styled-components";
import IconSet from "@assets/selection.json";
import IcoMoon from "react-icomoon";

export default function UserHoliday() {
  const { id } = useParams()
  const notificationAlert = useRef(null)
  const location = useLocation()

  const [token, setToken] = useState('')
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])
  const [pagination, setPagination] = useState('')
  
  const [item, setItem] = useState('')
  const [itemModal, setItemModal] = useState({
    show: false,
    header: '',
    division: ''
  })
  const [errors, setErrors] = useState('')
  const [deleteModal, setDeleteModal] = useState({
    show: false,
    header: '',
    body: '',
    onClick: null
  })

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    }
    notificationAlert.current.notificationAlert(options)
  }

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    fetchData(1, source, grs_token)

    return () => {
      source.cancel()
    }
  }, [])

  const fetchData = async (pageNumber, source, token) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/holidays`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page: pageNumber,
          user_id: id,
        },
        cancelToken: source.token,
      })
      console.log('response :: ', response.data)
      const data = response.data
      setItems(data.data.items.data)
      setPagination(data.data.items)

      setLoading(false)
    } catch (error) {
      setLoading(false)
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
      }
    }
  }

  const init = (item) => {
    setItem(item)
    setErrors('')
  }

  const createHoliday = async () => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${constants.API_URL}/holidays`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          ...item,
          user_id: id,
        },
      })
      console.log('response :: ', response.data)
      const data = response.data
      setItems(data.data.items.data)
      setPagination(data.data.items)

      notify('success', 'Success', data.message)
      
      setItemModal({
        show: false
      })
    } catch (error) {
      console.error('error :: ', error)
      if (error.response && error.response.status === 422) {
        setErrors(error.response.data.errors)
        return
      }
      setItemModal({
        show: false
      })
      notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
    }
  }

  const updateHoliday = async () => {
    try {
      const response = await axios({
        method: 'PUT',
        url: `${constants.API_URL}/holidays/${item.id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          ...item,
          user_id: id,
        },
      })
      console.log('response :: ', response.data)
      const data = response.data
      setItems(data.data.items.data)
      setPagination(data.data.items)

      notify('success', 'Success', data.message)
      
      setItemModal({
        show: false
      })
    } catch (error) {
      console.error('error :: ', error)
      if (error.response && error.response.status === 422) {
        setErrors(error.response.data.errors)
        return
      }
      setItemModal({
        show: false
      })
      notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
    }
  }

  const deleteHoliday = async (holidayId) => {
    try {
      const response = await axios({
        method: 'DELETE',
        url: `${constants.API_URL}/holidays/${holidayId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          user_id: id,
        }
      })
      console.log('response :: ', response.data)
      const data = response.data
      setItems(data.data.items.data)
      setPagination(data.data.items)

      notify('success', 'Success', data.message)
      
      setDeleteModal({
        show: false
      })
    } catch (error) {
      console.error('error :: ', error)
      setDeleteModal({
        show: false
      })
      notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
    }
  }

  return (
    <>
      <CardsHeader name="Admin" parentName="Holidays Management" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">{`${location.state && location.state.user_name? `${location.state.user_name}'s `: ``}Holidays`}</h1>
                  </Col>
                  <Col xs={4} className="text-right">
                    <ButtonContainer onClick={() => {
                      init('')
                      setItemModal({
                        show: true,
                        header: 'Add new holiday',
                        division: 'create'
                      })
                    }}>
                      <i className="fa fa-plus" aria-hidden="true"></i>
                      Add new
                    </ButtonContainer>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <TableCustom 
                  loading={loading}
                  items={items}
                  pagination={pagination}
                  onChange={pageNumber => fetchData(pageNumber, axios.CancelToken.source(), token)}
                  columns={[
                    {
                      dataField: "name",
                      text: "Name"
                    },
                    {
                      dataField: "start_date",
                      text: "Start date",
                      formatter: (rowContent, row) => {
                        return row.start_date? constants.formatDateShortGB(row.start_date): ''
                      }
                    },
                    {
                      dataField: "end_date",
                      text: "End date",
                      formatter: (rowContent, row) => {
                        return row.end_date? constants.formatDateShortGB(row.end_date): ''
                      }
                    },
                    {
                      dataField: "",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                            <>
                              <IcoMoon
                                  className={'cursor-pointer'}
                                  iconSet={IconSet}
                                  icon={'edit'}
                                  size={24}
                                  onClick={() => {
                                    init(row)
                                    setItemModal({
                                      ...itemModal,
                                      show: true,
                                      header: 'Update holiday',
                                      division: 'update'
                                    })
                                  }}
                              />
                              <IcoMoon
                                  className={'cursor-pointer'}
                                  iconSet={IconSet}
                                  icon={'delete_outline'}
                                  size={24}
                                  onClick={() => {
                                    setDeleteModal({
                                      ...deleteModal,
                                      show: true,
                                      header: 'Delete holiday',
                                      body: 'Are you sure you want to delete this holiday?',
                                      onClick: () => deleteHoliday(row.id)
                                    })
                                  }}
                              />
                            </>
                        )
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={itemModal.show}
          toggle={() => setItemModal({
            ...itemModal,
            show: false,
          })}
          className="modal-dialog-centered"
        >
          <div className="modal-header main-color">
            <h6 className="modal-title">{itemModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setItemModal({
                ...itemModal,
                show: false,
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <InputCustom 
              newLabel={`Name`}
              value={item && item.name? item.name: ''}
              onChange={e => setItem({
                ...item,
                name: e.target.value
              })}
              invalid={errors && errors.name? true: false}
              errorMessage={errors.name}
            />
            <InputCustom 
              type={`date`}
              newLabel={`Start date`}
              value={item && item.start_date? item.start_date: ''}
              onChange={e => setItem({
                ...item,
                start_date: e.target.value
              })}
              invalid={errors && errors.start_date? true: false}
              errorMessage={errors.start_date}
            />
            <InputCustom 
              type={`date`}
              newLabel={`End date`}
              min={item && item.start_date? item.start_date: ''}
              value={item && item.end_date? item.end_date: ''}
              onChange={e => setItem({
                ...item,
                end_date: e.target.value
              })}
              invalid={errors && errors.end_date? true: false}
              errorMessage={errors.end_date}
            />
          </div>
          <div className="modal-footer justify-content-center">
            <ButtonContainer
                onClick={() => setItemModal({
                  ...itemModal,
                  show: false,
                })}
            >
              {`Close`}
            </ButtonContainer>
            <ButtonContainer
              reverse
              onClick={() => itemModal.division === 'create'? createHoliday() : updateHoliday()}
            >
              {`Save`}
            </ButtonContainer>

          </div>
        </Modal>
        <Modal
          isOpen={deleteModal.show}
          toggle={() => setDeleteModal({
            ...deleteModal,
            show: false,
          })}
          className="modal-dialog-centered"
        >
          <div className="modal-header main-color">
            <h6 className="modal-title">{deleteModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setDeleteModal({
                ...deleteModal,
                show: false,
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {deleteModal.body}
          </div>
          <div className="modal-footer justify-content-center">
            <ButtonContainer
                onClick={() => setDeleteModal({
                  ...deleteModal,
                  show: false,
                })}
            >
              {`Close`}
            </ButtonContainer>
            <ButtonContainer
              reverse
              onClick={deleteModal.onClick}
            >
              {`Delete`}
            </ButtonContainer>

          </div>
        </Modal>
      </Container>
    </>
  )
}