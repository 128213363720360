import React, {useEffect, useRef} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";
import CardsHeader from 'layouts/Headers/CardsHeader.js';
import Content from './Content';
import Tabs from './Tabs';

import { PaymentContextProvider } from '../context';

import {
  Container,
  Row,
  Col,
  Card,
} from "reactstrap";
import actions from "../../../../actions";
import {useDispatch, useSelector} from "react-redux";

export default function Payment() {
  const { id } = useParams();
  const history = useHistory()
  const dispatch = useDispatch();
  const {activeSubmenusList} = useSelector((state) => state.getState);
    useEffect(()=>{
        if (!activeSubmenusList.length) {
            const tabs = [
                {
                    value: 'info',
                    key:'Deal Info',
                },
                {
                    value: 'documents',
                    key:'Documents',
                },
                {
                    value: 'uploads',
                    key:'Uploads',
                },
                {
                    value: 'notes',
                    key:'Notes',
                },
                {
                    value: 'tasks',
                    key:'Tasks',
                },
                {
                    value: 'payments',
                    key:'Payments',
                    action:'goPayment'
                },
                {
                    value: 'tracking',
                    key:'Vehicle Tracking Location',
                    action:'goTracking'
                },
            ]
            dispatch(actions.setMenusList(tabs))
            dispatch(actions.setMenuName('Deals'))
            dispatch(actions.setActiveMenu( 'payments'))
        }

    },[activeSubmenusList])
  const notificationAlert = useRef(null);
  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };
    const clearMenus = () => {
        history.push(`/admin/deals`)
    }
  return (
    <PaymentContextProvider id={id} notify={notify}>
      <CardsHeader name="Deal" parentName="Payment management" currentName="List"  backButtonText={'Back to Deals'}
                   showBackButton={true}  redirect={clearMenus} />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col md={12} xl={12}>
            <Card>
              <Tabs />
            </Card>
            <Card>
              <Content />
            </Card>
          </Col>
        </Row>
      </Container>
    </PaymentContextProvider>
  )
}