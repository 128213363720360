import React from 'react';

import XeroDetail from './XeroDetail';

export default function PaymentItem({ provider, ...props }) {
    const moduleSelector = (provider) => {
        switch (provider) {
            case 'xero':
                return (<XeroDetail key={provider} {...props}/>)
            default:
                break;
        }
    }

    return (
        <>
            {moduleSelector(provider)}
        </>
    )
}