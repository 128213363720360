import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import Select from 'react-select'
import NotificationAlert from "react-notification-alert"
import axios from 'axios'
import Cookies from 'js-cookie'
import * as constants from 'constants/index.js'

import CardsHeader from 'layouts/Headers/CardsHeader.js'
import InputCustom from 'views/pages/components/InputCustom'


import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup
} from 'reactstrap'
import {ButtonContainer} from "@pages/reusable-components/styled-components";

export default function ActivateTracker() {
  const notificationAlert = useRef(null)
  const history = useHistory()

  const [token, setToken] = useState('')
  const [activeVehicles, setActiveVehicles] = useState([])
  const [vehicle, setVehicle] = useState('')
  const [filter, setFilter] = useState('')
  const [errors, setErrors] = useState('')

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    }
    notificationAlert.current.notificationAlert(options)
  }

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    fetchData(source, grs_token, '')

    return () => {
      source.cancel()
    }
  }, [])

  const fetchData = async (source, token, filter) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/tracking/device/create`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: filter,
        cancelToken: source.token,
      })
      console.log('response :: ', response.data)
      const data = response.data

      const _activeVehicles = data.data.activeVehicles;
      const _activeVehicles_array = [];
      _activeVehicles.map(_vehicle => {
        return _activeVehicles_array.push({
          ..._vehicle,
          value: _vehicle.id,
          label: _vehicle.stock_no,
        })
      })
      setActiveVehicles(_activeVehicles_array);
      setVehicle({
        ...data.data.vehicle
      });
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
      }
    }
  }

  const goList = () => {
    history.push({
      pathname: '/admin/deals'
    })
  }

  const loadVehicle = async () => {
    const source = axios.CancelToken.source()
    fetchData(source, token, filter)
  }

  const resetVehicle = async () => {
    setFilter('')
    const source = axios.CancelToken.source()
    fetchData(source, token, '')
  }

  const activateTracker = async () => {
    try {
      console.log(vehicle)
      const response = await axios({
        method: 'POST',
        url: `${constants.API_URL}/tracking/device`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          vehicle_id: vehicle ? vehicle.id : '',
          device_name: vehicle && vehicle.stock_no ? vehicle.stock_no : '',
          identifier: vehicle && vehicle.identifier ? vehicle.identifier : '',
          phone_number: vehicle && vehicle.device_iccid_or_phone ? vehicle.device_iccid_or_phone : '',
          device_iccid_or_phone: vehicle && vehicle.device_iccid_or_phone ? vehicle.device_iccid_or_phone : '',
          current_mileage: vehicle && vehicle.current_mileage ? vehicle.current_mileage : '',
          device_type: 'fm204',
        },
      })
      console.log('response :: ', response.data)
      const data = response.data
      const uuid = data.data.uuid
      notify('success', 'Success', data.message)

      setTimeout(() => {
        history.push({
          pathname: `/admin/tracking/device/${uuid}`
        })
      }, 1500);
    } catch (error) {
      console.error('error :: ', error)
      if (error.response && error.response.status === 422) {
        setErrors(error.response.data.errors)
        return
      }
      notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
    }
  }

  return (
    <>
      <CardsHeader name="Activate Tracker" parentName="Activate Tracker" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Load Data</h1>
                  </Col>
                  <Col xs={3}>
                    <ButtonContainer color="success" type="button" onClick={() => goList()}>Back to list</ButtonContainer>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={3} sm={12}>
                    <FormGroup>
                      <label className="form-control-label">Vehicle</label>
                      <Select
                        options={activeVehicles}
                        value={filter && filter.vehicle_load ? (
                          (activeVehicles.find((element) => element.id === filter.vehicle_load) || '')
                        ) : ''}
                        onChange={value => setFilter({
                          ...filter,
                          vehicle_load: value.id
                        })}
                      />
                      <div className="invalid-feedback" style={{ display: errors && errors.vehicle_id ? 'block' : 'none' }}>
                        {errors.vehicle_id}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Col sm={12} lg={3} className={'align-self-center '}>
                  <ButtonContainer color="success" style={{ margin: 5 }} type="button" onClick={() => loadVehicle()}>Load</ButtonContainer>
                  <ButtonContainer reverse style={{ margin: 5 }} type="button" onClick={() => resetVehicle()}>Reset</ButtonContainer>
                </Col>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <h1 className="title">Activate Tracker</h1>
                <span className="text-sm mb-0">Activate your vehicle trackers here.</span>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={4} sm={6}>
                    <InputCustom
                      label={`Tracker Serial Number:`}
                      onChange={e => setVehicle({
                        ...vehicle,
                        identifier: e.target.value
                      })}
                      invalid={errors && errors.identifier ? true : false}
                      errorMessage={errors.identifier}
                    />
                  </Col>
                  <Col md={4} sm={6}>
                    <InputCustom
                      label={`Device Phone Number or IMEI:`}
                      onChange={e => setVehicle({
                        ...vehicle,
                        device_iccid_or_phone: e.target.value
                      })}
                      invalid={errors && errors.device_iccid_or_phone ? true : false}
                      errorMessage={errors.device_iccid_or_phone}
                    />
                  </Col>
                  <Col md={4} sm={6}>
                    <InputCustom
                      label={`Device Name:`}
                      value={vehicle && vehicle.stock_no ? vehicle.stock_no : ''}
                      readOnly={true}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={12} sm={12}>
                    <h2>{`Vehicle info`}</h2>
                  </Col>
                  <Col md={4} sm={12} className="mt-2">
                    <InputCustom
                      label={`Make & Model:`}
                      value={vehicle && (vehicle.vehicle_make && vehicle.vehicle_make.name) && (vehicle.vehicle_model && vehicle.vehicle_model.name) ? `${vehicle.vehicle_make.name} ${vehicle.vehicle_model.name}` : ''}
                      readOnly={true}
                    />
                  </Col>
                  <Col md={4} sm={12} className="mt-2">
                    <InputCustom
                      label={`Derivative:`}
                      value={vehicle && vehicle.derivative ? vehicle.derivative : ''}
                      readOnly={true}
                    />
                  </Col>
                  <Col md={4} sm={12} className="mt-2">
                    <InputCustom
                      label={`Reg Number:`}
                      value={vehicle && vehicle.stock_no ? vehicle.stock_no : ''}
                      readOnly={true}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={4} sm={12}>
                    <InputCustom
                      label={`Current Mileage:`}
                      value={vehicle && vehicle.current_mileage ? vehicle.current_mileage : ''}
                      readOnly={true}
                    />
                  </Col>
                  <Col md={4} sm={12}>
                    <InputCustom
                      label={`Colour:`}
                      value={vehicle && vehicle.colour ? vehicle.colour : ''}
                      readOnly={true}
                    />
                  </Col>
                </Row>
                <Col >
                  <ButtonContainer reverse onClick={() => activateTracker()}>{`Activate Tracker & Subscription`}</ButtonContainer>
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}