import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import TableCustom from 'views/pages/components/TableCustom';
import InputCustom from 'views/pages/components/InputCustom';

import {
  update,
  deletee,
  hasPermission
} from 'views/policies/VehicleMakePolicy';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Modal,
} from 'reactstrap';
import { ButtonContainer } from "@pages/reusable-components/styled-components";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";

export default function VehicleMake() {
  const notificationAlert = useRef(null);
  const permissions = useSelector(state => state.getState).permissions;

  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [isNotValid, setIsNotValid] = useState(true);
  const [delay, setDelay] = useState(true);
  const [makes, setMakes] = useState([]);

  const initialMakeState = {
    id: null,
    name: '',
  };

  const resetMakeState = () => {
    setMake(initialMakeState);
  };

  const [make, setMake] = useState(initialMakeState);
  const [makeModal, setMakeModal] = useState({
    show: false,
    header: '',
    division: '',
  });

  const [deleteModal, setDeleteModal] = useState({
    show: false,
    header: '',
    body: '',
    onClick: null,
  });

  const [errors, setErrors] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${constants.API_URL}/vehicle-make`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = response.data;
      setMakes(data.data);

      setTimeout(() => {
        setDelay(true);
        setLoading(false);
      }, 500);
    } catch (error) {
      setLoading(false);
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error);
      } else {
        console.error('error :: ', error);
        notify(
          'warning',
          'Warning',
          error.response && error.response.data
            ? error.response.data.message
            : error.response
              ? error.response.statusText
              : 'Error Occurred!'
        );
      }
    }
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token');
    setToken(grs_token);
    const source = axios.CancelToken.source();

    fetchData();

    return () => {
      source.cancel();
    };
  }, []);

  const handleModalClose = () => {
    setMakeModal({ ...makeModal, show: false });
  };

  const addOrUpdateMake = (url, successMessage) => {
    axios({
      method: make.id ? 'PUT' : 'POST',
      url,
      headers: { Authorization: `Bearer ${token}` },
      data: make,
    })
      .then(response => {
        handleModalClose();
        resetMakeState();
        setErrors('');
        console.log('res :: ', response.data);
        const data = response.data;
        fetchData();
        notify('success', 'Success', data.message || successMessage);
      })
      .catch(error => {
        setErrors('');
        console.error('error :: ', error);
        if (error.response && error.response.status === 422) {
          setErrors(error.response.data.errors);
          return;
        }
        handleModalClose();
        notify(
          'warning',
          'Warning',
          error.response ? error.response.statusText : 'Error occurred!'
        );
      });
  };

  const deleteMake = makeId => {
    axios({
      method: 'DELETE',
      url: `${constants.API_URL}/vehicle-make/${makeId}`,
      headers: { Authorization: `Bearer ${token}` },
      data: make,
    })
      .then(response => {
        setDeleteModal({ ...deleteModal, show: false });
        console.log('res :: ', response.data);
        const data = response.data;
        fetchData();
        notify('success', 'Success', data.message);
      })
      .catch(error => {
        console.error('error :: ', error);
        setDeleteModal({ ...deleteModal, show: false });
        notify(
          'warning',
          'Warning',
          error.response ? error.response.statusText : 'Error occurred!'
        );
      });
  };

  return (
    <>
      <CardsHeader name="Vehicles" parentName="Vehicle make Management" currentName="Create" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Vehicle makes</h1>
                    <p className="text-sm mb-0">You can manage Vehicle makes.</p>
                  </Col>
                  <Col xs={4} className="text-right">
                    <ButtonContainer onClick={() => {
                      setMake('')
                      setIsNotValid(true)
                      setMakeModal({
                        ...makeModal,
                        show: true,
                        header: 'Add make',
                        division: 'create'
                      })
                    }}>
                      <i className="fa fa-plus" aria-hidden="true"></i>
                      Add vehicle make</ButtonContainer>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <TableCustom
                  loading={loading}
                  delay={delay}
                  items={makes}
                  noOverflow
                  columns={[
                    {
                      dataField: "name",
                      text: "Name",
                      sort: true
                    },
                    {
                      dataField: "",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            {(hasPermission(permissions, 'access-vehicle-makes') || hasPermission(permissions, 'delete-vehicle-makes')) &&
                              <>

                                {update(permissions, row) &&

                                <IcoMoon
                                    className={'cursor-pointer'}
                                    iconSet={IconSet}
                                    icon={'edit'}
                                    size={24}
                                    onClick={() => {
                                      setMake(row)
                                      setIsNotValid(true)
                                      setMakeModal({
                                        ...makeModal,
                                        show: true,
                                        header: 'Edit make',
                                        division: 'update'
                                      })
                                    }}
                                />
                                }

                                {deletee(permissions, row) &&
                                <IcoMoon
                                    className={'cursor-pointer'}
                                    iconSet={IconSet}
                                    icon={'delete_outline'}
                                    size={24}
                                    onClick={() => {
                                      setDeleteModal({
                                        show: true,
                                        header: 'Delete make',
                                        body: 'Are you sure you want to delete this make?',
                                        onClick: () => deleteMake(row.id)
                                      })
                                    }}
                                />
                                }
                              </>
                            }
                          </>
                        )
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={makeModal.show}
          toggle={handleModalClose}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title main-color">{makeModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={handleModalClose}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <InputCustom
              newLabel={`Name`}
              editValue={make.name}
              required
              setIsNotValid={setIsNotValid}
              value={make.name ?? ''}
              onChange={e => setMake({ ...make, name: e.target.value })}
              invalid={errors && errors.name ? true : false}
              errorMessage={errors.name}
            />
          </div>
          <div className="modal-footer justify-content-end">
            <ButtonContainer onClick={handleModalClose}>{`Close`}</ButtonContainer>
            <ButtonContainer
              disabled={isNotValid}
              reverse
              onClick={() =>
                makeModal.division === 'create'
                  ? addOrUpdateMake(`${constants.API_URL}/vehicle-make`, 'Make added successfully.')
                  : addOrUpdateMake(
                    `${constants.API_URL}/vehicle-make/${make.id}`,
                    'Make updated successfully.'
                  )
              }
            >
              {`Save`}
            </ButtonContainer>
          </div>
        </Modal>
        <Modal
          isOpen={deleteModal.show}
          toggle={() => setDeleteModal({
            show: false
          })}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title main-color">{deleteModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setDeleteModal({
                show: false
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {deleteModal.body}
          </div>

          <div className="modal-footer justify-content-end">
            <ButtonContainer
              onClick={() => setDeleteModal({
                show: false
              })}
            >
              {`Close`}
            </ButtonContainer>
            <ButtonContainer
              reverse
              onClick={deleteModal.onClick}
            >
              {`Delete`}
            </ButtonContainer>

          </div>
        </Modal>
      </Container>
    </>
  )
}