import React, { useContext } from 'react';
import { LeadsContext } from '../context';

import {
  Row,
  Col,
} from "reactstrap";

import {
  InputArraySelect,
} from 'views/pages/components/InputSelect';

import InputCustom from 'views/pages/components/InputCustom';
import {ButtonContainer} from "@pages/reusable-components/styled-components";

export default function DetailsSection() {
  const leadsContext = useContext(LeadsContext);
  const {
    lead, setLead,
    leadErrors,
    setModels,
    updateLead,
    makes,
    makesAndModels,
    models,
    leadSources,
    dealerMembers,
  } = leadsContext;
  return (
          <Col lg={5} md={5} sm={12}>

              <h3>Details:</h3>
              <Row  className={'mt-4'}>
                  <Col md={6} sm={12}>
                      <Row>
                          <Col md={12}>
                              <InputCustom
                                  newLabel={`Full Name`}
                                  value={lead && lead.name ? lead.name : ""}
                                  onChange={(e) =>
                                      setLead({
                                          ...lead,
                                          name: e.target.value,
                                      })
                                  }
                                  invalid={leadErrors && leadErrors.name ? true : false}
                                  errorMessage={leadErrors.name}
                              />
                          </Col>
                      </Row>
                      <Row>
                          <Col md={12}>
                              <InputCustom
                                  newLabel={`Mobile No`}
                                  value={lead && lead.phone ? lead.phone : ""}
                                  onChange={(e) =>
                                      setLead({
                                          ...lead,
                                          phone: e.target.value,
                                      })
                                  }
                                  invalid={leadErrors && leadErrors.phone ? true : false}
                                  errorMessage={leadErrors.phone}
                              />
                          </Col>
                      </Row>
                      <Row>
                          <Col md={12}>
                              <InputCustom
                                  newLabel={`Email`}
                                  value={lead && lead.email ? lead.email : ""}
                                  onChange={(e) =>
                                      setLead({
                                          ...lead,
                                          email: e.target.value,
                                      })
                                  }
                                  invalid={leadErrors && leadErrors.email ? true : false}
                                  errorMessage={leadErrors.email}
                              />
                          </Col>
                      </Row>
                      <Row>
                          <Col md={12}>
                              <InputArraySelect
                                  newLabel={`Assignee`}
                                  value={lead && lead.assigned_to ? lead.assigned_to : ""}
                                  options={dealerMembers}
                                  onChange={(e) =>
                                      setLead({
                                          ...lead,
                                          assigned_to: e.target.value,
                                      })
                                  }
                                  valueKey={`id`}
                                  labelKey={`name`}
                                  defaultOption={true}
                                  invalid={leadErrors && leadErrors.assigned_to ? true : false}
                                  errorMessage={leadErrors.assigned_to}
                              />
                          </Col>
                      </Row>
                  </Col>
                  <Col md={6} sm={12}>
                      <Row>
                          <Col md={12}>
                              <InputArraySelect
                                  newLabel={`Makes`}
                                  value={lead && lead.lead_make ? lead.lead_make : ""}
                                  options={makes}
                                  onChange={(e) => {
                                      setModels(
                                          e.target.value !== "-"
                                              ? makesAndModels[e.target.value]
                                              : []
                                      );
                                      setLead({
                                          ...lead,
                                          lead_make: e.target.value,
                                          vehicle_model_id:
                                              makesAndModels[e.target.value] &&
                                              makesAndModels[e.target.value].length > 0
                                                  ? makesAndModels[e.target.value][0].id
                                                  : "",
                                      });
                                  }}
                                  valueKey={`id`}
                                  labelKey={`name`}
                                  defaultOption={true}
                                  invalid={leadErrors && leadErrors.lead_make ? true : false}
                                  errorMessage={leadErrors.lead_make}
                              />
                          </Col>
                      </Row>
                      <Row>
                          <Col md={12}>
                              <InputArraySelect
                                  newLabel={`Model`}
                                  value={
                                      lead && lead.vehicle_model_id ? lead.vehicle_model_id : ""
                                  }
                                  options={models}
                                  onChange={(e) =>
                                      setLead({
                                          ...lead,
                                          vehicle_model_id: e.target.value,
                                      })
                                  }
                                  valueKey={`id`}
                                  labelKey={`name`}
                                  invalid={
                                      leadErrors && leadErrors.vehicle_model_id ? true : false
                                  }
                                  errorMessage={leadErrors.vehicle_model_id}
                              />
                          </Col>
                      </Row>
                      <Row>
                          <Col md={12}>
                              <InputCustom
                                  newLabel={`Drivative`}
                                  value={lead && lead.derivative ? lead.derivative : ""}
                                  onChange={(e) =>
                                      setLead({
                                          ...lead,
                                          derivative: e.target.value,
                                      })
                                  }
                                  invalid={leadErrors && leadErrors.derivative ? true : false}
                                  errorMessage={leadErrors.derivative}
                              />
                          </Col>
                      </Row>
                      <Row>
                          <Col md={12}>
                              <InputArraySelect
                                  newLabel={`Source`}
                                  value={lead && lead.lead_source_id ? lead.lead_source_id : ""}
                                  options={leadSources}
                                  onChange={(e) =>
                                      setLead({
                                          ...lead,
                                          lead_source_id: e.target.value,
                                      })
                                  }
                                  valueKey={`id`}
                                  labelKey={`name`}
                                  defaultOption={true}
                                  invalid={
                                      leadErrors && leadErrors.lead_source_id ? true : false
                                  }
                                  errorMessage={leadErrors.lead_source_id}
                              />
                          </Col>
                      </Row>
                  </Col>
              </Row>
              {/*<Col md={4} sm={12}>*/}
              {/*    <Row>*/}
              {/*      <Col md={12}>*/}
              {/*        <InputKeySelect*/}
              {/*          newLabel={`Lead Type`}*/}
              {/*          value={lead && lead.lead_type ? lead.lead_type : ""}*/}
              {/*          options={leadTypes}*/}
              {/*          onChange={(e) =>*/}
              {/*            setLead({*/}
              {/*              ...lead,*/}
              {/*              lead_type: e.target.value,*/}
              {/*            })*/}
              {/*          }*/}
              {/*          defaultOption={true}*/}
              {/*          invalid={leadErrors && leadErrors.lead_type ? true : false}*/}
              {/*          errorMessage={leadErrors.lead_type}*/}
              {/*        />*/}
              {/*      </Col>*/}
              {/*    </Row>*/}
              {/*</Col>*/}
              {/*<Row>*/}
              {/*  <Col md={6} sm={12}>*/}
              {/*    <InputCustom*/}
              {/*      type={`textarea`}*/}
              {/*      label={`Additional vehicle information`}*/}
              {/*      rows={5}*/}
              {/*      value={*/}
              {/*        lead && lead.additional_information*/}
              {/*          ? lead.additional_information*/}
              {/*          : ""*/}
              {/*      }*/}
              {/*      onChange={(e) =>*/}
              {/*        setLead({*/}
              {/*          ...lead,*/}
              {/*          additional_information: e.target.value,*/}
              {/*        })*/}
              {/*      }*/}
              {/*    />*/}
              {/*  </Col>*/}
              {/*</Row>*/}
              <div className="d-flex flex-row justify-content-end">
                  <ButtonContainer
                      reverse
                      onClick={() => updateLead()}
                  >
                      Update
                  </ButtonContainer>
              </div>
          </Col>
  );
}