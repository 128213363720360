import React, { useRef, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";
import Cookies from 'js-cookie';
import * as constants from 'constants/index.js';
import axios from 'axios';

import InputCustom from 'views/pages/components/InputCustom';
import InputToggle from 'views/pages/components/InputToggle';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";

import CardsHeader from 'layouts/Headers/CardsHeader.js';
import {ButtonContainer} from "@pages/reusable-components/styled-components";

export default function PermissionEdit() {
  const { id } = useParams();
  const notificationAlert = useRef(null);
  const history = useHistory();

  const [token, setToken] = useState('');
  const [permission, setPermission] = useState();

  const [errors, setErrors] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{__html: message}} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    if (id) {
      const grs_token = Cookies.get('grs_token')
      setToken(grs_token)

      const source = axios.CancelToken.source()

      const fetchData = async () => {
        try {
          const response = await axios({
            method: 'GET',
            url: `${constants.API_CORE_URL}/permission/${id}`,
            headers: {
              Authorization: `Bearer ${grs_token}`,
            },
            cancelToken: source.token,
          })
          console.log('response :: ', response.data)
          const data = response.data
          setPermission(data.data);
        } catch (error) {
          if (axios.isCancel(error)) {
            console.log('Request canceled :: ', error)
          } else {
            console.error('error :: ', error)
            notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
          }
        }
      }

      fetchData()

      return () => {
        source.cancel()
      }
    }
  }, [id])

  const updatePermission = () => {
    axios({
      method: 'PUT',
      url: constants.API_URL + '/permission/' + id,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: permission
    })
      .then(response => {
        if (response.status === 200) {
          let data = response.data;
          notify('success', 'Success', response.data.message);
          console.log('response data :: ', data);
          setTimeout(() => {
            history.push({
              pathname: '/admin/permission'
            });
          }, 1500);
        }
      })
      .catch(error => {
        console.error('error :: ', error);  
        if (error.response.status === 422) {
          let errors = error.response.data.errors;
          setErrors(errors);
          return
        }
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred.');
      })
  }

  const goBack = () => {
    history.push({
      pathname: '/admin/permission'
    });
  }

  return (
    <>
      <CardsHeader name="Admin" parentName="Permission Management" currentName="Edit Permission" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--6 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Permission Management</h1>
                  </Col>
                  <Col xs={4} className="text-right">
                    <ButtonContainer reverse onClick={() => goBack()}>Back to list</ButtonContainer>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Card>
                  <CardHeader>
                    <h2 className="surtitle">Permission Information</h2>
                  </CardHeader>
                  <CardBody>
                    <InputCustom 
                      label={`Name ( lowercase letters, words separated by '-'. Eg: create-users )`}
                      placeholder={`Name`}
                      value={permission? permission.name: ''}
                      onChange={(e) => setPermission({
                        ...permission,
                        name: e.target.value
                      })}
                      invalid={errors && errors.name? true: false}
                      errorMessage={errors.name}
                    />
                    <InputCustom 
                      label={`Display name`}
                      placeholder={`Display name`}
                      value={permission? permission.display_name: ''}
                      onChange={(e) => setPermission({
                        ...permission,
                        display_name: e.target.value
                      })}
                      invalid={errors && errors.display_name? true: false}
                      errorMessage={errors.display_name}
                    />
                    <InputCustom 
                      type={`textarea`}
                      label={`Display name`}
                      placeholder={`Display name`}
                      rows={8}
                      value={permission? permission.description: ''}
                      onChange={(e) => setPermission({
                        ...permission,
                        description: e.target.value
                      })}
                      invalid={errors && errors.description? true: false}
                      errorMessage={errors.description}
                    />
                    <InputToggle 
                      label={`Is superadmin only`}
                      checked={permission && permission.is_superadmin_only === 1 ? true : false}
                      onChange={() =>
                        setPermission({
                          ...permission,
                          is_superadmin_only: permission.is_superadmin_only === 1 ? 0 : 1
                        })
                      }
                    />
                    <div className="d-flex flex-row justify-content-center">
                      <ButtonContainer reverse onClick={() => updatePermission()}>Save</ButtonContainer>
                    </div>
                  </CardBody>
                </Card>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}