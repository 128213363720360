import React, { useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CardsHeader from 'layouts/Headers/CardsHeader.js';

import TableCustom from 'views/pages/components/TableCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Modal,
} from 'reactstrap';

import {
  update,
  deletee,
  hasPermission,
} from 'views/policies/EmailTemplatePolicy';
import * as emailTemplateActions from '../../../../redux/Email/actions';
import usePrevious from '../../../../hooks/useprevious';
import { ClipLoader } from 'react-spinners';
import { css } from '@emotion/core';
import { ButtonContainer } from "@pages/reusable-components/styled-components";
import IconSet from "@assets/selection.json";
import IcoMoon from "react-icomoon";

const override = css`
  margin-right: 10px;
`;

export default function Emails() {
  const history = useHistory();

  const permissions = useSelector((state) => state.getState).permissions;

  /*
   * redux variables
   * */
  const emailTemplateState = useSelector(
    (state) => state.emailTemplateState,
    shallowEqual
  );
  const dispatch = useDispatch();

  const getTemplatesSuccessPrev = usePrevious(
    emailTemplateState.getTemplatesSuccess
  );

  const deleteTemplateSuccessPrev = usePrevious(
    emailTemplateState.deleteTemplateSuccess
  );

  const getTemplateTypesSuccessPrev = usePrevious(emailTemplateState.getTemplateTypesSuccess);

  const [loading, setLoading] = useState(true);

  const [types, setTypes] = useState([]);

  const [deleteModal, setDeleteModal] = useState({
    show: false,
    header: '',
    body: '',
    onClick: null,
  });

  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    dispatch({
      type: emailTemplateActions.GET_EMAIL_TEMPLATES_REQUEST,
      data:{
        mail_type: 'all'
      }
    });
  }, []);

  useEffect(() => {
    if (
      emailTemplateState.getTemplatesSuccess !== getTemplatesSuccessPrev &&
      emailTemplateState.getTemplatesSuccess === true
    ) {
      // setEmailTemplates(emailTemplateState.templates);
      setLoading(false);
    }
  }, [emailTemplateState.getTemplatesSuccess]);

  useEffect(() => {
    if (
      emailTemplateState.deleteTemplateSuccess !== deleteTemplateSuccessPrev &&
      emailTemplateState.deleteTemplateSuccess === true
    ) {
      setDeleteModal({ ...deleteModal, show: false });
      setDeleteLoading(false);
    }
  }, [emailTemplateState.deleteTemplateSuccess]);

  function deleteEmailTemplate(id) {

    dispatch({
      type: emailTemplateActions.DELETE_EMAIL_TEMPLATE_REQUEST,
      id: id,
    });
  }

  function updateTemplate(id) {
    history.push(`/admin/settings/email-templates/${id}/edit`);
  }

  useEffect(()=>{
    dispatch({
      type: emailTemplateActions.GET_TEMPLATE_TYPES_REQUEST
    });
  },[])

  useEffect(() => {
    if (
        emailTemplateState.getTemplateTypesSuccess !== getTemplateTypesSuccessPrev &&
        emailTemplateState.getTemplateTypesSuccess === true &&
        getTemplateTypesSuccessPrev !== undefined
    ) {

      setTypes(emailTemplateState.templateTypes)

    }
  }, [emailTemplateState.getTemplateTypesSuccess]);
  return (
    <>
      <CardsHeader
        name="Settings"
        parentName="Email Templates"
        currentName="List"
      />

      <Container className="mt--5 admin-main-body" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Emails</h1>
                    <p className="text-sm mb-0">
                      You can manage Email templates.
                    </p>
                  </Col>
                  <Col xs={4} className="text-right">
                    <ButtonContainer
                      reverse
                      onClick={() =>
                        history.push('/admin/settings/email-templates/create')
                      }
                    >
                      Add email template
                    </ButtonContainer>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Container className="admin-main-body d-flex flex-wrap" fluid>
                  {
                    types.map(type=>{
                      return  <Col lg={6} className={'mb-4'}  sm="12">
                        <div className={'reports-card-height table-bordered  overflow-auto'}>
                          <CardHeader>
                            <h2 className="title">{type}</h2>
                          </CardHeader>
                          <CardBody >
                            <Col>
                              {
                                emailTemplateState.templates.map(template=>{
                                  if (template.mail_type.includes(type) || (template.mail_type.length === 0 && type === 'Default')) {
                                    return <Row className={'mb-3'}>
                                      <Col>
                                        <h4>
                                          {template.name}
                                        </h4>
                                      </Col>
                                      <Col>
                                        <ButtonContainer
                                            onClick={()=>{
                                              updateTemplate(template.id)
                                            }}>Edit</ButtonContainer>
                                      </Col>
                                      <Col>
                                        <ButtonContainer
                                            onClick={() =>
                                                setDeleteModal({
                                                  ...deleteModal,
                                                  show: true,
                                                  header: 'Delete email template',
                                                  body:
                                                      'Are you sure you want to delete this email template?',
                                                  onClick: () =>
                                                      deleteEmailTemplate(template.id),
                                                })
                                            }
                                        >
                                          Delete
                                        </ButtonContainer>
                                      </Col>
                                    </Row>
                                  }
                                })
                              }
                            </Col>
                          </CardBody>
                        </div>
                      </Col>
                    })
                  }
                </Container>

              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={deleteModal.show}
          toggle={() =>
            setDeleteModal({
              ...deleteModal,
              show: false,
            })
          }
          className="modal-dialog-centered"
        >
          <div className="modal-header  main-color">
            <h6 className="modal-title">{deleteModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() =>
                setDeleteModal({
                  ...deleteModal,
                  show: false,
                })
              }
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {`Are you sure you want to delete this email template?`}
          </div>
          <div className="modal-footer">
            <ButtonContainer
              onClick={() =>
                setDeleteModal({
                  ...deleteModal,
                  show: false,
                })
              }
            >
              {`Close`}
            </ButtonContainer>
            <ButtonContainer
              onClick={() => !deleteLoading && deleteModal.onClick()}
              reverse
              disabled={deleteLoading}
            >
              <ClipLoader
                css={override}
                size={14}
                color={`#ffffff`}
                loading={deleteLoading}
              />
              Delete
            </ButtonContainer>

          </div>
        </Modal>
      </Container>
    </>
  );
}
