import React from 'react';
import { useHistory } from 'react-router-dom';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from 'reactstrap';
import {ButtonContainer} from "@pages/reusable-components/styled-components";

export default function Personalisation() {
  const history = useHistory();

  const gotoSettings = () => {
    history.push({
      pathname: `/admin/settings/personalisation/automated-reminders`
    })
  }

  const gotoNotifications = () => {
    history.push({
      pathname: `/admin/settings/personalisation/notifications`
    })
  }

  const gotoTasks = () => {
    history.push({
      pathname: `/admin/settings/personalisation/tasks`
    })
  }

  return (
    <>
      <CardsHeader name="Settings" parentName="News Management" currentName="List" />
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="4">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <div>
                    <h2 className="title mb-0">Automated Reminders</h2>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <p className=" mb-4 font-weight-600">Set up various roles groups to be notified about automated reminders.</p>
              </CardBody>
              <CardFooter className={'d-flex'}>
                <ButtonContainer reverse  className="w-100" onClick={() => gotoSettings()}>Go to settings</ButtonContainer>
              </CardFooter>
            </Card>
          </Col>
          <Col xl="4">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <div>
                    <h2 className="title mb-0">Notifications</h2>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <p className="mb-5 font-weight-600">Manage notifications each role could receive</p>
              </CardBody>
              <CardFooter className={'d-flex'}>
                <ButtonContainer className="w-100" reverse onClick={() => gotoNotifications()}>Go to Automated Tasks</ButtonContainer>
              </CardFooter>
            </Card>
          </Col>
          <Col xl="4">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <div>
                    <h2 className="title mb-0">Tasks</h2>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <p className="mb-5 font-weight-600">Manage automated tasks by rolls assignment</p>
              </CardBody>
              <CardFooter className={'d-flex'}>
                <ButtonContainer className="w-100" reverse onClick={() => gotoTasks()}>Go to Tasks</ButtonContainer>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}