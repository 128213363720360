import React from 'react'

export default function SortingArrow({order = 'sc',setOrder, color = '#Fafafa'}) {
    return (
        <a onClick={(e) => {
            e.preventDefault();
            setOrder(order === 'asc' ? 'desc' : 'asc')
        }}>
            <i className={`fa ${order === 'asc' ? 'fa-arrow-down' : 'fa-arrow-up'}`}
               style={{ color: color, cursor: "pointer" }}></i>
        </a>
    )
}
