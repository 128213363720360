import React, { useState, useEffect } from 'react';

import InputSideCustom from 'views/pages/components/InputSideCustom';
import {
  InputArraySideSelect
} from 'views/pages/components/InputSelect';

import {
  Container,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col
} from "reactstrap";

import CardsHeader from 'layouts/Headers/CardsHeader.js';

const rent2BuyTerms = [
  {
    value: '13',
    name: '1 Year (12 Rentals)'
  },
  {
    value: '26',
    name: '2 Years (25 Rentals)'
  },
  {
    value: '39',
    name: '3 Years (38 Rentals)'
  },
  {
    value: '52',
    name: '4 Years (51 Rentals) '
  },
  {
    value: '65',
    name: '5 Year (64 Rentals) '
  }
];

const warrantyTypes = [
  {
    value: '0',
    name: 'No Warranty Required'
  },
  {
    value: '300',
    name: '1 Year'
  },
  {
    value: '600',
    name: '2 Year'
  },
  {
    value: '900',
    name: '3 Year'
  },
  {
    value: '1200',
    name: '4 Year'
  },
  {
    value: '1500',
    name: '5 Year'
  }
];

const months = [];
for (let index = 0; index < 64; index++) {
  const element = {
    value: index + 1,
    name: 'Month ' + (index + 1)
  };
  months.push(element);
}

export default function RentalCalculator() {
  const [division, setDivision] = useState(true);

  // Monthly
  const [vehicleCost, setVehicleCost] = useState('');
  const [rfl, setRfl] = useState(0);
  const [warranty, setWarranty] = useState(0);
  const [fundingAmount, setFundingAmount] = useState('');

  const [initialPayment, setInitialPayment] = useState('');
  const [endPayment, setEndPayment] = useState('');
  const [rentalAmount, setRentalAmount] = useState('');
  const [rent2BuyTerm, setRent2BuyTerm] = useState(13);
  const [rentalIncome, setRentalIncome] = useState('');
  const [r2bTerm, setR2bTerm] = useState('');
  const [r2bYear, setR2bYear] = useState('');

  const [profit, setProfit] = useState('');
  const [totalTermRoi, setTotalTermRoi] = useState('');
  const [roi, setRoi] = useState('');

  useEffect(() => {
    // Funding Amount
    let fundingAmountValue = Number(vehicleCost) !== 0 ? Number(vehicleCost) + Number(rfl) + Number(warranty) : 0;
    setFundingAmount(fundingAmountValue);

    let r2bTermValue = rent2BuyTerm ? Number(rent2BuyTerm) - Number(1) : 0;
    setR2bTerm(r2bTermValue);
    let rentalIncomeValue = Number(rentalAmount) * Number(r2bTermValue) + Number(initialPayment) + Number(endPayment);
    setRentalIncome(rentalIncomeValue)

    let profitValue = rentalIncomeValue - fundingAmountValue;
    setProfit(profitValue)
    let r2bYearValue = Math.round(Number(rent2BuyTerm) / Number(13) * Number(100)) / 100;
    setR2bYear(r2bYearValue);

    let roiValue = Math.round(Number(profitValue) / Number(fundingAmountValue) * Number(100) / Number(r2bYearValue) * 100) / 100;
    console.log(roiValue)
    setRoi(roiValue);

    let roiTotalValue = Math.round(Number(roiValue) * Number(r2bYearValue) * Number(100)) / 100;
    setTotalTermRoi(roiTotalValue);
  }, [
    vehicleCost,
    rfl,
    warranty,
    initialPayment,
    endPayment,
    rentalAmount,
    rent2BuyTerm
  ])

  return (
    <>
      <CardsHeader name="Admin" parentName="Rental Calculator" />
      <Container className="mt--6 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <h1 className="title mb-0">Rental calculator</h1>
                <p>Rental simulator</p>
              </CardHeader>
            </Card>
            <Card>
              <CardHeader>
                <h1 className="title mb-0 text-center">{`Rent 2 Buy Calculator`}</h1>
              </CardHeader>
              <CardBody>
                {division && (
                <div className="rent-to-buy-calc-container">
                  <Col lg={4} md={8}>
                    <div className="mb-5">
                      <h2 className="title mb-0">{`Vehicle & Costs`}</h2>
                    </div>
                    <InputSideCustom
                      type={`number`}
                      label={`Vehicle Settlement`}
                      value={vehicleCost}
                      onChange={(e) => setVehicleCost(e.target.value)}
                    />
                    <InputSideCustom
                      type={`number`}
                      label={`RFL Cost for Term`}
                      value={rfl}
                      onChange={(e) => setRfl(e.target.value)}
                    />
                    <InputArraySideSelect
                      label={`Exteneded Warranty Term`}
                      options={warrantyTypes}
                      value={warranty}
                      onChange={(e) => setWarranty(e.target.value)}
                      valueKey={`value`}
                      labelKey={`name`}
                    />
                    <InputSideCustom
                      type={`number`}
                      label={`Total Cost of Vehicle`}
                      disabled={true}
                      value={fundingAmount}
                    />
                  </Col>
                  <Col lg={4} md={8}>
                    <div className="mb-5">
                      <h2 className="title mb-0">{`Rent 2 Buy Quote`}</h2>
                    </div>
                    <InputSideCustom
                      type={`number`}
                      label={`Initial payment`}
                      value={initialPayment}
                      onChange={(e) => setInitialPayment(e.target.value)}
                    />
                    <InputSideCustom
                      type={`number`}
                      label={`End Payment`}
                      value={endPayment}
                      onChange={(e) => setEndPayment(e.target.value)}
                    />
                    <InputSideCustom
                      type={`number`}
                      label={`Rental`}
                      value={rentalAmount}
                      onChange={(e) => setRentalAmount(e.target.value)}
                    />
                    <InputArraySideSelect
                      label={`Rent 2 Buy Term`}
                      options={rent2BuyTerms}
                      value={rent2BuyTerm}
                      onChange={(e) => setRent2BuyTerm(e.target.value)}
                      valueKey={`value`}
                      labelKey={`name`}
                    />
                  </Col>
                  <Col lg={4} md={8}>
                    <div className="mb-5">
                      <h2 className="title mb-0">{`Returns on Investment`}</h2>
                    </div>
                    <InputSideCustom
                      type={`number`}
                      label={`Profit`}
                      disabled={true}
                      value={profit}
                    />
                    <InputSideCustom
                      type={`number`}
                      label={`Total Term ROI %`}
                      disabled={true}
                      value={totalTermRoi}
                    />
                    <InputSideCustom
                      type={`number`}
                      label={` ROI % Per Year`}
                      disabled={true}
                      value={roi}
                    />
                  </Col>
                </div>)}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}