import React, {useState} from 'react';
import CardsHeader from 'layouts/Headers/CardsHeader.js';
import "react-modern-calendar-datepicker/lib/DatePicker.css";

import MasterVehicleList from "@pages/liveVehicles/MasterVehicleList";
import ForSaleVehicleList from "@pages/liveVehicles/ForSaleVehicleList";
import ForRentVehicleList from "@pages/liveVehicles/ForRentVehicleList";
import {LiveVehicleContextProvider} from "@pages/liveVehicles/context";
import {Container} from "reactstrap";

export default function Vehicles() {
     const [activeTab,setActiveTab] = useState('master')
     const [tabs] = useState([
         'master',
         'for-sale',
         'for-rent',
     ]);
    const sheetSelector = () => {
        switch (activeTab) {
            case 'master':
                return (<MasterVehicleList tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />)
            case 'for-sale':
                return (<ForSaleVehicleList tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />)
            case 'for-rent':
                return (<ForRentVehicleList tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />)
            default:
                break;
        }
    }
    return (
       <>
           <LiveVehicleContextProvider type={activeTab}>
               <CardsHeader name="Fleets" parentName="Master GRS Vehicles" currentName="List" />
               <Container className="mt--5 admin-main-body" id="liveVehiclesSection" fluid >
                   {sheetSelector()}
               </Container>
           </LiveVehicleContextProvider>
       </>
    )
}