import React from 'react';
import {Col, Row} from "reactstrap";

export default function VehicleDamageComponent({selectDamage, selectTyre, damages,wheelDamageData}) {
    const checkBorder = (position) => {
        if (damages.hasOwnProperty(position) && damages[position].length > 0) {
            return ' border-red'
        }
        return ''
    }
    const wheelDamageCheck = () => {
        let failed = Object.values(wheelDamageData).find(item=>{
            return item.hasOwnProperty('condition') && item.condition === 'Fail'
        })
        if (failed) {
            return Object.keys(failed)?.length > 0 ? ' border-red' : ''
        }
        return ''

    }
    return (
        <div className={'damage-group'}>
            <Row>
                <Col>
                    <div className={'position-relative'}>
                        <img onClick={() => selectDamage('front')}
                             alt={''}
                             className={'cursor-pointer mb-3' + checkBorder('front')}
                             src={require("assets/images/VehicleInspection/car_front.png")}
                             width={230}
                             height={200}/>
                        {!!checkBorder('front') && <div className={'count-container'}>
                            <span className={'count-style'}>{damages.front.length}</span>
                        </div>}
                    </div>
                    <div className={'position-relative'}>
                        <img onClick={() => selectDamage('left')}
                             alt={''}
                             className={'cursor-pointer' + checkBorder('left')}
                             src={require("assets/images/VehicleInspection/car_left.png")}
                             width={230}
                             height={200}/>
                        {!!checkBorder('left') && <div className={'count-container'}>
                            <span className={'count-style'}>{damages.left.length}</span>
                        </div>}
                    </div>

                </Col>

                <Col>
                    <div className={'position-relative'}>
                        <img onClick={() => selectDamage('top')}
                             alt={''}
                             className={'cursor-pointer mb-3' + checkBorder('top')}
                             src={require("assets/images/VehicleInspection/car_top.png")}
                             width={230}
                             height={200}/>
                        {!!checkBorder('top') && <div className={'count-container'}>
                            <span className={'count-style'}>{damages.top.length}</span>
                        </div>}
                    </div>

                    <div className={'position-relative'}>
                        <img onClick={() => selectDamage('doors')}
                             alt={''}
                             className={'cursor-pointer' + checkBorder('doors')}
                             src={require("assets/images/VehicleInspection/car_doors.png")}
                             width={230}
                             height={200}/>
                        {!!checkBorder('doors') && <div className={'count-container'}>
                            <span className={'count-style'}>{damages.doors.length}</span>
                        </div>}
                    </div>

                </Col>

                <Col>
                    <div className={'position-relative'}>
                        <img onClick={() => selectDamage('back')}
                             alt={''}
                             className={'cursor-pointer mb-3' + checkBorder('back')}
                             src={require("assets/images/VehicleInspection/car_back.png")}
                             width={230}
                             height={200}/>
                        {!!checkBorder('back') && <div className={'count-container'}>
                            <span className={'count-style'}>{damages.back.length}</span>
                        </div>}
                    </div>

                    <div className={'position-relative'}>
                        <img onClick={() => selectDamage('right')}
                             alt={''}
                             className={'cursor-pointer' + checkBorder('right')}
                             src={require("assets/images/VehicleInspection/car_right.png")}
                             width={230}
                             height={200}/>
                        {!!checkBorder('right') && <div className={'count-container'}>
                            <span className={'count-style'}>{damages.right.length}</span>
                        </div>}
                    </div>

                </Col>

                <Col>
                    <div className={'position-relative'}>
                        <img onClick={() => selectDamage('inside')}
                             alt={''}
                             className={'cursor-pointer mb-3' + checkBorder('inside')}
                             src={require("assets/images/VehicleInspection/car_inside.png")}
                             width={230}
                             height={200}/>
                        {!!checkBorder('inside') && <div className={'count-container'}>
                            <span className={'count-style'}>{damages.inside.length}</span>
                        </div>}
                    </div>

                    <div className={'position-relative'}>
                        <img onClick={() => selectTyre('tyre_wear')}
                             alt={''}
                             className={'cursor-pointer mb-3' + wheelDamageCheck()}
                             src={require("assets/images/VehicleInspection/tyre.png")}
                             width={230}
                             height={200}/>
                    </div>

                </Col>

            </Row>
        </div>

    )
}