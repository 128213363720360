import React, { useEffect, useState, useRef } from "react";

import {Button, Modal} from "reactstrap";
import {TextArea} from "semantic-ui-react";
import Input from "react-bootstrap-sweetalert/dist/components/Input";
import InputCustom from "@pages/components/InputCustom";
import {ButtonContainer} from "@pages/reusable-components/styled-components";


export default function GoogleEventModal({setEvent,isOpen = false,setIsOpen}){
    const [eventTitle, setEventTitle] = useState('');
    const [startDate, setStartDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endDate, setEndDate] = useState('');
    const [endTime, setEndTime] = useState('');
    const [eventLocation, setEventLocation] = useState('');
    const [eventDescription, setEventDescription] = useState('');

    const toggleOpen = () => {
        setIsOpen(!isOpen)
    }


    const closeModal = () => {
        setIsOpen(false);
    };

    const handleEventTitleChange = (e) => {
        setEventTitle(e.target.value);
    };

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
    };

    const handleStartTimeChange = (e) => {
        setStartTime(e.target.value);
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
    };

    const handleEndTimeChange = (e) => {
        setEndTime(e.target.value);
    };

    const handleEventLocationChange = (e) => {
        setEventLocation(e.target.value);
    };

    const handleEventDescriptionChange = (e) => {
        setEventDescription(e.target.value);
    };

    const handleCreateEvent = async () => {
        const event = {
            summary: eventTitle,
            location: eventLocation,
            description: eventDescription,
            start: {
                dateTime: `${startDate}T${startTime}:00`,
                timeZone: 'Europe/London',
            },
            end: {
                dateTime: `${endDate}T${endTime}:00`,
                timeZone: 'Europe/London',
            },
        };
          setEvent(event)
    };

    return (
            <Modal isOpen={isOpen} toggle={() => toggleOpen()}>
                <div className="modal-header">
                    <h3 className="modal-title">Add Event</h3>
                </div>
                <div className={'modal-body'}>
                    <InputCustom type="text" value={eventTitle} onChange={handleEventTitleChange} placeholder="Event Title" />
                    <InputCustom type={'date'} placeholder="Event Start Date" value={startDate} onChange={handleStartDateChange} />
                    <InputCustom type={'time'} placeholder="Event Start Time" value={startTime} onChange={handleStartTimeChange} />
                    <InputCustom type={'date'} placeholder="Event End date" value={endDate} onChange={handleEndDateChange} />
                    <InputCustom type={'time'} placeholder="Event End Time" value={endTime} onChange={handleEndTimeChange} />
                    <InputCustom type="text"  value={eventLocation} onChange={handleEventLocationChange} placeholder="Location" />
                    <InputCustom type={'textarea'} value={eventDescription} onChange={handleEventDescriptionChange} placeholder="Description"></InputCustom>
                </div>
                <div className={'modal-footer'}>
                    <ButtonContainer color="danger" onClick={closeModal}>Cancel</ButtonContainer>
                    <ButtonContainer reverse onClick={handleCreateEvent}>Create Event</ButtonContainer>
                </div>

            </Modal>
    );
};