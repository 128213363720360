import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import NotificationAlert from "react-notification-alert";
import Cookies from 'js-cookie';
import axios from 'axios';

import * as constants from 'constants/index.js';

import InputCustom from 'views/pages/components/InputCustom';
import InputFile from 'views/pages/components/InputFile';
import {
  InputKeySelect,
  InputArraySelect,
} from 'views/pages/components/InputSelect';
import TableCustom from 'views/pages/components/TableCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Modal,
  Badge
} from "reactstrap";

import {
  create,
  deletee,
  manageUsers,
  hasPermission,
} from 'views/policies/UserPolicy';

import CardsHeader from 'layouts/Headers/CardsHeader.js';
import {ButtonContainer} from "@pages/reusable-components/styled-components";
import {jobCategories} from "constants/index.js";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";

export default function User() {
  const notificationAlert = useRef(null);
  const history = useHistory();

  const profileData = useSelector((state) => state.getState).profileData;
  const permissions = useSelector((state) => state.getState).permissions;

  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [isNotValid, setIsNotValid] = useState(true);
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);

  const [confirmShow, setConfirmShow] = useState(false);
  const [selectedId, setSelectedId] = useState('');

  const [addModal, setAddModal] = useState(false);
  const [user, setUser] = useState({
    name: '',
    email: '',
    department_id: '',
    is_driver: 0,
    role_id: '',
    photo: '',
    password: '',
    password_confirmation: '',
  });

  const [errors, setErrors] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };
  const fetchData = async (token) => {
    try {
      const userResponse = await axios({
        method: 'GET',
        url: constants.API_CORE_URL + '/user',
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
      if (userResponse.status === 200) {
        const data = userResponse.data.data;
        setUsers(data);
      }
      const roleResponse = await axios({
        method: 'GET',
        url: constants.API_CORE_URL + '/role',
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
      if (roleResponse.status === 200) {
        const data = roleResponse.data.data;
        setRoles(data);
      }
      setTimeout(() => {
        setLoading(false);
      }, 500);
    } catch (e) {
      setLoading(false);
      console.error('error :: ', e);
      notify('warning', 'Warning', e);
    }

  }
  useEffect( () => {
    if (Cookies.get('grs_token')) {
      let grs_token = Cookies.get('grs_token');
      setToken(grs_token);
        fetchData(token)
    }
  }, [])


  const addUser = () => {
    let formData = new FormData();
    formData.append('name', user.name);
    formData.append('email', user.email);
    formData.append('role_id', user.role_id);
    formData.append('is_driver', user.is_driver);
    formData.append('photo', user.photo);
    formData.append('department_id', typeof user.department_id === 'undefined' || ['undefined', null, 'null', '-'].includes(user.department_id) ? '' : user.department_id);
    formData.append('password', user.password);
    formData.append('password_confirmation', user.password_confirmation);

    axios({
      method: 'POST',
      url: constants.API_CORE_URL + '/user',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: formData
    })
      .then(response => {
        setAddModal(false);
        if (response.status === 201) {
          const data = response.data.data;
          setUsers([...users,...[data]]);
          notify('success', 'Success', data.message);

        }
      })
      .catch(error => {
        console.error('error :: ', error);
        if (error.response.status === 422) {
          let errors = error.response.data.errors;
          setErrors(errors);
          return
        }
        setAddModal(false);
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred.');
      })
  }

  const editUser = (row) => {
    history.push({
      pathname: profileData.id === row.id? '/admin/profile': '/admin/user/' + row.id + '/edit'
    });
  }

  const deleteUser = () => {
    axios({
      method: 'DELETE',
      url: constants.API_CORE_URL + '/user/' + selectedId,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(() => {
          setConfirmShow(false);
          let tempItems = users.filter(user=>user.id !== selectedId)
          setUsers(tempItems)
          notify('success', 'Success');
      })
      .catch(error => {
        setConfirmShow(false);
        console.error('error :: ', error);
        notify('error', 'Error', error.response.statusText);
      })
  }

  const handleChange = (files) => {
    setUser({
      ...user,
      photo: files[0]
    });
  }

  const editHoliday = (user) => {
    history.push({
      pathname: `/admin/holiday/${user.id}/user`,
      state: {
        user_name: user.name
      }
    })
  }

  useEffect(()=>{
    if (!user.name || !user.email || !user.role_id) {
      setIsNotValid(true)
    } else setIsNotValid(false)
  },[user])

  return (
    <>
      <CardsHeader name="Admin" parentName="User Management" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--6 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Users</h1>
                    <p className="text-sm mb-0">Users management section.</p>
                  </Col>
                  <Col xs={4} className="text-right">
                    {create(permissions) &&
                    <ButtonContainer onClick={() => setAddModal(true)}>
                      <i className="fa fa-plus" aria-hidden="true"></i>
                      Add user
                    </ButtonContainer>}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <TableCustom
                  loading={loading}
                  keyField={`name`}
                  searchField={true}
                  items={users}
                  columns={[
                    {
                      dataField: "photo",
                      text: "Photo",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return (
                          <span className="avatar avatar-sm rounded-circle">
                            {row.picture ? (<img
                              alt={row.name ? row.name.slice(0, 2) : ''}
                              src={constants.STORAGE_URL + row.picture}
                            />)
                              : (row.name ? row.name.slice(0, 2) : '')}
                          </span>
                        )
                      }
                    },
                    {
                      dataField: "name",
                      text: "Name",
                      sort: true
                    },
                    {
                      dataField: "email",
                      text: "Email",
                      sort: true
                    },
                    {
                      dataField: "is_active",
                      text: "Is active(Login)",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return (
                          <Badge color={row.is_active === 1? 'success': 'dark'}>{row.is_active === 1? `Yes`: 'No'}</Badge>
                        );
                      }
                    },
                    {
                      dataField: "role",
                      text: "Roles",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return(
                          <span>
                            {row.roles && row.roles.length > 0 && row.roles.map((item, index) => {
                              return item.name;
                            })}
                          </span>
                        )
                      }
                    },
                    {
                      dataField: "created_at",
                      text: "Creation date",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.created_at;
                      }
                    },
                    {
                      dataField: "action",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            {manageUsers(permissions) &&
                              <>
                                <IcoMoon
                                    className={'cursor-pointer'}
                                    iconSet={IconSet}
                                    icon={'edit'}
                                    size={24}
                                    onClick={e => editUser(row)}
                                />
                                <IcoMoon
                                    className={'cursor-pointer'}
                                    iconSet={IconSet}
                                    icon={'visibility'}
                                    size={24}
                                    onClick={e => editHoliday(row)}
                                />
                                {deletee(permissions, profileData, row) &&
                                <IcoMoon
                                    className={'cursor-pointer'}
                                    iconSet={IconSet}
                                    icon={'delete_outline'}
                                    size={24}
                                    onClick={e => {
                                      setConfirmShow(true);
                                      setSelectedId(row.id);
                                    }}
                                />
                                }
                              </>
                             }
                          </>
                        )
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={addModal}
          toggle={() => setAddModal(false)}
          size="lg"
          className="modal-dialog-centered modal-secondary"
        >
          <div className="modal-header">
            <h2 className="title">User Information</h2>
          </div>
          <div className="modal-body">
            <Row>
              <Col md={6}>
                <InputCustom
                  newLabel={`Name`}
                  placeholder={`Name`}
                  column={true}
                  required={true}
                  checkValid={false}
                  onChange={(e) => setUser({
                    ...user,
                    name: e.target.value
                  })}
                  invalid={errors && errors.name? true: false}
                  errorMessage={errors.name}
                />
              </Col>
              <Col md={6}>
                <InputCustom
                  newLabel={`Email`}
                  checkEmail
                  required
                  checkValid={false}
                  column
                  placeholder={`Email`}
                  onChange={(e) => setUser({
                    ...user,
                    email: e.target.value
                  })}
                  invalid={errors && errors.email? true: false}
                  errorMessage={errors.email}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <InputArraySelect
                  label={`Role`}
                  required
                  placeholder={`Role`}
                  options={roles}
                  onChange={(e) => setUser({
                    ...user,
                    role_id: e.target.value
                  })}
                  valueKey={`id`}
                  labelKey={`name`}
                  defaultOption={true}
                  invalid={errors && errors.role_id? true: false}
                  errorMessage={errors.role_id}
                />
              </Col>
              <Col md={6}>
                <InputFile
                  label={`Profile photo`}
                  placeholder={`Select file`}
                  onChange={(e) => handleChange(e.target.files)}
                  invalid={errors && errors.photo? true: false}
                  errorMessage={errors.photo}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <InputCustom
                  type={`password`}
                  newLabel={`Password`}
                  column
                  placeholder={`Password`}
                  onChange={(e) => setUser({
                    ...user,
                    password: e.target.value
                  })}
                  invalid={errors && errors.password? true: false}
                  errorMessage={errors.password}
                />
              </Col>
              <Col md={6}>
                <InputCustom
                  type={`password`}
                  newLabel={`Confirm password`}
                  column
                  placeholder={`Confirm password`}
                  onChange={(e) => setUser({
                    ...user,
                    password_confirmation: e.target.value
                  })}
                  invalid={errors && errors.password_confirmation? true: false}
                  errorMessage={errors.password_confirmation}
                />
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <InputKeySelect
                  label={`Job Category`}
                  options={jobCategories}
                  onChange={(e) => setUser({
                    ...user,
                    department_id: e.target.value
                  })}
                  defaultOption={true}
                  invalid={errors && errors.department_id ? true : false}
                  errorMessage={errors.department_id}
                />
              </Col>
              <Col md={6}>
                <div className={'d-flex flex-column'}>
                  <label className="form-control-label">Is driver</label>
                <label className="custom-toggle">
                  <input
                    type="checkbox"
                       onChange={(e) => {
                         setUser({
                    ...user,
                    is_driver: !!user?.is_driver === false ? 1 : 0
                  })
                       }}
                  />
                  <span
                    className="custom-toggle-slider rounded-circle"
                    data-label-off="No"
                    data-label-on="Yes"
                  />
                </label>
                </div>

              </Col>
            </Row>


          </div>
          <div className="modal-footer">
            <ButtonContainer
              reverse
              disabled={isNotValid}
              onClick={() => addUser()}
            >
              Save
            </ButtonContainer>
            <ButtonContainer
              onClick={() => setAddModal(false)}
            >
              Cancel
            </ButtonContainer>
          </div>
        </Modal>
        <Modal
          isOpen={confirmShow}
          toggle={() => setConfirmShow(false)}
          className="modal-dialog-centered modal-secondary"
        >
          <div className="modal-body text-center">
            {`Are you sure you want to delete this user?`}
          </div>
          <div className="modal-footer justify-content-center">
            <ButtonContainer
                onClick={() => setConfirmShow(false)}
            >
              Cancel
            </ButtonContainer>
            <ButtonContainer
              reverse
              onClick={() => deleteUser()}
            >
              {`Delete`}
            </ButtonContainer>

          </div>
        </Modal>
      </Container>
    </>
  )
}