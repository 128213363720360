import React, { useContext } from 'react'
import { LiveVehicleContext } from "./context";

import InputCustom from 'views/pages/components/InputCustom'
import {
  InputKeySelect
} from 'views/pages/components/InputSelect'
import TableCustom from 'views/pages/components/TableCustom'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal
} from 'reactstrap'

import ChangeHandler from './ChangeHandler';

export default function ForSaleVehicleList() {
  const vehicleContext = useContext(LiveVehicleContext);
  const {
    notify,
    history,
    token,
    loading, 
    items, 
    confirmShow, setConfirmShow,
    vehicleStatuses,
    filter, setFilter,
    pagination,
    setPageNumber,
    updateDataChanges,
    fetchData,
    filterVehicle,
    resetVehicle,
    removeItem,
    submitItem,
    setRefererUrl
  } = vehicleContext;

  return (
    <>

      <Row>
        <Col xl="12">
          <ChangeHandler notify={notify} updateData={(vehicle) => updateDataChanges(vehicle)} />
        </Col>
        <Col xl="12">
          <Card>
            <CardHeader>
              <Row>
                <Col xs={8}>
                  <h1 className="mb-0">Vehicles For Delivery</h1>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Container>
                <Row className="m-2 justify-content-md-center">
                  <Col md={6} sm={12} xs={12}>
                    <InputCustom
                      label={`Search`}
                      value={filter && filter.reg_no ? filter.reg_no : ''}
                      onChange={e => setFilter({
                        ...filter,
                        reg_no: e.target.value
                      })}
                    />
                  </Col>
                  <Col md={6} sm={12}>
                    <InputKeySelect
                      newLabel={'Vehicle Status'}
                      options={vehicleStatuses}
                      value={filter.vehicle_status ?? ''}
                      onChange={(e) => {
                        setFilter({
                          ...filter,
                          vehicle_status: e.target.value
                        })
                      }}
                      defaultOption={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={12}>
                    <Button className="w-100" color="success" style={{ margin: 5 }} type="button" onClick={() => filterVehicle()}>Filter</Button>
                  </Col>
                  <Col md={6} sm={12}>
                    <Button className="w-100" color="warning" style={{ margin: 5 }} type="button" onClick={() => resetVehicle()}>Reset</Button>
                  </Col>
                </Row>
              </Container>
              {items.length > 0 && <Row>
                <Col xs={8}>
                  <h2 className="mb-0">Number of vehicles: {items.length}</h2>
                </Col>
              </Row>
              }
              <TableCustom
                loading={loading}
                items={items}
                // stickyContent={(
                //   !loading && items && items.length > 0 && <Row>
                //     <Col sm={12}>
                //       <Button className="w-100" color="success" style={{ margin: 5 }} type="button" onClick={() => submitItem()}>Save</Button>
                //     </Col>
                //   </Row>
                // )}
                pagination={pagination}
                classes={'custom-table-striped'}
                onChange={pageNumber => {
                  setPageNumber(pageNumber)
                  fetchData(pageNumber, token, filter)
                }
                }
                columns={[
                  {
                    dataField: "vehicle_status",
                    text: "Vehicle Status",
                    formatter: (rowContent, row) => {
                      let jobsInProgress = Number(row.in_progress_jobs);
                      let totalJobs = Number(row.total_jobs);
                      let label = jobsInProgress > 0 ? `Needs work` : (totalJobs > 0 ? 'Completed' : "No work recorded");
                      return <a href={`/admin/vehicle-details?id=${row.fleet_id}`} target='_blank'>{label}</a>;
                    }
                  },
                  {
                    dataField: "deal_id",
                    text: "Deal",
                    formatter: (rowContent, row) => {
                      return row.deal_id ? <a className="text-center" href={`/admin/deals/${row.deal_id}/edit`} target='_blank'>#{row.deal_id}</a> : '-';
                    }
                  },
                  {
                    dataField: "stock_no",
                    text: "Stock No",
                    style: {
                      "fontWeight": 800
                    },
                    formatter: (rowContent, row) => {
                      return `${row.stock_no}`
                    }
                  },
                  {
                    dataField: "vehicle",
                    text: "Vehicle",
                    formatter: (rowContent, row) => {
                      let vehicle = `${row.vehicle}, ${row.derivative}`;
                      return <a href={`/admin/vehicle/${row.vehicle_id}/edit`} target='_blank'>{vehicle.length > 35 ? `${vehicle.substring(0, 35)}...` : vehicle}</a>;
                    }
                  },
                  {
                    dataField: "color",
                    text: "Color",
                    formatter: (rowContent, row) => {
                      return `${row.colour}`
                    }
                  },
                  {
                    dataField: "current_mileage",
                    text: "Mileage"
                  },
                  {
                    dataField: "",
                    text: "Action",
                    formatter: (rowContent, row) => {
                      return (
                        <div className="flex-no-wrap">
                          <Button color="success" type="button" onClick={() => {
                            setRefererUrl('delivery');
                            history.push({
                              pathname: `/admin/live-vehicle/${row.live_id}/edit/fleet`
                            })
                          }}>View</Button>
                        </div>
                      )
                    }
                  }
                ]}
              />
              {!loading && items && items.length > 0 && <Row>
                <Col sm={12}>
                  <Button className="w-100" color="success" style={{ margin: 5 }} type="button" onClick={() => submitItem()}>Save</Button>
                </Col>
              </Row>}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal
        isOpen={confirmShow}
        toggle={() => setConfirmShow(false)}
        className="modal-dialog-centered modal-secondary"
      >
        <div className="modal-body">
          Are you sure you want to remove this vehicle from the list?
        </div>
        <div className="modal-footer">
          <Button
            className="new-event--add"
            color={'primary'}
            type="button"
            onClick={() => removeItem()}
          >
            Delete permanently
          </Button>
          <Button
            className="ml-auto"
            color="link"
            type="button"
            onClick={() => setConfirmShow(false)}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  )
}