import React, {useRef} from 'react';

import {
  FormGroup,
} from "reactstrap";

export default function InputFile({
  label,
  placeholder,
  accept,
  onChange,
  multiple,
  disabled,
  readOnly,
  invalid,
  errorMessage
}) {

  const ref = useRef()
  const selectFile = () => {
      ref.current.click()
  }
  return (
    <FormGroup className={'d-flex align-items-center'}>
      <label
        className="form-control-label w-50"
      >
        {label}
      </label>
      <div className="custom-file">
        <input
          className="custom-file-input profile-file-input"
          style={{width: '1px',height: '1px'}}
          ref={ref}
          lang="en"
          type="file"
          accept={accept}
          multiple={multiple}
          disabled={disabled}
          readOnly={readOnly}
          onChange={onChange}
        />
        <label
           onClick={selectFile}
           className="custom-file-label cursor-pointer"
        >
          {placeholder}
        </label>
      </div>
      <div className="invalid-feedback" style={{ display: invalid ? 'block' : 'none' }}>
        {errorMessage}
      </div>
    </FormGroup>
  )
}