import { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Modal, Row } from 'reactstrap';
import InputCustom from '../components/InputCustom';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { shallowEqual, useSelector } from 'react-redux';
import HistoryAndComments from './HistoryAndComments';
import useNotify from '../../../hooks/notify';
import { ButtonContainer } from "@pages/reusable-components/styled-components";
import CustomDisabledText from "@pages/components/CustomDisabledText";

function Details({
  tasks,
  taskId,
  isVisible,
  toggleModal,
  titleEditMode,
  setEditMode,
  inputTitleEl,
  updateTask,
  taskEdit,
}) {
  const {
    isUpdateTaskError,
    updateTaskErrorMessage,
    createCommentError,
    createCommentErrorMessage,
    deleteCommentError,
    deleteCommentErrorMessage,
    updateCommentError,
    updateCommentErrorMessage,
  } = useSelector((state) => state.taskState, shallowEqual);

  const [task, setTask] = useState({});
  const [title, setTitle] = useState('');
  const { notify } = useNotify();

  useEffect(() => {
    const selectedTask = tasks.find(({ id }) => id === parseInt(taskId));
    setTask(selectedTask);
  }, [taskId, tasks]);
  useEffect(() => {
    setTitle(task && task.title ? task.title : '');
  }, [task]);

  useEffect(() => {
    if (isUpdateTaskError) {
      notify(updateTaskErrorMessage, 'Warning', 'warning');
    }
  }, [isUpdateTaskError]);

  useEffect(() => {
    if (createCommentError) {
      notify(createCommentErrorMessage, 'Warning', 'warning');
    }
  }, [createCommentError]);

  useEffect(() => {
    if (deleteCommentError) {
      notify(deleteCommentErrorMessage, 'Warning', 'warning');
    }
  }, [deleteCommentError]);

  useEffect(() => {
    if (updateCommentError) {
      notify(updateCommentErrorMessage, 'Warning', 'warning');
    }
  }, [updateCommentError]);

  return (
    <Modal
      isOpen={isVisible}
      toggle={() => toggleModal(false)}
      className="modal-dialog-centered"
      size="xl"
    >

      <div className="modal-body border">
        <Row className={'d-flex'}>
          <Col md={3} sm={12}>
            {['deal', 'App\\Models\\Customer'].includes(task.taskable_type) ?
              <Card>
                <CardHeader>
                  <h3>Customer Details</h3>
                </CardHeader>
                <CardBody>
                  <InputCustom value={task?.taskModel?.full_name} disabled newLabel={'Name'} />
                  <InputCustom value={task?.taskModel?.email} disabled newLabel={'Email'} />
                  <InputCustom value={task?.taskModel?.phone_number} disabled newLabel={'Phone'} />
                  <div className={'d-flex align-items-center'}>
                    <ButtonContainer onClick={(e) => {
                      e.preventDefault();
                      taskEdit(task);
                    }}>View Resource</ButtonContainer>
                  </div>
                </CardBody>
              </Card>
              : null}
            {['App\\Models\\Vehicle'].includes(task.taskable_type) ?
              <Card>
                <CardHeader>
                  <h3>Vehicle Details</h3>
                </CardHeader>
                <CardBody>
                  <InputCustom value={task?.taskModel?.id} disabled newLabel={'Vehicle'} />
                  <InputCustom value={task?.taskModel?.stock_no} disabled newLabel={'Reg. No'} />
                  <div className={'d-flex align-items-center'}>
                    <ButtonContainer onClick={(e) => {
                      e.preventDefault();
                      taskEdit(task);
                    }}>View Vehicle</ButtonContainer>
                  </div>
                </CardBody>
              </Card>
              : null}
          </Col>
          <Col md={9} sm={12}>
            <Card>
              <CardHeader>
                <h3>Task Info</h3>
              </CardHeader>
              <CardBody>
                {
                  titleEditMode ?
                    <InputCustom
                      value={title}
                      innerRef={inputTitleEl}
                      onBlur={(e) => {
                        setEditMode('title', false);
                        updateTask({
                          id: task.id,
                          title: e.target.value,
                        });
                      }}
                      newLabel={'Task Title:'}
                    /> :
                    <CustomDisabledText
                      newLabel={'Task Title:'}
                      value={title}
                      onClick={() => setEditMode('title', true)}
                      className={'grey-hover px-2'}
                    />
                }
                <InputCustom
                  type={`date`}
                  value={
                    task.due_date
                      ? moment(task.due_date, "DD/MM/YYYY").format('YYYY-MM-DD')
                      : ''
                  }
                  onChange={(e) =>
                    updateTask({
                      id: task.id,
                      due_date: e.target.value,
                    })
                  }
                  newLabel={'Due Completed:'}
                />
                <HistoryAndComments task={task} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

    </Modal>
  );
}
export default Details;

Details.propTypes = {
  tasks: PropTypes.array,
  customDealerMembers: PropTypes.array,
  descriptionEditMode: PropTypes.bool,
  header: PropTypes.string,
  isVisible: PropTypes.bool,
  setEditMode: PropTypes.func,
  statuses: PropTypes.object,
  taskId: PropTypes.string,
  titleEditMode: PropTypes.bool,
  toggleModal: PropTypes.func,
  updateTask: PropTypes.func,
  inputDescriptionEl: PropTypes.object,
  inputTitleEl: PropTypes.object,
  taskEdit: PropTypes.func,
};