import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from "js-cookie";
import Select2 from "react-select2-wrapper";

import actions from 'actions';
import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import InputCustom from 'views/pages/components/InputCustom';
import {
  KeySelect
} from 'views/pages/components/InputSelect';
import TableCustom from 'views/pages/components/TableCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Modal,
} from "reactstrap";
import {ButtonContainer} from "@pages/reusable-components/styled-components";
import CustomLabel from "@pages/components/CustomLabel";
import {validateForm} from "constants/index.js";

export default function TaskEdit() {
  const { dealId, taskId } = useParams();
  const notificationAlert = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isNotValid, setIsNotValid] = useState(true);
  const [token, setToken] = useState('');

  const [deal, setDeal] = useState('');
  const [dealTaskStatuses, setDealTaskStatuses] = useState('');
  const [dealerMembers, setDealerMembers] = useState([]);
  const [task, setTask] = useState({
    logs: [],
    task_comments: [],
  });
  const [taskErrors, setTaskErrors] = useState('');
  const [tab, setTab] = useState('history');
  const [commentModal, setCommentModal] = useState({
    show: false,
    header: '',
    division: '',
  })
  const [comment, setComment] = useState('')
  const [confirmModal, setConfirmModal] = useState({
    show: false,
  })

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/deal/${dealId}/task/${taskId}`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data

        setDeal(data.data.deal);
        setDealTaskStatuses(data.data.dealTaskStatuses);

        let dealer_members = data.data.dealerMembers;
        dealer_members.forEach(dealer => {
          dealer.text = dealer.name;
        });
        setDealerMembers(dealer_members);

        let item = data.data.item;
        let assignee = [];
        if (item.task_assignee && item.task_assignee.length > 0) {
          item.task_assignee.forEach((val, index) => {
            assignee.push(val.id);
          })
        }
        setTask({
          ...item,
          task_assignee: assignee,
          due_date: item.due_date ? constants.formatDate(item.due_date) : '',
        });
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const goDeal = () => {
    history.push({
      pathname: '/admin/deals/' + dealId + '/edit',
    })
  }

  const goCustomer = () => {
    history.push({
      pathname: '/admin/customer/' + deal.customer_id + '/edit',
    })
  }


  const formatDateFull = (d) => {
    if (d) {
      let date = new Date(d);

      let options = {
        year: 'numeric', month: '2-digit', day: '2-digit',
        hour: 'numeric', minute: 'numeric', second: '2-digit',
        hour12: false
      }

      let da = Intl.DateTimeFormat('en-BG', options).format(date);

      return da
    }
  }

  const updateTask = () => {
    axios({
      method: 'PUT',
      url: constants.API_URL + '/deal/' + dealId + '/task/' + taskId,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        ...task,
        task_title: task.title,
        task_comments: task.comments,
        task_status: task.status,
        task_due_date: constants.formatDate(task.due_date),
      },
    })
      .then(response => {
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);

          let item = data.data;
          let assignee = [];
          if (item.task_assignee && item.task_assignee.length > 0) {
            item.task_assignee.forEach((val) => {
              assignee.push(val.id);
            })
          }
          setTask({
            ...item,
            task_assignee: assignee,
            due_date: item.due_date? constants.formatDate(item.due_date): '',
          });
          dispatch(actions.getNotifications(token));
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setTaskErrors('');
        console.error('error :: ', error.response);
        if (error.response && error.response.status === 422) {
          setTaskErrors(error.response.data.errors)
          return
        }
        notify('error', 'Error', error.response && error.response.statusText? error.response.statusText: 'Error occur.');
      })
  }

  const newComment = () => {
    setCommentModal({
      ...commentModal,
      show: true,
      header: "Create comment",
      division: 'create',
    })
  }

  const createComment = () => {
    if (comment?.comment === '' || comment?.comment === undefined) {
      return
    }
    axios({
      method: 'POST',
      url: `${constants.API_URL}/deal/${dealId}/task/${taskId}/comment`,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        comment: comment?.comment || ''
      },
    })
      .then(response => {
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setTask(data.data)
        }
        setCommentModal({
          ...commentModal,
          show: false
        })
      })
      .catch(error => {
        setTaskErrors('');
        console.error('error :: ', error.response);
        if (error.response && error.response.status === 422) {
          setTaskErrors(error.response.data.errors)
          return
        }
        notify('error', 'Error', error.response && error.response.statusText? error.response.statusText: 'Error occur.');
      })
  }

  const updateComment = () => {
    if (comment?.comment === '' || comment?.comment === undefined) {
      return
    }
    axios({
      method: 'PUT',
      url: `${constants.API_URL}/deal/${dealId}/task/${taskId}/comment/${comment.id}`,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        comment: comment?.comment || ''
      },
    })
      .then(response => {
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setTask(data.data)
        }
        setCommentModal({
          ...commentModal,
          show: false
        })
      })
      .catch(error => {
        setTaskErrors('');
        console.error('error :: ', error.response);
        if (error.response && error.response.status === 422) {
          setTaskErrors(error.response.data.errors)
          return
        }
        notify('error', 'Error', error.response && error.response.statusText? error.response.statusText: 'Error occur.');
      })
  }

  const deleteComment = () => {
    axios({
      method: 'DELETE',
      url: `${constants.API_URL}/deal/${dealId}/task/${taskId}/comment/${comment.id}`,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then(response => {
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setTask(data.data)
        }
        setConfirmModal({
          ...confirmModal,
          show: false
        })
      })
      .catch(error => {
        setTaskErrors('');
        console.error('error :: ', error.response);
        if (error.response && error.response.status === 422) {
          setTaskErrors(error.response.data.errors)
          return
        }
        notify('error', 'Error', error.response && error.response.statusText? error.response.statusText: 'Error occur.');
      })
  }

  const editComment = (comment) => {
    setComment(comment)
    setCommentModal({
      ...commentModal,
      show: true,
      header: 'Edit comment',
      division: 'update'
    })
  }
   useEffect(()=>{
     const required = ['title','status','task_assignee']
     setIsNotValid(!validateForm(required,task));
   },[task])
  return (
    <>
      <CardsHeader
        name="Deal Management"
        parentName="Deals Management"
        currentName="List"
      />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid>
        <Row>
          <Col md={4} sm={12}>
            <Card>
              <CardHeader>
                <h2 className="title mb-0 main-color">Customer details</h2>
              </CardHeader>
              <CardBody>
                <Row className="py-3 align-items-center">
                  <Col md={4} sm={5}>
                    <small className="text-uppercase font-weight-bold">{`Name`}</small>
                  </Col>
                  <Col md={8} sm={7}>
                    <h3 className="heading mb-0">{deal.full_name}</h3>
                  </Col>
                </Row>
                <Row className="py-3 align-items-center">
                  <Col md={4} sm={5}>
                    <small className="text-uppercase font-weight-bold">{`Email`}</small>
                  </Col>
                  <Col md={8} sm={7}>
                    <h3 className="heading mb-0">{deal.email}</h3>
                  </Col>
                </Row>
                <Row className="py-3 align-items-center">
                  <Col md={4} sm={5}>
                    <small className="text-uppercase font-weight-bold">{`Phone number`}</small>
                  </Col>
                  <Col md={8} sm={7}>
                    <h3 className="heading mb-0">{deal.mobile_number}</h3>
                  </Col>
                </Row>
                <Row className="py-3 align-items-center">
                  <Col md={4} sm={5}>
                    <small className="text-uppercase font-weight-bold">{`More details`}</small>
                  </Col>
                  <Col md={8} sm={7}>
                    <Button
                      className="h-50"
                      color="white"
                      type="button"
                      onClick={() => goCustomer()}
                    >
                      Customer
                    </Button>
                  </Col>
                </Row>
              </CardBody>
              <CardHeader>Vehicle details</CardHeader>
              <CardBody>
                <Row className="py-3 align-items-center">
                  <Col md={4} sm={5}>
                    <small className="text-uppercase font-weight-bold">{`Reg no`}</small>
                  </Col>
                  <Col md={8} sm={7}>
                    <h3 className="heading mb-0">{deal.stock_no}</h3>
                  </Col>
                </Row>
                <Row className="py-3 align-items-center">
                  <Col md={4} sm={5}>
                    <small className="text-uppercase font-weight-bold">{`Deal details`}</small>
                  </Col>
                  <Col md={8} sm={7}>
                    <Button
                      className="h-50"
                      color="white"
                      type="button"
                      onClick={() => goDeal()}
                    >
                      Deal
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md={8} sm={12}>
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <div>
                    <h2 className="title mb-0 main-color">Task Management</h2>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={12}>
                    <h2 className="font-weight-600 mb-4">{`Vehicle make information`}</h2>
                    <InputCustom
                      newLabel={`Task title`}
                      required
                      checkValid={false}
                      value={task && task.title ? task.title : ""}
                      onChange={(e) =>
                        setTask({
                          ...task,
                          title: e.target.value,
                        })
                      }
                      invalid={
                        taskErrors && taskErrors.task_title ? true : false
                      }
                      errorMessage={
                        taskErrors && taskErrors.task_title
                          ? taskErrors.task_title
                          : ""
                      }
                    />
                    <InputCustom
                      type={`textarea`}
                      newLabel={`Description`}
                      rows={5}
                      value={task && task.comments ? task.comments : ""}
                      onChange={(e) =>
                        setTask({
                          ...task,
                          comments: e.target.value,
                        })
                      }
                    />
                    <KeySelect
                      label={`Task deal status`}
                      required
                      value={task && task.status ? task.status : ""}
                      options={dealTaskStatuses}
                      onChange={(e) =>
                        setTask({
                          ...task,
                          status: e.target.value,
                        })
                      }
                      defaultOption={true}
                      invalid={
                        taskErrors && taskErrors.task_status ? true : false
                      }
                      errorMessage={
                        taskErrors && taskErrors.task_status
                          ? taskErrors.task_status
                          : ""
                      }
                    />
                    <FormGroup className={'d-flex align-items-center'}>
                      <CustomLabel defaultLabel required text={'Assign to'} className="form-control-label w-50"/>
                     <div className={'w-100'}>
                       <Select2
                           className="form-control"
                           multiple
                           value={
                             task && task.task_assignee ? task.task_assignee : ""
                           }
                           data={dealerMembers}
                           onChange={(e) =>
                               setTask({
                                 ...task,
                                 task_assignee: Array.from(
                                     e.target.selectedOptions,
                                     (option) => option.value
                                 ),
                               })
                           }
                       />
                       {taskErrors && taskErrors.task_assignee && (
                           <div
                               className="invalid-feedback"
                               style={{ display: "block" }}
                           >
                             {taskErrors.task_assignee}
                           </div>
                       )}
                     </div>
                    </FormGroup>
                    <InputCustom
                      type={`date`}
                      newLabel={`Due date`}
                      value={task && task.due_date ? task.due_date : ""}
                      onChange={(e) =>
                        setTask({
                          ...task,
                          due_date: e.target.value,
                        })
                      }
                      invalid={
                        taskErrors && taskErrors.task_due_date ? true : false
                      }
                      errorMessage={
                        taskErrors && taskErrors.task_due_date
                          ? taskErrors.task_due_date
                          : ""
                      }
                    />
                    <div className="d-flex flex-row justify-content-center mb-4">
                      <Button className="w-25" color="lighte" type="button" onClick={() => {
                        goDeal(false)
                      }}>Cancel</Button>
                      <Button disabled={isNotValid} className="w-25" color="warning" onClick={() => updateTask()}>Save</Button>

                    </div>
                    <Row>
                      <Col md={12} sm={12}>
                        <span
                          className={`cursor-pointer col-md-2 col-sm-12 deal-tab `}
                          style={{
                            color: '#16bb0b',
                            margin: 5,
                          }}
                          onClick={() => setTab("history")}
                        >
                          History
                        </span>
                        <span
                          className={`cursor-pointer col-md-2 col-sm-12 deal-tab`}
                          style={{
                            color: '#2246da',
                            margin: 5,
                          }}
                          onClick={() => setTab("comments")}
                        >
                          Comments
                        </span>
                      </Col>
                    </Row>
                    {tab === "history" && (
                      <TableCustom
                        loading={false}
                        items={task.logs}
                        keyField={`id`}
                        searchField={false}
                        columns={[
                          {
                            dataField: "owner",
                            text: "Author",
                            sort: true,
                            formatter: (rowContent, row) => {
                              return row.owner.name;
                            },
                          },
                          {
                            dataField: "notification",
                            text: "Notification",
                            sort: true,
                          },
                          {
                            dataField: "created_at",
                            text: "CreatedAt",
                            formatter: (rowContent, row) => {
                              return formatDateFull(row.created_at);
                            },
                          },
                        ]}
                      />
                    )}
                    {tab === 'comments' &&
                      <>
                        <Row>
                          <Col md={8}>
                          </Col>
                          <Col md={4}>
                            <ButtonContainer onClick={() => newComment()}>New comment</ButtonContainer>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            {
                              task.task_comments.length > 0 && task.task_comments.map((comment, index) => {
                                return (
                                  <div key={index} style={{marginTop: 20}}>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                      <span className="avatar avatar-sm rounded-circle">
                                        {comment?.owner?.picture ? (<img
                                          alt={comment?.owner?.name ? comment?.owner?.name.slice(0, 2) : ''}
                                          src={constants.STORAGE_URL + comment?.owner?.picture}
                                        />)
                                          : (comment?.owner?.name ? comment?.owner?.name.slice(0, 2) : '')}
                                      </span>
                                      <span style={{
                                        marginLeft: 10,
                                        fontWeight: "bold",
                                      }}>
                                        {comment?.owner?.name}
                                      </span>
                                      <span style={{
                                        marginLeft: 10,
                                      }}>
                                        {constants.formatDateFullGB(comment?.updated_at)}
                                      </span>
                                    </div>
                                    <div style={{marginLeft: 45}}>
                                      {comment.comment}
                                    </div>
                                    <div style={{marginLeft: 45}}>
                                      <span className="edit-comment" onClick={() => editComment(comment)}>Edit</span>
                                      <span className="edit-comment" style={{marginLeft: 10}} onClick={() => {                                        
                                        setComment(comment)
                                        setConfirmModal({
                                          ...confirmModal,
                                          show: true,
                                        })
                                      }}>Delete</span>
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </Col>
                        </Row>
                      </>
                    }
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={commentModal.show}
          toggle={() => setCommentModal({
            ...commentModal,
            show: false,
          })}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title main-color">{commentModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setCommentModal({
                ...commentModal,
                show: false,
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <InputCustom 
              newLabel={`Comment`}
              value={comment && comment.comment ? comment.comment : ''}
              onChange={e => {
                setComment({
                  ...comment,
                  comment: e.target.value
                })
              }}
            />
          </div>
          <div className="modal-footer">
            <Button className="w-25" color="lighte" type="button" o onClick={() => setCommentModal({
              ...commentModal,
              show: false,
            })}>Cancel</Button>
            <Button className="w-25" color="warning"  onClick={() => commentModal.division === 'create'?
                createComment() : updateComment()}>Save</Button>
          </div>
        </Modal>
        <Modal
          isOpen={confirmModal.show}
          toggle={() => setConfirmModal({
            ...confirmModal,
            show: false,
          })}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title">{`Delete comment`}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setConfirmModal({
                ...confirmModal,
                show: false,
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {`Are you sure you want to delete this comment?`}
          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color="danger"
              type="button"
              onClick={() => deleteComment()}
            >
              {`Delete`}
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setConfirmModal({
                ...confirmModal,
                show: false,
              })}
            >
              {`Close`}
            </Button>
          </div>
        </Modal>
      </Container>
    </>
  );
}
