import React, { useContext } from 'react';
import { LeadsContext } from '../context';

import {
  FormGroup,
  Row,
  Col,
} from "reactstrap";
import {ButtonContainer} from "@pages/reusable-components/styled-components";

export default function DetailsTab() {
  const leadsContext = useContext(LeadsContext);
  const {
    selectedQuote, setSelectedQuote,
    CreatableSelect,
    quoteTypes,
    quoteErrors,
    InputCustom,
    InputArraySelect,
    makes,
    models, setModels,
    makesAndModels,
    addQuote,
  } = leadsContext;

  return (
      <>
          <Col  lg={5} md={5} sm={12} className={'mb-3'}>
              <h3>Add Quote:</h3>
              <Row>
                  <Col>
                      <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-50">Quote Type</label>
                           <CreatableSelect
                              isClearable
                              className={'w-50'}
                              onChange={(item) => {
                                  setSelectedQuote({
                                      ...selectedQuote,
                                      lead_quote_type_id: item ? item.value : null,
                                  })
                              }}
                              options={quoteTypes}
                          />
                          {quoteErrors && quoteErrors.lead_quote_type_id && <div className="invalid-feedback" style={{ display: 'block' }}>
                              {quoteErrors.lead_quote_type_id}
                          </div>}
                      </FormGroup>

                      <InputArraySelect
                          newLabel={`Makes`}
                          value={selectedQuote && selectedQuote.make ? selectedQuote.make : ''}
                          options={makes}
                          onChange={(e) => {
                              setModels(e.target.value !== '-' ? makesAndModels[e.target.value] : [])
                              setSelectedQuote({
                                  ...selectedQuote,
                                  make: e.target.value,
                              })
                          }}
                          valueKey={`id`}
                          labelKey={`name`}
                          defaultOption={true}
                      />
                      <InputArraySelect
                          newLabel={`Model`}
                          value={selectedQuote && selectedQuote.vehicle_model_id ? selectedQuote.vehicle_model_id : ''}
                          options={models}
                          onChange={(e) => setSelectedQuote({
                              ...selectedQuote,
                              vehicle_model_id: e.target.value,
                          })}
                          valueKey={`id`}
                          labelKey={`name`}
                          invalid={quoteErrors && quoteErrors.vehicle_model_id ? true : false}
                          errorMessage={quoteErrors.vehicle_model_id}
                      />
                      <InputCustom
                          newLabel={`Derivative`}
                          value={selectedQuote && selectedQuote.derivative ? selectedQuote.derivative : ''}
                          onChange={(e) => setSelectedQuote({
                              ...selectedQuote,
                              derivative: e.target.value,
                          })}
                          invalid={quoteErrors && quoteErrors.derivative ? true : false}
                          errorMessage={quoteErrors.derivative}
                      />
                  </Col>
                  <Col>
                      <InputCustom
                          type={`number`}
                          newLabel={`Term`}
                          value={selectedQuote && selectedQuote.term ? selectedQuote.term : ''}
                          onChange={(e) => setSelectedQuote({
                              ...selectedQuote,
                              term: e.target.value,
                          })}
                          invalid={quoteErrors && quoteErrors.term ? true : false}
                          errorMessage={quoteErrors.term}
                      />

                      <InputCustom
                          type={`number`}
                          newLabel={`Initial Payment`}
                          value={selectedQuote && selectedQuote.initial_payment ? selectedQuote.initial_payment : ''}
                          onChange={(e) => setSelectedQuote({
                              ...selectedQuote,
                              initial_payment: e.target.value,
                          })}
                          invalid={quoteErrors && quoteErrors.initial_payment ? true : false}
                          errorMessage={quoteErrors.initial_payment}
                      />
                      <InputCustom
                          type={`number`}
                          newLabel={`Rental`}
                          value={selectedQuote && selectedQuote.rental_payment ? selectedQuote.rental_payment : ''}
                          onChange={(e) => setSelectedQuote({
                              ...selectedQuote,
                              rental_payment: e.target.value,
                          })}
                          invalid={quoteErrors && quoteErrors.rental_payment ? true : false}
                          errorMessage={quoteErrors.rental_payment}
                      />
                      <InputCustom
                          type={`number`}
                          newLabel={`End Payment`}
                          value={selectedQuote && selectedQuote.option_to_purchase ? selectedQuote.option_to_purchase : ''}
                          onChange={(e) => setSelectedQuote({
                              ...selectedQuote,
                              option_to_purchase: e.target.value,
                          })}
                          invalid={quoteErrors && quoteErrors.option_to_purchase ? true : false}
                          errorMessage={quoteErrors.option_to_purchase}
                      />

                  </Col>
              </Row>
              <Col>
                  <Row className={'align-items-center'}>
                      <Col md={6} sm={12}>
                          <InputCustom
                              type={`textarea`}
                              newLabel={`Notes`}
                              rows={5}
                              value={selectedQuote && selectedQuote.extras ? selectedQuote.extras : ''}
                              onChange={(e) => setSelectedQuote({
                                  ...selectedQuote,
                                  extras: e.target.value,
                              })}
                          />
                      </Col>
                      <Col  md={6} sm={12}>
                          <InputCustom
                              type={`number`}
                              newLabel={`28 day mileage allowance`}
                              value={selectedQuote && selectedQuote.annual_mileage ? selectedQuote.annual_mileage : ''}
                              onChange={(e) => setSelectedQuote({
                                  ...selectedQuote,
                                  annual_mileage: e.target.value,
                              })}
                              invalid={quoteErrors && quoteErrors.annual_mileage ? true : false}
                              errorMessage={quoteErrors.annual_mileage}
                          />
                      </Col>
                  </Row>
                  <Col md={6} sm={12}>
                      <div>
                          <ButtonContainer
                              reverse
                              onClick={() => addQuote()}
                          >
                              {`Save quote`}
                          </ButtonContainer>
                      </div>
                  </Col>
              </Col>
          </Col>
      </>

  );
}