import React from 'react';
import { Col, Row } from "reactstrap";
import { parseToWord } from "../../../constants";
import Select from "react-select";

export default function CustomTabs({ fullWidth = false, tabs, activeTab, setActiveTab }) {

    return (
        <>
            {window.innerWidth > 833 ? <div className={'d-flex mt-3'}>
                <Row className={fullWidth ? 'w-100' : ''}>
                    {tabs.map((tab, index) => {
                        const tabName = typeof tab === 'object' ? tab.name : tab;
                        const tabSlug = typeof tab === 'object' ? tab.slug : tab;
                        return <Col style={{ flexBasis: "content" }} key={index}>
                            <div onClick={() => setActiveTab(tabSlug)}
                                className={'d-flex align-items-center ' +
                                    'justify-content-center cursor-pointer ' +
                                    'payment-tab ' + (activeTab === tabSlug ? 'active' : '')}>
                                <span>{parseToWord(tabName)}</span>
                            </div>
                        </Col>
                    })}
                </Row>
            </div> :
                <Select
                    className={'w-100'}
                    styles={{ menu: provided => ({ ...provided, zIndex: 9 }) }}
                    options={tabs}
                    getOptionLabel={option => parseToWord(option)}
                    getOptionValue={option => option}
                    placeholder={parseToWord(activeTab)}
                    onChange={(e) => setActiveTab(e)}
                />

            }

        </>
    )
}