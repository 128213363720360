import { put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from './actio-types';
import axios from '../../config/axios';
import * as constants from '../../constants';

function* getDealerData() {
  try {
    const response = yield axios.get(`${constants.API_URL}/team-members`);
    if (response?.status === 200) {
      const
        data = response.data;
      yield put({ type: actionTypes.GET_DEALER_DATA_SUCCESS, data });
    }
  } catch (error) {
    console.log(error, 'error');
    // yield put({ type: actionTypes.GET_TASK_CATEGORIES_ERROR, error });
  }
}

export default function* watcherSaga() {
  yield takeLatest(actionTypes.GET_DEALER_DATA_REQUEST, getDealerData);
}
