import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from "js-cookie";

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import {
  ArraySelect,
  InputKeySelect,
} from 'views/pages/components/InputSelect';
import TableCustom from 'views/pages/components/TableCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
} from "reactstrap";

import {
  deletee,
} from 'views/policies/CustomerPolicy';
import CustomSearch from "@pages/components/CustomSearch";
import { ButtonContainer } from "@pages/reusable-components/styled-components";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";

export default function Customer() {
  const history = useHistory();
  const notificationAlert = useRef(null);
  const permissions = useSelector(state => state.getState).permissions;
  const authUserTeam = useSelector(state => state.getState).authUserTeam;
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [delay, setDelay] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [managers, setManagers] = useState([]);
  const [paymentManagers, setPaymentManagers] = useState([]);
  const [items, setItems] = useState('');
  const [dealers, setDealers] = useState('');
  const [filter, setFilter] = useState('');
  const profileData = useSelector((state) => state.getState).profileData;
  const [confirmShow, setConfirmShow] = useState(false);
  const [clearInput, setClearInput] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  const fetchData = async (pageNumber = 1, source, token, filter) => {
    localStorage.setItem('grms_customer_page_number', pageNumber)
    localStorage.setItem('grms_customer_search_filter', JSON.stringify(filter))

    try {
      const response = await axios({
        method: 'GET',
        url: constants.API_CORE_URL + '/customer',
        headers: {
          Authorization: 'Bearer ' + token
        },
        params: {
          ...filter,
          page: pageNumber,
        },
        cancelToken: source.token,
      })
      const data = response.data
      setCustomers(data.data)
      setItems(data.meta)
      if (data.data.dealers) {
        setDealers(data.data.dealers)
      }

      setLoading(false)
      setDelay(true)
    } catch (error) {
      setLoading(false);
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
      }
    }
  }

  useEffect(() => {
    const grs_token = Cookies.get('grs_token');
    setToken(grs_token);

    let savedPageNumber = 1
    let savedFilter = ''

    if (localStorage.getItem('grms_customer_page_number')) {
      savedPageNumber = localStorage.getItem('grms_customer_page_number')
    }
    if (localStorage.getItem('grms_customer_search_filter')) {
      savedFilter = JSON.parse(localStorage.getItem('grms_customer_search_filter'))
      setFilter(savedFilter)
    }

    const source = axios.CancelToken.source()

    fetchData(savedPageNumber, source, grs_token, savedFilter)

    axios({
      method: 'GET',
      url: `${constants.API_CORE_URL}/account-managers`,
      headers: {
        Authorization: `Bearer ${grs_token}`,
      },
    }).then((res) => {
      let managers = res.data.data;
      let tempManagers = managers.accountManagers;
      let paymentMangers = managers.paymentManagers;
      let manager = tempManagers.find(item => item.id === profileData.id)
      let paymentManager = paymentMangers.find(item => item.id === profileData.id)
      let id = manager ? manager.id : null;

      if (id) {
        setFilter({ ...filter, accountManagerId: id })
      }

      if (paymentManager && paymentManager.id) {
        setFilter({ ...filter, paymentManagerId: paymentManager.id })
      }

      setManagers(tempManagers)
      setPaymentManagers(paymentMangers)
    }).catch((error) => {
      console.log(error)
    })
    return () => {
      source.cancel()
    }
  }, [])

  const filterCustomer = (filter = {}) => {
    const source = axios.CancelToken.source()
    fetchData(1, source, token, {
      ...filter,
    })
  }


  const createCustomer = () => {
    history.push('/admin/customer/create');
  }

  const editCustomer = (row) => {
    history.push({
      pathname: '/admin/customer/' + row.id + '/edit',
      state: {
        customer: row,
        filter: filter
      }
    });
  }

  const deleteCustomer = () => {
    axios({
      method: 'DELETE',
      url: constants.API_URL + '/customer/' + selectedCustomerId,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(response => {
        setConfirmShow(false);
        if (response.status === 204) {
          let data = response.data;
          console.log('deleted data :: ', data);
          notify('success', 'Success', response.data.message);
          filterCustomer();
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        setConfirmShow(false);
        notify('warning', 'Warning', error.response.statusText);
      })
  }

  const searchData = (event, key) => {
    let tempFilter = { ...filter };
    tempFilter[key] = event;
    // if the key is payment_manager_id, remove account_manager_id from filter
    if (key === 'paymentManagerId') {
      delete tempFilter['accountManagerId'];
    }

    if (key === 'accountManagerId') {
      delete tempFilter['paymentManagerId'];
    }
    setFilter(tempFilter);
    filterCustomer(tempFilter);
  }

  const clearData = () => {
    setFilter({})
    setClearInput(token)
    localStorage.removeItem('grms_customer_search_filter')
    filterCustomer()
  }

  return (
    <>
      <CardsHeader name="Customers" parentName="All Customers" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <Row >
                  {dealers &&
                    <Col md={4}>
                      <InputKeySelect
                        newLabel={`Dealer`}
                        options={dealers}
                        value={filter && filter.dealer_id ? filter.dealer_id : ''}
                        onChange={(e) => setFilter({
                          ...filter,
                          dealer_id: e.target.value,
                        })}
                        defaultOption={true}
                      />
                    </Col>}
                  <Col md={4} className={'mb-3'}>
                    <CustomSearch
                      onChange={(e) => searchData(e.target.value, 'search')}
                      clear={clearInput}
                      setClear={() => setClearInput(false)}
                      onKeyPress={e => {
                        if (e.charCode === 13) {
                          searchData(e.target.value, 'search')
                        }
                      }}
                    />

                  </Col>
                  <Col md={4}>
                    <ArraySelect
                      label={'Account Manager'}
                      options={managers}
                      value={filter?.accountManagerId || null}
                      onChange={(e) => {
                        searchData(e.target.value, 'accountManagerId')
                      }}
                      defaultOption={true}
                      valueKey={`id`}
                      labelKey={`name`}
                      errorMessage={'Account Manager'}
                    />
                  </Col>
                  <Col md={4}>
                    <ArraySelect
                      label={'Payment Manager'}
                      options={paymentManagers}
                      value={filter?.paymentManagerId || null}
                      onChange={(e) => {
                        searchData(e.target.value, 'paymentManagerId')
                      }
                      }
                      defaultOption={true}
                      valueKey={`id`}
                      labelKey={`name`}
                      errorMessage={'Payment Manager'}
                    />
                  </Col>
                  <Col>
                    <ButtonContainer reverse onClick={createCustomer}>
                      Add Customer
                    </ButtonContainer>
                    <ButtonContainer
                      onClick={() => clearData()}>Clear</ButtonContainer>
                  </Col>
                </Row>
                <TableCustom
                  loading={loading}
                  noOverflow={true}
                  delay={delay}
                  keyField={`full_name`}
                  searchField={true}
                  items={customers}
                  pagination={items}
                  onChange={(pageNumber) => fetchData(pageNumber, axios.CancelToken.source(), token, filter)}
                  columns={[
                    {
                      dataField: "full_name",
                      text: "Name",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.full_name;
                      }
                    },
                    {
                      dataField: "dealers",
                      text: "Dealer",
                      style: {
                        display: authUserTeam ? 'none' : 'table-cell'
                      },
                      headerStyle: {
                        display: authUserTeam ? 'none' : 'table-cell'
                      },
                      sort: true,
                      formatter: (rowContent, row) => {
                        if (row.dealers && row.dealers.length > 0) {
                          return row.dealers.map((dealer, index) => {
                            return index === row.dealers.length - 1 ? dealer.name : `${dealer.name},`
                          })
                        }
                      }
                    },
                    {
                      dataField: "mobile_number",
                      text: "Phone No",
                      sort: true
                    },
                    {
                      dataField: "email",
                      text: "Email",
                      sort: true
                    },
                    {
                      dataField: "",
                      text: "Account Manager",
                      formatter: (rowContent, row) => {
                        return row?.account_manager?.name
                      }
                    },

                    {
                      dataField: "",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            <IcoMoon
                              className={'cursor-pointer'}
                              iconSet={IconSet}
                              icon={'edit'}
                              size={24}
                              onClick={() => editCustomer(row)}
                            />
                            {deletee(permissions, row) &&
                              <IcoMoon
                                className={'cursor-pointer'}
                                iconSet={IconSet}
                                icon={'delete_outline'}
                                size={24}
                                onClick={() => {
                                  setConfirmShow(true);
                                  setSelectedCustomerId(row.id);
                                }}
                              />
                            }
                          </>
                        )
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={confirmShow}
          toggle={() => setConfirmShow(false)}
          className="modal-dialog-centered modal-secondary"
        >
          <div className="modal-body justify-content-center">
            {`Are you sure you want to delete this customer?`}
          </div>
          <div className="modal-footer justify-content-end">
            <ButtonContainer
              onClick={() => setConfirmShow(false)}
            >
              Cancel
            </ButtonContainer>
            <ButtonContainer
              reverse
              onClick={() => deleteCustomer()}
            >
              {`Delete`}
            </ButtonContainer>

          </div>
        </Modal>
      </Container>
    </>
  )
}