import React, {useEffect, useState} from 'react'
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import {
    CalendarInput, CalendarSelect,
} from "@pages/reusable-components/styled-components"
import moment from 'moment'
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import {Calendar} from "react-multi-date-picker";
export default function CalendarFilter({filterByDate,initial})
{
    const [modal, setModal] = useState(false)
    const [values, setValues] = useState([])
    const [selectedDays, setSelectedDays] = useState(null);
    const toggle = () => setModal((prevState) => !prevState);
    useEffect(()=>{
        if (initial) {
            let startDate = moment().startOf('month');
            let endDate = moment().endOf('month');
            setValues([startDate,endDate])
            setSelectedDays([startDate.format('DD/MM/YYYY'), endDate.format('DD/MM/YYYY')])
            filterByDate({
                from: startDate.format('YYYY-MM-DD'),
                to: endDate.format('YYYY-MM-DD')
            })
        }

    },[])

    return (
        <>
            <Modal className={'custom-styles'} isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}/>
                <ModalBody>
                    <div className={'d-flex justify-content-center mb-3'}>
                        <div>
                            <label>From</label>
                            <CalendarInput width={'150px'} placeholder="Filter by date"
                                           value={values?.length ? values[0].format('DD MMM YYYY') : ''}/>
                        </div>
                        <div>
                            <label>To</label>
                            <CalendarInput width={'150px'} placeholder="Filter by date"
                                           value={values?.length > 1 ? values[values.length - 1].format('DD MMM YYYY') : ''}/>
                        </div>

                    </div>

                    <div className={'d-flex justify-content-center mb-3'}>
                        <Calendar
                            className={'custom-calendar'}
                            value={values}
                            onChange={(e) => {
                                setValues(e)
                            }}
                            range
                            numberOfMonths={2}
                            showOtherDays
                        />
                    </div>
                    <Button outline={true} style={{
                        backgroundColor: '#ec6409',
                        color: 'white',
                        border: '1px solid #ec6409',
                        float: 'right',
                        marginRight: '15px'
                    }} disabled={values.length < 2} onClick={() => {
                        setSelectedDays([values[0].format('DD/MM/YYYY'), values[values.length - 1].format('DD/MM/YYYY')])
                        filterByDate({
                            from: values[0].format('YYYY-MM-DD'),
                            to: values[values.length - 1].format('YYYY-MM-DD')
                        })
                        toggle()
                    }}
                    >Save</Button>

                </ModalBody>
            </Modal>

            <div>
                <CalendarSelect>
                    <img onClick={toggle} width='20px' height='20px'
                         src={require("assets/images/date_range.png")}
                         alt=""/>
                    <span className={'calendar-date-show-size'}
                        onClick={toggle}>{selectedDays?.length ? selectedDays[0] + ' - ' + selectedDays[1] : 'Filter by date'}
                    </span>
                         {selectedDays?.length ?
                          <i className="fa fa-times"
                           onClick={(e) => {
                            e.preventDefault()
                            setSelectedDays([])
                            setValues([])
                            filterByDate({
                                from: '',
                                to: ''
                            })
                        }}
                        />
                        :
                        <></>
                    }
                    <i onClick={toggle} className="ni ni-up rotate-down"/>
                </CalendarSelect>
            </div>
        </>

    )
}