import React, {useEffect, useState, useRef} from 'react'

import {
    Button,
    Container, Dropdown, DropdownItem, DropdownMenu, Input, Modal, ModalHeader,
} from 'reactstrap'
import Select from "react-select";
import {
    Text,
    ReportContainer,
    TaskDetailsContainer,
    TextContainer,
    StatusWrapper,
    FirstLetterContainer,
    ModalBodyContainer,
    ImageContainer,
    CloseIcon
} from '@pages/reusable-components/styled-components'
import {useSelector} from "react-redux";
import styled from "styled-components";
import {formatDateShortGB} from "../../../../../constants";
import {hasFleetAdminPermission} from '../../../../../utils/dealer';
import IconSet from "@assets/selection.json";
import IcoMoon from "react-icomoon";

const PriorityContainer = styled.span`
 color: ${props => props.priority === 'high' ? '#ec6409' : props.priority === 'medium' ? '#ffbb31' : '#247d5f'};
 display: flex;
 gap: 10px;
 align-items: center;
 font-size: 14px;
`

export default function CreateEditTask({
                                           isDisabled,
                                           assignees,
                                           isWorker,
                                           comment,
                                           availableAssignees = [],
                                           priorities,
                                           initialStatus,
                                           taskDetails,
                                           statuses,
                                           setTaskDetails,
                                           setAssignees,
                                           setImages,
                                           vehicle,
                                           setPriority,
                                           setDescription,
                                           setComment,
                                           saveData,
                                           priority,
                                           cancel,
                                           changeStatus,
                                           updateStatus,
                                           documents = [],
                                           showCancel = true
                                       }) {
    const inputRef = useRef(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [taskStatus, setTaskStatus] = useState(null);
    const [taskUpdatingStatus, setTaskUpdatingStatus] = useState(null);
    const [modal, setModal] = useState(false);
    const [statusChanged, setStatusChanged] = useState(false);
    const [tempStatus, setTempStatus] = useState({});
    const [imageModal, setImageModal] = useState({
        isOpen: false,
        url: null,
    });
    const [modalTitle, setModalTitle] = useState('');
    const [modalSubTitle, setModalSubTitle] = useState(null);
    const [imgSrc, setImgSrc] = useState([]);
    const roles = useSelector(state => state.getState).roles;
    const isAdmin = hasFleetAdminPermission(roles);

    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const statusInText = (text, value) => {
        return (<StatusWrapper status={value}>{text}</StatusWrapper>)
    }
    useEffect(() => {
        setTaskStatus(initialStatus)
        setTempStatus({})
        setStatusChanged(false)

    }, [initialStatus])
    useEffect(() => {
        if (documents.length) {
            let urls = []
            documents.forEach(image => {
                urls.push({url: image.url})
            })
            setImgSrc(urls)
        }
    }, [documents])
    const setStatus = (value, id) => {
        if (value === 'in_progress' && isWorker) {
            setModalTitle('You are going to change the task status')
            setModalSubTitle(<div>if you are going to change the task status
                to {statusInText('In progress', 'in_progress')},
                the Task Workflow will be started automatically</div>)

            setTaskUpdatingStatus('start')
            setStatusChanged(true)
            setTempStatus({value: value, id: id})
            toggleModal()
        } else if (value === 'in_review' && isWorker) {
            setModalTitle('You are going to change the task status')
            setModalSubTitle(<div>Are you sure that you have completed the task? If you change the status of the task
                to {statusInText('In review', 'in_review')},
                the Task Workflow will be ended automatically</div>)
            setTaskUpdatingStatus('end')
            setStatusChanged(true)
            setTempStatus({value: value, id: id})
            toggleModal()
        } else {
            updateStatus(id, value)
            setTaskDetails({...taskDetails, status: value})
        }

    }

    const formatText = (value) => {
        if (value) {
            let text = value.toString().replace(/_+/g, ' ').toLowerCase();
            return text.charAt(0).toUpperCase() + text.slice(1);
        }

    }
    const selectAssignees = (val) => {
        setAssignees(val)
    }
    const returnFirstLetter = (word) => {
        return word?.charAt(0).toUpperCase();
    }

    const MultiValueContainer = ({children, ...props}) => {
        return (
                <div>
                    {children.length ?   <FirstLetterContainer>{returnFirstLetter(children[0].props.data.name)}</FirstLetterContainer> : ''}
                </div>
        );
    };
    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            width: state.selectProps.width,
            borderBottom: '1px dotted pink',
            padding: 20,
        }),

        control: (_, {selectProps: {width}}) => ({
            display: 'flex',
        }),

        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';

            return {...provided, opacity, transition};
        },
        menuList: (provided) => ({
            ...provided,
            width: '200px'
        })
    }
    const onChangeSelect = (event) => {
        let tempFiles = [];
        let tempImgSrc = [];
        const files = event.target.files;

        for (let i = 0; i < files.length; i++) {
            tempFiles.push(files[i]);
            let reader = new FileReader();

            reader.onloadend = (e) => {
                tempImgSrc.push(reader.result);

                // Once all files are processed, set the state
                if (tempImgSrc.length === files.length) {
                    setImgSrc((prevImgSrc) => [...prevImgSrc, ...tempImgSrc]);
                    setImages(tempFiles);
                }
            };

            reader.readAsDataURL(files[i]);
        }
    };
    const removeImage = (index) => {
        let tempImages = imgSrc.filter((item, idx) => idx !== index);
        setImgSrc(tempImages)
    }
    const showImage = (url) => {
        console.log('url',url)
        setImageModal({isOpen: true,url: url})
    }
    const toggleModal = () => setModal(!modal);
    const toggleImageModal = () => setImageModal({...imageModal,isOpen: !imageModal.isOpen});
    const confirm = () => {
        if (statusChanged) {
            updateStatus(tempStatus.id, tempStatus.value)
            setTaskDetails({...taskDetails, status: tempStatus.value})
        }
        changeStatus(taskUpdatingStatus)
        toggleModal()
    }
    const closeModal = () => {
        setTaskUpdatingStatus('')
        setStatusChanged(false)
        setTempStatus({})
        toggleModal();
    }


    return (
        <>
            <Modal
                isOpen={modal}
                toggle={toggleModal}
            >
                <ModalHeader toggle={toggleModal}>

                </ModalHeader>
                <ModalBodyContainer>
                    <Text color='#ec6409'>{modalTitle}</Text>
                    <Text size='14px'>{modalSubTitle}</Text>
                    <ReportContainer>
                        <Button outline={true} className="w-100"
                                style={{backgroundColor: "white", color: '#ec6409', border: '1px solid #ec6409'}}
                                type="button" onClick={() => {
                            closeModal()

                        }}>Cancel</Button>
                        <Button className="w-100" style={{backgroundColor: "#ec6409", color: 'white', border: 'none'}}
                                type="button" onClick={() => {
                            confirm()
                        }}>Confirm</Button>
                    </ReportContainer>
                </ModalBodyContainer>

            </Modal>
            <Modal  size="md" isOpen={imageModal.isOpen} toggle={toggleImageModal}>
                <img  src={imageModal.url} alt=""/>
            </Modal>
            <Container className='pd-20'>
                <TaskDetailsContainer>
                    <Text size='22px' color='#ec6409'>{taskDetails.title}</Text>
                </TaskDetailsContainer>
                <ReportContainer marginBottom between>
                    <ReportContainer>
                        <img width={'219px'} height={'165px'}
                             src={vehicle.image}
                             alt=""/>
                        <TaskDetailsContainer>
                            <TextContainer>
                                {isWorker && <img height='25px' width='25px'
                                                  src={require(`assets/images/directions_car_grey.png`)}
                                                  alt=""/>}
                                <Text>No.</Text>
                                {
                                    isAdmin && <>
                                        <img height='25px' width='25px'
                                             src={require(`assets/images/taskEdit/road_grey.png`)}
                                             alt=""/>
                                        <img height='25px' width='25px'
                                             src={require(`assets/images/vehicle_color.svg`)}
                                             alt=""/>
                                        <img height='25px' width='25px'
                                             src={require(`assets/images/taskEdit/date_grey.png`)}
                                             alt=""/>
                                    </>
                                }
                                {
                                    isWorker &&
                                    <>
                                        <img height='25px' width='25px'
                                             src={require(`assets/images/taskEdit/priority.png`)}
                                             alt=""/>
                                        {taskStatus === 'start' && <img height='25px' width='25px'
                                                                        src={require(`assets/images/taskEdit/person.png`)}
                                                                        alt=""/>}
                                    </>
                                }
                                <img height='25px' width='25px' src={require(`assets/images/taskEdit/status_grey.png`)}
                                     alt=""/>
                            </TextContainer>
                            <TextContainer>
                                {isWorker && <Text>Vehicle</Text>}
                                <Text bold>Reg. Num.:</Text>
                                {isAdmin &&
                                <>
                                    <Text bold>Mileage:</Text>
                                    <Text bold>Colour:</Text>
                                    <Text bold>Created Date:</Text>
                                </>
                                }
                                {isWorker && <>
                                    <Text bold>Priority:</Text>
                                    {taskStatus === 'start' && <Text bold>Assignees:</Text>}
                                </>}
                                <Text bold>Status:</Text>
                            </TextContainer>
                            <TextContainer>
                                {isWorker && <Text>{vehicle.make.name + ' ' + vehicle.model.name}</Text>}
                                <Text>{vehicle.stock_no}</Text>
                                {isAdmin &&
                                <>
                                    <Text>{vehicle.mileage}</Text>
                                    <Text>{vehicle.colour ? vehicle.colour : '-'}</Text>
                                    <Text>{formatDateShortGB(taskDetails.created_at)}</Text>
                                </>

                                }
                                {isWorker &&
                                <PriorityContainer priority={taskDetails.priority}>
                                    {
                                        taskDetails.priority === 'high' ? <img height='14px' width='14px'
                                                                               src={require(`assets/images/ep_d-arrow-up-double.png`)}
                                                                               alt=""/>
                                            : taskDetails.priority === 'medium' ? <img height='14px' width='14px'
                                                                                       src={require(`assets/images/fluent_line-equal.png`)}
                                                                                       alt=""/>
                                                : require(`assets/images/ep_arrow-down.png`)
                                    }
                                    <span>{taskDetails.priority}</span>
                                </PriorityContainer>
                                }
                                {
                                    isWorker && taskStatus === 'start' &&

                                    <Select
                                        closeMenuOnSelect={false}
                                        onChange={selectAssignees}
                                        defaultValue={assignees}
                                        getOptionLabel={option => option.name}
                                        getOptionValue={option => option.id}
                                        components={{MultiValueContainer}}
                                        isMulti
                                        options={availableAssignees}
                                        styles={customStyles}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />
                                }

                                <Dropdown isOpen={dropdownOpen} toggle={toggle}>

                                    <span className='cursor-pointer' onClick={toggle}>
                                        <StatusWrapper status={taskDetails.status}
                                                       > {formatText(taskDetails.status)} </StatusWrapper> <i
                                        className="ni ni-up rotate-down"/>
                                    </span>
                                    {
                                        statuses?.length ?
                                            <DropdownMenu
                                                style={{
                                                    maxHeight: '400px',
                                                    overflowY: 'scroll',
                                                    position: 'absolute',
                                                }}>
                                                {
                                                    statuses.map((item,index) => {
                                                        return <React.Fragment key={index}> <DropdownItem
                                                            onClick={() => setStatus(item.value, taskDetails.id)}>{item.text}</DropdownItem>
                                                        </React.Fragment>
                                                    })
                                                }


                                            </DropdownMenu> : <></>
                                    }
                                </Dropdown>

                            </TextContainer>

                        </TaskDetailsContainer>
                    </ReportContainer>


                </ReportContainer>
                {
                    <>
                        <TaskDetailsContainer>
                            <IcoMoon
                                iconSet={IconSet}
                                icon={'sticky_note_2'}
                                size={25}
                            />
                            <TextContainer>
                                <Text bold>Note:</Text>
                                <Text size='14px'>{taskDetails.note}</Text>
                            </TextContainer>
                        </TaskDetailsContainer>
                        <TaskDetailsContainer>
                            <img height='25px' width='25px' src={require(`assets/images/taskEdit/edit.png`)}
                                 alt=""/>
                            <TextContainer width='400px'>
                                <Text bold size='14px'>Description:</Text>
                                {
                                    isAdmin ? <Input
                                        id="exampleText"
                                        type="textarea"
                                        className='text-area--m-h-100'
                                        value={taskDetails.description}
                                        onChange={(e) => {
                                            setDescription(e.target.value)
                                            setTaskDetails({...taskDetails, description: e.target.value})
                                        }
                                        }
                                        name="text"
                                        placeholder='Add a description...'
                                    /> : <Text>{taskDetails.description}</Text>
                                }
                            </TextContainer>
                        </TaskDetailsContainer>

                        <TaskDetailsContainer>
                            <img height='25px' width='25px' src={require(`assets/images/taskEdit/attachment.png`)}
                                 alt=""/>
                            <TextContainer width='400px'>
                                {/*vehicle*/}
                                <Text bold>Image:</Text>
                                <TaskDetailsContainer>
                                    {imgSrc.length ? imgSrc.map((image, index) => {
                                        return (
                                            <ImageContainer>
                                                <CloseIcon onClick={() => removeImage(index)} height='15px' width='15px'
                                                       src={require(`assets/images/xmark-solid.svg`)}
                                                           alt=""/>
                                                <img height='80px' onClick={()=>{showImage(image.hasOwnProperty('url') ? image.url : image)}} width='136px' src={image.hasOwnProperty('url') ? image.url : image}
                                                     alt=""/>
                                            </ImageContainer>
                                        )
                                    }) : <></>}
                                </TaskDetailsContainer>


                                {
                                    isAdmin ?
                                        <>
                                            <TaskDetailsContainer pointer onClick={() => inputRef.current.click()}>
                                                <img height='25px' width='25px'
                                                     src={require(`assets/images/taskEdit/upload.png`)}
                                                     alt=""/>
                                                <Text color='#ec6409'>Upload
                                                </Text>

                                            </TaskDetailsContainer>
                                            <input style={{display: 'none'}} onChange={(e) => onChangeSelect(e)}
                                                   ref={inputRef}
                                                   type="file"
                                                   multiple={'multiple'}
                                                   name="myImage" accept="image/png, image/gif, image/jpeg"/>
                                        </> : <img height='80px' width='136px' src={vehicle.image} alt=""/>
                                }
                            </TextContainer>
                        </TaskDetailsContainer>
                        <TaskDetailsContainer>
                            <img height='25px' width='25px' src={require(`assets/images/taskEdit/comment.png`)}
                                 alt=""/>

                            <TextContainer width='500px'>
                                <Text bold>{isWorker ? 'Comments' : 'Comment'}:</Text>
                                {isWorker && taskDetails.comments.map(item => {
                                    return (
                                        <>
                                            <TaskDetailsContainer noMarginBottom>
                                                <FirstLetterContainer>{returnFirstLetter(item?.created_by.name)}</FirstLetterContainer>
                                                <Text bold>{item?.created_by.name}</Text> added a comment
                                                - {formatDateShortGB(item?.created_at)}
                                            </TaskDetailsContainer>
                                            <Text size='12px'>{item.comment}</Text>
                                        </>
                                    )
                                })
                                }
                                <Input
                                    id="exampleText"
                                    name="text"
                                    value={comment}
                                    onChange={(e) => {
                                        setComment(e.target.value)
                                        setTaskDetails({...taskDetails, comment: e.target.value})
                                    }}
                                    placeholder='Add a comment...'
                                />
                            </TextContainer>
                        </TaskDetailsContainer>
                        {showCancel && <Button outline={true} style={{color: '#ec6409', border: '1px solid #ec6409', float: 'right'}}
                                 type="button" onClick={() => cancel()}>Cancel</Button>}
                        <Button outline={true} style={{
                            backgroundColor: '#ec6409',
                            color: 'white',
                            border: '1px solid #ec6409',
                            float: 'right',
                            marginRight: '15px'
                        }} disabled={isDisabled} onClick={() => saveData()}>Save</Button>
                    </>
                }

            </Container>
        </>
    )
}