import React, { useEffect, useState, useRef } from 'react';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import InputSideCustom from 'views/pages/components/InputSideCustom';
import {
  InputArraySideSelect,
} from 'views/pages/components/InputSelect';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from 'reactstrap';

export default function DealerGrouped() {
  const notificationAlert = useRef(null);

  const [token, setToken] = useState('');
  const [dealers, setDealers] = useState([]);
  const [filter, setFilter] = useState('');
  const [items, setItems] = useState('');

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  const fetchData = async (source, token, filter) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/report/deals/fees`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: filter,
        cancelToken: source.token,
      })
      console.log('response :: ', response.data)
      const data = response.data
      setDealers(data.data.dealers)
      setFilter({
        ...data.data.filter,
        from: data.data.filter.from? constants.formatDate(data.data.filter.from): '',
        to: data.data.filter.to? constants.formatDate(data.data.filter.to): '',
      })
      setItems(data.data.items)
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      }
    }
  }


  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()
    fetchData(source, grs_token, '')

    return () => {
      source.cancel()
    };
  }, [])


  const filterItems = () => {
    const source = axios.CancelToken.source()
    fetchData(source, token, {
      ...filter,
      from: constants.formatDate(filter.from),
      to: constants.formatDate(filter.to),
    })
  }

  const resetItems = () => {
    setFilter('')
    const source = axios.CancelToken.source()
    fetchData(source, token, '')
  }

  const download = async (dealerId) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/fees/dealer/${dealerId}/download`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: filter,
      })
      console.log('response :: ', response.data)
    } catch (error) {
      console.error('error :: ', error)
      notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
    }
  }

  return (
    <>
      <CardsHeader name="Sales" parentName="Home" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <h1 className="title">Sales</h1>
                <p className="text-sm mb-0">Sales overview</p>
              </CardHeader>
              <CardBody>
                <Container>
                  <Row>
                    <Col md={6} sm={12}>
                      <InputSideCustom 
                        type={`date`}
                        label={`From`}
                        value={filter && filter.from? filter.from: ''}
                        onChange={e => setFilter({
                          ...filter,
                          from: e.target.value
                        })}
                      />
                    </Col>
                    <Col md={6} sm={12}>
                      <InputSideCustom 
                        type={`date`}
                        label={`To`}
                        value={filter && filter.to? filter.to: ''}
                        onChange={e => setFilter({
                          ...filter,
                          to: e.target.value
                        })}
                      />
                    </Col>
                    {filter.isAllowedToFilterByDealer && 
                    <Col md={6} sm={12}>
                      <InputArraySideSelect
                        label={`Offices`}
                        options={dealers}
                        value={filter && filter.office? filter.office: ''}
                        onChange={e => setFilter({
                          ...filter,
                          office: e.target.value
                        })}
                        valueKey={`id`}
                        labelKey={`name`}
                        defaultOption={true}
                      />
                    </Col>}
                  </Row>
                  <div className="d-flex flex-row justify-content-center">
                    <Button className="w-50" color="success" onClick={() => filterItems()}>Filter</Button>
                    <Button className="w-50" color="danger" onClick={() => resetItems()}>Reset</Button>
                  </div>
                </Container>
                <Row className="mt-4">
                  <Col xl={12} className="px-5">
                    <div className="d-flex flex-row justify-content-between flex-wrap">
                      <div className="p-2">
                        <h2 className="mb-0">{`TOTAL`}</h2>
                        <h2 className="mb-0">{constants.CURRENCY} {items.all_subtotal}</h2>
                      </div>
                      <div className="p-2">
                        <h2 className="mb-0">{`GST`}</h2>
                        <h2 className="mb-0">{constants.CURRENCY} {items.all_gst}</h2>
                      </div>
                      <div className="p-2">
                        <h2 className="mb-0">{`GRAND TOTAL`}</h2>
                        <h2 className="mb-0">{constants.CURRENCY} {items.all_total}</h2>
                      </div>
                      <div className="p-2">
                        <h2 className="mb-0">{`Total number of deals`}</h2>
                        <h2 className="mb-0">{constants.CURRENCY}</h2>
                      </div>
                    </div>
                  </Col>
                </Row>
                <table className="table align-items-center table-flush">
                  {items.dealer &&
                    items.dealer.forEach((dealer, index) => {
                      if (index === 0) {
                        return <thead className="thead-light" key={index}>
                          <tr>
                            <th scope="col">{`Office`}</th>
                            <th scope="col">{`Id`}</th>
                            <th scope="col">{`Rego`}</th>
                            <th scope="col">{`Customer`}</th>
                            <th scope="col">{`Number`}</th>
                            <th scope="col">{`Fee`}</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                      }
                      if (dealer.deals) {
                        return <tbody key={index}>
                          {
                            dealer.deals.map((deal, index2) => {
                              return <tr>
                                <td>{dealer.business_name}</td>
                                <td>{deal.id}</td>
                                <td>{deal.rego}</td>
                                <td>{deal.full_name}</td>
                                <td>{deal.number}</td>
                                <td>{constants.CURRENCY} {deal.fee}</td>
                                <td>
                                  {index2 === 0 &&
                                    <Button className="btn-sm" color="success" type="button" onClick={() => download(dealer.dealer_id)}>Download</Button>
                                  }
                                </td>
                              </tr>
                            })
                          }
                          <tr class="bold-row">
                            <td>{`Subtotal`}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{constants.CURRENCY} {dealer.sub_total}</td>
                          </tr>
                          <tr class="bold-row">
                            <td>{`GST`}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{constants.CURRENCY} {dealer.gst}</td>
                          </tr>
                          <tr class="bold-row">
                            <td>{`Total`}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{dealer.total_number_of_deals}</td>
                            <td>{constants.CURRENCY} {dealer.total_to_be_paid}</td>
                          </tr>
                        </tbody>
                      }
                    })
                  }
                </table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}