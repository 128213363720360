import React, {useEffect, useState, useRef, useMemo} from 'react';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import {Doughnut} from "react-chartjs-2";

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
} from 'reactstrap';
import {FilterButtons} from "@pages/reusable-components/styled-components";
import CustomTable from "@pages/components/Table/CustomTable";

export default function Statistics() {
  const notificationAlert = useRef(null);
  const [token, setToken] = useState('');
  const [filter, setFilter] = useState('');
  const [statsByVehicle, setStatsByVehicle] = useState([]);
  const [leadVehiclesTypes, setLeadVehiclesTypes] = useState([]);
  const [leadsCount,setLeadsCount] = useState('')
  const [keys] = useState([
    {
      key: 'vehicle_make',
      label: 'Make'
    },
    {
      key: 'vehicle_model',
      label: 'Model'
    },
    {
      key: 'leads_count',
      label: 'Number'
    },
  ]);

  const [chartKeys] = useState(
      ['Car',
        'Van',]
  );
  const dealsStats = useMemo(() =>
  {
    if(leadVehiclesTypes) {
      let chartData = {
        labels: (chartKeys),
        datasets: [
          {
            label: 'Deal stats',
            data:  leadVehiclesTypes,
            backgroundColor: [
              '#4472c4',
              '#ed7d31',

            ],
            borderColor: [
              '#4472c4',
              '#ed7d31',
            ],
            borderWidth: 1,
          },
        ],
      };
      return  <Doughnut height={1} width={1} data={chartData} />
    }
  }, [leadVehiclesTypes]);

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };


  useEffect(() => {

    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    fetchData(source, grs_token, '')

    /**
     * chart
     */
    return () => {
      source.cancel()
    }
  }, [])

  const fetchData = async (source, token, filter) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/leads/statistics`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ...filter,
          from: constants.formatDate(filter.from),
          to: constants.formatDate(filter.to),
        },
        cancelToken: source.token,
      })
      const data = response
      setFilter({
        ...data.data.filter,
        from: data.data.filter?.from ? constants.formatDate(data.data.filter.from) : '',
        to: data.data.filter?.to ? constants.formatDate(data.data.filter.to) : '',
      })
      setStatsByVehicle(data.data.statsByVehicle)
      let types = data.data?.vehicleTypes
      let counts = []
      types.forEach(item=>{
        counts.push(item.leads_count)
      })
      setLeadVehiclesTypes(counts)
      setLeadsCount(data.data.count)

      setTimeout(() => {
      }, 1000);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
      }
    }
  }

  const filterStatistic = () => {

    const source = axios.CancelToken.source()
    fetchData(source, token, filter)
  }

  const resetStatistic = () => {
    setFilter('')

    const source = axios.CancelToken.source()
    fetchData(source, token, '')
  }



  return (
    <>
      <CardsHeader name="Leads Statistics" parentName="Statistics" currentName="Show" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <div>
                    <h1 className="title">{`Statistics`}</h1>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <Row className="m-2 ">
                  <Col md={3} sm={12}>
                    <FormGroup>
                         <div className={'d-flex align-items-center g-5'}>
                           <label className="form-control-label">From</label>
                           <Input
                               type="date"
                               value={filter && filter.from ? filter.from : ''}
                               onChange={(e) => setFilter({
                                 ...filter,
                                 from: e.target.value,
                               })}
                           />
                         </div>
                    </FormGroup>
                  </Col>
                  <Col md={3} sm={12}>
                    <FormGroup>
                          <div className={'d-flex align-items-center g-5'}>
                           <label className="form-control-label">To</label>
                           <Input
                               type="date"
                               value={filter && filter.to ? filter.to : ''}
                               onChange={(e) => setFilter({
                                 ...filter,
                                 to: e.target.value,
                               })}
                           />
                          </div>
                    </FormGroup>
                  </Col>
                  <Col md={2} sm={6} style={{margin: 5}}>
                    <FilterButtons color="#fafafa"  background="#ec6409"  onClick={() => filterStatistic()}>Filter</FilterButtons>
                  </Col>
                  <Col md={3} sm={6} style={{margin: 5}}>
                    <FilterButtons background="#fafafa"   color="#ec6409" onClick={() => resetStatistic()}>Reset</FilterButtons>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                 <Row>
                   <Col>
                     <h3>Total Leads: {leadsCount}</h3>
                     <div>
                       <h3>
                         Leads: by Make & Model
                       </h3>
                       <CustomTable multicolor={true} customClassName={'table-header'} keys={keys} tableData={statsByVehicle}/>
                     </div>
                   </Col>
                   <Col>
                     <div>
                       <h3>Car & Van Lead Split</h3>
                       <h4>Cars: {leadVehiclesTypes[0] ?? '-'}</h4>
                       <h4>Vans: {leadVehiclesTypes[1] ?? '-'}</h4>
                       {dealsStats}
                     </div>
                   </Col>
                 </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}