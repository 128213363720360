import React, { useEffect, useContext } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import InputCustom from 'views/pages/components/InputCustom';
import { InputKeySelect, InputArraySelect } from 'views/pages/components/InputSelect';
import * as actions from '../../../../redux/Deal/actions';
import { PaymentContext } from '../context';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "reactstrap";

export default function NewPayment() {
  const context = useContext(PaymentContext);

  const { salesAccounts, taxRates, mappedSalesAccounts } = useSelector(
    (state) => state.dealState,
    shallowEqual
  );

  const {
    deal,
    paymentDescriptors,
    setFlag,
    dispatch,
    paymentItems, setPaymentItems,
    addNewPaymentItem,
    customPayment, setCustomPayment,
    goDeal,
    addCustomPayment
  } = context;

  useEffect(() => {
    if(paymentItems.length > 0) {
      let amount = paymentItems.reduce(
        (previousValue, currentValue) => previousValue + parseFloat(currentValue.total_price),
        0
      )

      setCustomPayment({
        ...customPayment,
        amount: amount,
      })
    }
  }, [paymentItems])

  const handlePaymentItemChange = (type, value, index, item) => {
    const newPaymentItems = [...paymentItems];

    let totalprice = item.total_price;

    if (['qty', 'vat', 'price'].includes(type)) {
      let qty = type === 'qty' ? value : item.qty;
      let price = type === 'price' ? value : item.price;
      let vat = type === 'vat' ? value : item.vat;
      totalprice = ((qty * parseFloat(price)) + (qty * parseFloat(price)) * parseFloat(vat / 100)).toFixed(2);
    }

    const paymentItem = {
      ...item,
      [type]: value,
      total_price: totalprice
    };
    newPaymentItems[index] = paymentItem;
    setPaymentItems(newPaymentItems);
  }

  const getSalesAccounts = () => {
    dispatch({
      type: actions.GET_SALES_ACCOUNTS_REQUEST
    });
  };

  const getTaxes = () => {
    dispatch({
      type: actions.GET_TAXES_REQUEST
    });
  };

  const getPaymentDescription = (type) => {
    return `${type} ${deal.vehicle.vehicle_make.name} ${deal.vehicle.vehicle_model.name} ${deal.vehicle.stock_no} ${deal.vehicle.derivative}`;
  }

  const triggerPaymentTypesItems = (type) => {
    let invoiceReference = '';

    switch(type) {
      case "initial_payment":
        invoiceReference = `Initial payment for ${deal.vehicle.stock_no}`;
        break;
      case "fine":
        invoiceReference = `Fine payment for ${deal.vehicle.stock_no}`;
        break;
      case "deal":
        invoiceReference = `Deal payment for ${deal.vehicle.stock_no}`;
        break;
      case "end_payment":
        invoiceReference = `End Payment for ${deal.vehicle.stock_no}`;
        break;
      case "excess_mileage":
        invoiceReference = `Excess mileage Payment for ${deal.vehicle.stock_no}`;
        break;
      default: 
        break;
    }

    setCustomPayment({
      ...customPayment,
      note: invoiceReference,
      payment_descriptor: type,
      amount: 0,
    })

    if (paymentItems.length > 0) {
      setPaymentItems([]);
    }

    if (type === 'initial_payment') {
      let element = "initial_payment" in mappedSalesAccounts ? mappedSalesAccounts.initial_payment : null;
      let lessReservationFee = "reservation_fee" in mappedSalesAccounts ? mappedSalesAccounts.reservation_fee : null;
      let adminFee = "admin_fee" in mappedSalesAccounts ? mappedSalesAccounts.admin_fee : null;
      let deliveryFee = "delivery_fee" in mappedSalesAccounts ? mappedSalesAccounts.delivery_fee : null;

      addNewPaymentItem({
        qty: 1,
        price: deal.initial_payment,
        description: getPaymentDescription("Initial payment for"),
        total_price: parseFloat(deal.initial_payment) + parseFloat(deal.initial_payment) * 0.2,
        vat: 20,
        sales_account: element?.xero_value ?? '',
        tax_rate: 'OUTPUT2'
      });

      addNewPaymentItem({
        qty: 1,
        price: -416.67,
        description: getPaymentDescription("Less reservation fee for"),
        total_price: parseFloat(-500),
        vat: 20,
        sales_account: lessReservationFee?.xero_value ?? '',
        tax_rate: 'OUTPUT2'
      });

      addNewPaymentItem({
        qty: 1,
        price: deal.delivery_fee,
        description: getPaymentDescription('Delivery fee for'),
        total_price: parseFloat(deal.delivery_fee) + parseFloat(deal.delivery_fee) * 0.2,
        vat: 20,
        sales_account: deliveryFee?.xero_value ?? '',
        tax_rate: 'OUTPUT2'
      });

      addNewPaymentItem({
        qty: 1,
        price: 150,
        description: getPaymentDescription('Admin fee for'),
        total_price: 180,
        vat: 20,
        sales_account: adminFee?.xero_value ?? '',
        tax_rate: 'OUTPUT2'
      });      
    } else if (type === 'fine') {
      let adminFee = "fine" in mappedSalesAccounts ? mappedSalesAccounts.fine : null;
      addNewPaymentItem({
        qty: 1,
        price: 0,
        description: getPaymentDescription('Representation to transfer liability of fine for'),
        total_price: 0,
        vat: 20,
        sales_account: adminFee?.xero_value ?? '',
        tax_rate: 'OUTPUT2'
      });   
    } else if (type === 'excess_mileage') {
      let adminFee = "excess_mileage" in mappedSalesAccounts ? mappedSalesAccounts.excess_mileage : null;
      addNewPaymentItem({
        qty: 1,
        price: 0,
        description: getPaymentDescription('Excess mileage for'),
        total_price: 0,
        vat: 20,
        sales_account: adminFee?.xero_value ?? '',
        tax_rate: 'OUTPUT2'
      });   
    } else if (type === 'reservation_fee') {
      let adminFee = "reservation_fee" in mappedSalesAccounts ? mappedSalesAccounts.reservation_fee : null;
      addNewPaymentItem({
        qty: 1,
        price: 416.67,
        description: getPaymentDescription('Reservation fee for'),
        total_price: 500,
        vat: 20,
        sales_account: adminFee?.xero_value ?? '',
        tax_rate: 'OUTPUT2'
      });
    }
  };

  useEffect(() => {
    getSalesAccounts();
    getTaxes();
  }, [deal.id])

  return (
    <>
      <Row>
        <Col xl="12">
          <Card>
            <CardHeader>
              <div className="d-flex justify-content-between">
                <div>
                  <h1 className="title mb-0">{`Create a custom payment`}</h1>
                </div>
                <Button className="h-50" color="success" type="button" onClick={() => goDeal()}>Deal</Button>
              </div>
            </CardHeader>
            <CardBody>
              <InputCustom
                type={`date`}
                label={`Invoice Date`}
                value={customPayment && customPayment.scheduled_at ? customPayment.scheduled_at : ''}
                onChange={(e) => setCustomPayment({
                  ...customPayment,
                  scheduled_at: e.target.value,
                })}
              />
              <InputKeySelect
                label={`Payment Type`}
                value={customPayment && customPayment.payment_descriptor ? customPayment.payment_descriptor : ''}
                options={paymentDescriptors}
                onChange={(e) => {
                  setCustomPayment({
                    ...customPayment,
                    payment_descriptor: e.target.value,
                  })
                  setFlag(true);

                  triggerPaymentTypesItems(e.target.value);

                }}
                defaultOption={true}
              />
              <InputCustom
                type={`text`}
                label={`Invoice Reference`}
                value={customPayment && customPayment.note ? customPayment.note : ''}
                onChange={(e) => setCustomPayment({
                  ...customPayment,
                  note: e.target.value,
                })}
              />
              <InputCustom
                type={`number`}
                label={`Total amount`}
                value={customPayment && customPayment.amount ? customPayment.amount : ''}
                disabled={true}
              />
              {paymentItems.length === 0 ? <Row className="mt-2 mb-2">
                <Col xl="6">
                  <Button
                    color="primary"
                    type="button"
                    onClick={() => addNewPaymentItem({})}
                  >Add more payment items</Button>
                </Col>
              </Row> : null}
              {paymentItems.length > 0 ? paymentItems.map((item, index) => {
                return (<Row className="align-items-end" key={index}>
                  <Col md={2} sm={4}>
                    <InputCustom
                      type={`number`}
                      label={`Qty`}
                      placeholder={`Qty`}
                      value={item.qty}
                      onChange={(e) => {
                        handlePaymentItemChange('qty', e.target.value, index, item);
                      }}
                    />
                  </Col>
                  <Col md={3} sm={4}>
                    <InputCustom
                      type={`text`}
                      label={`Description`}
                      placeholder={`Description`}
                      value={item.description}
                      onChange={(e) => {
                        handlePaymentItemChange('description', e.target.value, index, item);
                      }}
                    />
                  </Col>
                  <Col md={2} sm={4}>
                    <InputCustom
                      type={`number`}
                      label={`Unit Price`}
                      placeholder={`Price`}
                      value={item.price}
                      onChange={(e) => {
                        handlePaymentItemChange('price', e.target.value, index, item);
                      }}
                    />
                  </Col>
                  <Col md={2} sm={4}>
                    <InputCustom
                      type={`number`}
                      label={`Vat (%)`}
                      placeholder={`Vat`}
                      value={item.vat}
                      disabled={true}
                      min={0}
                      max={100}
                    />
                  </Col>
                  <Col md={2}>
                    <InputArraySelect
                      options={taxRates}
                      value={item.tax_rate ?? ''}
                      valueKey={"tax_type"}
                      label={"Tax Rates"}
                      labelKey={"name"}
                      onChange={(e) => {
                        let element = taxRates.find(item => item.tax_type === e.target.value);

                        const paymentItem = {
                          ...item,
                          tax_rate: e.target.value
                        };

                        if (element) {
                          handlePaymentItemChange('vat', element.rate, index, paymentItem);
                        } else {
                          handlePaymentItemChange('tax_rate', e.target.value, index, item);
                        }
                      }}
                      defaultOption={true}
                    />
                  </Col>
                  <Col md={2}>
                    <InputArraySelect
                      options={salesAccounts}
                      value={item.sales_account ?? ''}
                      valueKey={"code"}
                      placeholder={`Vat`}
                      label={"Sales Account"}
                      labelKey={"name"}
                      onChange={(e) => {
                        handlePaymentItemChange('sales_account', e.target.value, index, item);
                      }}
                      defaultOption={true}
                    />
                  </Col>
                  <Col md={2} sm={4}>
                    <InputCustom
                      type={`number`}
                      label={`Total`}
                      disabled={true}
                      placeholder={`Total`}
                      value={item.total_price}
                    />
                  </Col>
                  <Col md={1} sm={4}>
                    <Button
                      className="new-event--add"
                      color="primary"
                      type="button"
                      style={{
                        'marginBottom': '1.5rem'
                      }}
                      onClick={() => {
                        const payments = paymentItems.filter((t, key) => key !== index);
                        setPaymentItems(payments);
                      }}
                    >
                      -
                    </Button>
                  </Col>
                  {index === paymentItems.length - 1 ? (
                    <Col md={12}>
                      <Button
                        className="new-event--add"
                        color="primary"
                        type="button"
                        style={{
                          'marginBottom': '1.5rem'
                        }}
                        onClick={() => addNewPaymentItem({})}
                      >
                        +
                      </Button>
                    </Col>
                  ) : null}
                </Row>
                )
              }) : null}

              <Button
                className="mt-5"
                color="primary"
                type="button"
                onClick={() => addCustomPayment()}
              >
                {`Save`}
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}