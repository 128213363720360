const viewAny = (permissions) => {
  return permissions.includes('access-customers')
}

const list = (permissions) => {
  return hasPermission(permissions, 'access-customers')
}

const create = (permissions, auth_user_team) => {
  return hasPermission(permissions, 'create-customers') && auth_user_team && auth_user_team.id
}

const update = (permissions, customer) => {
  return hasPermission(permissions, 'update-customers')
}

const deletee = (permissions, customer) => {
  return hasPermission(permissions, 'delete-customers')
}

const hasPermission = (permissions, permission) => {
  return permissions.includes(permission)
}

export {
  viewAny,
  list,
  create,
  update,
  deletee,
  hasPermission,
};