import React, { useEffect, useState, useRef } from 'react'
import NotificationAlert from 'react-notification-alert'
import Pagination from 'react-js-pagination';
import axios from 'axios'
import Cookies from 'js-cookie'

import { css } from '@emotion/core';
import { ClipLoader } from 'react-spinners';

import * as constants from 'constants/index.js'
import CardsHeader from 'layouts/Headers/CardsHeader.js'

import InputCustom from 'views/pages/components/InputCustom'
import {
  InputArraySelect,
} from 'views/pages/components/InputSelect'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button
} from 'reactstrap'
import {ButtonContainer} from "@pages/reusable-components/styled-components";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: blue;
`;

export default function AssigningFunder() {
  const notificationAlert = useRef(null)

  const [token, setToken] = useState('')
  const [loading, setLoading] = useState(true)

  const [funders, setFunders] = useState([])
  const [devices, setDevices] = useState([])
  const [items, setItems] = useState('')

  const [funderId, setFunderId] = useState('')
  const [searchText, setSearchText] = useState('')

  const filterTracking = () => {
    fetchData(axios.CancelToken.source(), 1, token, funderId, searchText)
  }

  const resetTracking = () => {
    setFunderId('');
    setSearchText('');
    const source = axios.CancelToken.source()
    fetchData(source, 1, token, '')
  }

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    }
    notificationAlert.current.notificationAlert(options)
  }

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    fetchData(source, 1, grs_token, funderId)

    return () => {
      source.cancel()
    }
  }, [])

  const fetchData = async (source, pageNumber, token, funderId, searchText) => {
    setLoading(true)
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/tracking/funder/index`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page: pageNumber,
          funder_id: funderId,
          search: searchText ?? ''
        },
        cancelToken: source.token,
      })
      console.log('response :: ', response.data)
      const data = response.data

      setFunders(data.data.funders)
      setItems(data.data.devices)
      setDevices(data.data.devices.data)

      setTimeout(() => {
        setLoading(false)
      }, 500)
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
      }
    }
  }

  const changeShowable = (device) => {
    if (!funderId || funderId === '-') {
      notify('info', 'Information', 'Please select a funder')
      return
    }
    if (device.showable === true) {
      const devices2 = devices.map(item => {
        const returnValue = { ...item }
        if (item.id === device.id) {
          returnValue.showable = false
        }

        return returnValue
      })
      sendShowable(devices2, device, 0)
    } else {
      const devices2 = devices.map(item => {
        const returnValue = { ...item }
        if (item.id === device.id) {
          returnValue.showable = true
        }

        return returnValue
      })
      sendShowable(devices2, device, 1)
    }
  }

  const sendShowable = async (devices, device, showable) => {
    setDevices(devices)

    try {
       await axios({
        method: 'PUT',
        url: `${constants.API_URL}/tracking/funder/update`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          funder_id: funderId,
          device_id: device.id,
          showable: showable
        },
      })
    } catch (error) {
      console.error('error :: ', error)
      notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
    }

  }

  return (
    <>
      <CardsHeader name="Leads" parentName="Leads Management" currentName="Create" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Assigning funder</h1>
                    <p className="text-sm mb-0">Assign tracking devices to funder</p>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={4}>
                    <InputArraySelect
                      newLabel={`Funder`}
                      onChange={e => {
                        setFunderId(e.target.value)
                      }}
                      options={funders}
                      defaultOption={true}
                      valueKey={`id`}
                      labelKey={`name`}
                    />
                  </Col>
                  <Col md={4}>
                    <InputCustom
                      label={`Search`}
                      value={searchText ?? ''}
                      onChange={e => setSearchText(e.target.value)}
                      onKeyPress={e => {
                        if (e.charCode === 13) {
                          filterTracking()
                        }
                      }}
                    />
                  </Col>
                  <Col md={4} sm={12}>
                    <ButtonContainer style={{margin: 5}} onClick={() => filterTracking()}>Filter</ButtonContainer>
                    <ButtonContainer reverse style={{margin: 5}} onClick={() => resetTracking()}>Reset</ButtonContainer>
                  </Col>
                </Row>

                <div>
                  {!loading && devices.length > 0 ?
                    <div className="table-responsive ">
                      <table className="table">
                        <thead>
                          <tr className={'reports-table-header header-white-text'}>
                            <th tabIndex="0" style={{ width: 30 }}>{` `}</th>
                            <th tabIndex="0">{`Vehicle`}</th>
                            <th tabIndex="0">{`Stock no`}</th>
                            <th tabIndex="0">{`Current customer`}</th>
                            <th tabIndex="0">{`Contact number`}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            devices && devices.length > 0 &&
                            devices.map((device, index) => {
                              return <tr key={index}>
                                <td style={{ width: 30 }}>
                                  <InputCustom
                                    type={`checkbox`}
                                    onChange={() => {
                                      changeShowable(device)
                                    }}
                                    className="cursor-pointer"
                                    checked={device.showable ? device.showable : false}
                                  />
                                </td>
                                <td>
                                  {`${device.vehicle.vehicle_make.name} ${device.vehicle.vehicle_model.name}`}
                                </td>
                                <td>
                                  {device.vehicle.stock_no ? device.vehicle.stock_no : ''}
                                </td>
                                <td>
                                  {device.customer ? device.customer.full_name : '-'}
                                </td>
                                <td>
                                  {device.customer ? device.customer.mobile_number : '-'}
                                </td>
                              </tr>
                            })
                          }
                        </tbody>
                      </table>
                    </div> :
                    <ClipLoader
                      css={override}
                      size={40}
                      color={`#7B61E4`}
                      loading={loading}
                    />
                  }
                </div>
                {items && devices.length > 0 &&
                  <Row className="react-bootstrap-table-pagination">
                    <Col lg={6}>
                      <p className="text-sm text-black">
                        {`Showing rows ${(items.current_page - 1) * items.per_page + 1} to ${items.per_page * items.current_page > items.total ? items.total : items.per_page * items.current_page} of ${items.total}`}
                      </p>
                    </Col>
                    <Col lg={6} className="react-bootstrap-table-pagination-list">
                      <Pagination
                        hideDisabled
                        activePage={items.current_page}
                        totalItemsCount={items.total}
                        itemsCountPerPage={items.per_page}
                        onChange={(pageNumber) => fetchData(axios.CancelToken.source(), pageNumber, token, funderId, searchText)}
                        itemClass="page-item"
                        linkClass="page-link"
                        innerClass="pagination react-bootstrap-table-page-btns-ul"
                      />
                    </Col>
                  </Row>}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}