const viewAny = (permissions) => {
  return permissions.includes('access-user-management')
}

const create = (permissions) => {
  return hasPermission(permissions, 'access-user-management')
}

const update = (permissions, user) => {
  return hasPermission(permissions, 'access-user-management')
}

const deletee = (permissions, current_user, user) => {
  return hasPermission(permissions, 'delete-user-management') && current_user.id !== user.id
}

const manageUsers = (permissions) => {
  return hasPermissions(permissions, [
    'access-user-management',
    'delete-user-management'
  ])
}

const manageItems = (permissions) => {
  return hasPermissions(permissions, [
    'access-user-management',
    'delete-user-management'
  ])
}

const manageSettings = (permissions) => {
  return hasPermissions(permissions, [
    'access-user-management',
    'delete-user-management'
  ])
}

const hasPermission = (permissions, permission) => {
  return permissions.includes(permission)
}

const hasPermissions = (permissions, _permissions) => {
  return _permissions.some(permission => {
    return permissions.includes(permission)
  })
}

export {
  viewAny,
  create,
  update,
  deletee,
  manageUsers,
  manageItems,
  manageSettings,
  hasPermission,
};