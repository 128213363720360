import React, {useState} from 'react'
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "react-modern-calendar-datepicker";
import moment from 'moment'
import {
    ButtonContainer,
    CalendarInput,
} from "@pages/reusable-components/styled-components";
import {SelectWithoutInput} from "@pages/components/InputSelect";
import {Col, Row} from "reactstrap";
import CustomSearch from "@pages/components/CustomSearch";
import CalendarFilter from "@pages/components/CalendarFilter";

export default function FilterComponent({
                                            selectDate,
                                            makeValue = null,
                                            modelValue = null,
                                            driverValue = null,
                                            selectMake,
                                            loading = false,
                                            showHalf = false,
                                            selectModel,
                                            selectDriver,
                                            taskType,
                                            selectTaskType,
                                            makes = [],
                                            types = [],
                                            models = [],
                                            drivers = [],
                                            searchData,
                                            showCreateNew,
                                            isVehicles = false,
                                            showDateFilter = false
                                        }
) {
    const [selectedDay, setSelectedDay] = useState(null);
    const renderCustomInput = ({ref}) => (
        <CalendarInput
            width={'100%'}
            readOnly
            ref={ref} // necessary
            placeholder="Select date"
            value={selectedDay ? moment({...selectedDay, month: selectedDay.month - 1,}).format('DD/MMM/YYYY') : ''}
        />
    )


    return (

        !loading &&
        <Row className={'g-10 fleet-table-button-lg'}>
            {
                !isVehicles ?
                    <Col>
                        <DatePicker
                            value={selectedDay}
                            onChange={(e) => {
                                selectDate(moment({...e, month: e.month - 1,}).format('YYYY-MM-DD'))
                                setSelectedDay(e)
                            }}
                            renderInput={renderCustomInput}
                            shouldHighlightWeekends
                        />
                    </Col>
                    :
                    <Col sm={!showHalf && 3}>
                        <CustomSearch
                            onChange={searchData}
                            placeholder={'Search'}
                        />
                    </Col>
            }

            {
                !showHalf &&
                <>
                    <Col sm={6} lg={2}>
                        <SelectWithoutInput
                            label={makeValue ? makeValue : `Make`}
                            onSelect={e => {
                                selectMake(e)
                            }}
                            options={makes}
                            defaultOption={true}
                            valueKey={'id'}
                            labelKey={'name'}
                            imageLink={'directions_car_grey.png'}
                        />
                    </Col>
                    <Col sm={6} lg={2}>
                        <SelectWithoutInput
                            label={modelValue ? modelValue : `Model`}
                            onSelect={e => {
                                selectModel(e)
                            }}
                            options={models}
                            defaultOption={true}
                            valueKey={'vehicle_model_id'}
                            labelKey={'vehicle_model'}
                            imageLink={'loyalty.png'}
                        />
                    </Col>
                    {showDateFilter &&
                    <Col>
                        <CalendarFilter filterByDate={(data) => {
                            selectDate(data)
                        }}/>
                    </Col>

                    }

                    <Col sm={6} lg={2}>
                        <SelectWithoutInput
                            label={driverValue ? driverValue : `Driver`}
                            onSelect={e => {
                                selectDriver(e)
                            }}
                            options={drivers}
                            defaultOption={true}
                            valueKey={'id'}
                            labelKey={'name'}
                            imageLink={'loyalty.png'}
                        />
                    </Col>
                    <Col>
                        <SelectWithoutInput
                            label={taskType ? taskType : `Task Type`}
                            onSelect={e => {
                                selectTaskType(e)
                            }}
                            options={types}
                            defaultOption={true}
                            valueKey={'value'}
                            labelKey={'label'}
                            imageLink={'loyalty.png'}
                        />
                    </Col>
                </>
            }
            {!isVehicles && <ButtonContainer onClick={() => showCreateNew(true)}>
                <i className="fa fa-plus" aria-hidden="true"></i>
                New Task
            </ButtonContainer>}
        </Row>
    )
}