import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from "js-cookie";

import * as constants from 'constants/index.js';

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Modal,
} from "reactstrap";

import {
    create,
} from 'views/policies/DealPolicy';
import DynamicTable from "@pages/components/DynamicTable";
import {ButtonContainer} from "@pages/reusable-components/styled-components";
import {UPLOAD_DEALS_TO_GOOGLE_SHEETS_REQUEST} from "@redux/Deal/actions";
import usePrevious from "../../../hooks/useprevious";


export default function Deal() {

    const notificationAlert = useRef(null);
    const history = useHistory();

    const profilePermissions = useSelector((state) => state.getState).permissions;
    const {authUserTeam} = useSelector((state) => state.getState, shallowEqual);
    const [token, setToken] = useState('');
    const [deals, setDeals] = useState([]);

    const [dealStatus, setDealStatus] = useState('');
    const [dealerUsers, setDealerUsers] = useState('');
    const [items, setItems] = useState('');
    const [loading, setLoading] = useState(true);

    const [filterOptions, setFilterOptions] = useState('');
    const profileData = useSelector((state) => state.getState).profileData;
    const [confirmModal, setConfirmModal] = useState(false);
    const [selectedDealId, setSelectedDealId] = useState('');
    const [managers, setManagers] = useState([]);
    const [keys] = useState(
        [
            {
                key: 'full_name',
                text: 'Customer Name'
            },
            {
                key: 'mobile_number',
                text: 'Phone Number'
            },
            {
                key: 'owner',
                text: 'Created By'
            },
            {
                key:'account_manager',
                text: 'Account Manager'
            },
            {
                key: 'deal_status',
                text: 'Deal Status'
            },
            {
                key: 'stock_no',
                text: 'Deal Status'
            },
            {
                key: 'actions',
                text: 'Actions'
            },
        ]
    )
    const notify = (type, header, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
              {header}
          </span>
                    <span data-notify="message">
            <div dangerouslySetInnerHTML={{__html: message}}/>
          </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5
        };
        notificationAlert.current.notificationAlert(options);
    };
    useEffect(() => {
        let grs_token = Cookies.get('grs_token')
        setToken(grs_token)

        let savedPageNumber = 1
        let savedFilter = ''
        if (localStorage.getItem('grms_deals_search_filter')) {
            savedFilter = JSON.parse(localStorage.getItem('grms_deals_search_filter'))
            setFilterOptions(savedFilter)
        }
        const source = axios.CancelToken.source()

        fetchData(source, savedPageNumber, grs_token, savedFilter)

        axios({
            method: 'GET',
            url: `${constants.API_CORE_URL}/account-managers`,
            headers: {
                Authorization: `Bearer ${grs_token}`,
            },
        }).then((res)=>{
            let tempManagers = res.data.data.accountManagers;
            let manager = tempManagers.find(item=> item.id === profileData.id)
            let id = manager ? manager.id : null
            if (id) {
                setFilterOptions({...filterOptions,account_manager_id: id})
            }
            setManagers(tempManagers)
        }).catch((error)=>{
            console.log(error)
        })

        return () => {
            source.cancel()
        }
    }, [])

    const fetchData = async (source, pageNumber = 1, token, filter) => {
        setLoading(true)
        try {
            localStorage.setItem('grms_deals_search_filter', JSON.stringify(filter))
            const response = await axios({
                method: 'GET',
                url: `${constants.API_URL}/deal`,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    ...filter,
                    page: pageNumber,
                },
                cancelToken: source.token,
            })
            const data = response.data.data.response
            setDeals(data.items.data)
            setDealStatus(data.dealStatus)
            setDealerUsers(data.dealerUsers ? data.dealerUsers : '')
            setItems(data.items)

            setTimeout(() => {
                setLoading(false)
            }, 500);
        } catch (error) {
            setLoading(false);
            if (axios.isCancel(error)) {
                console.log('Request canceled :: ', error)
            } else {
                console.error('error :: ', error)
                notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
            }
        }
    }

    const editDeal = (id) => {
        history.push({
            pathname: '/admin/deals/' + id + '/edit'
        })
    }

    const deleteDeal = () => {
        axios({
            method: 'DELETE',
            url: constants.API_URL + '/deal/' + selectedDealId,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then(response => {
                setConfirmModal(false);
                if (response.status === 200) {
                    let data = response.data;
                    setDeals(data.data.data);
                    notify('success', 'Success', data.message);

                }
            })
            .catch(error => {
                setConfirmModal(false);
                console.error('error :: ', error);
                notify('warning', 'Warning', error.response.statusText);
            })
    }

    const filterPayments = () => {
        const source = axios.CancelToken.source()
        fetchData(source, 1, token, {
            ...filterOptions,
            search: filterOptions.searchValue,
            deal_status: filterOptions.deal_status === '-' ? undefined : filterOptions.deal_status,
            created_by: filterOptions.dealer_id === '-' ? undefined : filterOptions.dealer_id,
        })
    }

    function getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key] === value);
    }

    const goAdd = () => {
        history.push({
            pathname: '/admin/deals/create'
        })
    }
    const paginate = (page) => {
        const source = axios.CancelToken.source()
        fetchData(source, page, token, {
            ...filterOptions,
            search: filterOptions.searchValue,
            dealer_id: filterOptions.dealer_id === '-' ? undefined : filterOptions.dealer_id,
            deal_status: filterOptions.deal_status === '-' ? undefined : filterOptions.deal_status,
            created_by: filterOptions.created_by === '-' ? undefined : filterOptions.created_by,
        })
    }
    const clearFilters = () => {
        const source = axios.CancelToken.source()
        setFilterOptions({})
        fetchData(source,1,token,{})
    }
    useEffect(() => {
        if (Object.values(filterOptions).length > 0) {
            filterPayments()
        }
    }, [filterOptions])

    return (

        <>
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlert}/>
            </div>
            <Container className="admin-main-body" fluid>
                <Row className={'mt-3 mb-3'}>
                    <Col lg="6" sm="10" xs="7">
                        <h6 className="h2 d-inline-block mb-0">
                            Deal Management
                        </h6>{" "}

                    </Col>
                </Row>
                <Row>
                    <Col xl="12">
                        <Card>
                            <CardBody>
                                <DynamicTable
                                    showClearButton
                                    loading={loading}
                                    disableDetailsPage={true}
                                    clearFilers={clearFilters}
                                    tableData={deals}
                                    showButton={create(profilePermissions, authUserTeam)}
                                    addButtonText={'Add Deal'}
                                    addNew={goAdd}
                                    showClear={true}
                                    paginate={paginate}
                                    showStatus
                                    managers={managers}
                                    editEvent={(id)=>{editDeal(id)}}
                                    deleteEvent={(id)=>{
                                        setSelectedDealId(id)
                                        setConfirmModal(true)
                                    }}
                                    selectedStatus={dealStatus[filterOptions?.deal_status]}
                                    accountManagerId={filterOptions?.account_manager_id}
                                    creatorName={dealerUsers[filterOptions?.dealer_id]}
                                    onSearchChange={(e) => {
                                        setFilterOptions({
                                            ...filterOptions,
                                            searchValue: e.target.value,
                                        })
                                    }}
                                    setAccountManager={(data) => {
                                        setFilterOptions({
                                            ...filterOptions,
                                            account_manager_id: data,
                                        })
                                      }
                                    }
                                    showCreatedByFilter
                                    selectCreator={e => {
                                        setFilterOptions({
                                            ...filterOptions,
                                            dealer_id: getKeyByValue(dealerUsers, e),
                                        })
                                    }}
                                    selectStatus={e => {
                                        setFilterOptions({
                                            ...filterOptions,
                                            deal_status: getKeyByValue(dealStatus, e),
                                        })
                                    }}
                                    creators={Object.values(dealerUsers)}
                                    statuses={Object.values(dealStatus)}
                                    noKeyForFilter={true}
                                    pagination={items}
                                    showCarFilter={false}
                                    noPriority={false}
                                    keys={keys}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Modal
                    isOpen={confirmModal}
                    toggle={() => setConfirmModal(false)}
                    className="disable-scroll modal-dialog-centered modal-md"
                >
                    <div  className="modal-header">
                        Are you sure ?
                    </div>
                    <div className="modal-body">
                       You are going to delete the deal
                    </div>
                    <div className="modal-footer mb-3">
                        <Button
                            className="ml-auto"
                            color="danger"
                            type="button"
                            onClick={() => setConfirmModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="new-event--add"
                            color="success"
                            type="button"
                            onClick={() => deleteDeal()}
                        >
                            {`Delete`}
                        </Button>
                    </div>
                </Modal>
            </Container>
        </>
    )
}