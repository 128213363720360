import React, {useEffect, useRef, useState} from 'react'
import axios from 'axios'
import Cookies from "js-cookie"
import * as constants from 'constants/index.js'

import {useSelector} from "react-redux";
import {
    ButtonContainer, DateContainer,
    LoaderContainer,
    override, Text,
} from "../reusable-components/styled-components";
import {ClipLoader} from "react-spinners";
import ScheduleComponent from "../components/ScheduleComponent";
import {formatDate} from "constants/index.js";
import CreateNewDelivery from "../fleet/pages/components/CreateNewDeliveryTask";
import {Notification} from "@pages/components/Notification";
import NotificationAlert from "react-notification-alert";

export default function Schedule() {
    const [loading, setLoading] = useState(true);
    const [showCreateNew, setShowCreateNew] = useState(false);
    const notificationAlert = useRef(null);
    const [token, setToken] = useState('');
    const [tableData, setTableData] = useState({})
    const [currentMoth, setCurrentMonth] = useState('')
    const [currentYear, setCurrentYear] = useState('')
    const roles = useSelector(state => state.getState).roles;
    const [days] = useState(['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']);
    const [monthNames] = useState(["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"]);
    const [columns, setColumns] = useState([])
    useEffect(() => {
        if (roles.length) {
            const getData = async () => {
                const current = new Date();
                await updateDate(current)
                let grs_token = Cookies.get('grs_token')
                setToken(grs_token)
            }
            getData()
        }
    }, [roles])
    const updateDate = async (current) => {
        setCurrentMonth(monthNames[current.getMonth()])
        setCurrentYear(current.getFullYear())
        const date = dates(current)
        setColumns(date)
        const tempData = {
            from: date[0].currentDate,
            to: date.at(-1).currentDate,
        }
        setLoading(true)
        await fetchData(token, tempData)
    }
    const nextWeek = async () => {
        const lastDay = new Date(columns.at(-1).currentDate)
        const tomorrow = new Date(lastDay + 1)
        await updateDate(tomorrow)
    }

    const previousWeek = async () => {
        const firstDay = new Date(columns[0].currentDate)
        let today = new Date()
        today = new Date(formatDate(today))
        const diffTime = Math.abs(firstDay - today);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays > 6) {
            let yesterday = new Date()
            yesterday.setDate(firstDay.getDate() - 7)
            await updateDate(yesterday)
        } else if (diffDays) {
            await updateDate(today)
        }

    }

    const fetchData = (token, data = {from: '', to: ''},) => {
        axios({
            method: 'GET',
            url: `${constants.FLEET_URL}/job-booker/get-schedule`,
            headers: {
                Authorization: 'Bearer ' + token
            },
            params: data
        })
            .then(response => {
                let objectOfNames = {}
                let tempData = response.data.data.data
                tempData.forEach(item => {
                    if (!objectOfNames.hasOwnProperty(item.task_type)) {
                        objectOfNames[item.task_type] = []
                    }
                })
                Object.keys(objectOfNames).forEach(key => {
                    objectOfNames[key] = tempData.filter(item => {
                        return item.task_type.toString() === key.toString()
                    })
                })

                setTableData(objectOfNames)
                setLoading(false)
            })
            .catch(error => {
                Notification('warning', 'Warning', 'Something went wrong please try again later!',notificationAlert);
                setLoading(false)
            })
    }

    function dates(current) {
        let tempDates = []
        for (let i = 0; i < 7; i++) {
            const curDay = formatDate(current)
            tempDates.push({
                day: curDay.split('-')[2],
                weekDay: days[new Date(curDay).getDay()],
                currentDate: curDay,
            })
            current.setDate(current.getDate() + 1);
        }
        return tempDates;
    }

    const sortTasks = async  (tasks) =>{
       try {
           const source = axios.CancelToken.source()

            await axios({
               method: 'POST',
               url: `${constants.FLEET_URL}/job-booker/sort-delivery-tasks`,
               headers: {
                   Authorization: `Bearer ${token}`,
               },
               data: tasks,
               cancelToken: source.token,
           })

       } catch (error) {
           Notification('warning', 'Warning', 'Something went wrong please try again later!',notificationAlert);

           if (axios.isCancel(error)) {
               console.log('Request canceled :: ', error)
           } else {
               console.error('error :: ', error)
           }
       }
   }

    const updateTask = async (task) => {
        try {
            const source = axios.CancelToken.source()

            await axios({
                method: 'POST',
                url: `${constants.FLEET_URL}/job-booker/set-delivery-task`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: task,
                cancelToken: source.token,
            })

        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled :: ', error)
            } else {
                console.error('error :: ', error)
            }
        }
    }

    const deleteDelivery = async (id) => {
              try {
             await axios({
                method: 'DELETE',
                url: `${constants.FLEET_URL}/job-booker/delete-delivery/${id}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                window.location.reload(true);
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled :: ', error)
            } else {
                console.error('error :: ', error)
            }
        }
    }

    const updateGrsCollectionDate = async (task) => {
       try {
            const source = axios.CancelToken.source()

            await axios({
                method: 'POST',
                url: `${constants.FLEET_URL}/job-booker/set-grs-delivery-date`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: task,
                cancelToken: source.token,
            })

        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled :: ', error)
            } else {
                console.error('error :: ', error)
            }
        }
    }
    return (
        <>
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlert} />
            </div>
            {
                loading ?
                    <LoaderContainer>
                        <ClipLoader
                            css={override}
                            size={40}
                            color={`#7B61E4`}
                            loading={loading}
                        />
                    </LoaderContainer> :

                    <>
                        {
                            showCreateNew ? <CreateNewDelivery updateData={() => fetchData(token)}
                                                               setShowCreateNew={setShowCreateNew}
                                                               setLoading={setLoading}/> :
                                <>
                                    <div className={'d-flex justify-content-between mt-3'}>
                                        <DateContainer>
                                            <i onClick={previousWeek} className='fa fa-angle-left cursor-pointer'/>
                                            <i onClick={nextWeek} className='fa fa-angle-right cursor-pointer'/>
                                            <Text bold>{currentMoth}, </Text>
                                            <Text bold>{currentYear}</Text>
                                        </DateContainer>
                                        <ButtonContainer onClick={() => setShowCreateNew(true)}>
                                            <i className="fa fa-plus" aria-hidden="true"></i>
                                            New Task
                                        </ButtonContainer>
                                    </div>
                                    <ScheduleComponent
                                        updateTask={updateTask}
                                        sortTasks={sortTasks}
                                        deleteDelivery={deleteDelivery}
                                        updateGrsCollectionDate={updateGrsCollectionDate}
                                        columns={columns}
                                        tableData={tableData}/>
                                </>


                        }
                    </>
            }
        </>

    )
}