import React, { useEffect, useState, useRef } from 'react';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import InputSideCustom from 'views/pages/components/InputSideCustom';
import TableCustom from 'views/pages/components/TableCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from 'reactstrap';

export default function ReportDealContracts() {
  const notificationAlert = useRef(null);

  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState('');
  const [filter, setFilter] = useState('');

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    fetchData(1, source, grs_token, '')

    return () => {
      source.cancel()
    };
  }, [])

  const fetchData = async (pageNumber = 1, source, token, filter) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/report/deals/current-contracts`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ...filter,
          page: pageNumber,
        },
        cancelToken: source.token,
      })
      console.log('response :: ', response.data)
      const data = response.data

      setItems(data.data.items)
      setFilter({
        ...data.data.filter,
        from: data.data.filter.from? constants.formatDate(data.data.filter.from): '',
        to: data.data.filter.to? constants.formatDate(data.data.filter.to): '',
      })

      setTimeout(() => {
        setLoading(false)
      }, 500);
    } catch (error) {
      setLoading(false)
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      }
    }
  }

  const filterItems = () => {
    const source = axios.CancelToken.source()
    fetchData(1, source, token, {
      ...filter,
      from: constants.formatDate(filter.from),
      to: constants.formatDate(filter.to),
    })
  }

  const resetItems = () => {
    setFilter('')
    const source = axios.CancelToken.source()
    fetchData(1, source, token, '')
  }

  return (
    <>
      <CardsHeader name="Current Deals" parentName="Home" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <h1 className="title">Sales</h1>
                <p className="text-sm mb-0">Current Deals Overview</p>
              </CardHeader>
              <CardBody>
                <Container>
                  <Row>
                    <Col md={6} sm={12}>
                      <InputSideCustom
                        type={`date`}
                        label={`From`}
                        value={filter && filter.from ? filter.from : ''}
                        onChange={e => setFilter({
                          ...filter,
                          from: e.target.value
                        })}
                      />
                    </Col>
                    <Col md={6} sm={12}>
                      <InputSideCustom
                        type={`date`}
                        label={`To`}
                        value={filter && filter.to ? filter.to : ''}
                        onChange={e => setFilter({
                          ...filter,
                          to: e.target.value
                        })}
                      />
                    </Col>
                  </Row>
                  <div className="d-flex flex-row justify-content-center">
                    <Button className="w-50" color="success" onClick={() => filterItems()}>Filter</Button>
                    <Button className="w-50" color="danger" onClick={() => resetItems()}>Reset</Button>
                  </div>
                </Container>
                <div className="d-flex flex-row justify-content-between flex-wrap mt-3">
                  <div className="p-2">
                    <h2 className="mb-0">{`Total Contract Price`}</h2>
                    <h2 className="mb-0">{`${constants.CURRENCY} ${items.total? items.total: 0}`}</h2>
                  </div>
                </div>
                {items.data && 
                <TableCustom 
                  loading={loading}
                  items={items.data.data}
                  pagination={items.data}
                  onChange={(pageNumber) => fetchData(pageNumber, axios.CancelToken.source(), token, filter)}
                  columns={[
                    {
                      dataField: "id",
                      text: "Contract Number"
                    },
                    {
                      dataField: "stock_no",
                      text: "Stock No"
                    },
                    {
                      dataField: "rego",
                      text: "Rego"
                    },
                    {
                      dataField: "dealer_name",
                      text: "Office"
                    },
                    {
                      dataField: "full_name",
                      text: "Name"
                    },
                    {
                      dataField: "address",
                      text: "Address"
                    },
                    {
                      dataField: "mobile_number",
                      text: "Mobile"
                    },
                    {
                      dataField: "email",
                      text: "Email"
                    },
                    {
                      dataField: "start_date",
                      text: "Start Date"
                    },
                    {
                      dataField: "term",
                      text: "Weeks"
                    },
                    {
                      dataField: "end_date",
                      text: "End Date"
                    },
                    {
                      dataField: "vehicle_price",
                      text: "Cash Price"
                    },
                    {
                      dataField: "initial_payment",
                      text: "Deposit"
                    },
                    {
                      dataField: "expected_profit",
                      text: "Interest"
                    },
                    {
                      dataField: "monthly_payment",
                      text: "Weekly Repayment"
                    },
                    {
                      dataField: "total_income",
                      text: "Total Contract Price"
                    }
                  ]}
                />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}