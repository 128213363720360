import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import * as constants from '../../../constants';
import InputCustom from '../components/InputCustom';
import * as taskActions from '../../../redux/Task/actions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

function HistoryAndComments({ task }) {
  const [comment, setComment] = useState('');
  const [viewButtons, setViewButtons] = useState(false);
  const [selectedComment, setSelectedComment] = useState('');
  const [comments, setComments] = useState(task.task_comments);

  const { createCommentSuccess, updateCommentSuccess } = useSelector(
    (state) => state.taskState,
    shallowEqual
  );

  const dispatch = useDispatch();

  const ButtonStyle = {
    padding: 5,
    lineHeight: 0,
    margin: 1,
  };

  useEffect(() => {
    if (createCommentSuccess) {
      setComment('');
    }
  }, [createCommentSuccess]);

  useEffect(() => {
    if (updateCommentSuccess) {
      setSelectedComment('');
    }
  }, [updateCommentSuccess]);

  useEffect(() => {
    const comments = task.task_comments?.map((comment) => ({
      ...comment,
      editMode: false,
    }));
    setComments(comments);
  }, [task]);

  const newComment = (comment, id) => {
    dispatch({
      type: taskActions.CREATE_COMMENT_REQUEST,
      comment,
      id,
    });
  };

  const deleteComment = (commentId, taskId) => {
    dispatch({
      type: taskActions.DELETE_COMMENT_REQUEST,
      commentId,
      taskId,
    });
  };

  const updateComment = (commentId) => {
    dispatch({
      type: taskActions.UPDATE_COMMENT_REQUEST,
      id: commentId,
      comment: selectedComment,
      taskId: task.id,
    });
  };

  const editComment = ({ comment }, index) => {
    setSelectedComment(comment);

    const commentsCopy = [...comments];
    commentsCopy[index].editMode = true;
    setComments(commentsCopy);
  };

  return (
    <div>

          <Row>
            <Col md={3}>
              <h3>History</h3>
            </Col>
            <Col style={{ overflowY: 'scroll', height: '250px', border: '1px gray solid', marginBottom: '5px' }} md={9}>
              {task?.logs?.length > 0 &&
              task.logs.map((log, index) => {
                return log?.type ? (
                    <div key={index} style={{ marginTop: 20 }}>
                      <div
                          style={{
                            display: 'flex',
                            whiteSpace: 'nowrap',
                          }}
                      >
                        <div style={{ flex: '0 1 0%' }}>{log?.owner?.name}</div>
                        <div>-></div>
                        <div style={{flex: '0 1 0%'}}>{log?.new_value}</div>
                        <div>-></div>
                        <div style={{flex: '0 1 0%' }}>{constants.formatDateFullGB(log?.updated_at)}</div>
                      </div>
                    </div>
                ) : null;
              })}
            </Col>

          </Row>
        <Row style={{marginTop: '10px' }}>
          <Col md={3}>
            <h3>Notes</h3>
          </Col>
          <Col md={9} style={{overflowY: 'scroll', height: '200px', border: '1px gray solid'}}>
            {comments?.length > 0 &&
            comments.map((comment, index) => {
              return (
                  <div style={{
                    display: 'flex',
                    marginTop: 20,
                    whiteSpace: 'nowrap',
                  }} key={index} >
                    <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                    >
                      <div
                          style={{flex: '0 1 0%' }}
                      >
                          {comment?.owner?.name}
                        </div>
                      <div>-></div>
                      {comment.editMode ? (
                          <InputCustom
                              value={selectedComment || ''}
                              onChange={(e) => setSelectedComment(e.target.value)}
                              onBlur={() => {
                                updateComment(comment.id);
                              }}
                          />
                      ) : (
                          <div >{comment.comment}</div>
                      )}
                      <div>-></div>
                      <div
                          style={{ flex: '0 1 0%' }}
                      >
                          {constants.formatDateFullGB(comment?.updated_at)}
                        </div>
                    </div>

                    <div>
                        <span
                            className="edit-comment badge-success"
                            style={{ marginLeft: 45 }}
                            onClick={() => editComment(comment, index)}
                        >
                          Edit
                        </span>
                      <span
                          className="edit-comment badge-danger"
                          style={{ marginLeft: 10 }}
                          onClick={() => {
                            deleteComment(comment.id, task.id);
                          }}
                      >
                          Delete
                        </span>
                    </div>
                  </div>
              );
            })}
          </Col>
        </Row>
        <div className={'mt-2'}>
          <InputCustom
              placeholder={'Add note'}
              newLabel={'Add note'}
              style={{ marginBottom: 0 }}
              onChange={(e) => setComment(e.target.value)}
              value={comment}
              onFocus={() => setViewButtons(true)}
          />
        </div>
        {viewButtons && (
            <div style={{ float: 'right' }}>
              <Button
                  style={ButtonStyle}
                  color="secondary"
                  onClick={() => {
                    newComment(comment, task.uuid);
                    setViewButtons(false);
                  }}
              >
                <i className="ni ni-check-bold" />
              </Button>
              <Button
                  color="secondary"
                  style={ButtonStyle}
                  onClick={() => {
                    setComment('');
                    setViewButtons(false);
                  }}
              >
                <i className="ni ni-fat-remove" />
              </Button>
            </div>
        )}
    </div>
  );
}
export default React.memo(HistoryAndComments);

HistoryAndComments.propTypes = {
  task: PropTypes.object,
};
