import React, { useRef, useEffect } from 'react';
import { getKashflowCustomer} from 'api/modules.api';
import NotificationAlert from "react-notification-alert";
import XeroPaymentSettings from './form/XeroPaymentSettings';
import {
    Row,
    Col,
} from "reactstrap";

export default function XeroDetail({ deal }) {
    const notificationAlert = useRef(null)

    useEffect(() => {
        if (deal.customer_id) {
            fetchKashflowCustomer(deal.customer_id)
        }
    }, [deal.customer_id]);



    const fetchKashflowCustomer = (customerId) => {
        getKashflowCustomer(customerId).then(response => {
        })
    }

    return (
        <Row>
            <Col xl="12">
                <div className="rna-wrapper">
                    <NotificationAlert ref={notificationAlert} />
                </div>
            </Col>
            <Col>
                <XeroPaymentSettings deal={deal} />
            </Col>
        </Row>)
}