import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import NotificationAlert from 'react-notification-alert'
import axios from 'axios'
import Cookies from 'js-cookie'

import * as constants from 'constants/index.js'

import {
    Container,
    Row,
    Col,
    Card,
    CardBody, Modal, Button,
} from 'reactstrap'

import DynamicTable from "../components/DynamicTable";
import { formatDate } from "constants/index.js";
import {ButtonContainer, LoaderContainer, override} from "../reusable-components/styled-components";
import { ClipLoader } from "react-spinners";
import moment from "moment";
import {Notification} from "@pages/components/Notification";

export default function JobList() {
    const notificationAlert = useRef(null)
    const location = useLocation()
    const history = useHistory();
    const [token, setToken] = useState('')
    const [isCompleted, setIsCompleted] = useState(false)
    const [loading, setLoading] = useState(true)
    const [disableAction, setDisableAction] = useState(false)
    const [models, setModels] = useState([])
    const [makes, setMakes] = useState([])
    const [drivers, setDrivers] = useState([])
    const [modelsCopy, setModelsCopy] = useState([])
    const [pagination, setPagination] = useState({})
    const [boardData, setBoardData] = useState([])
    const [makeValue, setMakeValue] = useState('')
    const [modelValue, setModelValue] = useState('')
    const [taskType, setTaskType] = useState('')
    const [driver, setDriver] = useState('')
    const [deleteModal, setDeleteModal] = useState({
        show: false,
        header: '',
        body: '',
        onClick: null,
    })
    const [filter, setFilter] = useState({
        from: moment().startOf('month').format('YYYY-MM-DD'),
        to: moment().endOf('month').format('YYYY-MM-DD')
    });
    const [jobKeys] = useState(
        [
            {
                key: 'vehicle',
                text: 'Vehicle'
            },
            {
                key: 'reg_no',
                text: 'Reg.Number'
            },
            {
                key: 'task_type',
                text: 'Task'
            },
            {
                key: 'full_name',
                text: 'Customer Name'
            },
            {
                key: 'execution_date',
                text: 'Date of Execution '
            },
            {
                key: 'driver_name',
                text: 'Driver Name'
            },
            {
                key: 'status',
                text: 'Deal Status'
            },
            {
                key: 'view',
                text: 'View'
            },
            {
                key: 'delete',
                text: 'Action'
            },
        ]
    )

    const [types] = useState([
        {
            value: 'delivery',
            label: 'Delivery'
        },
        {
            value: 'collect',
            label: 'Collect'
        },

    ]);

    useEffect(() => {
       try {
           const grs_token = Cookies.get('grs_token')
           setToken(grs_token)
           setLoading(true)
           setIsCompleted(location.pathname === '/admin/job-booker/jobs-completed')
           const date = formatDate(new Date())
           const getData = async () => {
               await fetchData(grs_token, date)
               const makeRequest = await axios({
                   method: 'GET',
                   url: `${constants.FLEET_URL}/job-booker/get-makes-models`,
                   headers: {
                       Authorization: 'Bearer ' + token
                   }
               })
               let makesTemp = []
               makeRequest.data.data.forEach(item => {
                   if (!makesTemp.find(make => make.id === item.vehicle_make_id)) {
                       makesTemp.push({ id: item.vehicle_make_id, name: item.vehicle_make })
                   }
               })
               setMakes(makesTemp)
               setModelsCopy(makeRequest.data.data)
               const driversRequest = await axios({
                   method: 'GET',
                   url: `${constants.FLEET_URL}/job-booker/get-available-drivers`,
                   headers: {
                       Authorization: 'Bearer ' + token
                   }
               })
               setDrivers(driversRequest.data.data)
               setLoading(false)
           };
           getData()
       } catch (e) {
           setLoading(false)
           Notification('warning', 'Warning', 'Something went wrong please try again later!',notificationAlert);
       }
    }, [location])
    const fetchData = async (token, data = {}) => {
        try {
            const source = axios.CancelToken.source();
            const isJobList = location.pathname === '/admin/job-booker/job-list';
            const statusFilter = isJobList ? {deal_status:  'initial_paid'} : {status: 'completed'}
            const response = await axios({
                method: 'GET',
                url: `${constants.FLEET_URL}/job-booker/get-vehicles`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: { ...filter, ...statusFilter },
                cancelToken: source.token,
            })
            const data = response.data
            setLoading(false)
            setPagination(data.data)
            // setImgSrc(arr => [...arr, reader.result]);
            setBoardData(data.data.data)
        } catch (error) {
            Notification('warning', 'Warning', 'Something went wrong please try again later!',notificationAlert);
            setLoading(false)
            if (axios.isCancel(error)) {
                console.log('Request canceled :: ', error)
            } else {
                console.error('error :: ', error)
            }
        }
    }

    const filterVehicle = async (data = null) => {
        const source = axios.CancelToken.source()
        await fetchData(source, 1, token, filter, true)
    }
    const setMakeFilter = async (e) => {
        setMakeValue(e.name)
        setModels(modelsCopy.filter(item => item.vehicle_make_id === e.id))
        setModelValue('')
        let tempFilter = filter
        tempFilter.make_id = e.id
        tempFilter.model_id = null
        setFilter(tempFilter)
        await fetchData(token, tempFilter)
    }
    const selectDate = async (e) => {
        let tempFilter = filter
        tempFilter.from = e.from
        tempFilter.to = e.to
        setFilter(tempFilter)
        await fetchData(token, tempFilter)
    }
    const setModelFilter = async (e) => {
        setModelValue(e.name)
        let tempFilter = filter
        tempFilter.model_id = e.vehicle_model_id
        setFilter(tempFilter)
        await fetchData(token, tempFilter)
    }

    const selectDriver = async (e) => {
        let tempFilter = filter
        setDriver(e.name)
        tempFilter.driver = e.id
        setFilter(tempFilter)
        await fetchData(token, tempFilter)
    }
    const selectTaskType = async (e) => {
        let tempFilter = filter
        setTaskType(e.value)
        tempFilter.type = e.value
        setFilter(tempFilter)
        await fetchData(token, tempFilter)
    }
    const deleteTask = (id) => {
        setDeleteModal({
            ...deleteModal,
            show: true,
            header: 'Delete current delivery',
            body: 'Are you sure you want to delete this delivery?',
            onClick: () => deleteDelivery(id),
        })
    }
    const deleteDelivery = async (id) => {
        try {
            await axios({
                method: 'DELETE',
                url: `${constants.FLEET_URL}/job-booker/delete-delivery/${id}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            window.location.reload(true);
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled :: ', error)
            } else {
                console.error('error :: ', error)
            }
        }
    }
    const searchByText = async (e) => {
        let tempFilter = filter
        tempFilter['search'] = e.target.value
        setFilter(tempFilter)
        await filterVehicle(tempFilter)
    }
    const clearFilters = async () => {
        setFilter({})
        setTaskType(null)
        setDriver(null)
        setModelValue(null)
        setModels([])
        setMakeValue(null)
        await filterVehicle({})
    }
    const getVehicleId = (id) => {
        history.push(`/admin/schedule-details?id=${id}`)
    }
    const generatePdf = async (id) => {
        setDisableAction(true)
        await axios({
            method: 'POST',
            url: `${constants.FLEET_URL}/job-booker/create-invoices`,
            headers: {
                Authorization: 'Bearer ' + token
            },
            data: { delivery_id: id },
        }).then(res => {
            setDisableAction(false)
            fetchData(token, filter, true)
        }).catch(err => {
            setDisableAction(false)
        })

    }
    const paginate = async (page) => {
        let tempFilter = filter
        tempFilter.page = page
        setFilter(tempFilter)
        await fetchData(token, tempFilter)
    }
    return (
        loading ?
            <LoaderContainer>
                <ClipLoader
                    css={override}
                    size={40}
                    color={`#7B61E4`}
                    loading={loading}
                />
            </LoaderContainer>
            :
            <>
                <div className="rna-wrapper">
                    <NotificationAlert ref={notificationAlert} />
                </div>
                <Container className="admin-main-body" fluid>
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <DynamicTable
                                        make={makes}
                                        model={models}
                                        showClearButton
                                        pagination={pagination}
                                        paginate={paginate}
                                        clearFilers={clearFilters}
                                        showAnyFilter={false}
                                        showDateFilter={isCompleted}
                                        selectDate={selectDate}
                                        showJobBookerFilter={true}
                                        onSearchChange={e => searchByText(e)}
                                        selectMake={setMakeFilter}
                                        selectModel={setModelFilter}
                                        selectDriver={selectDriver}
                                        selectTaskType={selectTaskType}
                                        driverValue={driver ? driver : ''}
                                        goToDetails={getVehicleId}
                                        makeValue={makeValue ? makeValue : ''}
                                        modelValue={modelValue ? modelValue : ''}
                                        tableData={boardData}
                                        generatePdf={generatePdf}
                                        disableAction={disableAction}
                                        keys={jobKeys}
                                        deleteTask={deleteTask}
                                        enableDelete={true}
                                        drivers={drivers}
                                        taskType={taskType}
                                        types={types} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Modal
                        isOpen={deleteModal.show}
                        toggle={() => setDeleteModal({
                            ...deleteModal,
                            show: false
                        })}
                        className="modal-dialog-centered"
                    >
                        <div className="modal-header">
                            <h6 className="modal-title">{deleteModal.header}</h6>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => setDeleteModal({
                                    ...deleteModal,
                                    show: false
                                })}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {deleteModal.body}
                        </div>
                        <div className="modal-footer">
                            <ButtonContainer
                                reverse
                                onClick={deleteModal.onClick}
                            >
                                {`Delete`}
                            </ButtonContainer>
                            <ButtonContainer
                                onClick={() => setDeleteModal({
                                    ...deleteModal,
                                    show: false
                                })}
                            >
                                {`Close`}
                            </ButtonContainer>
                        </div>
                    </Modal>
                </Container>
            </>
    )
}