import React, { useContext } from 'react';

import { PaymentContext } from '../context';

import General from './General';
import Test from './Test';

export default function Content() {
  const context = useContext(PaymentContext);
  const {
    tab
  } = context;

  return (
    <>
      {tab === 'info' && <General />}
      {tab === 'test' && <Test />}
    </>
  );
}