import React, { useEffect, useState, useRef } from 'react';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Alert,
  Input
} from 'reactstrap';

export default function Modules() {
  const notificationAlert = useRef(null);

  const [token, setToken] = useState('');
  const [modules, setModules] = useState('');
  
  const [paymentErrors, setPaymentErrors] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    if (Cookies.get('grs_token')) {
      let grs_token = Cookies.get('grs_token')
      setToken(grs_token)

      axios({
        method: 'GET',
        url: constants.API_URL + '/dealer/profile',
        headers: {
          Authorization: 'Bearer ' + grs_token
        },
      })
        .then(response => {
          console.log('res :: ', response.data)
          let data = response.data
          setModules(data.data.modules)
        })
        .catch(error => {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
        })
    }
  }, [])

  const actionRequire = (url) => {
    window.open(url, '_blank');
  }

  const saveConfiguration = () => {
    let formData = new FormData();
    Object.keys(modules).forEach(key => {
      if (modules[key]) {
        Object.keys(modules[key]).forEach(key2 => {
          if (modules[key][key2] && modules[key][key2].type !== 'redirect_access') {
            formData.append(`${key}[${key2}]`, modules[key][key2].value? modules[key][key2].value: '')
          }
        })
      }
    })
    for (var pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }
    axios({
      method: 'POST',
      url: constants.API_URL + '/dealer/profile-module-configuration',
      headers: {
        Authorization: 'Bearer ' + token,
      },
      data: formData
    })
      .then(response => {
        if (response.status === 200) {
          notify('success', 'Success', response.data.message);
        }
      })
      .catch(error => {
        setPaymentErrors('');
        console.error('error :: ', error.response);
        if (error.response && error.response.data.errors) {
          let errors = error.response.data.errors;
          setPaymentErrors(errors);
          return
        }
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred.');
      })
  }

  return (
    <>
      <CardsHeader name="Dealer Admin" parentName="" currentName="" breadcrumb={false} />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <div>
                    <h1 className="title mb-0">Modules</h1>
                    <p className="text-small mb-0">You can create and edit your company's profile. This is informations are also going to be used in the contracts.</p>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                {modules &&
                  Object.keys(modules).map(key => {
                    return (
                      <Row key={key} className="mb-3 border-bottom">
                        <Col md={12}>
                          <Row className="align-items-center">
                            <Col md={12}>
                              <h2 className="mb-4">{`${key.charAt(0).toUpperCase()}${key.slice(1)}`}</h2>
                            </Col>
                          </Row>
                          {modules[key] && Object.keys(modules[key]).length > 0 ?
                            Object.keys(modules[key]).map(key2 => {
                              if (modules[key][key2].type === 'redirect_access') {
                                return (
                                  <FormGroup key={key2}>
                                    <label className="form-control-label">{modules[key][key2].label}</label>
                                    {modules[key][key2].value.value === true ?
                                      <Alert color="primary">
                                        <strong>Already have access!</strong>
                                        {modules[key][key2].optional.afterCheck &&
                                          <strong>{modules[key][key2].optional.afterCheck}!</strong>
                                        }
                                      </Alert> :
                                      <div>
                                        <h4 className="text-dark">{modules[key][key2].value.message}</h4>
                                        <Button className="w-50 mt-4" color="success" type="button" onClick={() => actionRequire(modules[key][key2].value.actionRequired)}>Click Here</Button>
                                      </div>
                                    }
                                  </FormGroup>
                                )
                              } else {
                                return (
                                  <FormGroup key={key2}>
                                    <label className="form-control-label">{modules[key][key2].label}</label>
                                    <Input
                                      type="text"
                                      placeholder={modules[key][key2].label}
                                      value={modules[key][key2].value ? modules[key][key2].value : ''}
                                      onChange={e => setModules({
                                        ...modules,
                                        [key]: {
                                          ...modules[key],
                                          [key2]: {
                                            ...modules[key][key2],
                                            value: e.target.value
                                          }
                                        }
                                      })}
                                      invalid={paymentErrors && paymentErrors[`${key}.${key2}`] ? true : false}
                                    />
                                    <div className="invalid-feedback">
                                      {paymentErrors[`${key}.${key2}`]}
                                    </div>
                                  </FormGroup>
                                )
                              }
                            }) :
                            <h5 class="mb-1">{`No configuration required!`}</h5>
                          }
                        </Col>
                      </Row>
                    )
                  })
                }
                {modules && Object.keys(modules).length > 0 &&
                  <Button className="w-100 mt-4" color="success" onClick={() => saveConfiguration()}>Save Configuration</Button>
                }
                {modules && Object.keys(modules).length === 0 &&
                  <h3 className="mb-0">{`You have no active module!`}</h3>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}