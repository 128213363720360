import React, {useState} from 'react';
import {useSortable} from "@dnd-kit/sortable";
import {Button,Col, Popover, Row} from "reactstrap";
import {CSS} from '@dnd-kit/utilities';
import {
    DeliveryTableItem,
    Text,
    TextContainer
} from "@pages/reusable-components/styled-components";
import {useHistory} from "react-router";
import {formatDate, parseToWord} from "../../../../constants";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";

export default function Draggable({type, text, id = '', deliveryId = '', value = {},deleteItem}) {
    const history = useHistory()
    const [more, setMore] = useState(false)
    const {attributes, listeners, setNodeRef, transform, transition}
        = useSortable({id: id})
    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    }
    return (
        <div id={`s-${id}`}   ref={setNodeRef} className={'position-relative'}>
            <Button className={'button-clear'}>
                <DeliveryTableItem  id={id} type={text}
                                   style={style} {...attributes} {...listeners}>
                    <div>
                        {type === 'collect' ?
                            <IcoMoon
                                className={'cursor-pointer'}
                                iconSet={IconSet}
                                icon={'carbon_delivery'}
                                size={16}
                            />
                            :
                            <IcoMoon
                                className={'cursor-pointer'}
                                iconSet={IconSet}
                                icon={'carbon_delivery-add'}
                                size={16}
                            />
                        } <Text color='#ffffff'>{text}</Text>
                    </div>
                    <i onMouseDown={() => setMore(!more)} className="ni ni-up rotate-down mr-1 w-20 cursor-pointer"/>
                </DeliveryTableItem>
            </Button>

            <Popover
                placement="bottom"
                target={`s-${id}`}
                isOpen={more}
                trigger="focus"
                style={{padding: 10}}
            >
                <div className={'d-flex justify-content-between flex-row mb-4'}>
                    <Text size={'16px'}
                          bold>{value.task_type === 'grs_collection' ? 'Grs collection' : parseToWord(value.task_type)}</Text>
                    <img height='16px' width='16px' onMouseDown={() => setMore(!more)}
                         className={'cursor-pointer'}
                         src={require(`assets/images/xmark-solid.svg`)} alt=""/>
                </div>
                <Col>
                    <Row className={'align-items-center'}>
                            {value.task_type !== 'grs_collection' && <img height='25px' width='25px'
                                                                          src={require(`assets/images/taskEdit/person.png`)}
                                                                          alt=""/>}
                            {value.task_type !== 'grs_collection' &&
                            <Text size={'12px'} bold>
                                {value.task_type === 'collect_from_driver' ?
                                    'From driver:' : value.task_type === 'deliver_to_driver' ? 'To driver' : 'Customer:'}</Text>}
                            {value.task_type !== 'grs_collection' &&
                            <Text title={`${value.full_name || value.customer_name},${value.customer_address ||
                            value.address},${value.customer_postal_code || value.postal_code}`}
                                  className={'address-width'} size={'12px'}>
                                {value.full_name || value.customer_name}, {value.address || value.customer_address}</Text>}
                    </Row>
                    <Row className={'align-items-center'}>
                        <img height='25px' width='25px'
                             src={require(`assets/images/taskEdit/priority.png`)} alt=""/>
                        <Text size={'12px'} bold>Task Type:</Text>
                        <Text size={'12px'}>{parseToWord(value.task_type)}</Text>
                    </Row>
                    <Row className={'align-items-center'}>
                        <img height='25px' width='25px'
                             src={require(`assets/images/taskEdit/date_grey.png`)} alt=""/>
                        <Text size={'12px'}
                              bold>{value.task_type === 'grs_collection' ? 'Grs collection time:' : 'Execution time:'}</Text>
                        <Text size={'12px'}>{formatDate(value.execution_date)}</Text>
                    </Row>
                    <Row className={'align-items-center'}>
                        <img height='25px' width='25px'
                             src={require(`assets/images/taskEdit/person.png`)} alt=""/>
                        <Text size={'12px'} bold>Driver:</Text>
                        <Text size={'12px'}>{value.driver_name}</Text>
                    </Row>
                    <Row className={'align-items-center'}>
                        <img height='25px' width='25px'
                             src={require(`assets/images/taskEdit/status_grey.png`)} alt=""/>
                        <Text size={'12px'} bold>Status:</Text>
                        <Text size={'12px'}>{parseToWord(value.status)}</Text>
                    </Row>
                    <Row className={'align-items-center'}>
                        <img height='25px' width='25px'
                             src={require(`assets/images/directions_car_grey.png`)}
                             alt=""/>
                        <Text size={'12px'} bold>Reg No:</Text>
                        <Text size={'12px'}>{value.reg_no
                            ? value.reg_no : '-'}</Text>
                    </Row>
                </Col>
                <TextContainer radius={'4px'} className={'align-items-center cursor-pointer'}
                               onMouseDown={() => history.push(`/admin/schedule-details?id=${deliveryId}`)}
                               marginTop={'5px'} background={'#47cc69'} justify='center'>
                    <Text color={'#fafafa'}>View More</Text>
                </TextContainer>
                 <TextContainer radius={'4px'} className={'align-items-center cursor-pointer'}
                               onMouseDown={() => deleteItem(deliveryId)}
                               marginTop={'5px'} background={'#f44336'} justify='center'>
                    <Text color={'#fafafa'}>Delete</Text>
                </TextContainer>
            </Popover>
        </div>

    )
}