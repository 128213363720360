import React, { useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";

import {
  Container,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";

import CardsHeader from 'layouts/Headers/CardsHeader.js';

import { DealersContextProvider } from './context';
import ProfileTab from './tabs/ProfileTab';
import DetailsTab from './tabs/DetailsTab';
import ModulesTab from './tabs/ModulesTab';
import FinancialTab from './tabs/FinancialTab';
import FrontendTab from './tabs/FrontendTab';
import CustomTabs from "@pages/components/CustomTabs";
import {ButtonContainer} from "@pages/reusable-components/styled-components";

export default function DealerEdit() {
  const { id } = useParams();
  const notificationAlert = useRef(null);
  const history = useHistory();
  const [tab, setTab] = useState('profile');
  const [tabs] = useState([
    'details',
    'modules',
    'profile',
    'frontend',
    'financial',
  ]);

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{__html: message}} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  return (
    <DealersContextProvider id={id} notify={notify}>
      <CardsHeader name="Admin" parentName="Dealer Management" currentName="Edit Dealer" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--6 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Dealer Management</h1>
                  </Col>
                  <Col xs={4} className="text-right">
                    <ButtonContainer onClick={() => history.push('/admin/dealer')}>Back to list</ButtonContainer>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <CustomTabs tabs={tabs} activeTab={tab} setActiveTab={setTab} />
                  </Col>

                  <Col md={12}>
                    {tab === 'profile' && <ProfileTab />}
                    {tab === 'details' && <DetailsTab />}
                    {tab === 'modules' && <ModulesTab />}
                    {tab === 'financial' && <FinancialTab />}
                    {tab === 'frontend' && <FrontendTab />}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </DealersContextProvider>
  );
}