import React, { useEffect, useState, createContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CreatableSelect from "react-select/creatable";
import Select2 from "react-select2-wrapper";
import axios from 'axios';
import Cookies from "js-cookie";

import actions from 'actions/index.js';
import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import InputCustom from 'views/pages/components/InputCustom';
import {
  InputKeySelect,
  InputArraySelect,
} from 'views/pages/components/InputSelect';

import {
  deletee,
  hasPermission,
} from 'views/policies/LeadPolicy';

export const Context = createContext({});

export const Provider = props => {
  const { id, notify, children } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);

  const profilePermissions = useSelector((state) => state.getState).permissions;

  const [lead, setLead] = useState('');
  const [dealerMembers, setDealerMembers] = useState([]);
  const [customDealerMembers, setCustomDealerMembers] = useState([]);
  const [leadSources, setLeadSources] = useState([]);
  const [leadTypes, setLeadTypes] = useState('');
  const [leadsStatuses, setLeadsStatuses] = useState();
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [hideModal, setHideModal] = useState(false);
  const [makesAndModels, setMakesAndModels] = useState('');
  const [quoteTypes, setQuoteTypes] = useState([]);
  const [authUserTeam, setAuthUserTeam] = useState('');
  const [statuses, setStatuses] = useState('');

  const [leadErrors, setLeadErrors] = useState('');

  const [selectedQuote, setSelectedQuote] = useState('');
  const [quoteModal, setQuoteModal] = useState({
    show: false,
    header: '',
    division: '',
  })
  const [quoteErrors, setQuoteErrors] = useState('');
  const [quoteEmail, setQuoteEmail] = useState([]);

  const [selectedNote, setSelectedNote] = useState('');
  const [noteModal, setNoteModal] = useState({
    show: false,
    header: '',
    division: '',
  })
  const [noteErrors, setNoteErrors] = useState('');

  const [selectedTask, setSelectedTask] = useState('');
  const [taskModal, setTaskModal] = useState({
    show: false,
    header: '',
    division: '',
  })
  const [taskErrors, setTaskErrors] = useState('');

  const [deleteModal, setDeleteModal] = useState({
    show: false,
    header: '',
    body: '',
    onClick: null,
  });

  useEffect(() => {
    const grs_token = Cookies.get('grs_token');
    setToken(grs_token);
    dispatch(actions.getProfileData(grs_token));

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/leads/${id}/edit`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        const data = response.data

        const dealer_members = data.data.dealerMembers;
        setDealerMembers(dealer_members);
        dealer_members.map(dealer => dealer.text = dealer.name);
        setCustomDealerMembers(dealer_members);

        setLead({
          ...data.data.item,
          lead_make: data.data.item && data.data.item.vehicleModel && data.data.item.vehicleModel.vehicleMake && data.data.item.vehicleModel.vehicleMake.id
            ? data.data.item.vehicleModel.vehicleMake.id : undefined,
        });
        setLeadSources(data.data.leadSources);
        setLeadTypes(data.data.leadTypes);
        setLeadsStatuses(data.data.leadsStatuses);
        setMakes(data.data.makes);
        setMakesAndModels(data.data.makesAndModels);
        setAuthUserTeam(data.data.authUserTeam);
        setStatuses(data.data.statuses);

        setSelectedQuote({
          ...selectedQuote,
          make: data.data.item && data.data.item.vehicleModel && data.data.item.vehicleModel.vehicleMake && data.data.item.vehicleModel.vehicleMake.id
            ? data.data.item.vehicleModel.vehicleMake.id : undefined,
          vehicle_model_id: data.data.item.vehicle_model_id,
          derivative: data.data.item && data.data.item.derivative ? data.data.item.derivative : undefined,
        })

        const quote_types = []
        Object.keys(data.data.quoteTypes).forEach(key => {
          const item = {
            value: key,
            label: data.data.quoteTypes[key]
          }
          quote_types.push(item)
        })
        setQuoteTypes(quote_types)

        if (data.data.item && data.data.item.vehicleModel && data.data.item.vehicleModel.vehicleMake && data.data.item.vehicleModel.vehicleMake.id) {
          setModels(data.data.makesAndModels[data.data.item.vehicleModel.vehicleMake.id])
        }

        dispatch(actions.getNotifications(grs_token));

        setTimeout(() => {
          setLoading(false)
        }, 1000);
      } catch (error) {
        setLoading(false)
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const goList = () => {
    history.push({
      pathname: '/admin/leads'
    })
  }

  const markWonLead = () => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/leads/' + id + '/process',
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then(response => {
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setLead({
            ...data.data.lead,
            lead_make: data.data.lead && data.data.lead.vehicleModel && data.data.lead.vehicleModel.vehicleMake && data.data.lead.vehicleModel.vehicleMake.id
              ? data.data.lead.vehicleModel.vehicleMake.id : undefined,
          });
          if (data.data.lead && data.data.lead.vehicleModel && data.data.lead.vehicleModel.vehicleMake && data.data.lead.vehicleModel.vehicleMake.id) {
            setModels(makesAndModels[data.data.lead.vehicleModel.vehicleMake.id])
          }
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setLoading(false);
        console.error('error :: ', error);
        notify('warning', 'Warning', error.response.statusText);
      })
  }

  const markLostLead = () => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/leads/' + id + '/lost',
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then(response => {
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setLead({
            ...data.data.lead,
            lead_make: data.data.lead && data.data.lead.vehicleModel && data.data.lead.vehicleModel.vehicleMake && data.data.lead.vehicleModel.vehicleMake.id
              ? data.data.lead.vehicleModel.vehicleMake.id : undefined,
          });
          if (data.data.lead && data.data.lead.vehicleModel && data.data.lead.vehicleModel.vehicleMake && data.data.lead.vehicleModel.vehicleMake.id) {
            setModels(makesAndModels[data.data.lead.vehicleModel.vehicleMake.id])
          }
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setLoading(false);
        console.error('error :: ', error);
        notify('warning', 'Warning', error.response.statusText);
      })
  }

  const updateLead = () => {
    axios({
      method: 'PUT',
      url: constants.API_URL + '/leads/' + id,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        dealer_id: lead.dealer_id,
        name: lead.name,
        phone: lead.phone,
        email: lead.email,
        lead_make: lead.lead_make,
        vehicle_model_id: lead.vehicle_model_id,
        derivative: lead.derivative,
        lead_source_id: lead.lead_source_id,
        lead_type: lead.lead_type,
        assigned_to: lead.assigned_to,
        additional_information: lead.additional_information,
      }
    })
      .then(response => {
        setLeadErrors('');
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setLead(data.data);
          setLead({
            ...data.data,
            lead_make: data.data && data.data.vehicleModel && data.data.vehicleModel.vehicleMake && data.data.vehicleModel.vehicleMake.id
              ? data.data.vehicleModel.vehicleMake.id : undefined,
          });
          if (data.data && data.data.vehicleModel && data.data.vehicleModel.vehicleMake && data.data.vehicleModel.vehicleMake.id) {
            setModels(makesAndModels[data.data.vehicleModel.vehicleMake.id])
          }
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setLeadErrors('');
        console.error('error :: ', error);
        if (error.response && error.response.status === 422) {
          let errors = error.response.data.errors;
          setLeadErrors(errors);
          return
        }
        notify('warning', 'Warning', error.response.statusText);
      })
  }

  const addQuote = () => {
    axios({
      method: 'POST',
      url: constants.API_URL + '/leads/' + id + '/quote',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: selectedQuote
    })
      .then(response => {
        setQuoteErrors('')
        setSelectedQuote('')
        setQuoteModal({
          ...quoteModal,
          show: false,
        })
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setLead({
            ...lead,
            quotes: data.data.quotes,
          })
          let quote_types = []
          Object.keys(data.quoteTypes).forEach(key => {
            const item = {
              value: key,
              label: data.quoteTypes[key]
            }
            quote_types.push(item)
          })
          setQuoteTypes(quote_types)
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setQuoteErrors('')
        console.error('error :: ', 'Error')
        if (error.response && error.response.status === 422) {
          setQuoteErrors(error.response.data.errors)
          return
        }
        setQuoteModal({
          ...quoteModal,
          show: false,
        })
        notify('warning', 'Warning')
      })
  }

  const updateQuote = () => {
    axios({
      method: 'POST',
      url: constants.API_URL + '/leads/' + id + '/quote/' + selectedQuote.id,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: selectedQuote
    })
      .then(response => {
        setQuoteErrors('')
        setSelectedQuote('')
        setQuoteModal({
          ...quoteModal,
          show: false,
        })
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setLead({
            ...lead,
            quotes: data.data.quotes,
          })
          let quote_types = []
          Object.keys(data.quoteTypes).forEach(key => {
            const item = {
              value: key,
              label: data.quoteTypes[key]
            }
            quote_types.push(item)
          })
          setQuoteTypes(quote_types)
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setQuoteErrors('')
        console.error('error :: ', error.response)
        if (error.response && error.response.status === 422) {
          setQuoteErrors(error.response.data.errors)
          return
        }
        setQuoteModal({
          ...quoteModal,
          show: false,
        })
        notify('warning', 'Warning', error.response.statusText)
      })
  }

  const sendMail = () => {
    let formData = new FormData();
    Object.keys(quoteEmail).forEach(key => {
      formData.append(`quote_email[${key}]`, quoteEmail[key]);
    })

    axios({
      method: 'POST',
      url: constants.API_URL + '/leads/' + id + '/quote/mail',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: formData
    })
      .then(response => {
        setQuoteModal({
          ...quoteModal,
          show: false,
        })
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        console.error('error :: ', error.response)
        notify('warning', 'Warning', error.response.statusText)
      })
  }

  const deleteNote = (noteId) => {
    setHideModal(false)
    axios({
      method: 'DELETE',
      url: constants.API_URL + '/leads/' + id + '/note/' + noteId,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then(response => {
        setDeleteModal({
          ...deleteModal,
          show: false,
        })
        if (response.status === 204) {
          setHideModal(true)
          let data = response.data;
          console.log('response data :: ', data);
          setLead({
            ...lead,
            notes: data.data,
          })
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setDeleteModal({
          ...deleteModal,
          show: false,
        })
        setHideModal(true)
        console.error('error :: ', error.response)
        notify('warning', 'Warning', error.response.statusText)
      })
  }

  const addNote = () => {
    setHideModal(false)
    axios({
      method: 'POST',
      url: constants.API_URL + '/leads/' + id + '/note',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: selectedNote,
    })
      .then(response => {
        setNoteErrors('');
        setSelectedNote('');
        setNoteModal({
          ...noteModal,
          show: false,
        })
        if (response.status === 200) {
          let data = response.data;
          setHideModal(true)
          console.log('response data :: ', data);
          setLead({
            ...lead,
            notes: data.data,
          })
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        console.error('error :: ', error.response)
        if (error.response && error.response.status === 422) {
          let errors = error.response.data.errors
          setNoteErrors(errors)
          return
        }
        setHideModal(true)
        setNoteModal({
          ...noteModal,
          show: false,
        })
        notify('warning', 'Warning', error.response.statusText)
      })
  }

  const addTask = () => {
    setHideModal(false)
    axios({
      method: 'POST',
      url: constants.API_URL + '/leads/' + id + '/task/store',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        ...selectedTask,
        task_category: 'lead-manually',
        status: 'new',
        task_assignee:
          selectedTask && selectedTask.taskAssignee
            ? selectedTask.taskAssignee
            : null,
      },
    })
      .then(response => {
        setTaskErrors('');
        setSelectedTask('');
        setTaskModal({
          ...taskModal,
          show: false,
        })
        if (response.status === 200) {
          setHideModal(true)
          let data = response.data;
          setLead({
            ...lead,
            tasks: data.data,
          })
          dispatch(actions.getNotifications(token));
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setTaskErrors('')
        console.error('error :: ', error.response)
        if (error.response && error.response.status === 422) {
          let errors = error.response.data.errors
          setTaskErrors(errors)
          return
        }
        setHideModal(true)
        setTaskModal({
          ...taskModal,
          show: false,
        })
        notify('warning', 'Warning', error.response.statusText)
      })
  }

  const updateTask = () => {
    setHideModal(false)
    axios({
      method: 'PUT',
      url: constants.API_URL + '/leads/' + id + '/tasks/' + selectedTask.uuid,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        ...selectedTask,
        due_date: selectedTask && selectedTask.due_date? constants.formatDate(selectedTask.due_date): null,
        task_assignee: selectedTask && selectedTask.taskAssignee ? selectedTask.taskAssignee : null,
      },
    })
      .then(response => {
        setHideModal(true)
        setTaskErrors('');
        setSelectedTask('');
        setTaskModal({
          ...taskModal,
          show: false,
        })
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setLead({
            ...lead,
            tasks: data.data,
          })
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setTaskErrors('')
        console.error('error :: ', error.response)
        if (error.response && error.response.status === 422) {
          let errors = error.response.data.errors
          setTaskErrors(errors)
          return
        }
        setHideModal(true)
        setTaskModal({
          ...taskModal,
          show: false,
        })
        notify('warning', 'Warning', error.response.statusText)
      })
  }

  const deleteTask = (taskId) => {
    setHideModal(false)
    axios({
      method: 'DELETE',
      url: constants.API_URL + '/leads/' + id + '/tasks/' + taskId,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then(response => {

        setDeleteModal({
          ...deleteModal,
          show: false,
        })
        if (response.status === 200) {
          setHideModal(true)
          let data = response.data;
          console.log('response data :: ', data);
          setLead({
            ...lead,
            tasks: data.data,
          })
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setHideModal(true)
        console.error('error :: ', error.response)
        setDeleteModal({
          ...deleteModal,
          show: false,
        })
        notify('warning', 'Warning', error.response.statusText)
      })
  }

  const leadsContext = {
    deletee, hasPermission,
    InputCustom, InputKeySelect, InputArraySelect,
    CreatableSelect, Select2,
    constants,
    CardsHeader,
    id,
    token, setToken,
    loading, setLoading,
    profilePermissions,
    lead, setLead,
    dealerMembers, setDealerMembers,
    customDealerMembers, setCustomDealerMembers,
    leadSources, setLeadSources,
    leadTypes, setLeadTypes,
    leadsStatuses, setLeadsStatuses,
    makes, setMakes,
    models, setModels,
    makesAndModels, setMakesAndModels,
    quoteTypes, setQuoteTypes,
    authUserTeam, setAuthUserTeam,
    statuses, setStatuses,
    leadErrors, setLeadErrors,
    selectedQuote, setSelectedQuote,
    quoteModal, setQuoteModal,
    quoteErrors, setQuoteErrors,
    quoteEmail, setQuoteEmail,
    selectedNote, setSelectedNote,
    noteModal, setNoteModal,
    noteErrors, setNoteErrors,
    selectedTask, setSelectedTask,
    taskModal, setTaskModal,
    taskErrors, setTaskErrors,
    hideModal,
    deleteModal, setDeleteModal,
    notify,
    goList,
    markWonLead,
    markLostLead,
    updateLead,
    addQuote,
    updateQuote,
    sendMail,
    deleteNote,
    addNote,
    addTask,
    updateTask,
    deleteTask,
  }

  return <Context.Provider value={leadsContext}>{children}</Context.Provider>
}

export const { Consumer } = Context;