import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import NotificationAlert from "react-notification-alert";
import Cookies from 'js-cookie';
import * as constants from 'constants/index.js';
import axios from 'axios';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
} from "reactstrap";

import CardsHeader from 'layouts/Headers/CardsHeader.js';
import {
  ArraySelect,
  InputKeySelect
} from 'views/pages/components/InputSelect';
import {ButtonContainer} from "@pages/reusable-components/styled-components";
import InputCustom from "@pages/components/InputCustom";
import {validateForm} from "constants/index.js";
import DebounceInput from "@pages/components/DebounceInput";

export default function CustomerCreate() {
  const notificationAlert = useRef(null);
  const history = useHistory();
  const [isNotValid, setIsNotValid] = useState(true);

  const [token, setToken] = useState('');
  const [businessTypes, setBusinessTypes] = useState([]);
  const [companyTypes, setCompanyTypes] = useState([]);
  const [counties, setCounties] = useState([]);
  const [titles, setTitles] = useState('');
  const [errors, setErrors] = useState('');
  const [lookupAddresses, setLookupAddresses] = useState([]);

  const [customer, setCustomer] = useState({
    is_active: 0,
  });

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
          const companyTypesResponse = await axios({
              method: 'GET',
              url: `${constants.API_CORE_URL}/company-type`,
              headers: {
                  Authorization: `Bearer ${grs_token}`,
              },
              cancelToken: source.token,
          })
          const countyResponse = await axios({
              method: 'GET',
              url: `${constants.API_CORE_URL}/county`,
              headers: {
                  Authorization: `Bearer ${grs_token}`,
              },
              cancelToken: source.token,
          })
          const businessTypesResponse = await axios({
              method: 'GET',
              url: `${constants.API_CORE_URL}/business-activity`,
              headers: {
                  Authorization: `Bearer ${grs_token}`,
              },
              cancelToken: source.token,
          })
          console.log('response :: ', )
        setBusinessTypes(businessTypesResponse.data.data)
        setCompanyTypes(companyTypesResponse.data.data)
        setCounties(countyResponse.data.data)
        setTitles(constants.titles);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const addCustomer = () => {
    axios({
      method: 'POST',
      url: constants.API_URL + '/customer',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: customer
    })
      .then(response => {
        if (response.status === 201) {
          let data = response.data;
          setErrors('');
          notify('success', 'Success', data.message);
          setTimeout(() => {
            history.push({
              pathname: '/admin/customer/' + data.data.id + '/edit',
              state: {
                customer: data.data
              }
            })
          }, 1500);
        }
      })
      .catch(error => {
        setErrors('');
        console.error('error :: ', error);
        if (error.response.status === 422) {
          let errors = error.response.data.errors;
          setErrors(errors);
          return
        }
        notify('warning', 'Warning', error.response.statusText);
      })
  }
    useEffect(() => {
       const required = ['address',
           'business_activity_id', 'business_address',
           'business_phone_number', 'company_name',
           'company_type_id', 'county_id',
           'driver_license_number', 'email',
           'full_name', 'mobile_number']

        setIsNotValid(!validateForm(required,customer));
    }, [customer])
  const goList = () => {
    history.push('/admin/customer');
  }

  const setSelectedAddress = (address) => {
    const a = address.FormattedAddressLines;
    let summaryAddress = address.SummaryAddress;
    const addressParts = address.ComponentParts;

    if (a) {
      if(!summaryAddress) {
        summaryAddress = `${a.Street ? a.Street : ''}${a.Premises ? `, ${a.Premises}` : ''}${a.PostTown ? `, ${a.PostTown}` : ''}${a.Organisation ? `, ${a.Organisation}` : ''}${a.Locality ? `, ${a.Locality}` : ''}${a.County ? `, ${a.County}` : ''}`
      }

      setCustomer({
        ...customer,
        address: summaryAddress,
        street: addressParts.Street,
        city: a.PostTown,
        building: `${addressParts.BuildingName ? addressParts.BuildingName + (addressParts.BuildingNumber ? ',' : '') : ''}${addressParts.BuildingNumber ? ' ' + addressParts.BuildingNumber : ''}${addressParts.SubBuildingName ? ', ' + addressParts.SubBuildingName : ''}`
      })
    }
  }

  const getAddressByPostcode = (postcode) => {
    axios({
      method: 'GET',
      url: `${constants.UK_VEHICLE_DATA_API_URL}/PostcodeLookup?v=2&api_nullitems=1&auth_apikey=${constants.UK_VEHICLE_DATA_API_KEY}&key_POSTCODE=${postcode}`,
    })
      .then(response => {
        const data = response.data

        if (data.Response) {

          if (data.Response.StatusCode === 'Success') {

            const addressList = data.Response.DataItems.AddressDetails.AddressList

            if (addressList.length === 1) {
              setCustomer({
                ...customer,
                postal_code: postcode
              });

              setErrors({
                ...errors,
                postal_code: ''
              })

              setSelectedAddress(addressList[0]);
            } else if (addressList.length > 1) {
              setCustomer({
                ...customer,
                postal_code: postcode
              })

              setErrors({
                ...errors,
                postal_code: ''
              })

              setLookupAddresses(addressList);
            }
          } else {
            setErrors({
              ...errors,
              postal_code: data.Response.StatusMessage
            })
              setLookupAddresses([]);
            setCustomer({
              ...customer,
              postal_code: postcode,
              address: ''
            })
          }
        }
      })
      .catch(error => {
        console.error('error :: ', error)
      })
  }

  return (
    <>
      <CardsHeader name="Customers" parentName="Customer Management" currentName="Create customer" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Add Customer</h1>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Card>
                  <CardHeader>
                    <h2 className="surtitle mb-0">Customer management</h2>
                  </CardHeader>
                  <CardBody>
                    <Col>
                        <h3 className={'main-color mb-3'}>Personal information</h3>
                      <Row className={'phone-container'}>
                        <Col>
                          <InputKeySelect
                              newLabel={`Title`}
                              options={titles}
                              value={customer && customer.title ? customer.title : ''}
                              onChange={e => setCustomer({
                                ...customer,
                                title: e.target.value,
                                attention_to: `${e.target.value ? `${titles[e.target.value]} ` : ''}${customer.first_name ? `${customer.first_name}` : ''}${customer.last_name ? ` ${customer.last_name}` : ''}`
                              })}
                              defaultOption={true}
                              invalid={errors && errors.title ? true : false}
                              errorMessage={errors.title}
                          />
                            <InputCustom
                                newLabel={'First Name'}
                                placeholder={'First Name'}
                                value={customer && customer.first_name ? customer.first_name : ''}
                                onChange={(e) => setCustomer({
                                    ...customer,
                                    first_name: e.target.value,
                                    full_name: `${e.target.value ? ` ${e.target.value}`  : ''}${customer.last_name ? ` ${customer.last_name}`  : ''}`,
                                    attention_to: `${customer.title ? `${titles[customer.title]} `  : ''}${e.target.value ? `${e.target.value}`  : ''}${customer.last_name ? ` ${customer.last_name}`  : ''}`
                                })}
                            />
                            <InputCustom
                                newLabel={'Last Name'}
                                placeholder={'Last Name'}
                                value={customer && customer.last_name ? customer.last_name : ''}
                                onChange={(e) => setCustomer({
                                    ...customer,
                                    last_name: e.target.value,
                                    full_name: `${customer.first_name ? ` ${customer.first_name}`  : ''}${e.target.value ? ` ${e.target.value}`  : ''}`,
                                    attention_to: `${customer.title ? `${titles[customer.title]} `  : ''}${customer.first_name ? `${customer.first_name}`  : ''}${e.target.value ? ` ${e.target.value}`  : ''}`
                                })}
                            />
                          <InputCustom
                              newLabel={'Business phone number'}
                              required
                              checkValid={false}
                              placeholder={'Business phone number'}
                              value={customer && customer.business_phone_number ? customer.business_phone_number : ''}
                              onChange={(e) => setCustomer({
                                ...customer,
                                business_phone_number: e.target.value
                              })}
                              invalid={errors && errors.business_phone_number ? true : false}
                              errorMessage={'Please provide a business phone number.'}
                          />
                        </Col>
                        <Col>
                          <InputCustom
                              newLabel={'Full Name'}
                              required
                              checkValid={false}
                              placeholder={'Full Name'}
                              value={customer && customer.full_name ? customer.full_name : ''}
                              onChange={(e) => setCustomer({
                                ...customer,
                                full_name: e.target.value
                              })}
                              invalid={errors && errors.full_name ? true : false}
                              errorMessage={'Please provide the full name.'}
                          />
                          <InputCustom
                              newLabel={'Email Address'}
                              checkEmail
                              required
                              checkValid={false}
                              placeholder={'Email Address'}
                              value={customer && customer.email ? customer.email : ''}
                              onChange={(e) => setCustomer({
                                ...customer,
                                email: e.target.value
                              })}
                              invalid={errors && errors.email ? true : false}
                              errorMessage={'Please provide a email.'}
                          />
                          <InputCustom
                              newLabel={'Phone Number'}
                              required
                              type={'number'}
                              checkValid={false}
                              placeholder={'Phone Number'}
                              value={customer && customer.mobile_number ? customer.mobile_number : ''}
                              onChange={(e) => setCustomer({
                                ...customer,
                                mobile_number: e.target.value
                              })}
                              invalid={errors && errors.mobile_number ? true : false}
                              errorMessage={'Please provide a phone number.'}
                          />
                            <DebounceInput
                                label={'Postal Code'}
                                placeholder={'Postal Code'}
                                onChange={(e) => {
                                    getAddressByPostcode(e.target.value)
                                }}
                            />

                        </Col>
                        <Col>
                            <InputCustom
                                newLabel={'Attention To'}
                                placeholder={'Attention To'}
                                value={customer && customer.attention_to ? customer.attention_to : ''}
                                onChange={(e) => setCustomer({
                                    ...customer,
                                    attention_to: e.target.value
                                })}
                            />
                            <InputCustom
                                newLabel={'N.I Number'}
                                placeholder={'N.I Number'}
                                value={customer && customer.national_insurance_number ? customer.national_insurance_number : ''}
                                onChange={(e) => setCustomer({
                                    ...customer,
                                    national_insurance_number: e.target.value
                                })}
                            />
                          <InputCustom
                              newLabel={'D/L Number'}
                              required
                              checkValid={false}
                              placeholder={'D/L Number'}
                              value={customer && customer.driver_license_number ? customer.driver_license_number : ''}
                              onChange={(e) => setCustomer({
                                ...customer,
                                driver_license_number: e.target.value
                              })}
                              invalid={errors && errors.driver_license_number ? true : false}
                              errorMessage={'Please provide a D/L Number.'}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                        <h3 className={'main-color mb-3'}>Address details</h3>
                      <Row className={'phone-container'}>
                        <Col>
                          <FormGroup className={'d-flex align-items-center'}>
                            <label className="form-control-label w-50">Select the correspondent address</label>
                            <Input
                                placeholder="Select the correspondent address"
                                type="select"
                                disabled={!lookupAddresses.length}
                                onChange={(e) => {
                                  let selectedAddress = lookupAddresses.find(item => item.Udprn === e.target.value);
                                  setSelectedAddress(selectedAddress);
                                }}
                            >
                              {lookupAddresses.length === 0 && <option>No lookup address to select from</option>}
                              {lookupAddresses.length > 0 &&
                              <>
                                <option>-</option>
                                {lookupAddresses.map((item, index) => {
                                  return <option value={item.Udprn} key={index}>{item.SummaryAddress}</option>;
                                })}
                              </>
                              }
                            </Input>
                          </FormGroup>
                            <InputCustom
                                newLabel={'Address'}
                                required
                                checkValid={false}
                                placeholder={'Address'}
                                value={customer && customer.address ? customer.address : ''}
                                onChange={(e) => setCustomer({
                                    ...customer,
                                    address: e.target.value
                                })}
                                invalid={errors && errors.address ? true : false}
                                errorMessage={'Please provide an address..'}
                            />
                            <InputCustom
                                newLabel={'Alternate Number'}
                                placeholder={'Alternate Number'}
                                value={customer && customer.land_line_number ? customer.land_line_number : ''}
                                onChange={(e) => setCustomer({
                                    ...customer,
                                    land_line_number: e.target.value
                                })}
                            />
                        </Col>
                        <Col>
                            <InputCustom
                                newLabel={'Building'}
                                placeholder={'Building'}
                                value={customer && customer.building ? customer.building : ''}
                                onChange={(e) => setCustomer({
                                    ...customer,
                                    building: e.target.value
                                })}
                            />
                            <InputCustom
                                newLabel={'Street'}
                                placeholder={'Street'}
                                value={customer && customer.street ? customer.street : ''}
                                onChange={(e) => setCustomer({
                                    ...customer,
                                    street: e.target.value
                                })}
                            />
                            <InputCustom
                                newLabel={'City'}
                                placeholder={'City'}
                                value={customer && customer.city ? customer.city : ''}
                                onChange={(e) => setCustomer({
                                    ...customer,
                                    city: e.target.value
                                })}
                            />
                        </Col>
                        <Col>
                          <ArraySelect
                              label={`Country`}
                              required
                              options={counties}
                              value={customer ? customer.county_id : ''}
                              onChange={(e) => setCustomer({
                                ...customer,
                                county_id: e.target.value
                              })}
                              invalid={errors && errors.county_id ? true : false}
                              defaultOption={true}
                              valueKey={`id`}
                              labelKey={`name`}
                              errorMessage={' Please provide a county.'}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="detail_is_active"
                      >
                        Is active
                      </label>
                      <Row>
                        <Col>
                          <label className="custom-toggle">
                            <input
                              type="checkbox"
                              onChange={() =>
                                setCustomer({
                                  ...customer,
                                  is_active: customer.is_active === 1 ? 0 : 1
                                })
                              }
                            />
                            <span
                              className="custom-toggle-slider rounded-circle"
                              data-label-off="No"
                              data-label-on="Yes"
                            />
                          </label>
                        </Col>
                      </Row>
                    </FormGroup>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader>
                    <h2 className="surtitle mb-0">Financial Information</h2>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={6} sm={12}>
                          <InputCustom
                              newLabel={'Bank name'}
                              placeholder={'Bank name'}
                              value={customer && customer.bank_name ? customer.bank_name : ''}
                              onChange={(e) => setCustomer({
                                  ...customer,
                                  bank_name: e.target.value
                              })}
                          />
                      </Col>
                      <Col md={6} sm={12}>
                          <InputCustom
                              newLabel={'Bank sort code'}
                              placeholder={'Bank sort code'}
                              value={customer && customer.bank_sort_code ? customer.bank_sort_code : ''}
                              onChange={(e) => setCustomer({
                                  ...customer,
                                  bank_sort_code: e.target.value
                              })}
                          />
                      </Col>

                      <Col md={6} sm={12}>
                          <InputCustom
                              newLabel={'Bank account name'}
                              placeholder={'Bank account name'}
                              value={customer && customer.bank_account_name ? customer.bank_account_name : ''}
                              onChange={(e) => setCustomer({
                                  ...customer,
                                  bank_account_name: e.target.value
                              })}
                          />
                      </Col>
                      <Col md={6} sm={12}>
                          <InputCustom
                              newLabel={'Bank account number'}
                              placeholder={'Bank account number'}
                              value={customer && customer.bank_account_number ? customer.bank_account_number : ''}
                              onChange={(e) => setCustomer({
                                  ...customer,
                                  bank_account_number: e.target.value
                              })}
                          />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader>
                    <h2 className="surtitle mb-0">Company Information</h2>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={6} sm={12}>
                        <ArraySelect
                            label={`Business type`}
                            required
                            options={businessTypes}
                            value={customer ? customer.business_activity_id : ''}
                            onChange={(e) => setCustomer({
                              ...customer,
                              business_activity_id: e.target.value
                            })}
                            invalid={errors && errors.business_activity_id ? true : false}
                            defaultOption={true}
                            valueKey={`id`}
                            labelKey={`name`}
                            errorMessage={'Please provide a business type.'}
                        />
                      </Col>
                      <Col md={6} sm={12}>
                        <ArraySelect
                            label={`Company type`}
                            required
                            options={companyTypes}
                            value={customer ? customer.company_type_id : ''}
                            onChange={(e) => setCustomer({
                              ...customer,
                              company_type_id: e.target.value
                            })}
                            defaultOption={true}
                            valueKey={`id`}
                            labelKey={`name`}
                            invalid={errors && errors.company_type_id ? true : false}
                            errorMessage={'Please provide a company type.'}
                        />
                      </Col>
                      <Col md={6} sm={12}>
                          <InputCustom
                              newLabel={`Company name`}
                              required
                              checkValid={false}
                              placeholder={`Company name`}
                              value={customer && customer.company_name ? customer.company_name : ''}
                              onChange={(e) => setCustomer({
                                ...customer,
                                company_name: e.target.value
                              })}
                              invalid={errors && errors.company_name ? true : false}
                              errorMessage={'Please provide a company name.'}
                          />
                      </Col>
                      <Col md={6} sm={12}>
                        <InputCustom
                            newLabel={'Business address'}
                            required
                            checkValid={false}
                            placeholder={'Business address'}
                            value={customer && customer.business_address ? customer.business_address : ''}
                            onChange={(e) => setCustomer({
                              ...customer,
                              business_address: e.target.value
                            })}
                            invalid={errors && errors.business_address ? true : false}
                            errorMessage={'Please provide a business address.'}
                        />
                      </Col>
                      <Col md={6} sm={12}>
                        <InputCustom
                            newLabel={'Insurance Company'}
                            placeholder={'Insurance Company'}
                            value={customer && customer.insurance_company ? customer.insurance_company : ''}
                            onChange={(e) => setCustomer({
                              ...customer,
                              insurance_company: e.target.value
                            })}
                            invalid={errors && errors.insurance_company ? true : false}
                            errorMessage={'Please provide a Insurance company.'}
                        />

                      </Col>
                      <Col md={6} sm={12}>
                          <InputCustom
                              newLabel={'Insurance Excess'}
                              placeholder={'Insurance Excess'}
                              value={customer && customer.insurance_excess ? customer.insurance_excess : ''}
                              onChange={(e) => setCustomer({
                                  ...customer,
                                  insurance_excess: e.target.value
                              })}
                          />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <div className="d-flex flex-row justify-content-end">
                  <ButtonContainer onClick={() => goList(true)}>Back to list</ButtonContainer>
                  <ButtonContainer disabled={isNotValid} reverse onClick={() => addCustomer()}>Save</ButtonContainer>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}