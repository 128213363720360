import * as actionTypes from './actions';
import produce from 'immer';

const initialState = {
  paymentDetails: null,
  paymentNotedCreateSuccessfully: null,
  businessActivities:null,
  getBusinessActivitiesSuccess: false,
  getBusinessActivitiesError: false,
  companiesTypes:null,
  getCompanyTypesSuccess: false,
  getCompanyTypesError: false,
  paymentInvoiceEmail: null,
  salesAccounts: [],
  mappedSalesAccounts: [],
  taxRates: [],
  paymentUpdateStatus: null,
  paymentUpdateMessage: null,
  dealsStatistics: [],
  getDealsStatisticsSuccess: false,
  getDealsStatisticsError: false,
  uploadDealsToGoogleSheetSuccess: false,
  uploadDealsToGoogleSheetError: false,
};

const dealData = produce((draft = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PAYMENT_DETAILS_SUCCESS:
      return { ...draft, paymentDetails: action.data };
    case actionTypes.CREATE_INVOICE_NOTE_SUCCESS:
      return { ...draft, paymentNotedCreateSuccessfully: true };
    case actionTypes.CREATE_INVOICE_NOTE_ERROR:
      draft.paymentNotedCreateSuccessfully = false;
      break;
    case actionTypes.SEND_PAYMENT_INVOICE_EMAIL_ERROR:
      draft.paymentInvoiceEmail = false;
      break;
    case actionTypes.SEND_PAYMENT_INVOICE_EMAIL_SUCCESS:
      draft.paymentInvoiceEmail = true;
      break;
    case actionTypes.GET_SALES_ACCOUNTS_SUCCESS:
      draft.salesAccounts = action.data.products;
      draft.mappedSalesAccounts = action.data.mappedProducts;
      break;
    case actionTypes.GET_TAXES_SUCCESS:
      draft.taxRates = action.data.taxes;
      break;
    case actionTypes.UPDATE_INVOICE_REQUEST:
      draft.paymentUpdateStatus = null;
      draft.paymentUpdateMessage = null;
      break;
    case actionTypes.UPDATE_INVOICE_SUCCESS:
      draft.paymentUpdateStatus = true;
      break;
    case actionTypes.UPDATE_INVOICE_ERROR:
      draft.paymentUpdateStatus = false;
      draft.paymentUpdateMessage = action.message;
      break;
    case actionTypes.GET_DEAL_STATISTICS_REQUEST:
      draft.dealsStatistics = []
      draft.getDealsStatisticsSuccess = false;
      draft.getDealsStatisticsError = false;
      break;
    case actionTypes.GET_DEAL_STATISTICS_SUCCESS:
      draft.dealsStatistics = action.data
      draft.getDealsStatisticsSuccess = true;
      draft.getDealsStatisticsError = false
      break;
    case actionTypes.GET_DEAL_STATISTICS_ERROR:
      draft.getDealsStatisticsSuccess = false;
      draft.getDealsStatisticsError = true;
      break;
    case actionTypes.GET_BUSINESS_ACTIVITY_REQUEST:
      draft.getBusinessActivitiesSuccess = false;
      draft.getBusinessActivitiesError = false;
      draft.businessActivities = null;

      break;
    case actionTypes.GET_BUSINESS_ACTIVITY_SUCCESS:
      draft.getBusinessActivitiesSuccess = true;
      draft.getBusinessActivitiesError = false;
      draft.businessActivities = action.data;
      break;
    case actionTypes.GET_BUSINESS_ACTIVITY_ERROR:
      draft.getBusinessActivitiesSuccess = false;
      draft.getBusinessActivitiesError = true;
      break;
    case actionTypes.GET_COMPANY_TYPE_REQUEST:
      draft.getCompanyTypesSuccess = false;
      draft.getCompanyTypesError = false;
      draft.companiesTypes = null;
      break;
    case actionTypes.GET_COMPANY_TYPE_SUCCESS:
      draft.getCompanyTypesSuccess = true;
      draft.getCompanyTypesError = false;
      draft.companiesTypes = action.data;
      break;
    case actionTypes.GET_COMPANY_TYPE_ERROR:
      draft.getCompanyTypesSuccess = false;
      draft.getCompanyTypesError = true;
      break;
    case actionTypes.UPLOAD_DEALS_TO_GOOGLE_SHEETS_REQUEST:
      draft.uploadDealsToGoogleSheetSuccess = false;
      draft.uploadDealsToGoogleSheetError = false;
      break;
    case actionTypes.UPLOAD_DEALS_TO_GOOGLE_SHEETS_SUCCESS:
      draft.uploadDealsToGoogleSheetSuccess = true;
      draft.uploadDealsToGoogleSheetError = false;
      break;
    case actionTypes.UPLOAD_DEALS_TO_GOOGLE_SHEETS_ERROR:
      draft.uploadDealsToGoogleSheetSuccess = false;
      draft.uploadDealsToGoogleSheetError = true;
      break;
    default:
      return draft;
  }
});

export default dealData;
