import React, { useEffect, useMemo, useState } from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import * as actions from '@redux/Payments/actions';
import CardsHeader from 'layouts/Headers/CardsHeader.js';
import useNotify from 'hooks/notify';

import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody, Modal, Button, Input, FormGroup, Label
} from "reactstrap";
import {
    override,
    UploadButtonContainer
} from "@pages/reusable-components/styled-components";
import CustomTable from "@pages/components/Table/CustomTable";
import AddEditPayment from "@pages/components/payment/AddEditPayment";
import { ClipLoader } from "react-spinners";
import usePrevious from "../../../hooks/useprevious";
import moment from "moment";
import {
    SelectWithoutInput
} from 'views/pages/components/InputSelect'
import AddPaymentPlan from "@pages/components/payment/AddPaymentPlan";
import {formatDate} from "../../../constants";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";

function getYears() {
    let years = [];
    let currentYear = new Date().getFullYear();
    let startYear = 2022;
    while (startYear <= currentYear) {
        years.push(startYear++);
    }
    return years;
}

const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

const years = getYears();


export default function PaymentsComponent() {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const { ref, notify } = useNotify();

    const {
        addedPaymentSuccess,
        addedPaymentError,
        getPaymentsListSuccess,
        getPaymentsListError,
        updatePaymentNotesSuccess,
        updatePaymentNotesError,
        updatePaymentSuccess,
        updatePaymentError,
        payments,
        deletePaymentSuccess,
        deletePaymentError,
        deleteAllPaymentSuccess,
        deleteAllPaymentError,
        getCustomerPaymentNotesSuccess,
        getCustomerPaymentNotesError,
        customerPaymentNotes,
    } = useSelector((state) => state.paymentState, shallowEqual);

    const addedPaymentSuccessPrev = usePrevious(addedPaymentSuccess)
    const addedPaymentErrorPrev = usePrevious(addedPaymentError)



    const getCustomerPaymentNotesSuccessPrev = usePrevious(getCustomerPaymentNotesSuccess)
    const getCustomerPaymentNotesErrorPrev = usePrevious(getCustomerPaymentNotesError)

    const getPaymentsListSuccessPrev = usePrevious(getPaymentsListSuccess)
    const getPaymentsListErrorPrev = usePrevious(getPaymentsListError)

    const deletePaymentSuccessPrev = usePrevious(deletePaymentSuccess)
    const deletePaymentErrorPrev = usePrevious(deletePaymentError)

    const deleteAllPaymentSuccessPrev = usePrevious(deleteAllPaymentSuccess)
    const deleteAllPaymentErrorPrev = usePrevious(deleteAllPaymentError)

    const updatePaymentNotesSuccessPrev = usePrevious(updatePaymentNotesSuccess)
    const updatePaymentNotesErrorPrev = usePrevious(updatePaymentNotesError)

    const updatePaymentSuccessPrev = usePrevious(updatePaymentSuccess)
    const updatePaymentErrorPrev = usePrevious(updatePaymentError)
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState({
        year: null,
        month: null,
        search: null,
        date_order: 'asc',
        due_date: null
    });

    const [showAddPayment, setShowAddPayment] = useState(false)
    const [showAddPaymentPlan, setShowAddPaymentPlan] = useState(false)
    const [url, setUrl] = useState('')
    const [tableData, setTableData] = useState([]);
    const [deletedNotes, setDeletedNotes] = useState([])
    const [availablePaymentsCount,setAvailablePaymentsCount] = useState({})
    const [pagination,setPagination] = useState({})
    const [modalLoading, setModalLoading] = useState(false);

    const [isNotesModalOpened, setIsNotesModalOpened] = useState(false);
    const [deleteModal, setDeleteModal] = useState({
        show: false,
        text: '',
        onClick: () => null
    });
    const [newNote, setNewNote] = useState('')
    const [selectedNotesData, setSelectedNotesData] = useState([])
    const [selectedPaymentId, setSelectedPaymentId] = useState(null)
    const [allowRemoveAll, setAllowRemoveAll] = useState(false)

    const tableKeys = useMemo(() => {
        let tableKeys = [
        ];
        tableKeys.push({key: 'customer_name', label: 'Customer name' })
        if (url !== 'vehicle_repairs' && url !== 'payment_plan' ) {
            tableKeys.push(
                { key: 'invoice_number', label: 'invoice no' },
            )
        }
        tableKeys.push(
            { key: 'type', label: 'Type' },
        )
        if (url === 'payment_plan') {
            tableKeys.push(
                { key: 'amount', label: 'Amount Due'},
            )
        } else {
            tableKeys.push(
                { key: 'amount', label: 'Amount'},
            )
        }
            tableKeys.push(
                { key: 'due_date', label: 'Due date', sort: true },
            )
        if (url === 'payment_plan') {
            tableKeys.push(
                { key: 'number_of_payments', label: 'No of reoccurring payments' },
                { key: 'payment_amount', label: 'Payment amount' },
            )

        }
            tableKeys.push(
                { key: 'openModal', label: 'View notes' },
            )
        tableKeys.push(

            { key: 'delete', label: 'Action',className: 'red'  },
        )
        return tableKeys;
    }, [url])

    const toggleDeleteModal = (data) => {
        setDeleteModal(data)
    }
    const toggleNotesModal = () => {
        setIsNotesModalOpened(!isNotesModalOpened)
    }
    const onNotesModalClosed = () => {
        setSelectedNotesData([])
        setDeletedNotes([])
        setSelectedPaymentId(null)
    }

    const  updateNotes = () => {
        let data = {
            notes: selectedNotesData,
            deleted: deletedNotes,
            customer_id: selectedPaymentId
        }
        dispatch({
            type: actions.UPDATE_PAYMENT_NOTES_REQUEST,
            data,
        });
        toggleNotesModal()
    }
    const removeNote = (index) => {
        let tempData = [...selectedNotesData]
        let dNote = tempData.splice(index,1);
        if (dNote[0].hasOwnProperty('customer_id')) {
            setDeletedNotes([...deletedNotes,dNote[0].id])
        }
        setSelectedNotesData(tempData)
    }
    const checkDelete = (id) => {
        toggleDeleteModal({text: 'You want to delete payment',onClick: () => deletePayment(id),show: true})
    }

    const deleteAll =  (index) => {
        const planId = tableData[index].payment_plan_id
        toggleDeleteModal({text: 'You want to delete all payments of this customer',onClick: () => deleteAllPayments(planId),show: true})
    }

    const deleteAllPayments = (id) => {
        let data = {
            id: id
        }
        dispatch({
            type: actions.DELETE_ALL_PAYMENT_REQUEST,
            data,
        });
    }
    const deletePayment = (id) => {
        let data = {
            id: id
        }
        dispatch({
            type: actions.DELETE_PAYMENT_REQUEST,
            data,
        });
    }
    const sortData = (sort) => {
        let tempFilter = { ...filter }
        tempFilter.date_order = sort.value
        setFilter(tempFilter)
        fetchDetails(tempFilter,url);
    }
    const openNotesModal = (itemIndex) => {
            dispatch({
                type: actions.GET_CUSTOMER_PAYMENTS_NOTES_REQUEST,
                data: tableData[itemIndex].customer_id,
            });
            setSelectedPaymentId(tableData[itemIndex].customer_id)
        setModalLoading(true)
        toggleNotesModal()
    }
    const paginate = (page) => {
        fetchDetails(null,url,page);
    }


    const fetchDetails = (currentFilter = null, endpoint = null, page = null) => {
        dispatch({
            type: actions.GET_PAYMENT_LIST_REQUEST,
            url: endpoint,
            data: {
                ...(currentFilter ? currentFilter : filter),
                page: page
            },
        });
    }

    useEffect(() => {
        let tU = location.pathname.split('/')[3]
        //
        switch (tU) {
            case 'failed':
                 tU = 'failed_dd';
                 break;
            case 'payment-plans':
                tU = 'payment_plan';
                setAllowRemoveAll(true)
                break;
            case 'excess':
                tU = 'excess_mileage';
                break;
            case 'repairs':
                tU = 'vehicle_repairs';
                break;


            default:
                break;
        }
        setUrl(tU)
        fetchDetails(null,tU);
    }, [location]);

    useEffect(() => {
        if (addedPaymentSuccess && addedPaymentSuccessPrev === false) {
            window.location.reload();
        }
    }, [addedPaymentSuccess])

     useEffect(() => {
        if (deletePaymentSuccess && deletePaymentSuccessPrev === false) {
           window.location.reload()
        }
     }, [deletePaymentSuccess])

    useEffect(() => {
        if (deletePaymentError && !deletePaymentErrorPrev) {
            setDeleteModal({...deleteModal,show: false})
        }
    }, [deletePaymentError])

    useEffect(() => {
        if (deleteAllPaymentSuccess && deleteAllPaymentSuccessPrev === false) {
            window.location.reload()
        }
    }, [deleteAllPaymentSuccess])

    useEffect(() => {
        if (getCustomerPaymentNotesSuccess && getCustomerPaymentNotesSuccessPrev === false) {
            setModalLoading(false)
            setSelectedNotesData(customerPaymentNotes)
        }
    }, [getCustomerPaymentNotesSuccess,customerPaymentNotes])
    useEffect(() => {
        if (getCustomerPaymentNotesError && getCustomerPaymentNotesErrorPrev === false) {
            toggleNotesModal()
            setModalLoading(false)
        }
    }, [getCustomerPaymentNotesError])

    useEffect(() => {
        if (deleteAllPaymentError && !deleteAllPaymentErrorPrev) {
            setDeleteModal({...deleteModal,show: false})
        }
    }, [deleteAllPaymentError])


    useEffect(() => {
        if (addedPaymentError && !addedPaymentErrorPrev) {
            notify('Error', 'Something went wrong')
            setLoading(false)
        }
    }, [addedPaymentError])

    useEffect(() => {
        if (getPaymentsListSuccess && getPaymentsListSuccessPrev === false) {
            let tempData = payments.data
            setTableData(tempData.data)
            setPagination(tempData.meta)
            setAvailablePaymentsCount(tempData?.count)
            setLoading(false)
        }
    }, [getPaymentsListSuccess])
    useEffect(() => {
        if (getPaymentsListError && !getPaymentsListErrorPrev) {
            notify('Error', 'Something went wrong')
            setLoading(false)
        }
    }, [getPaymentsListError])

    useEffect(() => {
        if (updatePaymentNotesSuccess && updatePaymentNotesSuccessPrev === false) {
            window.location.reload();
        }
    }, [updatePaymentNotesSuccess])

    useEffect(() => {
        if (updatePaymentNotesError && !updatePaymentNotesErrorPrev) {
            notify('Error', 'Something went wrong')
        }
    }, [updatePaymentNotesError])


    useEffect(() => {
        if (updatePaymentSuccess && updatePaymentSuccessPrev === false) {
            fetchDetails();
        }
    }, [updatePaymentSuccess])

    useEffect(() => {
        if (updatePaymentError && !updatePaymentErrorPrev) {
            notify('Error', 'Something went wrong')
        }
    }, [updatePaymentError])

    const saveData = async (data) => {
        setLoading(true)
        dispatch({
            type: actions.ADD_PAYMENT_REQUEST,
            data: data
        });
        setShowAddPayment(false)
    }
    const savePaymentPlan  = async (data) => {
        setLoading(true)
        dispatch({
            type: actions.ADD_PAYMENT_REQUEST,
            data: data
        });
        setShowAddPaymentPlan(false)
    }
    const addNewNote = () => {
        let tempData = [...selectedNotesData]
        tempData.push({
                note: newNote,
                status: 'new'
        })
        setSelectedNotesData(tempData)
        setNewNote('')
    }
    const uploadAction = () => {
        history.push({
            pathname: '/admin/payments/upload'
        });
    }

    return (
        loading ?
            <ClipLoader
                css={override}
                size={40}
                color={`#7B61E4`}
                loading={loading}
            />
            :
            <>

                <Modal
                    isOpen={deleteModal.show}
                    toggle={() =>  toggleDeleteModal({text: '',onClick: () =>null,show: false})}
                    className="disable-scroll modal-dialog-centered modal-md"
                >
                    <div className="modal-header">
                        <h3>{`Are you sure ?`}</h3>
                    </div>
                    <div className="modal-body">
                        <h4>{deleteModal.text}</h4>
                    </div>
                    <div className="modal-footer mb-3">
                        <Button
                            className="ml-auto"
                            color="danger"
                            type="button"
                            onClick={() => {
                                toggleDeleteModal({text: '',onClick: () =>null,show: false})
                            }}
                        >
                            {`Close`}
                        </Button>
                        <Button
                            className="new-event--add"
                            color="success"
                            type="button"
                            onClick={deleteModal.onClick}
                        >
                            {`Save`}
                        </Button>
                    </div>
                </Modal>

                <Modal
                    isOpen={isNotesModalOpened}
                    onClosed={onNotesModalClosed}
                    toggle={() => toggleNotesModal()}
                    className="modal-dialog-centered modal-lg"
                >
                    <div className="modal-header">
                        <h3>{`Notes for payment`}</h3>
                    </div>
                    <div className="modal-body">
                        {  modalLoading ?    <ClipLoader
                                css={override}
                                size={40}
                                color={`#7B61E4`}
                                loading={modalLoading}
                            /> :
                            <Row>
                                <Col lg={4}>
                                    <Col>
                                        <Input
                                            value={newNote}
                                            placeholder={'write a note'}
                                            onChange={e => setNewNote(e.target.value)}
                                        />
                                        <Button className={'mt-2 mb-2'} onClick={addNewNote}>
                                            Add Note
                                        </Button>
                                        <Button
                                            className="ml-auto"
                                            color="danger"
                                            type="button"
                                            onClick={() => {
                                                toggleNotesModal()
                                            }}
                                        >
                                            {`Close`}
                                        </Button>
                                        <Button
                                            className="new-event--add"
                                            color="success"
                                            type="button"
                                            onClick={updateNotes}
                                        >
                                            {`Save`}
                                        </Button>
                                    </Col>
                                </Col>
                                <Col className={'overflow-auto'} lg={8}>
                                    {selectedNotesData.length ?
                                        <table className="table table-bordered">
                                            <thead>
                                            <tr className={'reports-table-header'}>
                                                <th className={'header-text'}>
                                                    Date
                                                </th>
                                                <th className={'header-text'}>
                                                    Note
                                                </th>

                                                <th className={'header-text'}>
                                                    Action
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                selectedNotesData.map((note, index) => {
                                                    return <tr key={index}>
                                                        <td>
                                                            <td>
                                                                <h4>{note.hasOwnProperty('created_at') ? formatDate(note?.created_at) : 'Now'}</h4>
                                                            </td>
                                                        </td>
                                                        <td>
                                                            <td>
                                                                {
                                                                    <h4>{note?.note}</h4>
                                                                }
                                                            </td>

                                                        </td>
                                                        <td>
                                                            <IcoMoon
                                                                className={'cursor-pointer'}
                                                                iconSet={IconSet}
                                                                icon={'delete_outline'}
                                                                size={24}
                                                                onClick={() => removeNote(index)}
                                                            />
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table> : null}
                                </Col>
                            </Row>}

                    </div>
                    <div className="modal-footer mb-3">

                    </div>
                </Modal>
                <CardsHeader name="Payments Management" parentName="Payments Management" currentName="List" />
                <div className="rna-wrapper">
                    <NotificationAlert ref={ref} />
                </div>
                {
                    showAddPayment ?
                        <AddEditPayment
                            create={saveData}
                            location={url}
                            setOpen={setShowAddPayment}
                        /> : showAddPaymentPlan ?
                            <AddPaymentPlan
                                location={url}
                                create={savePaymentPlan}
                                setOpen={setShowAddPaymentPlan}
                            />
                            : <Container className="mt--5 admin-main-body" fluid >
                            <Row>
                                <Col xl="12">
                                    <Card>
                                        <CardHeader className={'border-bottom-0'}>
                                            <Row>

                                            </Row>
                                            {/*<Filter fetchData={fetchData}/>*/}
                                        </CardHeader>
                                        <CardBody>
                                            {(url === 'failed_dd' || url === 'vehicle_repairs') ?
                                                <Row className={'justify-content-end mb-2'}>
                                                    {url === 'failed_dd' && <Col md={4} lg={3} sm={8} className={'mb-2'}>
                                                        <UploadButtonContainer background={'#70ad47'} color={'white'}
                                                                               onClick={() => uploadAction()}>
                                                            <i className="fa fa-upload" aria-hidden="true"></i>
                                                            <span className={'button-text-responsive'}>
                                                                   Upload Failed DD
                                                               </span>
                                                        </UploadButtonContainer>
                                                    </Col>}
                                                    <Col md={4}  lg={3} sm={8}>
                                                        <UploadButtonContainer background={'#70ad47'} color={'white'} onClick={() => setShowAddPayment(true)}>
                                                            <i className="fa fa-plus" aria-hidden="true"></i>
                                                            <span className={'button-text-responsive'}>
                                                                  {url === 'failed_dd' ? 'Add Failed DD' : 'Add vehicle repairs'}
                                                               </span>
                                                        </UploadButtonContainer>
                                                    </Col>
                                                </Row> : url === 'payment_plan' ?
                                                   <Row className={'justify-content-end mb-2'}>
                                                       <Col md={4} lg={3} sm={8} className={'mb-2'}>
                                                           <UploadButtonContainer background={'#70ad47'} color={'white'} onClick={() => setShowAddPaymentPlan(true)}>
                                                               <i className="fa fa-plus" aria-hidden="true"></i>
                                                               <span className={'button-text-responsive'}>
                                                                    Add Payment Plan
                                                                </span>
                                                           </UploadButtonContainer>
                                                       </Col>
                                                   </Row>
                                                    : null
                                            }
                                            <Row className={'flex-wrap'}>
                                                <Col lg={2} md={3}>
                                                        <Input
                                                            placeholder="Search..."
                                                            value={filter.search ?? ''}
                                                            type={`text`}
                                                            onChange={(e) => setFilter({ ...filter, search: e.target.value })}
                                                        />
                                                    </Col  >
                                                <Col md={3} lg={2}>
                                                    <SelectWithoutInput
                                                        label={filter.month !== null ? months[filter.month] : 'Select month'}
                                                        onSelect={e => {
                                                            let month = months.findIndex(item => item === e);
                                                            setFilter({ ...filter, month })
                                                        }}
                                                        fullWidth={window.innerWidth < 833}
                                                        options={months}
                                                        defaultOption={true}
                                                    />
                                                </Col>
                                                <Col md={3} lg={2} className={'mb-sm-2'}>
                                                    <SelectWithoutInput
                                                        label={filter.year ? filter.year : 'Select year'}
                                                        onSelect={e => {
                                                            setFilter({ ...filter, year: e })
                                                        }}
                                                        fullWidth={window.innerWidth < 833}
                                                        options={years}
                                                        defaultOption={true}
                                                    />
                                                </Col>
                                                <Col lg={2} md={6} sm={12} >
                                                    <FormGroup className={'d-flex g-10 align-items-center'}>
                                                        <Label>Due Date: </Label>
                                                        <Input type={'date'}
                                                               value={filter.due_date ? formatDate(filter.due_date) : ''}
                                                               onChange={(e) => {
                                                                   setFilter({...filter,due_date: moment(e.target.value).format('YYYY-MM-DD')})
                                                               }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={1} md={2} className={'mb-sm-2'}>
                                                   <Button
                                                       className="ml-3"
                                                       type="button"
                                                       onClick={() => {
                                                           setFilter({ ...filter, month: null, year: null, search: null, due_date: null });
                                                       }}
                                                   >
                                                       Clear
                                                   </Button>
                                               </Col>
                                                <Col md={2} lg={1}>
                                                    <Button
                                                        className="ml-3"
                                                        onClick={() => { fetchDetails(null,url) }}
                                                    >
                                                        Search
                                                    </Button>
                                                </Col>

                                            </Row>
                                        </CardBody>
                                        <CardBody>
                                           <CustomTable
                                                    keys={tableKeys}
                                                    noOverflow={true}
                                                    multicolor
                                                    customClassName={'table-header'}
                                                    pagination={pagination}
                                                    paginate={paginate}
                                                    openModal={openNotesModal}
                                                    count={availablePaymentsCount}
                                                    removeAll={allowRemoveAll}
                                                    sort={sortData}
                                                    deleteRow={checkDelete}
                                                    deleteAll={deleteAll}
                                                    sortValue={filter.date_order}
                                                    tableData={tableData}
                                                    isPaymentsPage={true} />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                }
            </>
    )
}