import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import TableCustom from 'views/pages/components/TableCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

export default function AuthorizedDealers() {
  const notificationAlert = useRef(null);
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {

    if (Cookies.get('grs_token')) {
      let grs_token = Cookies.get('grs_token')

      axios({
        method: 'GET',
        url: constants.API_URL + '/staff/payments',
        headers: {
          Authorization: 'Bearer ' + grs_token
        },
      })
        .then(response => {
          console.log('res :: ', response.data)
          let data = response.data
          setItems(data.data.items)

          setTimeout(() => {
            setLoading(false)
          }, 1000);
        })
        .catch(error => {
          console.error('error :: ', error)
          setLoading(false)
          notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
        })
    }

  }, [])

  const goUnsupportedDirectDebits = () => {
    history.push({
      pathname: '/admin/staff/payments/unauthorized-dealers'
    })
  }

  const goDealerPayment = (slug) => {
    history.push({
      pathname: '/admin/staff/payments/' + slug + '/customers'
    })
  }

  return (
    <>
      <CardsHeader name="Payments" parentName="Authorized Dealers management" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <div>
                    <h1 className="title mb-0">Authorized Dealers for direct payments yet</h1>
                  </div>
                  <Button className="h-50" color="success" type="button" onClick={() => goUnsupportedDirectDebits()}>Unsupported Direct Debits</Button>
                </div>
              </CardHeader>
              <CardBody>
                <TableCustom 
                  loading={loading}
                  items={items}
                  keyField={`name`}
                  searchField={false}
                  columns={[
                    {
                      dataField: "name",
                      text: "Dealer",
                      sort: true
                    },
                    {
                      dataField: "",
                      text: "",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                color=""
                                role="button"
                                size="sm"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" right>
                                <DropdownItem
                                  onClick={e => goDealerPayment(row.slug)}
                                >
                                  View
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </>
                        )
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}