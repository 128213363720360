import React from 'react';


import Draggable from "@pages/components/drag/Draggable";
import {CustomTd, DeliveryTableItem, Text} from "@pages/reusable-components/styled-components";
import {formatDate} from "../../../../constants";
import {DragOverlay, useDroppable} from "@dnd-kit/core";
import {
    SortableContext,
    verticalListSortingStrategy
} from "@dnd-kit/sortable";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";

export default function Drag({id, items, itemKey,customKey, dateIndex, date, activeId, droppingItem,deleteItem}) {
    const {setNodeRef} = useDroppable({
        id
    });

    return (

        <SortableContext
            id={id}
            key={customKey}
            items={items[itemKey]}
            strategy={verticalListSortingStrategy}
        >
            <CustomTd key={`${customKey}_${id}`} ref={setNodeRef}>
                {
                    items[itemKey].map((value, index) => {
                        if (date?.currentDate?.toString() === formatDate(value?.execution_date?.toString())) {
                            return (
                                <React.Fragment  key={`${value.delivery_id}^${index}`}>
                                    <Draggable
                                            key={value.delivery_id}
                                            deliveryId={value.delivery_id}
                                            type={value.task_type}
                                            text={value.driver_name}
                                            value={value}
                                            id={`${id}_${value.delivery_id}`}
                                            index={index}
                                            dateIndex={dateIndex}
                                            deleteItem={deleteItem}
                                        />
                                    {
                                        `${id}_${value.delivery_id}` === activeId &&
                                        <DragOverlay>
                                            <Draggable
                                                key={value.delivery_id}
                                                type={value.task_type}
                                                text={value.driver_name}
                                                id={`${id}_${value.delivery_id}`}
                                                index={index}
                                                dateIndex={dateIndex}
                                            />
                                        </DragOverlay>
                                    }
                                    </React.Fragment>

                            )
                        }

                    })

                }
                {

                    items[itemKey].map((value, index) => {
                        return (
                            Object.values(droppingItem).length &&
                            droppingItem.containerId === itemKey &&
                            date?.currentDate?.toString() === formatDate(droppingItem?.execution_date?.toString()) && index === 0 ?
                             <React.Fragment key={index + '_' + droppingItem.task_type}>
                                 <DeliveryTableItem opacity='0.5' type={droppingItem.task_type}>
                                     {droppingItem.task_type === 'collect' ?
                                         <IcoMoon
                                             className={'cursor-pointer'}
                                             iconSet={IconSet}
                                             icon={'carbon_delivery'}
                                             size={16}
                                         />
                                         :
                                         droppingItem.task_type === 'delivery' ?
                                             <IcoMoon
                                                 className={'cursor-pointer'}
                                                 iconSet={IconSet}
                                                 icon={'carbon_delivery-add'}
                                                 size={16}
                                             />
                                             : null
                                     } <Text color='#ffffff'>{droppingItem.driver_name}</Text>
                                 </DeliveryTableItem>
                             </React.Fragment>
                            :
                        <React.Fragment key={'nothing_'+index}/>
                    )
                    })
                }

            </CustomTd>


        </SortableContext>
    )
}