export const GET_PAYMENT_LIST_REQUEST = 'GET_PAYMENT_LIST_REQUEST';
export const GET_PAYMENT_LIST_SUCCESS = 'GET_PAYMENT_LIST_SUCCESS';
export const GET_PAYMENT_LIST_ERROR = 'GET_PAYMENT_LIST_ERROR';

export const GET_PAYMENT_REPORTS_REQUEST = 'GET_PAYMENT_REPORTS_REQUEST';
export const GET_PAYMENT_REPORTS_SUCCESS = 'GET_PAYMENT_REPORTS_SUCCESS';
export const GET_PAYMENT_REPORTS_ERROR = 'GET_PAYMENT_REPORTS_ERROR';

export const GET_CRON_PAYMENT_LIST_REQUEST = 'GET_CRON_PAYMENT_LIST_REQUEST';
export const GET_CRON_PAYMENT_LIST_SUCCESS = 'GET_CRON_PAYMENT_LIST_SUCCESS';
export const GET_CRON_PAYMENT_LIST_ERROR = 'GET_CRON_PAYMENT_LIST_ERROR';

export const ADD_PAYMENT_REQUEST = 'ADD_PAYMENT_REQUEST';
export const ADD_PAYMENT_SUCCESS = 'ADD_PAYMENT_SUCCESS';
export const ADD_PAYMENT_ERROR = 'ADD_PAYMENT_ERROR';

export const UPDATE_PAYMENT_REQUEST = 'UPDATE_PAYMENT_REQUEST';
export const UPDATE_PAYMENT_SUCCESS = 'UPDATE_PAYMENT_SUCCESS';
export const UPDATE_PAYMENT_ERROR = 'UPDATE_PAYMENT_ERROR';

export const UPDATE_PAYMENT_NOTES_REQUEST = 'UPDATE_PAYMENT_NOTES_REQUEST';
export const UPDATE_PAYMENT_NOTES_SUCCESS = 'UPDATE_PAYMENT_NOTES_SUCCESS';
export const UPDATE_PAYMENT_NOTES_ERROR = 'UPDATE_PAYMENT_NOTES_ERROR';

export const DELETE_PAYMENT_REQUEST = 'DELETE_PAYMENT_REQUEST';
export const DELETE_PAYMENT_SUCCESS = 'DELETE_PAYMENT_SUCCESS';
export const DELETE_PAYMENT_ERROR = 'DELETE_PAYMENT_ERROR';

export const UPLOAD_PAYMENT_DOCUMENT_REQUEST = 'UPLOAD_PAYMENT_DOCUMENT_REQUEST';
export const SAVE_PAYMENTS_REQUEST = 'SAVE_PAYMENTS_REQUEST';
export const CLEANUP_PAYMENTS = 'CLEANUP_PAYMENTS';

export const API_PAYMENTS_SUCCESS = 'API_PAYMENTS_SUCCESS';
export const API_PAYMENTS_ERROR = 'API_PAYMENTS_ERROR';

export const UPDATE_PARTIAL_PAYMENT_REQUEST = 'UPDATE_PARTIAL_PAYMENT_REQUEST';
export const UPDATE_PARTIAL_PAYMENT_SUCCESS = 'UPDATE_PARTIAL_PAYMENT_SUCCESS';
export const UPDATE_PARTIAL_PAYMENT_ERROR = 'UPDATE_PARTIAL_PAYMENT_ERROR';

export const UPDATE_EXPECTED_PAYMENT_REQUEST = 'UPDATE_EXPECTED_PAYMENT_REQUEST';
export const UPDATE_EXPECTED_PAYMENT_SUCCESS = 'UPDATE_EXPECTED_PAYMENT_SUCCESS';
export const UPDATE_EXPECTED_PAYMENT_ERROR = 'UPDATE_EXPECTED_PAYMENT_ERROR';

export const GET_PAYMENTS_NOTES_REQUEST = 'GET_PAYMENTS_NOTES_REQUEST';
export const GET_PAYMENTS_NOTES_SUCCESS = 'GET_PAYMENTS_NOTES_SUCCESS';
export const GET_PAYMENTS_NOTES_ERROR = 'GET_PAYMENTS_NOTES_ERROR';

export const GET_CUSTOMER_PAYMENTS_NOTES_REQUEST = 'GET_CUSTOMER_PAYMENTS_NOTES_REQUEST';
export const GET_CUSTOMER_PAYMENTS_NOTES_SUCCESS = 'GET_CUSTOMER_PAYMENTS_NOTES_SUCCESS';
export const GET_CUSTOMER_PAYMENTS_NOTES_ERROR = 'GET_CUSTOMER_PAYMENTS_NOTES_ERROR';

export const GET_PAYMENTS_PLANS_REQUEST = 'GET_PAYMENTS_PLANS_REQUEST';
export const GET_PAYMENTS_PLANS_SUCCESS = 'GET_PAYMENTS_PLANS_SUCCESS';
export const GET_PAYMENTS_PLANS_ERROR = 'GET_PAYMENTS_PLANS_ERROR';

export const UPDATE_APPRROVEMENT_DATE_REQUEST = 'UPDATE_APPRROVEMENT_DATE_REQUEST';
export const UPDATE_APPRROVEMENT_DATE_SUCCESS = 'UPDATE_APPRROVEMENT_DATE_SUCCESS';
export const UPDATE_APPRROVEMENT_DATE_ERROR = 'UPDATE_APPRROVEMENT_DATE_ERROR';


export const DELETE_ALL_PAYMENT_REQUEST = 'DELETE_ALL_PAYMENT_REQUEST';
export const DELETE_ALL_PAYMENT_SUCCESS = 'DELETE_ALL_PAYMENT_SUCCESS';
export const DELETE_ALL_PAYMENT_ERROR = 'DELETE_ALL_PAYMENT_ERROR';

export const UPDATE_GROUP_PAYMENTS_STATUS_REQUEST = 'UPDATE_GROUP_PAYMENTS_STATUS_REQUEST';
export const UPDATE_GROUP_PAYMENTS_STATUS_SUCCESS = 'UPDATE_GROUP_PAYMENTS_STATUS_SUCCESS';
export const UPDATE_GROUP_PAYMENTS_STATUS_ERROR = 'UPDATE_GROUP_PAYMENTS_STATUS_ERROR';

export const UPLOAD_PAYMENT_PLANS_TO_GOOGLE_SHEETS_REQUEST = 'UPLOAD_PAYMENT_PLANS_TO_GOOGLE_SHEETS_REQUEST';
export const UPLOAD_PAYMENT_PLANS_TO_GOOGLE_SHEETS_SUCCESS = 'UPLOAD_PAYMENT_PLANS_TO_GOOGLE_SHEETS_SUCCESS';
export const UPLOAD_PAYMENT_PLANS_TO_GOOGLE_SHEETS_ERROR = 'UPLOAD_PAYMENT_PLANS_TO_GOOGLE_SHEETS_ERROR';

export const UPLOAD_MISSED_PAYMENTS_TO_GOOGLE_SHEETS_REQUEST = 'UPLOAD_MISSED_PAYMENTS_TO_GOOGLE_SHEETS_REQUEST';
export const UPLOAD_MISSED_PAYMENTS_TO_GOOGLE_SHEETS_SUCCESS = 'UPLOAD_MISSED_PAYMENTS_TO_GOOGLE_SHEETS_SUCCESS';
export const UPLOAD_MISSED_PAYMENTS_TO_GOOGLE_SHEETS_ERROR = 'UPLOAD_MISSED_PAYMENTS_TO_GOOGLE_SHEETS_ERROR';