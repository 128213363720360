import Dashboard from 'views/paulAu/Dashboard.js';

/**
 * Admin
 */
import Profile from 'views/pages/admin/Profile.js';
import Role from 'views/pages/admin/role/Index.js';
import RoleEdit from 'views/pages/admin/role/Edit.js';
import RoleShow from 'views/pages/admin/role/Show.js';
import Dealer from 'views/pages/admin/dealer/Index.js';
import DealerEdit from 'views/pages/admin/dealer/Edit.js';
import Permission from 'views/pages/admin/permission/Index';
import PermissionEdit from 'views/pages/admin/permission/Edit';
import User from 'views/pages/admin/user/Index';
import UserEdit from 'views/pages/admin/user/Edit';
import Message from 'views/pages/admin/message/Index';
import MessageEdit from 'views/pages/admin/message/Edit';
import DealerProfile from 'views/paulAu/admin/dealerProfile/Index';
import Modules from 'views/paulAu/admin/modules/Index';
import Integration from 'views/pages/admin/3rdPartyIntegration/Index';

/**
 * Customer
 */
import Customer from 'views/pages/customers/customer/Index';
import CustomerCreate from 'views/paulAu/customers/customer/Create';
import CustomerEdit from 'views/paulAu/customers/customer/Edit';
import CustomerTask from 'views/pages/customers/customer/task/Index';
import CustomerTaskEdit from 'views/pages/customers/customer/task/Edit';
import CustomerNote from 'views/pages/customers/customer/note/Index';
import CustomerNoteEdit from 'views/pages/customers/customer/note/Edit';
import Defaulters from 'views/pages/customers/defaulters/Index';
import Notifications from 'views/pages/customers/notifications/Index';

/**
 * Payments
 */
import UpcomingPayments from 'views/paulAu/payments/upcoming/Index';
import AuthorizedDealers from 'views/paulAu/payments/setup/AuthorizedDealers';
import UnauthorizedDealers from 'views/paulAu/payments/setup/UnauthorizedDealers';
import SetupPayment from 'views/pages/customers/payments/Index';
import Payments from 'views/pages/customers/payments/Index';
import MissedPayments from 'views/pages/payments/missed/Index';
import FailedMandates from 'views/pages/payments/mandate/Failed';
import MandateInfo from 'views/pages/payments/mandate/Info';

/**
 * Deal
 */
import Deal from 'views/pages/deals/Index';
import DealCreate from 'views/pages/deals/Create';
import DealEdit from 'views/pages/deals/Edit';
import DealTaskEdit from 'views/pages/deals/task/Edit';
import DealPayment from 'views/pages/deals/payment/Index';

/**
 * Reports
 */
import DealReport from 'views/pages/reports/deal/Index';
import DealerGrouped from 'views/pages/reports/deal/DealerGrouped';
import VehicleProfit from 'views/pages/reports/vehicle/Profit';
import ReportCustomerLeads from 'views/pages/reports/customer/Leads';
import ReportDealContracts from 'views/pages/reports/deal/Contracts';

/**
 * Invoices
 */
import Invoices from 'views/pages/invoices/Index';
import InvoiceShow from 'views/pages/invoices/Show';
import InvoiceEdit from 'views/pages/invoices/Edit';

/**
 * Vehicle
 */
import Vehicle from 'views/pages/vehicles/Index';
import VehicleEdit from 'views/pages/vehicles/Edit';
import VehicleCreate from 'views/pages/vehicles/Create';
import VehicleDuplicate from 'views/pages/vehicles/Duplicate';
import VehicleCost from 'views/pages/vehicles/VehicleCost';
import VehicleFinancialDetails from 'views/pages/vehicles/FinancialDetails';
import VehicleFinancialHistory from 'views/pages/vehicles/FinancialHistory';
import VehicleMake from 'views/pages/vehicles/make/Index';
import VehicleModel from 'views/pages/vehicles/model/Index';
import Applications from 'views/pages/website/applications/Index';

/**
 * Lead
 */
import Lead from 'views/pages/lead/Index';
import LeadEdit from 'views/pages/lead/Edit';
import LeadCreate from 'views/pages/lead/Create';
import Statistics from 'views/pages/lead/Statistics';
import UsersStatistics from 'views/pages/lead/UsersStatistics';

/**
 * Settings
 */
import SettingsDocuments from 'views/pages/settings/documents/Index';
import SettingsDocumentsForm from 'views/pages/settings/documents/Form';
import SettingsCounties from 'views/pages/settings/counties/Index';
import SettingsReminders from 'views/pages/settings/reminders/Index';
import SettingsResourceTypes from 'views/pages/settings/resourceTypes/Index';
import SettingsResourceCategories from 'views/pages/settings/resourceCategories/Index';

/**
 * Website
 */
import Newsletter from 'views/pages/settings/newsletter/Index';
import FranchiseEnquiries from 'views/paulAu/website/franchiseEnquiries/Index';
import EnquiryForm from 'views/paulAu/website/franchiseEnquiries/Form';
import News from 'views/paulAu/website/news/Index';
import NewsForm from 'views/paulAu/website/news/Form';
import EmailTemplates from 'views/pages/website/emailTemplates/Index';
import Testimonials from 'views/pages/pages/testimonials/Index';

/**
 * Types
 */
import LeadSources from 'views/pages/types/sources/Index';
import Countries from 'views/pages/types/countries/Index';
import Regions from 'views/pages/types/regions/Index';
import Areas from 'views/pages/settings/areas/Index'

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    miniName: "D",
    icon: "fas fa-home text-primary",
    component: Dashboard,
    layout: "/admin"
  },
  {
    collapse: true,
    name: "Admin",
    icon: "fas fa-users-cog text-info",
    state: "adminCollapse",
    views: [
      {
        path: "/profile",
        name: "Profile",
        miniName: "P",
        component: Profile,
        layout: "/admin"
      },
      {
        path: "/dealer/profile-frontend",
        name: "Dealer profile",
        miniName: "DP",
        component: DealerProfile,
        layout: "/admin",
        authUserTeam: true,
        permission: [
          'read-dealer-profile',
          'update-dealer-profile',
          'create-dealer-profile'
        ]
      },
      {
        path: "/dealer/profile",
        name: "Modules",
        miniName: "M",
        component: Modules,
        layout: "/admin",
        authUserTeam: true,
        permission: [
          'read-dealer-profile',
          'update-dealer-profile',
          'create-dealer-profile'
        ]
      },
      {
        path: "/dealer/integration",
        name: "3rd Party Integration",
        miniName: "3PI",
        component: Integration,
        layout: "/admin",
        authUserTeam: true,
        permission: [
          'read-dealer-profile',
          'update-dealer-profile',
          'create-dealer-profile'
        ]
      },
      {
        path: "/role",
        name: "Role Management",
        miniName: "RM",
        component: Role,
        layout: "/admin",
        permission: [
          'access-role-management'
        ]
      },
      {
        path: "/role/:id/edit",
        name: "NavHidden",
        miniName: "RE",
        component: RoleEdit,
        layout: "/admin",
      },
      {
        path: "/role/:id/show",
        name: "NavHidden",
        miniName: "RS",
        component: RoleShow,
        layout: "/admin",
      },
      {
        path: "/dealer",
        name: "Dealers Management",
        miniName: "D",
        component: Dealer,
        layout: "/admin",
        permission: [
          'read-dealer-dealers'
        ]
      },
      {
        path: "/dealer/:id/edit",
        name: "NavHidden",
        miniName: "DE",
        component: DealerEdit,
        layout: "/admin",
      },
      {
        path: "/permission",
        name: "Permission Management",
        miniName: "PL",
        component: Permission,
        layout: "/admin",
        permission: [
          'access-role-management'
        ],
        role: [
          'superadministrator'
        ]
      },
      {
        path: "/permission/:id/edit",
        name: "NavHidden",
        miniName: "PE",
        component: PermissionEdit,
        layout: "/admin",
      },
      {
        path: "/user",
        name: "User Management",
        miniName: "UL",
        component: User,
        layout: "/admin",
        permission: [
          'access-user-management'
        ],
      },
      {
        path: "/user/:id/edit",
        name: "NavHidden",
        miniName: "UE",
        component: UserEdit,
        layout: "/admin",
      },
      {
        path: "/message",
        name: "Messages",
        miniName: "M",
        component: Message,
        layout: "/admin",
        permission: [
          'create-messages'
        ],
      },
      {
        path: "/message/:id/edit",
        name: "NavHidden",
        miniName: "ME",
        component: MessageEdit,
        layout: "/admin",
      },
      {
        path: "/all-notifications",
        name: "NavHidden",
        miniName: "AN",
        component: Notifications,
        layout: "/admin",
      }
    ]
  },
  {
    collapse: true,
    name: "Customers",
    icon: "fas fa-users text-red",
    state: "customersCollapse",
    views: [
      {
        path: "/customer",
        name: "Customer",
        miniName: "C",
        component: Customer,
        layout: "/admin",
        permission: [
          'access-customers'
        ],
      },
      {
        path: "/customer/create",
        name: "NavHidden",
        miniName: "CC",
        component: CustomerCreate,
        layout: "/admin"
      },
      {
        path: "/customer/:id/edit",
        name: "NavHidden",
        miniName: "CE",
        component: CustomerEdit,
        layout: "/admin"
      },
      {
        path: "/customer/:customerId/tasks",
        name: "NavHidden",
        miniName: "CT",
        component: CustomerTask,
        layout: "/admin"
      },
      {
        path: "/customer/:customerId/tasks/:taskId/edit",
        name: "NavHidden",
        miniName: "CTE",
        component: CustomerTaskEdit,
        layout: "/admin"
      },
      {
        path: "/customer/:customerId/notes",
        name: "NavHidden",
        miniName: "CN",
        component: CustomerNote,
        layout: "/admin"
      },
      {
        path: "/customer/:customerId/notes/:noteId/edit",
        name: "NavHidden",
        miniName: "CNE",
        component: CustomerNoteEdit,
        layout: "/admin"
      },
      {
        path: "/defaulters",
        name: "Defaulters",
        miniName: "D",
        component: Defaulters,
        layout: "/admin",
        permission: [
          'access-customers'
        ],
      }
    ]
  },
  {
    collapse: true,
    name: "Payments",
    icon: "fas fa-money-check text-purple",
    state: "paymentsCollapse",
    views: [
      {
        path: "/staff/payments",
        name: "Set Up Payment",
        miniName: "SUP",
        component: AuthorizedDealers,
        layout: "/admin",
        authUserTeam: false
      },
      {
        path: "/payments",
        name: "Set Up Payment",
        miniName: "SUP",
        component: Payments,
        layout: "/admin",
        can: 'canAccessPaymentsDetails'
      },
      {
        path: "/staff/payments/unauthorized-dealers",
        name: "NavHidden",
        miniName: "UAD",
        component: UnauthorizedDealers,
        layout: "/admin"
      },
      {
        path: "/staff/payments/:slug/customers",
        name: "NavHidden",
        miniName: "DP",
        component: SetupPayment,
        layout: "/admin"
      },
      {
        path: "/payments/upcoming",
        name: "Upcoming Payments",
        miniName: "UP",
        component: UpcomingPayments,
        layout: "/admin",
      },
      {
        path: "/payments/late-and-missed",
        name: "Missed Payments",
        miniName: "MP",
        component: MissedPayments,
        layout: "/admin",
      },
      {
        path: "/payments/mandates/failed",
        name: "Failed Mandates",
        miniName: "FM",
        component: FailedMandates,
        layout: "/admin",
      },
      {
        path: "/payments/mandates/:dealerSlug/info/:customerId",
        name: "NavHidden",
        miniName: "MI",
        component: MandateInfo,
        layout: "/admin",
      }
    ]
  },
  {
    collapse: true,
    name: "Deals",
    icon: "fas fa-shopping-cart text-green",
    state: "dealsCollapse",
    permission: [
      'access-all-deals'
    ],
    views: [
      {
        path: "/deals",
        name: "All Deals",
        miniName: "AD",
        component: Deal,
        layout: "/admin",
        permission: [
          'read-deals'
        ],
      },
      {
        path: "/deals/create",
        name: "New Deal",
        miniName: "ND",
        component: DealCreate,
        layout: "/admin",
        can: 'canDealCreate'
      },
      {
        path: "/deals/:id/edit",
        name: "NavHidden",
        miniName: "DE",
        component: DealEdit,
        layout: "/admin"
      },
      {
        path: "/deals/:dealId/task/:taskId",
        name: "NavHidden",
        miniName: "DTE",
        component: DealTaskEdit,
        layout: "/admin"
      },
      {
        path: "/deals/:id/payment",
        name: "NavHidden",
        miniName: "DP",
        component: DealPayment,
        layout: "/admin"
      },
    ]
  },
  {
    collapse: true,
    name: "Reports",
    icon: "fas fa-industry text-info",
    state: "reportsCollapse",
    views: [
      {
        path: "/report/deals",
        name: "Sales Report",
        miniName: "SR",
        component: DealReport,
        layout: "/admin"
      },
      {
        path: "/report/deals/fees",
        name: "Franchise Fee Report",
        miniName: "FFR",
        component: DealerGrouped,
        layout: "/admin"
      },
      {
        path: "/report/vehicles/profit",
        name: "Vehicle Cost/Profit",
        miniName: "VCP",
        component: VehicleProfit,
        layout: "/admin"
      },
      {
        path: "/report/customer/leads",
        name: "Leads source",
        miniName: "LS",
        component: ReportCustomerLeads,
        layout: "/admin"
      },
      {
        path: "/report/deals/current-contracts",
        name: "Current Contracts",
        miniName: "CC",
        component: ReportDealContracts,
        layout: "/admin"
      }
    ]
  },
  {
    collapse: true,
    name: "Invoices",
    icon: "fas fa-file-pdf text-yellow",
    state: "invoicesCollapse",
    permission: [
      'read-invoice'
    ],
    views: [
      {
        path: "/invoices",
        name: "Invoices",
        miniName: "I",
        component: Invoices,
        layout: "/admin"
      },
      {
        path: "/invoice/:id/show",
        name: "NavHidden",
        miniName: "IS",
        component: InvoiceShow,
        layout: "/admin"
      },
      {
        path: "/invoice/:id/edit",
        name: "NavHidden",
        miniName: "IE",
        component: InvoiceEdit,
        layout: "/admin"
      }
    ]
  },
  {
    collapse: true,
    name: "Inventory",
    icon: "fas fa-car text-purple",
    state: "vehicleCollapse",
    permission: [
      'access-vehicles',
      'access-vehicle-makes',
      'access-vehicle-models'
    ],
    views: [
      {
        path: "/vehicle",
        name: "Vehicles",
        miniName: "V",
        component: Vehicle,
        layout: "/admin",
        permission: [
          'read-vehicles'
        ],
      },
      {
        path: "/vehicle/:id/edit",
        name: "NavHidden",
        miniName: "VE",
        component: VehicleEdit,
        layout: "/admin"
      },
      {
        path: "/vehicle/create",
        name: "NavHidden",
        miniName: "VC",
        component: VehicleCreate,
        layout: "/admin"
      },
      {
        path: "/vehicle/:id/duplicate",
        name: "NavHidden",
        miniName: "VD",
        component: VehicleDuplicate,
        layout: "/admin"
      },
      {
        path: "/vehicle/:id/cost",
        name: "NavHidden",
        miniName: "VC",
        component: VehicleCost,
        layout: "/admin"
      },
      {
        path: "/vehicle/:id/financial",
        name: "NavHidden",
        miniName: "VFD",
        component: VehicleFinancialDetails,
        layout: "/admin"
      },
      {
        path: "/vehicle/:id/financial-history",
        name: "NavHidden",
        miniName: "VFH",
        component: VehicleFinancialHistory,
        layout: "/admin"
      },
      {
        path: "/vehicle-make",
        name: "Vehicle Makes",
        miniName: "VMA",
        component: VehicleMake,
        layout: "/admin",
        permission: [
          'read-vehicle-make'
        ],
      },
      {
        path: "/vehicle-model",
        name: "Vehicle Models",
        miniName: "VMO",
        component: VehicleModel,
        layout: "/admin",
        permission: [
          'read-vehicle-model'
        ],
      },
    ]
  },
  {
    collapse: true,
    name: "Leads CRM",
    icon: "fas fa-cube text-default",
    state: "leadCollapse",
    permission: [
      'read-leads'
    ],
    views: [
      {
        path: "/leads",
        name: "View leads",
        miniName: "VL",
        component: Lead,
        layout: "/admin",
        permission: [
          'read-leads'
        ],
      },
      {
        path: "/leads/:id/edit",
        name: "NavHidden",
        miniName: "LE",
        component: LeadEdit,
        layout: "/admin"
      },
      {
        path: "/leads/create",
        name: "Create Lead",
        miniName: "CL",
        component: LeadCreate,
        layout: "/admin",
        permission: [
          'create-leads'
        ],
      },
      {
        path: "/applications",
        name: "Applications",
        miniName: "A",
        component: Applications,
        layout: "/admin",
        permission: [
          'read-applications'
        ],
      },
      {
        path: "/leads/statistics",
        name: "Statistics",
        miniName: "S",
        component: Statistics,
        layout: "/admin",
        permission: [
          'create-leads'
        ],
      },
      {
        path: "/leads/statistics/users",
        name: "Users Stats",
        miniName: "US",
        component: UsersStatistics,
        layout: "/admin",
        permission: [
          'create-leads'
        ],
      },
    ]
  },
  {
    collapse: true,
    name: "Settings",
    icon: "fas fa-cogs text-warning",
    state: "settingsCollapse",
    permission: [
      'read-business-activities',
      'read-company-types',
      'read-counties',
      'read-automation-task',
      'read-automated-reminders'
    ],
    views: [
      {
        path: "/settings/documents",
        name: "Documents",
        miniName: "SD",
        component: SettingsDocuments,
        layout: "/admin",
        role: [
          'superadministrator',
          'administrator'
        ],
        permission: [
          'read-dealer-documents'
        ]
      },
      {
        path: "/settings/documents/create",
        name: "NavHidden",
        miniName: "SD",
        component: SettingsDocumentsForm,
        layout: "/admin"
      },
      {
        path: "/settings/documents/:id/edit",
        name: "NavHidden",
        miniName: "SD",
        component: SettingsDocumentsForm,
        layout: "/admin"
      },
      {
        path: "/settings/resource-types",
        name: "Resource Types",
        miniName: "RT",
        component: SettingsResourceTypes,
        layout: "/admin",
        role: [
          'superadministrator',
          'administrator'
        ],
        permission: [
          'read-resource-types'
        ]
      },
      {
        path: "/settings/resource-categories",
        name: "Resource Categories",
        miniName: "RC",
        component: SettingsResourceCategories,
        layout: "/admin",
        role: [
          'superadministrator',
          'administrator'
        ],
        permission: [
          'read-resource-types'
        ]
      },
      {
        path: "/settings/county",
        name: "Counties",
        miniName: "C",
        component: SettingsCounties,
        layout: "/admin",
        permission: [
          'read-counties'
        ]
      },

      {
        path: "/settings/automated-reminders",
        name: "Reminders",
        miniName: "AR",
        component: SettingsReminders,
        layout: "/admin",
        permission: [
          'read-automated-reminders'
        ]
      }
    ]
  },
  {
    collapse: true,
    name: "Resources",
    icon: "fas fa-file-alt text-primary",
    state: "resourcesCollapse",
    permission: [
      'read-resource'
    ],
    views: []
  },
  {
    collapse: true,
    name: "Website",
    icon: "fas fa-sitemap text-success",
    state: "websiteCollapse",
    authUserTeam: false,
    views: [
      {
        path: "/newsletter",
        name: "Newsletter",
        miniName: "NL",
        component: Newsletter,
        layout: "/admin",
        permission: [
          'read-newsletter'
        ],
      },
      {
        path: "/franchise-enquiries",
        name: "Franchise Enquiries",
        miniName: "FE",
        component: FranchiseEnquiries,
        layout: "/admin",
        permission: [
          'read-franchise-enquiry'
        ],
      },
      {
        path: "/franchise-enquiries/:id/show",
        name: "NavHidden",
        miniName: "FEF",
        component: EnquiryForm,
        layout: "/admin",
        permission: [
          'read-franchise-enquiry'
        ],
      },
      {
        path: "/news",
        name: "News",
        miniName: "N",
        component: News,
        layout: "/admin",
        permission: [
          'read-news'
        ],
      },
      {
        path: "/news/create",
        name: "NavHidden",
        miniName: "NC",
        component: NewsForm,
        layout: "/admin",
      },
      {
        path: "/news/:id/edit",
        name: "NavHidden",
        miniName: "NC",
        component: NewsForm,
        layout: "/admin",
      },
      {
        path: "/settings/emails",
        name: "Email Templates",
        miniName: "ET",
        component: EmailTemplates,
        layout: "/admin",
        permission: [
          'read-email-templates'
        ],
      },
      {
        path: "/pages/testimonials",
        name: "Homepage Testimonials",
        miniName: "HT",
        component: Testimonials,
        layout: "/admin",
        permission: [
          'read-crm-pages'
        ],
      }
    ]
  },
  {
    collapse: true,
    name: "Types",
    icon: "fas fa-sitemap text-default",
    state: "typesCollapse",
    permission: [
      'read-lead-sources',
      'read-countries',
      'read-regions'
    ],
    authUserTeam: false,
    views: [
      {
        path: "/settings/countries",
        name: "Country",
        miniName: "C",
        component: Countries,
        layout: "/admin",
        permission: [
          'read-countries'
        ],
      },
      {
        path: "/types/regions",
        name: "State",
        miniName: "R",
        component: Regions,
        layout: "/admin",
        permission: [
          'read-regions'
        ],
      },
      {
        path: "/types/areas",
        name: "Area",
        miniName: "R",
        component: Areas,
        layout: "/admin",
        permission: [
          'read-areas'
        ],
      },
      {
        path: "/lead-sources",
        name: "Lead Sources",
        miniName: "LS",
        component: LeadSources,
        layout: "/admin",
        permission: [
          'read-lead-sources'
        ],
      },
    ]
  }
];

export default routes;
