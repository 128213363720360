import React, { useState, useMemo } from 'react'
import Pagination from 'react-js-pagination';
import styled from "styled-components";
import { ClipLoader } from 'react-spinners';

import {
    ArraySelect,
    CustomSort, SelectWithoutInput
} from 'views/pages/components/InputSelect'

import {
    Row,
    Col,
    Card, Modal, ModalHeader, ModalBody, Button, Input, InputGroup, InputGroupText,
} from 'reactstrap'
import CustomSearch from "./CustomSearch";

import {
    CustomContainer,
    FilterButtons,
    FirstLetterContainer,
    LoaderContainer, override
} from '../reusable-components/styled-components'
import { parseToWord } from "../../../constants";
import FilterComponent from "@pages/fleet/pages/components/FilterComponent";
import { Calendar } from "react-multi-date-picker";
import CalendarFilter from "@pages/components/CalendarFilter";
import moment from "moment";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";

const CustomTd = styled.td`
 color: ${props => props.color} !important;
 font-size: 14px !important;
 font-weight: 600;
`

export const CalendarInput = styled.input`
  cursor: pointer;
  text-align: center;
  align-items: center;
  border: 1px solid #F8F8F9;
  gap: 10px;
  font-size: 1rem;
  padding: 10px;
  color: #808087;
  outline: none;
`
const CalendarSelect = styled.div`
 border: 1px solid #e6e6e7;
 justify-content: space-around;
 border-radius: 4px;
 display: flex;
 padding: 10px;
 font-size: 14px;
 cursor: pointer;
 align-items: center;
 gap: 10px;
`
export default function DynamicTable({
    valueKey,
    showClear = false,
    showSelectForStatus = false,
    selectOptionStatus,
    clickAction,
    showSelectForDepartments,
    selectOptionDepartment,
    updateCosts,
    labelKey,
    statuses,
    fleetStatuses,
    selectedFleetStatus,
    showFleetStatus = false,
    selectFleetStatus,
    showRangeFilter = false,
    selectDateRange,
    noKeyForFilter = false,
    showDateFilter = false,
    selectDate,
    selectedStatus,
    creatorName,
    makeValue = null,
    modelValue = null,
    driverValue = null,
    showHalf,
    selectMake,
    selectModel,
    pagination,
    generatePdf,
    tableData,
    priorities,
    priorityValue,
    responsive = true,
    make,
    model,
    keys,
    downloadFile,
    editEvent,
    deleteEvent,
    selectPriority,
    selectStatus,
    showCarFilter = true,
    showStatus = false,
    saveStatuses,
    disableSaving = false,
    disableAction = false,
    regularTable = false,
    showSearchButton = false,
    showClearButton = false,
    makeSearch,
    clearFilers,
    selectCreator,
    creators = [],
    showCreatedByFilter = false,
    showWorkerTasksFilter = false,
    loading,
    paginate,
    noPriority = true,
    showButton = false,
    addButtonText = 'Add New',
    addNew,
    searchPlaceHolder = 'Search',
    onSearchChange,
    showAlphabet = false,
    showDepartment = false,
    sortValue,
    selectSort,
    departments,
    showAnyFilter = true,
    showJobBookerFilter = false,
    drivers = [],
    selectDriver,
    taskType,
    selectTaskType,
    types,
    searchValue,
    goToDetails,
    enableDelete = false,
    managers = null,
    accountManagerId = null,
    setAccountManager,
    deleteTask,
    disableDetailsPage = false,
    selectDepartment,
    filterByDate
}) {
    const [funderId, setFunderId] = useState('')
    const [modal, setModal] = useState(false)
    const [clearInput, setClearInput] = useState(false);
    const [values, setValues] = useState([])
    const [selectedDays, setSelectedDays] = useState(null);
    const toggle = () => setModal((prevState) => !prevState);
    const searchData = (event) => {
        onSearchChange({ target: { value: event } })
    }
    const selectFilter = (data) => {
        if (data === 'Clear') {
            return ''
        }
        return data
    }
    const returnFirstLetter = (word) => {
        return word?.charAt(0).toUpperCase();
    }

    const generateRegularItem = (value, key, id, keyVal) => {
        if (key === 'driver') {
            return <CustomTd key={key + '' + id} tabIndex="0" color="grey">{value?.driver?.name}</CustomTd>
        } else if (key === 'vehicle') {
            return <CustomTd key={key + '' + id} tabIndex="0" color="grey">{value.vehicle?.vehicle_make?.name + ' ' + value.vehicle?.vehicle_model?.name}</CustomTd>
        } else if (key === 'reg_no') {
            return <CustomTd key={key + '' + id} tabIndex="0" color="grey">{value['vehicle']?.stock_no}</CustomTd>
        } else if (key === 'action') {
            return <th key={key + '_action_' + id} className={'cursor-pointer'}>
                <Button block color="success"
                    type="button"
                    disabled={disableAction}
                    onClick={() => clickAction(value)} >{keyVal}</Button>
            </th>
        }
        value = parseToWord(value?.toString())
        return <th key={key + '' + id} tabIndex="0"
        >{value}</th>
    }
    const generateBookerItem = (value, key, id, row) => {
        if (key === 'status') {
            value = parseToWord(value?.toString())
            if (value?.toLowerCase() === 'to do') {
                return <CustomTd key={key + '&' + id + value} tabIndex="0" color="#4e4e56">
                    <CustomContainer key={key + '_' + id + value} color='#f5f5f5'>
                        {value}
                    </CustomContainer>
                </CustomTd>
            } else if (value?.toLowerCase() === 'in progress') {
                return <CustomTd key={key + '&&' + id} tabIndex="0" color="#ffbb31">
                    <CustomContainer key={key + '__' + id} color='#fef7ea'>
                        {value}
                    </CustomContainer>
                </CustomTd>
            } else if (value?.toLowerCase() === 'completed') {
                return <CustomTd key={key + '$' + id} tabIndex="0" color="#e4f8f0">
                    <CustomContainer key={key + '--' + id} color='#247d5f'>
                        {value}
                    </CustomContainer>
                </CustomTd>
            }
        } else if (key === 'workers') {
            let names = <div style={{ display: "flex" }}>
                {
                    value.length ? value.map((item, idx) => {
                        return <FirstLetterContainer key={`idz_${idx}`}>{returnFirstLetter(item.name ? item.name : item)}</FirstLetterContainer>
                    }) : ''
                }
            </div>

            return <CustomTd key={key + '' + id} tabIndex="0" color="grey">{names}</CustomTd>
        } else if (key === 'fleet_title') {
            if (value) {
                return <CustomTd key={key + '' + id + value} tabIndex="0" color="#f44336">
                    Needs work
                </CustomTd>
            } else {
                return <CustomTd key={key + '' + id} tabIndex="0" color="#34c759">

                    Ready
                </CustomTd>
            }
        } else if (key === 'action') {
            return <th key={key + '_action_' + id} className={'cursor-pointer'}>
                <Button block color="success"
                    type="button"
                    disabled={disableAction}
                    onClick={() => generatePdf(id)} >Generate</Button>
            </th>
        }
        else if (key === 'delete') {
            return <th key={key + '' + id}> <Button outline color="danger" onClick={() =>  deleteTask(id)}>Delete</Button></th>
        }
        else if (key === 'download') {
            return <th key={key + '_action_' + id} className={'cursor-pointer'}>
                <Button block color="success"
                    type="button"
                    disabled={disableAction}
                    onClick={() => downloadFile(row)} >Download</Button>
            </th>
        }
        else if (key === 'view') {
            return <th key={key + '_action_' + id} className={'cursor-pointer'}>
                <Button block color="success"
                    type="button"
                    onClick={() => goToDetails(id)} >View</Button>
            </th>
        }
        else if (key === 'execution_date' || key === 'delivered_at') {

            return <th key={key + '' + id} tabIndex="0"
            >{value ? moment(value).format('DD/MM/YYYY') : '-'}</th>
        }

        value = parseToWord(value?.toString())
        return <th key={key + '' + id} tabIndex="0"
        >{value}</th>
    }
    const generateCustomItem = (value, key, id, keyIndex, valueIndex) => {
        if (key === 'status') {
            let initialVal = value
            value = parseToWord(value?.toString())

            if (showSelectForStatus) {
                return <th key={key} style={{ padding: '5px' }}>
                    <Input
                        type="select"
                        onChange={(e) => {
                            selectOptionStatus({
                                id: id,
                                status: e.target.value,
                                index: valueIndex,
                            })
                        }}
                        value={initialVal}
                    >
                        {statuses &&
                            statuses.map((item) => {
                                return <option value={item.value} key={item.value}>{item.text}</option>
                            })
                        }
                    </Input>
                </th>
            }
            else if (value?.toLowerCase() === 'in review') {
                return <CustomTd key={`value_${value}_${keyIndex}`} tabIndex="0" color="#007aff">

                </CustomTd>
            } else if (value?.toLowerCase() === 'to do') {
                return <CustomTd key={key + '' + id + value} tabIndex="0" color="#4e4e56">
                    <CustomContainer color='#f5f5f5'>
                        {value}
                    </CustomContainer>
                </CustomTd>
            } else if (value?.toLowerCase() === 'in progress') {
                return <CustomTd key={key + '' + id} tabIndex="0" color="#ffbb31">
                    <CustomContainer color='#fef7ea'>
                        {value}
                    </CustomContainer>
                </CustomTd>
            }
        } else if (key === 'reg_no' || key === 'stock_no') {
            return <th key={key + '' + id} tabIndex="0"
                onClick={() => !disableDetailsPage && goToDetails(id)}>{value?.toUpperCase()}</th>
        } else if (key === 'actions') {
            return <th key={key + '' + id} className={'cursor-pointer'}>
                <IcoMoon
                    className={'cursor-pointer'}
                    iconSet={IconSet}
                    icon={'edit'}
                    size={24}
                    onClick={() => editEvent(id)}
                />
                <IcoMoon
                    className={'cursor-pointer'}
                    iconSet={IconSet}
                    icon={'delete_outline'}
                    size={24}
                    onClick={() => deleteEvent(id)}
                />
            </th>
        } else if (key === 'toDetails') {
            return <th key={key + '' + id} style={{ padding: '5px' }}> <Button outline color="success" onClick={() => !disableDetailsPage && goToDetails(id)}>View</Button></th>
        } else if (key === 'delete') {
            return <th key={key + '' + id} style={{ padding: '5px' }}> <Button outline color="danger" onClick={() => enableDelete && deleteTask(id)}>Delete</Button></th>
        } else if (key === 'department') {
            if (showSelectForDepartments) {
                return <th key={key} style={{ padding: '5px' }}>
                    <Input
                        type="select"
                        onChange={(e) => {
                            selectOptionDepartment({
                                id: id,
                                department: e.target.value
                            })
                        }}
                        value={value?.name}
                    >
                        {statuses &&
                            departments.map((item) => {
                                return <option value={item.value} key={item.value}>{item.text}</option>
                            })
                        }
                    </Input>
                </th>
            } else {
                return <th key={key + '' + id} tabIndex="0" >{parseToWord(value?.name) ?? '-'}</th>
            }

        } else if (key === 'price') {
            if (keys[keyIndex].hasOwnProperty('input')) {
                return <th key={key + "" + id} style={{ padding: '5px' }}>
                    <InputGroup>
                        <InputGroupText>
                            &#163;
                        </InputGroupText>
                        <Input
                            value={value ? value : ''}
                            onChange={(e) => {
                                updateCosts({
                                    id: id,
                                    price: e.target.value
                                })
                            }
                            } placeholder="Cost of job" type={'number'} />
                    </InputGroup>
                </th>
            }
        } else if (key === 'owner') {
            return <th key={key + '' + id} tabIndex="0" >{value?.name}</th>
        } else if (key === 'full_name') {
            return <th key={key + '' + id} tabIndex="0" >{value}</th>
        }
        value = parseToWord(value?.toString())
        return <th key={key + '' + id} tabIndex="0" onClick={() => !disableDetailsPage && goToDetails(id)}>{value}</th>
    }

    const columns = useMemo(() => {
        return keys.map((column, index) => {
            return <th className={showJobBookerFilter ? 'header-text' : ''} key={index + ''} tabIndex="0">{column?.text}</th>
        })
    }, [keys, showJobBookerFilter]);

    const rows = useMemo(() => {
        return tableData && tableData.length > 0 &&
            tableData.map((device, index) => {
                return <tr key={`${index}_device_${device.vehicle_make}`}>
                    {
                        keys.map((item, keyIndex) => {
                            return showJobBookerFilter ? generateBookerItem(item.key === 'vehicle' ? device.vehicle_make + ' ' + device.vehicle_model : device[item.key], item.key, device?.id, device)
                                : regularTable ? generateRegularItem(device, item.key, device?.id, item.text) : generateCustomItem(device[item.key], item.key, device?.id, keyIndex, index)
                        })
                    }

                </tr>
            })
    }, [tableData, keys, showJobBookerFilter, disableAction, regularTable, departments,enableDelete])
    return (
        <>
            <Modal className={'custom-styles'} isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle} />
                <ModalBody>
                    <div className={'d-flex justify-content-center mb-3'}>
                        <div>
                            <span>From</span>
                            <CalendarInput placeholder="Filter by date"
                                value={values?.length ? values[0].format('DD MMM YYYY') : ''} />
                        </div>
                        <div><span>To</span>

                            <CalendarInput placeholder="Filter by date"
                                value={values?.length > 1 ? values[values.length - 1].format('DD MMM YYYY') : ''} />
                        </div>

                    </div>

                    <div className={'d-flex justify-content-center mb-3'}>
                        <Calendar
                            className={'custom-calendar'}
                            value={values}
                            onChange={(e) => {
                                setValues(e)
                            }}
                            range
                            numberOfMonths={2}
                            showOtherDays
                        />
                    </div>
                    <Button outline={true} style={{
                        backgroundColor: '#ec6409',
                        color: 'white',
                        border: '1px solid #ec6409',
                        float: 'right',
                        marginRight: '15px'
                    }} disabled={values.length < 2} onClick={() => {
                        setSelectedDays([values[0].format('YYYY MMM DD'), values[values.length - 1].format('DD MMM YYYY')])
                        filterByDate({
                            from: values[0].format('YYYY-MM-DD'),
                            to: values[values.length - 1].format('YYYY-MM-DD')
                        })
                        toggle()
                    }}
                    >Save</Button>

                </ModalBody>
            </Modal>
            <Card>
                {showAnyFilter &&
                    <Row className='pd-20 g-10'>
                        <Col sm={12} md={3} className={'align-self-center'}>
                            <CustomSearch
                                clear={clearInput}
                                setClear={() => setClearInput(false)}
                                defaultValue={searchValue}
                                onChange={(e) => searchData(e.target.value)}
                                placeholder={searchPlaceHolder}
                            />
                        </Col>
                        {showDepartment &&
                            <Col sm={12} lg={2} md={4}>
                                <SelectWithoutInput
                                    showClear={showClear}
                                    label={funderId ? funderId : `Department`}
                                    onSelect={e => {
                                        setFunderId(selectFilter(e))
                                        selectDepartment(e)
                                    }}
                                    labelKey={labelKey}
                                    valueKey={valueKey}
                                    options={departments}
                                    defaultOption={true}
                                    imageLink={'group-p.png'}
                                />
                            </Col>
                        }
                        {
                            showRangeFilter && <Col sm={12} lg={4} md={4}>
                                <CalendarFilter filterByDate={(data) => {
                                    selectDateRange(data)
                                }} />
                            </Col>
                        }
                        {showAlphabet &&
                            <Col sm={12} md={2}>
                                <CustomSort imageLink={'sort.png'} label={sortValue === 0 ? 'A - Z' : 'Z - A'}
                                    value={sortValue} onSelect={selectSort} />
                            </Col>
                        }

                        {noPriority &&
                            <Col lg={2} md={2} sm={12}>
                                <SelectWithoutInput
                                    showClear={showClear}
                                    label={priorityValue ? priorityValue : `Priority`}
                                    onSelect={e => {
                                        setFunderId(selectFilter(e))
                                        selectPriority(e)
                                        // fetchData(axios.CancelToken.source(), 1, token, e.target.value)
                                    }}
                                    options={priorities}
                                    defaultOption={true}
                                    valueKey={`value`}
                                    labelKey={`label`}
                                    imageLink={'label_important_outline.png'}
                                />
                            </Col>
                        }
                        {showCarFilter &&
                            <>
                                <Col lg={2} md={2} sm={12}>
                                    <SelectWithoutInput
                                        showClear={showClear}
                                        label={makeValue ? makeValue : `Make`}
                                        onSelect={e => {
                                            selectMake(e)
                                        }}
                                        options={make}
                                        defaultOption={true}
                                        valueKey={valueKey}
                                        labelKey={labelKey}
                                        imageLink={'directions_car_grey.png'}
                                    />
                                </Col>
                                <Col lg={2} md={2} sm={12}>
                                    <SelectWithoutInput
                                        showClear={showClear}
                                        label={modelValue ? modelValue : `Model`}
                                        onSelect={e => {
                                            selectModel(e)
                                        }}
                                        options={model}
                                        defaultOption={true}
                                        valueKey={valueKey}
                                        labelKey={labelKey}
                                        imageLink={'loyalty.png'}
                                    />
                                </Col>
                                {
                                    showFleetStatus &&
                                    <Col lg={2} md={3} sm={12}>
                                        <SelectWithoutInput
                                            showClear={showClear}
                                            label={selectedFleetStatus ? selectedFleetStatus : `Fleet Status`}
                                            onSelect={selectFleetStatus}
                                            options={fleetStatuses}
                                            noKeyForFilter={noKeyForFilter}
                                            defaultOption={true}
                                            valueKey={`value`}
                                            labelKey={`text`}
                                            imageLink={'loyalty.png'}
                                        />
                                    </Col>
                                }

                            </>
                        }
                        {
                            showCreatedByFilter &&
                            <Col lg={2} md={2} sm={12}>
                                <SelectWithoutInput
                                    showClear={showClear}
                                    label={creatorName ? creatorName : `Created By`}
                                    onSelect={e => {
                                        selectCreator(e)
                                    }}
                                    options={creators}
                                    imageLink={'group-p.png'}
                                />
                            </Col>

                        }
                        {
                            showStatus &&
                            <Col lg={2} md={2}>
                                <SelectWithoutInput
                                    showClear={showClear}
                                    label={selectedStatus ? selectedStatus : `Status`}
                                    onSelect={e => {
                                        selectStatus(e)
                                    }}
                                    options={statuses}
                                    noKeyForFilter={noKeyForFilter}
                                    defaultOption={true}
                                    valueKey={noKeyForFilter ? null : `value`}
                                    labelKey={noKeyForFilter ? null : `text`}
                                    imageLink={'loyalty.png'}
                                />
                            </Col>
                        }
                        {
                            managers?.length &&
                            <Col lg={3} md={2}>
                                <ArraySelect
                                    noBottom={true}
                                    label={'Account Manager'}
                                    options={managers}
                                    value={parseInt(accountManagerId)}
                                    onChange={(e) => {
                                        setAccountManager(e.target.value)
                                    }
                                    }
                                    defaultOption={true}
                                    valueKey={`id`}
                                    labelKey={`name`}
                                    errorMessage={'Account Manager'}
                                />
                            </Col>
                        }

                        <Col lg={4} md={6} sm={12}>
                            <Row>

                                {
                                    showClearButton &&
                                    <Col>
                                        <FilterButtons className={'mb-2'} background="#fafafa" color="#ec6409"
                                            onClick={() => {
                                                clearFilers()
                                                setClearInput(true)
                                            }}>Clear</FilterButtons>
                                    </Col>
                                }
                                {
                                    showButton &&
                                    <Col>
                                        <FilterButtons background="#ec6409" color="#fafafa" onClick={addNew}>
                                            {addButtonText}
                                        </FilterButtons>
                                    </Col>
                                }

                            </Row>
                        </Col>
                        {
                            showSearchButton &&
                            <div className={'d-flex align-items-center ml-5'}>
                                <Button className="w-100" color="warning" type="button"
                                    onClick={() => makeSearch()}>Filter</Button>
                            </div>
                        }


                    </Row>

                }
                {
                    showWorkerTasksFilter &&
                    <Row className='pd-20'>
                        <CalendarSelect>
                            <img onClick={toggle} width='20px' height='20px'
                                src={require("assets/images/date_range.png")}
                                alt="" />
                            <span
                                onClick={toggle}>{selectedDays?.length ? selectedDays[0] + ' - ' + selectedDays[1] : 'Filter by date'}</span>
                            {selectedDays?.length ? <i className="fa fa-times" onClick={(e) => {
                                e.preventDefault()
                                setSelectedDays([])
                                setValues([])
                                filterByDate({
                                    from: '',
                                    to: ''
                                })
                            }}
                            />
                                :
                                <></>
                            }
                            <i onClick={toggle} className="ni ni-up rotate-down" />
                        </CalendarSelect>
                    </Row>
                }
                {showJobBookerFilter && <FilterComponent models={model}
                    selectDate={selectDate}
                    selectMake={selectMake}
                    modelValue={modelValue}
                    makeValue={makeValue}
                    showDateFilter={showDateFilter}
                    showHalf={showHalf}
                    driverValue={driverValue}
                    selectDriver={selectDriver}
                    searchData={(e) => searchData(e.target.value)}
                    drivers={drivers}
                    isVehicles={true}
                    types={types}
                    taskType={taskType}
                    selectTaskType={selectTaskType}
                    selectModel={selectModel} makes={make} />}

                <>
                    {
                        loading ? <LoaderContainer>
                                <ClipLoader
                                    css={override}
                                    size={40}
                                    color={`#7B61E4`}
                                    loading={loading}
                                />
                            </LoaderContainer> :
                            <>
                                <div className={responsive && 'table-responsive'} style={{ maxHeight: responsive && 'calc(100vh - 250px)' }}>
                                    <div className={showJobBookerFilter ? '' : ''}>
                                        <table className="table">
                                            <thead>
                                            <tr className={'reports-table-header header-white-text'}>
                                                {
                                                    columns
                                                }
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                rows
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {pagination && tableData.length > 0 &&
                                <Row className="react-bootstrap-table-pagination">
                                    <Col lg={6}>
                                        <p className="text-sm text-black">
                                            {`Showing rows ${(pagination.current_page - 1) * pagination.per_page + 1} to
                                         ${pagination.per_page * pagination.current_page > pagination.total ? pagination.total : pagination.per_page * pagination.current_page} of ${pagination.total}`}
                                        </p>
                                    </Col>
                                    <Col lg={6} className="react-bootstrap-table-pagination-list">
                                        <Pagination
                                            hideDisabled
                                            activePage={pagination.current_page}
                                            totalItemsCount={pagination.total}
                                            itemsCountPerPage={pagination.per_page}
                                            onChange={paginate}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            innerClass="pagination react-bootstrap-table-page-btns-ul"
                                        />
                                    </Col>
                                </Row>}
                            </>
                    }
                </>

            </Card>
            {showSelectForStatus ? <Row className={'d-flex justify-content-end w-100'}>
                <Button disabled={disableSaving} onClick={saveStatuses} color={'success'}>
                    Save
                </Button>
            </Row> : null}
        </>
    )
}