import React, { useEffect, useState, useRef } from 'react';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import Chart from 'chart.js';
import { Doughnut } from "react-chartjs-2";
import {
  chartOptions,
  parseOptions,
} from "constants/charts.js";

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import InputSideCustom from 'views/pages/components/InputSideCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from 'reactstrap';

export default function ReportCustomerLeads() {
  const notificationAlert = useRef(null);

  const [token, setToken] = useState('');
  const [filter, setFilter] = useState('');
  const [items, setItems] = useState([]);
  const [totals, setTotals] = useState('');
  const [leadSources, setLeadSources] = useState('');
  const [chartData, setChartData] = useState('');

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    if (window.Chart) {
      parseOptions(Chart, chartOptions())
    }

    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()
    fetchData(source, grs_token, '')

    return () => {
      source.cancel()
    };
  }, [])

  const fetchData = async (source, token, filter) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/report/customer/leads`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: filter,
        cancelToken: source.token,
      })
      console.log('response :: ', response.data)
      const data = response.data
      setFilter({
        ...data.data.filter,
        from: data.data.filter.from? constants.formatDate(data.data.filter.from): '',
        to: data.data.filter.to? constants.formatDate(data.data.filter.to): '',
      })
      setItems(data.data.items)
      setLeadSources(data.data.lead_sources)
      setTotals(data.data.totals)

      _get_chart_data(data.data.lead_sources, data.data.totals)

    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      }
    }
  }

  const filterItems = () => {
    const source = axios.CancelToken.source()
    fetchData(source, token, {
      ...filter,
      from: constants.formatDate(filter.from),
      to: constants.formatDate(filter.to),
    })
  }

  const resetItems = () => {
    setFilter('')
    const source = axios.CancelToken.source()
    fetchData(source, token, '')
  }

  const _get_chart_data = (labels, datas) => {
    let labelsGraphic = []

    Object.keys(labels).forEach(function (item) {
      labelsGraphic.push([labels[item]['name']])
    })

    let datasets = []
    let bgColors = []

    Object.keys(datas).forEach((item) => {
      datasets.push([datas[item]])
      bgColors.push(constants.getColorsForSources(item))
    });

    setChartData({
      labels: labelsGraphic,
      datasets: [{
        data: datasets,
        backgroundColor: bgColors
      }],
    })
  }

  return (
    <>
      <CardsHeader name="Sales" parentName="Home" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <h1 className="title">Sales</h1>
                <p className="text-sm mb-0">Sales overview</p>
              </CardHeader>
              <CardBody>
                <Container>
                  <Row>
                    <Col md={6} sm={12}>
                      <InputSideCustom
                        type={`date`}
                        label={`From`}
                        value={filter && filter.from ? filter.from : ''}
                        onChange={e => setFilter({
                          ...filter,
                          from: e.target.value
                        })}
                      />
                    </Col>
                    <Col md={6} sm={12}>
                      <InputSideCustom
                        type={`date`}
                        label={`To`}
                        value={filter && filter.to ? filter.to : ''}
                        onChange={e => setFilter({
                          ...filter,
                          to: e.target.value
                        })}
                      />
                    </Col>
                  </Row>
                  <div className="d-flex flex-row justify-content-center">
                    <Button className="w-50" color="success" onClick={() => filterItems()}>Filter</Button>
                    <Button className="w-50" color="danger" onClick={() => resetItems()}>Reset</Button>
                  </div>
                </Container>
              </CardBody>
            </Card>
            <Row>
              <Col md={4}>
                <Card>
                  <CardBody className="h-100">
                    <div className="d-flex flex-column justify-content-center align-items-center flex-wrap h-100">
                      {leadSources &&
                        Object.keys(leadSources).map(key => {
                          return <div className="p-1" key={key}>
                            <h3 className="mb-0">{`${leadSources[key].name} - ${totals[leadSources[key].slug] ? totals[leadSources[key].slug] : 0}`}</h3>
                          </div>
                        })
                      }
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md={8}>
                <Card>
                  <CardHeader className="text-center">
                    <h6 className="surtitle">General overview</h6>
                    <h1 className="title mb-0">Customer Lead Sources</h1>
                  </CardHeader>
                  <CardBody>
                    <div className="chart">
                      {chartData &&
                        <Doughnut
                          data={chartData}
                          options={
                            {
                              responsive: true,
                              legend: {
                                display: true,
                                position: 'top',
                              },
                              animation: {
                                animateScale: true,
                                animateRotate: true
                              }
                            }
                          }
                          className="chart-canvas"
                        />}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Card>
              <CardBody>
                <table className="table align-items-center table-flush">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">{`Dealer`}</th>
                      {
                        Object.keys(leadSources).map(key => {
                          return <th scope="col" key={key}>{leadSources[key].name}</th>
                        })
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {items && items.length > 0 &&
                      items.map((item, index) => {
                        return <tr key={index}>
                          <td>{item.business_name ? item.business_name : item.name}</td>
                          {
                            Object.keys(leadSources).map(key => {
                              return <th scope="col" key={key}>{item[leadSources[key].slug]}</th>
                            })
                          }
                        </tr>
                      })
                    }
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}