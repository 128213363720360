import React from 'react'
import moment from "moment";

export const BASE_URL    = process.env.REACT_APP_BASE_URL;
export const SITE_CODE   = process.env.REACT_APP_SITE_CODE;
export const CURRENCY    = process.env.REACT_APP_CURRENCY;
export const TINYMCE_KEY = process.env.REACT_APP_TINYMCE_KEY;

export const API_URL     = `${BASE_URL}/api/v1`;
export const API_CORE_URL = `${BASE_URL}/api/v1`;
export const FLEET_URL     = `${BASE_URL}/api/v2`;
export const STORAGE_URL = `${BASE_URL}/storage/`;

export const TRACKING_CLIENT_ID     = process.env.REACT_APP_TRACKING_CLIENT_ID;
export const TRACKING_CLIENT_SECRET = process.env.REACT_APP_TRACKING_CLIENT_SECRET;
export const TRACKING_URL           = process.env.REACT_APP_TRACKING_URL;

export const FLESPI_API_HOST  = process.env.REACT_APP_FLESPI_API_HOST;
export const FLESPI_API_TOKEN = process.env.REACT_APP_FLESPI_API_TOKEN;

export const PUSHER_APP_ID      = process.env.REACT_APP_PUSHER_APP_ID;
export const PUSHER_APP_KEY     = process.env.REACT_APP_PUSHER_APP_KEY;
export const PUSHER_APP_SECRET  = process.env.REACT_APP_PUSHER_APP_SECRET;
export const PUSHER_APP_CLUSTER = process.env.REACT_APP_PUSHER_APP_CLUSTER;

export const UK_VEHICLE_DATA_BASE_URL = process.env.REACT_APP_UK_VEHICLE_DATA_API_HOST;
export const UK_VEHICLE_DATA_API_URL  = `${UK_VEHICLE_DATA_BASE_URL}/api/datapackage`;
export const UK_VEHICLE_DATA_API_KEY  = process.env.REACT_APP_UK_VEHICLE_DATA_API_KEY;

// Microservices
export const FLEET_MS_BASE_URL = process.env.REACT_APP_MS_FLEET;
export const FLEET_MS_API_URL     = `${BASE_URL}/api`;

export const LIVE_VEHICLES_MS_API_URL     =  process.env.REACT_APP_MS_LIVE_VEHICLES
export const formatDate = (d) => {
  if (d) {
    let date = new Date(d);
    let ye = Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
    let mo = Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
    let da = Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

    return `${ye}-${mo}-${da}`
  }
}

export const formatToDatePickerValue = (d) => {
if (d) {
  let formatted = d.split("-")
  return  {
    day: parseInt(formatted[0]),
    month: parseInt(formatted[1]),
    year: parseInt(formatted[2]),
  };
}
}
export const formatDateShortGB = (d) => {
  if (d) {
    let date = new Date(d);
    let ye = Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
    let mo = Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
    let da = Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

    return `${da}/${mo}/${ye}`
  }
}

export const checkAndFormatShortGb = (d) => {
  if (d &&  ((d.includes('T') && d.includes('Z')) || (d.includes('t') && d.includes('z')))) {
    let date = new Date(d);
    let ye = date.getUTCFullYear();
    let mo = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    let da = date.getUTCDate().toString().padStart(2, '0');

    return `${da}/${mo}/${ye}`;
  } else return d
}
export const formatDateFullGB = (d) => {
  if (d) {
    let date = new Date(d);

    let options = {
      year: 'numeric', month: '2-digit', day: '2-digit',
      hour: '2-digit', minute: '2-digit', second: '2-digit',
      hour12: false
    }

    let formatted = Intl.DateTimeFormat('en-BG', options).format(date);

    return formatted
  }
}

export const formatDateHoursSeconds = (d) => {
  if (d) {
    let date = new Date(d);
    let ye = Intl.DateTimeFormat( 'en', { year: 'numeric' }).format(date);
    let mo = Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
    let da = Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
    let ho = Intl.DateTimeFormat('en', { hour: '2-digit', hour12: false }).format(date);
    let mi = Intl.DateTimeFormat('en', { minute: '2-digit' }).format(date);

    return `${ye}-${mo}-${da}T${ho}:${mi}`
  }
}

export const getRandomColor = () => {
  let letters = '0123456789ABCDEF'.split('');
  let color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const getColorsForSources = (labels) => {
  let colors = localStorage.getItem('sourcesColors') !== null ? JSON.parse(localStorage.getItem('sourcesColors')) : {};

  let bgColors = [];
  let shouldUpdate = false;

  if (Array.isArray(labels)) {
    labels.forEach(element => {
      if (colors[element]) {
        bgColors.push(colors[element]);
      } else {
        let tempColor = getRandomColor();
        bgColors.push(tempColor);
        colors[element] = tempColor;
        shouldUpdate = true;
      }
    });
  } else {
    if (colors[labels]) {
      return colors[labels]
    } else {
      let tempColor = getRandomColor();
      colors[labels] = tempColor;
      window.localStorage.setItem('sourcesColors', JSON.stringify(colors));

      return tempColor;
    }
  }

  if (shouldUpdate) {
    window.localStorage.setItem('sourcesColors', JSON.stringify(colors))
  }

  return bgColors;
}

export const formatString = (str, num) => {
  return str.slice(0, num) + '..';
}

export const convertKmToMile = (val) => {
  return Math.round(val * 0.621371);
}
export const parseToWord = (name) => {
  if (name) {
    let text = name.replace(/_+/g, ' ').toLowerCase();
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
}
export const parseToKey = (name) => {
       if (name) {
         return name.replace(/\s+/g, '_').toLowerCase();
       }
    }

export const returnFirstLetter = (word) => {
  return word?.charAt(0).toUpperCase();
}
export const propertiesSumInArray = (array,key) => {
 return  array.reduce((val, object) => {
    return Number(val) + Number(object?.[key]);
  }, 0)
}

export const formatCalendarDate = (date) => {
   if (date) {
     return `${date.year}-${date.month}-${date.day}`
   }
   else return ''
}

export const getYears = () => {
  let years = [];
  let currentYear = new Date().getFullYear();
  let startYear = 2022;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
}

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const  vehicleStatuses =
    {
      available: "Available",
      pending: "Pending",
      awarded: "Awarded",
      out_on_rent: "Out on rent",
      sold: "Sold"
    }

export const  activeStatuses =
    {
      1: "Yes",
      0: "No",
    }
export const jobCategories =   {
  1: "Mechanical", 2: "Valeting", 3: "Workshop", 4: "Body shop", 5: "Ply lining", 6: "Parts"
}

export const taskStatuses = {
    'new': 'New request',
    'in_progress' : 'In progress',
    'completed': 'Completed'
}

export const isEmailValid = (email) => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailRegex.test(email);
}

export const validateForm = (requiredFields,formData) => {
  const hasEmail = requiredFields.includes('email')
  return (
      requiredFields.every(field => {
          if (formData && formData[field]) {
              return Array.isArray(formData[field]) ? formData[field].length : formData[field]
          }
      } ) &&
      (hasEmail ? isEmailValid(formData['email']) : true)
  );
};

export const cutEmail = (email) => {
    let emailRegex = /[\w._%+-]+@[\w.-]+\.[a-zA-Z]{2,}/;

    return email.match(emailRegex);
}
export const formatEmailText = (email) => {
    return email.replace(/<[^>]+>/g, '');
}
export const parseGmailDate = (date) => {

    let newDate = moment(date, "ddd, DD MMM YYYY HH:mm:ss Z");

    return  newDate.format("DD/MM/YYYY");

}

export const timeAtAddressType  = {
        "1": "1 Year",
        "2": "2 Years",
        "3": "3 Years",
        "4": "4 Years",
        "5": "5 Years +"
}

export const titles  = {
        "miss": "Miss",
        "mrs": "Mrs",
        "mr": "Mr"
 }

export const dealStatuses = {
    'renewing': 'Renewing',
    'purchased_on_retention': 'Purchased on retention',
    'pending_payment' : 'Awaiting order',
    'paid_order_fee' : 'Paid order fee',
    'order_signed' : 'Order signed',
    'initial_payment_paid' : 'Initial payment paid',
    'delivered' : 'Delivered',
 }

 export const intervals = {
    day: "Day(s)",
    month: "Month(s)",
    week:"Week(s)"
 }

export const paymentIntervals = {
    days: "Days",
    months: "Months",
    weeks:"Weeks"
}
export const highlightText = (text,replacement) => {
    const highlightedText = text.replace(
        new RegExp(`(${replacement})`, 'gi'),
        '<span style="background-color: #fde293">$1</span>'
    );
     return <div dangerouslySetInnerHTML={{ __html: highlightedText }} />;
 }

export const monthsObj = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
]

export const isObject = (obj) => {
    return obj !== null && typeof obj === 'object' && !Array.isArray(obj);
}
export const stringToColor = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xFF;
        color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
};