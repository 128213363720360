import React, { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom'
import NotificationAlert from 'react-notification-alert'
import axios from 'axios'
import Cookies from 'js-cookie'

import actions from 'actions';
import * as constants from 'constants/index.js'
import CardsHeader from 'layouts/Headers/CardsHeader.js'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from 'reactstrap'

export default function ShowApplication() {
  const { id } = useParams()
  const notificationAlert = useRef(null)
  const history = useHistory()
  const dispatch = useDispatch()

  const [token, setToken] = useState('')
  const [item, setItem] = useState('')

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    }
    notificationAlert.current.notificationAlert(options)
  }

  useEffect(() => {
       if (id) {
         const grs_token = Cookies.get('grs_token')
         setToken(grs_token)
         const source = axios.CancelToken.source()

         const fetchData = async () => {
           try {
             const response = await axios({
               method: 'GET',
               url: `${constants.API_URL}/applications/${id}`,
               headers: {
                 Authorization: `Bearer ${grs_token}`,
               },
               cancelToken: source.token,
             })
             console.log('response :: ', response.data)
             const data = response.data
             setItem(data.data.item)

             dispatch(actions.getNotifications(grs_token));
           } catch (error) {
             if (axios.isCancel(error)) {
               console.log('Request canceled :: ', error)
             } else {
               console.error('error :: ', error)
               notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
             }
           }
         }

         fetchData()

         return () => {
           source.cancel()
         }
       }
  }, [id])

  const goList = () => {
    history.push({
      pathname: '/admin/applications'
    })
  }

  const approveApplication = async () => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/applications/${id}/approve`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      console.log('response :: ', response.data)
      const data = response.data

      notify('success', 'Success', data.message)
    } catch (error) {
      console.error('error :: ', error)
      notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
    }
  }

  const showPDF = (pdfURL) => {
    window.open(`${constants.STORAGE_URL}documents/aml/${pdfURL}`, '_blank')
  }

  return (
    <>
      <CardsHeader
        name="Application Enquiry"
        parentName="Application Management"
        currentName="List"
      />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid>
        <Row className="my-2">
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row className="my-2">
                  <Col xs={8}>
                    <h1 className="mb-0">Application Management</h1>
                  </Col>
                  <Col xs={2} className="text-right">
                    <Button
                      className="w-100"
                      color="secondary"
                      style={{ margin: 5 }}
                      type="button"
                      onClick={() => approveApplication()}
                    >
                      Approve
                    </Button>
                  </Col>
                  <Col xs={2} className="text-right">
                    <Button
                      className="w-100"
                      color="success"
                      style={{ margin: 5 }}
                      type="button"
                      onClick={() => goList()}
                    >
                      Back to list
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={6} className="p-3">
                    <h2 className="mb-3">{`Personal Details`}</h2>
                    <Row className="my-2">
                      <Col md={6}>
                        <b>{`Name`} :</b>
                      </Col>
                      <Col md={6}>{item.full_name}</Col>
                    </Row>
                    <Row className="my-2">
                      <Col md={6}>
                        <b>{`Email`} :</b>
                      </Col>
                      <Col md={6}>{item.email}</Col>
                    </Row>
                    <Row className="my-2">
                      <Col md={6}>
                        <b>{`Mobile`} :</b>
                      </Col>
                      <Col md={6}>{item.mobile_number}</Col>
                    </Row>
                    <Row className="my-2">
                      <Col md={6}>
                        <b>{`License Number`} :</b>
                      </Col>
                      <Col md={6}>{item.driver_license_number}</Col>
                    </Row>
                    <Row className="my-2">
                      <Col md={6}>
                        <b>{`Country of issue`} :</b>
                      </Col>
                      <Col md={6}>{item.country_of_issue}</Col>
                    </Row>
                    <Row className="my-2">
                      <Col md={6}>
                        <b>{`License Issue Date`} :</b>
                      </Col>
                      <Col md={6}>{constants.formatDate(item.licence_issue_date)}</Col>
                    </Row>
                    <Row className="my-2">
                      <Col md={6}>
                        <b>{`License Expiry Date`} :</b>
                      </Col>
                      <Col md={6}>{constants.formatDate(item.licence_expiry_date)}</Col>
                    </Row>
                    <Row className="my-2">
                      <Col md={6}>
                        <b>{`Date of Birth`} :</b>
                      </Col>
                      <Col md={6}>{constants.formatDate(item.dob)}</Col>
                    </Row>
                    <Row className="my-2">
                      <Col md={6}>
                        <b>{`Customer Accepted search conditions`} :</b>
                      </Col>
                      <Col md={6}>{item.terms_agreement ? "Yes" : "No"}</Col>
                    </Row>
                  </Col>
                  <Col md={6} className="p-3">
                    <h2 className="mb-3">{`Address Details`}</h2>
                    <Row className="my-2">
                      <Col md={6}>
                        <b>{`Time at Address`} :</b>
                      </Col>
                      <Col md={6}>{item.time_at_address}</Col>
                    </Row>
                    <Row className="my-2">
                      <Col md={6}>
                        <b>{`Address`} :</b>
                      </Col>
                      <Col md={6}>{item.address}</Col>
                    </Row>
                    <Row className="my-2">
                      <Col md={6}>
                        <b>{`County`} :</b>
                      </Col>
                      <Col md={6}>{item.county && item.county.name}</Col>
                    </Row>
                    <Row className="my-2">
                      <Col md={6}>
                        <b>{`Postcode`} :</b>
                      </Col>
                      <Col md={6}>{item.postal_code}</Col>
                    </Row>
                    <Row className="my-2">
                      <Col md={6}>
                        <b>{`Previous Address`} :</b>
                      </Col>
                      <Col md={6}>{item.previous_address}</Col>
                    </Row>
                  </Col>
                  <Col md={6} className="p-3">
                    <h2 className="mb-3">{`Income Details`}</h2>
                    <Row className="my-2">
                      <Col md={6}>
                        <b>{`Source Of Income`} :</b>
                      </Col>
                      <Col md={6}>{item.company_name}</Col>
                    </Row>
                    <Row className="my-2">
                      <Col md={6}>
                        <b>{`Company Address`} :</b>
                      </Col>
                      <Col md={6}>{item.business_address}</Col>
                    </Row>
                    <Row className="my-2">
                      <Col md={6}>
                        <b>{`Company Phone Number`} :</b>
                      </Col>
                      <Col md={6}>{item.business_phone_number}</Col>
                    </Row>
                    <Row className="my-2">
                      <Col md={6}>
                        <b>{`Company Type`} :</b>
                      </Col>
                      <Col md={6}>
                        {item.company_type && item.company_type.name}
                      </Col>
                    </Row>
                    <Row className="my-2">
                      <Col md={6}>
                        <b>{`Business Activity`} :</b>
                      </Col>
                      <Col md={6}>
                        {item.business_activity && item.business_activity.name}
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6} className="p-3">
                    <h2 className="mb-3">{`Banking Details`}</h2>
                    <Row className="my-2">
                      <Col md={6}>
                        <b>{`Bank Name`} :</b>
                      </Col>
                      <Col md={6}>{item.bank_name}</Col>
                    </Row>
                    <Row className="my-2">
                      <Col md={6}>
                        <b>{`Sort Code`} :</b>
                      </Col>
                      <Col md={6}>{item.bank_sort_code}</Col>
                    </Row>
                    <Row className="my-2">
                      <Col md={6}>
                        <b>{`Account Number`} :</b>
                      </Col>
                      <Col md={6}>{item.bank_account_number}</Col>
                    </Row>
                    <Row className="my-2">
                      <Col md={6}>
                        <b>{`Name on Account`} :</b>
                      </Col>
                      <Col md={6}>{item.bank_account_name}</Col>
                    </Row>
                  </Col>
                  <Col md={6} className="p-3">
                    <h2 className="mb-3">{`More Information`}</h2>
                    <Row className="my-2">
                      <Col md={6}>
                        <b>{`Referral`} :</b>
                      </Col>
                      <Col md={6}>
                        {item.lead_source && item.lead_source.name}
                      </Col>
                    </Row>
                    <Row className="my-2">
                      <Col md={6}>
                        <b>{`Date Submitted`} :</b>
                      </Col>
                      <Col md={6}>
                        {constants.formatDateFullGB(item.created_at)}
                      </Col>
                    </Row>
                    <Row className="my-2">
                      <Col md={6}>
                        <b>{`Office`} :</b>
                      </Col>
                      <Col md={6}>{item.dealer && item.dealer.name}</Col>
                    </Row>
                    <Row className="my-2">
                      <Col md={6}>
                        <b>{`Customer ID`} :</b>
                      </Col>
                      <Col md={6}>
                        {item.customer && item.customer.full_name
                          ? item.customer.full_name
                          : "-"}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {item.pdf && (
                  <Row>
                    <Col md={6}>
                      <Button
                        className="w-100"
                        color="success"
                        onClick={() => showPDF(item.pdf)}
                      >
                        Get PDF
                      </Button>
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}