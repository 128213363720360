import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { DragHandle } from "@pages/components/drag/DragHandle";
import { Button } from "reactstrap";
import { parseToWord } from "../../../../constants";
import styled from "styled-components";
import { CSS } from "@dnd-kit/utilities";


const CustomTd = styled.td`
 color: ${props => props.color} !important;
 font-size: 14px !important;
 font-weight: 600;
`
const DraggingRow = styled.td`
  background: rgba(127, 207, 250, 0.3);
`;

export const SortableTableItem = ({ device, index, keys, goToDetails, disableDetailsPage, disable = false, deleteItem = false, removeItem = false }) => {
    const {
        attributes,
        listeners,
        transform,
        transition,
        setNodeRef,
        isDragging
    } = useSortable({
        id: `${device.id}`
    });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition: transition
    };
    const generateDepartmentItem = (value, key, id, keyIndex) => {
        if (key === 'toDetails') {
            return <th key={`${id}_${keyIndex}`} style={{ padding: '5px' }}> <Button outline color="success" onClick={() => !disableDetailsPage && goToDetails(id)}>View</Button></th>
        }
        else if (key === 'delete' && deleteItem) {
            return <th key={`${id}_${keyIndex}`} style={{ padding: '5px' }}> <Button outline color="success" onClick={() => removeItem && removeItem(id)}>Delete</Button></th>
        }
        else if (key === 'vehicle_make_name' || key === 'vehicle_model_name' || key === 'stock_no' || key === 'current_mileage'|| key === 'colour') {
            return <th key={key + '' + id} tabIndex="0"> {value.vehicle[key]}</th>
        } else if (key === 'vehicle_model_name_nested') {
            return <th key={key + '' + id} tabIndex="0" >{value?.vehicle?.vehicle_model?.name}</th>
        } else if (key === 'vehicle_make_name_nested') {
            return <th key={key + '' + id} tabIndex="0" >{value?.vehicle?.vehicle_make?.name}</th>
        }
        else {
            let depIndex = value.departments.findIndex(item => item.name === key)
            if (depIndex > -1) {
                let item = value.departments[depIndex]
                let color = item.status === 'in_review' ? '#FFC231' :
                    item.status === 'in_progress' ? '#000' :
                        item.status === 'completed' ? '#00B170' :
                            item.status === 'needed' ? '#FF1E03' : '#000'
                return <CustomTd color={color} key={keyIndex + '_' + id + '_' + depIndex} tabIndex="0"> {parseToWord(item.statusLabel)}</CustomTd>
            }

        }
        let val = value.hasOwnProperty(key) ? value[key] : '-'
        value = parseToWord(val?.toString());
        return <th key={key + '' + id} tabIndex="0"> {value}</th>
    }
    return (
        <tr ref={setNodeRef} style={style} className={'cursor-pointer'} key={`${index}_device`}>
            {isDragging ?
                <DraggingRow colSpan={11}>&nbsp;</DraggingRow>
                :
                <>
                    {!disable && <th key={`${index}_device_${device?.make}`}><DragHandle {...attributes} {...listeners} /></th>}
                    {
                        keys.map((item, keyIndex) => {
                            return generateDepartmentItem(device, item.key, device?.id, keyIndex)
                        })
                    }
                </>
            }
        </tr>
    )
}