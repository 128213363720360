import React, { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";

import CardsHeader from 'layouts/Headers/CardsHeader.js';

import { DealContextProvider } from './context';

import {
  Container,
  Row,
  Col,
  Card,
} from "reactstrap";

import Tabs from 'views/pages/deals/tabs/Tabs'
import Content from 'views/pages/deals/tabs/Content'
import actions from "actions/index.js";
import { useDispatch } from "react-redux";
import { LoaderContainer, override } from "@pages/reusable-components/styled-components";
import { ClipLoader } from "react-spinners";

export default function DealEdit() {
  const { id } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const notificationAlert = useRef(null)
  const [showBackButton, setShowBackButton] = useState(false)
  const [loading, setLoading] = useState(true)
  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  }
  const clearMenus = () => {
    dispatch(actions.setMenusList([]))
    dispatch(actions.setMenuName(null))
    dispatch(actions.setActiveMenu(null))
    history.push(`/admin/deals`)
  }

  return (
    <DealContextProvider id={id} notify={notify} setLoading={setLoading}>
      {
        loading ?
          <LoaderContainer>
            <ClipLoader
              css={override}
              size={40}
              color={`#7B61E4`}
              loading={loading}
            />
          </LoaderContainer> :
          <>
            <CardsHeader name="Deal Management"
              parentName="Deals Management"
              currentName="Edit"
              backButtonText={'Back to Deals'}
              showBackButton={showBackButton}
              redirect={clearMenus} />
            <div className="rna-wrapper">
              <NotificationAlert ref={notificationAlert} />
            </div>
            <Container className="mt--5 admin-main-body" fluid >
              <Row>
                <Col md={12} xl={12}>
                  <Tabs activateTab={setShowBackButton} />
                  <Card>
                    <Content />
                  </Card>
                </Col>
              </Row>
            </Container>
          </>
      }
    </DealContextProvider>
  )
}