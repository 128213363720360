import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import {
  InputKeySelect,
} from 'views/pages/components/InputSelect';
import TableCustom from 'views/pages/components/TableCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

export default function FailedMandates() {
  const notificationAlert = useRef(null);
  const history = useHistory();

  const authUserTeam = useSelector(state => state.getState).authUserTeam;

  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [dealersList, setDealersList] = useState('');
  const [payments, setPayments] = useState([]);
  const [filter, setFilter] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    let grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        let response = await axios({
          method: 'GET',
          url: constants.API_URL + '/payments/mandates/failed',
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        setDealersList(response.data.data.dealersList)
        setPayments(response.data.data.items)

        setTimeout(() => {
          setLoading(false)
        }, 1000);
      } catch (error) {
        setLoading(false)
        if (axios.isCancel(error)) {
          console.log('Request canceled', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    };
  }, [])

  const filterPayment = () => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/payments/mandates/failed',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: filter
    })
      .then(response => {
        console.log('res :: ', response.data)
        let data = response.data
        setPayments(data.data.items)
      })
      .catch(error => {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      })
  }

  const resetPayment = () => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/payments/mandates/failed',
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(response => {
        console.log('res :: ', response.data)
        let data = response.data
        setPayments(data.data.items)
        setFilter('')
      })
      .catch(error => {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      })
  }

  const viewCustomer = (item) => {
    history.push({
      pathname: authUserTeam ? 
        '/admin/payments' : 
        '/admin/staff/payments/' + item.go_cardless_agreements[0].dealer.slug + '/customers',
      state: {
        search: item.full_name
      }
    })
  }

  return (
    <>
      <CardsHeader name="Mandates" parentName="Home" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <h1 className="title mb-0">Failed mandates part of active deals. System can't collect payments for the following customers</h1>
              </CardHeader>
              <CardBody>
                <Container>
                  <Row>
                    <Col md={4} sm={6}>
                      <InputKeySelect
                        label={`Dealer`}
                        options={dealersList}
                        value={filter && filter.dealer ? filter.dealer : ''}
                        onChange={e => setFilter({
                          ...filter,
                          dealer: e.target.value
                        })}
                        defaultOption={true}
                      />
                    </Col>
                  </Row>
                  <div className="d-flex flex-row justify-content-center">
                    <Button className="w-50" color="success" onClick={() => filterPayment()}>Filter</Button>
                    <Button className="w-50" color="danger" onClick={() => resetPayment()}>Reset</Button>
                  </div>
                </Container>
                <TableCustom 
                  loading={loading}
                  keyField={`id`}
                  searchField={false}
                  items={payments}
                  columns={[
                    {
                      dataField: "full_name",
                      text: "Name",
                      sort: true
                    },
                    {
                      dataField: "mandate",
                      text: "Mandate",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.go_cardless_agreements[0].mandate
                      }
                    },
                    {
                      dataField: "status",
                      text: "Status",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.go_cardless_agreements[0].state
                      }
                    },
                    {
                      dataField: "status",
                      text: "Status",
                      style: {
                        display: !authUserTeam? 'block': 'none'
                      },
                      headerStyle: {
                        display: !authUserTeam? 'block': 'none'
                      },
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.go_cardless_agreements[0].dealer.name
                      }
                    },
                    {
                      dataField: "",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                color=""
                                role="button"
                                size="sm"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" right>
                                <DropdownItem
                                  onClick={e => viewCustomer(row)}
                                >
                                  View Customer
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </>
                        )
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}