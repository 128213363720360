const deletee = (permissions, auth_user_team, lead) => {
  if (auth_user_team && auth_user_team.id !== lead.dealer_id) {
    return false;
  }

  return permissions.includes('delete-applications')
}

const hasPermission = (permissions, permission) => {
  return permissions.includes(permission)
}

export {
  deletee,
  hasPermission,
};