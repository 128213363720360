import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import NotificationAlert from 'react-notification-alert'
import axios from 'axios'
import Cookies from 'js-cookie'

import * as constants from 'constants/index.js'
import CardsHeader from 'layouts/Headers/CardsHeader.js'

import FleetCalendar from 'views/pages/components/FleetCalendar';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
} from 'reactstrap'

const gradientColor = [
  'bg-gradient-yellow',
  'bg-gradient-purple',
  'bg-gradient-primary'
]

const fontColor = [
  'text-black',
  'text-white',
  'text-white'
]

export default function BodyShopWork() {
  const { category } = useParams()
  const notificationAlert = useRef(null)
  const history = useHistory()

  const [loading, setLoading] = useState(true)
  const [jobTypes, setJobTypes] = useState([])
  const [statusTypes, setStatusTypes] = useState([])
  const [jobs, setJobs] = useState([])
  const [jobsWithJobType, setJobsWithJobType] = useState('')

  const [jobTypesObject, setJobTypesObject] = useState('')

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    }
    notificationAlert.current.notificationAlert(options)
  }

  useEffect(() => {
    if(category) {
      const grs_token = Cookies.get('grs_token')

      const source = axios.CancelToken.source()

      const fetchData = async () => {
        try {
          const response = await axios({
            method: 'GET',
            url: `${constants.API_URL}/fleet-management/category-dashboard`,
            headers: {
              Authorization: `Bearer ${grs_token}`,
            },
            params: {
              category: category
            },
            cancelToken: source.token,
          })
          console.log('response :: ', response.data)
          const data = response.data

          let _job_types = data.data.jobTypes
          setJobTypes(_job_types)

          let _jobTypes = {}
          _job_types.map(type => {
            _jobTypes[type.type] = type.name
          })
          setJobTypesObject(_jobTypes)

          setStatusTypes(data.data.statusTypes)
          setJobs(data.data.jobs)
          setJobsWithJobType(data.data.jobsWithJobType)

          setLoading(false)
        } catch (error) {
          setLoading(false)
          if (axios.isCancel(error)) {
            console.log('Request canceled :: ', error)
          } else {
            console.error('error :: ', error)
            notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
          }
        }
      }

      fetchData()

      return () => {
        source.cancel()
      }
    }
  }, [category])

  const goJobs = (type) => {
    history.push({
      pathname: `/admin/fleet-job/type-inspect/${type}`,
      state: {
        category: category,
      }
    })
  }

  const makeHeader = (category) => {
    return `${category.slice(0, 1).toUpperCase()}${category.slice(1, category.length).replace('_', ' ').toLowerCase()}`
  }

  return (
    <>
      <CardsHeader name="Leads" parentName="Leads Management" currentName="Create" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
				        <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">{category? makeHeader(category): ''} Dashboard</h1>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row className="m-2">
                  {
                    Object.keys(jobsWithJobType).map((type, index) => {
                      return (
                        <Col md={4} className="p-2" key={index}>
                          <Card className={`card-stats ${gradientColor[index % 3]}`}>
                            <CardBody>
                              <Row>
                                <div className="col my-3">
                                  <CardTitle
                                    tag="h2"
                                    className={`mb-0 ${fontColor[index % 3]} text-center`}
                                  >
                                    {jobTypesObject[type]}
                                  </CardTitle>
                                  <h2 className={`font-weight-bold mb-0 ${fontColor[index % 3]} text-center`}>
                                    {jobsWithJobType[type] && jobsWithJobType[type].length}
                                  </h2>
                                </div>
                              </Row>
                            </CardBody>
                            <CardBody className="bg-green">
                              <p className="my-0 text-sm text-white text-center cursor-pointer" onClick={() => goJobs(type)}>
                                <span className="text-nowrap mr-2">{`View all Awaiting ${jobTypesObject[type]}`}</span>{" "}
                              </p>
                            </CardBody>
                          </Card>
                        </Col>
                      )
                    })
                  }
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                {!loading &&
                  <FleetCalendar jobs={jobs} statusTypes={statusTypes} jobTypes={jobTypes} />
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}