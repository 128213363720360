import React, { useEffect, useState } from 'react'
import styled from "styled-components";
import {
    Button,
    Col, Modal, ModalHeader, Row
} from 'reactstrap'
import {
    CustomFilter,
    SelectWithoutInput,
} from "@pages/components/InputSelect";
import CustomSearch from "../../../components/CustomSearch";
import { formatDateShortGB, propertiesSumInArray} from "../../../../../constants";
import {
    Text,
    ReportContainer,
    TaskDetailsContainer,
    TextContainer, CalendarInput, TimeStartStopContainer, ModalBodyContainer
} from '@pages/reusable-components/styled-components'
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "react-modern-calendar-datepicker";
import moment from 'moment'
import FilterComponent from "@pages/fleet/pages/components/FilterComponent";
import DynamicTable from "@pages/components/DynamicTable";
import { useHistory } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import CustomTable from "@pages/components/Table/CustomTable";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";


const ButtonContainer = styled.div`
  background-color: ${props => props.reverse ? '#EC6409' : 'white'};
  margin-right: 20px;
  width: 162px;
  height: 40px;
  border-radius: 4px;
  justify-content: center;
  display: flex;
  align-items: center;
  border: 1px solid ${props => props.reverse ? 'white' : '#EC6409'};
  gap: 10px;
  color: ${props => props.reverse ? 'white' : '#EC6409'};
  float: right;
  cursor: pointer;
`

const DButton = styled.div`
  background-color: ${props => props.backgroundColor ? props.backgroundColor : 'white'};
  margin-right: 20px;
  font-size: 14px;
  width: 162px;
  height: 40px;
  border-radius: 4px;
  justify-content: center;
  display: flex;
  align-items: center;
  border: 1px solid ${props => props.borderColor ? props.borderColor : '#EC6409'};
  gap: 10px;
  color: ${props => props.color ? props.color : '#EC6409'};
  float: right;
  cursor: pointer;
`


const FilterContainer = styled.div`
 display: flex;
 flex-direction:row;
 justify-content:${props => props.isAdmin && 'space-around'};
 width: ${props => props.width}; 
`

const TasksContainer = styled.div`
    height: calc(${props => props.height})
`
export default function TasksList({
    priorities,
    hasDocument,
    enableDelete = false,
    statuses,
    generatePdf,
    deleteTask,
    timeLine = '',
    disableSaving = false,
    types = [],
    workerData = [],
    jobBooker = false,
    adminTableData = [],
    setData,
    setStatusData,
    setDepartmentsData,
    keys = [],
    selectCar = false,
    openSelectCar,
    setShowCreateNew,
    isAdmin = false,
    vehicle,
    sendingEmail,
    tasks,
    saveCosts,
    selectDate,
    showChecklist, date,
    searchTasks = false,
    showVehicle = true,
    customFilter = false,
    assignees = [],
    departments = [],
    complete,
    regNos = [],
    showDateFilter = true,
    showCarFilter,
    makeValue = null,
    modelValue = null,
    selectMake,
    selectModel,
    selectPriority,
    selectDepartment,
    priority,
    makes = [],
    models = [],
    showCreateNew,
    valueKey,
    labelKey,
    setSearchByName,
    selectDriver,
    submit,
    drivers = [],
    taskType,
    selectTaskType,
    initialStatus,
    changeStatus,
    canWorkOn = true,
    activeDepartment = null
}
) {
    const [selectedDay, setSelectedDay] = useState(null);
    const history = useHistory();
    const [showModal, setShowModal] = useState(false)
    const [previewImage, setPreviewImage] = useState({})
    const [loading, setLoading] = useState(true);

    const [taskStatus, setTaskStatus] = useState(null);
    const [taskUpdatingStatus, setTaskUpdatingStatus] = useState(null);
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalSubTitle] = useState(null);
    const [disabled, setDisabled] = useState(true);
    const [statusArray] = useState([
        {
            value: 'to_do',
            text: 'To do'
        },
        {
            value: 'in_progress',
            text: 'In progress'
        },
        {
            value: 'in_review',
            text: 'In review'
        },
        {
            value: 'completed',
            text: 'Completed'
        }
    ])
    useEffect(() => {
        groupTasksByStatus(tasks)
    }, [tasks])

    const groupTasksByStatus = (tasks) => {
        let tempData = { columns: [] }
        statuses.forEach((item, index) => {
            tempData.columns.push({
                id: index,
                title: item.text,
                value: item.value,
                cards: tasks.filter(task => task.status === item.value)
            })
        })
        setLoading(false)
    }
    const showImage = (position) => {
        setPreviewImage(tasks[position.index]?.documents[position?.image])
        setShowModal(true)
    }
    const renderCustomInput = ({ ref }) => (
        <CalendarInput
            readOnly
            ref={ref} // necessary
            placeholder="Select date"
            value={selectedDay ? moment({ ...selectedDay, month: selectedDay.month - 1, }).format('DD/MMM/YYYY') : ''}
        />
    )
    const createTask = () => {
        setShowCreateNew(true)
    }

    const searchByTaskName = (e) => {
        setSearchByName(e.target.value.toLowerCase())
    }
    const updateCosts = (data) => {
        setData(data)
    }
    const updateStatuses = (data) => {
        setStatusData(data)
    }
    const updateDepartments = (data) => {
        setDepartmentsData(data)
    }


    useEffect(() => {
        setTaskStatus(initialStatus)
        setDisabled(false)
        if (initialStatus !== null) {
            // setShowInfo(true)
        }
    }, [initialStatus])
    const updateWorkStatus = (status) => {
        setTaskUpdatingStatus(status)
        setDisabled(true)
        changeStatus(status)
    }
    const closeModal = () => {
        setTaskUpdatingStatus('')
        setDisabled(false)
        toggleModal();
    }
    const toggleModal = () => setModal(!modal);

    const pauseTask = (status) => {
        setTaskUpdatingStatus(status)
        setDisabled(true)
        setTaskStatus(status)
        changeStatus(status)
    }
    const checkTaskStatus = () => {
        if (taskStatus === 'pause') {
            return (
                <ButtonContainer disabled={!disabled} onClick={() => updateWorkStatus('start')}>
                    <i className="fa fa-play" aria-hidden="true" />
                    Restart
                </ButtonContainer>
            )
        } else {
            return (
                <ButtonContainer disabled={!disabled} onClick={() => pauseTask('pause')}>
                    <i className="fa fa-pause" aria-hidden="true" />
                    Pause
                </ButtonContainer>
            )
        }

    }
    const updateWithModal = (status) => {
        setTaskUpdatingStatus(status)
        if (status === 'start') {
            setModalTitle('You are going to start working on a vehicle')
            //     it means that task status will be changed to {statusInText('In progress', 'in_progress')}</div>)

        } else if (status === 'end') {
            setModalTitle('You are going to finish working on a vehicle')
        }
        toggleModal()
    }
    const confirm = () => {
        setDisabled(true)
        changeStatus(taskUpdatingStatus)
        toggleModal()
    }
    const externalCloseBtn = <button className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => setShowModal(false)}>&times;</button>;

    //
    return (
        !loading &&
        <>

            <Modal className={'no-scroll'} centered={true} isOpen={showModal}
                external={externalCloseBtn}
                toggle={() => {
                    setShowModal(!showModal)
                }}>
                <img style={{ width: '100%', height: '100%' }} src={previewImage.url} alt="" />
            </Modal>
            <Modal
                isOpen={modal}
                toggle={toggleModal}
            >
                <ModalHeader toggle={toggleModal}>
                    <Text>{modalTitle}</Text>
                </ModalHeader>
                <ModalBodyContainer>
                    <Text size='14px'>{modalSubTitle}</Text>
                    <ReportContainer>
                        <Button outline={true} className="w-100"
                            style={{ backgroundColor: "white", color: '#ec6409', border: '1px solid #ec6409' }}
                            type="button" onClick={() => {
                                closeModal()

                            }}>Cancel</Button>
                        <Button className="w-100" style={{ backgroundColor: "#ec6409", color: 'white', border: 'none' }}
                            type="button" onClick={() => {
                                confirm()
                            }}>Confirm</Button>
                    </ReportContainer>
                </ModalBodyContainer>

            </Modal>
            <div style={{ backgroundColor: 'white', minHeight: 'calc(100vh - 128px)' }} className='pd-20'>
                {jobBooker ? <FilterComponent models={models} selectDate={selectDate} selectMake={selectMake}
                    selectDriver={selectDriver}
                    drivers={drivers}
                    types={types}
                    taskType={taskType}
                    selectTaskType={selectTaskType}
                    showCreateNew={showCreateNew}
                    selectModel={selectModel} makes={makes} loading={loading} /> : <>
                    {
                        showVehicle && <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: "wrap" }}>
                            <ReportContainer marginBottom column>
                                <img width={'219px'} height={'165px'}
                                    src={vehicle?.image}
                                    alt="" />
                                <TaskDetailsContainer>
                                    <TextContainer>
                                        <Text>No.</Text>
                                        <img height='25px' width='25px'
                                            src={require(`assets/images/taskEdit/road_grey.png`)}
                                            alt="" />
                                        <img height='25px' width='25px'
                                            src={require(`assets/images/vehicle_color.svg`)}
                                            alt="" />
                                        <img height='25px' width='25px'
                                             src={require(`assets/images/taskEdit/date_grey.png`)}
                                             alt="" />
                                    </TextContainer>
                                    <TextContainer>
                                        <Text bold>Reg. Num.:</Text>
                                        <Text bold>Mileage:</Text>
                                        <Text bold>Colour:</Text>
                                        <Text bold>Created Date:</Text>
                                    </TextContainer>
                                    <TextContainer>
                                        <Text>{vehicle?.stock_no}</Text>
                                        <Text>{vehicle?.mileage}</Text>
                                        <Text>{vehicle?.colour}</Text>
                                        <Text>{formatDateShortGB(date)}</Text>
                                    </TextContainer>

                                </TaskDetailsContainer>
                                {!isAdmin &&
                                    <TimeStartStopContainer>
                                        <h2>Vehicle Workflow</h2>
                                        {!canWorkOn ? 
                                            <Text size='12px'>Currently in {activeDepartment ? activeDepartment.name: "other department"}</Text> :
                                            <>
                                                {taskStatus !== 'end' && <ReportContainer>
                                                    <img height='16px' width='16px' src={require(`assets/images/info_outline.png`)}
                                                        alt="" />
                                                    {taskStatus === 'finished' ?
                                                        <Text size='12px'>Please press the button to start working</Text> :
                                                        <Text size='12px'><b>Pause</b> for any breaks and <b>Finish</b> when the vehicle is
                                                            complete and ready for review.</Text>
                                                    }
                                                </ReportContainer>}
                                                {taskStatus === 'end' ? <span>The Vehicle workflow was end</span> : taskStatus === null ?
                                                    <ButtonContainer disabled={!disabled} onClick={() => updateWithModal('start')}>
                                                        <i className="fa fa-play" aria-hidden="true" />
                                                        Start working
                                                    </ButtonContainer> :
                                                    <ReportContainer column>
                                                        <ButtonContainer disabled={!disabled} onClick={() => updateWithModal('end')}>
                                                            <i className="fa fa-stop" aria-hidden="true" />
                                                            Finish
                                                        </ButtonContainer>
                                                        {
                                                            checkTaskStatus()
                                                        }
                                                    </ReportContainer>
                                                }
                                            </>
                                        }
                                </TimeStartStopContainer>
                                }
                            </ReportContainer>
                            {isAdmin && <TaskDetailsContainer>
                                <DButton
                                    color={hasDocument ? 'grey' : null}
                                    borderColor={hasDocument ? 'grey' : null}
                                    onClick={() => !sendingEmail && generatePdf()}>
                                    {sendingEmail ?
                                        <ClipLoader
                                            size={28}
                                            color={`#3de032`}
                                            loading={sendingEmail}
                                        /> : hasDocument ? "PDF already generated" : "Generate Pdf"}

                                </DButton>
                                <ButtonContainer onClick={() => selectCar ? openSelectCar(true) : createTask()}>
                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                    New Task
                                </ButtonContainer>
                                <ButtonContainer reverse onClick={() => showChecklist(true)}>
                                    <IcoMoon
                                        iconSet={IconSet}
                                        icon={'sticky_note_2'}
                                        size={25}
                                    />
                                    <span>PDI Checklist</span>
                                </ButtonContainer>
                            </TaskDetailsContainer>}
                        </div>
                    }

                    {!isAdmin && <FilterContainer>


                        <div className={'mr-3 w-25'}>
                            <CustomSearch
                                onChange={searchByTaskName}
                                placeholder={'Search'}
                            />
                        </div>
                        {
                            !searchTasks && departments?.length && isAdmin ?
                                <Col md={2} className='align-self-center'>
                                    <SelectWithoutInput
                                        options={departments}
                                        label={'Department'}
                                        onSelect={selectDepartment}
                                        valueKey={`value`}
                                        labelKey={`text`}
                                        defaultOption={true}
                                        imageLink={'group-p.png'}
                                    />
                                </Col> : <></>
                        }
                        <FilterContainer width={showCarFilter ? '55%' : customFilter ? '55%' : '40%'} isAdmin={true}>

                            {
                                !searchTasks ? <>

                                    {showCarFilter &&
                                        <>
                                            <div className='align-self-center'>
                                                <SelectWithoutInput
                                                    label={makeValue ? makeValue : `Make`}
                                                    onSelect={e => {
                                                        selectMake(e)
                                                    }}
                                                    options={makes}
                                                    defaultOption={true}
                                                    valueKey={valueKey}
                                                    labelKey={labelKey}
                                                    imageLink={'directions_car_grey.png'}
                                                />
                                            </div>
                                            <div className='align-self-center'>
                                                <SelectWithoutInput
                                                    label={modelValue ? modelValue : `Model`}
                                                    onSelect={e => {
                                                        selectModel(e)
                                                    }}
                                                    options={models}
                                                    defaultOption={true}
                                                    valueKey={valueKey}
                                                    labelKey={labelKey}
                                                    imageLink={'loyalty.png'}
                                                />
                                            </div>
                                        </>
                                    }
                                    <div className='align-self-center'>
                                        <SelectWithoutInput
                                            label={priority ? priority : `Priority`}
                                            options={priorities}
                                            defaultOption={true}
                                            onSelect={e => {
                                                selectPriority(e)
                                            }}
                                            valueKey={`value`}
                                            labelKey={`label`}
                                            imageLink={'label_important_outline.png'}
                                        />
                                    </div>
                                </> :
                                    <>  {showDateFilter &&
                                        <Col className='align-self-center' md={2}>
                                            <DatePicker
                                                value={selectedDay}
                                                onChange={(e) => {
                                                    selectDate(moment({ ...e, month: e.month - 1, }).format('YYYY-MM-DD'))
                                                    setSelectedDay(e)
                                                }}
                                                renderInput={renderCustomInput}
                                                shouldHighlightWeekends
                                            />
                                        </Col>
                                    }
                                    </>
                            }
                            {
                                customFilter &&
                                <div className='align-self-center'>
                                    <CustomFilter
                                        priorities={priorities}
                                        assignees={assignees}
                                        departments={departments}
                                        regNos={regNos}
                                        onChange={submit}
                                    />
                                </div>
                            }

                        </FilterContainer>
                    </FilterContainer>}
                </>}
                {isAdmin ?
                    <>
                        <DynamicTable
                            statuses={statusArray}
                            enableDelete={enableDelete}
                            deleteTask={deleteTask}
                            selectOptionStatus={updateStatuses}
                            selectOptionDepartment={updateDepartments}
                            showSelectForStatus
                            showSelectForDepartments
                            departments={departments}
                            goToDetails={(id) => { history.push(`/admin/task-details?id=${id}`) }}
                            updateCosts={updateCosts}
                            disableSaving
                            tableData={adminTableData}
                            showAnyFilter={false}
                            keys={keys} />

                        <Row className={'justify-content-between p-3 pt-0'}>
                            {adminTableData.length ? <>
                                <h3>
                                    {adminTableData.length} Total Tasks
                                </h3>
                                <h3>
                                    Total cost of jobs {' '} &#163; {' '}{
                                        propertiesSumInArray(adminTableData, 'price')?.toFixed(2) || ''

                                    }
                                </h3>
                                <h3>Total time on jobs {' '}  {timeLine}</h3>
                            </> : null}
                            <Button disabled={disableSaving} onClick={saveCosts} color={'success'}>
                                Save
                            </Button>
                        </Row>
                    </>
                    :
                    <TasksContainer className={'mt-3'} height={tasks.length > 0 ? '' : '100vh - 222px'}>
                        {/*<ControlledBoard/>*/}
                        <CustomTable complete={complete} canWorkOn={canWorkOn} showImage={showImage} keys={keys} tableData={workerData} />
                    </TasksContainer>
                }

            </div>
        </>
    )
}