import FleetPdfImages from "@pages/fleet/FleetPdfImages";
import FleetPdfRedirected from "@pages/fleet/FleetPdfRedirected";
const fleetRedirectRoutes = [
    {
        path: "/fleet-pdf/:id",
        component: FleetPdfImages,
    },
    {
        path: "/fleet-pdf/redirected/:id",
        component: FleetPdfRedirected,
    },
]

export default fleetRedirectRoutes;