import styled from "styled-components";

const Card = styled.div`
 box-sizing: border-box;
 padding: 15px;
 width: 238px;
 height: 140px;
 background: #FFFFFF;
 border: 1px solid #F8F8F9;
 box-shadow: 0px 8px 36px rgba(112, 144, 176, 0.08);
 border-radius: 10px;
`
const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 30px 30px 30px 0px;
  flex-direction: row;
  justify-content: start;
`
const TitleContainer = styled.div`
  display: flex;
  margin-bottom:  ${props => props.bottom ? props.bottom : '10px'}; 
  width:  ${props => props.width ? props.width : ''}; 
  flex-direction: row;
  gap: 10px;
  justify-content: center;
`
const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: ${props => props.position ? props.position : 'center'};
    width: ${props => props.width};
    gap: ${props => props.gap};
`
const Circle = styled.div`
    width: 13px;
    height: 13px;
    background-color: ${props => props.color ? props.color : '#67676E'}; 
    border-radius:50%;
    box-shadow: 0 0 0 3px transparent;
`
const Text = styled.span`
 font-weight:${props => props.wide ? props.wide : '500'};
 font-size: ${props => props.size ? props.size : '16px'};
 width: ${props => props.width};
 line-height: ${props => props.withoutHeight ? '' : '100%'};
 height: ${props => props.withoutHeight ? '' : '24px'};
`
const ButtonContainer = styled.div`
  background-color: #EC6409;
  margin-right: 20px;
  width: ${props => props.width ? props.width : '140px'}
  height: 40px;
  display:flex;
  align-items: center;
  padding: 10px 20px;
  gap: 10px;
  border-radius: 3px;
  color: white;
  float: right;
  cursor: pointer;
`
const RadioButtonWrapper = styled.div`
  border: 1px solid #e6e6e7;
  position: relative;
  border-radius: 50%;
  width: 20px;
  height: 20px;
`
const RadioButton = styled.div`
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background-color: ${props => props.selected && '#ec6409'}
`
const RadioTextContainer = styled.div`
 display: flex;
 gap: 20px;
 justify-content: flex-start;
`
export {
    Text,
    RadioTextContainer,
    RadioButton,
    ButtonContainer,
    RadioButtonWrapper,
    Circle,
    ContentContainer,
    TitleContainer,
    CardContainer,
    Card,
}