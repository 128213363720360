import * as actionTypes from 'redux/action-types.js';

const login = (email, password) => {
  return {
    type: actionTypes.LOGIN,
    email,
    password
  }
}

const getProfileData = (token) => {
  return {
    type: actionTypes.GET_PROFILE_DATA,
    token
  }
}

const getNotifications = (token) => {
  return {
    type: actionTypes.GET_NOTIFICATIONS,
    token
  }
}

const getModules = (token) => {
  return {
    type: actionTypes.GET_MODULES,
    token
  }
}

const emptyState = () => {
  return {
    type: actionTypes.EMPTY_STATE
  }
}

const setCategories = (data) => {
  return {
    type: actionTypes.SET_CATEGORIES,
    data
  }
}
const setMenuName = (data) => {
  return {
    type: actionTypes.SET_IS_SUBMENU_ACTIVE,
    data
  }
}
const setMenusList = (data) => {
  return {
    type: actionTypes.SET_MENUS_LIST,
    data
  }
}

const setShowLoader = (data) => {
  return {
    type: actionTypes.SET_SHOW_LOADER,
    data
  }
}

const setActiveMenu = (data) => {
  return {
    type: actionTypes.SET_ACTIVE_MENU,
    data
  }
}

export default {
  login,
  getProfileData,
  getNotifications,
  emptyState,
  getModules,
  setCategories,
  setMenuName,
  setMenusList,
  setActiveMenu,
  setShowLoader,
};