import React, { useState } from 'react';

import InputCustom from 'views/pages/components/InputCustom';
import {
    InputKeySelect
} from 'views/pages/components/InputSelect';

import {
    Card,
    CardHeader,
    CardBody,
    Col,
    Button,
} from 'reactstrap';
import InputToggle from 'views/pages/components/InputToggle';

export default function FieldsForm({ fieldTypes, documentFields, addDocumentFields, handleDocumentFieldsChange, removeDocumentField }) {
    const [errors] = useState('');

    const renderType = (item, index) => {
        if (!item.field_type) return null;

        let type = item.field_type;
        switch (type) {
            case "3":
                return (<div className='row'>
                    <div className='col-12 col-md-6 col-sm-6'>
                        <InputCustom
                            type={`text`}
                            label={`Width`}
                            placeholder={`Width`}
                            value={item.options?.width ?? ''}
                            onChange={(e) => {
                                handleDocumentFieldsChange('width', e.target.value, index, item, 'options');
                            }}
                        />
                    </div>
                    <div className='col-12 col-md-6 col-sm-6'>
                        <InputCustom
                            type={`text`}
                            label={`Height`}
                            placeholder={`Height`}
                            value={item.options?.height ?? ''}
                            onChange={(e) => {
                                handleDocumentFieldsChange('height', e.target.value, index, item, 'options');
                            }}
                        />
                    </div>
                </div>);
            case "0":
                return (<div className='row'>
                    <div className='col-12'>
                        <InputCustom
                            type={`text`}
                            label={`Comma separated options`}
                            placeholder={`Options`}
                            value={item.options?.options ?? ''}
                            onChange={(e) => {
                                handleDocumentFieldsChange('options', e.target.value, index, item, 'options');
                            }}
                        />
                    </div>
                </div>)
            default:
                return null;;
        }
    }

    return (
        <Card>
            <CardHeader>
                <p className="mb-0">Custom fields to be filled by the customer using the local signing application</p>
            </CardHeader>
            <CardBody>
                {documentFields.length === 0 ?
                    <Col xs={12} className="text-right">
                        <Button className="w-100 main-button" onClick={() => addDocumentFields()}>+ Add document fields</Button>
                    </Col>
                    : null}
                {documentFields.length > 0 ? documentFields.map((item, index) => {
                    return (<div className="row" key={index}>
                        <div className="col-12 col-md-9">
                            <div className="row">
                                <div className='col-12'>
                                    <InputCustom
                                        type={`text`}
                                        label={`Frontend Name`}
                                        placeholder={`Frontend Name`}
                                        value={item.frontend_name ?? ""}
                                        onChange={(e) => {
                                            handleDocumentFieldsChange('frontend_name', e.target.value, index, item);
                                        }}
                                    />
                                </div>
                                <div className='col-12 col-md-6 col-sm-6'>
                                    <InputCustom
                                        type={`text`}
                                        column={true}
                                        newLabel={`Document placeholder`}
                                        placeholder={`Identifier document placeholder`}
                                        value={item.unique_identifier_on_doc}
                                        onChange={(e) => {
                                            handleDocumentFieldsChange('unique_identifier_on_doc', e.target.value, index, item);
                                        }}
                                    />
                                </div>
                                <div className='col-12 col-md-4 col-sm-6'>
                                    <InputKeySelect
                                        options={fieldTypes}
                                        value={item.field_type ?? ''}
                                        valueKey={"field_type"}
                                        label={"Type"}
                                        labelKey={"field_type"}
                                        onChange={(e) => {
                                            handleDocumentFieldsChange('field_type', e.target.value, index, item);
                                        }}
                                        defaultOption={true}
                                    />
                                </div>
                                <div className='col-12 col-md-2 col-sm-6'>
                                    <InputToggle
                                        label={`Is required`}
                                        checked={item && item.is_required ? true : false}
                                        onChange={e => {
                                            handleDocumentFieldsChange('is_required', e.target.value === 'on' ? 1 : 0, index, item);
                                        }}
                                        invalid={errors && errors.is_active ? true : false}
                                        errorMessage={errors.is_active}
                                    />
                                </div>
                                <div className='col-12 mt-3'>
                                    {renderType(item, index)}
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-3 col-sm-6'>
                            <div className='d-flex align-items-end h-100'>
                                <Button
                                    className="new-event--add"
                                    color="primary"
                                    type="button"
                                    style={{
                                        'marginBottom': '1.5rem'
                                    }}
                                    onClick={() => {
                                        removeDocumentField(index)
                                    }}
                                >
                                    -
                                </Button>

                                {index === documentFields.length - 1 ? (
                                    <Button
                                        className="new-event--add"
                                        color="primary"
                                        type="button"
                                        style={{
                                            'marginBottom': '1.5rem'
                                        }}
                                        onClick={() => addDocumentFields({})}
                                    >
                                        +
                                    </Button>

                                ) : null}
                            </div>
                        </div>
                    </div>
                    )
                }) : null}
            </CardBody>
        </Card>

    )
}