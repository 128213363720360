import React, {useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
import NotificationAlert from "react-notification-alert";
import * as actions from '@redux/Payments/actions';
import CardsHeader from 'layouts/Headers/CardsHeader.js';
import useNotify from 'hooks/notify';

import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody, Modal, Button, Input
} from "reactstrap";
import {
    override, Text,
    UploadButtonContainer
} from "@pages/reusable-components/styled-components";
import CustomTabs from "@pages/components/CustomTabs";
import CustomTable from "@pages/components/Table/CustomTable";
import {ClipLoader} from "react-spinners";
import usePrevious from "../../../hooks/useprevious";
import moment from "moment";
import AddBadDebt from "@pages/components/payment/AddBadDebt";
import {formatDate, formatDateShortGB} from "../../../constants";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";
import DatePicker from "react-datepicker";
import AddPaymentPlan from "@pages/components/payment/AddPaymentPlan";
import SendForm from "@pages/customers/customer/emails/SendForm";
import {InputArraySelect} from "@pages/components/InputSelect";
import Select from "react-select";


export default function DebtCollection() {
    const dispatch = useDispatch();
    const {ref, notify} = useNotify();
    const history = useHistory();
    const {
        addedPaymentSuccess,
        addedPaymentError,
        getPaymentsListSuccess,
        getPaymentsListError,
        updatePaymentNotesSuccess,
        updatePaymentNotesError,
        updatePaymentSuccess,
        updatePaymentError,
        payments,
        deletePaymentSuccess,
        deletePaymentError,
        getPaymentNotesSuccess,
        getPaymentNotesError,
        paymentNotes,
        updatePartialPaymentSuccess,
        updatePartialPaymentError,
        approvementUpdatedSuccess,
        approvementUpdatedError,
    } = useSelector((state) => state.paymentState, shallowEqual);

    const emailTemplateState = useSelector(
        (state) => state.emailTemplateState,
        shallowEqual
    );
    const sendEmailPrev = usePrevious(emailTemplateState.sendEmailSuccess);

    const addedPaymentSuccessPrev = usePrevious(addedPaymentSuccess)
    const addedPaymentErrorPrev = usePrevious(addedPaymentError)

    const getPaymentNotesSuccessPrev = usePrevious(getPaymentNotesSuccess)
    const getPaymentNotesErrorPrev = usePrevious(getPaymentNotesError)

    const updatePartialPaymentSuccessPrev = usePrevious(updatePartialPaymentSuccess)
    const updatePartialPaymentErrorPrev = usePrevious(updatePartialPaymentError)

    const approvementUpdatedSuccessPrev = usePrevious(approvementUpdatedSuccess)
    const approvementUpdatedErrorPrev = usePrevious(approvementUpdatedError)

    const getPaymentsListSuccessPrev = usePrevious(getPaymentsListSuccess)
    const getPaymentsListErrorPrev = usePrevious(getPaymentsListError)

    const deletePaymentSuccessPrev = usePrevious(deletePaymentSuccess)
    const deletePaymentErrorPrev = usePrevious(deletePaymentError)

    const updatePaymentNotesSuccessPrev = usePrevious(updatePaymentNotesSuccess)
    const updatePaymentNotesErrorPrev = usePrevious(updatePaymentNotesError)

    const updatePaymentSuccessPrev = usePrevious(updatePaymentSuccess)
    const updatePaymentErrorPrev = usePrevious(updatePaymentError)

    const [loading, setLoading] = useState(true);
    const [showAddPaymentPlan, setShowAddPaymentPlan] = useState(false)
    const [modalLoading, setModalLoading] = useState(false);
    const [filter, setFilter] = useState({
        is_debt_collected: 1,
        status: '',
    });

    const [companies, setCompanies] = useState([
        {
            value: 'RedWood Collections',
            label: 'RedWood Collections'
        },
        {
            value: 'First Legal Services',
            label: 'First Legal Services'
        }
    ]);
    const [selectedAction, setSelectedAction] = useState('');


    const [showAddPayment, setShowAddPayment] = useState(false)
    const [tableData, setTableData] = useState([]);

    const [isStatusModalOpened, setIsStatusModalOpened] = useState(false);
    const [debtCollectionModal, setDebtCollectionModal] = useState(false);
    const [isNotesModalOpened, setIsNotesModalOpened] = useState(false);
    const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
    const [openSendMailModal, setOpenSendMailModal] = useState(false);
    const [selectedStatusData, setSelectedStatusData] = useState({})
    const [defaultData, setDefaultData] = useState(null)
    const [selectedCustomerMailData, setSelectedCustomerMailData] = useState({})
    const [selectedDebtCollectionData, setSelectedDebtCollectionData] = useState({})
    const [pagination, setPagination] = useState(null)
    const [partPaymentData, setPartPaymentData] = useState({})
    const [isPayModalOpened, setIsPayModalOpened] = useState(false);
    const [newNote, setNewNote] = useState('')
    const [selectedNotesData, setSelectedNotesData] = useState([])
    const [deletedNotes, setDeletedNotes] = useState([])
    const [selectedPaymentId, setSelectedPaymentId] = useState(null)
    const [deleteId, setDeleteId] = useState(null)
    const [activeTab, setActiveTab] = useState('Outstanding Bad Debt');
    const [requestStatus, setRequestStatus] = useState('');
    const [selectedDay, setSelectedDay] = useState(null);
    const tableKeys = useMemo(() => {
        let tableKeys = [
            {key: 'customer_name', label: 'Customer name'},

        ];

        if (activeTab === 'Outstanding Bad Debt') {
            if (requestStatus) {
                setRequestStatus('')
            }
            tableKeys.push(
                {key: 'amount', label: 'Amount Due'},
                {key: 'due_date', label: 'Due date', sort: true},
                {key: 'openMailModal', label: 'Send email one', slug: '1st-reminder-payment-overdue',className: 'blue'},
                {key: 'openMailModal', label: 'Send email two', slug: '2nd-reminder-payment-overdue',className: 'blue'},
                {key: 'openMailModal', label: 'Send email three', slug: 'final-payment-notice-of-default',className: 'blue'},
                {key: 'action', label: 'Sent to debt collection', className: 'green'},
                {key: 'delete', label: 'Action', className: 'red'},
            )
        } else if (activeTab === 'Sent to debt collection') {
            setRequestStatus('sent_to_debt')
            tableKeys.push(
                {key: 'due_date', label: 'Due date', sort: true},
                {key: 'amount', label: 'Amount Due'},
                {key: 'openExternal', label: 'Payment Plan', className: 'green'},
                {key: 'sendToCourtAction', label: 'Send To Court', className: 'green'},
            )
        } else if (activeTab === 'Repayment with Redwood') {
            setRequestStatus('repayment_redwood')
            tableKeys.push(
                {key: 'amount', label: 'Amount Due'},
                {key: 'due_date', label: 'Due date', sort: true},
                {key: 'partial_paid', label: 'Partial Paid'},
                {key: 'number_of_payments', label: 'No of reoccurring payments'},
                {key: 'repayment_amount', label: 'Payment amount'},
                {key: 'action', label: 'Paid', className: 'green'},
                {key: 'payAction', label: 'Part Paid', className: 'blue'},
                {key: 'openModal', label: 'View notes'},
                {key: 'delete', label: 'Action', className: 'red'},
            )
        } else if (activeTab === 'Repayment with First Legal') {
            setRequestStatus('repayment')
            tableKeys.push(
                {key: 'amount', label: 'Amount Due'},
                {key: 'due_date', label: 'Due date', sort: true},
                {key: 'partial_paid', label: 'Partial Paid'},
                {key: 'number_of_payments', label: 'No of reoccurring payments'},
                {key: 'repayment_amount', label: 'Payment amount'},
                {key: 'action', label: 'Paid', className: 'green'},
                {key: 'payAction', label: 'Part Paid', className: 'blue'},
                {key: 'openModal', label: 'View notes'},
                {key: 'delete', label: 'Action', className: 'red'},
            )
        } else if (activeTab === 'Apply for CCJ') {
            setRequestStatus('apply_for_ccj')
            tableKeys = [
                {key: 'customer_name', label: 'Customer name'},
            ];
            tableKeys.push(
                {key: 'apply_for_ccj_date', label: 'Apply for CCJ', sort: true},
                {key: 'amount', label: 'Amount Due'},
                {key: 'ccj_approvement_date', label: 'Date CCJ Approved'},
                {key: 'date_sent_to_bailiffs', label: 'Date Sent To Bailiffs'},
                {key: 'delete', label: 'Action', className: 'red'},
            )
        } else if (activeTab === 'Sent to Court') {
            setRequestStatus('send_to_court')
            tableKeys.push(
                {key: 'amount', label: 'Amount Due'},
                {key: 'send_to_court_date', label: 'Sent to Court date', sort: true},
                {key: 'sendToCCJAction', label: 'Apply To CCJ', className: 'green'},
            )
        }


        return tableKeys;
    }, [activeTab])

    const [tabs] = useState([
        'Outstanding Bad Debt',
        'Sent to debt collection',
        'Sent to Court',
        'Apply for CCJ',
    ]);

    useEffect(() => {
        if (
            emailTemplateState.sendEmailSuccess !== sendEmailPrev &&
            emailTemplateState.sendEmailSuccess === true &&
            sendEmailPrev !== undefined
        ) {
            notify('Success', 'Email sent successfully')
        }
    }, [emailTemplateState.sendEmailSuccess]);

    const checkDisabledForRepayment = () => {
        if (!selectedStatusData.number_of_payments || !selectedStatusData.repayment_amount || !selectedStatusData.hasOwnProperty(`${selectedStatusData.status}_date`)) {
            return true
        } else return false
    }
    const toggleStatusModal = () => {
        setIsStatusModalOpened(!isStatusModalOpened)
    }
    const toggleDeleteModal = () => {
        setIsDeleteModalOpened(!isDeleteModalOpened)
    }
    const toggleOpenMailModal = () => {
        setOpenSendMailModal(!openSendMailModal)
    }
    const togglePaymentPlan = () => {
        setShowAddPaymentPlan(!showAddPaymentPlan)
    }
    const toggleNotesModal = () => {
        setIsNotesModalOpened(!isNotesModalOpened)
    }
    const toggleDebtCollectionModal = () => {
        setDebtCollectionModal(!debtCollectionModal)
    }
    const onNotesModalClosed = () => {
        setSelectedNotesData([])
        setDeletedNotes([])
        setSelectedPaymentId(null)
    }

    const updateNotes = () => {
        let data = {
            notes: selectedNotesData.map(item => item.note),
            deleted: deletedNotes,
            payment_id: selectedPaymentId
        }
        dispatch({
            type: actions.UPDATE_PAYMENT_NOTES_REQUEST,
            data,
        });
        toggleNotesModal()
    }
    const removeNote = (index) => {
        let tempData = [...selectedNotesData]
        let dNote = tempData.splice(index, 1);
        if (dNote[0].hasOwnProperty('invoice_number')) {
            setDeletedNotes([...deletedNotes, dNote[0].note.id])
        }
        setSelectedNotesData(tempData)
    }
    const checkDelete = (id) => {
        setDeleteId(id)
        toggleDeleteModal()
    }
    const deletePayment = () => {
        let data = {
            id: deleteId
        }
        dispatch({
            type: actions.DELETE_PAYMENT_REQUEST,
            data,
        });
    }

    const sendToDebtCollection = (data) => {
        let tempData = {}
        tempData['id'] = data.id
        tempData['status'] = 'sent_to_debt'
        tempData['sent_to_debt_date'] = ''
        tempData['company'] = ''
        setSelectedDebtCollectionData(tempData)
        toggleDebtCollectionModal()
    }
    const setStatus = (status) => {
        let tempData = {}
        tempData['id'] = status.id
        if (status.status === 'Sent to debt collection') {
            tempData['status'] = 'sent_to_debt'
        } else if (status.status === 'Repayment with First Legal') {
            let tempItem = tableData.find(item => item.id === status.id)
            tempData['number_of_payments'] = tempItem.number_of_payments
            tempData['repayment_amount'] = tempItem.repayment_amount
            tempData['status'] = 'repayment'
        } else if (status.status === 'Repayment with Redwood') {
            let tempItem = tableData.find(item => item.id === status.id)
            tempData['number_of_payments'] = tempItem.number_of_payments
            tempData['repayment_amount'] = tempItem.repayment_amount
            tempData['status'] = 'repayment_redwood'
        } else if (status.status === 'send_to_court' || status.status === 'apply_for_ccj') {
            tempData['status'] = status.status
        } else {
            tempData['status'] = 'paid'
        }
        setSelectedStatusData(tempData)
        toggleStatusModal()
    }
    const payAction = (data) => {
        setPartPaymentData(data)
        togglePayModal()
    }

    const paginate = (page) => {
        fetchDetails(requestStatus, null, page)
    }
    const togglePayModal = () => {
        setIsPayModalOpened(!isPayModalOpened)
    }
    const updatePartPayment = () => {
        dispatch({
            type: actions.UPDATE_PARTIAL_PAYMENT_REQUEST,
            data: partPaymentData,
        });
        togglePayModal()
    }
    const sortData = (sort) => {
        let tempFilter = {...filter}
        tempFilter.order = sort.value
        setFilter(tempFilter)
        fetchDetails(requestStatus, tempFilter);
    }
    const openNotesModal = (itemIndex) => {
        dispatch({
            type: actions.GET_PAYMENTS_NOTES_REQUEST,
            data: tableData[itemIndex].customer_id,
        });
        toggleNotesModal()
        setModalLoading(true)
        setSelectedPaymentId(tableData[itemIndex].id)
    }

     const openExternal = (data) => {
        let tempData = tableData[data.index];
        setDefaultData({
            customer: { value:tempData.customer_id, label: tempData.customer_name},
            amount: tempData.amount,
            debt_collection_company: tempData.debt_collection_company,
        })
        togglePaymentPlan()
    }
    const setApprovementDate = (data) => {
        dispatch({
            type: actions.UPDATE_APPRROVEMENT_DATE_REQUEST,
            data: {...data},
        });
    }
    const updateStatus = () => {
        dispatch({
            type: actions.UPDATE_PAYMENT_REQUEST,
            data: {...selectedStatusData, is_debt_collected: 1},
        });
        toggleStatusModal()
    }

    const changeToDebtCollection = () => {
        dispatch({
            type: actions.UPDATE_PAYMENT_REQUEST,
            data: {...selectedDebtCollectionData, is_debt_collected: 1},
        });
        toggleDebtCollectionModal()

    }

    const fetchDetails = (status = null, tempFilter = {}, page = null) => {
        let filter = {...tempFilter}
        filter['is_debt_collected'] = 1
        if (status) {
            filter['status'] = status
        }
        if (page) {
            filter['page'] = page
        }

        dispatch({
            type: actions.GET_PAYMENT_LIST_REQUEST,
            data: filter,
        });
    }

    const openMailModal = (data) => {
        let tempData = tableData[data.index]
        setSelectedCustomerMailData({
            resource_id: tempData.customer_id,
            form: data?.label,
            resource_type: 'Customer',
            to: [tempData.email],
        })
        setOpenSendMailModal(true)
    }

    useEffect(() => {
        setLoading(true);
        fetchDetails(requestStatus);
    }, [requestStatus]);
    useEffect(() => {
        if (addedPaymentSuccess && addedPaymentSuccessPrev === false) {
            window.location.reload();
        }
    }, [addedPaymentSuccess, addedPaymentSuccessPrev])

    useEffect(() => {
        if (deletePaymentSuccess && deletePaymentSuccessPrev === false) {
            let newData = [...tableData]
            newData = newData.filter(item => item.id !== deleteId)
            setTableData(newData)
            setDeleteId(null)
            toggleDeleteModal()
        }
    }, [deletePaymentSuccess])

    useEffect(() => {
        if (deletePaymentError && !deletePaymentErrorPrev) {
            setDeleteId(null)
            toggleDeleteModal()
        }
    }, [deletePaymentError])

    useEffect(() => {
        if (getPaymentNotesSuccess && getPaymentNotesSuccessPrev === false) {
            setModalLoading(false)
            setSelectedNotesData(paymentNotes)
        }
    }, [getPaymentNotesSuccess, paymentNotes])
    useEffect(() => {
        if (getPaymentNotesError && getPaymentNotesErrorPrev === false) {
            toggleNotesModal()
            setModalLoading(false)
        }
    }, [getPaymentNotesError])

    useEffect(() => {
        if (addedPaymentError && !addedPaymentErrorPrev) {
            notify('Error', 'Something went wrong')
            setLoading(false)
        }
    }, [addedPaymentError])

    useEffect(() => {
        if (getPaymentsListSuccess && getPaymentsListSuccessPrev === false) {
            let tempData = payments.data
            setTableData(tempData.data)
            setPagination(tempData.meta)
            setLoading(false)
        }
    }, [getPaymentsListSuccess])

    useEffect(() => {
        if (getPaymentsListError && !getPaymentsListErrorPrev) {
            notify('Error', 'Something went wrong')
            setLoading(false)
        }
    }, [getPaymentsListError])

    useEffect(() => {
        if (updatePaymentNotesSuccess && !updatePaymentNotesSuccessPrev) {
            window.location.reload();
        }
    }, [updatePaymentNotesSuccess])

    useEffect(() => {
        if (updatePaymentNotesError && !updatePaymentNotesErrorPrev) {
            notify('Error', 'Something went wrong')
        }
    }, [updatePaymentNotesError])


    useEffect(() => {
        if (updatePaymentSuccess && !updatePaymentSuccessPrev) {
            setLoading(true)
            fetchDetails(requestStatus);
        }
    }, [updatePaymentSuccess])

    useEffect(() => {
        if (updatePaymentError && !updatePaymentErrorPrev) {
            notify('Error', 'Something went wrong')
        }
    }, [updatePaymentError])

    useEffect(() => {
        if (updatePartialPaymentSuccess && updatePartialPaymentSuccessPrev === false) {
            fetchDetails(requestStatus);
        }
    }, [updatePartialPaymentSuccess])
    useEffect(() => {

        if (updatePartialPaymentError && !updatePartialPaymentErrorPrev) {
            notify('Error', 'Something went wrong')
        }
    }, [updatePartialPaymentError])

    useEffect(() => {
        if (approvementUpdatedSuccess && approvementUpdatedSuccessPrev === false) {
            setLoading(true)
            fetchDetails(requestStatus);
        }
    }, [approvementUpdatedSuccess])
    useEffect(() => {

        if (approvementUpdatedError && !approvementUpdatedErrorPrev) {
            notify('Error', 'Something went wrong')
        }
    }, [approvementUpdatedError])

    const saveData = async (data) => {
        setLoading(true)
        dispatch({
            type: actions.ADD_PAYMENT_REQUEST,
            data: data
        });
        setShowAddPayment(false)
    }
    const addNewNote = () => {
        let tempData = [...selectedNotesData]
        tempData.push({
            note: {
                note: newNote,
                status: 'new'
            }
        })
        setSelectedNotesData(tempData)
        setNewNote('')
    }
    const selectAction = (data) => {
        const updatedSelectedAction = {...selectedAction};
        updatedSelectedAction[data.id] = data.value;
        setSelectedAction(updatedSelectedAction);
    }

    const savePaymentPlan = async (data) => {
        let tempData = {...data}
        tempData.customer_id = defaultData.customer.value
        tempData.amount = defaultData.amount
        tempData.debt_collection_company = defaultData.debt_collection_company
        setLoading(true)
        dispatch({
            type: actions.ADD_PAYMENT_REQUEST,
            data: tempData
        });
        setShowAddPaymentPlan(false)
    }
    return (

        <>
            <Modal
                isOpen={isPayModalOpened}
                toggle={() => togglePayModal()}
                className="disable-scroll modal-dialog-centered modal-md"
            >
                <div className="modal-header">
                    <h4>{`You want to change add partial payment`}</h4>
                </div>
                <div className="modal-body">
                    <div className={'d-flex justify-content-start mb-3'}>
                        <div style={{position: 'absolute', zIndex: 15}}>
                            <Input
                                value={partPaymentData?.amount ? partPaymentData?.amount : ''}
                                placeholder={'Add partial payment'}
                                onChange={e => setPartPaymentData({...partPaymentData, amount: e.target.value})}
                            />
                        </div>
                    </div>

                </div>
                <div className="modal-footer mb-3">
                    <Button
                        className="ml-auto"
                        color="danger"
                        type="button"
                        onClick={() => {
                            togglePayModal()
                            setPartPaymentData({})
                        }}
                    >
                        {`Close`}
                    </Button>
                    <Button
                        className="new-event--add"
                        color="success"
                        type="button"
                        onClick={() => updatePartPayment()}
                    >
                        {`Save`}
                    </Button>
                </div>
            </Modal>
            <Modal
                isOpen={debtCollectionModal}
                toggle={() => toggleDebtCollectionModal()}
                className="disable-scroll modal-dialog-centered modal-md"
            >
                <div className="modal-header">
                    <h3>{`Are you sure ?`}</h3>
                </div>

                <div className="modal-body">
                    <h4>{`You want to change payment status`}</h4>
                    <Col>
                        <Text bold>No of reoccurring payments</Text>
                        <DatePicker
                            value={selectedDebtCollectionData?.sent_to_debt_date ? formatDateShortGB(selectedDebtCollectionData?.sent_to_debt_date) : ''}
                            wrapperClassName={'w-50 pr-3'}
                            onChange={(e) => {
                                setSelectedDebtCollectionData({
                                    ...selectedDebtCollectionData,
                                    'sent_to_debt_date': moment(e).format('YYYY-MM-DD')
                                })
                            }}
                            shouldHighlightWeekends
                        />
                    </Col>
                    <Col>
                        <Text bold>Company</Text>
                        <Select
                            className={'w-50'}
                            getOptionLabel={option => option.label}
                            getOptionValue={option => option.value}
                            options={companies}
                            onChange={(e) =>
                                setSelectedDebtCollectionData({
                                    ...selectedDebtCollectionData,
                                    'company': e.value
                                })
                            }
                        />

                    </Col>

                </div>

                <div className="modal-footer mb-3">
                    <Button
                        className="ml-auto"
                        color="danger"
                        type="button"
                        onClick={() => {
                            toggleDebtCollectionModal()
                        }}
                    >
                        {`Close`}
                    </Button>
                    <Button
                        className="new-event--add"
                        color="success"
                        type="button"
                        onClick={() =>
                            changeToDebtCollection()
                        }
                    >
                        {`Save`}
                    </Button>
                </div>
            </Modal>
            <Modal
                isOpen={isStatusModalOpened}
                toggle={() => toggleStatusModal()}
                className="disable-scroll modal-dialog-centered modal-md"
            >
                <div className="modal-header">
                    <h3>{`Are you sure ?`}</h3>
                </div>
                <div className="modal-body">
                    <h4>{`You want to change payment status`}</h4>
                    <h4>{`Please enter ${selectedStatusData.status} date`}</h4>
                    <Col>
                        {(selectedStatusData.status === 'repayment' || selectedStatusData.status === 'repayment_redwood') && <>
                            <Col lg={12} sm={12}>
                                <Row>
                                    <img height='25px' width='25px'
                                         src={require(`assets/images/loyalty.png`)} alt=""/>
                                    <Col>
                                        <Text bold>No of reoccurring payments</Text>
                                        <Input
                                            value={selectedStatusData.number_of_payments}
                                            type={`number`}
                                            onChange={(e) => setSelectedStatusData({
                                                ...selectedStatusData,
                                                number_of_payments: e.target.value
                                            })}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={12} sm={12}>
                                <Row>
                                    <img height='25px' width='25px'
                                         src={require(`assets/images/loyalty.png`)} alt=""/>
                                    <Col>
                                        <Text bold>Payment Amount</Text>
                                        <Input
                                            value={selectedStatusData.repayment_amount}
                                            type={`number`}
                                            onChange={(e) => setSelectedStatusData({
                                                ...selectedStatusData,
                                                repayment_amount: e.target.value
                                            })}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </>
                        }
                        <Col lg={12}>
                            <Row>
                                <img height='25px' width='25px'
                                     src={require(`assets/images/date_range.png`)} alt=""/>
                                <Col className={'signing'}>
                                    <Text bold>Select Date:</Text>
                                    <Row className={'pl-3'}>
                                        <DatePicker
                                            value={selectedDay ? formatDateShortGB(selectedDay) : ''}
                                            wrapperClassName={'w-100 pr-3'}
                                            onChange={(e) => {
                                                setSelectedDay(e)
                                                setSelectedStatusData({
                                                    ...selectedStatusData,
                                                    [`${selectedStatusData.status}_date`]: moment(e).format('YYYY-MM-DD')
                                                })
                                            }}
                                            shouldHighlightWeekends
                                        />
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Col>


                </div>
                <div className="modal-footer mb-3">
                    <Button
                        className="ml-auto"
                        color="danger"
                        type="button"
                        onClick={() => {
                            setSelectedStatusData({})
                            setNewNote('')
                            setSelectedPaymentId(null)
                            toggleStatusModal()
                        }}
                    >
                        {`Close`}
                    </Button>
                    <Button
                        className="new-event--add"
                        color="success"
                        disabled={(selectedStatusData.status === 'repayment' || selectedStatusData.status === 'repayment_redwood') ? checkDisabledForRepayment() : !selectedStatusData.hasOwnProperty(`${selectedStatusData.status}_date`)}
                        type="button"
                        onClick={() => updateStatus()}
                    >
                        {`Save`}
                    </Button>
                </div>
            </Modal>
            <Modal
                isOpen={isDeleteModalOpened}
                toggle={() => toggleDeleteModal()}
                className="disable-scroll modal-dialog-centered modal-md"
            >
                <div className="modal-header">
                    <h3>{`Are you sure ?`}</h3>
                </div>
                <div className="modal-body">
                    <h4>{`You want to delete payment`}</h4>
                </div>
                <div className="modal-footer mb-3">
                    <Button
                        className="ml-auto"
                        color="danger"
                        type="button"
                        onClick={() => {
                            setDeleteId(null)
                            toggleDeleteModal()
                        }}
                    >
                        {`Close`}
                    </Button>
                    <Button
                        className="new-event--add"
                        color="success"
                        type="button"
                        onClick={() => deletePayment()}
                    >
                        {`Save`}
                    </Button>
                </div>
            </Modal>

            <Modal
                isOpen={isNotesModalOpened}
                onClosed={onNotesModalClosed}
                toggle={() => toggleNotesModal()}
                className="modal-dialog-centered modal-lg"
            >
                <div className="modal-header">
                    <h3>{`Notes for payment`}</h3>
                </div>
                <div className="modal-body">
                    {
                        modalLoading ? <ClipLoader
                                css={override}
                                size={40}
                                color={`#7B61E4`}
                                loading={modalLoading}
                            /> :
                            <Row>
                                <Col lg={4}>
                                    <Col>
                                        <Input
                                            value={newNote}
                                            placeholder={'write a note'}
                                            onChange={e => setNewNote(e.target.value)}
                                        />
                                        <Button className={'mt-2 mb-2'} onClick={addNewNote}>
                                            Add Note
                                        </Button>
                                        <Button
                                            className="ml-auto"
                                            color="danger"
                                            type="button"
                                            onClick={() => {
                                                toggleNotesModal()
                                            }}
                                        >
                                            {`Close`}
                                        </Button>
                                        <Button
                                            className="new-event--add"
                                            color="success"
                                            type="button"
                                            onClick={updateNotes}
                                        >
                                            {`Save`}
                                        </Button>
                                    </Col>
                                </Col>
                                <Col className={'overflow-auto'} lg={8}>
                                    {selectedNotesData.length ?
                                        <table className="table table-bordered">
                                            <thead>
                                            <tr className={'reports-table-header'}>
                                                <th className={'header-text'}>
                                                    Date
                                                </th>
                                                <th className={'header-text'}>
                                                    Note
                                                </th>

                                                <th className={'header-text'}>
                                                    Action
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                selectedNotesData.map((note, index) => {
                                                    return <tr key={index}>
                                                        <td>
                                                            <h4>{note.note.hasOwnProperty('created_at') ? formatDate(note.note?.created_at) : 'Now'}</h4>
                                                        </td>
                                                        <td>
                                                            <h4>{note.note?.note}</h4>
                                                        </td>

                                                        <td>
                                                            <IcoMoon
                                                                className={'cursor-pointer'}
                                                                iconSet={IconSet}
                                                                icon={'delete_outline'}
                                                                size={20}
                                                                onClick={() => removeNote(index)}
                                                            />
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table> : null}
                                </Col>
                            </Row>
                    }
                </div>
                <div className="modal-footer mb-3">
                    <Button
                        className="ml-auto"
                        color="danger"
                        type="button"
                        onClick={() => {
                            toggleNotesModal()
                        }}
                    >
                        {`Close`}
                    </Button>
                    <Button
                        className="new-event--add"
                        color="success"
                        type="button"
                        onClick={updateNotes}
                    >
                        {`Save`}
                    </Button>
                </div>
            </Modal>

            <Modal
                isOpen={openSendMailModal}
                toggle={() => toggleOpenMailModal()}
                className="disable-scroll modal-dialog-centered modal-lg"
            >
                <div className="modal-header">
                    <h3>{`Are you sure ?`}</h3>
                </div>
                <div className="modal-body">
                    <SendForm
                        save={toggleOpenMailModal}
                        sendEmailDataOrigin={selectedCustomerMailData}
                    />
                </div>
                <div className="modal-footer mb-3">
                    <Button
                        className="ml-auto"
                        color="danger"
                        type="button"
                        onClick={() => {
                            setDeleteId(null)
                            toggleOpenMailModal()
                        }}
                    >
                        {`Close`}
                    </Button>

                </div>
            </Modal>
            <Modal
             isOpen={showAddPaymentPlan}
             toggle={togglePaymentPlan}
             className="disable-scroll modal-dialog-centered modal-lg"
            >
                <div className="modal-header">
                    <h3>{`Add payment plan`}</h3>
                </div>
                <div className="modal-body">
                    <AddPaymentPlan
                        location={'external_payment_plan'}
                        create={savePaymentPlan}
                        defaultData={defaultData}
                        setOpen={setShowAddPaymentPlan}
                    />
                </div>

            </Modal>
            <CardsHeader name="Payments Management" parentName="Payments Management" currentName="List"/>
            <div className="rna-wrapper">
                <NotificationAlert ref={ref}/>
            </div>
            {
                showAddPayment ?
                    <AddBadDebt
                        create={saveData}
                        setOpen={setShowAddPayment}
                    /> :
                        <Container className="mt--5 admin-main-body" fluid>
                            <Row>
                                <Col xl="12">
                                    <Card>
                                        <CardHeader className={'border-bottom-0'}>

                                            <CustomTabs fullWidth={true} tabs={tabs} activeTab={activeTab}
                                                        setActiveTab={setActiveTab}/>
                                        </CardHeader>
                                        <CardBody>
                                            {activeTab === 'Outstanding Bad Debt' ?
                                                <Row>
                                                    <Col md={3} lg={8}/>
                                                    <Col md={3} lg={4}>
                                                        <UploadButtonContainer background={'#70ad47'} color={'white'}
                                                                               onClick={() => setShowAddPayment(true)}>
                                                            <i className="fa fa-plus" aria-hidden="true"></i>
                                                            Add bad debt
                                                        </UploadButtonContainer>
                                                    </Col>
                                                </Row>
                                                : null}
                                        </CardBody>
                                        <CardBody>
                                            {
                                                loading ?
                                                    <ClipLoader
                                                        css={override}
                                                        size={40}
                                                        color={`#7B61E4`}
                                                        loading={loading}
                                                    />
                                                    : <CustomTable
                                                        keys={tableKeys}
                                                        multicolor
                                                        customClassName={'table-header'}
                                                        selectDate={setApprovementDate}
                                                        openExternal={openExternal}
                                                        toDetails={sendToDebtCollection}
                                                        openModal={openNotesModal}
                                                        customerDetails={(id) => {
                                                            history.push({
                                                                pathname: `/admin/customer/${id}/edit`,
                                                            })
                                                        }}
                                                        selectedAction={selectedAction}
                                                        selectAction={selectAction}
                                                        sort={sortData}
                                                        deleteRow={checkDelete}
                                                        pagination={pagination}
                                                        openMailModal={openMailModal}
                                                        paginate={paginate}
                                                        payAction={payAction}
                                                        sendToCourtAction={(data) => {
                                                            setStatus({
                                                                id: data?.id,
                                                                status: 'send_to_court'
                                                            })
                                                        }}
                                                        sendToCCJAction={(data) => {
                                                            setStatus({
                                                                id: data?.id,
                                                                status: 'apply_for_ccj'
                                                            })
                                                        }}
                                                        changeGroupStatus={(id) => {
                                                            setStatus({
                                                                id: id,
                                                                status: selectedAction[id]
                                                            })
                                                        }}
                                                        sortValue={filter.order}
                                                        tableData={tableData}
                                                        isPaymentsPage={true} tabName={activeTab}/>
                                            }

                                        </CardBody>

                                    </Card>
                                </Col>
                            </Row>
                        </Container>
            }
        </>
    )
}