const list = (permissions) => {
  return permissions.includes('access-vehicles')
}

const create = (permissions, auth_user_team) => {
  if (!auth_user_team) {
    return false
  }

  return hasPermission(permissions, 'access-create-vehicles')
}

const update = (permissions, auth_user_team, vehicle) => {
  if (auth_user_team) {
    if (!vehicle?.skip && auth_user_team.entityable?.name !== vehicle?.name) {
      return false;
    }
  }

  return hasPermission(permissions, 'access-vehicles')
}

const deletee = (permissions, auth_user_team, vehicle) => {
  if (auth_user_team && auth_user_team.entityable && vehicle.name !== auth_user_team.entityable.name) {
    return false;
  }

  return hasPermission(permissions, 'delete-vehicles')
}

const hasPermission = (permissions, permission) => {
  return permissions.includes(permission)
}

export {
  list,
  create,
  update,
  deletee,
  hasPermission,
};