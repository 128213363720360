import React, {useState} from 'react';
import { FormGroup, Input } from 'reactstrap';
import {isEmailValid} from "../../../constants";
import _uniqueId from 'lodash/uniqueId';
export default function InputCustom({
  type = 'text',
  className,
  required = false,
  checkEmail = false,
  column = false,
  editValue = '',
  placeholder,
  defaultValue,
  value,
  min,
  max,
  rows,
  onChange,
  disabled,
  readOnly,
  invalid,
  errorMessage,
  onKeyPress,
  checked,
  onBlur,
  innerRef,
  onFocus,
  style,
  newLabel,
  label = null,
  setIsNotValid,
  checkValid = true,
  checkZero = false,
  name,
}) {
  const [localErrorMessage, setLocalErrorMessage] = useState(null)
  const [id] = useState(_uniqueId('prefix-'));
  const showIOfValid = (val) => {
    if (checkValid) {
      setIsNotValid(val)
    }
  }
  const validateInput = (e) => {
      onChange(e)
      const val = e.target.value
      if (required) {
        if (!val) {
          setLocalErrorMessage(`${newLabel ?? label} field is required`)
          showIOfValid(true)
          return
        } else if (checkZero && val == 0) {
          setLocalErrorMessage(`${newLabel ?? label} can't be 0`)
          showIOfValid(true)
          return
        } else {
          if (!!editValue) {
            if (editValue === val) {
              showIOfValid(true)
              return
            } else {
              showIOfValid(false)
            }
          }
          showIOfValid(false)
          setLocalErrorMessage(null)
        }
      }

      if (checkEmail) {
          if (isEmailValid(val)) {
            setLocalErrorMessage(null)
            showIOfValid(false)
          } else {
            setLocalErrorMessage(`Email is not valid`)
            showIOfValid(true)
          }
      } else {
        setLocalErrorMessage(null)
      }
  }

  return (
    <FormGroup style={style} className={'d-flex' + (column ? ' flex-column' : ' align-items-center')}>
     <label htmlFor={id} className="form-control-label mr-1 w-50">{newLabel ?? label} {required ? <span className={'required-dot'}>*</span> : ''}</label>
       <div className={'w-100'}>
         <Input
             id={id}
             type={type}
             className={className}
             style={style}
             placeholder={placeholder}
             defaultValue={defaultValue}
             value={value}
             min={min}
             name={name}
             max={max}
             rows={rows}
             disabled={disabled}
             readOnly={readOnly}
             onChange={validateInput}
             invalid={localErrorMessage ? !!localErrorMessage : invalid}
             onKeyPress={onKeyPress}
             checked={checked}
             onBlur={onBlur}
             innerRef={innerRef}
             onFocus={onFocus}
         />
         <div className="invalid-feedback">{localErrorMessage ? localErrorMessage : Array.isArray(errorMessage) ? errorMessage[0] : errorMessage}</div>
       </div>
    </FormGroup>
  );
}
