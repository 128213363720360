import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'actions/index.js';
import Cookies from "js-cookie";
import axios from 'axios';
import * as constants from 'constants/index.js';
import NotificationAlert from "react-notification-alert";

import InputCustom from 'views/pages/components/InputCustom';
import InputFile from 'views/pages/components/InputFile';

import {
  Container,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";

import CardsHeader from 'layouts/Headers/CardsHeader.js';
import InputToggle from "@pages/components/InputToggle";
import {ButtonContainer, override} from "@pages/reusable-components/styled-components";
import {ClipLoader} from "react-spinners";

export default function Profile() {
  const notificationAlert = useRef(null);
  const profileData = useSelector((state) => state.getState).profileData;
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [imageFile, setImageFile] = useState('');
  const [isDriver, setIsDriver] = useState('');
  const [isNotValid, setIsNotValid] = useState(false);
  const [token, setToken] = useState('');

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [detailErrors, setDetailErrors] = useState('');
  const [passwordErrors, setPasswordErrors] = useState('');

  const data = profileData? profileData: { data: {name: '', email: ''} };
  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  const pullAsyncData = () => {
    if (Cookies.get('grs_token')) {
      let token = Cookies.get('grs_token');
      setToken(token);
      dispatch(actions.getProfileData(token));
    }
  }

  useEffect(() => {
    pullAsyncData()
  }, [])

  useEffect(() => {
    setName(data.name);
    setEmail(data.email);
    setIsDriver(data.is_driver);
  }, [data])

  const saveUserInformation = () => {
    let formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('photo', imageFile);
    formData.append('is_driver', isDriver);

    axios({
      method: 'POST',
      url: constants.API_URL + '/profile',
      data: formData,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(response => {
        if (response.status === 200) {
          setDetailErrors('');
          notify('success', 'Success', response.data.message);
          dispatch(actions.getProfileData(token));
        }
      })
      .catch(error => {
        if (error.response.status === 422) {
          let errors = error.response.data.errors;
          setDetailErrors(errors);
          return
        }
        notify('warning', 'Error', error.response.statusText);
      })
  }

  const changePassword = () => {
    let formData = new FormData();
    formData.append('old_password', currentPassword);
    formData.append('password', newPassword);
    formData.append('password_confirmation', confirmPassword);

    axios({
      method: 'POST',
      url: constants.API_URL + '/password',
      data: formData,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(response => {
        if (response.status === 200) {
          setPasswordErrors('');
          notify('success', 'Success', response.data.message);
          setCurrentPassword('');
          setNewPassword('');
          setConfirmPassword('');
        }
      })
      .catch(error => {
        console.log('error :: ', error.response);
        if (error.response.status === 422) {
          let errors = error.response.data.errors;
          setPasswordErrors(errors);
          return
        }
        notify('warning', 'Error', error.response.statusText);
      })
  }
  const handleChange = (files) => {
    setImageFile(files[0]);
  }

  return (
        !Object.values(data).length ? <ClipLoader
                css={override}
                size={40}
                color={`#7B61E4`}
                loading={!Object.values(data).length}
            /> :
            <>
              <CardsHeader name="Admin" parentName="Profile" />
              <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlert} />
              </div>
              <Container className="mt--6 admin-main-body" fluid >
                <Row>
                  <Col xl="12">
                    <Card>
                      <CardHeader>
                        <h2 className="title mb-0 main-color">Edit Profile</h2>
                      </CardHeader>
                      <CardBody>

                        <Row  className={'phone-container'}>
                          <Col>
                            <h3 className="surtitle mb-4">User Information</h3>
                            <Col >
                              <InputCustom
                                  newLabel={`Name`}
                                  required={true}
                                  placeholder={`Name`}
                                  value={name? name: ''}
                                  setIsNotValid={setIsNotValid}
                                  onChange={(e) => setName(e.target.value)}
                                  invalid={detailErrors && detailErrors.name? true: false}
                                  errorMessage={detailErrors.name}
                              />
                            </Col>
                            <Col>
                              <InputCustom
                                  type={`email`}
                                  newLabel={`Email`}
                                  required={true}
                                  checkEmail={true}
                                  setIsNotValid={setIsNotValid}
                                  placeholder={`Email`}
                                  value={email? email: ''}
                                  onChange={(e) => setEmail(e.target.value)}
                                  invalid={detailErrors && detailErrors.email? true: false}
                                  errorMessage={detailErrors.email}
                              />
                            </Col>
                            <Col >
                              <InputFile
                                  label={`Profile photo`}
                                  placeholder={`Select file`}
                                  onChange={(e) => handleChange(e.target.files)}
                                  invalid={detailErrors && detailErrors.photo? true: false}
                                  errorMessage={detailErrors.photo}
                              />
                            </Col>
                           <Row>
                             <Col md={4}>
                               <InputToggle
                                   side
                                   label={`Is driver`}
                                   checked={isDriver}
                                   onChange={() =>
                                       setIsDriver((prevCheck => prevCheck === 1 ? 0 : 1))
                                   }
                               />
                             </Col>
                             <Col md={8}>
                               <div className={'d-flex justify-content-end'}>
                                 <ButtonContainer reverse  disabled={isNotValid} width={'unset'} onClick={() => saveUserInformation()}>
                                   Save
                                 </ButtonContainer>
                               </div>
                             </Col>
                           </Row>



                          </Col>
                          <Col>
                            <h3 className="surtitle mb-4">Password</h3>
                            <Col>
                              <InputCustom
                                  type={`password`}
                                  newLabel={`Current password`}
                                  placeholder={`Current password`}
                                  value={currentPassword}
                                  onChange={(e) => setCurrentPassword(e.target.value)}
                                  invalid={passwordErrors && passwordErrors.old_password? true: false}
                                  errorMessage={passwordErrors.old_password}
                              />
                            </Col>
                            <Col>
                              <InputCustom
                                  type={`password`}
                                  newLabel={`New password`}
                                  placeholder={`New password`}
                                  value={newPassword}
                                  onChange={(e) => setNewPassword(e.target.value)}
                                  invalid={passwordErrors && passwordErrors.password? true: false}
                                  errorMessage={passwordErrors.password}
                              />
                            </Col>
                            <Col>
                              <InputCustom
                                  type={`password`}
                                  newLabel={` Confirm new password`}
                                  placeholder={` Confirm new password`}
                                  value={confirmPassword}
                                  onChange={(e) => setConfirmPassword(e.target.value)}
                                  invalid={passwordErrors && passwordErrors.password_confirmation? true: false}
                                  errorMessage={passwordErrors.password_confirmation}
                              />
                            </Col>
                            <div className={'d-flex justify-content-end'}>
                              <ButtonContainer width={'unset'}  reverse onClick={() => changePassword()}>
                                Change password
                              </ButtonContainer>
                            </div>
                          </Col>
                        </Row>

                        <hr />


                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </>
  );
}