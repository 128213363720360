import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import NotificationAlert from 'react-notification-alert'
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import CardsHeader from 'layouts/Headers/CardsHeader.js'

import InputCustom from 'views/pages/components/InputCustom'
import TableCustom from 'views/pages/components/TableCustom'

import * as personalisationActions from '../../../../redux/Personalisation/actions';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter
} from 'reactstrap'
import {ButtonContainer} from "@pages/reusable-components/styled-components";

export default function AutomatedTasksEdit() {
  const notificationAlert = useRef(null)
  const history = useHistory()
  const { role } = useParams()
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])
  const personalisationState = useSelector((state) => state.personalisationState, shallowEqual);

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    }
    notificationAlert.current.notificationAlert(options)
  }

  useEffect(() => {
    dispatch({
      type: personalisationActions.TASK_PERSONALISATION_EDIT_ROLE_REQUEST, payload: {
        role
      }
    });
  }, []);

  useEffect(() => {
    if (personalisationState.taskUpdatedSuccessfully)
      notify(
        "success",
        "Success",
        "Successfully updated!"
      );

  }, [personalisationState.taskUpdatedSuccessfully]);

  useEffect(() => {

    setItems(personalisationState.editedRole);

    setTimeout(() => {
      setLoading(false);
    }, 300);

  }, [personalisationState]);

  const goList = () => {
    history.push({
      pathname: `/admin/settings/personalisation/tasks`
    })
  }

  const updateNotifications = async () => {
    const notifications = []

    const _items = items
    try {
      _items.forEach((item, index) => {
        if (item.categories && item.categories.length > 0) {
          const _children = item.categories
          _children.forEach((children, index) => {
            notifications.push({
              id: children.id,
              is_notification_enabled: children.is_notification_enabled ?? 0
            })
          })
        }
      }
      )

      dispatch({
        type: personalisationActions.SAVE_TASK_PERSONALISATION_REQUEST, payload: {
          tasks: notifications,
          role
        }
      });
    } catch (error) {
      console.error('error :: ', error)
      notify(
        "warning",
        "Warning",
        error.response && error.response.data
          ? error.response.data.message
          : error.response
            ? error.response.statusText
            : "Error Occurred!"
      );
    }
  }

  return (
    <>
      <CardsHeader name="Settings" parentName="Notification Management" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Tasks</h1>
                  </Col>
                  <Col xs={4} className="text-right">
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {
                  items && items.length > 0 && items.map((item, index) => {
                    return (
                      <div key={index}>
                        <h2>{item.name}</h2>
                        {item.categories && <TableCustom
                          loading={loading}
                          items={item.categories}
                          columns={[
                            {
                              dataField: "name",
                              text: "Name",
                              sort: true,
                            },
                            {
                              dataField: "is_notification_enabled",
                              text: "Notification enabled",
                              sort: true,
                              formatter: (rowContent, row) => {
                                return (
                                  <>
                                    <InputCustom
                                      type={`checkbox`}
                                      checked={row && row.is_notification_enabled === 1 ? true : false}
                                      onChange={e => {
                                        const _items = items
                                        const _childrens = item.categories

                                        const __childrens = []
                                        _childrens.forEach((children, index) => {
                                          if (children.id === row.id) {
                                            __childrens.push({
                                              ...children,
                                              is_notification_enabled: e.target.checked === true ? 1 : 0
                                            })
                                          } else {
                                            __childrens.push({
                                              ...children,
                                            })
                                          }
                                        })

                                        const __items = []
                                        _items.forEach((item, index) => {
                                          if (item.id === row.resource_id) {
                                            __items.push({
                                              ...item,
                                              categories: __childrens
                                            })
                                          } else {
                                            __items.push({
                                              ...item,
                                            })
                                          }
                                        })

                                        setItems(__items)
                                      }}
                                    />
                                  </>
                                );
                              },
                            },
                          ]}
                        />}
                      </div>
                    );
                  })
                }

              </CardBody>
              <CardFooter>
                <ButtonContainer reverse onClick={() => updateNotifications()}>Save</ButtonContainer>
                <ButtonContainer  onClick={() => goList()}>Back to Roles</ButtonContainer>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}