import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import NotificationAlert from 'react-notification-alert'
import axios from 'axios'
import Cookies from 'js-cookie'

import * as constants from 'constants/index.js'
import CardsHeader from 'layouts/Headers/CardsHeader.js'

import TableCustom from 'views/pages/components/TableCustom'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Modal,
} from 'reactstrap'

import {
  deletee,
} from 'views/policies/FranchiseEnquiryPolicy';
import {ButtonContainer} from "@pages/reusable-components/styled-components";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";

export default function FrnchiseEnquiries() {
  const notificationAlert = useRef(null)
  const history = useHistory()

  const permissions = useSelector(state => state.getState).permissions

  const [token, setToken] = useState('')
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])

  const [deleteModal, setDeleteModal] = useState({
    show: false,
    header: '',
    body: '',
    onClick: null
  })

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    }
    notificationAlert.current.notificationAlert(options)
  }

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/franchise-enquiries`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data
        setItems(data.data.items)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const showEnquiry = (row) => {
    history.push({
      pathname: `/admin/franchise-enquiries/${row.id}/show`
    })
  }

  const deleteEnquiry = async (row) => {
    try {
      const response = await axios({
        method: 'DELETE',
        url: `${constants.API_URL}/franchise-enquiries/${row.id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      console.log('response :: ', response.data)
      const data = response.data
      setItems(data.data.items)
      notify('success', 'Success', data.message)
      setDeleteModal({
        show: false
      })
    } catch (error) {
      console.error('error :: ', error)
      setDeleteModal({
        show: false
      })
      notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
    }
  }

  return (
    <>
      <CardsHeader name="Franchises Enquiries" parentName="Franchises Enquiries Management" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Enquiries</h1>
                    <p className="text-sm mb-0">Franchises Enquiries management</p>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <TableCustom 
                  loading={loading}
                  items={items}
                  columns={[
                    {
                      dataField: "first_name",
                      text: "First name"
                    },
                    {
                      dataField: "last_name",
                      text: "Last name"
                    },
                    {
                      dataField: "personal_phone",
                      text: "Phone"
                    },
                    {
                      dataField: "mobile_phone",
                      text: "Mobile"
                    },
                    {
                      dataField: "email",
                      text: "Email"
                    },
                    {
                      dataField: "created_at",
                      text: "Date",
                      formatter: (rowContent, row) => {
                        return row.created_at? constants.formatDateShortGB(row.created_at): ''
                      }
                    },
                    {
                      dataField: "",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            <IcoMoon
                                className={'cursor-pointer'}
                                iconSet={IconSet}
                                icon={'visibility'}
                                size={24}
                                onClick={() => showEnquiry(row)}
                            />

                                {deletee(permissions) &&
                                <IcoMoon
                                    className={'cursor-pointer'}
                                    iconSet={IconSet}
                                    icon={'delete_outline'}
                                    size={24}
                                    onClick={e => {
                                      setDeleteModal({
                                        show: true,
                                        header: 'Delete Enquiry',
                                        body: 'Are you sure you want to delete this enquiry?',
                                        onClick: () => deleteEnquiry(row)
                                      })
                                    }}
                                />
                               }
                          </>
                        )
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={deleteModal.show}
          toggle={() => setDeleteModal({
            ...deleteModal,
            show: false,
          })}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title main-color">{deleteModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setDeleteModal({
                ...deleteModal,
                show: false,
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {deleteModal.body}
          </div>
          <div className="modal-footer justify-content-end">
            <ButtonContainer
                onClick={() => setDeleteModal({
                  ...deleteModal,
                  show: false,
                })}
            >
              {`Close`}
            </ButtonContainer>
            <ButtonContainer
              reverse
              onClick={deleteModal.onClick}
            >
              {`Delete`}
            </ButtonContainer>

          </div>
        </Modal>
      </Container>
    </>
  )
}