import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Cookies from "js-cookie";
import NotificationAlert from "react-notification-alert";

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import TableCustom from 'views/pages/components/TableCustom';
import InputCustom from 'views/pages/components/InputCustom';

import {
  Container,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Modal,
} from "reactstrap";

import {
  create,
  update
} from 'views/policies/RolePolicy';
import {ButtonContainer, LoaderContainer, override} from "@pages/reusable-components/styled-components";
import {ClipLoader} from "react-spinners";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";

export default function Role() {
  const history = useHistory();
  const notificationAlert = useRef(null);

  const permissions = useSelector(state => state.getState).permissions;
  const authUserTeam = useSelector(state => state.getState).authUserTeam;
  const roles = useSelector(state => state.getState).roles;
  
  const [token, setToken] = useState('');
  const [data, setData] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [isNotValid, setIsNotValid] = useState(true);

  const [name, setName] = useState('');

  const [loading, setLoading] = useState(true);

  const [errors, setErrors] = useState('');
  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_CORE_URL}/role`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data
        setData(data.data)

        setLoading(false)
      } catch (error) {
        setLoading(false)
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const editRole = (row) => {
    history.push({
      pathname: '/admin/role/' + row.id + '/edit',
      state: {
        role: row
      }
    });
  }

  const viewRole = (row) => {
    history.push({
      pathname: '/admin/role/' + row.id + '/show',
      state: {
        role: row
      }
    });
  }

  const addRole = () => {
    let data = {
      name: name,
    }
    axios({
      method: 'POST',
      url: constants.API_CORE_URL + '/role',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: data
    })
      .then(response => {
          let data = response.data;
          console.log('add data :: ', data);
          notify('success', 'Success', data.message);
          setAddModal(false);

          setTimeout(() => {
            history.push({
              pathname: '/admin/role/' + data.data.id + '/edit',
              state: {
                role: data.data
              }
            });
          }, 1500);
      })
      .catch(error => {
        setErrors('');
        console.error('error :: ', error);
        if (error.response && error.response.status === 422) {
          setErrors(error.response.data.errors);
          return
        }
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred.');
      })
  }


  return (
      loading ?  <LoaderContainer>
            <ClipLoader
                css={override}
                size={40}
                color={`#7B61E4`}
                loading={loading}
            />
          </LoaderContainer> :
    <>
      <CardsHeader name="Admin" parentName="Role Management" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--6 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h2 className="mb-0">Roles</h2>
                    <h3 className="mb-0">System Roles Management.</h3>
                  </Col>
                  <Col xs={4} className="text-right">
                    {create(permissions) &&
                      <ButtonContainer reverse onClick={() => setAddModal(true)}>Add role</ButtonContainer>}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <TableCustom 
                  loading={false}
                  delay={true}
                  items={data}
                  columns={[
                    {
                      dataField: "name",
                      text: "Name",
                      sort: true
                    },
                    {
                      dataField: "",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                         update(permissions, row, '', roles, authUserTeam) &&  <>
                           <IcoMoon
                               className={'cursor-pointer'}
                               iconSet={IconSet}
                               icon={'edit'}
                               size={24}
                               onClick={() => editRole(row)}
                           />
                           <IcoMoon
                               className={'cursor-pointer'}
                               iconSet={IconSet}
                               icon={'visibility'}
                               size={24}
                               onClick={() => viewRole(row)}
                           />
                          </>
                        )
                      }
                    }
                  ]}
                  keyField={`name`}
                  searchField={true}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={addModal}
          toggle={() => setAddModal(false)}
          className="modal-dialog-centered modal-secondary"
        >
          <div className="modal-body">
            <form className="new-event--form">
              <InputCustom
                required={true}
                setIsNotValid={setIsNotValid}
                newLabel={`Name`}
                placeholder={`Name`}
                onChange={(e) => setName(e.target.value)}
                invalid={errors && errors.name? true: false}
                errorMessage={errors.name}
              />
            </form>
          </div>
          <div className="modal-footer">
            <ButtonContainer
                onClick={() => setAddModal(false)}
            >
              Cancel
            </ButtonContainer>
            <ButtonContainer
                reverse
                disabled={isNotValid}
                onClick={() => isNotValid ? null : addRole()}
            >
              Save
            </ButtonContainer>
          </div>
        </Modal>
      </Container>
    </>
  );
}