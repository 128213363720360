import React, { useContext, useEffect, useState } from "react";

import { DealContext } from "../context";

import InputCustom from "views/pages/components/InputCustom";
import {
  InputKeySelect,
  InputArraySelect,
} from "views/pages/components/InputSelect";
import axios from "axios";
import * as constants from "../../../../constants";
import Cookies from "js-cookie";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  UncontrolledDropdown,
  FormGroup,
  DropdownToggle,
  DropdownMenu, Input,
  DropdownItem
} from "reactstrap";
import TableCustom from "../../components/TableCustom";

import { CURRENCY, validateForm } from 'constants/index.js';
import { useHistory } from 'react-router-dom';

import EmailsTab from '@pages/deals/tabs/EmailsTab';
import Score from "./details/Score";
import { ButtonContainer, Line } from "@pages/reusable-components/styled-components";
import { useDispatch } from "react-redux";
import actions from "actions/index.js";
export default function InfoTab() {
  const dealContext = useContext(DealContext);
  const dispatch = useDispatch();
  const [customerInfoIsNotValid, setCustomerInfoIsNotValid] = useState(false)
  const [vehicleInfoIsNotValid, setVehicleInfoIsNotValid] = useState(false)
  const [companyIsNotValid, setCompanyIsNotValid] = useState(false)
  const history = useHistory();
  const {
    businessTypes,
    companyTypes,
    dealChecksheet,
    dealStatuses,
    dealMileageIntervals,
    intervals,
    deal,
    titles,
    setDeal,
    paymentIntervals,
    company,
    setCompany,
    deliveredStatuses,
    customer,
    setCustomer,
    vehicle,
    setVehicle,
    dealErrors,
    transitModal,
    setTransitModal,
    updateDeal,
    defaulter,
    expired,
    id,
    formatDate,
    updateMileagePayment
  } = dealContext;

  const [tab, setTab] = useState("deal");
  const clearMenus = () => {
    dispatch(actions.setMenusList([]))
    dispatch(actions.setMenuName(null))
    dispatch(actions.setActiveMenu(null))
    history.push(`/admin/deals`)
  }
  const getDocument = async (id) => {
    if (Cookies.get('grs_token')) {
      let token = Cookies.get('grs_token');
      await axios({
        method: 'GET',
        url: constants.API_URL + '/live-vehicles-detail/' + id + '/deal-pdf-report',
        headers: {
          Authorization: 'Bearer ' + token
        },
        responseType: 'blob'
      })
        .then(response => {
          if (response.status === 200) {
            let data = response.data;
            console.log('response data :: ', data);
            const disposition = response.request.getResponseHeader('Content-Disposition');
            let fileName = "";
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, '');
            }
            const blob = new Blob([data])
            const downloadUrl = URL.createObjectURL(blob)
            let a = window.document.createElement("a");
            a.href = downloadUrl;
            a.download = fileName;
            window.document.body.appendChild(a);
            a.click();
          }
        })
        .catch(error => {
          console.error('error :: ', error.response);
        })
    }

  }

  useEffect(() => {
    const required = ['address', 'driver_license_number', 'email', 'full_name', 'mobile_number', 'postal_code']
    setCustomerInfoIsNotValid(!validateForm(required, customer));
  }, [customer])

  useEffect(() => {
    const required = ['stock_no', 'derivative', 'colour']
    setVehicleInfoIsNotValid(!validateForm(required, vehicle));
  }, [vehicle])

  useEffect(() => {
    const required = ['name', 'phone_number', 'address']
    setCompanyIsNotValid(!validateForm(required, company));
  }, [company])

  return (
    <CardBody>
      {deal.id &&
      <Row>
        <Col lg={8} sm={12}>
          <Row  style={{
            marginLeft: '12px',
            gap: '25px'
          }}>
            <div
                className={`navbar-nav`}
                style={{
                  margin: 5,
                }}
            >
            <span onClick={() => setTab("deal")}
                  className={'nav-text'}
                  style={{ color: tab === "deal" && '#ec6409' }}>
              Deal info
            </span>
              {tab === "deal" && <Line color={'#ec6409'} />}
            </div>

            <div
                className={`navbar-nav`}
                style={{
                  margin: 5,
                }}
            >
            <span
                onClick={() => setTab("customer")}
                className={'nav-text'}
                style={{ color: tab === "customer" && '#ec6409' }}
            >
              Customer info
            </span>
              {tab === "customer" && <Line color={'#ec6409'} />}
            </div>
            <div
                className={`navbar-nav`}
                style={{
                  margin: 5,
                }}
            >
            <span
                onClick={() => setTab("vehicle")}
                className={'nav-text'}
                style={{ color: tab === "vehicle" && '#ec6409' }}
            >
              Vehicle info
            </span>
              {tab === "vehicle" && <Line color={'#ec6409'} />}
            </div>
            <div
                className={`navbar-nav`}
                style={{
                  margin: 5,
                }}
            >
            <span
                onClick={() => setTab("company")}
                className={'nav-text'}
                style={{ color: tab === "company" && '#ec6409' }}
            >
              Company info
            </span>
              {tab === "company" && <Line color={'#ec6409'} />}
            </div>
            <div
                className={`navbar-nav`}
                style={{
                  margin: 5,
                }}
            >
            <span
                onClick={() => setTab("activity")}
                className={'nav-text'}
                style={{ color: tab === "activity" && '#ec6409' }}
            >
              Activity
            </span>
              {tab === "activity" && <Line color={'#ec6409'} />}
            </div>
            <div
                className={`navbar-nav`}
                style={{
                  margin: 5,
                }}
            >
            <span
                className={'nav-text'}
                onClick={() => setTab("emails")}
                style={{ color: tab === "emails" && '#ec6409' }}
            >
              Emails
            </span>
              {tab === "emails" && <Line color={'#ec6409'} />}
            </div>

          </Row>
        </Col>
        <Col lg={4}>
            <h4>
              Account Manager - {customer?.account_manager}
            </h4>

        </Col>
      </Row>
      }
      {tab === "deal" && (
        <>
          <Card>
            <CardBody>
              <Row className="mt-4">
                <Col
                    lg={3}
                    md={4}
                    sm={12}
                >
                  <h2 className="text-center mt-5">Deal Check List</h2>
                {Object.keys(dealChecksheet).map((key1,idx) => {
                  return (
                        <div key={idx}>
                          {Object.keys(dealChecksheet[key1]).map((key2) => {
                            return (
                                <Row className="mt-3" key={key2}>
                                  <Col md={5}>
                                    <label className="form-control-label">
                                      {dealChecksheet[key1][key2]}
                                    </label>
                                  </Col>
                                  <Col md={5} className={'d-flex'} style={{ gap: '8px' }}>
                                    <label className="custom-toggle">
                                      <input
                                          type="checkbox"
                                          checked={
                                            deal &&
                                            deal.checksheet &&
                                            deal.checksheet[key2] === 1
                                                ? true
                                                : false
                                          }
                                          onChange={(e) => {
                                            setDeal({
                                              ...deal,
                                              checksheet: {
                                                ...deal.checksheet,
                                                [key2]:
                                                    deal.checksheet[key2] &&
                                                    deal.checksheet[key2] === 1
                                                        ? 0
                                                        : 1,
                                              },
                                            })
                                          }
                                          }
                                      />
                                      <span
                                          className="custom-toggle-slider rounded-circle"
                                          data-label-off=""
                                          data-label-on=""
                                      />
                                    </label>
                                    {
                                      deal &&
                                      deal.checksheet &&
                                      deal.checksheet[key2] === 1 ?
                                          <span className={'font-weight-600'} style={{ color: '#ec6409', fontSize: 14 }}>Yes</span> :
                                          <span className={'font-weight-600'} style={{ fontSize: 14 }}>No</span>
                                    }

                                  </Col>
                                </Row>
                            );
                          })}
                        </div>
                  );
                })}
                      </Col>
                <Col lg={6} md={8} sm={12}>
                  <h2 className="text-center mt-5">Deal Info</h2>
                 <Row>
                   <Col md={6}>

                     <Col sm={12}>
                       <InputCustom
                           type={`number`}
                           newLabel={`Payment frequencies`}
                           value={
                             deal && deal.payment_frequency
                                 ? deal.payment_frequency
                                 : ""
                           }
                           onChange={(e) =>
                               setDeal({
                                 ...deal,
                                 payment_frequency: e.target.value,
                               })
                           }
                           invalid={
                             dealErrors && dealErrors.payment_frequency ? true : false
                           }
                           errorMessage={dealErrors.payment_frequency}
                       />
                     </Col>
                     <Col sm={12}>
                       <InputCustom
                           newLabel={`Initial payment`}
                           value={
                             deal && deal.initial_payment ? deal.initial_payment : ""
                           }
                           readOnly={true}
                           onChange={(e) =>
                               setDeal({
                                 ...deal,
                                 initial_payment: e.target.value,
                               })
                           }
                       />
                     </Col>
                     <Col sm={12}>
                       <InputCustom
                           newLabel={`Rental`}
                           value={
                             deal && deal.monthly_payment ? deal.monthly_payment : ""
                           }
                           readOnly={true}
                           onChange={(e) =>
                               setDeal({
                                 ...deal,
                                 monthly_payment: e.target.value,
                               })
                           }
                       />
                     </Col>
                     <Col sm={12}>
                       <InputCustom
                           newLabel={`Expected profit`}
                           value={
                             deal && deal.expected_profit ? deal.expected_profit : ""
                           }
                           readOnly={true}
                           onChange={(e) =>
                               setDeal({
                                 ...deal,
                                 expected_profit: e.target.value,
                               })
                           }
                       />
                     </Col>
                     <Col sm={12}>
                       <InputCustom
                           newLabel={`28 day mileage allowance`}
                           value={
                             deal && deal.annual_mileage ? deal.annual_mileage : ""
                           }
                           readOnly={false}
                           onChange={(e) =>
                               setDeal({
                                 ...deal,
                                 annual_mileage: e.target.value,
                               })
                           }
                       />
                     </Col>

                   </Col>
                   <Col md={6}>
                     <Col sm={12}>
                       <InputKeySelect
                           newLabel={`Interval`}
                           value={
                             deal && deal.payment_frequency_interval
                                 ? deal.payment_frequency_interval
                                 : ""
                           }
                           options={paymentIntervals}
                           onChange={(e) =>
                               setDeal({
                                 ...deal,
                                 payment_frequency_interval: e.target.value,
                               })
                           }
                           defaultOption={true}
                           invalid={
                             dealErrors && dealErrors.payment_frequency_interval ? true : false
                           }
                           errorMessage={dealErrors.payment_frequency_interval}
                       />
                     </Col>
                     <Col sm={12}>
                       <InputCustom
                           newLabel={`End payment`}
                           value={deal && deal.end_payment ? deal.end_payment : ""}
                           readOnly={false}
                           onChange={(e) =>
                               setDeal({
                                 ...deal,
                                 end_payment: e.target.value,
                               })
                           }
                       />
                     </Col>
                     <Col sm={12}>
                       <InputCustom
                           newLabel={`Term`}
                           value={deal && deal.term ? deal.term : ""}
                           readOnly={true}
                           onChange={(e) =>
                               setDeal({
                                 ...deal,
                                 term: e.target.value,
                               })
                           }
                       />
                     </Col>
                     <Col sm={12}>
                       <InputCustom
                           type={`number`}
                           newLabel={`Delivery Fee`}
                           value={deal && deal.delivery_fee ? deal.delivery_fee : ""}
                           onChange={(e) =>
                               setDeal({
                                 ...deal,
                                 delivery_fee: e.target.value,
                               })
                           }
                       />
                     </Col>

                   </Col>
                 </Row>
                </Col>
                <Col lg={3} sm={12} md={12}>
                  <Row  className="mt-5 mb-2">
                    <Col md={5} sm={12}>
                      <h4>Deal Status</h4>
                    </Col>
                    <Col md={7} sm={12}>
                      <ButtonContainer
                          onClick={() => getDocument(deal.id)}>Download</ButtonContainer>
                    </Col>
                  </Row>
                   <Col sm={12}>
                     <InputKeySelect
                         newLabel={`Deal status`}
                         value={deal ? deal.deal_status : ""}
                         onChange={(e) =>
                             setDeal({
                               ...deal,
                               deal_status: e.target.value,
                             })
                         }
                         options={dealStatuses}
                         defaultOption={true}
                     />
                   </Col>
                   <Col sm={12}>
                     <InputCustom
                         type={`date`}
                         newLabel={`Deal date`}
                         value={deal && deal.deal_date ? deal.deal_date : ""}
                         onChange={(e) =>
                             setDeal({
                               ...deal,
                               deal_date: e.target.value,
                             })
                         }
                     />
                   </Col>
                   <Col sm={12}>
                     <InputCustom
                         type={`date`}
                         newLabel={`Delivery date`}
                         value={deal && deal.delivery_date ? deal.delivery_date : ""}
                         onChange={(e) =>
                             setDeal({
                               ...deal,
                               delivery_date: e.target.value,
                             })
                         }
                         invalid={
                           dealErrors && dealErrors.delivery_date ? true : false
                         }
                         errorMessage={dealErrors.delivery_date}
                     />
                   </Col>
                   <Col sm={12}>
                     <InputCustom
                         type={`date`}
                         newLabel={`Canceled date`}
                         value={deal && deal.canceled_at ? deal.canceled_at : ""}
                         onChange={(e) =>
                             setDeal({
                               ...deal,
                               canceled_at: e.target.value,
                             })
                         }
                     />
                   </Col>
                  <Col sm={12}>
                    <div className="d-flex flex-row justify-content-end mt-5">
                      {deliveredStatuses &&
                      deliveredStatuses.length > 0 &&
                      deliveredStatuses.includes(deal.deal_status) && (
                        <ButtonContainer
                            onClick={() =>
                                setTransitModal({
                                  show: true,
                                  header:
                                      "Are you sure you want to add customer as defaulter?",
                                  body: "Proceeding with this operation will turn vehicle available!",
                                  onClick: () => defaulter(),
                                })
                            }
                        > Defaulter</ButtonContainer>)}
                      {deliveredStatuses &&
                      deliveredStatuses[0] &&
                      deliveredStatuses[0] === deal.deal_status && (
                        <ButtonContainer
                            onClick={() =>
                                setTransitModal({
                                  show: true,
                                  header: "Are you sure you want to mark as expired?",
                                  body: "Proceeding with this operation will mark deal as expired and vehicle available!",
                                  onClick: () => expired(),
                                })
                            }
                        > Expired</ButtonContainer>)}
                    </div>
                  </Col>

                 </Col>
              </Row>

              <div className="d-flex flex-row justify-content-center mt-5">
                <ButtonContainer onClick={() => {
                  clearMenus()

                }}>Cancel</ButtonContainer>
                <ButtonContainer reverse onClick={() => updateDeal()}>Update</ButtonContainer>
              </div>
            </CardBody>
          </Card>
        </>
      )
      }
      {
        tab === "customer" && (
          <Card>
            <CardHeader>
              <h2>Personal information</h2>
            </CardHeader>
            <CardBody>
              <Row className={'phone-container'}>
                <Col>
                  <Col >
                    <InputKeySelect
                      newLabel={`Title`}
                      options={titles}
                      value={customer && customer.title ? customer.title : ''}
                      onChange={e => setCustomer({
                        ...customer,
                        title: e.target.value,
                        attention_to: `${e.target.value ? `${titles[e.target.value]} ` : ''}${customer.first_name ? `${customer.first_name}` : ''}${customer.last_name ? ` ${customer.last_name}` : ''}`
                      })}
                      defaultOption={true}
                    />
                  </Col>
                  <Col >
                    <InputCustom
                      newLabel={'First Name'}
                      placeholder="First Name"
                      type="text"
                      value={customer ? customer.first_name : ''}
                      onChange={(e) => setCustomer({
                        ...customer,
                        first_name: e.target.value,
                        full_name: `${e.target.value ? ` ${e.target.value}` : ''}${customer.last_name ? ` ${customer.last_name}` : ''}`,
                        attention_to: `${customer.title ? `${titles[customer.title]} ` : ''}${e.target.value ? `${e.target.value}` : ''}${customer.last_name ? ` ${customer.last_name}` : ''}`
                      })}
                    />
                  </Col>
                  <Col>
                    <FormGroup className={'d-flex align-items-center'}>
                      <label className="form-control-label  w-50">Last Name</label>
                      <Input
                        placeholder="Last Name"
                        type="text"
                        value={customer ? customer.last_name : ''}
                        onChange={(e) => setCustomer({
                          ...customer,
                          last_name: e.target.value,
                          full_name: `${customer.first_name ? ` ${customer.first_name}` : ''}${e.target.value ? ` ${e.target.value}` : ''}`,
                          attention_to: `${customer.title ? `${titles[customer.title]} ` : ''}${customer.first_name ? `${customer.first_name}` : ''}${e.target.value ? ` ${e.target.value}` : ''}`
                        })}
                      />
                    </FormGroup>
                  </Col>
                  <Col >
                    <InputCustom
                      newLabel={`N.I Number`}
                      value={
                        customer && customer.national_insurance_number
                          ? customer.national_insurance_number
                          : ""
                      }
                      onChange={(e) =>
                        setCustomer({
                          ...customer,
                          national_insurance_number: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col>
                    <InputCustom
                      newLabel={`D/L Number`}
                      required
                      checkValid={false}
                      value={
                        customer && customer.driver_license_number
                          ? customer.driver_license_number
                          : ""
                      }
                      onChange={(e) =>
                        setCustomer({
                          ...customer,
                          driver_license_number: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Col>
                <Col>
                  <Col>
                    <FormGroup className={'d-flex align-items-center'}>
                      <label className="form-control-label w-50">Attention To</label>
                      <Input
                        placeholder="Attention To"
                        type="text"
                        value={customer ? customer.attention_to : ''}
                        onChange={(e) => setCustomer({
                          ...customer,
                          attention_to: e.target.value
                        })}
                      />
                    </FormGroup>
                  </Col>
                  <Col >
                    <InputCustom
                      newLabel={`Email Address`}
                      required
                      checkEmail
                      checkValid={false}
                      value={customer && customer.email ? customer.email : ""}
                      onChange={(e) =>
                        setCustomer({
                          ...customer,
                          email: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col >
                    <InputCustom
                      newLabel={`Phone Number`}
                      required
                      checkValid={false}
                      value={
                        customer && customer.mobile_number
                          ? customer.mobile_number
                          : ""
                      }
                      onChange={(e) =>
                        setCustomer({
                          ...customer,
                          mobile_number: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col >
                    <InputCustom
                      newLabel={`Land Line Number`}
                      value={
                        customer && customer.land_line_number
                          ? customer.land_line_number
                          : ""
                      }
                      onChange={(e) =>
                        setCustomer({
                          ...customer,
                          land_line_number: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col >
                    <InputCustom
                      newLabel={`Postal Code`}
                      required
                      checkValid={false}
                      value={
                        customer && customer.postal_code ? customer.postal_code : ""
                      }
                      onChange={(e) =>
                        setCustomer({
                          ...customer,
                          postal_code: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Col>
                <Col>
                  <Col>
                    <InputCustom
                      placeholder="Full Name"
                      newLabel={'Full Name'}
                      type="text"
                      required
                      checkValid={false}
                      value={customer ? customer.full_name : ''}
                      disabled={true}
                      onChange={(e) => setCustomer({
                        ...customer,
                        full_name: e.target.value
                      })}
                    />
                  </Col>
                  <Col>
                    <InputCustom
                      newLabel={`Address`}
                      required
                      checkValid={false}
                      value={customer && customer.address ? customer.address : ""}
                      onChange={(e) =>
                        setCustomer({
                          ...customer,
                          address: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col>
                    <FormGroup className={'d-flex align-items-center'}>
                      <label className="form-control-label w-50">Building</label>
                      <Input
                        placeholder="Building"
                        type="text"
                        value={customer && customer.building ? customer.building : ''}
                        onChange={(e) => setCustomer({
                          ...customer,
                          building: e.target.value
                        })}
                      />
                    </FormGroup>
                  </Col>
                  <Col >
                    <FormGroup className={'d-flex align-items-center'}>
                      <label className="form-control-label w-50">Street</label>
                      <Input
                        placeholder="Street"
                        type="text"
                        value={customer && customer.street ? customer.street : ''}
                        onChange={(e) => setCustomer({
                          ...customer,
                          street: e.target.value
                        })}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className={'d-flex align-items-center'}>
                      <label className="form-control-label w-50">City</label>
                      <Input
                        placeholder="City"
                        type="text"
                        value={customer && customer.city ? customer.city : ''}
                        onChange={(e) => setCustomer({
                          ...customer,
                          city: e.target.value
                        })}
                      />
                    </FormGroup>
                  </Col>
                </Col>
              </Row>
              <div className="d-flex flex-row justify-content-end mt-5">
                <ButtonContainer onClick={() => {
                  clearMenus()
                }}>Cancel</ButtonContainer>
                <ButtonContainer disabled={customerInfoIsNotValid} reverse onClick={() => updateDeal()}>Update</ButtonContainer>
              </div>
            </CardBody>
          </Card>
        )
      }
      {
        tab === "vehicle" && (
          <Card>
            <CardHeader>
              <h2>
                Vehicle information
                {deal.vehicle_make?.name ? ` : ${deal.vehicle_make.name}` : ""}
                {deal.vehicle_model?.name ? `, ${deal.vehicle_model.name}` : ""}
              </h2>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md={4}>
                  <Col >
                    <InputCustom
                      newLabel={`Reg. Number`}
                      required
                      checkValid={false}
                      value={vehicle && vehicle.stock_no ? vehicle.stock_no : ""}
                      onChange={(e) =>
                        setVehicle({
                          ...vehicle,
                          stock_no: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col >
                    <InputCustom
                      newLabel={`Colour`}
                      required
                      checkValid={false}
                      value={vehicle && vehicle.colour ? vehicle.colour : ""}
                      onChange={(e) =>
                        setVehicle({
                          ...vehicle,
                          colour: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col >
                    <InputCustom
                      newLabel={`Derivative`}
                      required
                      checkValid={false}
                      value={
                        vehicle && vehicle.derivative ? vehicle.derivative : ""
                      }
                      onChange={(e) =>
                        setVehicle({
                          ...vehicle,
                          derivative: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col>
                    <InputCustom
                      newLabel={`Current mileage`}
                      value={
                        deal && deal.current_mileage ? deal.current_mileage : ""
                      }
                      readOnly={true}
                      onChange={(e) =>
                        setVehicle({
                          ...deal,
                          current_mileage: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Col>
                <Col md={4}>
                  <Col>
                    <InputCustom
                      newLabel={`Mileage done since delivery date`}
                      value={
                        dealMileageIntervals.length > 0 ?
                          dealMileageIntervals.map(item => item.total_interval_miles).reduce((prev, next) => prev + next) : 0
                      }
                      readOnly={true}
                      disabled={true}
                    />
                  </Col>
                  <Col>
                    <InputCustom
                      type={`textarea`}
                      label={`Extras`}
                      rows="5"
                      value={deal && deal.extras ? deal.extras : ""}
                      onChange={(e) =>
                        setDeal({
                          ...deal,
                          extras: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Col>
              </Row>
              <div className="d-flex flex-row justify-content-end mt-5">
                <ButtonContainer onClick={() => {
                  clearMenus()
                }}>Cancel</ButtonContainer>
                <ButtonContainer disabled={vehicleInfoIsNotValid} reverse onClick={() => updateDeal()}>Update</ButtonContainer>
              </div>
            </CardBody>
          </Card>
        )
      }
      {
        tab === "company" && (
          <Card>
            <CardHeader>
              <h2>Company information</h2>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md={4}>
                  <Col >
                    <InputArraySelect
                      newLabel={`Business activity`}
                      value={
                        company && company.business_activity_id
                          ? company.business_activity_id
                          : ""
                      }
                      options={businessTypes}
                      valueKey={`id`}
                      labelKey={`name`}
                      onChange={(e) =>
                        setCompany({
                          ...company,
                          business_activity_id: e.target.value,
                        })
                      }
                      defaultOption={true}
                    />
                  </Col>
                  <Col>
                    <InputArraySelect
                      newLabel={`Company type`}
                      value={
                        company && company.company_type_id
                          ? company.company_type_id
                          : ""
                      }
                      options={companyTypes}
                      valueKey={`id`}
                      labelKey={`name`}
                      onChange={(e) =>
                        setCompany({
                          ...company,
                          company_type_id: e.target.value,
                        })
                      }
                      defaultOption={true}
                    />
                  </Col>
                </Col>
                <Col md={4}>
                  <Col >
                    <InputCustom
                      newLabel={`Company name`}
                      value={company && company.name ? company.name : ""}
                      onChange={(e) =>
                        setCompany({
                          ...company,
                          name: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col>
                    <InputCustom
                      newLabel={`Business address`}
                      value={company && company.address ? company.address : ""}
                      onChange={(e) =>
                        setCompany({
                          ...company,
                          address: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Col>
                <Col md={4}>
                  <InputCustom
                    newLabel={`Business phone number`}
                    value={
                      company && company.phone_number ? company.phone_number : ""
                    }
                    onChange={(e) =>
                      setCompany({
                        ...company,
                        phone_number: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
              <div className="d-flex flex-row justify-content-end mt-5">
                <ButtonContainer onClick={() => {
                  clearMenus()
                }}>Cancel</ButtonContainer>
                <ButtonContainer disabled={companyIsNotValid} reverse onClick={() => updateDeal()}>Update</ButtonContainer>
              </div>
            </CardBody>
          </Card>
        )
      }
      {
        tab === "activity" && (
          <Card>
            <CardHeader>
              <h2>Mileage activity</h2>
            </CardHeader>
            <CardBody>
              {dealMileageIntervals.length ? <TableCustom
                  loading={false}
                  items={dealMileageIntervals}
                  keyField={`title`}
                  searchField={true}
                  columns={[
                    {
                      dataField: "from_date",
                      text: "From",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return formatDate(row.from_date)
                      }
                    },
                    {
                      dataField: "to_date",
                      text: "To",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return formatDate(row.end_date)
                      }
                    },
                    {
                      dataField: "total_interval_miles",
                      text: "Mileage",
                      sort: true
                    },
                    {
                      dataField: "exceeded_at",
                      text: "Exceeded On",
                      sort: false,
                      formatter: (rowContent, row) => {
                        return formatDate(row.exceeded_at)
                      }
                    },
                    {
                      dataField: "exceeded_by",
                      text: "Exceeded By",
                      sort: false,
                      formatter: (rowContent, row) => {
                        return row.exceeded_at ? (row.total_interval_miles - deal.annual_mileage) : '-';
                      }
                    },
                    {
                      dataField: "exceeded_by",
                      text: "Cost",
                      sort: false,
                      formatter: (rowContent, row) => {
                        return row.exceeded_at ? `${CURRENCY} ${(Math.round(((row.total_interval_miles - deal.annual_mileage) * 0.2) * 100) / 100).toFixed(2)}` : '-';
                      }
                    },
                    {
                      dataField: "is_exceeded_paid",
                      text: "Invoiced",
                      sort: false,
                      formatter: (rowContent, row) => {
                        return row.exceeded_at ? (row.is_exceeded_paid === true ? "Yes" : "No") : '-';
                      }
                    },
                    {
                      dataField: "",
                      text: "",
                      formatter: (rowContent, row) => {
                        return (
                            <>
                              {row.exceeded_at && <UncontrolledDropdown>
                                <DropdownToggle
                                    className="btn-icon-only text-light"
                                    color=""
                                    role="button"
                                    size="sm"
                                >
                                  <i className="fas fa-ellipsis-v"/>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-arrow" right>
                                  <DropdownItem
                                      onClick={e => updateMileagePayment(row)}
                                  >
                                    {(row.is_exceeded_paid === true ? "Not Paid" : "Paid")}
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                              }
                            </>
                        )
                      }
                    }
                  ]}
              /> : <h3>No activity yet</h3>}
            </CardBody>
            {(deal && deal.device) && <Score deal={id} deliveryDate={deal.delivery_date ?? null} />}
          </Card>
        )
      }
      {
        tab === "emails" && (
          <EmailsTab />
        )
      }
      <Modal
        isOpen={transitModal.show}
        toggle={() =>
          setTransitModal({
            ...transitModal,
            show: false,
          })
        }
        className="modal-dialog-centered"
      >
        <div className="modal-header">
          <h2 className="modal-title">{transitModal.header}</h2>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() =>
              setTransitModal({
                ...transitModal,
                show: false,
              })
            }
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">{transitModal.body}</div>
        <div className="modal-footer">
          <Button
            className="new-event--add"
            color="danger"
            type="button"
            onClick={transitModal.onClick}
          >
            {`Proceed`}
          </Button>
          <Button
            className="ml-auto"
            color="link"
            type="button"
            onClick={() =>
              setTransitModal({
                ...transitModal,
                show: false,
              })
            }
          >
            {`Cancel`}
          </Button>
        </div>
      </Modal>
    </CardBody >
  );
}
