import React, { useEffect, useState, createContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from "js-cookie";
import axios from 'axios';

import actions from 'actions';
import * as constants from 'constants/index.js';
import { formatDate } from "constants/index.js";

export const Context = createContext({});

export const Provider = props => {
  const { id, notify, children, setLoading } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const profilePermissions = useSelector((state) => state.getState).permissions;
  const csrfToken = useSelector((state) => state.getState).csrfToken;
  const profileData = useSelector((state) => state.getState).profileData;

  const [token, setToken] = useState('');
  const [tab, setTab] = useState('info');

  const [siteCode, setSiteCode] = useState('');
  const [authUserTeam, setAuthUserTeam] = useState('');
  const [allowEdit, setAllowEdit] = useState(false);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [companyTypes, setCompanyTypes] = useState([]);
  const [contractTypes, setContractTypes] = useState('');
  const [dealChecksheet, setDealChecksheet] = useState('');
  const [dealStatuses, setDealStatuses] = useState('');
  const [dealMileageIntervals, setDealMileageIntervals] = useState('');
  const [dealStatusesLabels, setDealStatusesLabels] = useState('');
  const [dealTaskStatuses, setDealTaskStatuses] = useState('');
  const [tasks, setTasks] = useState('');
  const [dealTracking, setDealTracking] = useState('');
  const [dealerMembers, setDealerMembers] = useState([]);
  const [dealerProfile, setDealerProfile] = useState('');
  const [documents, setDocuments] = useState([]);
  const [documentsOld, setDocumentsOld] = useState([]);
  const [hasAccessToDocumentModule, setHasAccessToDocumentModule] = useState('');
  const [hasPaymentSchedule, setHasPaymentSchedule] = useState(false);
  const [intervals, setIntervals] = useState('');
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [deal, setDeal] = useState('');
  const [paymentIntervals, setPaymentIntervals] = useState('');
  const [company, setCompany] = useState('');
  const [deliveredStatuses, setDeliveredStatuses] = useState([]);
  const [defaultedStatus, setDefaultedStatus] = useState('');
  const [dealExpiredStatus, setDealExpiredStatus] = useState('');
  const [canAccessPaymentsDetails, setCanAccessPaymentsDetails] = useState('');
  const [isAbleToGetPayments, setIsAbleToGetPayments] = useState('');

  const [customer, setCustomer] = useState('');
  const [vehicle, setVehicle] = useState('');

  const [checkSheetCount, setCheckSheetCount] = useState('');

  const [dealErrors, setDealErrors] = useState('');
  const [documentErrors, setDocumentErrors] = useState('');
  const [taskErrors, setTaskErrors] = useState('');

  const [documentModal, setDocumentModal] = useState(false);
  const [document, setDocument] = useState('');

  const [deleteModal, setDeleteModal] = useState({
    show: false,
    header: '',
    body: '',
    onClick: null,
  })

  const [fileLists, setFileLists] = useState([]);

  const [addNoteModal, setAddNoteModal] = useState(false);
  const [noteDelay, setNoteDelay] = useState(false);
  const [note, setNote] = useState('');
  const [titles, setTitles] = useState('');
  const [taskDelay, setTaskDelay] = useState('');
  const [task, setTask] = useState('');
  const [addTaskModal, setAddTaskModal] = useState(false);
  const [inspectionDocuments, setInspectionDocuments] = useState([]);
  const [selectedRecurrentTask, setSelectedRecurrentTask] = useState('');
  const [editRecurrentTaskModal, setEditRecurrentTaskModal] = useState(false);

  const [transitModal, setTransitModal] = useState({
    show: false,
    header: '',
    body: '',
    onClick: null
  })

  const [filterOptions, setFilterOptions] = useState('');
  const fetchData = async (source, token) => {
    setLoading(true)
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/deal/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      const data = response.data
      setSiteCode(data.data.siteCode);
      setAuthUserTeam(data.data.authUserTeam);
      setAllowEdit(data.data.allowEdit);
      setBusinessTypes(data.data.businessTypes);
      setCompanyTypes(data.data.companyTypes);
      setContractTypes(data.data.contractTypes);
      setDealChecksheet(data.data.dealChecksheet);
      setDealStatuses(data.data.dealStatuses);
      setDealStatusesLabels(data.data.dealStatusesLabels);
      setDealTaskStatuses(constants.taskStatuses);
      setDealTracking(data.data.dealTracking);
      setDealMileageIntervals(data.data.dealMileageIntervals);
      setInspectionDocuments(data.data.inspectionDocuments);
      setTitles(data.data.titles);

      if (data.data.dealerMembers) {
        let dealer_members = data.data.dealerMembers;
        dealer_members.forEach(dealer => {
          dealer.text = dealer.name;
        });
        setDealerMembers(dealer_members);
      }

      setDealerProfile(data.data.dealerProfile);
      let tempDocs = []
      Object.values(data.data.documents).forEach(item => {
        if (item?.docs?.length) {
          tempDocs.push(...item?.docs)
        }
      })
      setDocuments(tempDocs);
      setDocumentsOld(data.data.documents);
      setHasAccessToDocumentModule(data.data.hasAccessToDocumentModule);
      setHasPaymentSchedule(data.data.hasPaymentSchedule);
      setIntervals(data.data.intervals);
      setIsAuthorized(data.data.isAuthorized);
      setDeal({
        ...data.data.item,
        deal_date: data.data.item.deal_date ? formatDate(data.data.item.deal_date) : '',
        delivery_date: data.data.item.delivery_date ? formatDate(data.data.item.delivery_date) : '',
        canceled_at: data.data.item.canceled_at ? formatDate(data.data.item.canceled_at) : '',
        live_vehicle_id: data.data.liveVehicleId ?? null,
      });
      setPaymentIntervals(data.data.paymentIntervals);
      setCompany(data.data.item.customer.company);
      setDeliveredStatuses(data.data.deliveredStatuses);
      setDefaultedStatus(data.data.defaultedStatus);
      setDealExpiredStatus(data.data.dealExpiredStatus);
      setCanAccessPaymentsDetails(data.data.canAccessPaymentsDetails);
      setIsAbleToGetPayments(data.data.isAbleToGetPayments);

      setCustomer({
        ...customer,
        id: data.data.item.customer_id,
        full_name: data.data.item.customer.full_name,
        account_manager: data.data.item.customer?.account_manager?.name,
        address: data.data.item.customer.address,
        email: data.data.item.customer.email,
        mobile_number: data.data.item.customer.mobile_number,
        land_line_number: data.data.item.customer.land_line_number,
        postal_code: data.data.item.customer.postal_code,
        national_insurance_number: data.data.item.customer.national_insurance_number,
        driver_license_number: data.data.item.customer.driver_license_number,
        first_name: data.data.item.customer.first_name,
        last_name: data.data.item.customer.last_name,
        building: data.data.item.customer.building,
        street: data.data.item.customer.street,
        city: data.data.item.customer.city,
        title: data.data.item.customer.title,
        attention_to: data.data.item.customer.attention_to,
      })

      setVehicle({
        ...vehicle,
        id: data.data.item.vehicle_id,
        stock_no: data.data.item.vehicle.stock_no,
        colour: data.data.item.vehicle.colour,
        derivative: data.data.item.vehicle.derivative,
        current_mileage: data.data.item.vehicle.current_mileage,
        device_id: data.data.item.vehicle?.device?.uuid,
      })
      if (data.data.dealChecksheet) {
        setCheckSheetCount(Object.keys(data.data.dealChecksheet).length)
      }
      dispatch(actions.getNotifications(token));
      setLoading(false)
    } catch (error) {
      setLoading(false)
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
      }
    }
  }

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    fetchData(source, grs_token)

    return () => {
      source.cancel()
    }
  }, [])


  useEffect(() => {
    switch (tab) {
      case 'notes':
        setTimeout(() => {
          setNoteDelay(true);
        }, 1000);
        break;

      case 'tasks':
        setTimeout(() => {
          setTaskDelay(true);
        }, 1000);
        break;

      default:
        break;
    }
  }, [tab])

  const updateDeal = () => {
    axios({
      method: 'PUT',
      url: constants.API_URL + '/deal/' + id,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        deal_status: deal && deal.deal_status ? deal.deal_status : '',
        deal_date: deal && deal.deal_date ? deal.deal_date : '',
        delivery_date: deal && deal.delivery_date ? deal.delivery_date : '',
        canceled_at: deal && deal.canceled_at ? deal.canceled_at : '',
        customer: {
          ...customer,
          company: {
            ...company,
            business_address: company.address,
            business_phone_number: company.phone_number,
            company_name: company.name,
          }
        },
        checksheet: deal.checksheet,
        vehicle: vehicle,
        extras: deal.extras,
        payment_frequency: deal.payment_frequency,
        payment_frequency_interval: deal.payment_frequency_interval,
        initial_payment: deal.initial_payment,
        monthly_payment: deal.monthly_payment,
        expected_profit: deal.expected_profit,
        end_payment: deal.end_payment,
        term: deal.term,
        interval: deal.interval,
        annual_mileage: deal.annual_mileage ? deal.annual_mileage : 0,
        delivery_fee: deal.delivery_fee,
        finance_notes: deal.finance_notes,
        vehicle_id: vehicle.id,
        customer_id: customer.id,
      }
    })
      .then(response => {
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setDealErrors('');
        console.error('error :: ', error.response);
        if (error.response.status === 422) {
          setDealErrors(error.response.data.errors);
        }
        notify('warning', 'Warning', error.response.statusText);
      })
  }
  const downloadDocument = (url) => {
    const link = window.document.createElement("a");
    link.setAttribute('target', '_blank');
    link.href = url;
    link.download = 'file.pdf';
    link.click();
    // fetch(url, { method: 'get', referrerPolicy: 'no-referrer' })
    //     .then(res => res.blob())
    //     .then(res => {
    //
    //       const aElement = document.createElement('a');
    //       aElement.setAttribute('download', url.split('/').pop());
    //       const href = URL.createObjectURL(res);
    //       aElement.href = href;
    //       aElement.setAttribute('target', '_blank');
    //       aElement.click();
    //       URL.revokeObjectURL(href);
    //     });
  };

  const sendDocument = () => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/deal/' + id + '/doc',
      headers: {
        Authorization: 'Bearer ' + token
      },
      params: document,
    })
      .then(response => {
        if (response.status === 200) {
          const source = axios.CancelToken.source()
          console.log('response',response)
          fetchData(source, token)
          setDocumentModal(false);
        }
      })
      .catch(error => {
        console.error('error :: ', error.response);
        notify('warning', 'Warning', error.response && error.response.data.message ? error.response.data.message : error.response.statusText);
      })
  }

  const viewDocument = (row) => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/deal/' + id + '/document/' + row.id,
      headers: {
        Authorization: 'Bearer ' + token
      },
      responseType: 'blob'
    })
      .then(response => {
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          const disposition = response.request.getResponseHeader('Content-Disposition');
          let fileName = "";
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '');
          }
          const blob = new Blob([data])
          const downloadUrl = URL.createObjectURL(blob)
          let a = window.document.createElement("a");
          a.href = downloadUrl;
          a.download = fileName;
          window.document.body.appendChild(a);
          a.click();
        }
      })
      .catch(error => {
        console.error('error :: ', error.response);
        notify('warning', 'Warning', error.response && error.response.data.message ? error.response.data.message : error.response.statusText);
      })
  }

  const deleteDocument = (documentId) => {
    console.log('documentId :: ', documentId);
    axios({
      method: 'GET',
      url: constants.API_URL + '/deal/' + id + '/remove/' + documentId,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(response => {
        setDeleteModal({
          ...deleteModal,
          show: false,
        });
        if (response.status === 204) {
          let data = response.data;
          setDeal({
            ...deal,
            custom_documents: data.data,
          })
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setDeleteModal({
          ...deleteModal,
          show: false,
        });
        console.error('error :: ', error.response);
        notify('warning', 'Warning', error.response && error.response.data.message ? error.response.data.message : error.response.statusText);
      })
  }

  const uploadDocument = () => {
    let formData = new FormData();
    for (let index = 0; index < fileLists.length; index++) {
      const element = fileLists[index];
      formData.append(`file[${index}]`, element);
    }

    axios({
      method: 'POST',
      url: constants.API_URL + '/deal/' + id + '/general-documents',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: formData
    })
      .then(response => {
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setDeal({
            ...deal,
            custom_documents: data.data,
          })
          setFileLists([]);
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setDocumentErrors('');
        console.error('error :: ', error);
        if (error.response && error.response.status === 422) {
          let errors = error.response.data.errors;
          setDocumentErrors(errors);
        }
        notify('warning', 'Warning', error.response && error.response.statusText ? error.response.statusText : 'Error occur.');
      })
  }

  const updateMileagePayment = (row) => {
    axios({
      method: 'PUT',
      url: `${constants.API_URL}/deal/${row.id}/mileage`,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        status: row.is_exceeded_paid === true ? 0 : 1 // We turn the status the other way around: 0 to mark unpaid, 1 to mark as paid 
      }
    })
      .then(response => {
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          notify('success', 'Success', data.message);
          setDealMileageIntervals(data.data);
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        notify('warning', 'Warning', error.response.statusText);
      })
  }

  const formatDateTask = (d) => {
    if (d) {
      let date = new Date(d);
      let ye = Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
      let mo = Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
      let da = Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

      return `${da}/${mo}/${ye}`
    }
  }

  const formatDateNote = (d) => {
    if (d) {
      let date = new Date(d);

      let options = {
        year: 'numeric', month: '2-digit', day: '2-digit',
        hour: 'numeric', minute: 'numeric', second: '2-digit',
        hour12: false
      }

      let da = Intl.DateTimeFormat('en-BG', options).format(date);

      return da
    }
  }

  const addNote = () => {
    if (note === '') {
      notify('info', 'Information', 'Please fill note.')
      return
    }
    axios({
      method: 'POST',
      url: constants.API_URL + '/deal/' + id + '/note',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        task: note
      }
    })
      .then(response => {
        setAddNoteModal(false);
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setDeal({
            ...deal,
            notes: data.data,
          })
          setNote('');
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setAddNoteModal(false);
        console.error('error :: ', error);
        notify('warning', 'Warning', error.response && error.response.statusText ? error.response.statusText : 'Error occur.');
      })
  }

  const deleteNote = (noteId) => {
    axios({
      method: 'DELETE',
      url: constants.API_URL + '/deal/' + id + '/note/' + noteId,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then(response => {
        setDeleteModal({
          ...deleteModal,
          show: false,
        });
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setDeal({
            ...deal,
            notes: data.data,
          })
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setDeleteModal({
          ...deleteModal,
          show: false,
        });
        console.error('error :: ', error);
        notify('warning', 'Warning', error.response && error.response.statusText ? error.response.statusText : 'Error occur.');
      })
  }

  const addTask = () => {
    axios({
      method: 'POST',
      url: constants.API_URL + '/deal/' + id + '/task',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: { ...task, task_category: 'deal-manually' },
    })
      .then(response => {
        setAddTaskModal(false);
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setDeal({
            ...deal,
            tasks: data.data.tasks,
            recurrentTasks: data.data.recurrentTasks,
          })
          setTask('');
          dispatch(actions.getNotifications(token));
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setTaskErrors('');
        console.error('error :: ', error.response);
        if (error.response && error.response.status === 422) {
          setTaskErrors(error.response.data.errors)
          return
        }
        setAddTaskModal(false);
        notify('warning', 'Warning', error.response && error.response.statusText ? error.response.statusText : 'Error occur.');
      })
  }

  const taskEdit = (row) => {
    history.push({
      pathname: '/admin/deals/' + id + '/task/' + row.uuid,
    })
  }

  const deleteTask = (taskId) => {
    axios({
      method: 'DELETE',
      url: constants.API_URL + '/deal/' + id + '/task/' + taskId,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then(response => {
        setDeleteModal({
          ...deleteModal,
          show: false,
        });
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setDeal({
            ...deal,
            tasks: data.data.tasks,
            recurrentTasks: data.data.recurrentTasks,
          })
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setDeleteModal({
          ...deleteModal,
          show: false,
        });
        console.error('error :: ', error.response);
        notify('warning', 'Warning', error.response && error.response.statusText ? error.response.statusText : 'Error occur.');
      })
  }

  const recurrentTaskUpdate = () => {
    axios({
      method: 'POST',
      url: constants.API_URL + '/deal/' + id + '/recurrent-tasks/' + selectedRecurrentTask.id,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        ...selectedRecurrentTask,
        task_assignee: selectedRecurrentTask.assignee,
        next_schedule_at: formatDate(selectedRecurrentTask.next_schedule_at),
      },
    })
      .then(response => {
        setTaskErrors('');
        setEditRecurrentTaskModal(false);
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setDeal({
            ...deal,
            tasks: data.data.tasks,
            recurrentTasks: data.data.recurrentTasks,
          })
          setSelectedRecurrentTask('');
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setTaskErrors('');
        console.error('error :: ', error.response);
        if (error.response && error.response.status === 422) {
          setTaskErrors(error.response.data.errors)
          return
        }
        setEditRecurrentTaskModal(false);
        notify('warning', 'Warning', error.response && error.response.statusText ? error.response.statusText : 'Error occur.');
      })
  }

  const deleteRecurrentTask = (taskId) => {
    axios({
      method: 'DELETE',
      url: constants.API_URL + '/deal/' + id + '/recurrent-tasks/' + taskId,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then(response => {
        setDeleteModal({
          ...deleteModal,
          show: false,
        });
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setDeal({
            ...deal,
            tasks: data.data.tasks,
            recurrentTasks: data.data.recurrentTasks,
          })
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setDeleteModal({
          ...deleteModal,
          show: false,
        });
        console.error('error :: ', error.response);
        notify('warning', 'Warning', error.response && error.response.statusText ? error.response.statusText : 'Error occur.');
      })
  }

  const goPayment = () => {
    history.push({
      pathname: '/admin/deals/' + id + '/payment',
    })
  }
  const goTracking = () => {
    history.push({
      pathname: `/admin/deals/${id}/device/${vehicle.device_id}`,
    })
  }

  const getDocument = (docId) => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/deal/' + id + '/contract/' + docId,
      headers: {
        Authorization: 'Bearer ' + token
      },
      responseType: 'blob'
    })
      .then(response => {
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          const disposition = response.request.getResponseHeader('Content-Disposition');
          let fileName = "";
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '');
          }
          const blob = new Blob([data])
          const downloadUrl = URL.createObjectURL(blob)
          let a = window.document.createElement("a");
          a.href = downloadUrl;
          a.download = fileName;
          window.document.body.appendChild(a);
          a.click();
        }
      })
      .catch(error => {
        console.error('error :: ', error.response);
        notify('warning', 'Warning', error.response && error.response.data.message ? error.response.data.message : error.response.statusText);
      })
  }

  const viewSigning = (docId) => {
    window.open(constants.BASE_URL + '/deal/' + id + '/' + docId + '/documents/web', '_blank');
  }

  const checkSignedDocument = (docId) => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/deal/' + id + '/signed-document/' + docId,
      headers: {
        Authorization: 'Bearer ' + token
      },
      responseType: 'blob'
    })
      .then(response => {
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          const disposition = response.request.getResponseHeader('Content-Disposition');
          let fileName = "";
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '');
          }
          const blob = new Blob([data])
          const downloadUrl = URL.createObjectURL(blob)
          let a = window.document.createElement("a");
          a.href = downloadUrl;
          a.download = fileName;
          window.document.body.appendChild(a);
          a.click();
        }
      })
      .catch(error => {
        console.error('error :: ', error.response);
        notify('warning', 'Warning', error.response && error.response.data.message ? error.response.data.message : error.response.statusText);
      })
  }

  const defaulter = () => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/deal/' + id + '/transit/' + defaultedStatus,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then(response => {
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        console.error('error :: ', error.response);
        notify('warning', 'Warning', error.response && error.response.statusText ? error.response.statusText : 'Error occur.');
      })
  }

  const expired = () => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/deal/' + id + '/transit/' + dealExpiredStatus,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then(response => {
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        console.error('error :: ', error.response);
        notify('warning', 'Warning', error.response && error.response.statusText ? error.response.statusText : 'Error occur.');
      })
  }

  const fetchTasks = async (filter) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/deal/${id}/task`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: filter
      })
      console.log('response :: ', response.data)
      const data = response.data;
      setTasks(data.data.items || []);
    } catch (error) {
      console.error('error :: ', error)
      notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
    }
  }

  const filterTasks = () => {
    fetchTasks(filterOptions)
  }

  const resetTasks = () => {
    setFilterOptions('')
    fetchTasks('')
  }

  const dealContext = {
    id,
    notify,
    setLoading,
    history,
    dispatch,
    profilePermissions,
    csrfToken,
    profileData,
    token, setToken,
    tab, setTab,
    siteCode, setSiteCode,
    authUserTeam, setAuthUserTeam,
    allowEdit, setAllowEdit,
    businessTypes, setBusinessTypes,
    companyTypes, setCompanyTypes,
    contractTypes, setContractTypes,
    dealChecksheet, setDealChecksheet,
    dealStatuses, setDealStatuses,
    dealMileageIntervals, setDealMileageIntervals,
    inspectionDocuments, setInspectionDocuments,
    dealStatusesLabels, setDealStatusesLabels,
    dealTaskStatuses, setDealTaskStatuses,
    dealTracking, setDealTracking,
    dealerMembers, setDealerMembers,
    dealerProfile, setDealerProfile,
    documents, setDocuments,
    documentsOld, setDocumentsOld,
    hasAccessToDocumentModule, setHasAccessToDocumentModule,
    hasPaymentSchedule, setHasPaymentSchedule,
    intervals, setIntervals,
    isAuthorized, setIsAuthorized,
    deal, setDeal,
    paymentIntervals, setPaymentIntervals,
    company, setCompany,
    deliveredStatuses, setDeliveredStatuses,
    defaultedStatus, setDefaultedStatus,
    dealExpiredStatus, setDealExpiredStatus,
    canAccessPaymentsDetails, setCanAccessPaymentsDetails,
    isAbleToGetPayments, setIsAbleToGetPayments,
    customer, setCustomer,
    vehicle, setVehicle,
    checkSheetCount, setCheckSheetCount,
    dealErrors, setDealErrors,
    documentErrors, setDocumentErrors,
    taskErrors, setTaskErrors,
    documentModal, setDocumentModal,
    document, setDocument,
    deleteModal, setDeleteModal,
    fileLists, setFileLists,
    addNoteModal, setAddNoteModal,
    noteDelay, setNoteDelay,
    note, setNote,
    titles, setTitles,
    taskDelay, setTaskDelay,
    task, setTask,
    tasks, setTasks,
    addTaskModal, setAddTaskModal,
    selectedRecurrentTask, setSelectedRecurrentTask,
    editRecurrentTaskModal, setEditRecurrentTaskModal,
    transitModal, setTransitModal,
    filterOptions, setFilterOptions,
    updateDeal,
    sendDocument,
    downloadDocument,
    viewDocument,
    deleteDocument,
    uploadDocument,
    updateMileagePayment,
    formatDate,
    formatDateTask,
    formatDateNote,
    addNote,
    deleteNote,
    addTask,
    taskEdit,
    deleteTask,
    recurrentTaskUpdate,
    deleteRecurrentTask,
    goPayment,
    goTracking,
    getDocument,
    viewSigning,
    checkSignedDocument,
    defaulter,
    expired,
    filterTasks,
    resetTasks
  }

  return <Context.Provider value={dealContext}>{children}</Context.Provider>
}

export const { Consumer } = Context;