import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import NotificationAlert from 'react-notification-alert'
import { Editor } from '@tinymce/tinymce-react'
import axios from 'axios'
import Cookies from 'js-cookie'
import moment from 'moment';

import * as constants from 'constants/index.js'
import CardsHeader from 'layouts/Headers/CardsHeader.js'

import InputCustom from 'views/pages/components/InputCustom'
import TableCustom from 'views/pages/components/TableCustom'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  FormGroup,
} from 'reactstrap'

import {
  create,
  update,
  deletee,
} from 'views/policies/NewsletterPolicy';
import {ButtonContainer} from "@pages/reusable-components/styled-components";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";

export default function Newsletter() {
  const notificationAlert = useRef(null)
  const permissions = useSelector(state => state.getState).permissions

  const [token, setToken] = useState('')
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])
  const [pagination, setPagination] = useState('')
  const [dealers, setDealers] = useState([])

  const [newsletter, setNewsletter] = useState('')
  const [newsletterModal, setNewsletterModal] = useState({
    show: false,
    header: '',
    division: '',
  })
  const [errors, setErrors] = useState('')

  const [deleteModal, setDeleteModal] = useState({
    show: false,
    header: '',
    body: '',
    onClick: null,
  })

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    }
    notificationAlert.current.notificationAlert(options)
  }

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    fetchData(source, 1, grs_token)

    return () => {
      source.cancel()
    }
  }, [])

  const fetchData = async (source, pageNumber, token) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/newsletter`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page: pageNumber,
        },
        cancelToken: source.token,
      })
      console.log('response :: ', response.data)
      const data = response.data
      
      setItems(data.data.items.data)
      setPagination(data.data.items)

      let _dealers = data.data.dealers
      let _dealers_array = []
      _dealers.map(_dealer => {
        return _dealers_array.push({
          ..._dealer,
          value: _dealer.id,
          label: _dealer.name,
        })
      })
      setDealers(_dealers_array)

      setLoading(false)
    } catch (error) {
      setLoading(false)
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
      }
    }
  }

  const createNewsletter = async () => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${constants.API_URL}/newsletter`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: newsletter
      })
      console.log('response :: ', response.data)
      const data = response.data
      
      setItems(data.data.items.data)
      setPagination(data.data.items)
      notify('success', 'Success', data.message)

      setNewsletterModal({
        ...newsletterModal,
        show: false,
      })
    } catch (error) {
      console.error('error :: ', error)
      if (error.response.status === 422) {
        setErrors(error.response.data.errors)
        return
      }
      
      setNewsletterModal({
        ...newsletterModal,
        show: false,
      })
      notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
    }
  }

  const updateNewsletter = async () => {
    try {
      const response = await axios({
        method: 'PUT',
        url: `${constants.API_URL}/newsletter/${newsletter.id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: newsletter
      })
      console.log('response :: ', response.data)
      const data = response.data
      
      setItems(data.data.items.data)
      setPagination(data.data.items)
      notify('success', 'Success', data.message)

      setNewsletterModal({
        ...newsletterModal,
        show: false,
      })
    } catch (error) {
      console.error('error :: ', error)
      if (error.response.status === 422) {
        setErrors(error.response.data.errors)
        return
      }
      
      setNewsletterModal({
        ...newsletterModal,
        show: false,
      })
      notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
    }
  }

  const deleteNewsletter = async (row) => {
    try {
      const response = await axios({
        method: 'DELETE',
        url: `${constants.API_URL}/newsletter/${row.id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      console.log('response :: ', response.data)
      const data = response.data
      
      setItems(data.data.items.data)
      setPagination(data.data.items)
      notify('success', 'Success', data.message)

      setDeleteModal({
        ...deleteModal,
        show: false,
      })
    } catch (error) {
      console.error('error :: ', error)
      setDeleteModal({
        ...deleteModal,
        show: false,
      })
      notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
    }
  }

  return (
    <>
      <CardsHeader name="Newsletters" parentName="Newsletter" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Newsletters</h1>
                    <p className="text-sm mb-0">Keep your customers up to date with newsletters</p>
                  </Col>
                  <Col xs={4} className="text-right">
                    {create(permissions) && 
                    <Button className="w-100" color="success" type="button" onClick={() => {
                      setNewsletter({
                        dealer_id: dealers[0].id
                      })
                      setErrors('')
                      setNewsletterModal({
                        ...newsletterModal,
                        show: true,
                        header: 'Create NewsLetter',
                        division: 'create'
                      })
                    }}>Add new</Button>}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <TableCustom 
                  loading={loading}
                  items={items}
                  pagination={pagination}
                  onChange={pageNumber => fetchData(axios.CancelToken.source(), pageNumber, token)}
                  columns={[
                    {
                      dataField: "subject",
                      text: "Subject"
                    },
                    {
                      dataField: "start_at",
                      text: "Starting Date",
                      formatter: (rowContent, row) => {
                        return constants.formatDateShortGB(row.start_at)
                      }
                    },
                    {
                      dataField: "",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                                {update(permissions, row) &&
                                <IcoMoon
                                    className={'cursor-pointer'}
                                    iconSet={IconSet}
                                    icon={'edit'}
                                    size={24}
                                    onClick={e => {
                                      setNewsletter({
                                        ...row,
                                        start_at: constants.formatDateHoursSeconds(row.start_at)
                                      })
                                      setErrors('')
                                      setNewsletterModal({
                                        ...newsletterModal,
                                        show: true,
                                        header: 'Update newsletter',
                                        division: 'update',
                                      })
                                    }}
                                />
                             }
                                {deletee(permissions, row) &&
                                <IcoMoon
                                    className={'cursor-pointer'}
                                    iconSet={IconSet}
                                    icon={'delete_outline'}
                                    size={24}
                                    onClick={e => setDeleteModal({
                                      ...deleteModal,
                                      show: true,
                                      header: 'Delete newsletter',
                                      body: 'Are you sure you want to delete this newsletter?',
                                      onClick: () => deleteNewsletter(row)
                                    })}
                                />

                             }
                          </>
                        )
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={newsletterModal.show}
          toggle={() => setNewsletterModal({
            ...newsletterModal,
            show: false,
          })}
          size={`lg`}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title main-color">{newsletterModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setNewsletterModal({
                ...newsletterModal,
                show: false,
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <Col md={6} sm={12}>
                <InputCustom 
                  newLabel={`Subject`}
                  value={newsletter && newsletter.subject? newsletter.subject: ''}
                  onChange={e => setNewsletter({
                    ...newsletter,
                    subject: e.target.value,
                  })}
                  invalid={errors && errors.subject? true: false}
                  errorMessage={errors.subject}
                />
              </Col>
              <Col md={6} sm={12}>
                <InputCustom 
                  type={`datetime-local`}
                  newLabel={`Date you want to run the newsletter at`}
                  min={constants.formatDateHoursSeconds(moment())}
                  value={newsletter && newsletter.start_at? newsletter.start_at: ''}
                  onChange={e => setNewsletter({
                    ...newsletter,
                    start_at: e.target.value,
                  })}
                  invalid={errors && errors.start_at? true: false}
                  errorMessage={errors.start_at}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <label className="form-control-label">Dealer</label>
                  <Select
                    options={dealers}
                    value={newsletter && newsletter.dealer_id ? (dealers.find((element) => element.value === newsletter.dealer_id) || '') : ''}
                    onChange={value => setNewsletter({
                      ...newsletter,
                      dealer_id: value.id
                    })}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <InputCustom 
                  type={`textarea`}
                  label={`Notes`}
                  rows={8}
                  value={newsletter && newsletter.notes? newsletter.notes: ''}
                  onChange={e => setNewsletter({
                    ...newsletter,
                    notes: e.target.value,
                  })}
                  invalid={errors && errors.notes? true: false}
                  errorMessage={errors.notes}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Content
                  </label>
                  <Editor
                    value={newsletter && newsletter.content? newsletter.content.content: ''}
                    apiKey={constants.TINYMCE_KEY}
                    init={{
                      height: 500,
                      menubar: true,
                      plugins: ['code', 'preview', 'pagebreak', 'image'],
                      toolbar:
                        'undo redo | formatselect | bold italic | \
                                alignleft aligncenter alignright alignjustify | \
                                outdent indent'
                    }}
                    onEditorChange={(content, editor) => setNewsletter({
                      ...newsletter,
                      content: content
                    })}
                  />
                  <div className="invalid-feedback" style={{display: errors.content? 'block': 'none'}}>
                    {errors.content}
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </div>
          <div className="modal-footer justify-content-end">
            <ButtonContainer

                onClick={() => setNewsletterModal({
                  ...newsletterModal,
                  show: false,
                })}
            >
              {`Close`}
            </ButtonContainer>
            <ButtonContainer
              reverse
              onClick={() => newsletterModal.division === 'create'? createNewsletter() : updateNewsletter()}
            >
              {`Save`}
            </ButtonContainer>

          </div>
        </Modal>
        <Modal
          isOpen={deleteModal.show}
          toggle={() => setDeleteModal({
            ...deleteModal,
            show: false,
          })}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title main-color">{deleteModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setDeleteModal({
                ...deleteModal,
                show: false,
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {deleteModal.body}
          </div>
          <div className="modal-footer">
            <ButtonContainer
                onClick={() => setDeleteModal({
                  ...deleteModal,
                  show: false,
                })}
            >
              {`Close`}
            </ButtonContainer>
            <ButtonContainer
              reverse
              onClick={deleteModal.onClick}
            >
              {`Delete`}
            </ButtonContainer>

          </div>
        </Modal>
      </Container>
    </>
  )
}