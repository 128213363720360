import React, { useState, useEffect } from 'react'
import {Button, Col, Container, Input, Row} from "reactstrap";
import {CalendarInput, Text} from "@pages/reusable-components/styled-components";
import Select from "react-select";
import DatePicker from "react-modern-calendar-datepicker";
import moment from "moment";
import axios from 'axios'
import Cookies from 'js-cookie'
import AsyncSelect from 'react-select/async';
import * as constants from 'constants/index.js'
import CustomLabel from "@pages/components/CustomLabel";
import {validateForm} from "constants/index.js";

export default function AddPaymentPlan({ setOpen, create, location, defaultData }) {
    const [data, setData] = useState({
        customer_id: null,
        amount: '',
        type: 'payment_plan',
        is_debt_collected: 0,
        number_of_payments: '',
        payment_amount: '',
        frequency: '',
        frequency_interval: '',
        payment_method: '',
    })
    const [token, setToken] = useState('');
    const [customers, setCustomers] = useState([]);
    const [selectedData, setSelectedData] = useState(null);
    const [isNotValid, setIsNotValid] = useState(true);
    const [alreadyHave, setAlreadyHave] = useState({
        customer: null,
        amount: null,
    });
    const [paymentIntervals] = useState(['days','weeks','months']);
    const [paymentMethods] = useState([
        {
            key: 'direct_debit',
            label: 'Direct Debit'
        },
        {
            key: 'card',
            label: 'Card Payment'
        },
        {
            key: 'bank_transfer',
            label: 'Bank Transfer'
        }
    ]);
    const [selectedDay, setSelectedDay] = useState(null);
    const [clicked, setClicked] = useState(false);
    const renderCustomInput = ({ ref }) => (
        <CalendarInput
            width={'100%'}
            readOnly
            ref={ref} // necessary
            placeholder="Select date"
            value={selectedDay ? moment({ ...selectedDay, month: selectedDay.month - 1, }).format('DD/MMM/YYYY') : ''}
        />
    )
    useEffect(()=>{
        if (location) {
            setData({...data,type: location})
        }
    },[location])
    useEffect(()=>{
        if (defaultData) {
            setAlreadyHave(defaultData)
        }
    },[defaultData])

    const changeData = (obj) => {
        setData({ ...data, [obj.key]: obj.value })
    }
    const selectDate = (obj) => {
        setData({ ...data, [obj.key]: moment({ ...obj.value, month: obj.value.month - 1, }).format('YYYY-MM-DD') })
    }

    const promiseOptions = inputValue => {
        if (inputValue.length < 2) {
            return
        } else {
            return new Promise(resolve => {
                setTimeout(() => {
                    resolve(filterCustomers(inputValue));
                }, 1000);
            });
        }
    }

    const filterCustomers = inputValue => {
        return customers.filter(i =>
            i.text.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const fetchData = async (reg_no) => {
        try {
            const source = axios.CancelToken.source()
            const response = await axios({
                method: 'GET',
                url: `${constants.API_URL}/customer-payments/create/options`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    reg_no: reg_no,
                    withMedia: 1
                },
                cancelToken: source.token,
            })
            const data = response.data

            let _customers = data.customers || [];
            if (_customers.length > 0) {
                _customers.forEach((item, index) => {
                    item['value'] = item.id;
                    item['label'] = item.text;
                })
            }
            setCustomers(_customers);

        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled :: ', error)
            } else {
                console.error('error :: ', error);
            }
        }
    }
    useEffect(() => {
        const grs_token = Cookies.get('grs_token')
        setToken(grs_token);

        fetchData();
    }, [])


    useEffect(() => {
        const required = alreadyHave ?
              ['number_of_payments','frequency','payment_amount']
            : ['customer_id','amount','number_of_payments','frequency','payment_amount']
        setIsNotValid(!validateForm(required,data));
    }, [data])

    return (

        <Container>

            <Row>
                <Col lg={12}>
                    <Row>
                        <Col lg={6}>
                            <Row>
                                <img height='25px' width='25px'
                                     src={require(`assets/images/taskEdit/person.png`)} alt="" />
                                <Col>
                                    <CustomLabel
                                    text={'Customer Name'}
                                    required={true}
                                    />
                                    <Row className={'pl-3  pr-3'}>
                                        <AsyncSelect
                                            className="w-100"
                                            onMenuOpen={() => {
                                                setClicked(false)
                                            }}
                                            loadOptions={promiseOptions}
                                            isDisabled={alreadyHave.customer}
                                            value = {(selectedData && selectedData?.customer) ? selectedData?.customer : alreadyHave.customer}
                                            onChange={value => {
                                                setSelectedData({...selectedData, customer: value})
                                                setData({
                                                    ...data,
                                                    customer_id: value.id
                                                })
                                            }}
                                            placeholder="Search..."
                                            noOptionsMessage={value => {
                                                if (value.inputValue.length > 1) {
                                                    return "No options"
                                                }
                                                return `Please enter ${2 - value.inputValue.length} or more characters`
                                            }}
                                        />
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={6} sm={12}>
                            <Row>
                                <img height='25px' width='25px'
                                     src={require(`assets/images/loyalty.png`)} alt="" />
                                <Col>
                                    <CustomLabel text={'Amount'} required/>
                                    <Input
                                        value={data.amount ? data.amount : alreadyHave.amount}
                                        disabled={alreadyHave.amount}
                                        type={`number`}
                                        onChange={(e) => changeData({ key: 'amount', value: e.target.value })}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={6}>
                            <Row>
                                <img height='25px' width='25px'
                                     src={require(`assets/images/date_range.png`)} alt="" />
                                <Col>
                                    <CustomLabel text={'Next Payment Due Date:'} required/>
                                    <Row className={'pl-3'} onClick={() => setClicked(true)}>
                                        <DatePicker
                                            value={selectedDay}
                                            wrapperClassName={'w-100 pr-3' + (clicked ? '' : ' withoutIndex' : '') }
                                            onChange={(e) => {
                                                setSelectedDay(e)
                                                selectDate({ key: 'due_date', value: e })
                                            }}
                                            renderInput={renderCustomInput}
                                            shouldHighlightWeekends
                                        />
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={6} sm={12}>
                            <Row>
                                <img height='25px' width='25px'
                                     src={require(`assets/images/date_range.png`)} alt="" />
                                <Col>
                                    <Text bold>Payment Interval:</Text>
                                    <Row className={'pl-3 pr-3'}>
                                        <Select
                                            className={'w-100'}
                                            getOptionLabel={option => option}
                                            getOptionValue={option => option}
                                            options={paymentIntervals}
                                            placeholder={data.frequency_interval}
                                            onChange={(e) => {
                                                setData({
                                                    ...data,
                                                    frequency_interval: e,
                                                })
                                            }}
                                        />

                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col lg={12}>
                    <Row>
                        <Col lg={6} sm={12}>
                            <Row>
                                <img height='25px' width='25px'
                                     src={require(`assets/images/loyalty.png`)} alt="" />
                                <Col>
                                    <CustomLabel text={'No of reoccurring payments'} required/>
                                    <Input
                                        value={data.number_of_payments}
                                        type={`number`}
                                        onChange={(e) => changeData({ key: 'number_of_payments', value: e.target.value })}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={6} sm={12}>
                            <Row>
                                <img height='25px' width='25px'
                                     src={require(`assets/images/loyalty.png`)} alt="" />
                                <Col>
                                    <CustomLabel text={'Payment Amount'} required/>
                                    <Input
                                        value={data.payment_amount}
                                        type={`number`}
                                        onChange={(e) => changeData({ key: 'payment_amount', value: e.target.value })}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={6} sm={12}>
                            <Row>
                                <img height='25px' width='25px'
                                     src={require(`assets/images/loyalty.png`)} alt="" />
                                <Col>
                                    <CustomLabel text={'Payment frequency'} required/>
                                    <Input
                                        value={data.frequency}
                                        type={`number`}
                                        onChange={(e) => changeData({ key: 'frequency', value: e.target.value })}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={6} sm={12}>
                            <Row>
                                <img height='25px' width='25px'
                                     src={require(`assets/images/person_card.png`)} alt="" />
                                <Col>
                                    <Text bold>Payment Method:</Text>
                                    <Row className={'pl-3 pr-3'}>
                                        <Select
                                            className={'w-100'}
                                            getOptionLabel={option => option.label}
                                            getOptionValue={option => option.key}
                                            options={paymentMethods}
                                            placeholder={data.payment_method}
                                            onChange={(e) => {
                                                setData({
                                                    ...data,
                                                    payment_method: e.key,
                                                })
                                            }}
                                        />

                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>

            </Row>

            <Row className={'mt-4 justify-content-end'}>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button disabled={isNotValid} onClick={() => create(data)}>Save</Button>
            </Row>
        </Container>
    )
}