import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import NotificationAlert from 'react-notification-alert'
import axios from 'axios'
import Cookies from 'js-cookie'

import * as constants from 'constants/index.js'
import CardsHeader from 'layouts/Headers/CardsHeader.js'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from 'reactstrap'

export default function EnquiryForm() {
  const { id } = useParams()
  const notificationAlert = useRef(null)
  const history = useHistory()

  const [item, setItem] = useState('')

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    }
    notificationAlert.current.notificationAlert(options)
  }

  useEffect(() => {
     if (id) {
       const grs_token = Cookies.get('grs_token')

       const source = axios.CancelToken.source()

       const fetchData = async () => {
         try {
           const response = await axios({
             method: 'GET',
             url: `${constants.API_URL}/franchise-enquiries/${id}`,
             headers: {
               Authorization: `Bearer ${grs_token}`,
             },
             cancelToken: source.token,
           })
           console.log('response :: ', response.data)
           const data = response.data
           setItem(data.data.item)
         } catch (error) {
           if (axios.isCancel(error)) {
             console.log('Request canceled :: ', error)
           } else {
             console.error('error :: ', error)
             notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
           }
         }
       }

       fetchData()

       return () => {
         source.cancel()
       }
     }
  }, [id])

  const goList = () => {
    history.push({
      pathname: '/admin/franchise-enquiries'
    })
  }

  return (
    <>
      <CardsHeader name="Franchise Enquiries" parentName="Enquiries Management" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
				        <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Enquiries Management</h1>
                  </Col>
                  <Col xs={4} className="text-right">
                    <Button className="w-100" color="success" type="button" onClick={() => goList()}>Back to list</Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={6} sm={12} className="mt-4">
                    <h2 className="mb-3">{`Personal Details`}</h2>
                    <Row className="mb-2">
                      <Col md={5}>
                        <b>Name: </b>
                      </Col>
                      <Col md={7}>
                        {item && `${item.first_name} ${item.last_name}`}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={5}>
                        <b>Email: </b>
                      </Col>
                      <Col md={7}>
                        {item && `${item.email}`}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={5}>
                        <b>Phone: </b>
                      </Col>
                      <Col md={7}>
                        {item && `${item.personal_phone}`}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={5}>
                        <b>Mobile: </b>
                      </Col>
                      <Col md={7}>
                        {item && `${item.mobile_phone}`}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={5}>
                        <b>DOB: </b>
                      </Col>
                      <Col md={7}>
                        {item && `${item.birth_date}`}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={5}>
                        <b>License Number: </b>
                      </Col>
                      <Col md={7}>
                        {item && `${item.license_number}`}
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6} sm={12} className="mt-4">
                    <h2 className="mb-3">{`Address Details`}</h2>
                    <Row className="mb-2">
                      <Col md={5}>
                        <b>Address: </b>
                      </Col>
                      <Col md={7}>
                        {item && `${item.personal_street_address}`}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={5}>
                        <b>Suburb: </b>
                      </Col>
                      <Col md={7}>
                        {item && `${item.personal_suburb}`}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={5}>
                        <b>State: </b>
                      </Col>
                      <Col md={7}>
                        {item && `${item.personal_state.name}`}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={5}>
                        <b>Postcode: </b>
                      </Col>
                      <Col md={7}>
                        {item && `${item.personal_post_code}`}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={5}>
                        <b>Area applying for: </b>
                      </Col>
                      <Col md={7}>
                        {item && `${item.franchise_area}`}
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6} sm={12} className="mt-4">
                    <h2 className="mb-3">{`Business Details`}</h2>
                    <Row className="mb-2">
                      <Col md={5}>
                        <b>Company: </b>
                      </Col>
                      <Col md={7}>
                        {item && `${item.company_name}`}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={5}>
                        <b>Industry: </b>
                      </Col>
                      <Col md={7}>
                        {item && `${item.business_industry  }`}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={5}>
                        <b>Address: </b>
                      </Col>
                      <Col md={7}>
                        {item && `${item.business_street_address}`}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={5}>
                        <b>Suburb: </b>
                      </Col>
                      <Col md={7}>
                        {item && `${item.business_suburb}`}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={5}>
                        <b>State: </b>
                      </Col>
                      <Col md={7}>
                        {item && `${item.business_state.name}`}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={5}>
                        <b>Postcode: </b>
                      </Col>
                      <Col md={7}>
                        {item && `${item.business_post_code}`}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={5}>
                        <b>Phone: </b>
                      </Col>
                      <Col md={7}>
                        {item && `${item.business_phone}`}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={5}>
                        <b>Position: </b>
                      </Col>
                      <Col md={7}>
                        {item && `${item.business_position}`}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={5}>
                        <b>Position From: </b>
                      </Col>
                      <Col md={7}>
                        {item && item.business_from && `${item.business_from}`}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={5}>
                        <b>Position To: </b>
                      </Col>
                      <Col md={7}>
                        {item && item.business_to && `${item.business_to}`}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={5}>
                        <b>Years in Operations: </b>
                      </Col>
                      <Col md={7}>
                        {item && `${item.business_years_in_operation}`}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={5}>
                        <b>Turnover: </b>
                      </Col>
                      <Col md={7}>
                        {item && `${item.business_turnover}`}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={5}>
                        <b>Number Of Staff: </b>
                      </Col>
                      <Col md={7}>
                        {item && `${item.business_no_of_staff}`}
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12} className="mt-4">
                    <h2 className="mb-3">{`More Information`}</h2>
                    <Row className="mb-2">
                      <Col md={5}>
                        <b>Any motor trade experience: </b>
                      </Col>
                      <Col md={7}>
                        {item && item.has_motor_trade_experience? 'Yes': 'No'}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={5}>
                        <b>Will you be seeking finance or investors to purchase the Franchise: </b>
                      </Col>
                      <Col md={7}>
                        {item && item.is_seeking_finance? 'Yes': 'No'}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={5}>
                        <b>Can you commit to a $10,000.00 fully refundable deposit: </b>
                      </Col>
                      <Col md={7}>
                        {item && item.can_commit_deposit? 'Yes': 'No'}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={5}>
                        <b>Have you had any previous history or experience in the Finance Industry: </b>
                      </Col>
                      <Col md={7}>
                        {item && item.has_finance_experience? 'Yes': 'No'}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={5}>
                        <b>Are there any disabilities which may affect your application: </b>
                      </Col>
                      <Col md={7}>
                        {item && item.has_disabilities? 'Yes': 'No'}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={5}>
                        <b>Disability Description: </b>
                      </Col>
                      <Col md={7}>
                        {item && item.disability_description ? item.disability_description : ''}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={5}>
                        <b>Other Experience: </b>
                      </Col>
                      <Col md={7}>
                        {item && item.other_description ? item.other_description : ''}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={5}>
                        <b>Personal Interests: </b>
                      </Col>
                      <Col md={7}>
                        {item && item.personal_interests ? item.personal_interests : ''}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={5}>
                        <b>Business Interests: </b>
                      </Col>
                      <Col md={7}>
                        {item && item.business_interests ? item.business_interests : ''}
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={5}>
                        <b>How did you find about the franchise opportunity: </b>
                      </Col>
                      <Col md={7}>
                        {item && item.lead_source ? item.lead_source : ''}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}