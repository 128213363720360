import React, {useEffect, useRef, useState} from 'react';
import {useHistory, useLocation, useParams} from "react-router-dom";
import axios from "axios";
import * as constants from "../../../../constants";
import {ClipLoader} from "react-spinners";
import {ButtonContainer, override} from "@pages/reusable-components/styled-components";
import Cookies from "js-cookie";
import {Card, CardBody, CardSubtitle, CardText, CardTitle, Col, Container, Row} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import {Notification} from "@pages/components/Notification";

export default function GmailDetails() {

    const { id } = useParams();
    const location = useLocation()
    const history = useHistory()
    const notificationAlert = useRef(null);

    const [subject,setSubject] = useState('')
    const [from,setFrom] = useState('')
    const [text,setText] = useState('')
    const [token, setToken] = useState('');
    const [loading,setLoading] = useState(true)

    useEffect(()=>{

        if (location.state?.email) {
            const grs_token = Cookies.get('grs_token')
            setToken(grs_token)
            axios({
                method: 'GET',
                url: constants.API_URL + `/get-gmail-details/${id}`,
                params: {
                    email: location.state.email,
                },
                headers: {
                    Authorization: 'Bearer ' + grs_token
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        const data = response.data.data
                         data.payload.headers.forEach(item=>{
                            if (item.name === 'Subject') {
                                setSubject(item.value)
                            } else if (item.name === 'From') {
                                setFrom(item.value)
                            }
                        })

                        let parser = new DOMParser();
                        let doc = parser.parseFromString(data.snippet, "text/html");
                        let decodedString = doc.documentElement.textContent;
                        Notification('success', 'Success','',notificationAlert);
                        setText(decodedString)
                        setLoading(false)

                    }
                })
                .catch(() => {
                    Notification('warning', 'Error', 'Something went wrong',notificationAlert);
                    setLoading(false);
                })
        }

    },[id,location])

    const goBack = () => {
        history.goBack()
    }
    return (
       <>
           <div className="rna-wrapper">
               <NotificationAlert ref={notificationAlert} />
           </div>
           {
               loading ?
                   <ClipLoader
                       css={override}
                       size={40}
                       color={`#7B61E4`}
                       loading={loading}
                   /> :
                   <Container>
                       <div className="rna-wrapper">
                           <NotificationAlert ref={notificationAlert} />
                       </div>
                       <Row>
                           <Col className={'p-3'}>
                               <h2>
                                   Gmail
                               </h2>
                           </Col>
                           <Col className={'p-3'}>
                               <ButtonContainer reverse onClick={() => goBack()}>Back to the list</ButtonContainer>
                           </Col>

                       </Row>
                       <Card>
                           <CardBody>
                               <CardTitle>
                                   From - {from}
                               </CardTitle>
                               <CardSubtitle>
                                   {subject}
                               </CardSubtitle>
                               <CardText>
                                   {text}
                               </CardText>
                           </CardBody>
                       </Card>
                   </Container>
           }
       </>

    )
}