import React, {useEffect, useRef, useState} from 'react';

import { FormGroup, Input } from 'reactstrap';
import _uniqueId from 'lodash/uniqueId';

export default function DebounceInput({
                                          type = 'text',
                                          className,
                                          label,
                                          withoutDaley = false,
                                          placeholder,
                                          defaultValue,
                                          value,
                                          min,
                                          max,
                                          rows,
                                          onChange,
                                          disabled,
                                          readOnly,
                                          invalid,
                                          onKeyPress,
                                          clear,
                                          setClear,
                                          style,
                                          column,
                                    }) {
    const inputRef = useRef()
    const [id] = useState(_uniqueId('prefix-'));
    useEffect(()=>{
        if (clear) {
            inputRef.current.value  = ''
            setClear()
        }
    },[clear])

    const searchData = (event) => {
        if (withoutDaley) {
            onChange({target: {value: event}})
        } else {
            if (inputRef.current.typingTimeout) {
                clearTimeout(inputRef.current.typingTimeout);
            }
            inputRef.current = {
                name: event,
                typing: false,
                typingTimeout: setTimeout(() => {
                    onChange({ target: { value: event } });
                }, 700),
            };
        }
    }
    return (
        <FormGroup style={style} className={'d-flex' + (column ? ' flex-column' : ' align-items-center')}>
            <label htmlFor={id} className="form-control-label mr-1 w-50">{label}</label>
            <div className={'w-100'}>
                <Input
                    ref={inputRef}
                    type={type}
                    className={className}
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    value={value}
                    min={min}
                    max={max}
                    rows={rows}
                    disabled={disabled}
                    readOnly={readOnly}
                    onChange={(e)=>searchData(e.target.value)}
                    invalid={invalid}
                    onKeyPress={onKeyPress}
                />
            </div>
        </FormGroup>
    );
}
