import React, { useContext } from 'react';

import { DealersContext } from '../context';

import InputSideCustom from 'views/pages/components/InputSideCustom';

import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import {ButtonContainer} from "@pages/reusable-components/styled-components";

export default function FinancialTab() {
  const dealersContext = useContext(DealersContext);
  const {
    dealerFinancial,
    setDealerFinancial,
    updateFinancial,
    financialErrors,
  } = dealersContext;
  return (
    <Card>
      <CardBody>
        <Card>
          <CardHeader className="text-left pb-0 pt-2">
            <h2 className="title" style={{ color: '#000000' }}>Contract Details</h2>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={6} sm={12}>
                <InputSideCustom 
                  type={`date`}
                  label={`Deposit`}
                  value={dealerFinancial.contract_deposit_at ? dealerFinancial.contract_deposit_at : undefined}
                  onChange={(e) => setDealerFinancial({
                    ...dealerFinancial,
                    contract_deposit_at: e.target.value
                  })}
                  invalid={financialErrors && financialErrors.contract_deposit_at? true: false}
                  errorMessage={financialErrors.contract_deposit_at}
                />
                <InputSideCustom 
                  type={`date`}
                  label={`Commencement`}
                  value={dealerFinancial.contract_starts_at ? dealerFinancial.contract_starts_at : undefined}
                  onChange={(e) => setDealerFinancial({
                    ...dealerFinancial,
                    contract_starts_at: e.target.value
                  })}
                  invalid={financialErrors && financialErrors.contract_starts_at? true: false}
                  errorMessage={financialErrors.contract_starts_at}
                />
              </Col>
              <Col md={6} sm={12}>
                <InputSideCustom 
                  type={`date`}
                  label={`Contract Signed`}
                  value={dealerFinancial.contract_signed_at ? dealerFinancial.contract_signed_at : undefined}
                  onChange={(e) => setDealerFinancial({
                    ...dealerFinancial,
                    contract_signed_at: e.target.value
                  })}
                  invalid={financialErrors && financialErrors.contract_signed_at? true: false}
                  errorMessage={financialErrors.contract_signed_at}
                />
                <InputSideCustom 
                  type={`number`}
                  label={`Terms (Years)`}
                  value={dealerFinancial.term ? dealerFinancial.term : ''}
                  onChange={(e) => setDealerFinancial({
                    ...dealerFinancial,
                    term: e.target.value
                  })}
                  invalid={financialErrors && financialErrors.term? true: false}
                  errorMessage={financialErrors.term}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardHeader className="text-left pb-0 pt-2">
            <h2 className="title" style={{ color: '#000000' }}>Financial Details</h2>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={6} sm={12}>
                <InputSideCustom 
                  type={`number`}
                  label={`Initial Franchise Fee`}
                  value={dealerFinancial.initial_fee ? dealerFinancial.initial_fee : ''}
                  onChange={(e) => setDealerFinancial({
                    ...dealerFinancial,
                    initial_fee: e.target.value
                  })}
                  invalid={financialErrors && financialErrors.initial_fee? true: false}
                  errorMessage={financialErrors.initial_fee}
                />
                <InputSideCustom 
                  type={`number`}
                  label={`Support Levy`}
                  value={dealerFinancial.support_levy ? dealerFinancial.support_levy : ''}
                  onChange={(e) => setDealerFinancial({
                    ...dealerFinancial,
                    support_levy: e.target.value
                  })}
                  invalid={financialErrors && financialErrors.support_levy? true: false}
                  errorMessage={financialErrors.support_levy}
                />
              </Col>
              <Col md={6} sm={12}>
                <InputSideCustom 
                  type={`number`}
                  label={`Franchise Fee`}
                  value={dealerFinancial.monthly_fee ? dealerFinancial.monthly_fee : ''}
                  onChange={(e) => setDealerFinancial({
                    ...dealerFinancial,
                    monthly_fee: e.target.value
                  })}
                  invalid={financialErrors && financialErrors.monthly_fee? true: false}
                  errorMessage={financialErrors.monthly_fee}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardHeader className="text-left pb-0 pt-2">
            <h2 className="title" style={{ color: '#000000' }}>Assignment Fees</h2>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={6} sm={12}>
                <InputSideCustom 
                  type={`number`}
                  label={`Assignment Fee`}
                  value={dealerFinancial.assignment_fee ? dealerFinancial.assignment_fee : ''}
                  onChange={(e) => setDealerFinancial({
                    ...dealerFinancial,
                    assignment_fee: e.target.value
                  })}
                  invalid={financialErrors && financialErrors.assignment_fee? true: false}
                  errorMessage={financialErrors.assignment_fee}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <div className="d-flex flex-row justify-content-end">
          <ButtonContainer reverse onClick={() => updateFinancial()}>Save</ButtonContainer>
        </div>
      </CardBody>
    </Card>
  );
}