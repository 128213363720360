import React, { useContext, useState } from 'react';
import { DealContext } from '../context';

import InputCustom from 'views/pages/components/InputCustom';

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  UncontrolledAlert,
  FormGroup,
  Input,
} from "reactstrap";
import InputToggle from 'views/pages/components/InputToggle';
import TableCustom from "@pages/components/TableCustom";
import moment from "moment";
import CustomTable from "@pages/components/Table/CustomTable";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";
import {ButtonContainer} from "@pages/reusable-components/styled-components";

export default function DocumentTab() {
  const dealContext = useContext(DealContext);
  const {
    allowEdit,
    inspectionDocuments,
    downloadDocument,
    dealerProfile,
    documentsOld,
    documents,
    hasAccessToDocumentModule,
    documentModal, setDocumentModal,
    document, setDocument,
    getDocument,
    checkSignedDocument,
    sendDocument,
  } = dealContext;
  const [keys] = useState([
    {
      key: 'dynamicTitle',
      label: 'Rental Documents',
    },
    {
      key: 'started_at',
      label: 'Time & Date Out',
    },
    {
      key: 'ended_at',
      label: 'Time & Date Back',
    },
    {
      key: 'start_mileage',
      label: 'Start Mileage',
    },
    {
      key: 'download',
      label: 'Download',
    },

  ])
  console.log(documents)
  return (
    <CardBody>
      <Card>
        <CardBody>
          <Row>
            {Object.keys(documentsOld).filter(key => documentsOld[key].is_sendable)
              .map((key, index) => {
                let isEnabled = false;
                let isSent = false;
                let signedDocument = null;
                const { docs } = documentsOld[key];

                if (docs && docs.length > 0) {
                  isSent = true;
                  // signedDocument = docs.find(({ finalized_at }) => finalized_at);

                  signedDocument = docs.reduce((latestDocument, currentDocument) => {
                    if (currentDocument.finalized_at && (!latestDocument || moment(currentDocument.finalized_at, 'DD/MM/YYYY') >= moment(latestDocument.finalized_at, 'DD/MM/YYYY'))) {
                      return currentDocument;
                    }
                    return latestDocument;
                  }, null);
                  isEnabled = !!signedDocument;
                }

                return <Col key={index} className={'border pb-2'} lg={3} md={4} sm={12}>
                <div className={'d-flex'}>
                  <h3>
                      Document name - {' '}
                  </h3>
                  &nbsp;
                  <h3 className={'text-center'}
                      style={{ cursor: "pointer" }}
                      onClick={() => getDocument(documentsOld[key].id)}>
                    {documentsOld[key].document_name}
                  </h3>
                </div>
                 <div className={'d-flex'}>
                   <h4>
                     Document status -
                   </h4>
                   &nbsp;
                   <h4 className={'text-center'}>
                     {isSent ? 'Sent' : 'Not Sent'}
                   </h4>
                 </div>
                  <div className={'d-flex'}>
                    <h4>Action - {' '}</h4> &nbsp;
                      <Button block className={'w-50'} color="success"
                              disabled={!isEnabled}
                              onClick={() => checkSignedDocument(signedDocument.id)}
                              type="button"
                      >{isEnabled ? 'Download' : 'Not signed'}
                      </Button>
                  </div>
                </Col>
              })}

          </Row>
        </CardBody>
      </Card >
      <Card>
        <CardBody>
          {!dealerProfile && <UncontrolledAlert color="info" fade={false}>
            <span className="alert-inner--icon">
              <i className="ni ni-like-2" />
            </span>{" "}
            <span className="alert-inner--text">
              {`Make sure your dealer profile is complete`}<a >{`Check profile`}</a>
            </span>
          </UncontrolledAlert>}
          {hasAccessToDocumentModule ?
            allowEdit && <div className="d-flex flex-row justify-content-left mt-3 mb-5">
              <ButtonContainer reverse className="w-25"  onClick={() => setDocumentModal(true)}>Send document for signing</ButtonContainer>
            </div>
            :
            <h2 className="mt-3">{`You need to pass the Signable API key`}</h2>}
          <CustomTable downloadFile={checkSignedDocument}
                       disabledReason={'finalized_at'}
                       dynamicTitle={'Rental'} keys={keys}
                       tableData={documents.filter(item => !item.is_sendable)} />


        </CardBody>
      </Card>
      <Modal
        isOpen={documentModal}
        toggle={() => setDocumentModal(false)}
        className="modal-dialog-centered"
      >
        <div className="modal-header">
          <h6 className="modal-title">{`Sign document`}</h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setDocumentModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <FormGroup>
            <label className="form-control-label">{`Document type`}</label>
            <Input
              type="select"
              value={document && document.adobe_document_type ? document.adobe_document_type : ''}
              onChange={(e) => setDocument({
                ...document,
                adobe_document_type: e.target.value,
              })}
            >
              <option>-</option>
              {
                Object.keys(documentsOld).map(key => {
                  return <option value={documentsOld[key].id} key={key}>{documentsOld[key].document_name}</option>
                })
              }
            </Input>
          </FormGroup>
          <InputCustom
            label={`Document name`}
            value={document && document.adobe_document_name ? document.adobe_document_name : ''}
            onChange={(e) => setDocument({
              ...document,
              adobe_document_name: e.target.value,
            })}
          />
          <InputCustom
            type={`textarea`}
            label={`Email template`}
            rows={5}
            value={document && document.adobe_email_template ? document.adobe_email_template : ''}
            onChange={(e) => setDocument({
              ...document,
              adobe_email_template: e.target.value,
            })}
          />
          <div className='col-12 col-md-12 col-sm-12'>
            <InputToggle
                label={`Is local signing ?`}
                checked={document && document.is_local_signing === 1}
                onChange={e => {
                  setDocument({
                    ...document,
                    is_local_signing: document.is_local_signing === 1 ? 0 : 1
                  })
                }}
            />
          </div>
        </div>
        <div className="modal-footer">
          <Button
            className="w-100 text-center"
            color="success"
            type="button"
            onClick={() => sendDocument()}
          >
            {`Send`}
          </Button>
        </div>
      </Modal>
      {
        inspectionDocuments?.length ?
          <>
            <h2 className="text-center mt-5">Uploaded pdfs</h2>
            <TableCustom
              loading={false}
              items={inspectionDocuments}
              keyField={`name`}
              searchField={true}
              columns={[
                {
                  dataField: "name",
                  text: "Name",
                  sort: true,
                  formatter: (rowContent, row) => {
                    return `Document - ${row.document.split('/').pop()}`
                  }
                },
                {
                  dataField: "",
                  text: "Action",
                  formatter: (rowContent, row) => {
                    return (
                      <>
                        <IcoMoon
                            className={'cursor-pointer'}
                            iconSet={IconSet}
                            icon={'visibility'}
                            size={24}
                            onClick={() => downloadDocument(row.document)}
                        />
                      </>
                    )
                  }
                }
              ]}
            />
          </> : null
      }
    </CardBody >
  );
}