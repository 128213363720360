import React from 'react';
import styled from "styled-components";
import {
    Media, Row,
} from "reactstrap";


const Text = styled.span`
    font-size: 12px;
    border: 1px solid #e6e6e7;
    padding: 5px;
    margin-left: 5px;
`
const TextInput = styled.input`
    font-size: 12px;
    border: 1px solid #e6e6e7;
    padding: 5px;
    margin-left: 5px;
`

const Container = styled.div`
    border-radius: 4px;
    padding: 20px;
    gap: 34px;
    display: flex;
    flex-direction:${props => props.column ? 'column' : 'row'};
    margin-bottom: 25px;
    @media (max-width: 833px) {
        flex-direction:column
      }
`
export default function VehicleInfo({vehicle, showImage = false,showStockNo=false,setMileage, mileage, showMileageInput = false}) {
    return (
        showImage ?
            <Row>
                <img src={vehicle?.featured_image} width={'200px'} height={'162px'} alt=""/>
                <div className={'d-flex flex-column justify-content-between'}>
                    <Media className="align-items-center">
                        <img height='25px' width='25px' src={require(`assets/images/directions_car_grey.png`)} alt=""/>
                        <Media className="ml-2  d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                     {vehicle.vehicle_make ? vehicle.vehicle_make?.name ? vehicle.vehicle_make?.name : vehicle.vehicle_make : vehicle?.make.name}
                      </span>
                        </Media>
                    </Media>
                    <Media className="align-items-center">
                        <img height='25px' width='25px' src={require(`assets/images/loyalty.png`)} alt=""/>
                        <Media className="ml-2  d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                          {vehicle.vehicle_model ? vehicle.vehicle_model?.name ? vehicle.vehicle_model?.name : vehicle.vehicle_model : vehicle?.model.name}
                      </span>
                        </Media>
                    </Media>
                    <Media className="align-items-center">
                        <img height='25px' width='25px' src={require(`assets/images/vehicle_color.svg`)} alt=""/>
                        <Media className="ml-2  d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                          {vehicle.colour ? vehicle.colour : '-'}
                      </span>
                        </Media>
                    </Media>
                    {
                        showStockNo &&
                        <Media className="align-items-center">
                            <img height='25px' width='25px' src={require(`assets/images/loyalty.png`)} alt=""/>
                            <Media className="ml-2  d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                     {vehicle.stock_no}
                      </span>
                            </Media>
                        </Media>
                    }
                    <Media className="align-items-center">
                        <img height='25px' width='25px' src={require(`assets/images/add_road.png`)} alt=""/>
                        <Media className="ml-2 d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                      Mileage    {
                          showMileageInput ?  <TextInput value={mileage} onChange={(e)=>setMileage(e.target.value)} /> :<Text>{vehicle.current_mileage || vehicle.mileage}</Text>
                      }

                      </span>
                        </Media>
                    </Media>
                </div>
            </Row>
            :
        <Container>

            <Media className="align-items-center">
                <img height='25px' width='25px' src={require(`assets/images/directions_car_grey.png`)} alt=""/>
                <Media className="ml-2  d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                     {vehicle.vehicle_make ? vehicle.vehicle_make?.name ? vehicle.vehicle_make?.name : vehicle.vehicle_make : vehicle?.make.name}
                      </span>
                </Media>
            </Media>
            <Media className="align-items-center">
                <img height='25px' width='25px' src={require(`assets/images/loyalty.png`)} alt=""/>
                <Media className="ml-2  d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                          {vehicle.vehicle_model ? vehicle.vehicle_model?.name ? vehicle.vehicle_model?.name : vehicle.vehicle_model : vehicle?.model.name}
                      </span>
                </Media>
            </Media>
            <Media className="align-items-center">
                <img height='25px' width='25px' src={require(`assets/images/vehicle_color.svg`)} alt=""/>
                <Media className="ml-2  d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                          {vehicle.colour ? vehicle.colour : '-'}
                      </span>
                </Media>
            </Media>
            {
                showStockNo &&
                <Media className="align-items-center">
                    <img height='25px' width='25px' src={require(`assets/images/loyalty.png`)} alt=""/>
                    <Media className="ml-2  d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                     {vehicle.stock_no}
                      </span>
                    </Media>
                </Media>
            }
            <Media className="align-items-center">
                <img height='25px' width='25px' src={require(`assets/images/add_road.png`)} alt=""/>
                <Media className="ml-2 d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                      Mileage    {
                              showMileageInput ?  <TextInput value={mileage} onChange={(e)=>setMileage(e.target.value)} /> :<Text>{vehicle.current_mileage || vehicle.mileage}</Text>
                          }

                      </span>
                </Media>
            </Media>
        </Container>

    )
}