import React from 'react';
import {Text} from "@pages/reusable-components/styled-components";

export default function CustomLabel({
                                        text = '',
                                        required = false,
                                        className= '',
                                        defaultLabel = false

                                    }) {


    return (
        defaultLabel ?  <label className={className}>{text}{required ? <span className={'required-dot'}> *</span> : ''}</label>
                     :  <Text bold>{text}{required ? <span className={'required-dot'}> *</span> : ''}</Text>
    );
}
