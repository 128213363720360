import React from "react";
import {Route, Switch, Redirect} from "react-router-dom";
import AdminNavbar from 'layouts/Navbars/AdminNavbar.js';
import AdminFooter from "layouts/Footers/AdminFooter.js";

import Sidebar_ from "layouts/Sidebar/Sidebar.js";
import Sidebar_PaulAu from "layouts/Sidebar/Sidebar_au.js";

import * as constants from "constants/index";

import routes from "routes.js";
import routes_PaulAu from "routes_au.js";

import SettingsResources from 'views/pages/settings/resources/Index';
import VehicleInStock from 'views/pages/fleet/VehicleInStock';
import CategoryDashBoard from 'views/pages/fleet/CategoryDashBoard';
import LiveVehicleIndex from 'views/pages/liveVehicles/LiveVehicleIndex';
import LiveVehicleCreate from 'views/pages/liveVehicles/LiveVehicleCreate';
import TopMenu from "./TopMenu";
import {connect} from "react-redux";
import {ClipLoader} from "react-spinners";
import {LoaderContainer, override} from "@pages/reusable-components/styled-components";
import {SET_MENUS_LIST,GET_PROFILE_DATA,GET_NOTIFICATIONS,GET_MODULES,SET_SHOW_LOADER} from "@redux/action-types";
import Cookies from "js-cookie";


const RoutesComponents = {
    routes,
    routes_PaulAu
}

const SidebarComponent = {
    Sidebar_,
    Sidebar_PaulAu
}

const Sidebar = constants.SITE_CODE === 'Uk' ? SidebarComponent['Sidebar_'] : SidebarComponent['Sidebar_' + constants.SITE_CODE]
class Admin extends React.Component {
    state = {
        sidenavOpen: true,
        isOpen: false,
        width: window.innerWidth,
        activeMenu: {},
    };
    // componentDidUpdate(e) {
    //     if (e.history.pathname !== e.location.pathname) {
    //         document.documentElement.scrollTop = 0;
    //         document.scrollingElement.scrollTop = 0;
    //         this.refs.mainContent.scrollTop = 0;
    //     }
    // }

    componentWillMount() {
        this.pullAsyncData()
    }
     pullAsyncData  () {
        if (Cookies.get('grs_token')) {
            const grs_token = Cookies.get('grs_token');
            this.props.getProfileData(grs_token)
            this.props.getNotifications(grs_token)
            this.props.getModules(grs_token)
        }
    }

    normalizeUrl(url) {
        return url.replace(/(\d+|:id|:vehicleId|:type|:role|:uuid|:task_category_slug|:customerId|:taskId|:noteId|:dealId|:scheduleId)/g, '');
    }
    componentDidMount() {
        const { pathname } = this.props.location;
        let currenMenuName = null
        currenMenuName = window.localStorage.getItem('menuName')
        const currentRouteItem = RoutesComponents['routes'].find(item => {
            if (item.views) {
                return  item.views.find(view => {
                    return this.normalizeUrl(view.layout + view.path) === this.normalizeUrl(pathname);
                })
            }
            return item?.name?.toLowerCase() === currenMenuName?.toLowerCase()
        })
        if (currentRouteItem !== undefined) {
            this.setState({
                isOpen: currentRouteItem?.name !== 'Dashboard',
                activeMenu: currentRouteItem,
            })
        }

    }
    getRoutes = routes => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return this.getRoutes(prop.views);
            }
            if (prop.layout === "/admin") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        exact
                        component={prop.component}
                        key={key}
                    />
                );
            } else {
                return null;
            }
        });
    };
    getBrandText = () => {
        for (let i = 0; i < routes.length; i++) {
            if (
                this.props.location.pathname.indexOf(
                    routes[i].layout + routes[i].path
                ) !== -1
            ) {
                return routes[i].name;
            }
        }
        return "Brand";
    };
    checkRoutes = (route,authState) => {

        if (route.hasOwnProperty('role')) {
           if (route.role.length) {
               let enable = authState.roles.findIndex(role=>{
                   return  route.role.includes(role)
               })
               return enable > -1
           }
       }   return true
    }
    checkRoute = (routes,userPermission) => {
         return routes?.role?.find(permission => {
            if( permission !== 'hidden') {
                if (userPermission.includes(permission)) {
                    return routes
                }
            }
        });
    }
    toggleSubmenu = (e) => {
        this.props.setMenusList([])
        let rrs = RoutesComponents['routes'].find(item => item.state === Object.keys(e)[0])
        window.localStorage.setItem('menuName',rrs.name)
        if (rrs?.views?.length && window.innerWidth > 833) {
            let path = ''
            if (rrs.name === "Tracking") {
                path =`${rrs?.views[2].layout}${rrs?.views[2].path}`
            } else {
                path=`${rrs?.views[0].layout}${rrs?.views[0].path}`
            }
            this.props.history.push(path)
        }
        this.setState({
            activeMenu: rrs,
            isOpen: true
        });

    }
    toggleSidenav = e => {
        if (document.body.classList.contains("g-sidenav-hidden")) {
            document.body.classList.remove("g-sidenav-hidden");
            document.body.classList.add("g-sidenav-pinned");
        } else {
            document.body.classList.remove("g-sidenav-pinned");
            document.body.classList.add("g-sidenav-hidden");
        }
        this.setState({
            sidenavOpen: !this.state.sidenavOpen
        });
    };
    getNavbarTheme = () => {
        return this.props.location.pathname.indexOf(
            "admin/alternative-dashboard"
        ) === -1
            ? "dark"
            : "light";
    };
    withoutSub = () => {
        window.localStorage.removeItem('menuName')
        this.setState({
            activeMenu: {},
            isOpen: false
        });

    }
    render() {
        return (
           this.props.showLoader ?
                <LoaderContainer>
                    <ClipLoader
                        css={override}
                        size={40}
                        color={`#7B61E4`}
                        loading={this.props.showLoader}
                    />
                </LoaderContainer> :
            <>
                <Sidebar
                    {...this.props}
                    routes={constants.SITE_CODE === 'Uk' ? RoutesComponents['routes'] : RoutesComponents['routes_' + constants.SITE_CODE]}
                    toggleSidenav={this.toggleSidenav}
                    width={this.state.width}
                    clickedMenu={this.state.activeMenu}
                    toggleSubmenu={this.toggleSubmenu}
                    withoutSub={this.withoutSub}
                    sidenavOpen={this.state.sidenavOpen}
                    logo={{
                        innerLink: "/",
                        imgSrc: require("assets/img/RentalHub.png"),
                        imgAlt: "..."
                    }}
                />
                <div
                    className="main-content"
                    ref="mainContent"
                    onClick={this.closeSidenav}
                >
                    {this.state.width < 833 && <AdminNavbar getData={true}
                        {...this.props}
                        logo={{
                            innerLink: "/",
                            imgSrc: require("assets/img/RentalHub.png"),
                            imgAlt: "..."
                        }}
                        isSmall={this.state.width < 833}
                        toggleSidenav={this.toggleSidenav}
                        sidenavOpen={this.state.sidenavOpen}
                        brandText={this.getBrandText(this.props.location.pathname)}
                    />}
                    {/*`${prop.layout}${prop.path}`*/}
                        <TopMenu isSmall={this.state.width < 833 }
                                 show={this.state.isOpen}
                                 routes={this.state.activeMenu.views} menu={this.state.activeMenu}/>
                    <Switch>
                        <Route
                            path="/admin/resource-categories/:slug/docs"
                            exact
                            component={(props) => <SettingsResources {...props} key={window.location.pathname}/>}
                        />
                        <Route
                            path="/admin/fleet-job/inspect/:category"
                            exact
                            component={(props) => <VehicleInStock {...props} key={window.location.pathname}/>}
                        />
                        <Route
                            path="/admin/fleet-job/dashboard/:category"
                            exact
                            component={(props) => <CategoryDashBoard {...props} key={window.location.pathname}/>}
                        />
                        <Route
                            path="/admin/live-vehicle/create"
                            exact
                            component={(props) => <LiveVehicleCreate {...props} key={window.location.pathname}/>}
                        />
                        <Route
                            path="/admin/live-vehicle/:type"
                            exact
                            component={(props) => <LiveVehicleIndex {...props} key={window.location.pathname}/>}
                        />
                        {this.getRoutes(constants.SITE_CODE === 'Uk' ? RoutesComponents['routes'] : RoutesComponents['routes_' + constants.SITE_CODE])}
                        <Redirect from="*" to="/admin/dashboard"/>
                    </Switch>
                    <AdminFooter/>
                </div>
                {!this.state.sidenavOpen ? (
                    <div className="backdrop d-xl-none" onClick={this.toggleSidenav}/>
                ) : null}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        showLoader: state.getState.showLoader,
        authUserTeam: state.getState.authUserTeam,
        routeCan: state.getState.routeCan,
        roles: state.getState.roles,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setMenusList: () => dispatch({ type: SET_MENUS_LIST,data: [] }),
        getProfileData: (token) => dispatch({ type: GET_PROFILE_DATA, token: token}),
        getNotifications: (token) => dispatch({ type: GET_NOTIFICATIONS, token}),
        getModules: (token) => dispatch({ type: GET_MODULES, token}),
        setShowLoader: (condition) => dispatch({ type: SET_SHOW_LOADER, data: condition}),
    }
}
export default connect(
    mapStateToProps,mapDispatchToProps
)(Admin)
