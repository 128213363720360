import React from "react";
import { DragHandle } from "./DragHandle";
import styled from "styled-components";
import {Button} from "reactstrap";
import {parseToWord} from "../../../../constants";

const CustomTd = styled.td`
 color: ${props => props.color} !important;
 font-size: 14px !important;
 font-weight: 600;
`
const StyledStaticTableRow = styled.tr`
  box-shadow: rgb(0 0 0 / 10%) 0px 20px 25px -5px,
    rgb(0 0 0 / 30%) 0px 10px 10px -5px;
  outline: #3e1eb3 solid 1px;
`;

export const StaticTableRow = ({device, index, keys,disable = false}) => {
    const generateDepartmentItem = (value, key, id,keyIndex) => {

        if (key === 'toDetails') {

            return <th key={keyIndex} style={{padding: '5px'}}> <Button outline color="success" >View</Button></th>

        } else if (key === 'Body Shop' || key === 'Valeting' || key === 'Mechanical' || key === 'Miscellaneous' || key === 'Ply') {

            let department = value.departments.find(item=>item.name === key)
            let color = department.status === 'in_review' ? '#FFC231' :
                department.status === 'in_progress' ? '#000' :
                    department.status === 'completed' ? '#00B170' :
                        department.status === 'needed' ? '#FF1E03' : '#000'
            return   <CustomTd key={key + '' + id + value} tabIndex="0" color={color}>
                {parseToWord(department.status_label)}
            </CustomTd>
        } else if (key === 'make' || key === 'model' || key === 'reg_no') {
            return <th  key={key + '' + id} tabIndex="0"> {value.vehicle[key]}</th>
        }
        let val =value.hasOwnProperty(key) ?  value[key] : '-'
        value = parseToWord(val?.toString());
        return <th key={key + '' + id} tabIndex="0"> {value}</th>

    }
    return (
        <StyledStaticTableRow>
                    <th><DragHandle /></th>
                    {
                        keys.map((item, keyIndex) => {
                            return generateDepartmentItem(device, item.key, device?.id, keyIndex)
                        })
                    }
        </StyledStaticTableRow>
    )
}