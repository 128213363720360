const viewAny = (permissions) => {
  return permissions.includes('access-applications')
}

const update = (permissions, leadApplication) => {
  return hasPermission(permissions, 'update-applications')
}

const deletee = (permissions, leadApplication) => {
  return hasPermission(permissions, 'delete-applications')
}

const hasPermission = (permissions, permission) => {
  return permissions.includes(permission)
}

export {
  viewAny,
  update,
  deletee,
  hasPermission,
};