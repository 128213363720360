import styled from "styled-components";
import {css} from "@emotion/core";
import {stringToColor} from "../../../constants";

const TextContainer = styled.div`
  display: flex;
  flex-direction:${props => props.direction ? props.direction : 'column'};  
  align-items: ${props => props.items};
  gap: 10px;
  width: ${props => props.width};
  align-items: ${props => props.center && 'center'};
  padding: ${props => props.padding};
  background-color: ${props => props.background};
  margin-top: ${props => props.marginTop};
  justify-content: ${props => props.justify};
  border-radius: ${props => props.radius};
}
`
const CalendarInput = styled.input`
  cursor: pointer;
  text-align: center;
  width: ${props => props.width ? props.width : '110px'};
  align-items: center;
  border: ${props => props.border ? props.border : '1px solid #dee2e6'};
  gap: 10px;
  font-size: 1rem;
  padding: ${props => props.padding ? props.padding : '10px'};
  border-radius: 4px;
  color: #808087;
  outline: none;
  &:focus {
      border-color: #5e72e4;
  }
`
const TaskDetailsContainer = styled.div`
  display: flex;
  flex-direction:${props => props.direction ? props.direction : 'row'};  
  gap: ${props => props.gap ? props.gap : '15px'}; 
  margin-bottom: ${props => props.noMarginBottom ? '' : '40px'}; 
  margin-left:  ${props => props.noMargin ? '' : props.marginLeft ? props.marginLeft : '15px'};
  margin-top:  ${props => props.marginTop};
  height: ${props => props.height};
  width: ${props => props.width};
  cursor: ${props => props.pointer && 'pointer'};
  float: ${props => props.float};
  @media (max-width: 833px) {
       flex-direction: ${props => props.column && 'column'};
   }
`
const ReportContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${props => props.marginBottom ? '45px' : ''}; 
  justify-content: ${props => props.between && 'space-between'};
  min-height: ${props => props.minHeight && 'calc(100vh - 128px)'}; 
  @media (max-width: 833px) {
       flex-direction: ${props => props.column && 'column'};
   }
`

const Text = styled.span`
 font-weight: ${props => props.bold ? '600' : '400'};
 color: ${props => props.bold ? '#35353E' : props.color ? props.color : '#4E4E56'};
 font-size:  ${props => props.size ? props.size : '16px'};
 max-width: ${props => props.maxWidth};
`
const FirstLetterContainer = styled.div`
 background-color: #B3B3B7;
 border: 1px solid #ffffff;
 border-radius: 50%;
 cursor: pointer;
 height: 25px;
 width: 25px;
 text-align: center;
 color: white;
 margin-left: -6px;
`

const StatusWrapper = styled.span`
 height: fit-content;
 width: ${props => props.width};
 border-radius: 2px;
 padding: 3px 6px;
 background-color: ${props => props.status === 'to_do'
    ? '#f5f5f5' : props.status === 'in_progress'
        ? '#ffbb31' : props.status === 'in_review'
            ? 'rgb(85,214,255,0.2)' : '#247d5f'};
 color:  ${props => props.status === 'to_do'
    ? '#4e4e56' : props.status === 'in_progress'
        ? '#fef7ea' : props.status === 'in_review'
            ? '#007aff' : '#e4f8f0'}
`

const FailedFinishedWrapper = styled.span`
 height: fit-content;
 border-radius: 2px;
 padding: 3px 6px;
 background-color: ${props => props.status === 'completed'
    ? '#e4f8f0' : 'rgba(244,67,54,0.1)'};
 color:  ${props => props.status === 'completed'
    ? '#247d5f' : '#f44336'}
`

const DeliveryTableItem = styled.div`
 border-radius: 3px;
 display: flex;
 position:relative;
 cursor: grab;
 justify-content: space-around;
 align-items: center;
 text-align: center;
 padding: 6px 6px;
 background-color: ${props => stringToColor(props.type)};
 opacity:${props => props.opacity};
 color: white;
`
const DeliveryTableItemDetails = styled.div`
    position: absolute;
    padding: 20px 20px 10px 20px;
    z-index: 2;
    background-color: #ffffff;
    top: 36px;
    right: 0;
    @media (max-width: 1480px) {
     right: -100px;
    }
`
const FilterButtons = styled.button`
 width: 100% !important;
 color: ${props => props.color};
 background-color: ${props => props.background};
 border: 1px solid ${props => props.reverse ? 'white' : '#EC6409'};
 border-radius: 4px;
 padding: 5px 15px;
 cursor: pointer;
`
const CustomTd = styled.td`
 height: 180px;
 width: 13%;
 border: 1px solid #e9ecef;
`

const CustomContainer = styled.div`
 background-color: ${props => props.color};
 text-align: center;
 display: inline-block;
 padding: 3px 5px;
 border-radius: 3px;
`

const CustomTh = styled.th`
 width: 13%;
 text-align: center;
`
const RangeContainer = styled.div`
   border: 1px solid #e6e6e7;
   width: 30px;
   height: 27px;
   border-radius: 4px;
   text-align: -webkit-center;
`

const TimeStartStopContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-left: 82px;
    border: 1px solid #e6e6e7;
    text-align: center;
    justify-content: ${props => props.around && 'space-around'};
    padding: 20px;
    height: 170px;
    gap: 10px;
    float: ${props => props.right && 'right'};
    @media (max-width: 833px) {
      margin-left: 0px;
       height: 230px;
   }
`
const ModalBodyContainer = styled.div`
 display: flex;
 flex-direction: column;
 gap: 22px;
 text-align: center;
 padding-right: 25px;
 padding-bottom: 25px;
 padding-left: 25px;
`
const LoaderContainer = styled.div`
  height: calc(100vh - 1rem);
  display: flex;
  align-items: center;
`
const override = css`
  display: block;
  margin: 0 auto;
  border-color: blue;
`;
const ImagesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 25px;
`
const ImageContainer = styled.div`
    position: relative;
    cursor: pointer;
`
const CloseIcon = styled.img`
    position: absolute;
    cursor: pointer;
`
const ButtonContainer = styled.button`
  background-color: ${props => props.disabled ? 'white' : props.reverse ? '#EC6409' : 'white'};
  margin-right: 20px;
  height: 40px;
  justify-content: center;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid ${props => props.disabled ? 'grey' :  props.reverse ? 'white' : '#EC6409'};
  border-radius: 4px;
  padding: 15px 25px;
  cursor: pointer;
  gap: 10px;
  color: ${props => props.disabled ? 'grey' : props.reverse ? 'white' : '#EC6409'};
  float: right;
   @media (max-width: 833px) {
     font-size: 12px;
    }
`
const UploadButtonContainer = styled.div`
  background-color: ${props => props.background};
  height: 40px;
  padding: 0 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  gap: 10px;
  color: ${props => props.color};
  float: right;
`
const DateContainer = styled.div`
  padding-left: 20px;
  max-width: fit-content;
  display: flex;
  flex-direction: row;
  gap: 16px;
  place-content: center;
  align-items: center;
`
const CalendarSelect = styled.div`
 border: 1px solid #e6e6e7;
 justify-content: space-around;
 border-radius: 4px;
 display: flex;
 padding: 10px;
 font-size: 14px;
 cursor: pointer;
 align-items: center;
 gap: 10px;
`
const Line = styled.span`
 width: 100%;
 border-width: 1px;
 border-style: solid;
 border-color: ${props => props.color ? props.color : '#e6e6e7'};
 color: #e6e6e7;
`
const FilterContainer = styled.div`
     display: flex;
     margin-bottom: 40px;
     gap: ${props => props.gap ? props.gap : '80px'};
      @media (max-width: 833px) {
       gap: 20px;
       width: 100%;
       justify-content: center;
       flex-direction: ${props => props.column && 'column'};
   }
`
const SignContainer = styled.div`
    width: fit-content;
    border: 1px solid #e6e6e7;
`
const List = styled.ul`
  display: flex;
  list-style-type: none;
  float: right;
  flex-direction: row;
  border-bottom: 1px solid lightgray;
  width: 100%;
  cursor: pointer;
`;

const ListItems = styled.li`
  margin-right: 6%;
  color: #9095a4;

  ${(props) =>
    props.activeTab &&
    `
      color: #ec6409;
      ::after {
        content: "";
        position: relative;
        height: 2px;
        margin-top: 0.5rem;
        border-radius: 5px 5px 0 0;
        background-color: #ec6409;
        display: block;
      }
    `}
`;
export {
    Text,
    SignContainer,
    LoaderContainer,
    ReportContainer,
    TaskDetailsContainer,
    CalendarInput,
    TextContainer,
    FirstLetterContainer,
    StatusWrapper,
    RangeContainer,
    FailedFinishedWrapper,
    TimeStartStopContainer,
    ModalBodyContainer,
    override,
    ImagesContainer,
    ImageContainer,
    CloseIcon,
    ButtonContainer,
    CustomTd,
    DeliveryTableItem,
    CustomTh,
    DateContainer,
    UploadButtonContainer,
    CalendarSelect,
    Line,
    FilterContainer,
    DeliveryTableItemDetails,
    FilterButtons,
    CustomContainer,
    List,
    ListItems,
}