import React, {useContext, useEffect} from 'react';

import {DealContext} from '../context';

import actions from "actions/index.js";
import {useDispatch, useSelector} from "react-redux";

export default function Tabs({activateTab}) {
  const dealContext = useContext(DealContext);
  const {
    setTab,
    goPayment,
    goTracking
  } = dealContext;
  const dispatch = useDispatch();
  const {activeMenu} = useSelector((state) => state.getState);
  useEffect(()=>{
    if (activeMenu !== null) {
      if (activeMenu === 'payments') {
        goPayment()
      }
      if (activeMenu === 'tracking') {
        goTracking()
      }
      setTab(activeMenu)
      activateTab(!!activeMenu);
    }
  },[activeMenu])

  useEffect(()=>{
    const tabs = [
      {
        value: 'info',
        key:'Deal Info',
      },
      {
        value: 'documents',
        key:'Documents',
      },
      {
        value: 'uploads',
        key:'Uploads',
      },
      {
        value: 'notes',
        key:'Notes',
      },
      {
        value: 'tasks',
        key:'Tasks',
      },
      {
        value: 'payments',
        key:'Payments',
        action:'goPayment'
      },
      {
        value: 'tracking',
        key:'Vehicle Tracking Location',
        action:'goTracking'
      },
    ]
    dispatch(actions.setMenusList(tabs))
    dispatch(actions.setMenuName('Deals'))
    dispatch(actions.setActiveMenu(activeMenu ? activeMenu : 'info'))
  },[])
  return (
    <>
    </>
  );
}