import React, { useState, useEffect } from 'react'
import { Button, Col, Container, Input, Row, FormGroup } from "reactstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import axios from 'axios'
import Cookies from 'js-cookie'
import AsyncSelect from 'react-select/async';
import * as constants from 'constants/index.js'
import CustomLabel from "@pages/components/CustomLabel";
import {formatDateShortGB, validateForm} from "constants/index.js";

export default function AddBadDebt({ setOpen, create }) {
    const [data, setData] = useState({
        due_date: '',
        customer_id: null,
        amount: '',
        note: '',
        is_debt_collected: 1
    })
    const [token, setToken] = useState('');
    const [customers, setCustomers] = useState([]);

    const [selectedDay, setSelectedDay] = useState(null);
    const [isNotValid, setIsNotValid] = useState(true);


    const changeData = (obj) => {
        setData({ ...data, [obj.key]: obj.value })
    }

    const promiseOptions = inputValue => {
        if (inputValue.length < 2) {
            return
        } else {
            return new Promise(resolve => {
                setTimeout(() => {
                    resolve(filterCustomers(inputValue));
                }, 1000);
            });
        }
    }

    const filterCustomers = inputValue => {
        return customers.filter(i =>
            i.text.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const fetchData = async (reg_no) => {
        try {
            const source = axios.CancelToken.source()
            const response = await axios({
                method: 'GET',
                url: `${constants.API_URL}/customer-payments/create/options`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    reg_no: reg_no,
                    withMedia: 1
                },
                cancelToken: source.token,
            })
            const data = response.data

            let _customers = data.customers || [];
            if (_customers.length > 0) {
                _customers.forEach((item, index) => {
                    item['value'] = item.id;
                    item['label'] = item.text;
                })
            }
            setCustomers(_customers);

        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled :: ', error)
            } else {
                console.error('error :: ', error);
            }
        }
    }

    useEffect(() => {
        const grs_token = Cookies.get('grs_token')
        setToken(grs_token);

        fetchData();
    }, [])

    useEffect(() => {
        const required = ['due_date', 'amount', 'customer_id']
        setIsNotValid(!validateForm(required, data));
    }, [data])
    return (
        <Container>
            <Row>
                <Col lg={12}>
                    <Row>
                        <Col lg={4}>
                            <Row>
                                <img height='25px' width='25px'
                                    src={require(`assets/images/taskEdit/person.png`)} alt="" />
                                <Col>
                                    <CustomLabel text={'Customer Name'} required />
                                    <FormGroup className={'d-flex align-items-center'}>
                                        <AsyncSelect
                                            className="w-75"
                                            loadOptions={promiseOptions}
                                            onChange={value => {
                                                setData({
                                                    ...data,
                                                    customer_id: value.id
                                                })
                                            }}
                                            placeholder="Search..."
                                            noOptionsMessage={value => {
                                                if (value.inputValue.length > 1) {
                                                    return "No options"
                                                }
                                                return `Please enter ${2 - value.inputValue.length} or more characters`
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={4} sm={12}>
                            <Row>
                                <img height='25px' width='25px'
                                    src={require(`assets/images/loyalty.png`)} alt="" />
                                <Col>
                                    <CustomLabel text={'Amount'} required />
                                    <Input
                                        value={data.amount}
                                        type={`number`}
                                        onChange={(e) => changeData({ key: 'amount', value: e.target.value })}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={4}>
                            <Row>
                                <img height='25px' width='25px'
                                    src={require(`assets/images/date_range.png`)} alt="" />
                                <Col className={'signing'}>
                                    <CustomLabel text={'Next Payment Due Date:'} required />
                                        <DatePicker
                                            value={selectedDay ? formatDateShortGB(selectedDay) : ''}
                                            onChange={(e) => {
                                                setSelectedDay(e)
                                                setData({ ...data, due_date: moment(e).format('YYYY-MM-DD') })
                                            }}
                                        />
                                </Col>
                            </Row>
                        </Col>


                    </Row>
                </Col>

            </Row>
            <Row>
                <Col lg={6} className={'mt-4 mb-4'}>
                    <CustomLabel text={'Note'} />
                    <Input
                        value={data.note}
                        placeholder={`Note`}
                        type={`textarea`}
                        rows={8}
                        onChange={(e) => changeData({ key: 'note', value: e.target.value })}
                    />
                </Col>
            </Row>
            <Row className={'mt-4'}>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button disabled={isNotValid} onClick={() => create(data)}>Save</Button>
            </Row>
        </Container>
    )
}