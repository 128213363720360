import React, { useRef, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import NotificationAlert from 'react-notification-alert'
import axios from 'axios'
import Cookies from 'js-cookie'

import * as constants from 'constants/index.js'

import CardsHeader from 'layouts/Headers/CardsHeader.js'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap'
import {ButtonContainer} from "@pages/reusable-components/styled-components";

export default function AccountIntegration() {
  const notificationAlert = useRef(null)
  const location = useLocation()

  const [token, setToken] = useState('')
  const [setQuery] = useState('')

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    if (location.state && location.state.message) {
      notify('warning', 'Warning', location.state.message)
    }
  }, [])

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    }
    notificationAlert.current.notificationAlert(options)
  }

  const integration = async () => {
    const base_url = window.location.origin
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/tracking-authorization`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          redirect_url: `${base_url}/admin/tracking/account-integration-callback`
        }
      })
      console.log('response :: ', response.data)
      const data = response.data

      const query = data.data.query
      document.location.href = `${constants.TRACKING_URL}/oauth/authorize?${query}`

      setQuery(query)
    } catch (error) {
      console.error('error :: ', error)
      notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
    }
  }

  return (
    <>
      <CardsHeader name="3rd Party integration steps" parentName="3rd Party integration steps" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h2 className="mb-0">About Tracking Integration</h2>
                  </Col>
                  <Col xs={4} className="text-right">
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <p style={{color: '#000000', fontSize: 14,fontWeight: 600}}>
                  Application
                  <br />
                  In order to use our stand-alone tracking application you need to authorize the app to handle the devices flow.
                  <br />
                  To get full access to the app you need to either login to your account or register for a new one. The new account will be linked to your CRM dealer profile.
                </p>
                <Row>
                  <Col lg={12}>
                    <ButtonContainer reverse onClick={() => integration()}>{`Click Here`}</ButtonContainer>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}