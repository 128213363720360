import { put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from './actions';
import axios from '../../config/axios';
import * as constants from "../../constants";

function* getPayments(action) {
  try {
    let params = action.data;
    let endpoint = action.url ? `/type/${action.url}` : ''
    const response = yield axios.get(`${constants.API_URL}/customer-payments${endpoint}`, {
      params: params
    });
    if (response?.status === 200) {
      const data = response.data;
      yield put({
        type: actionTypes.GET_PAYMENT_LIST_SUCCESS,
        data
      });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.GET_PAYMENT_LIST_ERROR, payload });
  }

}
function* getPaymentNotes(action) {
  try {
    let id = action.data;
    const response = yield axios.get(`${constants.API_URL}/payment-notes/${id}`);
    if (response?.status === 200) {
      const data = response.data;
      yield put({
        type: actionTypes.GET_PAYMENTS_NOTES_SUCCESS,
        data
      });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.GET_PAYMENTS_NOTES_ERROR, payload });
  }
}

function* getCustomerPaymentNotes(action) {
  try {
    let id = action.data;
    const response = yield axios.get(`${constants.API_URL}/customer/payment-notes/${id}`);
    if (response?.status === 200) {
      const data = response.data;
      yield put({
        type: actionTypes.GET_CUSTOMER_PAYMENTS_NOTES_SUCCESS,
        data
      });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.GET_CUSTOMER_PAYMENTS_NOTES_ERROR, payload });
  }
}
function* getReports(action) {
  try {
    let params = action.data;
    const response = yield axios.get(`${constants.API_URL}/customer-payments/reports/stats`, {
      params: params
    });
    if (response?.status === 200) {
      const data = response.data;
      yield put({
        type: actionTypes.GET_PAYMENT_REPORTS_SUCCESS,
        data
      });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.GET_PAYMENT_REPORTS_ERROR, payload });
  }

}

function* uploadPaymentPlansToGoogleSheet() {
  try {
    const response = yield axios.post(`${constants.API_URL}/upload-payment-plans-to-google`);
    if (response?.status === 200) {
      const data = response.data;
      yield put({
        type: actionTypes.UPLOAD_PAYMENT_PLANS_TO_GOOGLE_SHEETS_SUCCESS,
        data
      });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.UPLOAD_PAYMENT_PLANS_TO_GOOGLE_SHEETS_ERROR, payload });
  }
}

function* uploadMissedPaymentsToGoogleSheet(action) {
  try {
    const response = yield axios.post(`${constants.API_URL}/upload-missed-payments-to-google`);
    if (response?.status === 200) {
      const data = response.data;
      yield put({
        type: actionTypes.UPLOAD_PAYMENT_PLANS_TO_GOOGLE_SHEETS_SUCCESS,
        data
      });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.UPLOAD_PAYMENT_PLANS_TO_GOOGLE_SHEETS_ERROR, payload });
  }
}

function* addPaymentRequest(action) {
  try {
    let data = action.data;
    const response = yield axios.post(`${constants.API_URL}/customer-payments`, data);
    if (response?.status === 200) {
      const { data } = response.data;
      yield put({
        type: actionTypes.ADD_PAYMENT_SUCCESS,
        data
      });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.ADD_PAYMENT_ERROR, payload });
  }
}
function* deletePaymentRequest(action) {
  try {
    let data = action.data;
    const response = yield axios.delete(`${constants.API_URL}/customer-payments/${data.id}`);
    if (response?.status === 200) {
      const { data } = response.data;
      yield put({
        type: actionTypes.DELETE_PAYMENT_SUCCESS,
        data
      });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.DELETE_PAYMENT_ERROR, payload });
  }
}

function* delteAllPayments(action) {
  try {
    let data = action.data;
    const response = yield axios.delete(`${constants.API_URL}/customer-payments/all/${data.id}`);
    if (response?.status === 200) {
      const { data } = response.data;
      yield put({
        type: actionTypes.DELETE_ALL_PAYMENT_SUCCESS,
        data
      });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.DELETE_ALL_PAYMENT_ERROR, payload });
  }
}

function* updatePartialPayment(action) {
  try {
    let data = action.data;
    const response = yield axios.post(`${constants.API_URL}/customer-payments/${data.id}/partial`, {amount: data.amount});
    if (response?.status === 200) {
      const { data } = response.data;
      yield put({
        type: actionTypes.UPDATE_PARTIAL_PAYMENT_SUCCESS,
        data
      });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.UPDATE_PARTIAL_PAYMENT_ERROR, payload });
  }
}

function* updateExpectedPayment(action) {
  try {
    let data = action.data;
    const response = yield axios.post(`${constants.API_URL}/customer-payments/expected`, {data});
    if (response?.status === 200) {
      const { data } = response.data;
      yield put({
        type: actionTypes.UPDATE_EXPECTED_PAYMENT_SUCCESS,
        data
      });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.UPDATE_EXPECTED_PAYMENT_ERROR, payload });
  }
}

function* updatePaymentsGroupRequest(action) {
  try {
    let data = action.data;
    const response = yield axios.post(`${constants.API_URL}/payments/group-status-update`, data);
    if (response?.status === 200) {
      const { data } = response.data;
      yield put({
        type: actionTypes.UPDATE_GROUP_PAYMENTS_STATUS_SUCCESS,
        data
      });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.UPDATE_GROUP_PAYMENTS_STATUS_ERROR, payload });
  }
}
function* updateApprovementDateRequest(action) {
  try {
    let data = action.data;
    const response = yield axios.post(`${constants.API_URL}/payments/update-approvement`, data);
    if (response?.status === 200) {
      const { data } = response.data;
      yield put({
        type: actionTypes.UPDATE_APPRROVEMENT_DATE_SUCCESS,
        data
      });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.UPDATE_APPRROVEMENT_DATE_ERROR, payload });
  }
}
function* getPaymentPlans(action) {
  try {
    const response = yield axios.get(`${constants.API_URL}/customer-payments/plans`,{params: action.data});
    if (response?.status === 200) {
      const  data  = response.data;
      yield put({
        type: actionTypes.GET_PAYMENTS_PLANS_SUCCESS,
        data
      });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.GET_PAYMENTS_PLANS_ERROR, payload });
  }
}

function* updatePaymentRequest(action) {
  try {
    let data = action.data;
    const response = yield axios.put(`${constants.API_URL}/customer-payments/${data.id}`, data);
    if (response?.status === 200) {
      const { data } = response.data;
      yield put({
        type: actionTypes.UPDATE_PAYMENT_SUCCESS,
        data
      });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.UPDATE_PAYMENT_ERROR, payload });
  }
}

function* updatePaymentNotesRequest(action) {
  try {
    let data = action.data;
    const response = yield axios.post(`${constants.API_URL}/payment-notes`, data);
    if (response?.status === 200) {
      yield put({
        type: actionTypes.UPDATE_PAYMENT_NOTES_SUCCESS,
      });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.UPDATE_PAYMENT_NOTES_ERROR, payload });
  }
}

function* getPaymentsCron(action) {
  try {
    let { page, from, to } = action.data;

    const response = yield axios({
      method: 'GET',
      url: `/payments/cron/list?page=${page}&start=${from ?? ''}&to=${to ?? ''}`,
    })

    if (response?.status === 200) {
      const { data } = response.data;
      yield put({
        type: actionTypes.GET_CRON_PAYMENT_LIST_SUCCESS,
        data
      });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.GET_CRON_PAYMENT_LIST_ERROR, payload });
  }
}

function* uploadPaymentsDocument(action) {
  const document = action.payload.document[0];
  try {
    const formData = new FormData();
    formData.append(`document`, document, document.name)

    const response = yield axios({
      method: 'POST',
      url: `${constants.API_URL}/customer-payments/upload`,
      headers: {
        'content-type': 'multipart/form-data',
      },
      data: formData
    });

    yield put({
      type: actionTypes.API_PAYMENTS_SUCCESS,
      data: {
        requestType: action.type,
        ...response.data
      }
    });
  } catch (error) {
    let data = error.response.data;
    yield put({ type: actionTypes.API_PAYMENTS_ERROR, data: { ...data, requestType: action.type } });
  }

}

function* savePaymentsBatch(action) {
  try {
    const response = yield axios({
      method: 'POST',
      url: `${constants.API_URL}/customer-payments/save`,
      data: action.payload.payments
    });

    yield put({
      type: actionTypes.API_PAYMENTS_SUCCESS,
      data: {
        requestType: action.type,
        ...response.data
      }
    });
  } catch (error) {
    let data = error.response.data;
    yield put({
      type: actionTypes.API_PAYMENTS_ERROR, data: {
        ...data,
        requestType: action.type
      }
    });
  }

}

export default function* watcherSaga() {
  yield takeLatest(actionTypes.GET_PAYMENT_LIST_REQUEST, getPayments);
  yield takeLatest(actionTypes.GET_PAYMENT_REPORTS_REQUEST, getReports);
  yield takeLatest(actionTypes.GET_CRON_PAYMENT_LIST_REQUEST, getPaymentsCron);
  yield takeLatest(actionTypes.ADD_PAYMENT_REQUEST, addPaymentRequest);
  yield takeLatest(actionTypes.UPDATE_PAYMENT_REQUEST, updatePaymentRequest);
  yield takeLatest(actionTypes.UPDATE_PAYMENT_NOTES_REQUEST, updatePaymentNotesRequest);
  yield takeLatest(actionTypes.DELETE_PAYMENT_REQUEST, deletePaymentRequest);
  yield takeLatest(actionTypes.UPLOAD_PAYMENT_DOCUMENT_REQUEST, uploadPaymentsDocument);
  yield takeLatest(actionTypes.SAVE_PAYMENTS_REQUEST, savePaymentsBatch);
  yield takeLatest(actionTypes.UPDATE_PARTIAL_PAYMENT_REQUEST, updatePartialPayment);
  yield takeLatest(actionTypes.UPDATE_EXPECTED_PAYMENT_REQUEST, updateExpectedPayment);
  yield takeLatest(actionTypes.GET_PAYMENTS_NOTES_REQUEST, getPaymentNotes);
  yield takeLatest(actionTypes.DELETE_ALL_PAYMENT_REQUEST, delteAllPayments);
  yield takeLatest(actionTypes.UPDATE_GROUP_PAYMENTS_STATUS_REQUEST, updatePaymentsGroupRequest);
  yield takeLatest(actionTypes.UPDATE_APPRROVEMENT_DATE_REQUEST, updateApprovementDateRequest);
  yield takeLatest(actionTypes.GET_PAYMENTS_PLANS_REQUEST, getPaymentPlans);
  yield takeLatest(actionTypes.GET_CUSTOMER_PAYMENTS_NOTES_REQUEST, getCustomerPaymentNotes);
  yield takeLatest(actionTypes.UPLOAD_PAYMENT_PLANS_TO_GOOGLE_SHEETS_REQUEST, uploadPaymentPlansToGoogleSheet);
  yield takeLatest(actionTypes.UPLOAD_MISSED_PAYMENTS_TO_GOOGLE_SHEETS_REQUEST, uploadMissedPaymentsToGoogleSheet);
}
