import React, { useEffect, useMemo, useState } from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import * as actions from '@redux/Payments/actions';
import CardsHeader from 'layouts/Headers/CardsHeader.js';
import useNotify from 'hooks/notify';

import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody, Modal, Button, Input
} from "reactstrap";
import {
    override,
    UploadButtonContainer
} from "@pages/reusable-components/styled-components";
import { ClipLoader } from "react-spinners";
import usePrevious from "../../../hooks/useprevious";
import {
    SelectWithoutInput
} from 'views/pages/components/InputSelect'
import AddPaymentPlan from "@pages/components/payment/AddPaymentPlan";
import {formatDate} from "../../../constants";
import NestedTable from "@pages/components/Table/NestedTable";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";

function getYears() {
    let years = [];
    let currentYear = new Date().getFullYear();
    let startYear = 2022;
    while (startYear <= currentYear) {
        years.push(startYear++);
    }
    return years;
}

const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

const years = getYears();


export default function PaymentPlans() {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const { ref, notify } = useNotify();

    const {
        addedPaymentSuccess,
        addedPaymentError,
        getPaymentsPlansSuccess,
        getPaymentsPlansError,
        updatePaymentNotesSuccess,
        updatePaymentNotesError,
        updatePaymentSuccess,
        updatePaymentError,
        paymentPlans,
        deletePaymentSuccess,
        deletePaymentError,
        deleteAllPaymentSuccess,
        deleteAllPaymentError,
        getCustomerPaymentNotesSuccess,
        getCustomerPaymentNotesError,
        customerPaymentNotes,
        getPaymentsListSuccess,
        getPaymentsListError,
        payments
    } = useSelector((state) => state.paymentState, shallowEqual);

    const addedPaymentSuccessPrev = usePrevious(addedPaymentSuccess)
    const addedPaymentErrorPrev = usePrevious(addedPaymentError)

    const getCustomerPaymentNotesSuccessPrev = usePrevious(getCustomerPaymentNotesSuccess)
    const getCustomerPaymentNotesErrorPrev = usePrevious(getCustomerPaymentNotesError)

    const getPaymentsPlansSuccessPrev = usePrevious(getPaymentsPlansSuccess)
    const getPaymentsPlansErrorPrev = usePrevious(getPaymentsPlansError)

    const deletePaymentSuccessPrev = usePrevious(deletePaymentSuccess)
    const deletePaymentErrorPrev = usePrevious(deletePaymentError)

    const deleteAllPaymentSuccessPrev = usePrevious(deleteAllPaymentSuccess)
    const deleteAllPaymentErrorPrev = usePrevious(deleteAllPaymentError)

    const updatePaymentNotesSuccessPrev = usePrevious(updatePaymentNotesSuccess)
    const updatePaymentNotesErrorPrev = usePrevious(updatePaymentNotesError)

    const updatePaymentSuccessPrev = usePrevious(updatePaymentSuccess)
    const updatePaymentErrorPrev = usePrevious(updatePaymentError)

    const getPaymentsListSuccessPrev = usePrevious(getPaymentsListSuccess)
    const getPaymentsListErrorPrev = usePrevious(getPaymentsListError)

    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState(null)
    const [filter, setFilter] = useState({
        year: null,
        month: null,
        search: null,
    });
    const [showAddPaymentPlan, setShowAddPaymentPlan] = useState(false)
    const [url, setUrl] = useState('')
    const [tableData, setTableData] = useState([]);
    const [deletedNotes, setDeletedNotes] = useState([])
    const [modalLoading, setModalLoading] = useState(false);

    const [isNotesModalOpened, setIsNotesModalOpened] = useState(false);
    const [deleteModal, setDeleteModal] = useState({
        show: false,
        text: '',
        onClick: () => null
    });
    const [parentKeys] = useState([
        { key: 'full_name', label: 'Customer name' },
        { key: 'missed_payments_count', label: 'Number of payments ' },
        { key: 'payments_sum_amount', label: 'Total Owed' },
        { key: 'partials_sum_amount', label: 'Total Part paid' },
        { key: 'show_diff', label: 'Total Remaining' },
        { key: 'openModal', label: 'View notes' },
        { key: 'open_nested', label: 'Action' },
    ])
    const [isNestedOpened, setIsNestedOpened] = useState({})
    const [newNote, setNewNote] = useState('')
    const [selectedNotesData, setSelectedNotesData] = useState([])
    const [selectedCustomerId, setSelectedCustomerId] = useState(null)
    const [deleteId, setDeleteId] = useState(null)
    const [deleteIndex, setDeleteIndex] = useState(null)
    const tableKeys = useMemo(() => {
        let tableKeys = [
         ];
         if (url === 'no_dd_setup') {
            tableKeys.push(
                { key: 'number', label: 'Payment No' },
                { key: 'type', label: 'Type' },
                { key: 'amount', label: 'Amount'},
                { key: 'due_date', label: 'Due date'},
                { key: 'delete', label: 'Action',className: 'red'  },
         )
        } else {
             tableKeys.push(
                 { key: 'number', label: 'Payment No' },
                 { key: 'type', label: 'Type' },
                 { key: url ==='external_payment_plan' ? 'debt_collection_company' : 'payment_method', label:  'Payment Method' },
                 { key: 'amount', label: 'Amount' },
                 { key: 'due_date', label: 'Due date' },
                 { key: 'delete', label: 'Action',className: 'red'  },
             )
         }
        return tableKeys;
    }, [url])



    const toggleDeleteModal = (data) => {
        setDeleteModal(data)
    }
    const toggleNotesModal = () => {
        setIsNotesModalOpened(!isNotesModalOpened)
    }
    const onNotesModalClosed = () => {
        setSelectedNotesData([])
        setDeletedNotes([])
        setSelectedCustomerId(null)
    }

    const updateNotes = () => {
        let data = {
            notes: selectedNotesData,
            deleted: deletedNotes,
            customer_id: selectedCustomerId
        }
        dispatch({
            type: actions.UPDATE_PAYMENT_NOTES_REQUEST,
            data,
        });
        toggleNotesModal()
    }

    const removeNote = (index) => {
        let tempData = [...selectedNotesData]
        let dNote = tempData.splice(index,1);
        if (dNote[0].hasOwnProperty('customer_id')) {
            setDeletedNotes([...deletedNotes,dNote[0].id])
        }
        setSelectedNotesData(tempData)
    }
    const checkDelete = (id,index) => {
        setDeleteId(id)
        setDeleteIndex(index)
        toggleDeleteModal({text: 'You want to delete payment',onClick: () => deletePayment(id),show: true})
    }
    const deleteAll =  (index) => {
        const planId = tableData[index].payments[0].payment_plan_id
        toggleDeleteModal({text: 'You want to delete all payments of this customer',onClick: () => deleteAllPayments(planId),show: true})
    }
    const deleteAllPayments = (id) => {
        let data = {
            id: id
        }
        dispatch({
            type: actions.DELETE_ALL_PAYMENT_REQUEST,
            data,
        });
    }
    const deletePayment = (id) => {
        let data = {
            id: id
        }
        dispatch({
            type: actions.DELETE_PAYMENT_REQUEST,
            data,
        });
    }

    const openNotesModal = (id) => {
        dispatch({
            type: actions.GET_CUSTOMER_PAYMENTS_NOTES_REQUEST,
            data: id,
        });
        setSelectedCustomerId(id)
        toggleNotesModal()
        setModalLoading(true)
    }

     const paginate = (page) => {
         fetchDetails(null,url !== 'payment_plan',page,url);
    }

    const fetchDetails = ( currentFilter = null,noDdSetup = null, page = 1,type = null) => {
        if (noDdSetup) {
            dispatch({
                type: actions.GET_PAYMENT_LIST_REQUEST,
                url: type,
                data: {
                    ...(currentFilter ? currentFilter : filter),
                    page: page
                },
            });
        } else {
            dispatch({
                type: actions.GET_PAYMENTS_PLANS_REQUEST,
                data: {
                    ...(currentFilter ? currentFilter : filter),
                    page: page
                },
            });
        }

    }

    useEffect(() => {
        let tU = location.pathname.split('/')[3]
        //
        switch (tU) {
            case 'no-dd-setup':
                tU = 'no_dd_setup';
                break;
            case 'payment-plans':
                tU = 'payment_plan';
                break;
            case 'repayment-plans':
                tU = 'external_payment_plan';
                break;
            default:
                break;
        }
        setUrl(tU)
        fetchDetails(null,tU !== 'payment_plan',1,tU);
    }, [location]);

    useEffect(() => {
        if (addedPaymentSuccess && addedPaymentSuccessPrev === false) {
            window.location.reload();
        }
    }, [addedPaymentSuccess])

    useEffect(() => {
        if (deletePaymentSuccess && deletePaymentSuccessPrev === false) {
            let newData = tableData.map((item, index) => {
                if (index === deleteIndex) {
                    return {
                        ...item,
                        payments: item.payments.filter(payment => payment.id !== deleteId)
                    };
                }
                return item;
            });
            setTableData(newData)
            setDeleteId(null)
            setDeleteIndex(null)
            setDeleteModal({...deleteModal,show: false})
        }
    }, [deletePaymentSuccess])

    useEffect(() => {
        if (deletePaymentError && !deletePaymentErrorPrev) {
            setDeleteId(null)
            setDeleteIndex(null)
            setDeleteModal({...deleteModal,show: false})
        }
    }, [deletePaymentError])

    useEffect(() => {
        if (deleteAllPaymentSuccess && deleteAllPaymentSuccessPrev === false) {
            setLoading(true)
            fetchDetails(null,url !== 'payment_plan',1,url);
            setDeleteModal({...deleteModal,show: false})
        }
    }, [deleteAllPaymentSuccess])

    useEffect(() => {
        if (deleteAllPaymentError && !deleteAllPaymentErrorPrev) {
            setDeleteModal({...deleteModal,show: false})
        }
    }, [deleteAllPaymentError])

    useEffect(() => {
        if (getCustomerPaymentNotesSuccess && getCustomerPaymentNotesSuccessPrev === false) {
            setModalLoading(false)
            setSelectedNotesData(customerPaymentNotes)
        }
    }, [getCustomerPaymentNotesSuccess,customerPaymentNotes])
    useEffect(() => {
        if (getCustomerPaymentNotesError && getCustomerPaymentNotesErrorPrev === false) {
            toggleNotesModal()
            setModalLoading(false)
        }
    }, [getCustomerPaymentNotesError])

    useEffect(() => {
        if (addedPaymentError && !addedPaymentErrorPrev) {
            notify('Error', 'Something went wrong')
            setLoading(false)
        }
    }, [addedPaymentError])

    useEffect(() => {
        if (getPaymentsPlansSuccess && getPaymentsPlansSuccessPrev === false) {
            let nestedTablesAction = {}
            const tempData = paymentPlans.data
            tempData.map(item=>{
                nestedTablesAction[item.id] = false
            })
            setIsNestedOpened(nestedTablesAction)
            setTableData(tempData)
            setPagination(paymentPlans)
            setLoading(false)
        }
    }, [getPaymentsPlansSuccess])

    useEffect(() => {
        if (getPaymentsPlansError && !getPaymentsPlansErrorPrev) {
            notify('Error', 'Something went wrong')
            setLoading(false)
        }
    }, [getPaymentsPlansError])

    useEffect(() => {
        if (updatePaymentNotesSuccess && updatePaymentNotesSuccessPrev === false) {
            window.location.reload();
        }
    }, [updatePaymentNotesSuccess])

    useEffect(() => {
        if (updatePaymentNotesError && !updatePaymentNotesErrorPrev) {
            notify('Error', 'Something went wrong')
        }
    }, [updatePaymentNotesError])


    useEffect(() => {
        if (updatePaymentSuccess && updatePaymentSuccessPrev === false) {
            fetchDetails(null,url !== 'payment_plan',1,url);

        }
    }, [updatePaymentSuccess])

    useEffect(() => {
        if (updatePaymentError && !updatePaymentErrorPrev) {
            notify('Error', 'Something went wrong')
        }
    }, [updatePaymentError])

    useEffect(() => {
        if (getPaymentsListSuccess && getPaymentsListSuccessPrev === false) {
            let tempData = payments.data.data
                let nestedTablesAction = {}
                tempData.forEach(item=>{
                    nestedTablesAction[item.id] = false
                })
                setIsNestedOpened(nestedTablesAction)
                setTableData(tempData)
            setPagination(payments.data)
            setLoading(false)
        }
    }, [getPaymentsListSuccess])

    useEffect(() => {
        if (getPaymentsListError && !getPaymentsListErrorPrev) {
            notify('Error', 'Something went wrong')
            setLoading(false)
        }
    }, [getPaymentsListError])

    const savePaymentPlan  = async (data) => {
        setLoading(true)
        dispatch({
            type: actions.ADD_PAYMENT_REQUEST,
            data: data
        });
        setShowAddPaymentPlan(false)
    }

    const addNewNote = () => {
        let tempData = [...selectedNotesData]
        tempData.push({
            note: newNote,
            status: 'new'
        })
        setSelectedNotesData(tempData)
        setNewNote('')
    }


    return (
        loading ?
            <ClipLoader
                css={override}
                size={40}
                color={`#7B61E4`}
                loading={loading}
            />
            :
            <>

                <Modal
                    isOpen={deleteModal.show}
                    toggle={() =>  toggleDeleteModal({text: '',onClick: () =>null,show: false})}
                    className="disable-scroll modal-dialog-centered modal-md"
                >
                    <div className="modal-header">
                        <h3>{`Are you sure ?`}</h3>
                    </div>
                    <div className="modal-body">
                        <h4>{deleteModal.text}</h4>
                    </div>
                    <div className="modal-footer mb-3">
                        <Button
                            className="ml-auto"
                            color="danger"
                            type="button"
                            onClick={() => {
                                setDeleteId(null)
                                toggleDeleteModal({text: '',onClick: () =>null,show: false})
                            }}
                        >
                            {`Close`}
                        </Button>
                        <Button
                            className="new-event--add"
                            color="success"
                            type="button"
                            onClick={deleteModal.onClick}
                        >
                            {`Save`}
                        </Button>
                    </div>
                </Modal>

                <Modal
                    isOpen={isNotesModalOpened}
                    onClosed={onNotesModalClosed}
                    toggle={() => toggleNotesModal()}
                    className="modal-dialog-centered modal-lg"
                >
                    <div className="modal-header">
                        <h3>{`Notes for payment`}</h3>
                    </div>
                    <div className="modal-body">
                        {  modalLoading ?    <ClipLoader
                                css={override}
                                size={40}
                                color={`#7B61E4`}
                                loading={modalLoading}
                            /> :
                            <Row>
                                <Col lg={4}>
                                    <Col>
                                        <Input
                                            value={newNote}
                                            placeholder={'write a note'}
                                            onChange={e => setNewNote(e.target.value)}
                                        />
                                        <Button className={'mt-2 mb-2'} onClick={addNewNote}>
                                            Add Note
                                        </Button>
                                        <Button
                                            className="ml-auto"
                                            color="danger"
                                            type="button"
                                            onClick={() => {
                                                toggleNotesModal()
                                            }}
                                        >
                                            {`Close`}
                                        </Button>
                                        <Button
                                            className="new-event--add"
                                            color="success"
                                            type="button"
                                            onClick={updateNotes}
                                        >
                                            {`Save`}
                                        </Button>
                                    </Col>
                                </Col>
                                <Col className={'overflow-auto'} lg={8}>
                                    {selectedNotesData.length ?
                                        <table className="table table-bordered">
                                            <thead>
                                            <tr className={'reports-table-header'}>
                                                <th className={'header-text'}>
                                                    Date
                                                </th>
                                                <th className={'header-text'}>
                                                    Note
                                                </th>

                                                <th className={'header-text'}>
                                                    Action
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                selectedNotesData.map((note, index) => {
                                                    return <tr key={index}>
                                                        <td>
                                                            <h4>{note.hasOwnProperty('created_at') ? formatDate(note?.created_at) : 'Now'}</h4>
                                                        </td>
                                                        <td>
                                                            <h4>{note?.note}</h4>
                                                        </td>
                                                        <td>
                                                            <IcoMoon
                                                                className={'cursor-pointer'}
                                                                iconSet={IconSet}
                                                                icon={'delete_outline'}
                                                                size={24}
                                                                onClick={() => removeNote(index)}
                                                            />
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table> : null}
                                </Col>
                            </Row>}

                    </div>
                    <div className="modal-footer mb-3">

                    </div>
                </Modal>
                <CardsHeader name="Payments Management" parentName="Payments Management" currentName="List" />
                <div className="rna-wrapper">
                    <NotificationAlert ref={ref} />
                </div>
                { showAddPaymentPlan ?
                            <AddPaymentPlan
                                location={url}
                                create={savePaymentPlan}
                                setOpen={setShowAddPaymentPlan}
                            />
                            : <Container className="mt--5 admin-main-body" fluid >
                                <Row>
                                    <Col xl="12">
                                        <Card>
                                            <CardHeader className={'border-bottom-0'}>
                                                <Row>

                                                </Row>
                                                {/*<Filter fetchData={fetchData}/>*/}
                                            </CardHeader>
                                            <CardBody>
                                                {url === 'payment_plan' ?  <Row className={'justify-content-end mb-2'}>
                                                    <Col md={4} lg={3} sm={8} className={'mb-2'}>
                                                        <UploadButtonContainer background={'#70ad47'} color={'white'}
                                                                               onClick={() => setShowAddPaymentPlan(true)}>
                                                            <i className="fa fa-plus" aria-hidden="true"></i>
                                                            <span className={'button-text-responsive'}>
                                                                    Add Payment Plan
                                                                </span>
                                                        </UploadButtonContainer>
                                                    </Col>
                                                </Row> :
                                                url === 'no_dd_setup' ?
                                                    <Row className={'justify-content-end mb-2'}>
                                                        <Col md={4} lg={3} sm={8} className={'mb-2'}>
                                                            <UploadButtonContainer background={'#70ad47'} color={'white'} onClick={() => setShowAddPaymentPlan(true)}>
                                                                <i className="fa fa-plus" aria-hidden="true"></i>
                                                                <span className={'button-text-responsive'}>
                                                                    Add no dd setup
                                                                </span>
                                                            </UploadButtonContainer>
                                                        </Col>
                                                    </Row>
                                                    : null
                                              }

                                                <Row className={'flex-wrap'}>
                                                    <Col lg={2} md={3}>
                                                        <Input
                                                            placeholder="Search..."
                                                            value={filter.search ?? ''}
                                                            type={`text`}
                                                            onChange={(e) => setFilter({ ...filter, search: e.target.value })}
                                                        />
                                                    </Col  >
                                                    <Col md={3} lg={2}>
                                                        <SelectWithoutInput
                                                            label={filter.month !== null ? months[filter.month] : 'Select month'}
                                                            onSelect={e => {
                                                                let month = months.findIndex(item => item === e);
                                                                setFilter({ ...filter, month })
                                                            }}
                                                            fullWidth={window.innerWidth < 833}
                                                            options={months}
                                                            defaultOption={true}
                                                        />
                                                    </Col>
                                                    <Col md={3} lg={2} className={'mb-sm-2'}>
                                                        <SelectWithoutInput
                                                            label={filter.year ? filter.year : 'Select year'}
                                                            onSelect={e => {
                                                                setFilter({ ...filter, year: e })
                                                            }}
                                                            fullWidth={window.innerWidth < 833}
                                                            options={years}
                                                            defaultOption={true}
                                                        />
                                                    </Col>
                                                    <Col lg={1} md={2} className={'mb-sm-2'}>
                                                        <Button
                                                            className="ml-3"
                                                            type="button"
                                                            onClick={() => {
                                                                setFilter({ ...filter, month: null, year: null, search: null });
                                                            }}
                                                        >
                                                            Clear
                                                        </Button>
                                                    </Col>
                                                    <Col md={2} lg={1}>
                                                        <Button
                                                            className="ml-3"
                                                            onClick={() => {
                                                                fetchDetails(filter,url !== 'payment_plan',1,url)
                                                            }}
                                                        >
                                                            Search
                                                        </Button>
                                                    </Col>

                                                </Row>

                                            </CardBody>
                                            <CardBody>
                                                  <NestedTable
                                                      parentKeys={parentKeys}
                                                      keys={tableKeys}
                                                      tableData={tableData}
                                                      deleteAll={deleteAll}
                                                      removeAll={true}
                                                      pagination={pagination}
                                                      paginate={paginate}
                                                      openModal={openNotesModal}
                                                      isNestedOpened={isNestedOpened}
                                                      setIsNestedOpened={(id) => {
                                                          setIsNestedOpened({
                                                              ...isNestedOpened,
                                                              [id]: !isNestedOpened[id]
                                                          })
                                                      }}
                                                      deleteRow={checkDelete}
                                                      ownerDetails={(id) => {
                                                          history.push({
                                                              pathname: `/admin/customer/${id}/edit`,
                                                          })
                                                      }}
                                                      nestedTableDataKey={'payments'}
                                                  />

                                            </CardBody>

                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                }
            </>
    )
}