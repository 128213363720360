import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import CardsHeader from "layouts/Headers/CardsHeader.js";
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import TableCustom from "views/pages/components/TableCustom";
import * as personalisationActions from '../../../../redux/Personalisation/actions';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import {ButtonContainer} from "@pages/reusable-components/styled-components";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";

export default function AutomatedTasks() {
  const notificationAlert = useRef(null);
  const personalisationState = useSelector((state) => state.personalisationState, shallowEqual);
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);

  useEffect(() => {
    dispatch({ type: personalisationActions.TASK_PERSONALISATION_LIST_REQUEST });
  }, []);

  useEffect(() => {

    setItems(personalisationState.rols);

    setTimeout(() => {
      setLoading(false);
    }, 300);

  }, [personalisationState]);

  const goList = () => {
    history.push({
      pathname: `/admin/settings/personalisation`,
    });
  };

  const showDetail = (row) => {
    history.push({
      pathname: `/admin/settings/personalisation/tasks/${row.id}/edit`,
    });
  }

  return (
    <>
      <CardsHeader
        name="Settings"
        parentName="Personalisation"
        currentName="Roles list"
      />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={6}>
                    <h2 className="mb-0">Automated Tasks Roles</h2>
                  </Col>
                  <Col xs={6} className="text-right">
                    <ButtonContainer
                      reverse
                      width={'300px'}
                      onClick={() => goList()}
                    >
                      Back to personalisation
                    </ButtonContainer>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <TableCustom
                  loading={loading}
                  items={items}
                  columns={[
                    {
                      dataField: "name",
                      text: "Name",
                      sort: true,
                    },
                    {
                      dataField: "display_name",
                      text: "Display name",
                      sort: true,
                    },
                    {
                      dataField: "description",
                      text: "Description",
                      sort: true,
                    },
                    {
                      dataField: "",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                            <IcoMoon
                                className={'cursor-pointer'}
                                iconSet={IconSet}
                                icon={'visibility'}
                                size={24}
                                onClick={() => {
                                  showDetail(row);
                                }}
                            />
                        );
                      },
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
