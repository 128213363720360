import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import Cookies from "js-cookie";
import * as dealsActions from '@redux/Deal/actions'
import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import InputCustom from 'views/pages/components/InputCustom';
import InputSideCustom from 'views/pages/components/InputSideCustom';
import {
  InputKeySelect,
  InputArraySelect,
  InputKeySideSelect
} from 'views/pages/components/InputSelect';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
} from "reactstrap";
import {ButtonContainer, Line} from "@pages/reusable-components/styled-components";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import usePrevious from "../../../hooks/useprevious";

const t = new Date();
const year = Intl.DateTimeFormat('en-GB', { year: 'numeric' }).format(t);
const month = Intl.DateTimeFormat('en-GB', { month: '2-digit' }).format(t);
const day = Intl.DateTimeFormat('en-GB', { day: '2-digit' }).format(t);
const today = `${year}-${month}-${day}`;

export default function DealCreate() {
  const notificationAlert = useRef(null);
  const history = useHistory();

  const [token, setToken] = useState('');

  const [businessTypes, setBusinessTypes] = useState([]);
  const [companyTypes, setCompanyTypes] = useState([]);
  const dispatch = useDispatch();
  const { businessActivities,
          getBusinessActivitiesSuccess,
    getCompanyTypesSuccess,
    companiesTypes
  } = useSelector((state) => state.dealState, shallowEqual);
  const [customer, setCustomer] = useState('');
  const [customers, setCustomers] = useState([]);

  const [regeneratedFrom] = useState(false);
  const [vehicle, setVehicle] = useState('');
  const getBusinessActivitiesSuccessPrev = usePrevious(getBusinessActivitiesSuccess)
  const getCompanyTypesSuccessPrev = usePrevious(getCompanyTypesSuccess)
  const [vehicles, setVehicles] = useState('');

  const [filterOptions, setFilterOptions] = useState({
    customerId: '',
    vehicleId: '',
  });

  const [division, setDivision] = useState(true);

  const [monthly, setMonthly] = useState({payment_frequency_interval: 'week'});
  const [daily, setDaily] = useState('');

  const [annualMileage, setAnnualMileage] = useState('');
  const [dealStatus, setDealStatus] = useState('');
  const [dealDate, setDealDate] = useState(today);

  const [errors, setErrors] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    fetchData(source, grs_token, '')

    return () => {
      source.cancel()
    }
  }, [])

  useEffect(()=>{
    if (getBusinessActivitiesSuccess && !getBusinessActivitiesSuccessPrev) {
      setBusinessTypes(businessActivities)
    }
  },[getBusinessActivitiesSuccess,getBusinessActivitiesSuccessPrev])

  useEffect(()=>{
    if (getCompanyTypesSuccess && !getCompanyTypesSuccessPrev) {
      setCompanyTypes(companiesTypes)
    }
  },[getCompanyTypesSuccess,getCompanyTypesSuccessPrev])

  const fetchData = async (source, token, filter) => {
    try {

      await dispatch({type: dealsActions.GET_BUSINESS_ACTIVITY_REQUEST})
      await dispatch({type: dealsActions.GET_COMPANY_TYPE_REQUEST})

      const dealVehicles = await axios({
        method: 'GET',
        url: `${constants.API_URL}/deal/vehicles`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: filter,
        cancelToken: source.token,
      })
      const data = dealVehicles.data
      const dealCustomers = await axios({
        method: 'GET',
        url: `${constants.API_URL}/deal/customers`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: filter,
        cancelToken: source.token,
      })
      const customersData = dealCustomers.data.data
      let _customers = customersData || [];
      if (_customers.length > 0) {
        _customers.forEach((item, index) => {
          item['value'] = item.id;
          item['label'] = item.full_name;
        })
      }
      setCustomers(_customers);
      setVehicle(data.data);
      let _vehicles = data.data
      let _vehicles_array = [];
      Object.keys(_vehicles).map(key => {
        return _vehicles_array.push({
          ..._vehicles[key],
          value: _vehicles[key].id,
          label: _vehicles[key].stock_no,
        })
      })
      setVehicles(_vehicles_array);

      setMonthly({
        ...monthly,
        vehicle_monthly_cost: data.data.vehicle && data.data.vehicle.monthly_cost ? data.data.vehicle.monthly_cost : 0,
        vehicle_monthly_settled_interval: 'month',
        payment_frequency: 4,
        payment_frequency_interval: 'weeks',
        monthly_payment: data.data.vehicle && data.data.vehicle.estimated_term_price ? data.data.vehicle.estimated_term_price : 0,
        interval: 'month',
        initial_payment: data.data.vehicle && data.data.vehicle.estimated_initial_payment ? data.data.vehicle.estimated_initial_payment : 0,
      })

      setDaily({
        ...daily,
        vehicle_monthly_cost: data.data.vehicle && data.data.vehicle.monthly_cost ? Math.ceil(data.data.vehicle.monthly_cost * 12 / 365) : 0,
        vehicle_monthly_settled_interval: 'day',
        monthly_payment: data.data.vehicle && data.data.vehicle.estimated_term_price ? data.data.vehicle.estimated_term_price : 0,
        interval: 'day',
        initial_payment: data.data.vehicle && data.data.vehicle.estimated_initial_payment ? data.data.vehicle.estimated_initial_payment : 0,
      })
    } catch (error) {
      if (axios.isCancel(error)) {
      } else {
        notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
      }
    }
  }

  const goList = () => {
    history.push({
      pathname: '/admin/deals',
    })
  }
  const validate = () => {
    return (
           !customer?.company?.address || !monthly.term || !monthly.end_payment || !dealStatus
           || !vehicle.colour || !vehicle.stock_no || !vehicle.derivative || !vehicle.current_mileage
           || !customer.company.business_activity_id || !customer.company.company_type_id
           || !customer.company.name  || !customer.company.phone_number || !customer.postal_code
           || !customer.address
       )
  }
  const load = async () => {
    if (filterOptions.customerId === '') {
      notify('info', 'Information', 'Please select a customer');
      return
    }
    if (filterOptions.vehicleId === '') {
      notify('info', 'Information', 'Please select a vehicle');
      return
    }

    const source = axios.CancelToken.source()
    const resources = await axios({
      method: 'GET',
      url: `${constants.API_URL}/deal/load-resources`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: filterOptions,
      cancelToken: source.token,
    })
    setCustomer(resources.data.data.customer)
    setVehicle(resources.data.data.vehicle)
  }

  const reset = () => {
    setFilterOptions({
      customerId: '',
      vehicleId: '',
    })

    const source = axios.CancelToken.source()
    fetchData(source, token, '')
  }

  useEffect(() => {
    let InitialCost = vehicle && vehicle.monthly_cost ? vehicle.monthly_cost : 0;
    let YearSettled = monthly.term ? monthly.term : 0;
    let CostUs = monthly.vehicle_monthly_cost ? monthly.vehicle_monthly_cost : 0;
    let R2BTotalCost = Number(CostUs) * Number(YearSettled);
    let maintenanceCost = monthly.insurance_interval ? monthly.insurance_interval : 0;
    let RentalSoldAt = monthly.monthly_payment ? monthly.monthly_payment : 0;
    let RentalTerm = monthly.no_of_payments ? monthly.no_of_payments : 0;
    let InitalPayment = monthly.initial_payment ? monthly.initial_payment : 0;
    let EndPayment = monthly.end_payment ? monthly.end_payment : 0;
    let TotalRentalReceived = Number(RentalSoldAt) * Number(RentalTerm) + Number(InitalPayment) + Number(EndPayment);
    let RentalProfit = Number(TotalRentalReceived) - Number(R2BTotalCost);

    let monthlyMaintenanceCost = 0;

    if (Number(RentalTerm) > 0 && YearSettled && maintenanceCost) {
      monthlyMaintenanceCost = (maintenanceCost * Number(YearSettled)) / Number(RentalTerm);
      monthlyMaintenanceCost = Math.round(monthlyMaintenanceCost * 100) / 100;
    }

    setMonthly({
      ...monthly,
      vehicle_monthly_cost: Number(InitialCost) + Number(monthlyMaintenanceCost),
      vehicle_total_estimated_cost: Math.round(R2BTotalCost * 100) / 100,
      weekly_payment: Number(RentalSoldAt) / Number(4),
      rental_received: Math.round(TotalRentalReceived * 100) / 100,
      expected_profit: Math.round(RentalProfit * 100) / 100,
    })
  }, [
    monthly.vehicle_monthly_cost,
    monthly.insurance_interval,
    monthly.monthly_payment,
    monthly.no_of_payments,
    monthly.term,
    monthly.initial_payment,
    monthly.end_payment,
  ])

  useEffect(() => {
    var InitialCost = vehicle && vehicle.monthly_cost ? Math.ceil(vehicle.monthly_cost * 12 / 365) : 0;
    let CostUs = daily.vehicle_monthly_cost ? daily.vehicle_monthly_cost : 0;
    let RentalTerm = daily.term ? daily.term : 0;
    let DaysSettled = RentalTerm;
    let R2BTotalCost = Number(CostUs) * Number(DaysSettled);
    var Insurance = daily.insurance_interval ? daily.insurance_interval : 0;
    var RentalSoldAt = daily.monthly_payment ? daily.monthly_payment : 0;
    let TotalRentalReceived = Number(RentalSoldAt) * Number(RentalTerm);
    let RentalProfit = Number(TotalRentalReceived) - Number(R2BTotalCost);

    setDaily({
      ...daily,
      vehicle_total_estimated_cost: Math.round(R2BTotalCost * 100) / 100,
      vehicle_monthly_cost: Number(InitialCost) + Number(Insurance),
      rental_received: Math.round(TotalRentalReceived * 100) / 100,
      expected_profit: Math.round(RentalProfit * 100) / 100,
    })
  }, [
    daily.vehicle_monthly_cost,
    daily.term,
    daily.insurance_interval,
    daily.monthly_payment,
  ])

  const createDeal = () => {
    if (!customer.id || !vehicle.id) {
      notify('info', 'Information', 'Please load customer and vehicle.');
      return
    }
    axios({
      method: 'POST',
      url: constants.API_URL + '/deal',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        regeneratedFrom: regeneratedFrom,
        rental_calculator: division ? 'monthly_rental_calculator' : 'daily_rental_calculator',
        deal_date: dealDate,
        deal_status: dealStatus ? dealStatus : constants.dealStatuses['renewing'],
        annual_mileage: annualMileage ? annualMileage : undefined,
        customer: {
          ...customer,
          company: {
            ...customer.company,
            business_address: customer.company.address,
            business_phone_number: customer.company.phone_number,
            company_name: customer.company.name,
          }
        },
        vehicle: vehicle,
        monthly: {
          ...monthly,
          vehicle_monthly_settled_term: monthly.term,
        },
        daily: {
          ...daily,
          vehicle_monthly_settled_term: daily.term,
        },
        vehicle_id: vehicle ? vehicle.id : undefined,
        customer_id: vehicle ? customer.id : undefined,
      }
    })
      .then(response => {
        if (response.status === 201) {
          let data = response.data;
          notify('success', 'Success', data.message);
          setTimeout(() => {
            goList()
          }, 1500);
        }
      })
      .catch(error => {
        setErrors('');
        console.error('error :: ', error);
        notify('warning', 'Warning', error.response.statusText);

        if (error.response && error.response.status === 422) {
          setErrors(error.response.data.errors);
          return
        }
      })
  }

  const filterCustomers = inputValue => {
    return customers.filter(i =>
      i.full_name.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const promiseOptions = inputValue => {
    if (inputValue.length < 2) {
      return
    } else {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(filterCustomers(inputValue));
        }, 1000);
      });
    }
  }

  return (
    <>
      <CardsHeader name="Deal Management" parentName="Deals Management" currentName="Create" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                    <Row>
                      <Col md={3}>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-25">Customer</label>
                          <AsyncSelect
                            className="w-75"
                            loadOptions={promiseOptions}
                            value={filterOptions && filterOptions.customerId ? (customers.find((element) => element.value === filterOptions.customerId) || '') : ''}
                            onChange={value => {
                              setFilterOptions({
                                ...filterOptions,
                                customerId: value.id
                              })
                            }}
                            placeholder="Search..."
                            noOptionsMessage={value => {
                              if (value.inputValue.length > 1) {
                                return "No options"
                              }
                              return `Please enter ${2 - value.inputValue.length} or more characters`
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup className={'d-flex align-items-center'}>
                          <label className="form-control-label w-25">Vehicle</label>
                          <Select
                            options={vehicles}
                            className="w-75"
                            value={filterOptions && filterOptions.vehicleId ? (vehicles.find((element) => element.value === filterOptions.vehicleId) || '') : ''}
                            onChange={value => setFilterOptions({
                              ...filterOptions,
                              vehicleId: value.id
                            })}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <div className="d-flex flex-row">
                          <ButtonContainer onClick={load} reverse>
                            <i className="fa fa-download" aria-hidden="true"></i>
                            Load
                          </ButtonContainer>
                          <ButtonContainer onClick={reset} >
                            Reset
                          </ButtonContainer>
                          {/*<Button className="w-50 mr-3" color="success" type="button" onClick={() => load()}>Load</Button>*/}
                          {/*<Button className="w-50 ml-3" color="danger" type="button" onClick={() => reset()}>Reset</Button>*/}
                        </div>
                      </Col>
                    </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Card>
          <CardHeader>
            <h2>Customer Management</h2>
          </CardHeader>
          <CardBody>
            <Row>
            <Col sm={6} md={4}>
              <Col sm={12}>
                <InputKeySelect
                  newLabel={'Title'}
                  options={constants.titles}
                  value={customer && customer.title ? customer.title : ''}
                  onChange={e => setCustomer({
                    ...customer,
                    title: e.target.value,
                    attention_to: `${e.target.value ? `${constants.titles[e.target.value]} ` : ''}${customer.first_name ? `${customer.first_name}` : ''}${customer.last_name ? ` ${customer.last_name}` : ''}`
                  })}
                  defaultOption={true}
                />
              </Col>
              <Col sm={12}>
              <FormGroup className={'d-flex align-items-center'}>
                <label className="form-control-label w-50">First Name</label>
                <Input
                    placeholder="First Name"
                    type="text"
                    value={customer ? customer.first_name : ''}
                    onChange={(e) => setCustomer({
                      ...customer,
                      first_name: e.target.value,
                      full_name: `${e.target.value ? ` ${e.target.value}` : ''}${customer.last_name ? ` ${customer.last_name}` : ''}`,
                      attention_to: `${customer.title ? `${constants.titles[customer.title]} ` : ''}${e.target.value ? `${e.target.value}` : ''}${customer.last_name ? ` ${customer.last_name}` : ''}`
                    })}
                />
              </FormGroup>
              </Col>
              <Col sm={12}>
                <FormGroup className={'d-flex align-items-center'}>
                  <label className="form-control-label w-50">Last Name</label>
                  <Input
                      placeholder="Last Name"
                      type="text"
                      value={customer ? customer.last_name : ''}
                      onChange={(e) => setCustomer({
                        ...customer,
                        last_name: e.target.value,
                        full_name: `${customer.first_name ? ` ${customer.first_name}` : ''}${e.target.value ? ` ${e.target.value}` : ''}`,
                        attention_to: `${customer.title ? `${constants.titles[customer.title]} ` : ''}${customer.first_name ? `${customer.first_name}` : ''}${e.target.value ? ` ${e.target.value}` : ''}`
                      })}
                  />
                </FormGroup>
              </Col>
              <Col sm={12}>
                <InputCustom
                    newLabel={`N.I Number`}
                    value={
                      customer && customer.national_insurance_number
                          ? customer.national_insurance_number
                          : ""
                    }
                    onChange={(e) =>
                        setCustomer({
                          ...customer,
                          national_insurance_number: e.target.value,
                        })
                    }
                />
              </Col>
              <Col sm={12}>
                <InputCustom
                    newLabel={`D/L Number`}
                    value={
                      customer && customer.driver_license_number
                          ? customer.driver_license_number
                          : ""
                    }
                    onChange={(e) =>
                        setCustomer({
                          ...customer,
                          driver_license_number: e.target.value,
                        })
                    }
                />
              </Col>
            </Col>
            <Col sm={6} md={4}>
              <Col sm={12}>
                <FormGroup className={'d-flex align-items-center'}>
                  <label className="form-control-label w-50">Attention To</label>
                  <Input
                      placeholder="Attention To"
                      type="text"
                      value={customer ? customer.attention_to : ''}
                      onChange={(e) => setCustomer({
                        ...customer,
                        attention_to: e.target.value
                      })}
                  />
                </FormGroup>
              </Col>
              <Col sm={12}>
                <InputCustom
                    newLabel={`Email Address`}
                    value={customer && customer.email ? customer.email : ""}
                    onChange={(e) =>
                        setCustomer({
                          ...customer,
                          email: e.target.value,
                        })
                    }
                />
              </Col>
              <Col sm={12}>
                <InputCustom
                    newLabel={`Mobile Number`}
                    value={
                      customer && customer.mobile_number
                          ? customer.mobile_number
                          : ""
                    }
                    onChange={(e) =>
                        setCustomer({
                          ...customer,
                          mobile_number: e.target.value,
                        })
                    }
                />
              </Col>
              <Col sm={12}>
                <InputCustom
                    newLabel={`Land Line Number`}
                    value={
                      customer && customer.land_line_number
                          ? customer.land_line_number
                          : ""
                    }
                    onChange={(e) =>
                        setCustomer({
                          ...customer,
                          land_line_number: e.target.value,
                        })
                    }
                />
              </Col>
              <Col msm={12}>
                <InputCustom
                    newLabel={`Postal Code`}
                    required
                    checkValid={false}
                    value={
                      customer && customer.postal_code ? customer.postal_code : ""
                    }
                    onChange={(e) =>
                        setCustomer({
                          ...customer,
                          postal_code: e.target.value,
                        })
                    }
                />
              </Col>
            </Col>
            <Col sm={6} md={4}>
              <Col sm={12}>
                <FormGroup className={'d-flex align-items-center'}>
                  <label className="form-control-label w-50">Full Name</label>
                  <Input
                      placeholder="Full Name"
                      type="text"
                      value={customer ? customer.full_name : ''}
                      disabled={true}
                      onChange={(e) => setCustomer({
                        ...customer,
                        full_name: e.target.value
                      })}
                  />
                </FormGroup>
              </Col>
              <Col sm={12}>
                <InputCustom
                    newLabel={`Address`}
                    required
                    checkValid={false}
                    value={customer && customer.address ? customer.address : ""}
                    onChange={(e) =>
                        setCustomer({
                          ...customer,
                          address: e.target.value,
                        })
                    }
                    invalid={errors && errors['customer.address'] ? true : false}
                    errorMessage={errors['customer.address']}
                />
              </Col>
              <Col sm={12}>
                <FormGroup className={'d-flex align-items-center'}>
                  <label className="form-control-label w-50">Building</label>
                  <Input
                      placeholder="Building"
                      type="text"
                      value={customer ? customer.building : ''}
                      onChange={(e) => setCustomer({
                        ...customer,
                        building: e.target.value
                      })}
                  />
                </FormGroup>
              </Col>
              <Col sm={12}>
                <FormGroup className={'d-flex align-items-center'}>
                  <label className="form-control-label  w-50">Street</label>
                  <Input
                      placeholder="Street"
                      type="text"
                      value={customer ? customer.street : ''}
                      onChange={(e) => setCustomer({
                        ...customer,
                        street: e.target.value
                      })}
                  />
                </FormGroup>
              </Col>
              <Col sm={12}>
                <FormGroup className={'d-flex align-items-center'}>
                  <label className="form-control-label w-50">City</label>
                  <Input
                      placeholder="City"
                      type="text"
                      value={customer ? customer.city : ''}
                      onChange={(e) => setCustomer({
                        ...customer,
                        city: e.target.value
                      })}
                  />
                </FormGroup>
              </Col>
            </Col>
            </Row>
          </CardBody>
        </Card>

        <Row>
          <Col sm={6} md={6}>
            <Card>
              <CardHeader>
                <h2>Vehicle information {vehicle && vehicle.vehicleMake && vehicle.vehicleModel ? ` : ${vehicle.vehicleMake.name}, ${vehicle.vehicleModel.name}` : ''}</h2>
              </CardHeader>
              <CardBody>
                <Col sm={10} md={10}>
                  <Col sm={12}>
                    <InputCustom
                        newLabel={`Reg no`}
                        value={vehicle && vehicle.stock_no ? vehicle.stock_no : ''}
                        required
                        checkValid={false}
                        onChange={(e) => setVehicle({
                          ...vehicle,
                          stock_no: e.target.value,
                        })}
                        invalid={errors && errors['vehicle.stock_no'] ? true : false}
                        errorMessage={errors['vehicle.stock_no']}
                    />
                  </Col>
                  <Col sm={12}>
                    <InputCustom
                        newLabel={'Colour'}
                        required
                        checkValid={false}
                        value={vehicle && vehicle.colour ? vehicle.colour : ''}
                        onChange={(e) => setVehicle({
                          ...vehicle,
                          colour: e.target.value,
                        })}
                        invalid={errors && errors['vehicle.colour'] ? true : false}
                        errorMessage={errors['vehicle.colour']}
                    />
                  </Col>
                  <Col sm={12}>
                    <InputCustom
                        newLabel={'Derivative'}
                        required
                        checkValid={false}
                        value={vehicle && vehicle.derivative ? vehicle.derivative : ''}
                        onChange={(e) => setVehicle({
                          ...vehicle,
                          derivative: e.target.value,
                        })}
                        invalid={errors && errors['vehicle.derivative'] ? true : false}
                        errorMessage={errors['vehicle.derivative']}
                    />
                  </Col>
                  <Col md={12}>
                    <InputCustom
                        newLabel={'Current mileage'}
                        required
                        type={`number`}
                        checkValid={false}
                        checkZero
                        value={vehicle && vehicle.current_mileage ? vehicle.current_mileage : ''}
                        onChange={(e) => setVehicle({
                          ...vehicle,
                          current_mileage: e.target.value,
                        })}
                        invalid={errors && errors['vehicle.current_mileage'] ? true : false}
                        errorMessage={errors['vehicle.current_mileage']}
                    />
                  </Col>
                  <Col md={12}>
                    <InputCustom
                        type={`textarea`}
                        newLabel={'Extras'}
                        rows={5}
                        value={vehicle && vehicle.extras ? vehicle.extras : ''}
                        onChange={(e) => setVehicle({
                          ...vehicle,
                          extras: e.target.value,
                        })}
                        invalid={errors && errors['vehicle.extras'] ? true : false}
                        errorMessage={errors['vehicle.extras']}
                    />
                  </Col>
                </Col>

              </CardBody>
            </Card>
          </Col>
          <Col sm={6} md={6}>
            <Card style={{height: "95%"}}>
              <CardHeader>
                <h2>Company information</h2>
              </CardHeader>
              <CardBody>
                <Col sm={10} md={10}>
                  <Col sm={12}>
                    <InputArraySelect
                        newLabel={`Business activity`}
                        options={businessTypes}
                        required
                        value={customer && customer.company ? customer.company.business_activity_id : ''}
                        onChange={(e) => setCustomer({
                          ...customer,
                          company: {
                            ...customer.company,
                            business_activity_id: e.target.value
                          }
                        })}
                        valueKey={`id`}
                        labelKey={`name`}
                        defaultOption={true}
                        invalid={errors && errors['customer.company.business_activity_id'] ? true : false}
                        errorMessage={errors['customer.company.business_activity_id']}
                    />
                  </Col>
                  <Col sm={12}>
                    <InputArraySelect
                        newLabel={`Company type`}
                        options={companyTypes}
                        required
                        value={customer && customer.company ? customer.company.company_type_id : ''}
                        onChange={(e) => setCustomer({
                          ...customer,
                          company: {
                            ...customer.company,
                            company_type_id: e.target.value
                          }
                        })}
                        valueKey={`id`}
                        labelKey={`name`}
                        defaultOption={true}
                        invalid={errors && errors['customer.company.company_type_id'] ? true : false}
                        errorMessage={errors['customer.company.company_type_id']}
                    />
                  </Col>
                  <Col sm={12}>
                    <InputCustom
                        newLabel={`Company name`}
                        value={customer && customer.company ? customer.company.name : ''}
                        required
                        checkValid={false}
                        onChange={(e) => setCustomer({
                          ...customer,
                          company: {
                            ...customer.company,
                            name: e.target.value
                          }
                        })}
                        invalid={errors && errors['customer.company.company_name'] ? true : false}
                        errorMessage={errors['customer.company.company_name']}
                    />
                  </Col>
                  <Col sm={12}>
                    <InputCustom
                        newLabel={`Business address`}
                        required
                        checkValid={false}
                        value={customer && customer.company ? customer.company.address : ''}
                        onChange={(e) => setCustomer({
                          ...customer,
                          company: {
                            ...customer.company,
                            address: e.target.value
                          }
                        })}
                        invalid={errors && errors['customer.company.business_address'] ? true : false}
                        errorMessage={errors['customer.company.business_address']}
                    />
                  </Col>
                  <Col sm={12}>
                    <InputCustom
                        newLabel={`Business phone number`}
                        required
                        checkValid={false}
                        value={customer && customer.company ? customer.company.phone_number : ''}
                        onChange={(e) => setCustomer({
                          ...customer,
                          company: {
                            ...customer.company,
                            phone_number: e.target.value
                          }
                        })}
                        invalid={errors && errors['customer.company.business_phone_number'] ? true : false}
                        errorMessage={errors['customer.company.business_phone_number']}
                    />
                  </Col>
                </Col>

              </CardBody>
            </Card>
          </Col>
        </Row>


        <Card>
          <CardHeader>
            <h2 >Finance info</h2>
            <div className="d-flex mt-5 cursor-pointer">
              <div className="navbar-nav mr-4">
                <span
                  style={{color: division && '#ec6409'}}
                  onClick={() => setDivision(true)}
                >
                  Monthly Rental Calculator
                </span>
                {division &&  <Line color={'#ec6409'}/>}
              </div>
              <div className="navbar-nav">
                <span
                  style={{color: !division && '#ec6409'}}
                  onClick={() => setDivision(false)}
                >
                  Daily Rental Calculator
                </span>
                {!division &&  <Line color={'#ec6409'}/>}
              </div>
            </div>
          </CardHeader>
          <CardBody>
            {division &&
            <Row>
           <Col md={4}>
                <h2>Finance Cost</h2>
                <div className="mt-5">
                  <InputSideCustom
                    type={`number`}
                    label={`Monthly cost`}
                    disabled={true}
                    value={monthly && monthly.vehicle_monthly_cost ? monthly.vehicle_monthly_cost : ''}
                    invalid={errors && errors['monthly.vehicle_monthly_cost'] ? true : false}
                    errorMessage={errors['monthly.vehicle_monthly_cost']}
                  />
                  <InputSideCustom
                    type={`number`}
                    label={`Total cost`}
                    disabled={true}
                    value={monthly && monthly.vehicle_total_estimated_cost ? monthly.vehicle_total_estimated_cost : ''}
                    invalid={errors && errors['monthly.vehicle_total_estimated_cost'] ? true : false}
                    errorMessage={errors['monthly.vehicle_total_estimated_cost']}
                  />
                  <InputKeySideSelect
                    label={`Interval`}
                    options={
                      {
                        month: 'Month(s)'
                      }
                    }
                    value={monthly && monthly.vehicle_monthly_settled_interval ? monthly.vehicle_monthly_settled_interval : ''}
                    onChange={(e) => setMonthly({
                      ...monthly,
                      vehicle_monthly_settled_interval: e.target.value,
                    })}
                    invalid={errors && errors['monthly.vehicle_monthly_settled_interval'] ? true : false}
                    errorMessage={errors['monthly.vehicle_monthly_settled_interval']}
                  />
                  <InputSideCustom
                    type={`number`}
                    label={`Delivery Fee`}
                    value={monthly && monthly.delivery_fee ? monthly.delivery_fee : ''}
                    onChange={(e) => setMonthly({
                      ...monthly,
                      delivery_fee: e.target.value,
                    })}
                    invalid={errors && errors['monthly.delivery_fee'] ? true : false}
                    errorMessage={errors['monthly.delivery_fee']}
                  />
                  <InputSideCustom
                    type={`number`}
                    label={`Maintenance Cost`}
                    value={monthly && monthly.insurance_interval ? monthly.insurance_interval : ''}
                    onChange={(e) => setMonthly({
                      ...monthly,
                      insurance_interval: e.target.value,
                    })}
                    invalid={errors && errors['monthly.insurance_interval'] ? true : false}
                    errorMessage={errors['monthly.insurance_interval']}
                  />
                  <InputSideCustom
                    type={`number`}
                    label={`Payment frequencies`}
                    value={monthly && monthly.payment_frequency ? monthly.payment_frequency : ''}
                    onChange={(e) => setMonthly({
                      ...monthly,
                      payment_frequency: e.target.value,
                    })}
                    invalid={errors && errors['monthly.payment_frequency'] ? true : false}
                    errorMessage={errors['monthly.payment_frequency']}
                  />
                  <InputKeySideSelect
                    label={`Interval`}
                    options={constants.paymentIntervals}
                    value={monthly && monthly.payment_frequency_interval ? monthly.payment_frequency_interval : 'weeks'}
                    onChange={(e) => setMonthly({
                      ...monthly,
                      payment_frequency_interval: e.target.value,
                    })}
                    invalid={errors && errors['monthly.payment_frequency_interval'] ? true : false}
                    errorMessage={errors['monthly.payment_frequency_interval']}
                  />
                </div>
           </Col>
              <Col md={8}>
                <Col sm={12}>
                  <h2>Rental Quote</h2>
                </Col>
             <Row className="mt-5">
               <Col md={6}>
                   <InputSideCustom
                       type={`number`}
                       label={`4 Weekly Rental`}
                       value={monthly && monthly.monthly_payment ? monthly.monthly_payment : ''}
                       onChange={(e) => setMonthly({
                         ...monthly,
                         monthly_payment: e.target.value,
                       })}
                       invalid={errors && errors['monthly.monthly_payment'] ? true : false}
                       errorMessage={errors['monthly.monthly_payment']}
                   />
                   <InputSideCustom
                       type={`number`}
                       label={`Weekly Rental`}
                       disabled={true}
                       value={monthly && monthly.weekly_payment ? monthly.weekly_payment : ''}
                       invalid={errors && errors['monthly.weekly_payment'] ? true : false}
                       errorMessage={errors['monthly.weekly_payment']}
                   />
                   <InputSideCustom
                       type={`number`}
                       label={`Rental no of payments`}
                       value={monthly && monthly.no_of_payments ? monthly.no_of_payments : ''}
                       onChange={(e) => setMonthly({
                         ...monthly,
                         no_of_payments: e.target.value,
                       })}
                       invalid={errors && errors['monthly.no_of_payments'] ? true : false}
                       errorMessage={errors['monthly.no_of_payments']}
                   />
                   <InputSideCustom
                       type={`number`}
                       label={`Rental term`}
                       required
                       checkValid={false}
                       value={monthly && monthly.term ? monthly.term : ''}
                       onChange={(e) => setMonthly({
                         ...monthly,
                         term: e.target.value,
                       })}
                       invalid={errors && errors['monthly.term'] ? true : false}
                       errorMessage={errors['monthly.term']}
                   />
                   <InputKeySideSelect
                       label={`Interval`}
                       options={
                         {
                           month: 'Month(s)'
                         }
                       }
                       value={monthly && monthly.interval ? monthly.interval : ''}
                       onChange={(e) => setMonthly({
                         ...monthly,
                         interval: e.target.value,
                       })}
                       invalid={errors && errors['monthly.interval'] ? true : false}
                       errorMessage={errors['monthly.interval']}
                   />
                   <InputSideCustom
                       type={`number`}
                       label={`Initial payment`}
                       value={monthly && monthly.initial_payment ? monthly.initial_payment : ''}
                       onChange={(e) => setMonthly({
                         ...monthly,
                         initial_payment: e.target.value,
                       })}
                       invalid={errors && errors['monthly.initial_payment'] ? true : false}
                       errorMessage={errors['monthly.initial_payment']}
                   />
                   <InputSideCustom
                       type={`number`}
                       label={`End payment`}
                       required
                       checkValid={false}
                       value={monthly && monthly.end_payment ? monthly.end_payment : ''}
                       onChange={(e) => setMonthly({
                         ...monthly,
                         end_payment: e.target.value,
                       })}
                       invalid={errors && errors['monthly.end_payment'] ? true : false}
                       errorMessage={errors['monthly.end_payment']}
                   />
               </Col>
               <Col md={6}>
                   <InputSideCustom
                       type={`number`}
                       label={`Estimated Rental received`}
                       disabled={true}
                       value={monthly && monthly.rental_received ? monthly.rental_received : ''}
                       invalid={errors && errors['monthly.rental_received'] ? true : false}
                       errorMessage={errors['monthly.rental_received']}
                   />
                   <InputSideCustom
                       type={`number`}
                       label={`Estimated Rental profit`}
                       disabled={true}
                       value={monthly && monthly.expected_profit ? monthly.expected_profit : ''}
                       invalid={errors && errors['monthly.expected_profit'] ? true : false}
                       errorMessage={errors['monthly.expected_profit']}
                   />
               </Col>
             </Row>
           </Col>
            </Row>}
            {!division && <Row>
              <Col md={6}>
                <h2 >Finance Cost</h2>
                <div className="mt-5">
                  <InputSideCustom
                    type={`number`}
                    label={`Daily cost`}
                    disabled={true}
                    value={daily && daily.vehicle_monthly_cost ? daily.vehicle_monthly_cost : ''}
                    invalid={errors && errors['daily.vehicle_monthly_cost'] ? true : false}
                    errorMessage={errors['daily.vehicle_monthly_cost']}
                  />
                  <InputSideCustom
                    type={`number`}
                    label={`Total cost`}
                    disabled={true}
                    value={daily && daily.vehicle_total_estimated_cost ? daily.vehicle_total_estimated_cost : ''}
                    invalid={errors && errors['daily.vehicle_total_estimated_cost'] ? true : false}
                    errorMessage={errors['daily.vehicle_total_estimated_cost']}
                  />
                  <InputSideCustom
                    type={`number`}
                    label={`Interval`}
                    value={daily && daily.vehicle_monthly_settled_interval ? daily.vehicle_monthly_settled_interval : ''}
                    onChange={(e) => setDaily({
                      ...daily,
                      vehicle_monthly_settled_interval: e.target.value,
                    })}
                    invalid={errors && errors['daily.vehicle_monthly_settled_interval'] ? true : false}
                    errorMessage={errors['daily.vehicle_monthly_settled_interval']}
                  />
                  <InputSideCustom
                    type={`number`}
                    label={`Daily Insurance`}
                    value={daily && daily.insurance_interval ? daily.insurance_interval : ''}
                    onChange={(e) => setDaily({
                      ...daily,
                      insurance_interval: e.target.value,
                    })}
                    invalid={errors && errors['daily.insurance_interval'] ? true : false}
                    errorMessage={errors['daily.insurance_interval']}
                  />
                  <InputSideCustom
                    type={`number`}
                    label={`Delivery Fee`}
                    value={daily && daily.delivery_fee ? daily.delivery_fee : ''}
                    onChange={(e) => setDaily({
                      ...daily,
                      delivery_fee: e.target.value,
                    })}
                    invalid={errors && errors['daily.delivery_fee'] ? true : false}
                    errorMessage={errors['daily.delivery_fee']}
                  />
                </div>
              </Col>
              <Col md={6}>
                <h2>Rental Quote</h2>
                <div className="mt-5">
                  <InputSideCustom
                    type={`number`}
                    label={`Daily Rental`}
                    value={daily && daily.monthly_payment ? daily.monthly_payment : ''}
                    onChange={(e) => setDaily({
                      ...daily,
                      monthly_payment: e.target.value,
                    })}
                    invalid={errors && errors['daily.monthly_payment'] ? true : false}
                    errorMessage={errors['daily.monthly_payment']}
                  />
                  <InputSideCustom
                    type={`number`}
                    label={`Rental term`}
                    value={daily && daily.term ? daily.term : ''}
                    onChange={(e) => setDaily({
                      ...daily,
                      term: e.target.value,
                    })}
                    invalid={errors && errors['daily.term'] ? true : false}
                    errorMessage={errors['daily.term']}
                  />
                  <InputKeySideSelect
                    label={`Interval`}
                    options={
                      {
                        day: 'Day(s)'
                      }
                    }
                    value={daily && daily.interval ? daily.interval : ''}
                    onChange={(e) => setDaily({
                      ...daily,
                      interval: e.target.value,
                    })}
                    invalid={errors && errors['daily.interval'] ? true : false}
                    errorMessage={errors['daily.interval']}
                  />
                  <InputSideCustom
                    type={`number`}
                    label={`Reundable Deposit`}
                    value={daily && daily.initial_payment ? daily.initial_payment : ''}
                    onChange={(e) => setDaily({
                      ...daily,
                      initial_payment: e.target.value,
                    })}
                    invalid={errors && errors['daily.initial_payment'] ? true : false}
                    errorMessage={errors['daily.initial_payment']}
                  />
                  <InputSideCustom
                    type={`number`}
                    label={`Estimated Rental received`}
                    disabled={true}
                    value={daily && daily.rental_received ? daily.rental_received : ''}
                    invalid={errors && errors['daily.rental_received'] ? true : false}
                    errorMessage={errors['daily.rental_received']}
                  />
                  <InputSideCustom
                    type={`number`}
                    label={`Estimated Rental profit`}
                    disabled={true}
                    value={daily && daily.expected_profit ? daily.expected_profit : ''}
                    invalid={errors && errors['daily.expected_profit'] ? true : false}
                    errorMessage={errors['daily.expected_profit']}
                  />
                </div>
              </Col>
            </Row>}
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <h2>Finance info</h2>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={4}>
                <InputCustom
                  newLabel={'28 day mileage allowance'}
                  value={annualMileage}
                  onChange={(e) => setAnnualMileage(e.target.value)}
                  invalid={errors && errors.annual_mileage ? true : false}
                  errorMessage={errors.annual_mileage}
                />
              </Col>
              <Col md={4}>

                <InputKeySelect
                  newLabel={`Deal status`}
                  required
                  options={constants.dealStatuses}
                  value={dealStatus}
                  onChange={(e) => setDealStatus(e.target.value)}
                  defaultOption={true}
                  invalid={errors && errors.deal_status ? true : false}
                  errorMessage={errors.deal_status}
                />
              </Col>
              <Col md={4}>
                <InputCustom
                  type={`date`}
                  newLabel={`Deal date`}
                  value={dealDate}
                  onChange={(e) => setDealDate(e.target.value)}
                  invalid={errors && errors.deal_date ? true : false}
                  errorMessage={errors.deal_date}
                />
              </Col>
            </Row>

          </CardBody>
        </Card>
        <div className="d-flex flex-row justify-content-end">
          <ButtonContainer  onClick={() => goList()}>Cancel</ButtonContainer>
          <ButtonContainer disabled={validate()}  reverse onClick={() => createDeal()}>Save</ButtonContainer>
        </div>
      </Container>
    </>
  )
}