import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux';
import NotificationAlert from 'react-notification-alert'
import axios from 'axios'
import Cookies from 'js-cookie'

import * as constants from 'constants/index.js'
import CardsHeader from 'layouts/Headers/CardsHeader.js'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Button,
  Modal,
} from 'reactstrap'

export default function InvoiceEdit() {
  const { id } = useParams()
  const notificationAlert = useRef(null)
  const history = useHistory()

  const permissions = useSelector(state => state.getState).permissions

  const [token, setToken] = useState('')
  const [item, setItem] = useState('')
  const [dealer, setDealer] = useState('')
  const [filter, setFilter] = useState('')
  const [types, setTypes] = useState('')

  const [deleteModal, setDeleteModal] = useState({
    show: false,
    header: '',
    body: '',
    onClick: null,
  })

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    }
    notificationAlert.current.notificationAlert(options)
  }

  useEffect(() => {
     if (id) {
       const grs_token = Cookies.get('grs_token')
       setToken(grs_token)

       const source = axios.CancelToken.source()

       const fetchData = async () => {
         try {
           const response = await axios({
             method: 'GET',
             url: `${constants.API_URL}/invoices/${id}/edit`,
             headers: {
               Authorization: `Bearer ${grs_token}`,
             },
             cancelToken: source.token,
           })
           console.log('response :: ', response.data)
           const data = response.data
           setItem(data.data.item)
           setDealer(data.data.dealer)
           setFilter(data.data.filter)
           setTypes(data.data.types)
         } catch (error) {
           if (axios.isCancel(error)) {
             console.log('Request canceled :: ', error)
           } else {
             console.error('error :: ', error)
             notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
           }
         }
       }

       fetchData()

       return () => {
         source.cancel()
       }
     }
  }, [id])

  const paidInvoice = async () => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/invoices/${id}/paid`,
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      console.log('response :: ', response.data)
      const data = response.data
      setItem(data.data.item)
      notify('success', 'Success', data.message)
    } catch (error) {
      console.error('error :: ', error)
      notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
    }
  }

  const downloadInvoice = async () => {
    try {
      await axios({
        method: 'GET',
        url: `${constants.API_URL}/invoices/${id}/download`,
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
    } catch (error) {
      console.error('error :: ', error)
      notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
    }
  }

  const deleteInvoice = async () => {
    try {
      const response = await axios({
        method: 'DELETE',
        url: `${constants.API_URL}/invoices/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      notify('success', 'Success', response.data.message)

      setTimeout(() => {
        history.push({
          pathname: '/admin/invoices'
        })
      }, 1500);
    } catch (error) {
      console.error('error :: ', error)
      notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
    }
  }

  const updateInvoice = async () => {
    let formData = new FormData()
    if (dealer.deals && dealer.deals.length > 0) {
      dealer.deals.forEach(deal => {
        formData.append(`deals[${deal.id}]`, deal.isActive)
      })
    }

    try {
      const response = await axios({
        method: 'PUT',
        url: `${constants.API_URL}/invoices/${id}/update`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: formData
      })
      notify('success', 'Success', response.data.message)

      setTimeout(() => {
        history.push({
          pathname: '/admin/invoices'
        })
      }, 1500);
    } catch (error) {
      console.error('error :: ', error)
      notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
    }
  }

  return (
    <>
      <CardsHeader name="Invoicing" parentName="Invoices Management" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md={8}>
                    <h1 className="title mb-0">{`Invoice generated for ${dealer ? dealer.business_name : ''}`}</h1>
                    <Row>
                      <Col sm={3}>
                        <small className="text-uppercase text-muted font-weight-bold">{`Time interval`}</small>
                      </Col>
                      <Col sm={9}>
                        <h2 className="mb-0">{`${constants.formatDateFullGB(filter.from)} to ${constants.formatDateFullGB(filter.to)}`}</h2>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={3}>
                        <small className="text-uppercase text-muted font-weight-bold">{`Created At`}</small>
                      </Col>
                      <Col sm={9}>
                        <h2 className="mb-0">{`${constants.formatDateFullGB(filter.created_at)}`}</h2>
                      </Col>
                    </Row>
                    {item.rules &&
                      <Row>
                        <Col sm={3}>
                          <small className="text-uppercase text-muted font-weight-bold">{`Specific rules at the time running`}</small>
                        </Col>
                        <Col sm={9}>
                          <h2 className="mb-0">
                            {item.rules && item.rules.length > 0 &&
                              item.rules.map((rule, index) => {
                                return <br key={index}>{`From ${rule.value_from} deals, value is ${rule.value}`}</br>
                              })
                            }
                          </h2>
                        </Col>
                      </Row>}
                    {item.paid_at &&
                      <Row>
                        <Col sm={3}>
                          <small className="text-uppercase text-muted font-weight-bold">{`Mark Paid`}</small>
                        </Col>
                        <Col sm={9}>
                          <h2 className="mb-0">
                            {constants.formatDateFullGB(item.paid_at)}
                          </h2>
                        </Col>
                      </Row>}
                  </Col>
                  <Col md={4}>
                    {permissions.includes('update-invoice') && types[item.type] &&
                      <a className="btn btn-sm btn-default" onClick={() => paidInvoice()}>{`Paid`}</a>
                    }
                    <a className="btn btn-sm btn-primary mt-2" onClick={() => downloadInvoice()}>{`Download`}</a>
                    {permissions.includes('delete-invoice') &&
                      <Button className="btn btn-sm btn-success-red mt-2" type="button" onClick={() => setDeleteModal({
                        ...deleteModal,
                        show: true,
                        header: 'Delete current invoice',
                        body: 'Are you sure you want to delete this invoice?',
                        onClick: () => deleteInvoice(),
                      })}>
                        {`Delete`}
                      </Button>
                    }
                  </Col>
                </Row>
              </CardHeader>
            </Card>
            <Card>
              <Row className="mt-2">
                <div className="table-responsive">
                  <table className="table align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">{`Included`}</th>
                        <th scope="col">{`Office`}</th>
                        <th scope="col">{`Id`}</th>
                        <th scope="col">{`Rego`}</th>
                        <th scope="col">{`Customer`}</th>
                        <th scope="col">{`Number`}</th>
                        <th scope="col">{`Fee`}</th>
                      </tr>
                    </thead>
                    <tbody className="list">
                      {dealer.deals && dealer.deals.length > 0 &&
                        dealer.deals.map((deal, index) => {
                          return <tr bgcolor="#ffffff" align="left" className="deals-row" key={index}>
                            <td>
                              <div className="custom-control custom-checkbox d-flex align-items-center">
                                <input
                                  className="custom-control-input"
                                  type="checkbox"
                                  style={{ opacity: 1, zIndex: 1 }}
                                  checked={deal && deal.isActive? true: false}
                                  onChange={() => setDealer({
                                    ...dealer,
                                    [deal]: {
                                      ...[deal],
                                      isActive: deal.isActive === 1? 0: 1
                                    }
                                  })}
                                />
                              </div>
                            </td>
                            <td>{dealer.business_name}</td>
                            <td>{deal.id}</td>
                            <td>{deal.stockNo}</td>
                            <td>{deal.fullName}</td>
                            <td>{deal.number}</td>
                            <td>{deal.isActive && `${constants.CURRENCY} ${deal.fee}`}</td>
                          </tr>
                        })
                      }
                      <tr bgcolor="#ffffff" align="left" className="deals-row">
                        <td className="emphasize-label">{`Subtotal`}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="emphasize-label">{`${constants.CURRENCY} ${dealer.sub_total}`}</td>
                      </tr>
                      <tr bgcolor="#ffffff" align="left" className="deals-row">
                        <td className="emphasize-label">{`GST`}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="emphasize-label">{`${constants.CURRENCY} ${dealer.gst}`}</td>
                      </tr>
                      <tr bgcolor="#ffffff" align="left" className="deals-row">
                        <td className="emphasize-label">{`Total`}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="emphasize-label">{dealer.total_number_of_deals}</td>
                        <td className="emphasize-label">{`${constants.CURRENCY} ${dealer.total_to_be_paid}`}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Row>
              <Row className="mt-4 mb-2">
                <Button color="success" type="button" onClick={() => updateInvoice()}>Save</Button>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={deleteModal.show}
          toggle={() => setDeleteModal({
            ...deleteModal,
            show: false
          })}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title">{deleteModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setDeleteModal({
                ...deleteModal,
                show: false
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {deleteModal.body}
          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color="danger"
              type="button"
              onClick={deleteModal.onClick}
            >
              {`Delete`}
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setDeleteModal({
                ...deleteModal,
                show: false
              })}
            >
              {`Close`}
            </Button>
          </div>
        </Modal>
      </Container>
    </>
  )
}