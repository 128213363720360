import React, {useEffect, useState, useRef} from 'react'
import {useParams} from 'react-router-dom'
import axios from "axios";
import * as constants from "../../../../../constants";
import Cookies from "js-cookie";
import {
    LoaderContainer, override,
    ReportContainer,
    TaskDetailsContainer,
    Text,
    TextContainer
} from "@pages/reusable-components/styled-components";
import {parseToWord} from "../../../../../constants";
import {ClipLoader} from "react-spinners";
import NotificationAlert from "react-notification-alert";
import {Button, Col, Row, Table} from "reactstrap";

export default function ReportsDetails() {
    const {id} = useParams()
    const [loading,setLoading] = useState(true);
    const notificationAlert = useRef(null);
    const [data,setData] = useState([]);
    const [activeTasks,setActiveTasks] = useState([]);
    const [showTasks,setShowTasks] = useState(false);

    const notify = (type, header, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
              {header}
          </span>
                    <span data-notify="message">
            {message}
          </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5
        };
        notificationAlert.current.notificationAlert(options);
    };
    useEffect(()=>{

      if (id) {
          const grs_token = Cookies.get('grs_token')
          const fetchData = async (token) => {
              try {
                  const data = await axios({
                      method: 'GET',
                      url: constants.FLEET_URL + `/fleet-management/reports/worker/details`,
                      headers: {
                          Authorization: 'Bearer ' + token
                      },
                      params: {id:id}
                  })
                  if (data.status === 200) {
                      setData(data.data.data.workers)
                  }

                  setLoading(false)
              } catch (error) {
                  if (axios.isCancel(error)) {
                  } else {
                      notify('warning', 'Warning', error?.response?.data?.message ? error?.response?.data?.message : error?.response?.statusText)
                  }
                  setLoading(false)
              }

          }
          fetchData(grs_token)
      }

    },[id])
    const showActiveTasks = (index) => {
        setShowTasks(true)
        setActiveTasks(data[index].tasks)
    }
    const backToVehicles = () => {
        setShowTasks(false)
        setActiveTasks([])
    }
    return (
        <>
            <NotificationAlert ref={notificationAlert} />
            {
                loading ? <LoaderContainer>
                        <ClipLoader
                            css={override}
                            size={40}
                            color={`#7B61E4`}
                            loading={loading}
                        />
                    </LoaderContainer> :
                    <div  className="mt-5 ml-3 admin-main-body">
                        {
                            showTasks ? <>
                                    <h2>Tasks</h2>
                                    <Table striped>
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Title</th>
                                            <th>Job Category</th>
                                            <th>Description</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {activeTasks.map((item,index)=>{
                                            return (
                                                <tr key={index}>
                                                    <th scope="row">{index}</th>
                                                    <td>{item.title}</td>
                                                    <td>{parseToWord(item.job_category)}</td>
                                                    <td>{item.description}</td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </Table>
                                <Button color={'warning'} onClick={backToVehicles}>
                                    Back to vehicles
                                </Button>
                                </> :
                                <>
                                    <h2>Vehicles</h2>
                                    <Row>
                                        {data.map((item,index)=>{
                                            return (
                                                <Col className={'mb-3'} sm={12} md={6} lg={4}>
                                                    <ReportContainer>
                                                        <img width={'219px'} height={'165px'}
                                                             src={item.vehicle.image}
                                                             alt=""/>
                                                        <TaskDetailsContainer>
                                                            <TextContainer>
                                                                <Text>No.</Text>
                                                                <img height='25px' width='25px'
                                                                     src={require(`assets/images/taskEdit/road_grey.png`)}
                                                                     alt=""/>

                                                            </TextContainer>
                                                            <TextContainer>
                                                                <Text bold>Reg. Num.:</Text>
                                                                <Text bold>Mileage:</Text>
                                                                <Text bold> See Tasks</Text>
                                                            </TextContainer>
                                                            <TextContainer>
                                                                <Text>{item.vehicle.stock_no}</Text>
                                                                <Text>{item.vehicle.mileage}</Text>
                                                                <Button onClick={()=>showActiveTasks(index)} color="success">Show</Button>
                                                            </TextContainer>

                                                        </TaskDetailsContainer>
                                                    </ReportContainer>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </>
                        }

                    </div>}
        </>
    )
}