import React, {useRef} from 'react';
import { useParams } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";

import {
  Container,
  Row,
  Col,
} from "reactstrap";
import CardsHeader from 'layouts/Headers/CardsHeader.js';
import { LeadsContextProvider} from './context';
import DetailsSection from './sections/DetailsSection';
import QuotesSection from './sections/QuotesSection';
import LeadQuoteSection from "@pages/lead/sections/LeadQuoteSection";

export default function LeadEdit() {

  const { id } = useParams();
  const notificationAlert = useRef(null);
  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };


  return (
    <LeadsContextProvider id={id} notify={notify}>
      <CardsHeader name="Leads" parentName="Leads Management" currentName="Edit" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            {/* Header */}
           <div className={'d-flex flex-wrap justify-content-around g-10'}>
             <DetailsSection />
             <QuotesSection />
           </div>
            <LeadQuoteSection/>
          </Col>
        </Row>
        </Container>
    </LeadsContextProvider>
  )
}