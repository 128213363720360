import React, { useEffect, useState, useRef } from "react";
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import actions from 'actions/index.js';
import Cookies from 'js-cookie';
import { css } from '@emotion/core';
import { ClipLoader } from 'react-spinners';

import classnames from "classnames";

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

import AuthHeader from 'layouts/Headers/AuthHeader.js';
import AuthFooter from 'layouts/Footers/AuthFooter.js';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: blue;
`;

export default function Index() {
  const history = useHistory();
  const notificationAlert = useRef(null);

  const dispatch = useDispatch();
  const loginData = useSelector((state) => state.getState).loginData;
  
  const [focusedEmail, setFocusedEmail] = useState();
  const [focusedPassword, setFocusedPassword] = useState();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [loading, setLoading] = useState(false);

  const { success, data, message } = loginData ? loginData : { success: false, data: {}, message: ''};
  
  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  const login = () => {
    if (email === '') {
      notify('info', 'Require', 'You have to fill the email field.');
      return;
    }

    if (password === '') {
      notify('info', 'Require', 'You have to fill the password field.');
      return;
    }

    setLoading(true);

    dispatch(actions.login(email, password));
  }

  useEffect(() => {
    if (success) {
      setLoading(false);
      Cookies.set('grs_token', data.token, {expires: 1/12});
      Cookies.set('loggedin', true, {expires: 1/12});
      
      history.push('/admin/dashboard');
    } else if (message !== '' && message !== null && message !== undefined) {
      setLoading(false);
      console.log('index message :: ', message);
      notify('danger', 'Error', message);
    }
  }, [data])

  return (
    <>
      <AuthHeader />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <section className="py-6 bg-default" style={{height: "35vh"}}>
        <Container className="mt--9 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                <CardBody className="px-lg-6 py-lg-5">
                  <Form role="form">
                    <FormGroup
                      className={classnames("mb-3", {
                        focused: focusedEmail
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Email"
                          type="email"
                          onFocus={() => setFocusedEmail(true)}
                          onBlur={() => setFocusedEmail(false)}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: focusedPassword
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Password"
                          type="password"
                          onFocus={() => setFocusedPassword(true)}
                          onBlur={() => setFocusedPassword(false)}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        id=" customCheckLogin"
                        type="checkbox"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor=" customCheckLogin"
                      >
                        <span className="text-muted">Remember me</span>
                      </label>
                    </div>
                    <div className="text-center">
                      <Button 
                        className="my-4 w-50 h-25"
                        color="info" 
                        type="button" 
                        onClick={() => login()}
                        disabled={loading}
                      >
                        {!loading? `Sign in`:
                        <ClipLoader 
                          css={override}
                          size={21}
                          color={`#7B61E4`}
                          loading={loading}
                        />}
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <Row className="mt-3">
                <Col xs="6">
                  <a
                    className="text-light"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    <small>Forgot password?</small>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <AuthFooter />
    </>
  );
}