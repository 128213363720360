import React, { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import NotificationAlert from 'react-notification-alert'
import CardsHeader from 'layouts/Headers/CardsHeader.js'

import { LiveVehicleContextProvider } from './context';
import {
  Container,
} from 'reactstrap'

import ForDeliveryVehicleList from './ForDeliveryVehicleList'
import FleetVehicleList from './FleetVehicleList'
import ForR2bVehicleList from './ForR2bVehicleList'

export default function VehicleInStock() {
  const { type } = useParams()
  const notificationAlert = useRef(null)
  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  }
  useEffect(() => {
    window.addEventListener('load', () => {
      let body = document.getElementsByTagName("body");
      body[0].style.height = "auto"
    }, false)
  }, []);

  const sheetSelector = () => {
    switch (type) {
      case 'delivery':
        return (<ForDeliveryVehicleList />)
      case 'fleet-management':
        return (<FleetVehicleList home={'/admin/live-vehicle/fleet-management'} />)
      case 'job-booker':
        return (<FleetVehicleList home={'/admin/live-vehicle/job-booker'} />)
      case 'r-2-b':
        return (<ForR2bVehicleList />)
      default:
        break;
    }
  }
  return (
    <LiveVehicleContextProvider type={type === 'job-booker' ? 'fleet-management' : type} notify={notify}>
      <CardsHeader name="Fleets" parentName="Master GRS Vehicles" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" id="liveVehiclesSection" fluid >
        {sheetSelector()}
      </Container>
    </LiveVehicleContextProvider>
  )
}