import React, {useEffect, useState} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import {
    Modal, ModalBody, ModalFooter, Button
} from 'reactstrap';
import {formatDate} from "../../../constants";
import {
    CustomTh,
    Text
} from "@pages/reusable-components/styled-components";
import {
    DndContext,
    closestCorners,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors, MouseSensor
} from "@dnd-kit/core";
import {sortableKeyboardCoordinates} from "@dnd-kit/sortable";
import Drag from "@pages/components/drag/Drag";

export default function ScheduleComponent({
                                              tableData = {},
                                              columns = [],
                                              deleteDelivery,
                                              dragEnd,
                                              updateTask,
                                              sortTasks,
                                          }) {
    const [activeId, setActiveId] = useState('');
    const [deleteId, setDeleteId] = useState(null);
    const [items, setItems] = useState({});
    const [droppingItem, setDroppingItem] = useState({});
    const [activeColumn, setActiveColumn] = useState({index: null,element: {}});
    const [loading, setLoading] = useState(true);

    const [open, setOpen] = useState(false);
    const [focusAfterClose] = useState(true);

    const rows = {
        grs_collection: 'GRS Collection',
        delivery: 'Delivery',
        collect: 'Collect'
    }
    const toggle = () => setOpen(!open);

    useEffect(() => {
        if (tableData) {
            setItems(tableData)
            setLoading(false)
        }
    }, [tableData])
    const defaultAnnouncements = {
        onDragStart(id) {
            console.log(`Picked up draggable item ${id}.`);
        },
        onDragOver(id, overId) {
            if (overId) {
                console.log(
                    `Draggable item ${id} was moved over droppable area ${overId}.`
                );
                return;
            }

            console.log(`Draggable item ${id} is no longer over a droppable area.`);
        },
        onDragEnd(id, overId) {
            if (overId) {
                console.log(
                    `Draggable item ${id} was dropped over droppable area ${overId}`
                );
                return;
            }

            console.log(`Draggable item ${id} was dropped.`);
        },
        onDragCancel(id) {
            console.log(`Dragging was cancelled. Draggable item ${id} was dropped.`);
        }
    };
    const dragData = (newData, itemKey) => {
        dragEnd(newData, itemKey)
    }
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 8,
            },
        }),
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        }),
        useSensor(MouseSensor, {
                activationConstraint: {
                    delay: 1000,
                    distance: 100,
                }
            }
        ),
    )

    function findContainer(id) {
        const ids = id.split('_')
        let containerId = null
        let dateIndex = null
        if (ids[0] === 'grs') {
            containerId = `${ids[0]}_${ids[1]}`
            dateIndex = ids[2]
        } else {
             containerId = ids[0]
             dateIndex = ids[1]
        }

        let itemId = null
        if (ids[0] === 'grs') {
            if (ids[3]) {
                itemId = ids[3]
            }
        } else  {
            if (ids[2]) {
                itemId = ids[2]
            }
        }

        return {containerId, dateIndex, itemId};
    }

    function handleDragOver(event) {
        setActiveColumn({element: {},index: null})
        const {active, over} = event;
        const {id} = active;
        const {id: overId} = over;
        const activeContainer = findContainer(id);
        const overContainer = findContainer(overId);
        const activeItems = items[activeContainer.containerId];
        const overItems = items[overContainer.containerId];
        const activeIndex = activeItems.findIndex(item => item?.delivery_id?.toString() === activeContainer.itemId);
        const overIndex = overItems.findIndex(item => item?.delivery_id?.toString() === overContainer.itemId);
        let tempCont = cloneDeep(activeContainer);
        if (activeContainer.containerId === overContainer.containerId) {
            if (formatDate(items[activeContainer.containerId][activeIndex].execution_date.toString()) === overContainer.dateIndex.toString()) {
                let tempItems = cloneDeep(items)
                let arr = [...tempItems[activeContainer.containerId]]
                const toIndex = overIndex;
                            const element = arr.splice(activeIndex, 1)[0];
                            setActiveColumn({element: element,index: activeIndex})
                            arr.splice(toIndex, 0, element);
                            tempItems[activeContainer.containerId] = [...arr]
                            setItems(tempItems)
                return
            } else {
                tempCont = {...items[activeContainer.containerId][activeIndex]}
                tempCont.dateIndex = overContainer.dateIndex
                tempCont.execution_date = overContainer.dateIndex
                tempCont.containerId = overContainer.containerId
                setDroppingItem(tempCont)
            }
        } else {
            // tempCont = {...items[activeContainer.containerId][activeIndex]}
            // tempCont.dateIndex = overContainer.dateIndex
            // tempCont.execution_date = overContainer.dateIndex
            // tempCont.containerId = overContainer.containerId
            // setDroppingItem(tempCont)
        }
    }

    const updateDeliveryTask = (task) => {
        updateTask({
            execution_date: task.execution_date,
            driver_id: task.driver_id,
            id: task.delivery_id
        })
    }
    const changeTasksSorts = (tasks) => {
        let updatingData = []
        tasks.forEach((item,index)=>{
            updatingData.push(
                {
                    id: item.delivery_id,
                    sort_number: index
                }
            )
        })
        sortTasks(updatingData)
    }

    function handleDragEnd(event) {
        const {active, over} = event;
        const {id} = active;
        const {id: overId} = over;
        setDroppingItem({})
        const activeContainer = findContainer(id);
        const overContainer = findContainer(overId);
        const activeIndex = items[activeContainer.containerId].findIndex(item => item?.delivery_id?.toString() === activeContainer.itemId);
        let tempItems = cloneDeep(items);
        //todo can be useful

        // const tempItem = tempItems[activeContainer.containerId][activeIndex]
        // if (new Date(overContainer.dateIndex) > new Date(tempItem.execution_date)) {
        //     setActiveId(null);
        //     return
        // }
        if (activeContainer.containerId === overContainer.containerId) {
            if (formatDate(tempItems[activeContainer.containerId][activeIndex].execution_date.toString()) !== overContainer.dateIndex.toString()) {
                tempItems[activeContainer.containerId][activeIndex].execution_date = overContainer.dateIndex
                updateDeliveryTask(tempItems[activeContainer.containerId][activeIndex])
                setItems(tempItems)
            } else {
                if (activeColumn.index > -1 && activeColumn.index !== null) {

                    changeTasksSorts(tempItems[activeContainer.containerId])
                }
            }
        } else {
            // let tempItem = cloneDeep(tempItems[activeContainer.containerId][activeIndex]);
            // if (tempItems[activeContainer.containerId].length > 1) {
            //     tempItems[activeContainer.containerId].splice(activeIndex, 1)
            // } else {
            //     Object.keys(tempItems[activeContainer.containerId][0]).forEach(key => {
            //         if (key !== 'driver_id' && key !== 'driver_name') {
            //             tempItems[activeContainer.containerId][activeIndex][key] = undefined
            //         }
            //     })
            // }
            // tempItem.driver_id = tempItems[overContainer.containerId][0].driver_id
            // tempItem.driver_name = tempItems[overContainer.containerId][0].driver_name
            // tempItem.execution_date = overContainer.dateIndex
            // tempItems[overContainer.containerId].push(tempItem)
            // updateDeliveryTask(tempItem)
            // setItems(tempItems)
        }

        setActiveId(null);
    }

    function handleDragStart(event) {
        const {active} = event;
        const {id} = active;
        setActiveId(id);
    }

    function deleteItem(id) {
        setDeleteId(id)
        toggle()
    }

    function confirmDeleting() {
        deleteDelivery(deleteId)
    }

    return (
        !loading &&
        <div className={'admin-main-body ml-4 mr-4 '}>
            <div className="py-4 table-responsive admin-main-body">
                <Modal returnFocusAfterClose={focusAfterClose} isOpen={open}>
                    <ModalBody>
                        <div className={'d-flex flex-column align-items-center'}>
                            <span>Are you sure?</span>
                            <span> You want to delete delivery task.</span>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className={'d-flex justify-content-around w-100'}>
                            <Button color="primary" onClick={() => {
                                toggle();
                                setDeleteId(null)
                            }}>
                                Close
                            </Button>
                            <Button style={{color: 'white', backgroundColor: '#EC6409'}} onClick={confirmDeleting}>
                                Confirm
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>
                <div>
                    <DndContext
                        announcements={defaultAnnouncements}
                        sensors={sensors}
                        collisionDetection={closestCorners}
                        onDragStart={handleDragStart}
                        onDragEnd={handleDragEnd}
                        onDragOver={handleDragOver}
                    >
                    <table className="table">
                        <thead>
                        <tr>
                            <th></th>
                            {
                                columns && columns.length > 0 &&
                                columns.map((column, index) => {
                                    return <th tabIndex="0" key={index}>
                                        <div>
                                            <h3>{column.weekDay}</h3>
                                            <span>{column.day}</span>
                                        </div>
                                    </th>
                                })
                            }
                        </tr>
                        </thead>

                            <tbody>
                            {

                                Object.keys(items).map((val, idx) => {
                                    return (
                                        <tr style={{backgroundColor: idx %2 === 0 ? '#cfd5ea' : '#e9ebf5'}} key={idx}>
                                            <CustomTh key={val + idx}>
                                                <Text color='#ffffff' size='16px'
                                                      bold> {rows[items[val][0].task_type]}</Text>
                                            </CustomTh>
                                        {
                                            columns.map((date, dateIndex) => {
                                                return (
                                                 <React.Fragment key={`${idx}__${dateIndex}`}>
                                                      <Drag
                                                          id={`${val}_${date.currentDate}`}
                                                          customKey={`${val}_${date.currentDate}`}
                                                          date={date}
                                                          droppingItem={droppingItem}
                                                          activeId={activeId}
                                                          items={items}
                                                          itemKey={val}
                                                          deleteItem={deleteItem}
                                                          dateIndex={dateIndex}
                                                          dragEnd={(data) => dragData(data, val)}/>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </tr>)
                                })
                            }
                            </tbody>


                    </table>
                </DndContext>
                </div>
            </div>

        </div>
    )
}
