import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from "js-cookie";

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import InputCustom from 'views/pages/components/InputCustom';

import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Button,
    Modal,
} from "reactstrap";
import {override} from "@pages/reusable-components/styled-components";
import {ClipLoader} from "react-spinners";

export default function TaskEdit() {
    const { dealId, taskId } = useParams();
    const notificationAlert = useRef(null);
    const history = useHistory();

    const [token, setToken] = useState('');

    const [deal, setDeal] = useState('');
    const [task, setTask] = useState({
        logs: [],
        task_comments: [],
    });
    const [taskErrors, setTaskErrors] = useState('');
    const [commentModal, setCommentModal] = useState({
        show: false,
        header: '',
        division: '',
    })
    const [comment, setComment] = useState('')
    const [confirmModal, setConfirmModal] = useState({
        show: false,
    })
    const [loading,setLoading] = useState(true)
    const notify = (type, header, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
              {header}
          </span>
                    <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5
        };
        notificationAlert.current.notificationAlert(options);
    };

    useEffect(() => {
        const grs_token = Cookies.get('grs_token')
        setToken(grs_token)

        const source = axios.CancelToken.source()
        setLoading(true)
        const fetchData = async () => {
            try {
                const response = await axios({
                    method: 'GET',
                    url: `${constants.API_URL}/deal/${dealId}/task/${taskId}`,
                    headers: {
                        Authorization: `Bearer ${grs_token}`,
                    },
                    cancelToken: source.token,
                })
                console.log('response :: ', response.data)
                const data = response.data

                setDeal(data.data.deal);

                let dealer_members = data.data.dealerMembers;
                dealer_members.forEach(dealer => {
                    dealer.text = dealer.name;
                });

                let item = data.data.item;
                let assignee = [];
                if (item.task_assignee && item.task_assignee.length > 0) {
                    item.task_assignee.forEach((val, index) => {
                        assignee.push(val.id);
                    })
                }
                setTask({
                    ...item,
                    task_assignee: assignee,
                    due_date: item.due_date ? formatDate(item.due_date) : '',
                });
                setLoading(false)
            } catch (error) {
                setLoading(false)
                if (axios.isCancel(error)) {
                    console.log('Request canceled :: ', error)
                } else {
                    console.error('error :: ', error)
                    notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
                }
            }
        }

        fetchData()

        return () => {
            source.cancel()
        }
    }, [])

    const goDeal = () => {
        history.push({
            pathname: '/admin/deals/' + dealId + '/edit',
        })
    }

    const goCustomer = () => {
        history.push({
            pathname: '/admin/customer/' + deal.customer_id + '/edit',
        })
    }

    const formatDate = (d) => {
        if (d) {
            let date = new Date(d);
            let ye = Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
            let mo = Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
            let da = Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

            return `${ye}-${mo}-${da}`
        }
    }

    const formatDateFull = (d) => {
        if (d) {
            let date = new Date(d);

            let options = {
                year: 'numeric', month: '2-digit', day: '2-digit',
                hour: 'numeric', minute: 'numeric', second: '2-digit',
                hour12: false
            }

            let da = Intl.DateTimeFormat('en-BG', options).format(date);

            return da
        }
    }

    const newComment = () => {
        setCommentModal({
            ...commentModal,
            show: true,
            header: "Create comment",
            division: 'create',
        })
    }

    const createComment = () => {
        if (comment?.comment === '' || comment?.comment === undefined) {
            return
        }
        setLoading(true)
        axios({
            method: 'POST',
            url: `${constants.API_URL}/deal/${dealId}/task/${taskId}/comment`,
            headers: {
                Authorization: 'Bearer ' + token
            },
            data: {
                comment: comment?.comment || ''
            },
        })
            .then(response => {
                if (response.status === 200) {
                    let data = response.data;
                    setTask({...task, task_comments: data.data.task_comments})
                }
                setCommentModal({
                    ...commentModal,
                    show: false
                })
                setLoading(false)
            })
            .catch(error => {
                setTaskErrors('');
                setLoading(false)
                console.error('error :: ', error.response);
                if (error.response && error.response.status === 422) {
                    setTaskErrors(error.response.data.errors)
                    return
                }
                notify('error', 'Error', error.response && error.response.statusText? error.response.statusText: 'Error occur.');
            })
    }

    const updateComment = () => {
        if (comment?.comment === '' || comment?.comment === undefined) {
            return
        }
        setLoading(true)
        axios({
            method: 'PUT',
            url: `${constants.API_URL}/deal/${dealId}/task/${taskId}/comment/${comment.id}`,
            headers: {
                Authorization: 'Bearer ' + token
            },
            data: {
                comment: comment?.comment || ''
            },
        })
            .then(response => {
                if (response.status === 200) {
                    let data = response.data;
                    console.log('response data :: ', data);
                    setTask({...task, task_comments: data.data.task_comments})
                }
                setCommentModal({
                    ...commentModal,
                    show: false
                })
                setLoading(false)
            })
            .catch(error => {
                setTaskErrors('');
                setLoading(false)
                console.error('error :: ', error.response);
                if (error.response && error.response.status === 422) {
                    setTaskErrors(error.response.data.errors)
                    return
                }

                notify('error', 'Error', error.response && error.response.statusText? error.response.statusText: 'Error occur.');
            })
    }

    const deleteComment = () => {
        setLoading(true)
        axios({
            method: 'DELETE',
            url: `${constants.API_URL}/deal/${dealId}/task/${taskId}/comment/${comment.id}`,
            headers: {
                Authorization: 'Bearer ' + token
            },
        })
            .then(response => {

                if (response.status === 200) {
                    let data = response.data;
                    console.log('response data :: ', data);
                    setTask({...task, task_comments: data.data.task_comments})
                }
                setConfirmModal({
                    ...confirmModal,
                    show: false
                })
                setLoading(false)
            })
            .catch(error => {
                setTaskErrors('');
                setLoading(false)
                console.error('error :: ', error.response);
                if (error.response && error.response.status === 422) {
                    setTaskErrors(error.response.data.errors)
                    return
                }
                notify('error', 'Error', error.response && error.response.statusText? error.response.statusText: 'Error occur.');
            })
    }

    const editComment = (comment) => {
        setComment(comment)
        setCommentModal({
            ...commentModal,
            show: true,
            header: 'Edit comment',
            division: 'update'
        })
    }
    return (
        loading ?  <ClipLoader    css={override}
                                  size={40}
                                  color={`#7B61E4`}
                                  loading={loading}/> :
        <>
            <CardsHeader
                name="Deal Management"
                parentName="Deals Management"
                currentName="List"
            />
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlert} />
            </div>
            <Container className="mt--5 admin-main-body" fluid>
                <Row>
                    <Col md={4} sm={12}>
                        <Card>
                            <CardHeader>
                                <h2 className="title mb-0 main-color">Customer details</h2>
                            </CardHeader>
                            <CardBody>
                                <Row className="py-3 align-items-center">
                                    <Col md={4} sm={5}>
                                        <small className="text-uppercase font-weight-bold">{`Name`}</small>
                                    </Col>
                                    <Col md={8} sm={7}>
                                        <h3 className="heading mb-0">{deal.full_name}</h3>
                                    </Col>
                                </Row>
                                <Row className="py-3 align-items-center">
                                    <Col md={4} sm={5}>
                                        <small className="text-uppercase font-weight-bold">{`Email`}</small>
                                    </Col>
                                    <Col md={8} sm={7}>
                                        <h3 className="heading mb-0">{deal.email}</h3>
                                    </Col>
                                </Row>
                                <Row className="py-3 align-items-center">
                                    <Col md={4} sm={5}>
                                        <small className="text-uppercase font-weight-bold">{`Phone number`}</small>
                                    </Col>
                                    <Col md={8} sm={7}>
                                        <h3 className="heading mb-0">{deal.mobile_number}</h3>
                                    </Col>
                                </Row>
                                <Row className="py-3 align-items-center">
                                    <Col md={4} sm={5}>
                                        <small className="text-uppercase font-weight-bold">{`More details`}</small>
                                    </Col>
                                    <Col md={8} sm={7}>
                                        <Button
                                            className={'main-color h-50'}
                                            type="button"
                                            onClick={() => goCustomer()}
                                        >
                                            Customer
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardHeader>   <h2 className="title mb-0 main-color">Vehicle details</h2></CardHeader>

                            <CardBody>

                                <Row className="py-3 align-items-center">
                                    <Col md={4} sm={5}>
                                        <small className="text-uppercase font-weight-bold">{`Vehicle`}</small>
                                    </Col>
                                    <Col md={8} sm={7}>
                                        <h3 className="heading mb-0">{deal?.vehicle?.make} {deal?.vehicle?.model}</h3>
                                    </Col>
                                </Row>
                                <Row className="py-3 align-items-center">
                                    <Col md={4} sm={5}>
                                        <small className="text-uppercase font-weight-bold">{`Reg no`}</small>
                                    </Col>

                                    <Col md={8} sm={7}>
                                        <h3 className="heading mb-0">{deal.stock_no}</h3>
                                    </Col>
                                </Row>
                                <Row className="py-3 align-items-center">
                                    <Col md={4} sm={5}>
                                        <small className="text-uppercase font-weight-bold">{`Deal details`}</small>
                                    </Col>
                                    <Col md={8} sm={7}>
                                        <Button
                                            className={'main-color h-50'}
                                            type="button"
                                            onClick={() => goDeal()}
                                        >
                                            Deal
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={8} sm={12}>
                        <Card>
                            <CardHeader>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <h2 className="title mb-0 main-color">Task Management</h2>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={12}>
                                        <h2 className="font-weight-600 mb-4">{`Vehicle make information`}</h2>
                                        <Row className="py-3 align-items-center">
                                            <Col md={4} sm={5}>
                                                <small className="text-uppercase font-weight-bold">{`Task title`}</small>
                                            </Col>
                                            <Col md={8} sm={7}>
                                                <h3 className="heading mb-0">{task.title}</h3>
                                            </Col>
                                        </Row>
                                        <Row className="py-3 align-items-center">
                                            <Col md={4} sm={5}>
                                                <small className="text-uppercase font-weight-bold">{`Date Completed`}</small>
                                            </Col>
                                            <Col md={8} sm={7}>
                                                <h3 className="heading mb-0">{task.due_date}</h3>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={3}>
                                                <h3>History</h3>
                                            </Col>
                                            <Col  style={{ overflowY: 'scroll', height: '250px', border: '1px gray solid', marginBottom: '5px' }} md={9}>
                                                {task.logs.length > 0 && task.logs.map((comment, index) => {
                                                    return (
                                                        <div key={index} style={{ marginTop: 20 }}>
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    whiteSpace: 'nowrap',
                                                                }}
                                                            >
                                                                <div style={{ flex: '0 1 0%' }}>{comment.owner.name}</div>
                                                                <div>-></div>
                                                                <div style={{flex: '0 1 0%'}}>Created</div>
                                                                <div>-></div>
                                                                <div style={{flex: '0 1 0%' }}>{constants.formatDateFullGB(formatDateFull(comment.created_at))}</div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </Col>
                                        </Row>
                                            <Row>
                                                <Col md={3}>
                                                    <h3>Comments</h3>
                                                    <Button className={'main-color'} onClick={() => newComment()}>New comment</Button>
                                                </Col>
                                                <Col  style={{ overflowY: 'scroll', height: '250px', border: '1px gray solid', marginBottom: '5px' }} md={9}>
                                                    {task.task_comments.length > 0 && task.task_comments.map((comment, index) => {
                                                        return (
                                                            <div key={index} style={{ marginTop: 20 }}>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        whiteSpace: 'nowrap',
                                                                    }}
                                                                >
                                                                    <div style={{ flex: '0 1 0%' }}>{comment?.owner?.name}</div>
                                                                    <div>-></div>
                                                                    <div style={{flex: '0 1 0%'}}>{comment.comment}</div>
                                                                    <div>-></div>
                                                                    <div style={{flex: '0 1 0%' }}>{constants.formatDateFullGB(comment?.updated_at)}</div>
                                                                    <div style={{marginLeft: 45}}>
                                                                        <span className="edit-comment badge-success" onClick={() => editComment(comment)}>Edit</span>
                                                                        <span className="edit-comment badge-danger" style={{marginLeft: 10}} onClick={() => {
                                                                            setComment(comment)
                                                                            setConfirmModal({
                                                                                ...confirmModal,
                                                                                show: true,
                                                                            })
                                                                        }}>Delete</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </Col>
                                            </Row>

                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Modal
                    isOpen={commentModal.show}
                    toggle={() => setCommentModal({
                        ...commentModal,
                        show: false,
                    })}
                    className="modal-dialog-centered"
                >
                    <div className="modal-header">
                        <h6 className="modal-title main-color">{commentModal.header}</h6>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setCommentModal({
                                ...commentModal,
                                show: false,
                            })}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <InputCustom
                            newLabel={`Comment`}
                            value={comment && comment.comment ? comment.comment : ''}
                            onChange={e => {
                                setComment({
                                    ...comment,
                                    comment: e.target.value
                                })
                            }}
                        />
                    </div>
                    <div className="modal-footer">
                        <Button className="w-25" color="lighte" type="button" o onClick={() => setCommentModal({
                            ...commentModal,
                            show: false,
                        })}>Cancel</Button>
                        <Button className="w-25" color="warning"  onClick={() => commentModal.division === 'create'?
                            createComment() : updateComment()}>Save</Button>
                    </div>
                </Modal>
                <Modal
                    isOpen={confirmModal.show}
                    toggle={() => setConfirmModal({
                        ...confirmModal,
                        show: false,
                    })}
                    className="modal-dialog-centered"
                >
                    <div className="modal-header">
                        <h6 className="modal-title">{`Delete comment`}</h6>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setConfirmModal({
                                ...confirmModal,
                                show: false,
                            })}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {`Are you sure you want to delete this comment?`}
                    </div>
                    <div className="modal-footer">
                        <Button
                            className="new-event--add"
                            color="danger"
                            type="button"
                            onClick={() => deleteComment()}
                        >
                            {`Delete`}
                        </Button>
                        <Button
                            className="ml-auto"
                            color="link"
                            type="button"
                            onClick={() => setConfirmModal({
                                ...confirmModal,
                                show: false,
                            })}
                        >
                            {`Close`}
                        </Button>
                    </div>
                </Modal>
            </Container>
        </>
    );
}