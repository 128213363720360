import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import * as actions from '@redux/Payments/actions';
import CardsHeader from 'layouts/Headers/CardsHeader.js';
import cloneDeep from 'lodash/cloneDeep';
import { Notification } from "@pages/components/Notification";

import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody, Modal, Button, Input, Label, FormGroup
} from "reactstrap";
import { override } from "@pages/reusable-components/styled-components";
import CustomTabs from "@pages/components/CustomTabs";
import { ClipLoader } from "react-spinners";
import usePrevious from "../../../hooks/useprevious";
import DatePicker from "react-datepicker";
import moment from "moment";

import { formatDate, API_URL, formatDateShortGB } from "../../../constants";
import axios from "axios";
import NestedTable from "@pages/components/Table/NestedTable";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";
import * as constants from "../../../constants";
import Cookies from "js-cookie";
import { ArraySelect } from "@pages/components/InputSelect";
import SendForm from "@pages/customers/customer/emails/SendForm";
import EmailsTab from "@pages/customers/customer/emails/emails";

export default function PaymentsList() {
    const dispatch = useDispatch();
    const notificationAlert = useRef(null);

    const history = useHistory();

    const {
        getPaymentsListSuccess,
        getPaymentsListError,
        updatePaymentNotesSuccess,
        updatePaymentNotesError,
        updatePaymentSuccess,
        updatePaymentError,
        payments,
        deletePaymentSuccess,
        updateGroupPaymentsError,
        updateGroupPaymentsSuccess,
        deletePaymentError,
        updatePartialPaymentSuccess,
        updatePartialPaymentError,
        updateExpectedPaymentSuccess,
        updateExpectedPaymentError,
        getCustomerPaymentNotesSuccess,
        getCustomerPaymentNotesError,
        customerPaymentNotes,
    } = useSelector((state) => state.paymentState, shallowEqual);

    const profileData = useSelector((state) => state.getState).profileData;


    const getPaymentsListSuccessPrev = usePrevious(getPaymentsListSuccess)
    const getPaymentsListErrorPrev = usePrevious(getPaymentsListError)

    const updateGroupPaymentsSuccessPrev = usePrevious(updateGroupPaymentsSuccess)
    const updateGroupPaymentsErrorPrev = usePrevious(updateGroupPaymentsError)

    const deletePaymentSuccessPrev = usePrevious(deletePaymentSuccess)
    const deletePaymentErrorPrev = usePrevious(deletePaymentError)

    const getCustomerPaymentNotesSuccessPrev = usePrevious(getCustomerPaymentNotesSuccess)
    const getCustomerPaymentNotesErrorPrev = usePrevious(getCustomerPaymentNotesError)

    const updatePaymentNotesSuccessPrev = usePrevious(updatePaymentNotesSuccess)
    const updatePaymentNotesErrorPrev = usePrevious(updatePaymentNotesError)

    const updatePaymentSuccessPrev = usePrevious(updatePaymentSuccess)
    const updatePaymentErrorPrev = usePrevious(updatePaymentError)

    const updatePartialPaymentSuccessPrev = usePrevious(updatePartialPaymentSuccess)
    const updatePartialPaymentErrorPrev = usePrevious(updatePartialPaymentError)

    const updateExpectedPaymentSuccessPrev = usePrevious(updateExpectedPaymentSuccess)
    const updateExpectedPaymentErrorPrev = usePrevious(updateExpectedPaymentError)

    const [loading, setLoading] = useState(true);
    const [sortObject, setSortObject] = useState({})

    const [filter, setFilter] = useState({
        is_debt_collected: 0,
        year: null,
        month: null,
        search: null,
        orderKey: 'expected_payment_date',
        type: '',
        orderValue: 'desc',
        page: 1,
        due_date: null,
        expected_payment_date: null,
        payment_manager_id: null
    });

    const [tableData, setTableData] = useState([]);
    const [tableDataDraft, setTableDataDraft] = useState('');
    const [managers, setManagers] = useState([]);
    const [isStatusModalOpened, setIsStatusModalOpened] = useState(false);
    const [isGroupModalOpened, setIsGroupModalOpened] = useState(false);
    const [groupStatusModalData, setGroupStatusModalData] = useState({ status: '', index: '', id: '', count: '', group_date: '', ids: [] });
    const [isNotesModalOpened, setIsNotesModalOpened] = useState(false);
    const [isPayModalOpened, setIsPayModalOpened] = useState(false);
    const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [selectedAction, setSelectedAction] = useState('');
    const [selectedStatusData, setSelectedStatusData] = useState({})
    const [partPaymentData, setPartPaymentData] = useState({})
    const [newNote, setNewNote] = useState('')
    const [selectedNotesData, setSelectedNotesData] = useState([])
    const [tableActions, setTableActions] = useState([])
    const [deletedNotes, setDeletedNotes] = useState([])
    const [selectedCustomerId, setSelectedCustomerId] = useState(null)
    const [pagination, setPagination] = useState(null)
    const [deleteId, setDeleteId] = useState(null)
    const [activeTab, setActiveTab] = useState('missed payments');
    const [expectedPayments, setExpectedPayments] = useState([])
    const [selectedDay, setSelectedDay] = useState(null);
    const [activeCustomerId, setActiveCustomerId] = useState(null);
    const [isNestedOpened, setIsNestedOpened] = useState({});
    const [selectedCustomerMailData, setSelectedCustomerMailData] = useState({})
    const [openSendMailModal, setOpenSendMailModal] = useState(false);
    const [showCustomerMails, setShowCustomerMails] = useState(false);

    const parentKeys = useMemo(() => {
        let tableKeys = [
            { key: 'full_name', label: 'Customer name' },
            { key: 'missed_payments_count', label: 'Missed Payments ' },
            { key: 'due_date', label: 'Due Date', sort: true },
            { key: 'expected_payment_date', label: 'Expected Payment', sort: true },

        ]
        if (activeTab === 'immobilised') {
            tableKeys.push({ key: 'immobilised_date', label: 'Immobilised Date' },)
        }
        tableKeys.push(
            { key: 'openModal', label: 'View notes' },
            { key: 'payments_sum_amount', label: 'Total Owed' },
            { key: 'partials_sum_amount', label: 'Total Part paid' },
            { key: 'openMailModal', label: 'Send Mail' },
            { key: 'show_diff', label: 'Total Remaining' },
            { key: 'open_nested', label: 'Action' },
        )

        return tableKeys;
    }, [activeTab])

    const tableKeys = useMemo(() => {
        let tableKeys = [
            { key: 'invoice_number', label: 'invoice no' },
            { key: 'vehicle', label: 'Reg no' },
            { key: 'type', label: 'Type' },
            { key: 'amount', label: 'Amount' },
        ];
        let tempTableActions = []
        if (activeTab === 'missed payments') {
            tempTableActions.push(
                {
                    value: 'paid',
                    text: 'Paid',
                },
                {
                    value: 'immobilised',
                    text: 'Immobilised',
                },
                {
                    value: 'arrange repo',
                    text: 'Arrange repo',
                },
                {
                    value: 'collected',
                    text: 'Repo',
                },
                {
                    value: 'other',
                    text: 'Other',
                },
            )
            tableKeys.push(
                { key: 'due_date', label: 'Due date' },
                { key: 'expected_payment_date', label: 'Expected Payment', date: true },
                { key: 'partials_amount', label: 'Part Paid amount', first: true },
                { key: 'payAction', label: 'Part Paid', className: 'blue' },
                { key: 'delete', label: 'Action', className: 'red' },

            )
        } else if (activeTab === 'immobilised') {
            tempTableActions.push({
                value: 'paid',
                text: 'Paid',
            },
                {
                    value: 'arrange repo',
                    text: 'Arrange repo',
                },
                {
                    value: 'collected',
                    text: 'Repo',
                },
                {
                    value: 'other',
                    text: 'Other',
                },
            )
            tableKeys.push(
                { key: 'due_date', label: 'Due date' },
                { key: 'expected_payment_date', label: 'Expected Payment', date: true },
                { key: 'immobilised_date', label: 'Immobilised Date' },
                { key: 'payAction', label: 'Part Paid', className: 'blue' },
            )
        } else if (activeTab === 'arrange_repo') {
            tempTableActions.push({
                value: 'paid',
                text: 'Paid',
            },
                {
                    value: 'immobilised',
                    text: 'Immobilised',
                },
                {
                    value: 'collected',
                    text: 'Repo',
                },
                {
                    value: 'other',
                    text: 'Other',
                },
            )
            tableKeys.push(
                { key: 'due_date', label: 'Due date' },
                { key: 'arrange_repo_date', label: 'Arrange Repo Date' },
                { key: 'payAction', label: 'Part Paid', className: 'blue' },
            )
        } else if (activeTab === 'repo') {
            tempTableActions.push({
                value: 'paid',
                text: 'Paid',
            },
                {
                    value: 'immobilised',
                    text: 'Immobilised',
                },
                {
                    value: 'arrange repo',
                    text: 'Arrange repo',
                },
            )
            tableKeys.push(
                { key: 'due_date', label: 'Due date' },
                { key: 'expected_payment_date', label: 'Expected Payment', date: true },
                { key: 'repo_date', label: 'Repo Date' },
            )
        } else if (activeTab === 'paid') {
            tempTableActions.push(
                {
                    value: 'immobilised',
                    text: 'Immobilised',
                },
                {
                    value: 'arrange repo',
                    text: 'Arrange repo',
                },
                {
                    value: 'collected',
                    text: 'Repo',
                })
            tableKeys.push(
                { key: 'due_date', label: 'Due date' },
                { key: 'expected_payment_date', label: 'Expected Payment', date: true },
                { key: 'paid_date', label: 'Paid Date' },
            )
        } else if (activeTab === 'other') {
            tempTableActions.push(
                {
                    value: 'paid',
                    text: 'Paid',
                },
                {
                    value: 'immobilised',
                    text: 'Immobilised',
                },
                {
                    value: 'arrange repo',
                    text: 'Arrange repo',
                },
                {
                    value: 'collected',
                    text: 'Repo',
                },

            )
            tableKeys.push(
                { key: 'due_date', label: 'Due date' },
                { key: 'expected_payment_date', label: 'Expected Payment', date: true },
                { key: 'partials_amount', label: 'Part Paid amount', first: true },
                { key: 'payAction', label: 'Part Paid', className: 'blue' },
                { key: 'delete', label: 'Action', className: 'red' },
            )
        }
        tableKeys.push(
            { key: 'action_select', label: 'Move To' },
        )
        setTableActions(tempTableActions)
        return tableKeys;
    }, [activeTab])

    const [tabs] = useState([
        'missed payments',
        'immobilised',
        'arrange_repo',
        'repo',
        'other',
    ]);
    const sort = (data) => {
        setSortObject(data)
        let tempFilter = { ...filter }
        tempFilter.orderValue = Object.values(data)[0]
        tempFilter.orderKey = Object.keys(data)[0]
        setFilter(tempFilter)
        fetchDetails(activeTab, tempFilter)
    }
    const toggleStatusModal = () => {
        setIsStatusModalOpened(!isStatusModalOpened)
    }
    const clearGroupStatusModalData = () => {
        setGroupStatusModalData({ status: '', id: '', index: '', count: '', ids: [], group_date: '' })
    }
    const toggleGroupModal = () => {
        setIsGroupModalOpened(!isGroupModalOpened)
    }
    const toggleDeleteModal = () => {
        setIsDeleteModalOpened(!isDeleteModalOpened)
    }
    const toggleNotesModal = () => {
        setIsNotesModalOpened(!isNotesModalOpened)
    }
    const onNotesModalClosed = () => {
        setSelectedNotesData([])
        setDeletedNotes([])
        setSelectedCustomerId(null)
    }
    const togglePayModal = () => {
        setIsPayModalOpened(!isPayModalOpened)
    }
    const updatePartPayment = () => {
        dispatch({
            type: actions.UPDATE_PARTIAL_PAYMENT_REQUEST,
            data: partPaymentData,
        });
        togglePayModal()
    }

    const selectDate = (obj) => {
        setSelectedStatusData({ ...selectedStatusData, [obj.key]: moment({ ...obj.value, month: obj.value.month - 1, }).format('YYYY-MM-DD') })
    }
    const selectGroupDate = (obj) => {
        setGroupStatusModalData({ ...groupStatusModalData, [obj.key]: obj.value })
    }
    const saveExpectedPayments = () => {
        dispatch({
            type: actions.UPDATE_EXPECTED_PAYMENT_REQUEST,
            data: expectedPayments,
        });
    }
    const updateNotes = () => {
        let data = {
            notes: selectedNotesData,
            deleted: deletedNotes,
            customer_id: selectedCustomerId
        }
        dispatch({
            type: actions.UPDATE_PAYMENT_NOTES_REQUEST,
            data,
        });
        toggleNotesModal()
    }
    const checkDelete = (id, index) => {
        setDeleteId({ id, index })
        toggleDeleteModal()
    }

    const downloadInvoice = (idx) => {
        let row = tableData[idx]
        let token = 1;
        axios({
            method: 'GET',
            url: API_URL + '/deal/' + row.deal_id + '/invoice/' + row.deal_invoice_id,
            headers: {
                Authorization: 'Bearer ' + token
            },
        })
            .then(response => {
                if (response.status === 200) {
                    let data = response.data;
                    let invoice = data.data.invoice;
                    window.open(invoice, "_blank");
                }
            })
            .catch(error => {
                console.error('error :: ', error.response);
                if (error.response.status === 422) {
                }
                Notification('warning', 'Error', 'Something went wrong', notificationAlert);
            })
    }

    const deletePayment = () => {
        let data = {
            id: deleteId.id
        }
        dispatch({
            type: actions.DELETE_PAYMENT_REQUEST,
            data,
        });
    }
    const setStatus = (status) => {
        setSelectedStatusData(status)
        toggleStatusModal()
    }

    const payAction = (data) => {
        setPartPaymentData(data)
        togglePayModal()
    }
    const openNotesModal = (id) => {
        dispatch({
            type: actions.GET_CUSTOMER_PAYMENTS_NOTES_REQUEST,
            data: id,
        });
        toggleNotesModal()
        setModalLoading(true)
        setSelectedCustomerId(id)
    }
    const selectAction = (data) => {
        const updatedSelectedAction = { ...selectedAction };
        updatedSelectedAction[data.id] = data.value;
        setSelectedAction(updatedSelectedAction);
    }
    const paginate = (page) => {
        let tempFilter = { ...filter }
        tempFilter.page = page
        setFilter(tempFilter)
        fetchDetails(activeTab, tempFilter);
    }
    const changeGroupStatus = (id) => {
        setGroupStatusModalData({ status: selectedAction[id], id: id })
        toggleGroupModal()
    }
    const openMailModal = (data) => {
        let tempData = tableData[data.index]
        setSelectedCustomerMailData({
            resource_id: tempData.id,
            resource_type: 'Missed Payments',
            to: [tempData.email],
        })
        setOpenSendMailModal(true)
    }
    const viewMails = (data) => {
        setActiveCustomerId(tableData[data.index].id)
        setShowCustomerMails(true)
    }
    const toggleOpenMailModal = () => {
        setOpenSendMailModal(!openSendMailModal)
    }
    const toggleShowCustomerMails = () => {
        setShowCustomerMails(!showCustomerMails)
    }
    const updateStatus = () => {
        dispatch({
            type: actions.UPDATE_PAYMENT_REQUEST,
            data: selectedStatusData,
        });
        toggleStatusModal()
    }

    const fetchDetails = (status = null, currentFilter = null) => {
        dispatch({
            type: actions.GET_PAYMENT_LIST_REQUEST,
            url: null,
            data: {
                ...(currentFilter ? currentFilter : filter),
                status,
            },
        });
    }

    useEffect(() => {
        if (activeTab) {
            setLoading(true);
            fetchDetails(activeTab);
            setFilter({ ...filter, month: null, year: null });
        }
    }, [activeTab]);

    useEffect(() => {
        if (deletePaymentSuccess && deletePaymentSuccessPrev === false) {
            setDeleteId(null)
            setLoading(true);
            fetchDetails(activeTab);
            toggleDeleteModal()
        }
    }, [deletePaymentSuccess])

    useEffect(() => {
        if (deletePaymentError && !deletePaymentErrorPrev) {
            setDeleteId(null)
            toggleDeleteModal()
        }
    }, [deletePaymentError])



    useEffect(() => {
        if (getPaymentsListSuccess && getPaymentsListSuccessPrev === false) {
            let tempData = payments.data.data
            setPagination(payments.data)
            let nestedTablesAction = {}
            tempData.forEach(item => {
                nestedTablesAction[item.id] = false
            })
            setIsNestedOpened(nestedTablesAction)
            setTableData(tempData)
            setTableDataDraft(JSON.stringify(tempData))
            setLoading(false)
        }
    }, [getPaymentsListSuccess])
    useEffect(() => {
        if (getPaymentsListError && !getPaymentsListErrorPrev) {
            Notification('warning', 'Error', 'Something went wrong', notificationAlert);
            setLoading(false)
        }
    }, [getPaymentsListError])

    useEffect(() => {
        if (updatePaymentNotesSuccess && updatePaymentNotesSuccessPrev === false) {
            Notification('success', 'Success', 'Notes updated successfully.', notificationAlert);
        }
    }, [updatePaymentNotesSuccess])

    useEffect(() => {
        if (getCustomerPaymentNotesSuccess && getCustomerPaymentNotesSuccessPrev === false) {
            setModalLoading(false)
            setSelectedNotesData(customerPaymentNotes)
        }
    }, [getCustomerPaymentNotesSuccess, customerPaymentNotes])
    useEffect(() => {
        if (getCustomerPaymentNotesError && getCustomerPaymentNotesErrorPrev === false) {
            toggleNotesModal()
            setModalLoading(false)
        }
    }, [getCustomerPaymentNotesError])

    useEffect(() => {
        if (updatePaymentNotesError && !updatePaymentNotesErrorPrev) {
            Notification('warning', 'Error', 'Something went wrong', notificationAlert);

        }
    }, [updatePaymentNotesError])

    const updateStatuses = () => {
        setLoading(true);
        dispatch({
            type: actions.UPDATE_GROUP_PAYMENTS_STATUS_REQUEST,
            data: { status: groupStatusModalData.status, id: groupStatusModalData.id, is_debt_collected: 0, group_date: groupStatusModalData.group_date },
        });
    }

    useEffect(() => {
        if (updateGroupPaymentsSuccess && updateGroupPaymentsSuccessPrev === false) {
            clearGroupStatusModalData()
            toggleGroupModal()
            fetchDetails(activeTab, filter);
        }
    }, [updateGroupPaymentsSuccess, updateGroupPaymentsSuccessPrev])

    useEffect(() => {
        if (updateGroupPaymentsError && updateGroupPaymentsErrorPrev === false) {
            setLoading(false)
            clearGroupStatusModalData()
            toggleGroupModal()
        }
    }, [updateGroupPaymentsError, updateGroupPaymentsErrorPrev])

    useEffect(() => {
        if (updatePaymentSuccess && updatePaymentSuccessPrev === false) {
            fetchDetails(activeTab, filter);
        }
    }, [updatePaymentSuccess])

    useEffect(() => {
        if (updatePaymentError && !updatePaymentErrorPrev) {
            Notification('warning', 'Error', 'Something went wrong', notificationAlert);
        }
    }, [updatePaymentError])

    useEffect(() => {
        if (updatePartialPaymentSuccess && updatePartialPaymentSuccessPrev === false) {
            fetchDetails(activeTab, filter);
        }
    }, [updatePartialPaymentSuccess])
    useEffect(() => {

        if (updatePartialPaymentError && !updatePartialPaymentErrorPrev) {
            Notification('warning', 'Error', 'Something went wrong', notificationAlert);
        }
    }, [updatePartialPaymentError])

    useEffect(() => {
        if (updateExpectedPaymentSuccess && updateExpectedPaymentSuccessPrev === false) {
            fetchDetails(activeTab, filter);
        }
    }, [updateExpectedPaymentSuccess])
    useEffect(() => {

        if (updateExpectedPaymentError && !updateExpectedPaymentErrorPrev) {
            Notification('warning', 'Error', 'Something went wrong', notificationAlert);
        }
    }, [updateExpectedPaymentError])

    useEffect(() => {
        const grs_token = Cookies.get('grs_token');
        axios({
            method: 'GET',
            url: `${constants.API_CORE_URL}/account-managers`,
            headers: {
                Authorization: `Bearer ${grs_token}`,
            },
        }).then((res) => {
            let tempManagers = res.data.data.paymentManagers;
            let manager = tempManagers.find(item => item.id === profileData.id)
            let id = manager ? manager.id : null
            setFilter({ ...filter, payment_manager_id: id })
            setManagers(tempManagers)
        }).catch((error) => {
        })
    }, [])
    const addNewNote = () => {
        let tempData = [...selectedNotesData]
        tempData.push({
            note: newNote,
            status: 'new'
        })
        setSelectedNotesData(tempData)
        setNewNote('')
    }
    const removeNote = (index) => {
        let tempData = [...selectedNotesData]
        let dNote = tempData.splice(index, 1);
        if (dNote[0].hasOwnProperty('customer_id')) {
            setDeletedNotes([...deletedNotes, dNote[0].id])
        }
        setSelectedNotesData(tempData)
    }
    const selectExpectedPayment = (idx, data) => {
        let expectedPayment = data.value
        let tempDates = [...expectedPayments]
        let index = expectedPayments.findIndex(({ id }) => id === data.id)
        if (index > -1) {
            tempDates[index].expected_payment_date = expectedPayment
        } else {
            tempDates.push({
                id: data.id,
                expected_payment_date: expectedPayment
            })
        }
        setExpectedPayments(tempDates)
        let tempTableData = cloneDeep(tableData)
        let tableIndex = tempTableData[idx].payments.findIndex(({ id }) => id === data.id)
        tempTableData[idx].payments[tableIndex]['expected_payment_date'] = expectedPayment
        setTableData(tempTableData)
    }
    return (

        <>

            <Modal
                isOpen={showCustomerMails}
                toggle={() => toggleShowCustomerMails()}
                className="disable-scroll modal-dialog-centered modal-lg"
            >

                <div className="modal-body">
                    <EmailsTab
                        showSendButton={false}
                        customerId={activeCustomerId}
                    />
                </div>
                <div className="modal-footer mb-3">
                    <Button
                        className="ml-auto"
                        color="danger"
                        type="button"
                        onClick={() => {
                            toggleShowCustomerMails()
                        }}
                    >
                        {`Close`}
                    </Button>

                </div>
            </Modal>
            <Modal
                isOpen={openSendMailModal}
                toggle={() => toggleOpenMailModal()}
                className="disable-scroll modal-dialog-centered modal-lg"
            >
                <div className="modal-header">
                    <h3>{`Are you sure ?`}</h3>
                </div>
                <div className="modal-body">
                    <SendForm
                        save={toggleOpenMailModal}
                        sendEmailDataOrigin={selectedCustomerMailData}
                    />
                </div>
                <div className="modal-footer mb-3">
                    <Button
                        className="ml-auto"
                        color="danger"
                        type="button"
                        onClick={() => {
                            setDeleteId(null)
                            toggleOpenMailModal()
                        }}
                    >
                        {`Close`}
                    </Button>

                </div>
            </Modal>
            <Modal
                isOpen={isGroupModalOpened}
                toggle={() => toggleGroupModal()}
                className="disable-scroll modal-dialog-centered modal-md"
            >
                <div className="modal-header">
                    <h3>{`Are you sure ?`}</h3>
                </div>
                <div className="modal-body">
                    <h4>{`You want to change payment status`}</h4>
                    {
                        groupStatusModalData.status !== 'other' && <><h4>{`Please enter ${groupStatusModalData.status} date`}</h4>

                            < div className={'d-flex justify-content-center'}>
                                <div style={{ position: 'absolute', zIndex: 15 }}>
                                    <DatePicker
                                        value={selectedDay ? formatDateShortGB(selectedDay) : ''}
                                        onChange={(e) => {
                                            setSelectedDay(e)
                                            selectGroupDate({ key: `group_date`, value: moment(e).format('YYYY-MM-DD') })
                                        }}
                                        shouldHighlightWeekends
                                    />
                                </div>
                            </div>
                        </>
                    }

                </div>
                <div className="modal-footer mb-3">
                    <Button
                        className="ml-auto"
                        color="danger"
                        type="button"
                        onClick={() => {
                            clearGroupStatusModalData()
                            toggleGroupModal()
                        }}
                    >
                        {`Close`}
                    </Button>
                    <Button
                        className="new-event--add"
                        color="success"
                        type="button"
                        disabled={!groupStatusModalData.group_date && groupStatusModalData.status !== 'other'}
                        onClick={() => updateStatuses()}
                    >
                        {`Save`}
                    </Button>
                </div>
            </Modal>

            <Modal
                isOpen={isStatusModalOpened}
                toggle={() => toggleStatusModal()}
                className="disable-scroll modal-dialog-centered modal-md"
            >
                <div className="modal-header">
                    <h3>{`Are you sure ?`}</h3>
                </div>
                <div className="modal-body">
                    <h4>{`You want to change payment status`}</h4>
                    <h4>{`Please enter ${selectedStatusData.status} date`}</h4>

                    <div className={'d-flex justify-content-center'}>
                        <div style={{ position: 'absolute', zIndex: 15 }}>
                            <DatePicker
                                value={selectedDay ? formatDate(selectedDay) : ''}
                                onChange={(e) => {
                                    setSelectedDay(e)
                                    selectDate({ key: `${selectedStatusData.status}_date`, value: moment(e).format('YYYY-MM-DD') })
                                }}
                                shouldHighlightWeekends
                            />
                        </div>
                    </div>

                </div>
                <div className="modal-footer mb-3">
                    <Button
                        className="ml-auto"
                        color="danger"
                        type="button"
                        onClick={() => {
                            setSelectedStatusData({})
                            setNewNote('')
                            setSelectedCustomerId(null)
                            toggleStatusModal()
                        }}
                    >
                        {`Close`}
                    </Button>
                    <Button
                        className="new-event--add"
                        color="success"
                        disabled={!selectedStatusData.hasOwnProperty(`${selectedStatusData.status}_date`)}
                        type="button"
                        onClick={() => updateStatus()}
                    >
                        {`Save`}
                    </Button>
                </div>
            </Modal>
            <Modal
                isOpen={isPayModalOpened}
                toggle={() => togglePayModal()}
                className="disable-scroll modal-dialog-centered modal-md"
            >
                <div className="modal-header">
                    <h4>{`You want to change add partial payment`}</h4>
                </div>
                <div className="modal-body">
                    <div className={'d-flex justify-content-start mb-3'}>
                        <div style={{ position: 'absolute', zIndex: 15 }}>
                            <Input
                                value={partPaymentData?.amount ? partPaymentData?.amount : ''}
                                placeholder={'Add partial payment'}
                                onChange={e => setPartPaymentData({ ...partPaymentData, amount: e.target.value })}
                            />
                        </div>
                    </div>

                </div>
                <div className="modal-footer mb-3">
                    <Button
                        className="ml-auto"
                        color="danger"
                        type="button"
                        onClick={() => {
                            togglePayModal()
                            setPartPaymentData({})
                        }}
                    >
                        {`Close`}
                    </Button>
                    <Button
                        className="new-event--add"
                        color="success"
                        type="button"
                        onClick={() => updatePartPayment()}
                    >
                        {`Save`}
                    </Button>
                </div>
            </Modal>
            <Modal
                isOpen={isDeleteModalOpened}
                toggle={() => toggleDeleteModal()}
                className="disable-scroll modal-dialog-centered modal-md"
            >
                <div className="modal-header">
                    <h3>{`Are you sure ?`}</h3>
                </div>
                <div className="modal-body">
                    <h4>{`You want to delete payment`}</h4>
                </div>
                <div className="modal-footer mb-3">
                    <Button
                        className="ml-auto"
                        color="danger"
                        type="button"
                        onClick={() => {
                            setDeleteId(null)
                            toggleDeleteModal()
                        }}
                    >
                        {`Close`}
                    </Button>
                    <Button
                        className="new-event--add"
                        color="success"
                        type="button"
                        onClick={() => deletePayment()}
                    >
                        {`Save`}
                    </Button>
                </div>
            </Modal>

            <Modal
                isOpen={isNotesModalOpened}
                onClosed={onNotesModalClosed}
                toggle={() => toggleNotesModal()}
                className="modal-dialog-centered modal-lg"
            >
                <div className="modal-header">
                    <h3>{`Notes for payment`}</h3>
                </div>
                <div className="modal-body">
                    {
                        modalLoading ? <ClipLoader
                            css={override}
                            size={40}
                            color={`#7B61E4`}
                            loading={modalLoading}
                        /> : <Row>
                            <Col lg={4} >
                                <Col>
                                    <Input
                                        value={newNote}
                                        placeholder={'write a note'}
                                        onChange={e => setNewNote(e.target.value)}
                                    />
                                    <Button className={'mt-2 mb-2'} onClick={addNewNote}>
                                        Add Note
                                    </Button>
                                    <Button
                                        className="ml-auto"
                                        color="danger"
                                        type="button"
                                        onClick={() => {
                                            toggleNotesModal()
                                        }}
                                    >
                                        {`Close`}
                                    </Button>
                                    <Button
                                        className="new-event--add"
                                        color="success"
                                        type="button"
                                        onClick={updateNotes}
                                    >
                                        {`Save`}
                                    </Button>
                                </Col>
                            </Col>
                            <Col className={'overflow-auto'} lg={8}>
                                {selectedNotesData.length ?
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr className={'reports-table-header'}>
                                                <th className={'header-text'}>
                                                    Date
                                                </th>
                                                <th className={'header-text'}>
                                                    Note
                                                </th>

                                                <th className={'header-text'}>
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                selectedNotesData.map((note, index) => {
                                                    return <tr key={index}>
                                                        <td>
                                                            <h4>{note.hasOwnProperty('created_at') ? formatDate(note?.created_at) : 'Now'}</h4>
                                                        </td>
                                                        <td>
                                                            <h4>{note?.note}</h4>
                                                        </td>

                                                        <td>
                                                            <IcoMoon
                                                                className={'cursor-pointer'}
                                                                iconSet={IconSet}
                                                                icon={'delete_outline'}
                                                                size={20}
                                                                onClick={() => removeNote(index)}
                                                            />

                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table> : null}
                            </Col>
                        </Row>
                    }

                </div>
                <div className="modal-footer mb-3">

                </div>
            </Modal>
            <CardsHeader name="Payments Management" parentName="Payments Management" currentName="List" />
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlert} />
            </div>
            <Container className="mt--5 admin-main-body" fluid >
                <Row>
                    <Col xl="12">
                        <Card>
                            <CardHeader className={'border-bottom-0'}>
                                <Row>
                                    <Col lg={8} sm={12}>
                                        <CustomTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
                                    </Col>
                                    <Col lg={4} sm={12}>
                                        <ArraySelect
                                            label={'Payment Manager'}
                                            options={managers}
                                            value={filter.payment_manager_id}
                                            onChange={(e) => {
                                                const tempFilter = { ...filter }
                                                tempFilter.payment_manager_id = e.target.value
                                                setFilter(tempFilter)
                                                fetchDetails(activeTab, tempFilter)
                                            }
                                            }
                                            defaultOption={true}
                                            valueKey={`id`}
                                            labelKey={`name`}
                                            errorMessage={'Payment Manager'}
                                        />
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col lg={3} md={6} sm={12} className="mb-2">
                                        <div className="d-flex mb-2 text-center">
                                            <Input
                                                placeholder="Search..."
                                                value={filter.search ?? ''}
                                                type={`text`}
                                                onChange={(e) => setFilter({ ...filter, search: e.target.value })}
                                            />
                                        </div>
                                    </Col>


                                    <Col lg={3} md={6} sm={12} >
                                        <div className={'d-flex g-10 align-items-center'}>
                                            <Label className="form-control-label" for={'expected_payment_date'}>Expected Date: </Label>
                                            <Input type={'date'}
                                                value={filter.expected_payment_date ? formatDate(filter.expected_payment_date) : ''}
                                                onChange={(e) => {
                                                    setFilter({ ...filter, expected_payment_date: moment(e.target.value).format('YYYY-MM-DD') })
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={3} md={6} sm={12} >
                                        <FormGroup className={'d-flex g-10 align-items-center'}>
                                            <Label className="form-control-label" >Due Date: </Label>
                                            <Input type={'date'}
                                                value={filter.due_date ? formatDate(filter.due_date) : ''}
                                                onChange={(e) => {
                                                    setFilter({ ...filter, due_date: moment(e.target.value).format('YYYY-MM-DD') })
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg={3} sm={12}>
                                        <Button
                                            className="ml-3"
                                            type="button"
                                            onClick={() => {
                                                setFilter({ ...filter, month: null, year: null, search: null, type: null, expected_payment_date: null, due_date: null });
                                            }}
                                        >
                                            Clear
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                const tempFilter = { ...filter }
                                                tempFilter.page = null
                                                setFilter(tempFilter)
                                                fetchDetails(activeTab, tempFilter)
                                            }}
                                        >
                                            Search
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                            {
                                loading ?
                                    <ClipLoader
                                        css={override}
                                        size={40}
                                        color={`#7B61E4`}
                                        loading={loading}
                                    />
                                    :
                                    <CardBody>
                                        <NestedTable
                                            pagination={pagination}
                                            openMailModal={openMailModal}
                                            viewMails={viewMails}
                                            parentKeys={parentKeys}
                                            changeGroupStatus={changeGroupStatus}
                                            tableActions={tableActions}
                                            selectAction={selectAction}
                                            selectedAction={selectedAction}
                                            sortObject={sortObject}
                                            sort={sort}
                                            paginate={paginate}
                                            keys={tableKeys}
                                            tableData={tableData}
                                            selectDate={(idx, data) => selectExpectedPayment(idx, data)}
                                            openModal={openNotesModal}
                                            toDetails={setStatus}
                                            isNestedOpened={isNestedOpened}
                                            setIsNestedOpened={(id) => {
                                                setIsNestedOpened({
                                                    ...isNestedOpened,
                                                    [id]: !isNestedOpened[id]
                                                })
                                            }}
                                            payAction={payAction}
                                            deleteRow={checkDelete}
                                            tabName={activeTab}
                                            downloadInvoice={downloadInvoice}
                                            ownerDetails={(id) => {
                                                history.push({
                                                    pathname: `/admin/customer/${id}/edit`,
                                                })
                                            }}
                                            nestedTableDataKey={'payments'}
                                        />

                                        <Row className={'mt-4 justify-content-end'}>
                                            <Button onClick={() => saveExpectedPayments()} disabled={tableDataDraft === JSON.stringify(tableData)}>Save</Button>
                                        </Row>
                                    </CardBody>
                            }

                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}