import axios from '../config/axios';

export const getDealerModules = (token) => {
  return axios({
    method: 'GET',
    url: `/dealer/modules`
  })
    .then(response =>  response.data).catch(err=>{
          console.log('err',err)
      });
}

export const getKashflowCustomer = (customer) => {
  return axios({
      method: 'GET',
      url: `/dealer/kashflow/${customer}/customer`
    })
      .then(response =>  response.data);
}

export const saveKashflowCustomer = (customer) => {
  return axios({
      method: 'POST',
      url: `/dealer/kashflow/${customer}/customer`
    })
      .then(response =>  response.data);
}

export const syncKashflowCustomer = (customer, code) => {
  return axios({
      method: 'POST',
      url: `/dealer/kashflow/${customer}/customer-sync`,
      data: {
        code
      }
    })
      .then(response =>  response.data);
}

export const saveDealSettings = (deal, settings) => {
  return axios({
      method: 'POST',
      url: `/deal/${deal}/payments/settings`,
      data: settings
    })
      .then(response =>  response.data);
}


export const getXeroCustomer = (customer) => {
  return axios({
      method: 'GET',
      url: `/dealer/xero/${customer}/customer`
    })
      .then(response =>  response.data);
}

export const saveXeroCustomer = (customerId, data) => {
  return axios({
      method: 'POST',
      url: `/dealer/xero/${customerId}/customer`,
      data: data
    })
      .then(response =>  response.data);
}