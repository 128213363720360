import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {
    FormGroup,
    Input,
    Label,
    Col,
    DropdownMenu,
    Nav,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Dropdown,
    CardBody,
     CardTitle, Button,
} from "reactstrap";
import {useHistory} from "react-router-dom";
import Select from "react-select";
import {formatDate} from "../../../constants";
import {Line, TaskDetailsContainer, Text, TextContainer} from "@pages/reusable-components/styled-components";
const SortContainer = styled.div`
   cursor: pointer;
`
const FilterContainer = styled.div`
  display:flex;
`
const SortText = styled.span``
const TextLocal = styled.span`
 font-size: ${props => props.size};
 font-weight: 600;
 line-height: ${props => props.lineHeight ? props.lineHeight : '4'};
 margin-left: ${props => props.marginLeft ? props.marginLeft : '22px'};
 margin-top: ${props => props.marginTop};
`
const SortIcon = styled.img`
 transform: ${props => props.iconPosition === 0 ? 'rotate(0)' : 'rotate(180deg)'} ;
 width: 20px;
 height: 20px;
`
const DescriptionContainer = styled.div`
   max-width: fit-content;
   margin-top: 10px;
   text-align: left;
   font-size: 12px;
`

const TaskTitleContainer = styled.span`
 background-color: #f2f2f2;
 color: #4e4e56;
 padding: 3px 10px;
 font-size: 12px;
 display: flex;
 align-items: center;
 border-radius: 4px;
`
const TimeContainer = styled.span`
 background-color: #e4f8f0;
 color: #247d5f;
 padding: 3px 10px;
 display: flex;
 align-items: center;
 justify-content: space-between;
 font-size: 12px;
 border-radius: 4px;
`
const PriorityContainer = styled.span`
 color: ${props => props.priority === 'high' ? '#ec6409' : props.priority === 'medium' ? '#ffbb31' : '#247d5f'};
 margin-top: 20px;
 display: flex;
 gap: 10px;
 align-items: center;
 font-size: 14px;
`
const TextContainerLocal = styled.div`
 display: flex;
 flex-direction: ${props => props.direction};
 justify-content: space-between
`


const CustomCard = styled.div`
    position: relative;
    display: flex;
    padding: ${props => props.padding};;
    width: ${props => props.width};
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0.375rem;
`
const FilterItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 15px;
    width: ${props => props.width};
`

const KeySelect = ({
                            label,
                            value,
                            onChange,
                            options,
                            disabled,
                            readOnly,
                            defaultOption,
                            required = false,
                            invalid,
                            errorMessage
                        }) => {
    const selectValue = (data) => {
        if (data.target.value === '-') {
            data.target.value = null
            return onChange(data)
        } else onChange(data)
    }
    return (
        <FormGroup className={'d-flex align-items-center g-5'}>
            <label className="form-control-label w-50">{label} {required ? <span className={'required-dot'}>*</span> : ''}</label>
          <div className={'w-100'}>
              <Input
                  type="select"
                  value={value === null ? '-' : value}
                  onChange={selectValue}
                  disabled={disabled}
                  readOnly={readOnly}
                  invalid={invalid}
              >
                  {defaultOption && <option>-</option>}
                  {options &&
                  Object.keys(options).map(key => {
                      return <option value={key} key={key}>{options[key]}</option>
                  })
                  }
              </Input>
              <div className="invalid-feedback">
                  {errorMessage}
              </div>
          </div>
        </FormGroup>
    )
}

const InputKeySelect = ({
                            label,
                            newLabel,
                            value,
                            onChange,
                            options,
                            disabled,
                            readOnly,
                            defaultOption,
                            invalid,
                            required = false,
                            errorMessage
                        }) => {
    return (
        <FormGroup className={`${newLabel ? 'd-flex align-items-center' : ''}`}>
            {label && !newLabel && <label className="form-control-label">{label}</label>}
            {newLabel && <label className="form-control-label w-50">{newLabel} {required ? <span className={'required-dot'}>*</span> : ''}</label>}
            <div className={'w-100'}>
                <Input
                    type="select"
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    readOnly={readOnly}
                    invalid={invalid}
                >
                    {defaultOption && <option>-</option>}
                    {options &&
                    Object.keys(options).map(key => {
                        return <option value={key} key={key}>{options[key]}</option>
                    })
                    }
                </Input>
                <div className="invalid-feedback">
                    {errorMessage}
                </div>
            </div>
        </FormGroup>

    )
}

const InputArraySelect = ({
                              label,
                              required = false,
                              value,
                              onChange,
                              options = [],
                              disabled,
                              readOnly,
                              defaultOption,
                              defaultOptionText = '-',
                              valueKey,
                              labelKey,
                              newLabel,
                              labelKey2,
                              invalid,
                              errorMessage
                          }) => {
    const selectValue = (data) => {
        if (data.target.value === '-') {
            data.target.value = null
            return onChange(data)
        } else onChange(data)
    }
    return (
        <div className={'d-flex align-items-center form-group'}>
            <label  className="form-control-label mr-1 w-50">{newLabel ?? label} {required ? <span className={'required-dot'}>*</span> : ''}</label>
            <Input
                type="select"
                value={value === null ? '-' : value}
                onChange={(e) =>{
                    if (e.target.value === defaultOptionText) {
                        selectValue({target: {value: null}})
                    } else {
                        selectValue(e)
                    }
                }}
                placeholder={'defaultOptionText'}
                disabled={disabled}
                readOnly={readOnly}
                invalid={invalid}
            >
                {defaultOption && <option>{defaultOptionText}</option>}
                {options && options.length > 0 &&
                options.map((item, index) => {
                    return <option value={item[valueKey]}
                                   key={index}>{labelKey2 ? `${item[labelKey]}, ${item[labelKey2].name}` : item[labelKey]}</option>
                })
                }
            </Input>
            <div className="invalid-feedback">
                {errorMessage}
            </div>
        </div>
    )
}

const ArraySelect = ({
                              label,
                              required = false,
                              value,
                              onChange,
                              options = [],
                              disabled,
                              readOnly,
                              defaultOption,
                              valueKey,
                              labelKey,
                              labelKey2,
                              invalid,
                              noBottom = false,
                              errorMessage
                          }) => {
    const selectValue = (data) => {
        if (data.target.value === '-') {
            data.target.value = null
            return onChange(data)
        } else onChange(data)
    }
    return (
        <FormGroup className={'d-flex align-items-center' + (noBottom ? ' mb-0' : '')}>
           <label className="form-control-label w-50">{label} {required ? <span className={'required-dot'}>*</span> : ''}</label>
            <div className={'w-100'}>
                <Input
                    type="select"
                    value={value === null ? '-' : value}
                    onChange={selectValue}
                    disabled={disabled}
                    readOnly={readOnly}
                    invalid={invalid}
                >
                    {defaultOption && <option>-</option>}
                    {options && options.length > 0 &&
                    options.map((item, index) => {
                        return <option value={item[valueKey]}
                                       key={index}>{labelKey2 ? `${item[labelKey]}, ${item[labelKey2].name}` : item[labelKey]}</option>
                    })
                    }
                </Input>
                <div className="invalid-feedback">
                    {errorMessage}
                </div>
            </div>
        </FormGroup>
    )
}
const InputKeySideSelect = ({
                                label,
                                value,
                                onChange,
                                options,
                                disabled,
                                readOnly,
                                defaultOption,
                                invalid,
                                required = false,
                                errorMessage
                            }) => {
    return (
        <FormGroup className="row">
            <Label
                className="form-control-label"
                md="3"
            >
                {label}  {required ? <span className={'required-dot'}>*</span> : ''}
            </Label>
            <Col md="7">
                <Input
                    type="select"
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    readOnly={readOnly}
                    invalid={invalid}
                >
                    {defaultOption && <option>-</option>}
                    {options &&
                    Object.keys(options).map(key => {
                        return <option value={key} key={key}>{options[key]}</option>
                    })
                    }
                </Input>
                <div className="invalid-feedback">
                    {errorMessage}
                </div>
            </Col>
        </FormGroup>
    )
}

const InputArraySideSelect = ({
                                  label,
                                  value,
                                  onChange,
                                  options = [],
                                  disabled,
                                  readOnly,
                                  defaultOption,
                                  valueKey,
                                  labelKey,
                                  labelKey2,
                                  invalid,
                                  required,
                                  errorMessage
                              }) => {
    return (
        <FormGroup className="row">
            <Label
                className="form-control-label"
                md="3"
            >
                {label}{required ? <span className={'required-dot'}>*</span> : ''}
            </Label>
            <Col md="7">
                <Input
                    type="select"
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    readOnly={readOnly}
                    invalid={invalid}
                >
                    {defaultOption && <option>-</option>}
                    {options && options.length > 0 &&
                    options.map((item, index) => {
                        return <option value={item[valueKey]}
                                       key={index}>{labelKey2 ? `${item[labelKey]}, ${item[labelKey2].name}` : item[labelKey]}</option>
                    })
                    }
                </Input>
                <div className="invalid-feedback">
                    {errorMessage}
                </div>
            </Col>
        </FormGroup>
    )
}

const SelectWithoutInput = ({
                                label,
                                onSelect,
                                options = [],
                                imageLink,
                                fullWidth = false,
                                withBorder = false,
                                labelKey = null,
                                valueKey,
                                defaultOption = true
                            }) => {

    const formatOptionLabel = (option) => {
        return <div className={'cursor-pointer'}>{
            option?.icon ?
                <img height='25px' width='25px'
                     src={require(`assets/images/${option?.icon}`)} alt=""/>
                : <></>
        }
            {labelKey !== null ? <span>{option[labelKey]}</span> : <span>{option}</span>}
        </div>
    };
    const styles = {
        control: base => ({
            ...base,
            border: 'none',
            justifyContent: 'left',
            flexWrap: 'nowrap',
        }),
        menu: base => ({
            ...base,
            minWidth: '150px',
            zIndex: 1021
        }),
        dropdownIndicator: base => ({
            ...base,
            paddingRight: '20px',

        }),
    }


    return (
        <div className={"d-flex align-items-center " + (fullWidth ? "w-100" : "") + (withBorder ? " selectBorder" : "")}>
            {imageLink && <img height='25px' width='25px' src={require(`assets/images/${imageLink}`)} alt=""/>}
            {window.innerWidth < 833 ?
                    <Input
                        type="select"
                        value={label}
                        onChange={e => {
                            onSelect(options[e.target.value])
                        }}
                        disabled={false}
                    >
                        {defaultOption && <option>{label}</option>}
                        {options &&
                        options.map((key,index) => {
                            return <option value={index} key={index}>{labelKey ? key[labelKey] : key}</option>
                        })
                        }
                    </Input>
                :
                <Col className={'pl-0 pr-0'} md={9}>
                    <Select
                        styles={styles}
                        options={options}
                        isClearable={true}
                        getOptionLabel={option => option[labelKey]}
                        getOptionValue={option => option[valueKey]}
                        components={{ IndicatorSeparator: () => null}}
                        formatOptionLabel={formatOptionLabel}
                        isSearchable={true}
                        placeholder={label}
                        onChange={value => {
                            onSelect(value ? value : '')
                        }}
                    />
                </Col>
            }
        </div>
    )
}
const JobTaskCard = ({
                         item,
                         details = false,
                     }) => {
     const history = useHistory();
    const [showDetails, setDetails] = useState(false)
    return (
        <CustomCard padding={'10px'}>
            <TextContainer onClick={() => setDetails(!showDetails)}>
                <div><Text marginLeft={'0'} bold size='16px'>Task Type - {item.task_type}</Text>
                    {
                        showDetails ?
                            <i className="ni ni-up rotate-down" style={{color: 'black'}}/> :
                            <i className="ni ni-up rotate-up" style={{color: 'black'}}/>
                    }
                </div>
                <Text marginLeft={'0'}>{item.vehicle_make + ' ' + item.vehicle_model}</Text>
                <Line/>
            </TextContainer>
            {showDetails && <div className={'d-flex flex-column'} onClick={(e) => details &&  history.push(`/admin/delivery-task-details?id=${item.id}`)}>
                <TextContainerLocal className='mt-2'>
                    <Text>
                        Date of execution
                    </Text>
                    <TimeContainer>
                        <img height='14px' width='14px'
                             src={require(`assets/images/taskEdit/timer.png`)} alt=""/>
                        <span>{formatDate(item.execution_date)}</span>
                    </TimeContainer>
                </TextContainerLocal>
                <div className={'text-left'}>
                    <TextLocal>
                        Customer Info
                    </TextLocal>
                    <TaskDetailsContainer noMarginBottom>
                        <TextContainer>
                            <img height='16px' width='16px'
                                 src={require(`assets/images/taskEdit/person.png`)}
                                 alt=""/>
                            <img height='16px' width='16px'
                                 src={require(`assets/images/location_on.png`)}
                                 alt=""/>
                            <img height='16px' width='16px'
                                 src={require(`assets/images/call_grey.png`)}
                                 alt=""/>
                        </TextContainer>
                        <TextContainer>
                            <Text size='12px'>{item.full_name || item?.customer_name}</Text>
                            <Text size='12px'>{item.address || item?.customer_address}</Text>
                            <Text size='12px'>{item.mobile_number || item?.customer_phone}</Text>
                        </TextContainer>
                    </TaskDetailsContainer>

                </div>
                <div className={'text-left'}>
                    <TextLocal>
                        Driver Info
                    </TextLocal>
                    <TaskDetailsContainer noMarginBottom>
                        <TextContainer>
                            <img height='16px' width='16px'
                                 src={require(`assets/images/taskEdit/person.png`)}
                                 alt=""/>
                        </TextContainer>
                        <TextContainer>
                            <Text size='12px'>{item.driver_name}</Text>
                        </TextContainer>
                    </TaskDetailsContainer>
                </div>
            </div>}
        </CustomCard>
    )
}
const SelectCards = ({
                         label,
                         item,
                         showMake = false,
                         details,
                         alwaysOpen = false,
                     }) => {
    const history = useHistory();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [workers, setWorkers] = useState([]);
    useEffect(() => {
        if (alwaysOpen) {
            setDropdownOpen(true)
        }
    }, [alwaysOpen])
    useEffect(() => {
        if (item) {
            const temp = item.hasOwnProperty('task_assignees') ? item.task_assignees : item.hasOwnProperty('workers') ? item.workers : []
            setWorkers(temp)
        }
    }, [item])

    const toggle = () => setDropdownOpen((prevState) => !prevState);
    return (
        <Nav className="align-items-center ml-auto ml-md-0 select-label" navbar>
            <Dropdown direction={'down'} className={'w-100'} isOpen={dropdownOpen}
                      toggle={() => !alwaysOpen && toggle()}>

                {dropdownOpen && <div className={'w-100'}>
                    <CustomCard onClick={(e) => details && history.push(`/admin/task-details?id=${item.id}`)}>
                        <CardBody className="card-item">
                            <CardTitle tag="h3">
                                {item.title}
                            </CardTitle>
                            {
                                showMake &&
                                <TextContainerLocal direction={'column'}>
                                    <span> {item.vehicle.make.name + ' ' + item.vehicle.model.name}</span>
                                    <span>
                                              {item.vehicle.stock_no}
                                        </span>
                                </TextContainerLocal>
                            }

                        </CardBody>
                        <TextContainerLocal size='12' className='px-3'>
                            {item.status === "completed" && <DescriptionContainer>Total worked time {item.total_task_worked_time}</DescriptionContainer> }
                        </TextContainerLocal>
                        <TextContainerLocal size='12' className='px-3'>
                            {item.status === "completed" &&
                            <DescriptionContainer>Completed at {item.completed_at}</DescriptionContainer>}
                        </TextContainerLocal>
                        <CardBody className="card-item">
                            <TextContainerLocal>
                                <TaskTitleContainer>
                                    {item.job_category}
                                </TaskTitleContainer>
                                <TimeContainer>
                                    <img height='14px' width='14px'
                                         src={require(`assets/images/taskEdit/timer.png`)} alt=""/>
                                    <span>{formatDate(item.created_at)}</span>
                                </TimeContainer>
                            </TextContainerLocal>
                            <DescriptionContainer>
                                {item.description}
                            </DescriptionContainer>
                            <TextContainerLocal>
                                <PriorityContainer priority={item.priority}>
                                    {
                                        item.priority === 'high' ? <img height='14px' width='14px'
                                                                        src={require(`assets/images/ep_d-arrow-up-double.png`)}
                                                                        alt=""/>
                                            : item.priority === 'medium' ? <img height='14px' width='14px'
                                                                                src={require(`assets/images/fluent_line-equal.png`)}
                                                                                alt=""/>
                                                : <img height='14px' width='14px'
                                                       src={require(`assets/images/ep_arrow-down.png`)}
                                                       alt=""/>
                                    }
                                    <span>{item.priority}</span>
                                </PriorityContainer>
                                <div style={{
                                    marginTop: '15px',
                                    alignSelf: 'center'
                                }}>
                                    {
                                        workers?.map((worker, index) => {
                                            return <Text key={`${index}_1_${worker.name}`} lineHeight={'0px'} size={"12px"}>{worker.name}</Text>
                                        })
                                    }
                                </div>
                            </TextContainerLocal>
                        </CardBody>
                    </CustomCard>
                </div>}


            </Dropdown>

        </Nav>
    )
}

const CustomSort = ({
                        label, value, onSelect,
                        imageLink,
                    }) => {
    const selectValue = () => {
        onSelect(Number(!value))
    }
    return (
        <Nav className="align-items-center ml-auto ml-md-0" navbar>
            <SortContainer onClick={selectValue}>
                <SortIcon iconPosition={value} src={require(`assets/images/${imageLink}`)}/>
                <SortText>{label}</SortText>
            </SortContainer>
        </Nav>
    )
}
const CustomFilter = ({
                          regNos = [], departments = [], assignees = [], priorities = [], onChange
                      }) => {

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [filter, setFilter] = useState({})
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    return (
        <Nav className="align-items-center ml-auto ml-md-0" navbar>
            <UncontrolledDropdown nav>
                <Dropdown isOpen={dropdownOpen} toggle={toggle}>

                    <DropdownToggle className="nav-link pr-0" color="" tag="a" style={{cursor: 'pointer'}}>
                        <Media className="align-items-center">
                            <img height='25px' width='25px' src={require(`assets/images/filter_alt.png`)} alt=""/>
                            <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                      Filter Bar
                      </span>
                                <i className="ni ni-up rotate-down"/>
                            </Media>
                        </Media>
                    </DropdownToggle>
                    <DropdownMenu style={{position: 'absolute', cursor: 'pointer', padding: '25px'}}>
                        <FilterContainer>
                            <FilterItemsContainer width='135px'>
                                <FilterContainer>
                                    <img height='25px' width='25px'
                                         src={require(`assets/images/directions_car_grey.png`)} alt=""/>
                                    <span>Reg. Number</span>
                                </FilterContainer>
                                <FilterContainer>
                                    <img height='25px' width='25px'
                                         src={require(`assets/images/taskEdit/group_grey.png`)} alt=""/>
                                    <span>Department</span>
                                </FilterContainer>
                                <FilterContainer>
                                    <img height='25px' width='25px' src={require(`assets/images/taskEdit/person.png`)}
                                         alt=""/>
                                    <span>Worker</span>
                                </FilterContainer>
                                <FilterContainer>
                                    <img height='25px' width='25px' src={require(`assets/images/taskEdit/priority.png`)}
                                         alt=""/>
                                    <span>Priority</span>
                                </FilterContainer>
                            </FilterItemsContainer>
                            <FilterItemsContainer width='230px'>
                                <Select
                                    options={regNos}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isSearchable={true}
                                    placeholder={'Reg. Number'}
                                    onChange={value => {
                                        setFilter({...filter, regNo: value.stock_no})
                                    }}
                                />
                                <Select
                                    options={departments}
                                    className="basic-single"
                                    getOptionLabel={option => option.text}
                                    getOptionValue={option => option.value}
                                    classNamePrefix="select"
                                    isSearchable={true}
                                    placeholder={'Departments'}
                                    onChange={value => {
                                        setFilter({...filter, department: value.value})
                                    }}
                                />
                                <Select
                                    options={assignees}
                                    getOptionLabel={option => option.name}
                                    getOptionValue={option => option.id}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isSearchable={true}
                                    placeholder={'Workers'}
                                    onChange={value => {
                                        setFilter({...filter, worker: value.id})
                                    }}
                                />
                                <Select
                                    options={priorities}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isSearchable={true}
                                    placeholder={'Priority'}
                                    onChange={value => {
                                        setFilter({...filter, priority: value.label})
                                    }}
                                />
                            </FilterItemsContainer>
                        </FilterContainer>
                        <Button outline={true} style={{
                            backgroundColor: '#ec6409',
                            color: 'white',
                            marginTop: "10px",
                            border: '1px solid #ec6409',
                            float: 'right',
                            marginRight: '15px'
                        }} onClick={() => {
                            onChange(filter)
                        }}>Save</Button>

                    </DropdownMenu>
                </Dropdown>

            </UncontrolledDropdown>
        </Nav>
    )
}

export {
    InputKeySelect,
    InputArraySelect,
    ArraySelect,
    KeySelect,
    InputKeySideSelect,
    InputArraySideSelect,
    SelectWithoutInput,
    CustomSort,
    SelectCards,
    JobTaskCard,
    CustomFilter,
}