import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from "js-cookie";

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import TableCustom from 'views/pages/components/TableCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
} from "reactstrap";
import {ButtonContainer} from "@pages/reusable-components/styled-components";
import InputCustom from "@pages/components/InputCustom";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";

export default function Note() {
  const { customerId } = useParams();
  const location = useLocation();
  const parentPath = {
    pathname: '/admin/customer/' + customerId + '/edit',
    state: {
      filter: location.state && location.state.filter? location.state.filter: ''
    }
  };
  const history = useHistory();
  const notificationAlert = useRef(null);

  const [token, setToken] = useState('');
  const [notes, setNotes] = useState([]);

  const [loading, setLoading] = useState(true);
  const [isNotValid, setIsNotValid] = useState(true);
  const [addModal, setAddModal] = useState(false);
  const [confirmShow, setConfirmShow] = useState(false);
  const [editing, setEditing] = useState(false);
  const [selectedNoteId, setSelectedNoteId] = useState('');

  const [note, setNote] = useState('');
  const [errors, setErrors] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };
   const getData = (token,source) => {
     const fetchData = async () => {
       try {
         const response = await axios({
           method: 'GET',
           url: `${constants.API_URL}/customer/${customerId}/notes`,
           headers: {
             Authorization: `Bearer ${token}`,
           },
           cancelToken: source.token,
         })
         const data = response.data
         setNotes(data.data);
         setLoading(false);
       } catch (error) {
         setLoading(false);
         if (axios.isCancel(error)) {
           console.log('Request canceled :: ', error)
         } else {
           console.error('error :: ', error)
           notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
         }
       }
     }
     fetchData()
   }
  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()
    getData(grs_token,source)
    return () => {
      source.cancel()
    }
  }, [])

  const deleteNote = () => {
    axios({
      method: 'DELETE',
      url: constants.API_URL + '/customer/' + customerId + '/notes/' + selectedNoteId,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(response => {
        setConfirmShow(false);
        if (response.status === 200) {
          let data = response.data;
          console.log('deleted data :: ', data);
          setNotes(data.data);
          notify('success', 'Success', response.data.message);
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        setConfirmShow(false);
        notify('warning', 'Warning', error.response.statusText);
      })
  }
  const getNoteData = async (id) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/customer/${customerId}/notes/${id}/edit`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      const data = response.data
      setEditing(true)
      setAddModal(true)
      setNote(data.data);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
      }
    }
  }
  const editNote = (row) => {
    getNoteData(row.id)
    // history.push({
    //   pathname: '/admin/customer/' + customerId + '/notes/' + row.id + '/edit',
    //   state: {
    //     filter: location.state && location.state.filter? location.state.filter: ''
    //   }
    // })
  }

  const backToCustomer = (row) => {
    history.push({
      pathname: '/admin/customer/' + customerId + '/edit'
    })
  }
  const updateNote = () => {
    axios({
      method: 'PUT',
      url: constants.API_URL + '/customer/' + customerId + '/notes/' + note.id,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: note,
    })
        .then(response => {
          setErrors('');

          if (response.status === 200) {
            const source = axios.CancelToken.source()
            getData(token,source)
            notify('success', 'Success', response.data.message);
            setNote('')
            setEditing(false)
            setAddModal(false)
          }
        })
        .catch(error => {
          setErrors('');
          console.error('error :: ', error);
          if (error.response && error.response.status === 422) {
            let errors = error.response.data.errors;
            setErrors(errors);
            return;
          }
          setNote('')
          setEditing(false)
          setAddModal(false)
          notify('warning', 'Warning', error.response.statusText);
        })
  }
  const addNote = () => {
    axios({
      method: 'POST',
      url: constants.API_URL + '/customer/' + customerId + '/notes',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: note,
    })
      .then(response => {
        setAddModal(false);
        setErrors('');
        if (response.status === 200) {
          let data = response.data;
          setNotes(data.data);
          notify('success', 'Success', response.data.message);
        }
      })
      .catch(error => {
        setErrors('');
        console.error('error :: ', error);
        if (error.response && error.response.status === 422) {
          let errors = error.response.data.errors;
          setErrors(errors);
          return;
        }
        setAddModal(false);
        notify('warning', 'Warning', error.response.statusText);
      })
  }

  const formatDate = (d) => {
    if (d) {
      let date = new Date(d);
      let option = {
        year: 'numeric', month: '2-digit', day: '2-digit',
        hour: 'numeric', minute: 'numeric', second: '2-digit',
        hour12: false,
      }
      let formatedDate = new Intl.DateTimeFormat('en-GB', option).format(date);
  
      return formatedDate;
    }
  }

  return (
    <>
      <CardsHeader name="Customers" parentName="Customer Management" currentName="Note List" parentPath={parentPath} />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Notes</h1>
                  </Col>
                    <ButtonContainer reverse  onClick={() => backToCustomer()}>Back</ButtonContainer>
                    <ButtonContainer onClick={() => {
                      setAddModal(true)
                    }}>
                      <i className="fa fa-plus" aria-hidden="true"></i>
                      Add note
                    </ButtonContainer>
                </Row>
              </CardHeader>
              <CardBody>
                <TableCustom
                  loading={loading}
                  items={notes}
                  keyField={`note`}
                  searchField={true}
                  columns={[
                    {
                      dataField: "note",
                      text: "Note",
                      sort: true
                    },
                    {
                      dataField: "created_at",
                      text: "Created at",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return formatDate(row.created_at)
                      }
                    },
                    {
                      dataField: "",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            <IcoMoon
                                className={'cursor-pointer'}
                                iconSet={IconSet}
                                icon={'edit'}
                                size={24}
                                onClick={() => editNote(row)}
                            />
                            <IcoMoon
                                className={'cursor-pointer'}
                                iconSet={IconSet}
                                icon={'delete_outline'}
                                size={24}
                                onClick={() => {
                                  setConfirmShow(true);
                                  setSelectedNoteId(row.id);
                                }}
                            />
                          </>
                        )
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={addModal}
          toggle={() => {
            setAddModal(false)
            setNote('')
            setEditing(false)
          }}
          size="lg"
          className="modal-dialog-centered modal-secondary"
        >
          <div className="modal-body">
            <form className="new-event--form">
              <InputCustom
                  newLabel={`Note`}
                  type={'textarea'}
                  required
                 setIsNotValid={setIsNotValid}
                  placeholder={`Note`}
                  value={note && note?.note}
                  onChange={(e) => setNote({
                    ...note,
                    note: e.target.value,
                  })}
                  invalid={errors && errors.company_name ? true : false}
                  errorMessage={'Note field is required.'}
              />

            </form>
          </div>
          <div className="modal-footer justify-content-end">
            <Button
                onClick={() => setAddModal(false)}
            >
              Cancel
            </Button>
            <ButtonContainer
              reverse
              disabled={isNotValid}
              onClick={() => {
                editing ? updateNote() : addNote()
              }}
            >
              {editing ? 'Update' : 'Save'}
            </ButtonContainer>
          </div>
        </Modal>
        <Modal
          isOpen={confirmShow}
          toggle={() => setConfirmShow(false)}
          className="modal-dialog-centered modal-secondary"
        >
          <div className="modal-body justify-content-center">
            {`Are you sure you want to delete this note?`}
          </div>
          <div className="modal-footer justify-content-end">
            <ButtonContainer
                onClick={() => setConfirmShow(false)}
            >
              {`Cancel`}
            </ButtonContainer>
            <ButtonContainer
              reverse
              onClick={() => deleteNote()}
            >
              {`Delete`}
            </ButtonContainer>
          </div>
        </Modal>
      </Container>
    </>
  )
}