import React, { useContext, useEffect, useState } from 'react';
import Select2 from "react-select2-wrapper";

import { DealContext } from '../context';

import InputCustom from 'views/pages/components/InputCustom';
import {
  InputKeySelect,
  SelectWithoutInput,
} from 'views/pages/components/InputSelect';
import TableCustom from 'views/pages/components/TableCustom';

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  FormGroup,
} from "reactstrap";

import {
  deletee,
  hasPermission,
  update,
} from 'views/policies/DealPolicy';
import CalendarFilter from "@pages/components/CalendarFilter";
import { ButtonContainer } from "@pages/reusable-components/styled-components";
import CustomTabs from "@pages/components/CustomTabs";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";

export default function TaskTab() {
  const dealContext = useContext(DealContext);
  const {
    profilePermissions,
    siteCode,
    authUserTeam,
    allowEdit,
    dealTaskStatuses,
    deal,
    deleteModal, setDeleteModal,
    taskDelay,
    addTaskModal, setAddTaskModal,
    selectedRecurrentTask, setSelectedRecurrentTask,
    editRecurrentTaskModal, setEditRecurrentTaskModal,
    filterOptions, setFilterOptions,
    formatDate,
    formatDateTask,
    taskEdit,
    tasks,
    deleteTask,
    deleteRecurrentTask,
    task, setTask,
    taskErrors,
    dealerMembers,
    intervals,
    addTask,
    recurrentTaskUpdate,
    filterTasks,
  } = dealContext;

  const [tabs] = useState(['Due', 'Completed'])
  const [activeTab, setActiveTab] = useState('Due')
  const searchTasks = (data, key) => {
    setFilterOptions({
      ...filterOptions,
      [key]: data,
    })
  }
  useEffect(() => {
    filterTasks()
  }, [filterOptions])

  return (
    <CardBody>
      <Card>
        <CardHeader>
          <div className="d-flex justify-content-between">
            <div>
              <h2 className="title mb-0">Tasks</h2>
            </div>
            {hasPermission(profilePermissions, 'access-add-deal') && allowEdit &&
              <Button className="h-50" color="warning" type="button" onClick={() => setAddTaskModal(true)}>Add task</Button>}
          </div>
          <CustomTabs tabs={tabs} setActiveTab={setActiveTab} activeTab={activeTab} />
        </CardHeader>
        {activeTab === 'Due' &&
          <>
            {deal?.recurrentTasks?.length ?
                <CardBody>
                  <TableCustom
                      loading={false}
                      delay={taskDelay}
                      items={deal.recurrentTasks}
                      keyField={`title`}
                      searchField={false}
                      columns={[
                        {
                          dataField: "title",
                          text: "Task",
                          sort: true
                        },
                        {
                          dataField: "next_schedule_at",
                          text: "Next run date",
                          sort: true,
                          formatter: (rowContent, row) => {
                            return formatDateTask(row.next_schedule_at)
                          }
                        },
                        {
                          dataField: "created_by",
                          text: "Completed by",
                          sort: true,
                          formatter: (rowContent, row) => {
                            return row.owner.name
                          }
                        },
                        {
                          dataField: "",
                          text: "Action",
                          formatter: (rowContent, row) => {
                            return (
                                <>
                                  {hasPermission(profilePermissions, 'update-deals') && hasPermission(profilePermissions, 'delete-deals') &&
                                  <>
                                    {update(profilePermissions, authUserTeam, deal) &&
                                    <IcoMoon
                                        className={'cursor-pointer'}
                                        iconSet={IconSet}
                                        icon={'edit'}
                                        size={24}
                                        onClick={() => {
                                          setSelectedRecurrentTask({
                                            ...row,
                                            next_schedule_at: row.next_schedule_at ? formatDate(row.next_schedule_at) : '',
                                          })
                                          setEditRecurrentTaskModal(true)
                                        }}
                                    />
                                    }
                                    {deletee(profilePermissions, authUserTeam, deal, siteCode) &&
                                    <IcoMoon
                                        className={'cursor-pointer'}
                                        iconSet={IconSet}
                                        icon={'delete_outline'}
                                        size={24}
                                        onClick={() => {
                                          setDeleteModal({
                                            ...deleteModal,
                                            show: true,
                                            header: 'Delete recurrent task',
                                            body: 'Are you sure to delete this recurrent task?',
                                            onClick: () => deleteRecurrentTask(row.id)
                                          })
                                        }}
                                    />
                                    }
                                  </>
                                  }
                                </>
                            )
                          }
                        }
                      ]}
                  />
                </CardBody> : <h3>No data yet</h3>
            }

          </>
        }
        {activeTab === 'Completed' &&
          <CardBody>
            <Row>
              <Col md={12}>
                <Row>
                  <Col md={4} >
                    <CalendarFilter filterByDate={(data) => {
                      setFilterOptions({ ...filterOptions, ...data })
                    }}
                    />
                  </Col>
                  <Col md={4} style={{ alignSelf: 'center' }}>
                    <SelectWithoutInput
                      label={dealerMembers.find(item => item?.id === filterOptions?.dealer_id)?.name || 'Assignees'}
                      onSelect={(e) => {
                        searchTasks(e.id, 'dealer_id')
                      }}
                      options={dealerMembers}
                      defaultOption={true}
                      valueKey={'id'}
                      labelKey={'name'}
                      imageLink={'loyalty.png'}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            {tasks.length ?
                <TableCustom
                    loading={false}
                    delay={taskDelay}
                    items={tasks}
                    keyField={`title`}
                    searchField={true}
                    columns={[
                      {
                        dataField: "title",
                        text: "Task",
                        sort: true
                      },
                      {
                        dataField: "due_date",
                        text: "Completed at",
                        sort: true,
                        formatter: (rowContent, row) => {
                          return formatDateTask(row.due_date)
                        }
                      },
                      {
                        dataField: "created_by",
                        text: "Completed by",
                        sort: true,
                        formatter: (rowContent, row) => {
                          return row.owner.name
                        }
                      },
                      {
                        dataField: "status",
                        text: "Status",
                        sort: true,
                        formatter: (rowContent, row) => {
                          return dealTaskStatuses[row.status]
                        }
                      },
                      {
                        dataField: "",
                        text: "Action",
                        formatter: (rowContent, row) => {
                          return (
                              <>
                                {hasPermission(profilePermissions, 'update-deals') && hasPermission(profilePermissions, 'delete-deals') &&
                                <>
                                  <IcoMoon
                                      className={'cursor-pointer'}
                                      iconSet={IconSet}
                                      icon={'edit'}
                                      size={24}
                                      onClick={() => taskEdit(row)}
                                  />
                                  <IcoMoon
                                      className={'cursor-pointer'}
                                      iconSet={IconSet}
                                      icon={'delete_outline'}
                                      size={24}
                                      onClick={e => {
                                        setDeleteModal({
                                          ...deleteModal,
                                          show: true,
                                          header: 'Delete task',
                                          body: 'Are you sure to delete this task?',
                                          onClick: () => deleteTask(row.uuid)
                                        })
                                      }}
                                  />
                                </>
                                }
                              </>
                          )
                        }
                      }
                    ]}
                />
                : <h3>No data yet</h3>
            }

          </CardBody>
        }
      </Card>
      <Modal
        isOpen={addTaskModal}
        toggle={() => setAddTaskModal(false)}
        className="modal-dialog-centered"
      >
        <div className="modal-header">
          <h6 className="modal-title" style={{ color: '#ec6409' }}>{`Add task`}</h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setAddTaskModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <InputCustom
            newLabel={`Task title`}
            placeholder={'Enter title'}
            value={task && task.task_title ? task.task_title : ''}
            onChange={(e) => setTask({
              ...task,
              task_title: e.target.value,
            })}
            invalid={taskErrors && taskErrors.task_title ? true : false}
            errorMessage={taskErrors && taskErrors.task_title ? taskErrors.task_title : ''}
          />
          <InputCustom
            type={`textarea`}
            placeholder={'Add text here...'}
            newLabel={`Description`}
            rows={5}
            value={task && task.task_comments ? task.task_comments : ''}
            onChange={(e) => setTask({
              ...task,
              task_comments: e.target.value,
            })}
          />
          <InputCustom
            type={`date`}
            newLabel={`Due`}
            value={task && task.task_due_date ? task.task_due_date : ''}
            onChange={(e) => setTask({
              ...task,
              task_due_date: e.target.value,
            })}
            invalid={taskErrors && taskErrors.task_due_date ? true : false}
            errorMessage={taskErrors && taskErrors.task_due_date ? taskErrors.task_due_date : ''}
          />
          <FormGroup className={'d-flex align-items-center'}>
            <label className="form-control-label w-50">Assign to</label>
            <Select2
              className="form-control"
              multiple
              value={task && task.task_assignee ? task.task_assignee : ''}
              data={dealerMembers}
              onChange={(e) => setTask({
                ...task,
                task_assignee: Array.from(e.target.selectedOptions, option => option.value),
              })}
            />
            {taskErrors && taskErrors.task_assignee && <div className="invalid-feedback" style={{ display: 'block' }}>
              {taskErrors.task_assignee}
            </div>}
          </FormGroup>
          <InputKeySelect
            newLabel={`Status`}
            value={task && task.task_status ? task.task_status : ''}
            options={dealTaskStatuses}
            onChange={(e) => setTask({
              ...task,
              task_status: e.target.value,
            })}
            defaultOption={true}
            invalid={taskErrors && taskErrors.task_status ? true : false}
            errorMessage={taskErrors && taskErrors.task_status ? taskErrors.task_status : ''}
          />

          <FormGroup>
            <label className="form-control-label">Is recurrent</label>
            <Row>
              <Col md={12}>
                <label className="custom-toggle">
                  <input
                    type="checkbox"
                    checked={task && task.is_recurrent === 1 ? true : false}
                    onChange={() =>
                      setTask({
                        ...task,
                        is_recurrent: task.is_recurrent === 1 ? undefined : 1
                      })
                    }
                  />
                  <span
                    className="custom-toggle-slider rounded-circle"
                    data-label-off="No"
                    data-label-on="Yes"
                  />
                </label>
              </Col>
            </Row>
          </FormGroup>
          {task && task.is_recurrent === 1 && <div>
            <Row>
              <Col md={6} sm={12}>
                <InputCustom
                  type={`number`}
                  newLabel={`Interval`}
                  value={task && task.recurrent_interval ? task.recurrent_interval : ''}
                  onChange={(e) => setTask({
                    ...task,
                    recurrent_interval: e.target.value,
                  })}
                  invalid={taskErrors && taskErrors.recurrent_interval ? true : false}
                  errorMessage={taskErrors && taskErrors.recurrent_interval ? taskErrors.recurrent_interval : ''}
                />
              </Col>
              <Col md={6} sm={12}>
                <InputKeySelect
                  newLabel={`Period`}
                  value={task && task.recurrent_period ? task.recurrent_period : ''}
                  options={intervals}
                  onChange={(e) => setTask({
                    ...task,
                    recurrent_period: e.target.value,
                  })}
                  defaultOption={true}
                  invalid={taskErrors && taskErrors.recurrent_period ? true : false}
                  errorMessage={taskErrors && taskErrors.recurrent_period ? taskErrors.recurrent_period : ''}
                />
              </Col>
            </Row>
            <InputCustom
              type={`number`}
              newLabel={`No of times to run`}
              value={task && task.times_to_run ? task.times_to_run : ''}
              onChange={(e) => setTask({
                ...task,
                times_to_run: e.target.value,
              })}
            />
          </div>}
        </div>
        <div className="modal-footer">
          <Button className="w-25" color="lighte" type="button" onClick={() => {
            setAddTaskModal(false)
          }}>Cancel</Button>
          <Button className="w-25" color="warning" onClick={() => addTask()}>Save</Button>
        </div>
      </Modal>
      <Modal
        isOpen={editRecurrentTaskModal}
        toggle={() => setEditRecurrentTaskModal(false)}
        className="modal-dialog-centered"
      >
        <div className="modal-header">
          <h6 className="modal-title">{`Edit recurrent task`}</h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setEditRecurrentTaskModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <InputCustom
            label={`Task title`}
            value={selectedRecurrentTask && selectedRecurrentTask.title ? selectedRecurrentTask.title : ''}
            onChange={(e) => setSelectedRecurrentTask({
              ...selectedRecurrentTask,
              title: e.target.value,
            })}
            invalid={taskErrors && taskErrors.title ? true : false}
            errorMessage={taskErrors && taskErrors.title ? taskErrors.title : ''}
          />
          <InputCustom
            type={`textarea`}
            label={`Description`}
            rows={5}
            value={selectedRecurrentTask && selectedRecurrentTask.comments ? selectedRecurrentTask.comments : ''}
            onChange={(e) => setSelectedRecurrentTask({
              ...selectedRecurrentTask,
              comments: e.target.value,
            })}
          />
          <FormGroup>
            <label className="form-control-label">Automatically assign to</label>
            <Select2
              className="form-control"
              multiple
              value={selectedRecurrentTask && selectedRecurrentTask.assignee ? selectedRecurrentTask.assignee : ''}
              data={dealerMembers}
              onChange={(e) => setSelectedRecurrentTask({
                ...selectedRecurrentTask,
                assignee: Array.from(e.target.selectedOptions, option => option.value),
              })}
            />
            {taskErrors && taskErrors.task_assignee && <div className="invalid-feedback" style={{ display: 'block' }}>
              {taskErrors.task_assignee}
            </div>}
          </FormGroup>
          <InputCustom
            type={`date`}
            label={`Next scheduled date`}
            value={selectedRecurrentTask && selectedRecurrentTask.next_schedule_at ? selectedRecurrentTask.next_schedule_at : ''}
            onChange={(e) => setSelectedRecurrentTask({
              ...selectedRecurrentTask,
              next_schedule_at: e.target.value,
            })}
            invalid={taskErrors && taskErrors.next_schedule_at ? true : false}
            errorMessage={taskErrors && taskErrors.next_schedule_at ? taskErrors.next_schedule_at : ''}
          />
          <Row>
            <Col md={6} sm={12}>
              <InputCustom
                type={`number`}
                newLabel={`Recurrent interval`}
                placeholder={'Interval'}
                value={selectedRecurrentTask && selectedRecurrentTask.recurrent_interval ? selectedRecurrentTask.recurrent_interval : ''}
                onChange={(e) => setSelectedRecurrentTask({
                  ...selectedRecurrentTask,
                  recurrent_interval: e.target.value,
                })}
                invalid={taskErrors && taskErrors.recurrent_interval ? true : false}
                errorMessage={taskErrors && taskErrors.recurrent_interval ? taskErrors.recurrent_interval : ''}
              />
            </Col>
            <Col md={6} sm={12}>
              <InputKeySelect
                newLabel={`Period`}
                value={selectedRecurrentTask && selectedRecurrentTask.recurrent_period ? selectedRecurrentTask.recurrent_period : ''}
                options={intervals}
                onChange={(e) => setSelectedRecurrentTask({
                  ...selectedRecurrentTask,
                  recurrent_period: e.target.value,
                })}
                defaultOption={true}
                invalid={taskErrors && taskErrors.recurrent_period ? true : false}
                errorMessage={taskErrors && taskErrors.recurrent_period ? taskErrors.recurrent_period : ''}
              />
            </Col>
          </Row>
          <InputCustom
            type={`number`}
            newLabel={`No of times to run`}
            value={selectedRecurrentTask && selectedRecurrentTask.times_to_run ? selectedRecurrentTask.times_to_run : ''}
            onChange={(e) => setSelectedRecurrentTask({
              ...selectedRecurrentTask,
              times_to_run: e.target.value,
            })}
          />
        </div>
        <div className="modal-footer">
          <Button
            className="w-100"
            color="success"
            type="button"
            onClick={() => recurrentTaskUpdate()}
          >
            {`Save`}
          </Button>
        </div>
      </Modal>
      <Modal
        isOpen={deleteModal.show}
        toggle={() => setDeleteModal({
          ...deleteModal,
          show: false
        })}
        className="modal-dialog-centered"
      >
        <div className="modal-header">
          <h6 className="modal-title">{deleteModal.header}</h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setDeleteModal({
              ...deleteModal,
              show: false
            })}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <span>{deleteModal.body}</span>
        </div>
        <div className="modal-footer justify-content-end">
          <ButtonContainer
            reverse
            onClick={deleteModal.onClick}
          >
            {`Delete`}
          </ButtonContainer>
        </div>
      </Modal>
    </CardBody>
  );
}