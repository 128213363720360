import React, { useEffect, useState, createContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from "js-cookie";
import axios from 'axios';

import * as constants from 'constants/index.js';
import {formatDate} from "constants/index.js";
import { saveDealSettings } from 'api/modules.api';
import { saveCustomPayment, deletePayment, saveCustomInvoicePayment } from 'api/payments.api';

export const Context = createContext({});

function guidGenerator() {
  var S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
}

let timeoutCalculator = null;

export const Provider = props => {
  const { id, notify, children, paymentScheduleId } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const modules = useSelector((state) => state.getState).modules.modules || [];
  const modulesType = useSelector((state) => state.getState).modules.types || [];
  const [token, setToken] = useState('');
  const [tab, setTab] = useState('info');

  const [deal, setDeal] = useState('');
  const [settings, setSettings] = useState({});
  const [hasBeenGenerated, setHasBeenGenerated] = useState(true);
  const [isPaymentActive, setIsPaymentActive] = useState(true);
  const [payments, setPayments] = useState([]);
  const [minDate, setMinDate] = useState('');
  const [paymentDescriptors, setPaymentDescriptors] = useState('');
  const [paymentIntervals, setPaymentIntervals] = useState('');
  const [paymentProcessTypes, setPaymentProcessTypes] = useState('');
  const [paymentStatuses, setPaymentStatuses] = useState('');
  const [paymentMethods, setPaymentMethods] = useState('');
  const [paymentStatusesGroup, setPaymentStatusesGroup] = useState('');
  const [totalCollected, setTotalCollected] = useState('');
  const [totalPayments, setTotalPayments] = useState('');
  const [isAbleToCreateDeals, setIsAbleToCreateDeals] = useState(false);
  const [actualInvoice, setActualInvoice] = useState(false);
  const [paymentChangesHistory, setPaymentChangesHistory] = useState([]);
  const [preferences, setPreferences] = useState('');

  const [loading, setLoading] = useState(true);
  const [delay, setDelay] = useState(false);
  const [saveButtonDisable, setSaveButtonDisable] = useState(true);
  const [confirmRescheduleModal, setConfirmRescheduleModal] = useState(false);
  const [addPaymentModal, setAddPaymentModal] = useState(false);

  const [flag, setFlag] = useState(false);

  const [errors, setErrors] = useState('');

  const [message, setMessage] = useState('');
  const [changeMinDate, setChangeMinDate] = useState('');
  const [viewChangeDetail, setViewChangeDetail] = useState(false);

  const [paymentDetailModal, setPaymentDetailModal] = useState(false);
  const [hasXeroCustomer, setHasXeroCustomer] = useState(false);

  const [selectedPayment, setSelectedPayment] = useState('');
  const [paymentStatusPaidOut, setPaymentStatusPaidOut] = useState('');
  const [paymentStatusFailed, setPaymentStatusFailed] = useState('');
  const [paymentStatusNew, setPaymentStatusNew] = useState('');
  const [paymentStatusCreated, setPaymentStatusCreated] = useState('');
  const [processTypeDirectDebit, setProcessTypeDirectDebit] = useState('');
  const [paymentOnlineProcessLink, setPaymentOnlineProcessLink] = useState(false);

  const [addInvoicePaymentModal, setAddInvoicePaymentModal] = useState(false);
  const [customPaymentElement, setCustomPaymentElement] = useState({})

  const [paymentEdit, setPaymentEdit] = useState('');
  const [customPayment, setCustomPayment] = useState('');

  const [savePaymentErrors, setSavePaymentErrors] = useState('');

  const [paymentItems, setPaymentItems] = useState([]);

  const addNewPaymentItem = ({ qty = 1, price = 0, description, total_price = 0, vat = 0, sales_account = '', tax_rate = '' }) => {
    setPaymentItems(paymentItems => [...paymentItems, {
      qty: qty ?? 1,
      price: price ?? 0,
      description: description ?? '',
      total_price: total_price ?? 0,
      vat: vat ?? 0,
      tax_rate: tax_rate ?? '',
      sales_account: sales_account ?? '',
      id: guidGenerator()
    }]);
  }

  const fetchData = async (grs_token, source) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/deal/${id}/payments`,
        headers: {
          Authorization: `Bearer ${grs_token}`,
        },
        cancelToken: source.token,
      })
      console.log('response :: ', response.data)
      const data = response.data

      setDeal({
        ...data.data.deal,
        next_payment: data.data.deal.next_payment ? formatDate(data.data.deal.next_payment) : '',
      });
      setHasBeenGenerated(data.data.hasBeenGenerated);
      setIsPaymentActive(data.data.isPaymentActive);
      setPayments(data.data.items);
      setMinDate(data.data.minDate);
      setPaymentDescriptors(data.data.paymentDescriptors);
      setPaymentIntervals(data.data.paymentIntervals);
      setPaymentProcessTypes(data.data.paymentProcessTypes);
      setPaymentStatuses(data.data.paymentStatuses);
      setPaymentStatusesGroup(data.data.paymentStatusesGroup);
      setTotalCollected(data.data.totalCollected);
      setTotalPayments(data.data.totalPayments);
      setIsAbleToCreateDeals(data.data.isAbleToCreateDeals);
      setHasXeroCustomer(data.data?.hasXeroCustomer ?? false);

      if (data.data.dealSettings) {
        setSettings(data.data.dealSettings);
      }

      setTimeout(() => {
        setLoading(false);
        setDelay(true);
      }, 1000);
    } catch (error) {
      setLoading(false);
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
      }
    }
  }

  useEffect(() => {
    let grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    fetchData(grs_token, source)

    return () => {
      source.cancel()
    }
  }, [])

  const goDeal = () => {
    history.push({
      pathname: '/admin/deals/' + id + '/edit',
    })
  }

  const getHistory = () => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/deal/' + id + '/payments-history',
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(response => {
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);

          setPaymentChangesHistory(data.data.items);
          setPreferences(data.data.preferences);
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        notify('warning', 'Warning', error.response.statusText);
      })
  }

  const regeneratePaymentSchedule = () => {
    axios({
      method: 'POST',
      url: constants.API_URL + '/deal/' + id + '/update-payment',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        payment_frequency: String(deal.payment_frequency),
        payment_frequency_interval: deal.payment_frequency_interval,
        term: String(deal.term),
        interval: deal.interval,
        monthly_payment: String(deal.monthly_payment),
        next_payment: deal.next_payment,
        last_payment: deal.last_payment,
        no_of_rental_payments: deal.no_of_rental_payments,
        consent: deal.consent,
      },
    })
      .then(response => {
        setErrors('');
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setPayments(data.data.items);
          setTotalCollected(data.data.totalCollected);
          setTotalPayments(data.data.totalPayments);
          setIsPaymentActive(data.data.isPaymentActive);
          setHasBeenGenerated(data.data.hasBeenGenerated);
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setErrors('');
        console.error('error :: ', error.response);
        if (error.response.status === 422) {
          setErrors(error.response.data.errors);
        }
        notify('warning', 'Warning', error.response.statusText);
      })
  }

  const saveSettings = () => {
    saveDealSettings(deal.id, settings)
      .then(response => {
        if (response.success === true) {
          let data = response.data;
          console.log('response data :: ', data);
          notify('success', 'Success', "New Successfuly Saved!");
        }
      })
      .catch(error => {
        setErrors('');
        console.error('error :: ', error.response);
        if (error.response.status === 422) {
          setErrors(error.response.data.errors);
        }
        notify('warning', 'Warning', error.response.statusText);
      })
  }

  const resetPaymentSchedule = () => {
    history.push({
      pathname: '/admin/deals/' + id + '/payment',
    })
  }

  const paymentScheduleDetails = (scheduleId) => {
    history.push({
      pathname: `/admin/deals/${id}/payment/${scheduleId}/details`,
    })
  }

  useEffect(() => {
    switch (tab) {
      case 'notes':
        setTimeout(() => {

        }, 1000);
        break;

      case 'tasks':
        setTimeout(() => {

        }, 1000);
        break;

      default:
        break;
    }
  }, [tab])



  const formatDateTask = (d) => {
    if (d) {
      let date = new Date(d);
      let ye = Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
      let mo = Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
      let da = Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
      return `${da}/${mo}/${ye}`
    }
  }

  useEffect(() => {
    if (flag && deal.no_of_rental_payments > 0 && deal.next_payment) {
      let body = {
        payment_frequency: deal.payment_frequency,
        payment_frequency_interval: deal.payment_frequency_interval,
        monthly_payment: deal.monthly_payment,
        term: deal.term,
        interval: deal.interval,
        next_payment: deal.next_payment,
        no_of_rental_payments: deal.no_of_rental_payments,
      }

      clearTimeout(timeoutCalculator);

      timeoutCalculator = setTimeout(() => {
        axios({
          method: 'POST',
          url: constants.API_URL + '/deal/' + id + '/edit-calculator',
          headers: {
            Authorization: 'Bearer ' + token
          },
          data: body,
        })
          .then(response => {
            if (response.status === 200) {
              setErrors('');
              let data = response.data;
              console.log('response data :: ', data);
  
              setMessage(data.data.message);
              setChangeMinDate(data.data.minDate);
              setPayments(data.data.items);
              setTimeout(() => {
                setViewChangeDetail(true);
                setConfirmRescheduleModal(true);
              }, 1000);
            }
          })
          .catch(error => {
            setErrors('');

            notify('warning', 'Warning', error.response?.data?.message ?? error.response?.statusText);
          })
      }, 1500);
    }
  }, [
    deal.payment_frequency,
    deal.payment_frequency_interval,
    deal.term,
    deal.interval,
    deal.monthly_payment,
    deal.next_payment,
    deal.initial_payment,
    deal.end_payment,
    deal.no_of_rental_payments
  ])

  useEffect(() => {
    if (deal.consent === 1) {
      setSaveButtonDisable(false)
    }
  }, [deal.consent])

  const paymentShow = (row) => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/deal/' + id + '/payments/' + row.id,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then(response => {
        setErrors('');
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setSelectedPayment(data.data.item);
          setPaymentProcessTypes(data.data.paymentProcessTypes);
          setPaymentStatusPaidOut(data.data.paymentStatusPaidOut);
          setPaymentStatusFailed(data.data.paymentStatusFailed);
          setPaymentStatusNew(data.data.paymentStatusNew);
          setPaymentStatusCreated(data.data.paymentStatusCreated);
          setProcessTypeDirectDebit(data.data.processTypeDirectDebit);
          setPaymentOnlineProcessLink(data.data.addOnlinePayment);
          setPaymentMethods(data.data.paymentMethods);
          setPaymentEdit({
            ...paymentEdit,
            scheduled_at: data.data.item && data.data.item.reference ? formatDate(data.data.item.reference.charge_date) :formatDate(data.data.item.scheduled_at),
            amount: data.data.item && data.data.item.reference ? data.data.item.reference.amount : data.data.item.amount,
            process_type: data.data.item && data.data.item.reference ? data.data.item.reference.process_type : '',
            payment_reference_id: data.data.item ? data.data.item.id : '',
            selected_provider: data.data.item && data.data.item.reference ? data.data.item.reference.selected_provider : '',
            collect_now: 0,
          })

          setPaymentDetailModal(true);

          if(data.data.actualInvoice) {
            setActualInvoice(data.data.actualInvoice);
          }
        }
      })
      .catch(error => {
        setErrors('');
        console.error('error :: ', error.response);
        if (error.response.status === 422) {
          setErrors(error.response.data.errors);
        }
        notify('warning', 'Warning', error.response.statusText);
      })
  }

  const openPaymentLink = (link) => {
    var win = window.open(link, '_blank');
    win.focus();
  }

  const showInvoice = (row) => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/deal/' + id + '/invoice/' + row.id,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then(response => {
        console.log(response)
        setErrors('');
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          let invoice = data.data.invoice;
          window.open(invoice, "_blank");
        }
      })
      .catch(error => {
        setErrors('');
        console.error('error :: ', error.response);
        if (error.response.status === 422) {
          setErrors(error.response.data.errors);
        }
        notify('warning', 'Warning', error.response.statusText);
      })
  }

  const addCustomPayment = () => {
    saveCustomPayment(deal.id, customPayment, paymentItems)
      .then(response => {
        if (response.data.success === true) {
          let data = response.data;
          console.log('response data :: ', data);
          setAddPaymentModal(false);
          const source = axios.CancelToken.source()
          fetchData(token, source);
          notify('success', 'Success', data.message);
          history.push(`/admin/deals/${deal.id}/payment`);
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        notify('warning', 'Warning', error?.response?.message);
      })
  }

  const addCustomInvoicePayment = () => {
    saveCustomInvoicePayment(deal.id, selectedPayment.id, customPaymentElement)
      .then(response => {
        if (response.data.success === true) {
          let data = response.data;
          console.log('response data :: ', data);
          setAddInvoicePaymentModal(false);
          const source = axios.CancelToken.source()
          fetchData(token, source);
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        notify('warning', 'Warning', error?.response?.message);
      })
  }

  const removePayment = (row) => {
    setLoading(true);
    deletePayment(row.deal_id, row.id)
      .then(response => {
        if (response.data.success === true) {
          let data = response.data;
          console.log('response data :: ', data);
          setCustomPayment('');
          setSelectedPayment('');
          const source = axios.CancelToken.source()
          fetchData(token, source);
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        if (error.response && error.response.status === 422) {
          let errors = error.response.data.errors;
          let err = '';
          Object.keys(errors).forEach(key => {
            err += errors[key];
          })
          setSavePaymentErrors(err);
        } else if (error.response && error.response.status === 406) {
          let errors = error.response.data.errors;
          let err = errors.message;
          setSavePaymentErrors(err);
        }
      })
  }

  const savePayment = () => {
    axios({
      method: 'POST',
      url: constants.API_URL + '/deal/' + id + '/save-reference',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: paymentEdit,
    })
      .then(response => {
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setPaymentEdit('');
          setSelectedPayment('');
          setPaymentDetailModal(false);
          setPayments(data.data);
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        if (error.response && error.response.status === 422) {
          let errors = error.response.data.errors;
          let err = '';
          Object.keys(errors).forEach(key => {
            err += errors[key];
          })
          setSavePaymentErrors(err);
        } else if (error.response && error.response.status === 406) {
          let errors = error.response.data.errors;
          let err = errors.message;
          setSavePaymentErrors(err);
        }
      })
  }

  const cancelPayment = (referenceId) => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/deal/' + id + '/cancel/' + referenceId,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then(response => {
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setPaymentEdit('');
          setSelectedPayment('');
          setPaymentDetailModal(false);
          setPayments(data.data);
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        if (error.response && error.response.status === 422) {
          let errors = error.response.data.errors;
          let err = '';
          Object.keys(errors).forEach(key => {
            err += errors[key];
          })
          setSavePaymentErrors(err);
        } else if (error.response && error.response.status === 406) {
          let errors = error.response.data.errors;
          let err = errors.message;
          setSavePaymentErrors(err);
        }
      })
  }

  const retryPayment = (referenceId) => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/deal/' + id + '/retry/' + referenceId,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then(response => {
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setPaymentEdit('');
          setSelectedPayment('');
          setPaymentDetailModal(false);
          setPayments(data.data);
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        if (error.response && error.response.status === 422) {
          let errors = error.response.data.errors;
          let err = '';
          Object.keys(errors).forEach(key => {
            err += errors[key];
          })
          setSavePaymentErrors(err);
        } else if (error.response && error.response.status === 406) {
          let errors = error.response.data.errors;
          let err = errors.message;
          setSavePaymentErrors(err);
        }
      })
  }

  const paymentContext = {
    id,
    paymentScheduleId,
    notify,
    history,
    dispatch,
    modules,
    modulesType,
    token, setToken,
    tab, setTab,
    deal, setDeal,
    settings, setSettings,
    hasBeenGenerated, setHasBeenGenerated,
    isPaymentActive, setIsPaymentActive,
    payments, setPayments,
    minDate, setMinDate,
    paymentDescriptors, setPaymentDescriptors,
    paymentOnlineProcessLink,
    paymentIntervals, setPaymentIntervals,
    paymentProcessTypes, setPaymentProcessTypes,
    paymentStatuses, setPaymentStatuses,
    paymentStatusesGroup, setPaymentStatusesGroup,
    totalCollected, setTotalCollected,
    totalPayments, setTotalPayments,
    isAbleToCreateDeals, setIsAbleToCreateDeals,
    paymentChangesHistory, setPaymentChangesHistory,
    preferences, setPreferences,
    paymentMethods, setPaymentMethods,
    loading, setLoading,
    delay, setDelay,
    saveButtonDisable, setSaveButtonDisable,
    confirmRescheduleModal, setConfirmRescheduleModal,
    paymentItems, setPaymentItems,
    flag, setFlag,
    errors, setErrors,
    message, setMessage,
    changeMinDate, setChangeMinDate,
    addInvoicePaymentModal, setAddInvoicePaymentModal,
    customPaymentElement, setCustomPaymentElement,
    viewChangeDetail, setViewChangeDetail,
    paymentDetailModal, setPaymentDetailModal,
    selectedPayment, setSelectedPayment,
    paymentStatusPaidOut, setPaymentStatusPaidOut,
    paymentStatusFailed, setPaymentStatusFailed,
    paymentStatusNew, setPaymentStatusNew,
    paymentStatusCreated, setPaymentStatusCreated,
    processTypeDirectDebit, setProcessTypeDirectDebit,
    paymentEdit, setPaymentEdit,
    customPayment, setCustomPayment,
    savePaymentErrors, setSavePaymentErrors,
    addPaymentModal, setAddPaymentModal,
    hasXeroCustomer,
    saveSettings,
    addNewPaymentItem,
    regeneratePaymentSchedule,
    addCustomInvoicePayment,
    getHistory,
    goDeal,
    resetPaymentSchedule,
    paymentScheduleDetails,
    formatDate,
    formatDateTask,
    paymentShow,
    removePayment,
    savePayment,
    addCustomPayment,
    cancelPayment,
    showInvoice,
    openPaymentLink,
    retryPayment,
    actualInvoice
  }

  return <Context.Provider value={paymentContext}>{children}</Context.Provider>
}

export const { Consumer } = Context;