import React, { Fragment, useContext } from 'react';

import {
  Row,
  Col,
  CardBody,
} from "reactstrap";
import { PaymentContext } from '../context';
import PaymentItem from 'views/pages/components/payment/PaymentItem';
import { InputArraySelect } from 'views/pages/components/InputSelect';
import {ButtonContainer} from "@pages/reusable-components/styled-components";

export default function Test() {

  const context = useContext(PaymentContext);

  const {
    modules,
    deal,
    settings, setSettings,
    saveSettings,
  } = context;

  let accountingModules = modules.filter(item => item.module_type === 'accounting') || [];
  let paymentModules = modules.filter(item => item.module_type === 'payment');
  return (
    <>
      <CardBody>
        <Row className="py-3">
          <Col md={12}>
            <h2 className={'main-color'}>3rd party customer connections</h2>
          </Col>
          <Col md={12}>
            {
              modules.filter(item => item.module_type === 'accounting').map((item) => {
                return (
                  <Fragment key={item.id}>
                    <h3>{item.provider.toUpperCase()} ({item.module_type})</h3>
                    <PaymentItem key={item.id} provider={item.provider} deal={deal} />
                  </Fragment>
                )
              })
            }
          </Col>
        </Row>
      </CardBody>
      <CardBody className={'p-4'}>
        <Row className="py-3">
          <Col md={12}>
            <h3>Automatic payments settings</h3>
          </Col>
          <Col md={12}>
            <label className="form-control-label" htmlFor="consent">
              {`Payment collected through the accounting system`}
            </label>
            <div>
              <label className="custom-toggle">
                <input
                  type="checkbox"
                  id="consent"
                  checked={settings && settings.is_payment_collected_through_accounting_system == 1 ? true : false}
                  onChange={() =>
                    setSettings({
                      ...settings,
                      is_payment_collected_through_accounting_system: settings.is_payment_collected_through_accounting_system ? 0 : 1
                    })
                  }
                />
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off=""
                  data-label-on=""
                />
              </label>
            </div>
          </Col>
          <Col md={12}>{settings.accounting_system3}</Col>
          {accountingModules.length > 0 ? <Col md={4} sm={6}>
            <InputArraySelect
              label={`Accounting system`}
              value={settings && settings.accounting_system ? settings.accounting_system : ''}
              options={accountingModules}
              valueKey={"id'"}
              labelKey={"provider"}
              onChange={(e) => {
                setSettings({
                  ...settings,
                  accounting_system: e.target.value,
                })
              }}
              defaultOption={true}
            />
          </Col> : null
          }
          {paymentModules.length > 0 ? <Col md={4} sm={6}>
            <InputArraySelect
              label={`Payment system`}
              value={deal && deal.payment_frequency_interval ? deal.payment_frequency_interval : ''}
              options={paymentModules}
              valueKey={"id'"}
              labelKey={"provider"}
              onChange={(e) => {
                setSettings({
                  ...settings,
                  payment_automatic_system: e.target.value,
                })
              }}
              defaultOption={true}
            />
          </Col> : null
          }
        </Row>
          <Col lg={2} md={3} sm={6}>
              <ButtonContainer
                  className="w-100"
                  reverse
                onClick={() => saveSettings()}
              >Save</ButtonContainer>
          </Col>
      </CardBody>
    </>
  );
}