import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import * as constants from 'constants/index.js';
import Cookies from "js-cookie";

import CardsHeader from 'layouts/Headers/CardsHeader.js';

import TableCustom from 'views/pages/components/TableCustom';

import {
  Button,
  Container,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";

export default function RoleShow() {
  const {id} = useParams();
  const location = useLocation();
  const history = useHistory();

  const [name, setName] = useState('');

  const [permissions, setPermissions] = useState([]);
  const [hasPermissions, setHasPermissions] = useState([]);


  useEffect(() => {
    let role = location.state.role;
    setName(role.name);

    const grs_token = Cookies.get('grs_token')

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const roleResponse = await axios({
          method: 'GET',
          url: `${constants.API_CORE_URL}/role/${id}`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        const permissionResponse = await axios({
          method: 'GET',
          url: `${constants.API_CORE_URL}/permission`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })

        const data = permissionResponse.data
        setPermissions(data.data)
        const roleData = roleResponse.data
        const idArray = roleData.data.permissions.map(obj => obj.id);
        setHasPermissions(idArray)
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const goList = () => {
    history.push({
      pathname: '/admin/role'
    })
  }

  return (
    <>
      <CardsHeader name="Admin" parentName="Role Management" currentName="Show Role" />
      <Container className="mt--6 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Roles</h1>
                  </Col>
                  <Col xs={4} className="text-right">
                    <Button className="w-100" color="success" type="button" onClick={() => goList()}>Back to list</Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div>
                  <h2 className="heading-small mb-4">Role Name: <b>{name}</b></h2>
                </div>
                {hasPermissions.length > 0 && 
                <TableCustom 
                  loading={false}
                  keyField={`display_name`}
                  searchField={true}
                  items={permissions}
                  columns={[
                    {
                      dataField: "name",
                      text: "Name",
                      sort: true
                    },
                    {
                      dataField: "",
                      text: "Is Active",
                      formatter: (rowContent, row) => {
                        return (
                          <span className="badge badge-dot mr-4">
                            <i className={hasPermissions.includes(row.id) === true? 'bg-success': 'bg-warning'}></i>
                            <span className="status">{hasPermissions.includes(row.id) === true? 'active': 'inactive'}</span>
                          </span>
                        )
                      }
                    }
                  ]}
                />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}