import React, { useRef } from 'react';

import CardsHeader from 'layouts/Headers/CardsHeader.js';
import NotificationAlert from "react-notification-alert";

import {
  Container,
  Alert,
  Col,
} from "reactstrap";

export default function Supplier() {
  const notificationAlert = useRef(null);

  return (
    <>
      <CardsHeader name="Admin" parentName="Suppliers" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt-2 admin-main-body" fluid >
        <Col md={4}>
          <Alert color="info">
            <strong>Info!</strong> This page was deprecated!
          </Alert>
        </Col>
      </Container>
    </>
  )
}