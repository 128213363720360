import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';
import moment from 'moment';

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import InputCustom from 'views/pages/components/InputCustom';
import InputSideCustom from 'views/pages/components/InputSideCustom';
import {
  InputKeySelect,
} from 'views/pages/components/InputSelect';
import TableCustom from 'views/pages/components/TableCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  UncontrolledAlert,
  FormGroup
} from 'reactstrap';

export default function LatePayments() {
  const notificationAlert = useRef(null);
  const history = useHistory();

  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [payments, setPayments] = useState([]);
  const [paymentDescriptors, setPaymentDescriptors] = useState('');
  const [paymentProcessTypes, setPaymentProcessTypes] = useState('');
  const [paymentStatuses, setPaymentStatuses] = useState('');

  const [filter, setFilter] = useState('');

  const [paymentDetailModal, setPaymentDetailModal] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState('');
  const [paymentStatusPaidOut, setPaymentStatusPaidOut] = useState('');
  const [paymentStatusFailed, setPaymentStatusFailed] = useState('');
  const [paymentStatusNew, setPaymentStatusNew] = useState('');
  const [paymentStatusCreated, setPaymentStatusCreated] = useState('');
  const [processTypeDirectDebit, setProcessTypeDirectDebit] = useState('');
  const [paymentEdit, setPaymentEdit] = useState('');

  const [selectedDeal, setSelectedDeal] = useState('');

  const [savePaymentErrors, setSavePaymentErrors] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/payments/late-and-missed`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data

        setPayments(data.data.items)
        setPaymentDescriptors(data.data.paymentDescriptors)
        setPaymentProcessTypes(data.data.paymentProcessTypes)
        setPaymentStatuses(data.data.paymentStatuses)

        setTimeout(() => {
          setLoading(false)
        }, 1000);
      } catch (error) {
        setLoading(false)
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const filterPayments = () => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/payments/late-and-missed',
      headers: {
        Authorization: 'Bearer ' + token
      },
      params: filter
    })
      .then(response => {
        console.log('res :: ', response.data)
        let data = response.data
        setPayments(data.data.items)
      })
      .catch(error => {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      })
  }

  const resetPayments = () => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/payments/late-and-missed',
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then(response => {
        console.log('res :: ', response.data)
        let data = response.data
        setPayments(data.data.items)
      })
      .catch(error => {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      })
  }

  const goPayment = (deal) => {
    history.push({
      pathname: '/admin/deals/' + deal.id + '/payment'
    })
  }

  const paymentShow = (row) => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/deal/' + selectedDeal.id + '/payments/' + row.id,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then(response => {
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setSelectedPayment(data.data.item);
          setPaymentStatuses(data.data.paymentStatuses);
          setPaymentProcessTypes(data.data.paymentProcessTypes);
          setPaymentStatusPaidOut(data.data.paymentStatusPaidOut);
          setPaymentStatusFailed(data.data.paymentStatusFailed);
          setPaymentStatusNew(data.data.paymentStatusNew);
          setPaymentStatusCreated(data.data.paymentStatusCreated);
          setProcessTypeDirectDebit(data.data.processTypeDirectDebit);

          setPaymentEdit({
            ...paymentEdit,
            scheduled_at: data.data.item && data.data.item.reference? constants.formatDate(data.data.item.reference.charge_date): constants.formatDate(data.data.item.scheduled_at),
            amount: data.data.item && data.data.item.reference? data.data.item.reference.amount: data.data.item.amount,
            process_type: data.data.item && data.data.item.reference? data.data.item.reference.process_type: '',
            payment_reference_id: data.data.item? data.data.item.id: '',
            collect_now: 0,
          })

          setPaymentDetailModal(true);
        }
      })
      .catch(error => {
        console.error('error :: ', error.response);
        notify('warning', 'Warning', error.response.statusText);
      })
  }

  const savePayment = () => {
    axios({
      method: 'POST',
      url: constants.API_URL + '/deal/' + selectedDeal.id + '/save-reference',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: paymentEdit,
    })
      .then(response => {
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setPaymentEdit('');
          setSelectedPayment('');
          setPaymentDetailModal(false);
          setPayments(data.data);
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        if (error.response && error.response.status === 422) {
          let errors = error.response.data.errors;
          let err = '';
          Object.keys(errors).forEach(key => {
            err += errors[key];
          })
          setSavePaymentErrors(err);
        } else if (error.response && error.response.status === 406) {
          let errors = error.response.data.errors;
          let err = errors.message;
          setSavePaymentErrors(err);
        }
      }) 
  }

  const cancelPayment = (referenceId) => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/deal/' + selectedDeal.id + '/cancel/' + referenceId,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then(response => {
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setPaymentEdit('');
          setSelectedPayment('');
          setPaymentDetailModal(false);
          setPayments(data.data);
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        if (error.response && error.response.status === 422) {
          let errors = error.response.data.errors;
          let err = '';
          Object.keys(errors).forEach(key => {
            err += errors[key];
          })
          setSavePaymentErrors(err);
        } else if (error.response && error.response.status === 406) {
          let errors = error.response.data.errors;
          let err = errors.message;
          setSavePaymentErrors(err);
        }
      }) 
  }

  const retryPayment = (referenceId) => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/deal/' + selectedDeal.id + '/retry/' + referenceId,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then(response => {
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setPaymentEdit('');
          setSelectedPayment('');
          setPaymentDetailModal(false);
          setPayments(data.data);
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        if (error.response && error.response.status === 422) {
          let errors = error.response.data.errors;
          let err = '';
          Object.keys(errors).forEach(key => {
            err += errors[key];
          })
          setSavePaymentErrors(err);
        } else if (error.response && error.response.status === 406) {
          let errors = error.response.data.errors;
          let err = errors.message;
          setSavePaymentErrors(err);
        }
      }) 
  }

  return (
    <>
      <CardsHeader name="Payments" parentName="Late Payments Management" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <h1 className="title mb-0">List of missed/late payments</h1>
              </CardHeader>
              <CardBody>
                <Container>
                  <InputSideCustom 
                    label={`Customer`}
                    value={filter && filter.customer? filter.customer: ''}
                    onChange={e => setFilter({
                      ...filter,
                      customer: e.target.value
                    })}
                  />
                  <div className="d-flex flex-row justify-content-center">
                    <Button className="w-50" color="success" onClick={() => filterPayments()}>Filter</Button>
                    <Button className="w-50" color="danger" onClick={() => resetPayments()}>Reset</Button>
                  </div>
                </Container>
                <TableCustom 
                  loading={loading}
                  keyField={`id`}
                  searchField={false}
                  items={payments}
                  columns={[
                    {
                      dataField: "deal",
                      text: "Deal",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return <span onClick={() => goPayment(row.deal)}>{row.deal && row.deal.id? `Deal #${row.deal.id}`: ''}</span>
                      }
                    },
                    {
                      dataField: "full_name",
                      text: "Customer",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.deal && row.deal.complete_name? row.deal.complete_name: ''
                      }
                    },
                    {
                      dataField: "amount",
                      text: "Amount",
                      sort: true
                    },
                    {
                      dataField: "payment_status",
                      text: "Payment status",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.reference && row.reference.payment_status ? paymentStatuses[row.reference.payment_status] : "-"
                      }
                    },
                    {
                      dataField: "process_type",
                      text: "Process type",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.reference && row.reference.process_type ? paymentProcessTypes[row.reference.process_type] : "-"
                      }
                    },
                    {
                      dataField: "scheduled_at",
                      text: "Due Date",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return constants.formatDateShortGB(row.scheduled_at)
                      }
                    },
                    {
                      dataField: "payment_descriptor",
                      text: "Payment descriptor",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return paymentDescriptors[row.payment_descriptor]
                      }
                    },
                    {
                      dataField: "",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            {row.id && row.scheduled_at && 
                              <i 
                                className="fab fa-amazon-pay" 
                                style={{cursor: 'pointer',}}
                                onClick={() => {
                                  setSelectedDeal(row.deal)
                                  paymentShow(row)
                                }}
                              ></i>
                            }
                          </>
                        )
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={paymentDetailModal}
          toggle={() => setPaymentDetailModal(false)}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h2>{`Payment reference editor`}</h2>
          </div>
          <div className="modal-body">
            <h1 className="my-1">{`Notes`}</h1>
            <ul className="list-group mt-3 mb-5">
              <li className="list-group-item">Past payments can't be edited for direct debit processing</li>
              <li className="list-group-item">Direct debit payments are automatically processed</li>
              <li className="list-group-item">Cash/Card are automatically set as paid</li>
            </ul>
            {savePaymentErrors && <UncontrolledAlert color="warning" fade={false}>
              <span className="alert-inner--text">
                <strong>Warning!</strong> {savePaymentErrors}
              </span>
            </UncontrolledAlert>}
            {selectedPayment && selectedPayment.reference && selectedPayment.reference.payment_status === paymentStatusPaidOut && <Row>
              <Col sm={12}>
                <h3 className="text-info heading mb-0">{`Payment successfully collected!`}</h3>
              </Col>
            </Row>}
            {selectedPayment && selectedPayment.reference && selectedPayment.reference.process_type === processTypeDirectDebit && <Row>
              <Col sm={3}>
                <small className="text-uppercase text-muted font-weight-bold">Payment Reference</small>
              </Col>
              <Col sm={9}>
                <h3 className="heading mb-0">{selectedPayment.reference.payment_gateway_reference_id}</h3>
              </Col>
            </Row>}
            {selectedPayment && selectedPayment.reference && selectedPayment.reference.payment_status === paymentStatusFailed && <Row>
              <Col sm={12}>
                <h3 className="text-danger heading mb-0">{`Payment failed! You can take future actions by retrying the payment!`}</h3>
              </Col>
            </Row>}
            <InputCustom 
              type={`date`}
              label={`Charge At`}
              value={paymentEdit && paymentEdit.scheduled_at? paymentEdit.scheduled_at: ''}
              onChange={(e) => setPaymentEdit({
                ...paymentEdit,
                scheduled_at: e.target.value,
              })}
            />
            <InputCustom 
              type={`number`}
              label={`Amount`}
              value={paymentEdit && paymentEdit.amount? paymentEdit.amount: ''}
              onChange={(e) => setPaymentEdit({
                ...paymentEdit,
                amount: e.target.value,
              })}
            />
            <InputKeySelect 
              label={`Process type`}
              value={paymentEdit && paymentEdit.process_type? paymentEdit.process_type: ''}
              options={paymentProcessTypes}
              onChange={(e) => setPaymentEdit({
                ...paymentEdit,
                process_type: e.target.value,
              })}
              defaultOption={true}
            />
            {selectedPayment.scheduled_at >= moment().format() 
            && (!selectedPayment.reference 
              || (selectedPayment.reference 
                && selectedPayment.reference.process_type === processTypeDirectDebit
                && selectedPayment.reference.payment_status === paymentStatusNew))
                && <FormGroup>
              <label className="form-control-label">{`Collect payment now (only for direct debit payments)`}</label>
              <div>
                <label className="custom-toggle">
                  <input
                    type="checkbox"
                    checked={paymentEdit.collect_now === 1 ? true : false}
                    onChange={() =>
                      setPaymentEdit({
                        ...paymentEdit,
                        collect_now: paymentEdit.collect_now === 1 ? 0 : 1
                      })
                    }
                  />
                  <span
                    className="custom-toggle-slider rounded-circle"
                    data-label-off="No"
                    data-label-on="Yes"
                  />
                </label>
              </div>
            </FormGroup>}
          </div>
          <div className="modal-footer mb-4">
            {selectedPayment.reference 
            && selectedPayment.reference.process_type === processTypeDirectDebit 
            && selectedPayment.reference.payment_status === paymentStatusCreated
            && <Button
              className="new-event--add"
              color="primary"
              type="button"
              onClick={() => cancelPayment(selectedPayment.reference.id)}
            >
              {`Cancel Payment`}
            </Button>}
            {selectedPayment.reference 
            && selectedPayment.reference.process_type === processTypeDirectDebit 
            && selectedPayment.reference.payment_status === paymentStatusFailed
            && <Button
              className="new-event--add"
              color="primary"
              type="button"
              onClick={() => retryPayment(selectedPayment.reference.id)}
            >
              {`Retry`}
            </Button>}
            {!(selectedPayment.reference 
            && selectedPayment.reference.process_type === processTypeDirectDebit 
            && selectedPayment.reference.payment_status !== paymentStatusNew)
            && <Button
              className="new-event--add"
              color="primary"
              type="button"
              onClick={() => savePayment()}
            >
              {`Save`}
            </Button>}
            <Button
              className="ml-auto"
              color="danger"
              type="button"
              onClick={() => {
                setSavePaymentErrors('');
                setPaymentDetailModal(false)
              }}
            >
              {`Close`}
            </Button>
          </div>
        </Modal>
      </Container>
    </>
  )
}