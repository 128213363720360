import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import NotificationAlert from 'react-notification-alert';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import * as signatureActions from '@redux/Signature/actions';
import useNotify from '../../../../../hooks/notify';

import {
  Row,
  Col,
  Card,
  CardBody,
  Input
} from "reactstrap";
import { CustomSignatureCanvas } from './SignatureCanvas';
import { Checkbox } from './fields/Checkbox';

import { BASE_URL } from './../../../../../constants/index';
import styled from 'styled-components';
import PdfComponent from './PdfComponent';

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
}

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`
};

const Progress = styled.div`
  background: white;
  padding: 0.25em 1em;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  height:80px;
  box-shadow: 0 -15px 15px -20px #73889a;
  position: fixed;
`;

const ProgressTop = styled(Progress)`
  top: 0;
  box-shadow: 0 15px 15px -20px #73889a;

  h1{
    font-size: 1.5rem; 
    &.progress-top {
      @media (max-width: ${size.tablet}) {
        font-size: 1rem; 
      }
    }
    span {
      color: red;
    }
  }
`;

const ProgressComplete = styled.div`
  width:  ${props => props.width ? props.width : "0"}%;
  padding: 5px 0;
  background-color: ${props => props.width === 100 ? "#1bce57" : "#49509c"};;
  -webkit-transition: width .25s ease-in-out;
  transition: width .25s ease-in-out;
  top: 0;
  left: 0;
`;

const ProgressField = styled(Progress)`
  bottom: 80px;
  height: ${props => props.type === 'big' ? '400px' : "100px"};
  @media ${device.mobileS} { 
    height: ${props => props.type === 'big' ? '60vh' : "30vh"};
  }
  @media ${device.tablet} { 
    height: ${props => props.type === 'big' ? '40vh' : "20vh"};
  }
`;

const CloseButton = styled.div`
  top: 0;
  right:0;
  position: absolute;
`;

const ProgressInfo = styled.div`
  padding: 12px;
  padding-right: 0;
  color: #004d68;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: ${props => props.position ? props.position : "end"};
  @media (max-width: ${size.tablet}) {
    padding: 0;
  }
`;

export const FieldTitle = styled.h2`
  padding: 15px;
  border-bottom: 1px solid #dde2e5;
  margin: 0;
  position: relative;
`;

const NextButton = styled.button`
  background-color: #0098ce;
  margin-left: 15px;
  border-color: #0098ce;
  color: #fff;
  padding: 12px 15px;
  float: left;
  font-weight: 700;
  text-decoration: none;
  border: 1px solid #004d68;
  border-radius: 3px;
  :disabled {
    opacity: 0.4;
  }
  &.completed {
    color: #fff;
    background-color: #2dce89;
    border-color: #2dce89;
  }
}
`;


export function Process() {
  const { token } = useParams();
  const { ref, notify } = useNotify();
  const dispatch = useDispatch();
  const [customFields, setCustomFields] = useState([]);
  const [selectedField, setSelectedField] = useState(null);
  const { document, documentSaved, SendEmailSuccess, trimmedDataURL } = useSelector(
    (state) => state.customerSignatureState,
    shallowEqual
  );

  let pdfWrapper = null;

  const [width, setWidth] = useState(null);

  useEffect(() => {
    if (document.fields) {
      setDivSize()
    }
  }, document.fields)

  const setDivSize = () => {
    setWidth(pdfWrapper.getBoundingClientRect().width)
  }

  useEffect(() => {
    if (documentSaved !== null) {
      if (documentSaved) {
        // notify('All details are required! Please fill them in before submitting!', "Success", 'success');
      } else {
        notify("Warning", 'Warnings', 'Warning');
      }
    }
  }, [documentSaved]);

  useEffect(() => {
    if (SendEmailSuccess !== null) {
      if (SendEmailSuccess) {
        notify('Succesfully saved!', "Success", 'success');
      } else {
        notify("Error occurred while saving", 'Warning', 'Warning');
      }
    }
  }, [SendEmailSuccess]);

  useEffect(() => {
    if (document.fields) {
      let fields = document.fields.map((item, index) => {
        return {
          ...item,
          index
        }
      });

      setCustomFields(fields);
    }
  }, [document.fields]);

  useEffect(() => {
    if (customFields && customFields.length > 0 && customFields.filter(item => !item.value).length > 0) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  }, [customFields]);

  const typeHeight = useMemo(() => {
    if (selectedField === null) return null;

    let field = document.fields[selectedField];

    let height;
    switch (field.type) {
      case '3':
        height = 'big';
        break;
      case '1':
      case '2':
      case '0':
        height = 'medium';
        break;
      default:
        break;
    }

    return height;
  }, [selectedField]);

  const upload = () => {
    dispatch({
      type: signatureActions.SIGN_DOCUMENT_REQUEST,
      payload: { customFields, token }
    });
  }

  const save = () => {
    upload();
  }

  const renderSelectedField = () => {
    if (selectedField === null) return null;
    let field = document.fields[selectedField];

    let activeElement = window.document.evaluate(`//*[text()[contains(., '${field.unique_identifier_on_doc}')]][last()]`, window.document.body).iterateNext();
    let previosActive = window.document.getElementsByClassName("custom-field-active");

    if (previosActive.length > 0) {
      previosActive[0].classList.remove("custom-field-active");
      activeElement.classList.add("custom-field-active");
      window.scrollBy(0, window.document.evaluate(`//*[text()[contains(., '${field.unique_identifier_on_doc}')]][last()]`, window.document.body).iterateNext().getBoundingClientRect().top - 100);
    }
    return renderField(field, selectedField);
  }

  const renderField = (field, index) => {
    if (!field) {
      return null;
    }

    if (field.type == 3) {
      return (
        <CustomSignatureCanvas
          save={save}
          field={field}
          confirm={(trimmedDataURL) => {
            setCustomFields(prev => {
              let tempField = {
                ...field,
                value: trimmedDataURL
              };

              let fields = [...prev];
              fields[index] = tempField;

              return fields;
            });
          }}
        />
      )
    }
    else if (field.type == 1) {

      return (
        <Col>
          <Col lg={4} sm={12}>
            <FieldTitle>{field.frontend_name}</FieldTitle>
          </Col>
          <Col sm={12} lg={4}>
            <Input
              type={`text`}
              className={!field.value ? "signature-input-empty" : ''}
              max={100}
              placeholder={field.frontend_name}
              value={customFields.find(item => item.id === field.id).value ?? ''}
              onChange={(e) => {
                setCustomFields(prev => {
                  let tempField = {
                    ...field,
                    value: e.target.value
                  };

                  let fields = [...prev];
                  fields[index] = tempField;

                  return fields;
                });
              }}
            />
          </Col>
        </Col>
      )
    }
    else if (field.type == 2) {
      return (
        <Col>
          <Col xs={10}>
            <FieldTitle>{field.frontend_name}</FieldTitle>
          </Col>
          <Col xs={12} className="signing">
            <DatePicker
              dateFormat="yyyy/MM/dd"
              selected={customFields.find(item => item.id === field.id).value ?? null}
              minDate={moment().toDate()}
              maxDate={moment().add(10, "days").toDate()}
              onChange={(date) => {
                setCustomFields(prev => {
                  let tempField = {
                    ...field,
                    value: date
                  };

                  let fields = [...prev];
                  fields[index] = tempField;

                  return fields;
                });
              }}
            />
          </Col>
        </Col>
      )
    }
    else if (field.type == 0) {
      return (
        <Checkbox
          field={field}
          checkedValue={customFields.find(item => item.id === field.id).value ?? null}
          handleChange={(val) => {
            setCustomFields(prev => {
              let tempField = {
                ...field,
                value: val
              };

              let fields = [...prev];
              fields[index] = tempField;

              return fields;
            });
          }}
        />
      )
    }

    return null;
  }

  const checkIfDisabled = () => {
    if (selectedField === null) return false;

    if (customFields[selectedField].type !== 3 && customFields[selectedField].value) return false;
    if (customFields[selectedField].type === 3 && trimmedDataURL) return false;

    return true;
  }

  return (
    <>
      {SendEmailSuccess !== true && customFields.length > 0 && <>
        {selectedField !== null && <ProgressField type={typeHeight}>
          <div className="position-relative">
            <CloseButton>
              <a className="btn-close" onClick={() => {
                setSelectedField(null)
              }}>
                <span className="icon-cross"></span>
              </a>
            </CloseButton>
            <div className="d-flex w-100 flex-column">
              {renderSelectedField()}
            </div>
          </div>
        </ProgressField>
        }

        <Progress>
          <Row className="justify-content-center align-items-center w-100 h-100">
            <Col xs={12}>
              <Row className="justify-content-center align-items-center">
                <Col xs={12} className="">
                  <ProgressInfo>
                    {customFields.filter(field => !field.value).length > 0 && <>
                      {customFields.filter(field => !field.value).length} items to complete <NextButton
                        disabled={checkIfDisabled()}
                        onClick={() => {
                          setSelectedField(prev => prev === document.fields.length - 1 ? 0 : prev + 1);
                        }}
                      >Next: {selectedField === null ? 'Sign here' : customFields[selectedField].frontend_name}</NextButton></>}

                    {customFields.filter(field => !field.value).length === 0 && <NextButton className="completed"
                      onClick={() => {
                        setSelectedField(prev => prev === document.fields.length - 1 ? 0 : prev + 1);
                      }}
                    >Check selections</NextButton>}
                    {SendEmailSuccess !== true && customFields.filter(field => !field.value).length === 0 &&
                      <NextButton
                        onClick={save}
                      >
                        Save and Submit
                      </NextButton>
                    }
                  </ProgressInfo>
                </Col>
              </Row>
            </Col>
          </Row>
        </Progress>
      </>
      }
      <div className="rna-wrapper">
        <NotificationAlert ref={ref} />
      </div>
      <ProgressTop>
        <Row className="justify-content-center align-items-center w-100 h-100">
          <Col xs={12}>
            <Row className="justify-content-center align-items-center">
              <Col xs={12} className="">
                <ProgressInfo position="left">
                  <Row className="w-100">
                    <Col xs={8}>
                      <h1 className="progress-top mb-0">{document?.realName ?? "Signature pad"}</h1>
                    </Col>
                    <Col xs={4}>
                      <h1 className="progress-top mb-0"><span>{parseInt(Math.round((customFields.filter(item => item.value).length / customFields.length) * 100)) ?? 0}% completed</span></h1>
                    </Col>
                  </Row>
                </ProgressInfo>
              </Col>
            </Row>
          </Col>
        </Row>
        {customFields && <ProgressComplete width={parseInt(Math.round((customFields.filter(item => item.value).length / customFields.length) * 100) ?? 0) ?? 0} />}
      </ProgressTop>
      <Row style={{ position: "relative" }}>
        <Col xl="12">
          <Card>
            <CardBody>
              <Row>
                <Col xs={12}>
                  {/* {document && <div dangerouslySetInnerHTML={{ __html: atob(document.content) }} />} */}
                  <div id="pdfWrapper" style={{ width: "90vw" }} ref={(ref) => pdfWrapper = ref}>
                    <PdfComponent
                      wrapperDivSize={width}
                      file={`${BASE_URL}/api/v1/customer-experience/signing/view?token=${token}`}
                    />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}