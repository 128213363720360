import React, {useEffect, useRef, useState} from 'react'
import {useHistory} from 'react-router-dom'
import NotificationAlert from "react-notification-alert"
import axios from 'axios'
import Cookies from "js-cookie"
import {hasFleetAdminPermission} from '../../../utils/dealer';

import * as constants from 'constants/index.js'


import {
    ModalBody,
    ModalHeader,
    Modal, Button,
} from "reactstrap"
import DynamicTable from "../components/DynamicTable";
import { parseToWord} from "constants/index.js";
import {useSelector} from "react-redux";
import {
    LoaderContainer,
    ModalBodyContainer, override,
    ReportContainer,
} from "../reusable-components/styled-components";
import {useLocation} from "react-router";
import {ClipLoader} from "react-spinners";
import {Text, RadioTextContainer, RadioButton, ButtonContainer,
    RadioButtonWrapper, Circle, ContentContainer, TitleContainer,
    CardContainer, Card,} from "@pages/reusable-components/fleet-styled-components";


export default function Fleet() {
    const notificationAlert = useRef(null)
    const [modal, setModal] = useState(false);
    const [disableSaving, setDisableSaving] = useState(true);
    const [token, setToken] = useState('');
    const [backdrop] = useState(true);
    const [keyboard] = useState(true);
    const [rSelected, setRSelected] = useState(null);
    const [timeModal, setTimeModal] = useState(false);
    const [updateDataDraft,setUpdateDataDraft] = useState('')
    const [updateData,setUpdateData] = useState({})
    const [loading, setLoading] = useState(true);
    const [models, setModels] = useState([])
    const [makes, setMakes] = useState([])
    const [makesAndModels, setMakesAndModels] = useState('')
    const [vehiclesCount, setVehiclesCount] = useState({})
    const [statuses] = useState([
        {
            value: 'to_do',
            text: 'To do'
        },
        {
            value: 'in_progress',
            text: 'In progress'
        },
        {
            value: 'in_review',
            text: 'In review'
        },
        {
            value: 'completed',
            text: 'Completed'
        }
    ])
    const [keys] = useState(
        [
            {
                key: 'title',
                text: 'task'
            },
            {
                key: 'make',
                text: 'make'
            },
            {
                key: 'model',
                text: 'model'
            },
            {
                key: 'stock_no',
                text: 'reg no'
            },
            {
                key: 'status',
                text: 'status'
            }
        ]
    )
    const [workerKeys] = useState(
        [
            {
                key: 'title',
                text: 'task'
            },
            {
                key: 'make',
                text: 'make'
            },
            {
                key: 'model',
                text: 'model'
            },
            {
                key: 'stock_no',
                text: 'reg no'
            },
            {
                key: 'status',
                text: 'status'
            },
            {
                key: 'workers',
                text: 'workers'
            },
            {
                key: 'priority',
                text: 'priority'
            },
        ]
    )
    const [priorities] = useState([
        {
            label: 'High',
            value: 'high',
            color: 'red',
            icon: 'ep_d-arrow-up-double.png'
        },
        {
            label: 'Medium',
            value: 'medium',
            color: 'green',
            icon: 'fluent_line-equal.png'
        },
        {
            label: 'Low',
            value: 'low',
            color: 'Blue',
            icon: 'ep_arrow-down.png'
        }
    ])
    const history = useHistory();
    const location = useLocation();
    const [tableData, setTableData] = useState([])
    const [modelValue, setModelValue] = useState('')
    const [makeValue, setMakeValue] = useState('')
    const [priorityValue, setPriorityValue] = useState('')
    const [selectedStatus, setSelectedStatus] = useState('')
    const [filter, setFilter] = useState({})
    const [modalTitle] = useState('');
    const [modalSubTitle] = useState(null);
    const [statusChanged, setStatusChanged] = useState(false);
    const [taskUpdatingStatus, setTaskUpdatingStatus] = useState(null);
    const roles = useSelector(state => state.getState).roles;
    const isAdmin = hasFleetAdminPermission(roles);
    const isWorker = roles.includes('Fleet')
    const notify = (type, header, message) => {
        let options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
              {header}
          </span>
                    <span data-notify="message">
            <div dangerouslySetInnerHTML={{__html: message}}/>
          </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5
        }
        notificationAlert.current.notificationAlert(options)
    }

    useEffect(() => {
        if (roles.length) {
            let savedFilter = {}
            if (localStorage.getItem('grms_fleet_search_filter')) {
                savedFilter = JSON.parse(localStorage.getItem('grms_fleet_search_filter'))
                setPriorityValue(savedFilter?.priority)
                setSelectedStatus(parseToWord(savedFilter?.status))
                setFilter(savedFilter)
            }
            let grs_token = Cookies.get('grs_token')
            setToken(grs_token)
            fetchData(grs_token, savedFilter)
        }
    }, [roles])
    const toggle = () => setModal(!modal);
    useEffect(() => {
        if (makes.length && !makeValue && makesAndModels.length) {
            if (filter.hasOwnProperty('make') && filter.make) {
                setMakeValue(makes.find(item => filter.make === item.id)?.name)
                const modelsTemp = makesAndModels.filter(item => item.vehicle_make_id === filter.make)
                setModels(modelsTemp)
                if (modelsTemp.length) {
                    if (filter.hasOwnProperty('model') && filter.model) {
                        setModelValue(modelsTemp.find(item => filter.model === item.id)?.name)
                    }
                }
            }
        }

    }, [makes, makesAndModels])
    const fetchData = (token, filter, searching = false) => {
        localStorage.setItem('grms_fleet_search_filter', JSON.stringify(filter))
        const hasAdminPermission = hasFleetAdminPermission(roles);
        const url = hasAdminPermission ? '/fleet-management/dashboard' : '/fleet-worker/dashboard'
        axios({
            method: 'GET',
            url: `${constants.FLEET_MS_API_URL}${url}/tasks`,
            headers: {
                Authorization: 'Bearer ' + token
            },
            params: filter
        })
            .then(async response => {
                if (response.status === 200) {
                    let key = hasAdminPermission ? 'fleet' : 'tasks'
                    let data = response.data?.data;
                    if(data && data[key] && data[key].length > 0) {
                        for (let i = 0; i < data[key].length; i++) {
                            data[key][i].make = data[key][i].vehicle.make?.name;
                            data[key][i].model = data[key][i].vehicle.model?.name;
                            data[key][i].stock_no = data[key][i].vehicle?.stock_no;
                        }
                        setTableData(data[key])
                        setUpdateDataDraft(JSON.stringify(data[key]))
                    }
                    if (!searching) {
                        if (hasAdminPermission) {
                            const vehiclesCount = await axios({
                                method: 'GET',
                                url: `${constants.FLEET_MS_API_URL}${url}/vehicle-group-meta`,
                                headers: {
                                    Authorization: 'Bearer ' + token
                                },
                            })
                            setVehiclesCount(vehiclesCount.data.data.meta)
                        }

                        const makeRequest = await axios({
                            method: 'GET',
                            url: constants.FLEET_URL + '/fleet-management/vehicle/makes',
                            headers: {
                                Authorization: 'Bearer ' + token
                            }
                        })
                        setMakes(makeRequest.data.data.makes)
                        const modelRequest = await axios({
                            method: 'GET',
                            url: constants.FLEET_URL + '/fleet-management/vehicle/models',
                            headers: {
                                Authorization: 'Bearer ' + token
                            }
                        })
                        setMakesAndModels(modelRequest.data.data.models)
                    }
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
                console.error('error :: ', error)
                notify('warning', 'Warning', error?.response?.statusText)
            })
    }
    const filterVehicle = async (filter) => {
        await fetchData(token, filter, true)
    }
    const updateTaskStatus = async (status) => {
        await axios({
            method: 'GET',
            url: `${constants.FLEET_URL}/fleet-worker/worker/${status}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
    }
    const clearFilers = () => {
        setMakeValue(null)
        setModelValue(null)
        setPriorityValue(null)
        setSelectedStatus(null)
        localStorage.removeItem('grms_fleet_search_filter')
        setFilter({})
        filterVehicle({})
    }
    const setMakeFilter = async (e) => {
        setModels(makesAndModels.filter(item => item.vehicle_make_id === e.id))
        setMakeValue(e.name)
        setModelValue('')
        setFilter({
            ...filter,
            make: e.id,
            model: null,
        })
        let tempFilter = filter
        tempFilter.make = e.id
        tempFilter.model = null
        setFilter(tempFilter)
        await filterVehicle(tempFilter)
    }
    const setModelFilter = async (e) => {
        setModelValue(e.name)
        let tempFilter = filter
        tempFilter.model = e.id
        setFilter(tempFilter)
        await filterVehicle(tempFilter)
    }
    const searchByText = async (e) => {
        let tempFilter = filter
        tempFilter['search'] = e.target.value
        setFilter(tempFilter)
        await filterVehicle(tempFilter)
    }
    const setPriorityFilter = async (e) => {
        setPriorityValue(e.label)
        let tempFilter = filter
        tempFilter.priority = e.label
        setFilter(tempFilter)
        await filterVehicle(tempFilter)
    }
    const selectStatus = async (e) => {
        setSelectedStatus(e.text)
        let tempFilter = filter
        tempFilter.status = e.value
        setFilter(tempFilter)
        await filterVehicle(tempFilter)
    }

    const toggleModal = () => setTimeModal(!timeModal);


    const confirm = () => {
        if (statusChanged) {
        }
        updateTaskStatus(taskUpdatingStatus)
        toggleModal()
    }
    const closeModal = () => {
        setTaskUpdatingStatus('')
        setStatusChanged(false)
        toggleModal();
    }
    const addVehicle = () => {
        history.push({
            pathname: '/admin/fleet-job/create'
        })
    }
    const addTasks = () => {
        history.push({
            pathname: '/admin/tasks',
            state: {
                showAddTaskComponent: true,
                from: location
            }
        })
    }
    const selectOptionStatus = (e) => {
        let tempData = [...tableData]
        tempData[e.index].status = e.status
        let tempUpdateData = {...updateData}
        tempUpdateData[e.id] = {id: e.id, status: e.status}
        setUpdateData(tempUpdateData)
        setDisableSaving(updateDataDraft === JSON.stringify(tempData))

        setTableData(tempData)
    }
    const saveStatuses = () => {
        setLoading(true)
        setDisableSaving(true)
        axios({
            method: 'POST',
            url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet/edit`,
            headers: {
                Authorization: 'Bearer ' + token
            },
            data: updateData,
        }).then(res=>{
            filterVehicle({})
            setDisableSaving(false)
        }).catch(err=>{
            setDisableSaving(false)
        })
    }
    return (
        <>
            <Modal
                isOpen={modal}
                toggle={toggle}
                backdrop={backdrop}
                keyboard={keyboard}
            >
                <ModalHeader toggle={toggle}>Please select the type of document</ModalHeader>
                <ModalBody>

                    <RadioTextContainer>
                        <ContentContainer gap='5px'>
                            <img height='25px' width='25px' src={require("assets/images/directions_car.png")} alt=""/>
                            <img height='25px' width='25px' src={require("assets/images/library_books.png")} alt=""/>
                        </ContentContainer>
                        <ContentContainer gap='5px'>
                                <span>
                                       Add vehicle
                                 </span>
                            <span>
                               Add task
                              </span>
                        </ContentContainer>
                        <ContentContainer width='100px' gap='5px' position='end'>
                            <RadioButtonWrapper>
                                <RadioButton
                                    onClick={() => {
                                        setRSelected(1)
                                        addVehicle()
                                    }}
                                    selected={rSelected === 1}
                                >
                                </RadioButton>
                            </RadioButtonWrapper>
                            <RadioButtonWrapper>
                                <RadioButton
                                    onClick={() => {
                                        setRSelected(2)
                                        addTasks()
                                    }}
                                    selected={rSelected === 2}
                                >
                                </RadioButton>
                            </RadioButtonWrapper>
                        </ContentContainer>

                    </RadioTextContainer>

                </ModalBody>
            </Modal>
            <Modal
                isOpen={timeModal}
                toggle={toggleModal}
            >
                <ModalHeader toggle={toggleModal}>

                </ModalHeader>
                <ModalBodyContainer>
                    <Text color='#ec6409'>{modalTitle}</Text>
                    <Text size='14px'>{modalSubTitle}</Text>
                    <ReportContainer>
                        <Button outline={true} className="w-100"
                                style={{backgroundColor: "white", color: '#ec6409', border: '1px solid #ec6409'}}
                                type="button" onClick={() => {
                            closeModal()

                        }}>Cancel</Button>
                        <Button className="w-100" style={{backgroundColor: "#ec6409", color: 'white', border: 'none'}}
                                type="button" onClick={() => {
                            confirm()
                        }}>Confirm</Button>
                    </ReportContainer>
                </ModalBodyContainer>
            </Modal>
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlert}/>
            </div>
            {loading ?
                <LoaderContainer>
                    <ClipLoader
                        css={override}
                        size={40}
                        color={`#7B61E4`}
                        loading={loading}
                    />
                </LoaderContainer> :
                <div className='pt-3-4 admin-main-body container-fluid'>
                    {!isWorker &&
                    <ButtonContainer onClick={toggle}>
                        <i className="fa fa-plus" aria-hidden="true"></i>
                        Add New
                    </ButtonContainer>

                    }

                    <CardContainer style={{marginTop: window.innerWidth < 833 ? '25px' : ''}}>
                        {vehiclesCount.length ? vehiclesCount?.map((item,index) => {
                            return (
                                <Card key={index}>
                                    <TitleContainer bottom="20px">
                                        <img height='25px' width='25px'
                                             src={require("assets/images/directions_car.png")}
                                             alt=""/>
                                        <h3>{item.name}</h3>
                                    </TitleContainer>
                                    <ContentContainer>
                                        <TitleContainer>
                                            <Circle></Circle>
                                            <Text width={'90px'}>To do</Text>
                                            <Text wide={'800'} width={'25px'}>{item.to_do}</Text>
                                        </TitleContainer>
                                    </ContentContainer>
                                </Card>
                            )
                        }): null}
                    </CardContainer>
                    <DynamicTable
                        onSearchChange={searchByText}
                        searchValue={filter?.hasOwnProperty('search') ? filter['search'] : ''}
                        showClearButton
                        clearFilers={clearFilers}
                        make={makes}
                        model={models}
                        labelKey={`name`}
                        showStatus={true}
                        disableSaving={disableSaving}
                        saveStatuses={saveStatuses}
                        showSelectForStatus={isAdmin}
                        selectOptionStatus={selectOptionStatus}
                        valueKey={`id`}
                        selectMake={setMakeFilter}
                        selectPriority={setPriorityFilter}
                        selectStatus={selectStatus}
                        goToDetails={(e) => history.push(`/admin/task-details?id=${e}`,{from: 'fleet'})}
                        selectModel={setModelFilter}
                        makeValue={makeValue ? makeValue : ''}
                        modelValue={modelValue ? modelValue : ''}
                        priorityValue={priorityValue ? priorityValue : ''}
                        showCarFilter={true}
                        statuses={statuses}
                        selectedStatus={selectedStatus}
                        tableData={tableData}
                        priorities={priorities}
                        keys={isAdmin ? keys : workerKeys}
                    />

                </div>}
        </>
    )
}