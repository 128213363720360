import * as actionTypes from './actions';
import produce from 'immer';

function initialDocument() {
  return {
    content: null,
    id: null,
    realName: null,
    fields: []
  }
}
const initialState = {
  canSignTheDocument: false,
  linkErrorMessage:null,
  linkErrorCode: false,
  document: initialDocument(),
  documentSaved: null,
  SendEmailSuccess: null,
  trimmedDataURL: null
};

const customerSignatureState = produce((draft = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_VALIDITY_REQUEST:
      draft.canSignTheDocument = false;
      break;
    case actionTypes.GET_VALIDITY_SUCCESS:
      draft.canSignTheDocument = true;
      draft.documentSaved = true;
      draft.document = {
        content: action.data.document_content,
        realName: action.data.document_real_name,
        id: action.data.id,
        fields: action.data.fields
      }
      break;
    case actionTypes.GET_VALIDITY_ERROR:
      let { status, data } = action.response;
      draft.canSignTheDocument = false;
      draft.documentSaved = false;

      if (status === 403) {
        draft.linkErrorMessage = data.message;
        draft.linkErrorCode = data.error;
      }

      break;
    case actionTypes.SIGN_DOCUMENT_SUCCESS:
      draft.SendEmailSuccess = true;
      break;
    case actionTypes.SIGN_DOCUMENT_ERROR:
      draft.SendEmailSuccess = false;
      break;
    case actionTypes.SET_SIGNATURE_URL:
      draft.trimmedDataURL = action.payload.trimmedDataURL;
      break;
    case actionTypes.CLEAN_SIGNATURE_URL:
      draft.trimmedDataURL = null;
      break;
    default:
      return draft;
  }
});

export default customerSignatureState;
