import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';

import AdminLayout from 'layouts/Admin.js';
import IndexView from 'views/Index.js';
import { Signature } from '@pages/customers/customer/Signature';
import FleetRoutes from "@pages/fleet/fleetRoutes";


export default function App() {
  return (
    <Switch>
      <ProtectedFleetImages path="/fleet-pdf" component={FleetRoutes} />
      <ProtectedRoute path="/admin" component={AdminLayout} />
      <ProtectedCustomer path="/customer/me/signature/:token" component={Signature} />
      <ProtectedLogin path="/" component={IndexView} />
      <Redirect from="*" to="/" />
    </Switch>
  );
}

const ProtectedRoute = ({ component: Component, ...rest }) => {
  let auth = false;
  if (Cookies.get('loggedin')) {
    auth = true;
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        auth ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

const ProtectedLogin = ({ component: Component, ...rest }) => {
  let auth = false;
  if (Cookies.get('loggedin')) {
    auth = true;
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        !auth ? <Component {...props} /> : <Redirect to="/admin/dashboard" />
      }
    />
  );
};

const ProtectedCustomer = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>  <Component {...props} />
      }
    />
  );
};
const ProtectedFleetImages = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) =>  <Component {...props} />
            }
        />
    );
};

