import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import TableCustom from 'views/pages/components/TableCustom';
import InputCustom from 'views/pages/components/InputCustom';
import {
  InputArraySelect,
} from 'views/pages/components/InputSelect';

import {
  hasPermission
} from 'views/policies/VehicleMakePolicy';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Modal,
  FormGroup,
} from 'reactstrap';
import { ButtonContainer } from "@pages/reusable-components/styled-components";
import { validateForm } from "constants/index.js";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";

const vehicleTypes = [
  {
    name: "Car",
    value: "car",
  },
  {
    name: "Van",
    value: "van",
  },
];

export default function VehicleModel() {
  const notificationAlert = useRef(null);

  const permissions = useSelector(state => state.getState).permissions;

  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [isNotValid, setIsNotValid] = useState(true);
  const [delay, setDelay] = useState(true);
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);

  const [model, setModel] = useState('');
  const [modelModal, setModelModal] = useState({
    show: false,
    header: '',
    division: ''
  });

  const [deleteModal, setDeleteModal] = useState({
    show: false,
    header: '',
    body: '',
    onClick: null,
  })

  const [errors, setErrors] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  const fetchData = async () => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/vehicle-model`,
        headers: {
          Authorization: `Bearer ${grs_token}`,
        },
        cancelToken: source.token,
      })
      console.log('response :: ', response.data)
      const data = response.data
      setModels(data.data)
      const makesResponse = await axios({
        method: 'GET',
        url: `${constants.API_URL}/vehicle-make`,
        headers: {
          Authorization: `Bearer ${grs_token}`,
        },
        cancelToken: source.token,
      })
      const mData = makesResponse.data
      setMakes(mData.data)

      setTimeout(() => {
        setDelay(true)
        setLoading(false)
      }, 500);
    } catch (error) {
      setLoading(false)
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
      }
    }
  }

  useEffect(() => {
    fetchData();
  }, [])

  const addModel = () => {
    let formData = new FormData()
    Object.keys(model).forEach(key => {
      formData.append(key, model[key])
    })
    axios({
      method: 'POST',
      url: `${constants.API_URL}/vehicle-model`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: formData
    })
      .then(response => {
        setModelModal({
          ...modelModal,
          show: false
        })
        setModel('')
        setErrors('')
        console.log('res :: ', response.data)
        const data = response.data
        fetchData();
        notify('success', 'Success', data.message)
      })
      .catch(error => {
        setErrors('')
        console.error('error :: ', error)
        if (error.response && error.response.status === 422) {
          setErrors(error.response.data.errors)
          return
        }
        setModel('')
        setModelModal({
          ...modelModal,
          show: false
        })
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      })
  }

  const updateModel = () => {
    let formData = new FormData()
    Object.keys(model).forEach(key => {
      if (key === 'featured_image') {
        if (typeof model[key] == 'object') {
          formData.append(key, model[key])
        }
      } else {
        formData.append(key, model[key])
      }
    })
    formData.append('_method', 'PUT')
    axios({
      method: 'POST',
      url: `${constants.API_URL}/vehicle-model/${model.id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: formData
    })
      .then(response => {
        setModelModal({
          ...modelModal,
          show: false
        })
        setModel('')
        setErrors('')
        console.log('res :: ', response.data)
        const data = response.data
        fetchData();
        notify('success', 'Success', data.message)
      })
      .catch(error => {
        setErrors('')
        console.error('error :: ', error)
        if (error.response && error.response.status === 422) {
          setErrors(error.response.data.errors)
          return
        }
        setModel('')
        setModelModal({
          ...modelModal,
          show: false
        })
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      })
  }

  const deleteModel = (modelId) => {
    axios({
      method: 'DELETE',
      url: `${constants.API_URL}/vehicle-model/${modelId}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        setDeleteModal({
          ...deleteModal,
          show: false
        })
        console.log('res :: ', response.data)
        const data = response.data
        fetchData();
        notify('success', 'Success', data.message)
      })
      .catch(error => {
        console.error('error :: ', error)
        setDeleteModal({
          ...deleteModal,
          show: false
        })
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      })
  }
  useEffect(() => {
    const required = ['name', 'vehicle_make_id']
    setIsNotValid(!validateForm(required, model));
  }, [model])
  return (
    <>
      <CardsHeader name="Vehicles" parentName="Vehicle model Management" currentName="Create" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Vehicle models</h1>
                    <p className="text-sm mb-0">You can manage Vehicle models.</p>
                  </Col>
                  <Col xs={4} className="text-right">
                    <ButtonContainer onClick={() => {
                      setModel('')
                      setModelModal({
                        ...modelModal,
                        show: true,
                        header: 'Add model',
                        division: 'create'
                      })
                    }}>
                      <i className="fa fa-plus" aria-hidden="true"></i>
                      Add new
                    </ButtonContainer>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <TableCustom
                  loading={loading}
                  noOverflow
                  delay={delay}
                  items={models}
                  columns={[
                    {
                      dataField: "name",
                      text: "Name",
                      sort: true
                    },
                    {
                      dataField: "",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            {(hasPermission(permissions, 'access-vehicle-models') || hasPermission(permissions, 'delete-vehicle-models')) &&
                              <>
                                <IcoMoon
                                    className={'cursor-pointer'}
                                    iconSet={IconSet}
                                    icon={'edit'}
                                    size={24}
                                    onClick={() => {
                                      setModel(row)
                                      setModelModal({
                                        ...modelModal,
                                        show: true,
                                        header: 'Edit model',
                                        division: 'update'
                                      })
                                    }}
                                />
                                <IcoMoon
                                    className={'cursor-pointer'}
                                    iconSet={IconSet}
                                    icon={'delete_outline'}
                                    size={24}
                                    onClick={() => {
                                      setDeleteModal({
                                        show: true,
                                        header: 'Delete model',
                                        body: 'Are you sure you want to delete this model?',
                                        onClick: () => deleteModel(row.id)
                                      })
                                    }}
                                />
                              </>
                            }
                          </>
                        )
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={modelModal.show}
          toggle={() => setModelModal({
            ...modelModal,
            show: false,
          })}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title main-color">{modelModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setModelModal({
                ...modelModal,
                show: false,
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {model.media && model.imageUrl && model.media[0].file_name &&
              <Row>
                <div className="col-lg-12 order-lg-12 mb-2">
                  <div className="dealer-profile-image-edit-section">
                    <img alt={''} src={`${model.imageUrl}`} className="img-thumbnail" />
                  </div>
                </div>
              </Row>}
            <InputArraySelect
              newLabel={`Vehicle Make`}
              required
              value={model && model.vehicle_make_id ? model.vehicle_make_id : ''}
              options={makes}
              onChange={e => setModel({
                ...model,
                vehicle_make_id: e.target.value,
              })}
              valueKey={`id`}
              labelKey={`name`}
              defaultOption={true}
              invalid={errors && errors.vehicle_make_id ? true : false}
              errorMessage={errors.vehicle_make_id}
            />
            <InputArraySelect
              newLabel={`Vehicle Type`}
              value={model && model.vehicle_type ? model.vehicle_type : ''}
              onChange={e => setModel({
                ...model,
                vehicle_type: e.target.value,
              })}
              valueKey={`value`}
              labelKey={`name`}
              options={vehicleTypes}
              defaultOption={true}
              invalid={errors && errors.vehicle_type ? true : false}
              errorMessage={errors.vehicle_type}
            />
            <FormGroup>
              <div>
                <label className="form-control-label">Featured image</label>
              </div>
              <div className="custom-file">
                <input
                  className="custom-file-input profile-file-input"
                  type="file"
                  onChange={e => setModel({
                    ...model,
                    featured_image: e.target.files[0]
                  })}
                />
                <label
                  className="custom-file-label"
                  htmlFor="customFileLang"
                >
                  Featured image
                </label>
              </div>
            </FormGroup>
            <InputCustom
              newLabel={`Vehicle model`}
              required
              checkValid={false}
              value={model && model.name ? model.name : ''}
              onChange={e => setModel({
                ...model,
                name: e.target.value,
              })}
              invalid={errors && errors.name ? true : false}
              errorMessage={errors.name}
            />
            <InputCustom
              newLabel={`Service cost`}
              value={model && model.service_cost ? model.service_cost : ''}
              onChange={e => setModel({
                ...model,
                service_cost: e.target.value,
              })}
              invalid={errors && errors.service_cost ? true : false}
              errorMessage={errors.service_cost}
            />
            <InputCustom
              newLabel={`Tyre price`}
              value={model && model.tire_price ? model.tire_price : ''}
              onChange={e => setModel({
                ...model,
                tire_price: e.target.value,
              })}
              invalid={errors && errors.tire_price ? true : false}
              errorMessage={errors.tire_price}
            />
            <InputCustom
              newLabel={`Ply lining`}
              value={model && model.ply_lining_price ? model.ply_lining_price : ''}
              onChange={e => setModel({
                ...model,
                ply_lining_price: e.target.value,
              })}
              invalid={errors && errors.ply_lining_price ? true : false}
              errorMessage={errors.ply_lining_price}
            />
            <InputCustom
              newLabel={`Wind screen`}
              value={model && model.wind_screen_price ? model.wind_screen_price : ''}
              onChange={e => setModel({
                ...model,
                wind_screen_price: e.target.value,
              })}
              invalid={errors && errors.wind_screen_price ? true : false}
              errorMessage={errors.wind_screen_price}
            />

          </div>
          <div className="modal-footer justify-content-end">
            <ButtonContainer
              onClick={() => setModelModal({
                ...modelModal,
                show: false,
              })}
            >
              {`Close`}
            </ButtonContainer>
            <ButtonContainer
              disabled={isNotValid}
              onClick={() => (modelModal.division === 'create' ? addModel() : updateModel())}
            >
              {`Save`}
            </ButtonContainer>
          </div>
        </Modal>
        <Modal
          isOpen={deleteModal.show}
          toggle={() => setDeleteModal({
            show: false
          })}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title main-color">{deleteModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setDeleteModal({
                show: false
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {deleteModal.body}
          </div>
          <div className="modal-footer justify-content-end">
            <ButtonContainer
              onClick={() => setDeleteModal({
                show: false
              })}
            >
              {`Close`}
            </ButtonContainer>
            <ButtonContainer
              reverse
              onClick={deleteModal.onClick}
            >
              {`Delete`}
            </ButtonContainer>
          </div>
        </Modal>
      </Container>
    </>
  )
}