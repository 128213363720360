import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import NotificationAlert from 'react-notification-alert'
import axios from 'axios'
import Cookies from 'js-cookie'
import { Editor } from '@tinymce/tinymce-react';

import * as constants from 'constants/index.js'
import CardsHeader from 'layouts/Headers/CardsHeader.js'

import InputCustom from 'views/pages/components/InputCustom'
import InputFile from 'views/pages/components/InputFile'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
} from 'reactstrap'

export default function NewsForm() {
  const { id } = useParams()
  const notificationAlert = useRef(null)
  const history = useHistory()

  const [token, setToken] = useState('')
  const [item, setItem] = useState('')

  const [errors, setErrors] = useState('')

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    }
    notificationAlert.current.notificationAlert(options)
  }

  useEffect(() => {
  if (id) {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: id ? `${constants.API_URL}/news/${id}/edit` : `${constants.API_URL}/news/create`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data
        setItem({
          ...data.data.item,
          release_date: data.data.item && data.data.item.release_date? constants.formatDate(data.data.item.release_date): '',
          is_active: data.data.item && [0, 1].includes(data.data.item.is_active)? data.data.item.is_active: 0,
        })
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }
  }, [id])

  const goList = () => {
    history.push({
      pathname: `/admin/news`
    })
  }

  const saveNews = async () => {
    const formData = new FormData();
    
    Object.keys(item).forEach(key => {
      if (!['featured', 'media', 'old_id', 'created_at', 'updated_at', 'uuid', 'id'].includes(key)) {
        formData.append(key, item[key])
      }
    })

    if (id) {
      formData.append('_method', 'PUT')
    }

    try {
      const response = await axios({
        method: 'POST',
        url: id ? `${constants.API_URL}/news/${item.uuid}` : `${constants.API_URL}/news`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: formData,
      })
      console.log('response :: ', response.data)
      const data = response.data
      notify('success', 'Success', data.message)
      setItem({
        ...data.data.item,
        release_date: data.data.item && data.data.item.release_date? constants.formatDate(data.data.item.release_date): '',
      })
      setErrors('')
      if (!id) {
        setTimeout(() => {
          history.push({
            pathname: `/admin/news/${data.data.item.uuid}/edit`
          })
        }, 1000);
      }
    } catch (error) {
      console.error('error :: ', error)
      if (error.response && error.response.status === 422) {
        setErrors(error.response.data.errors)
        return
      }
      notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
    }
  }

  return (
    <>
      <CardsHeader name="News" parentName="News Management" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
				        <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">News Management</h1>
                  </Col>
                  <Col xs={4} className="text-right">
                    <Button className="w-100" color="success" type="button" onClick={() => goList()}>Back to list</Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={12}>
                    {item && item.featured && 
                    <img alt={''} src={`${constants.STORAGE_URL}${item.featured.disk}/${item.featured.id}/${item.featured.file_name}`} className="img-thumbnail" />}
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <h2 className="my-4">{`NEWS DETAILS`}</h2>
                    <Row>
                      <Col md={6} sm={12}>
                        <InputCustom 
                          label={`Title`}
                          value={item && item.title? item.title: ''}
                          onChange={e => setItem({
                            ...item,
                            title: e.target.value
                          })}
                          invalid={errors && errors.title? true: false}
                          errorMessage={errors.title}
                        />
                        <InputFile 
                          label={`Featured image`}
                          placeholder={`Featured image`}
                          onChange={e => setItem({
                            ...item,
                            featured_image: e.target.files[0]
                          })}
                          invalid={errors && errors.featured_image? true: false}
                          errorMessage={errors.featured_image}
                        />
                        <InputCustom 
                          type={`date`}
                          label={`Release Date`}
                          value={item && item.release_date? item.release_date: ''}
                          onChange={e => setItem({
                            ...item,
                            release_date: e.target.value
                          })}
                          invalid={errors && errors.release_date? true: false}
                          errorMessage={errors.release_date}
                        />
                        <FormGroup>
                          <label
                            className="form-control-label"
                          >
                            {`Status`}
                          </label>
                          <div className="custom-control custom-radio mb-2">
                            <input
                              className="custom-control-input"
                              name="customRadioInline1"
                              type="radio"
                              checked={item && item.is_active === 0? true: false}
                              onChange={() => setItem({
                                ...item,
                                is_active: 0
                              })}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customRadioInline1"
                              onClick={() => setItem({
                                ...item,
                                is_active: 0
                              })}
                            >
                              Inactive
                            </label>
                          </div>
                          <div className="custom-control custom-radio">
                            <input
                              className="custom-control-input"
                              name="customRadioInline1"
                              type="radio"
                              checked={item && item.is_active === 1? true: false}
                              onChange={() => setItem({
                                ...item,
                                is_active: 1
                              })}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customRadioInline2"
                              onClick={() => setItem({
                                ...item,
                                is_active: 1
                              })}
                            >
                              Active
                            </label>
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="example3cols2Input"
                          >
                            Description
                          </label>
                          <Editor
                            value={item && item.text? item.text: ''}
                            apiKey={constants.TINYMCE_KEY}
                            init={{
                              height: 500,
                              menubar: true,
                              plugins: ['code', 'preview', 'pagebreak', 'image'],
                              toolbar:
                                'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent'
                            }}
                            onEditorChange={(content, editor) => setItem({
                              ...item,
                              text: content
                            })}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <h2 className="my-4">{`SEARCH ENGINE OPTIMIZATION`}</h2>
                    <Row>
                      <Col md={6} sm={12}>
                        <InputCustom 
                          label={`Page Title`}
                          value={item && item.page_title? item.page_title: ''}
                          onChange={e => setItem({
                            ...item,
                            page_title: e.target.value
                          })}
                          invalid={errors && errors.page_title? true: false}
                          errorMessage={errors.page_title}
                        />
                        <InputCustom 
                          type={`textarea`}
                          rows={8}
                          label={`Meta Description`}
                          value={item && item.meta_description? item.meta_description: ''}
                          onChange={e => setItem({
                            ...item,
                            meta_description: e.target.value
                          })}
                          invalid={errors && errors.meta_description? true: false}
                          errorMessage={errors.meta_description}
                        />
                        <Button className="w-100" color="success" type="button" onClick={() => saveNews()}>Save</Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}