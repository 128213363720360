import React, {useMemo} from 'react'
import Pagination from 'react-js-pagination';
import styled from "styled-components";

import {
    Row,
    Col,
     Button, Input,
} from 'reactstrap'


import {
    checkAndFormatShortGb,
    formatDateShortGB,
    parseToWord,
} from "../../../../constants";
import SortingArrow from "@pages/components/icons/SortingArrow";

import DatePicker from 'react-datepicker';
import moment from "moment";

const CustomTd = styled.td`
 color: ${props => props.color} !important;
 text-align: center;
 background-color: ${props => props.backgroundColor} !important;
 font-size: 14px !important;
 font-weight:  ${props => props.weight ? props.weight : 600} !important;
`

export default function CustomTable({
                                        keys,
                                        tableData,
                                        pagination = null,
                                        noOverflow = false,
                                        dynamicTitle = '',
                                        disabledReason = '',
                                        customClassName = '',
                                        count = null,
                                        tableActions = [],
                                        paginate,
                                        showImage,
                                        complete,
                                        canWorkOn = true,
                                        openModal,
                                        toDetails,
                                        payAction,
                                        sendToCourtAction,
                                        sendToCCJAction,
                                        sort,
                                        sortValue = null,
                                        sortObject = {},
                                        openMailModal,
                                        openExternal,
                                        deleteRow,
                                        deleteAll,
                                        isPaymentsPage=false,
                                        removeAll=false,
                                        multicolor=false,
                                        customerDetails,
                                        selectDate,
                                        downloadInvoice,
                                        downloadFile,
                                        selectedAction,
                                        selectAction,
                                        changeGroupStatus,
                                    }) {

    const generateCustomItem = (value, key, id, keyIndex, itemIndex,keyLabel,className= null,customer_id= null, count = null,expectedCount = null,disabled = false,slug = null) => {
        if (key === 'status') {
            value = parseToWord(value?.toString())
            if (value?.toLowerCase() === 'in progress' || value?.toLowerCase() === 'to do') {
                return <CustomTd   key={key + '_' + itemIndex + '_' + keyIndex} className={'cursor-pointer'} onClick={() => {
                    if (canWorkOn) {
                        complete(id)
                    }
                }}
                                   tabIndex="0" backgroundColor="#09cd8b" >
                    <h2>
                        Complete
                    </h2>
                </CustomTd>
            } else if (value?.toLowerCase() === 'completed') {
                return <CustomTd   key={key + '_' + itemIndex + '_' + keyIndex}  classname={'cursor-pointer'} >
                    <h2>
                        already completed
                    </h2>
                </CustomTd>
            }
        }
        else if (key === 'action_select') {
            return (
                <td key={itemIndex+'_columnIndex_'+id}>
                    <div className={'text-center'}>
                        <Input
                            type="select"
                            onChange={(e)=>selectAction({id: id,value: e.target.value})}
                            value={selectedAction[id] || 'Select box'}
                        >
                            <option>-</option>
                            {tableActions &&
                            tableActions.map((item) => {
                                return <option value={item.value} key={item.value}>{item.text}</option>
                            })
                            }
                        </Input>
                        <span className={'table-button ' + (selectedAction[id] ? 'badge-success' : 'badge-secondary')} onClick={()=>selectedAction[id] && changeGroupStatus(id)} >Save</span>
                    </div>
                </td>
            )
        }
        else if (key === 'documents') {

            let images = <div style={{ display: "flex", flexWrap: 'wrap', gap: 10 }}>
                {
                    value && value.length ? value.map((item, idx) => {
                        return <img className={'cursor-pointer'} onClick={() => { showImage({ index: itemIndex, image: idx }) }} key={idx} src={item.url} width={80} height={80} alt="" />
                    }) : ''
                }
            </div>

            return <CustomTd  key={key + '_' + itemIndex + '_' + keyIndex}  tabIndex="0" color="grey">{images}</CustomTd>
        } else if (key === 'title') {
            return <CustomTd key={key + '_' + itemIndex + '_' + keyIndex} weight={700}  tabIndex="0">{value}</CustomTd>
        } else if (key === 'expected_payment_date' || key === 'ccj_approvement_date' || key === 'date_sent_to_bailiffs') {
            return <th key={key + '_' + itemIndex + '_' + keyIndex} tabIndex="0">
                <DatePicker
                    dateFormat="YYYY/MM/DD"
                    value={value ? checkAndFormatShortGb(value) : ''}
                    onChange={(date) => {
                        selectDate({id: id, value: moment(date).format('YYYY-MM-DD'), key: key})
                    }}
                />
            </th>
        } else if (isPaymentsPage && key === 'openModal') {
            return <th key={key + '_' + itemIndex + '_' + keyIndex} tabIndex="0"> <span className={`table-button white`} onClick={()=>openModal(itemIndex)}>View notes</span></th>
        } else if (isPaymentsPage && key === 'action') {
            return <th key={key + '_' + itemIndex + '_' + keyIndex} tabIndex="0"> <span className={`table-button ${className}`} onClick={()=>toDetails({id: id,status: keyLabel})}>{parseToWord(keyLabel)}</span></th>
        }
        else if (isPaymentsPage && key === 'payAction') {
            return <th key={key + '_' + itemIndex + '_' + keyIndex} tabIndex="0"> <span className={`table-button ${className}`} onClick={()=>payAction({id: id})}>{parseToWord(keyLabel)}</span></th>
        } else if (key === 'partials_amount') {
            return <th key={key + '_' + itemIndex + '_' + keyIndex} tabIndex="0">{value[0]?.all_partials_amount}</th>
        } else if (isPaymentsPage && key === 'sendToCourtAction') {
            return <th key={key + '_' + itemIndex + '_' + keyIndex} tabIndex="0"> <span className={`table-button ${className}`} onClick={()=>sendToCourtAction({id: id})}>{parseToWord(keyLabel)}</span></th>
        } else if (isPaymentsPage && key === 'sendToCCJAction') {
            return <th key={key + '_' + itemIndex + '_' + keyIndex} tabIndex="0"> <span className={`table-button ${className}`} onClick={()=>sendToCCJAction({id: id})}>{parseToWord(keyLabel)}</span></th>
        } else if (isPaymentsPage && key === 'openMailModal') {
            return <th key={key + '_' + itemIndex + '_' + keyIndex} tabIndex="0"> <span className={`table-button ${className}`} onClick={()=>openMailModal({label: slug,index: itemIndex})}>Send Mail</span></th>
        }  else if (isPaymentsPage && key === 'openExternal') {
            return <th key={key + '_' + itemIndex + '_' + keyIndex} tabIndex="0"> <span className={`table-button ${className}`} onClick={()=>openExternal({id: id,index: itemIndex})}>{parseToWord(keyLabel)}</span></th>
        }
        else if(key === 'reg_no') {
            return <th key={key + '_' + itemIndex + '_' + keyIndex} tabIndex="0">{value}</th>
        }  else if (key === 'delete') {
            return <th key={key + '_' + itemIndex + '_' + keyIndex}>
                <div className={'d-flex g-5'}>
                    <span className={`table-button ${className}`} onClick={() => deleteRow(id)}>Delete</span>
                    {removeAll && <span className={`table-button ${className}`} onClick={() => deleteAll(itemIndex)}>Delete All</span>
                    }
                </div>
            </th>
        } else if (key === 'customer_name') {
            if (customer_id) {
                return <th key={key + '_' + itemIndex + '_' + keyIndex} onClick={()=>customerDetails(customer_id)} tabIndex="0">
                    <a href="">{value}</a> {count && count[customer_id] ? <span>{count[customer_id]} ({expectedCount})</span> : null}</th>
            } else return <th key={key + '_' + itemIndex + '_' + keyIndex} tabIndex="0">{value}</th>
        }  else if (key === 'invoice_number') {
            return <th key={key + '_' + itemIndex + '_' + keyIndex} onClick={()=>downloadInvoice(itemIndex)} className={'cursor-pointer'} tabIndex="0">
                {value}</th>
        } else if (key === 'due_date' || key === 'immobilised_date' || key === 'arrange_repo_date' || key === 'repo_date') {
            value = parseToWord(value?.toString())
            return <th key={key + '_' + itemIndex + '_' + keyIndex} tabIndex="0">{checkAndFormatShortGb(value)}</th>
        } else if (key === 'dynamicTitle') {
            return <th className={'text-center'}>{dynamicTitle}: {itemIndex + 1}</th>
        } else if (key === 'download') {
            return <th key={key + '_action_' + id} className={'cursor-pointer'}>
                <Button block color="success"
                        type="button"
                        disabled={disabled}
                        onClick={() => downloadFile(id)}>Download</Button>
            </th>
        } else if (key === 'number') {
            return <th  key={key + '_' + itemIndex + '__'}>{itemIndex + 1}</th>
        } else if (key === 'vehicle') {

            return  <th key={key + '_' + itemIndex + '_' + keyIndex} tabIndex="0">{value?.stock_no || '-'}</th>
        }

        value = parseToWord(value?.toString())
        return <th key={key + '_' + itemIndex + '_' + keyIndex} tabIndex="0">{value}</th>
    }

    const columns = useMemo(() => {
        return  tableData && tableData.length > 0 ?
            tableData.map((device, index) => {
                return <tr key={`${index}_device_${device?.vehicle_make}`}  className={`${multicolor && 'multicolor'} ${index % 2 === 0 ? 'even' : 'odd'}`}>
                    {
                        keys.map((item, keyIndex) => {
                            return <React.Fragment key={index + '^' + keyIndex}>
                                {generateCustomItem(device[item.key], item.key, device?.id, keyIndex, index,item.label,item?.className, device?.customer_id,count,device['number_of_payments'],!device[disabledReason],item?.slug)}
                            </React.Fragment>
                        })
                    }

                </tr>
            }) : <tr>
                <td colSpan={keys?.length}><h3>No Data</h3></td>
            </tr>
    }, [tableData,canWorkOn,count,selectedAction]);
    return (
        <>
            <div>
                <div className={"table-responsive " + (noOverflow ? " overflow-y-hidden" : '')} style={{ maxHeight: noOverflow ? '' : 'calc(100vh - 250px)' }}>
                    <table className="table table-bordered">
                        <thead>
                        <tr className={customClassName ? customClassName : 'reports-table-header'}>
                            {
                                keys.map((column, index) => {
                                    return  <th key={index + 'X'} className={'header-text'}  tabIndex="0">{column?.label}
                                        {column?.sort ?
                                            <>
                                                &nbsp;
                                                <SortingArrow
                                                    order={sortValue ? sortValue : sortObject.key === column.key ? sortObject.value : null}
                                                    setOrder={(value)=>{
                                                        sort({key:column.key,value: value})
                                                    }}
                                                />
                                            </>
                                            : <></>}
                                    </th>
                                })

                            }
                        </tr>
                        </thead>
                        <tbody>
                        {
                            columns
                        }
                        </tbody>
                    </table>
                </div>
            </div>

            {pagination && tableData.length > 0 &&
            <Row className="react-bootstrap-table-pagination mt-3">
                <Col lg={6}>
                    <p className="text-sm text-black">
                        {`Showing rows ${(pagination.current_page - 1) * pagination.per_page + 1} to
                                         ${pagination.per_page * pagination.current_page > pagination.total ? pagination.total : pagination.per_page * pagination.current_page} of ${pagination.total}`}
                    </p>
                </Col>
                <Col lg={6} className="react-bootstrap-table-pagination-list">
                    <Pagination
                        hideDisabled
                        activePage={pagination.current_page}
                        totalItemsCount={pagination.total}
                        itemsCountPerPage={pagination.per_page}
                        onChange={paginate}
                        itemClass="page-item"
                        linkClass="page-link"
                        innerClass="pagination react-bootstrap-table-page-btns-ul"
                    />
                </Col>
            </Row>}
        </>
    )
}