import React, {useEffect, useState, useRef} from 'react'
import {
    Button,
    Container,
    Input,
} from 'reactstrap'
import Select from "react-select";
import VehicleInfo from "../../../vehicles/VehicleInfo";
import {
    Text,
    TaskDetailsContainer,
    TextContainer,
    ImagesContainer,
    ImageContainer,
    CloseIcon
} from '@pages/reusable-components/styled-components'

export default function CreateNewTask({
                                          isDisabled,
                                          departments,
                                          setData,
                                          vehicle,
                                          saveData,
                                          setShowCreateNew,
                                      }) {

    const inputRef = useRef(null);
    const [imgSrc, setImgSrc] = useState([]);
    const [department, setDepartment] = useState({});
    const [sendData, setSendData] = useState({
        title: '',
        description: '',
        department: '',
        images: []
    });


    const returnFirstLetter = (word) => {
        return word?.charAt(0).toUpperCase();
    }

    const styles = {
        valueContainer: base => ({
            ...base,
            paddingLeft: 24
        })
    }
    const onChangeSelect = (event) => {
        let tempFiles = [];
        let tempImgSrc = [];
        const files = event.target.files;

        for (let i = 0; i < files.length; i++) {
            tempFiles.push(files[i]);
            let reader = new FileReader();

            reader.onloadend = (e) => {
                tempImgSrc.push(reader.result);

                if (tempImgSrc.length === files.length) {
                    setImgSrc((arr) => [...arr, ...tempImgSrc]);
                    setSendData((prevData) => ({ ...prevData, images: tempFiles }));
                }
            };

            reader.readAsDataURL(files[i]);
        }
    };
    const removeImage = (index) => {

        let tempImages = sendData.images
        tempImages.splice(index, 1)
        setSendData({...sendData, images: tempImages})

        let tempResult = [...imgSrc]
        tempResult.splice(index, 1)
        setImgSrc(tempResult);
    }

    useEffect(() => {
        setData(sendData)
    }, [sendData])
    return (
        <>
            <Container className='pd-20 h-100vh'>
                <VehicleInfo vehicle={vehicle} showStockNo={true}/>
                <TaskDetailsContainer>
                    <img height='25px' width='25px' src={require(`assets/images/taskEdit/edit.png`)}
                         alt=""/>
                    <TextContainer width='400px'>
                        <Text bold>Task Title:</Text>
                        <Input
                            id="exampleText"
                            onChange={(e) => setSendData({...sendData, title: e.target.value})}
                            name="text"
                            placeholder='Title'
                        />
                    </TextContainer>
                </TaskDetailsContainer>
                <TaskDetailsContainer>
                    <img height='25px' width='25px' src={require(`assets/images/taskEdit/edit.png`)}
                         alt=""/>
                    <TextContainer width='400px'>
                        <Text bold>Description:</Text>
                        <Input
                            onChange={(e) => setSendData({...sendData, description: e.target.value})}
                            id="exampleText"
                            type='textarea'
                            name="text"
                            placeholder='Add a description...'
                        />
                    </TextContainer>
                </TaskDetailsContainer>
                <div className='d-flex flex-wrap'>

                    <TaskDetailsContainer>
                        <img height='25px' width='25px' src={require(`assets/images/loyalty.png`)}
                             alt=""/>
                        <TextContainer width='220px'>
                            <Text bold>Department:</Text>
                            <Select
                                value={department}
                                onChange={value => {
                                    setDepartment(value)
                                    setSendData({...sendData, department: value})
                                }}
                                options={departments}
                                getOptionLabel={option => option.text}
                                getOptionValue={option => option.value}
                                styles={styles}
                                placeholder='Select Department'
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                        </TextContainer>
                    </TaskDetailsContainer>
                    {/*<TaskDetailsContainer>*/}
                    {/*    <img height='25px' width='25px' src={require(`assets/images/taskEdit/person.png`)}*/}
                    {/*         alt=""/>*/}
                    {/*    <TextContainer width='220px'>*/}
                    {/*        <Text bold>Assignees:</Text>*/}
                    {/*        /!*<Text>{taskDetails.description}</Text>*!/*/}
                    {/*        <Select*/}
                    {/*            isMulti*/}
                    {/*            onChange={selectAssignees}*/}
                    {/*            selected={sendData.assignees}*/}
                    {/*            options={assignees}*/}
                    {/*            getOptionLabel={option => option.name}*/}
                    {/*            getOptionValue={option => option.id}*/}
                    {/*            placeholder='Select Workers'*/}
                    {/*            components={{ValueContainer}}*/}
                    {/*            styles={styles}*/}
                    {/*            className="basic-multi-select"*/}
                    {/*            classNamePrefix="select"*/}
                    {/*        />*/}
                    {/*    </TextContainer>*/}
                    {/*</TaskDetailsContainer>*/}
                    {/*<TaskDetailsContainer>*/}
                    {/*    <img height='25px' width='25px' src={require(`assets/images/taskEdit/priority.png`)}*/}
                    {/*         alt=""/>*/}
                    {/*    <TextContainer width='220px'>*/}
                    {/*        <Text bold>Priority:</Text>*/}
                    {/*        <Select*/}
                    {/*            options={priorities}*/}
                    {/*            placeholder='Select Priority'*/}
                    {/*            getOptionLabel={option => option.label}*/}
                    {/*            getOptionValue={option => option.label}*/}
                    {/*            onChange={(value) => {*/}
                    {/*                setSendData({...sendData, priority: value.label})*/}
                    {/*            }}*/}
                    {/*            defaultValue={priority}*/}
                    {/*            styles={styles}*/}
                    {/*            className="basic-multi-select"*/}
                    {/*            classNamePrefix="select"*/}
                    {/*        />*/}
                    {/*    </TextContainer>*/}
                    {/*</TaskDetailsContainer>*/}
                </div>
                <div className='d-flex'>
                    <TaskDetailsContainer>
                        <img height='25px' width='25px' src={require(`assets/images/taskEdit/attachment.png`)}
                             alt=""/>
                        <TextContainer>
                            <Text bold>Image:</Text>


                            <input style={{display: 'none'}}
                                   onChange={(e) => onChangeSelect(e)} ref={inputRef}
                                   type="file"
                                   name="myImage"
                                   multiple="multiple"
                                   accept="image/png, image/gif, image/jpeg"/>
                        </TextContainer>
                    </TaskDetailsContainer>
                    <TaskDetailsContainer pointer onClick={() => inputRef.current.click()}>
                        <img height='25px' width='25px' src={require(`assets/images/taskEdit/upload.png`)}
                             alt=""/>
                        <Text color='#ec6409'>Upload
                        </Text>

                    </TaskDetailsContainer>
                </div>
                {imgSrc.length ?
                    <ImagesContainer>
                        {
                            imgSrc.map((item, index) => {
                                return (<ImageContainer><img height='80px' width='136px' src={item} alt=""/>
                                    <CloseIcon onClick={() => removeImage(index)} height='15px' width='15px'
                                            //    src={require(`assets/images/xmark-solid.svg`)}
                                               alt=""/>
                                </ImageContainer>)
                            })
                        }
                    </ImagesContainer> : <></>
                }
                <Button outline={true} style={{color: '#ec6409', border: '1px solid #ec6409', float: 'right'}}
                        type="button" onClick={() => setShowCreateNew(false)}>Cancel</Button>
                <Button outline={true} style={{
                    backgroundColor: '#ec6409',
                    color: 'white',
                    border: '1px solid #ec6409',
                    float: 'right',
                    marginRight: '15px'
                }} disabled={isDisabled} onClick={() => saveData(vehicle.id)}>Save</Button>
            </Container>
        </>
    )
}