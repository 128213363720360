import React from 'react';

export const  Notification = (type, header, message,ref) => {
    let options = {
        place: "tc",
        message: (
            <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
              {header}
          </span>
                <span data-notify="message">
            {message}
          </span>
            </div>
        ),
        type: type,
        icon: "ni ni-bell-55",
        autoDismiss: 5
    };
    ref.current.notificationAlert(options);
};