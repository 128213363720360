import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';


import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Button,
} from 'reactstrap';

export default function FinancialHistory() {
  const { id } = useParams();
  const notificationAlert = useRef(null);
  const history = useHistory();
  
  const [vehicle, setVehicle] = useState('');


  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
     if (id) {
       const grs_token = Cookies.get('grs_token')

       const source = axios.CancelToken.source()

       const fetchData = async () => {
         try {
           const response = await axios({
             method: 'GET',
             url: `${constants.API_URL}/vehicle/${id}/financial-history`,
             headers: {
               Authorization: `Bearer ${grs_token}`,
             },
             cancelToken: source.token,
           })
           console.log('response :: ', response.data)
           const data = response.data

           setVehicle(data.data.vehicle)

         } catch (error) {
           if (axios.isCancel(error)) {
             console.log('Request canceled :: ', error)
           } else {
             console.error('error :: ', error)
             notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
           }
         }
       }

       fetchData()

       return () => {
         source.cancel()
       }
     }
  }, [id])
  
  const goList = () => {
    history.push({
      pathname: `/admin/vehicle/${id}/edit`
    })
  }



  return (
    <>
      <CardsHeader name="Vehicles" parentName={`Vehicle - ${vehicle.vehicle_make && vehicle.vehicle_make.name}, ${vehicle.vehicle_model && vehicle.vehicle_model.name}, ${vehicle.stock_no}`} />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="title">Financial History</h1>
                  </Col>
                  <Col xs={2} className="text-right">
                    <Button className="w-100" color="success" style={{margin: 5}} onClick={() => goList()}>Back to vehicle</Button>
                  </Col>
                </Row>
              </CardHeader>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}