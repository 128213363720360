import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import Select2 from "react-select2-wrapper";
import axios from 'axios';
import Cookies from "js-cookie";

import actions from 'actions';
import * as constants from 'constants/index.js';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Input,
} from "reactstrap";

import CardsHeader from 'layouts/Headers/CardsHeader.js';

import {formatDate} from "constants/index.js";

export default function TaskEdit() {
  const { customerId, taskId } = useParams();
  const location = useLocation();
  const parentPath = {
    pathname: '/admin/customer/' + customerId + '/edit',
    state: {
      filter: location.state && location.state.filter? location.state.filter: ''
    }
  };
  const history = useHistory();
  const notificationAlert = useRef(null);
  const dispatch = useDispatch();

  const [token, setToken] = useState('');
  const [dealerMembers, setDealerMembers] = useState([]);
  const [statuses, setStatuses] = useState([]);

  const [task, setTask] = useState('');
  const [errors, setErrors] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/customer/${customerId}/tasks/${taskId}/edit`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data
        const item = data.data.item;
        const newAssignee = [];
        if (item.task_assignee) {
          item.task_assignee.forEach(assignee => {
            newAssignee.push(assignee.id);
          })
          item.task_assignee = newAssignee;
        }
        setTimeout(() => {
          setTask({
            ...item,
            due_date: item.due_date? formatDate(item.due_date) : '',
          });
        }, 10);

        let members = data.data.dealerMembers;
        members.forEach(member => {
          member.text = member.name;
          delete member.name;
        });
        setDealerMembers(members);
        setStatuses(data.data.statuses);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const updateTask = () => {
    axios({
      method: 'PUT',
      url: constants.API_URL + '/customer/tasks/' + taskId,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        ...task,
        due_date: formatDate(task.due_date)
      },
    })
      .then(response => {
        setErrors('');
        if (response.status === 200) {
          notify('success', 'Success', response.data.message);
          dispatch(actions.getNotifications(token));
          setTimeout(() => {
            history.push({
              pathname: '/admin/customer/' + customerId + '/tasks',
              state: {
                filter: location.state && location.state.filter? location.state.filter: ''
              }
            })
          }, 1500);
        }
      })
      .catch(error => {
        setErrors('');
        console.error('error :: ', error);
        if (error.response && error.response.status === 422) {
          let errors = error.response.data.errors;
          setErrors(errors);
        }
        notify('warning', 'Warning', error.response.statusText);
      })
  }

  const handleAssigneeChange = (e) => {
    let value = Array.from(e.target.selectedOptions, option => option.value);
    setTask({
      ...task,
      task_assignee: value
    })
  }

  const goList = () => {
    history.push({
      pathname: '/admin/customer/' + customerId + '/tasks',
      state: {
        filter: location.state && location.state.filter? location.state.filter: ''
      }
    });
  }



  return (
    <>
      <CardsHeader name="Customers" parentName="Customer management" currentName="Task Edit" parentPath={parentPath} />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Task Management</h1>
                  </Col>
                  <Col xs={4} className="text-right">
                    <Button className="w-100" color="success" type="button" onClick={() => goList()}>Back to list</Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <FormGroup>
                  <label className="form-control-label">Title</label>
                  <Input
                    placeholder="Title"
                    type="text"
                    value={task && task.title ? task.title : ''}
                    onChange={(e) => setTask({
                      ...task,
                      title: e.target.value,
                    })}
                    invalid={errors && errors.title ? true : false}
                  />
                  <div className="invalid-feedback">
                    {errors.title}
                  </div>
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label">Description</label>
                  <Input
                    placeholder="Description"
                    type="textarea"
                    rows="8"
                    value={task ? task.comments : ''}
                    onChange={(e) => setTask({
                      ...task,
                      comments: e.target.value,
                    })}
                    invalid={errors && errors.comments ? true : false}
                  />
                  <div className="invalid-feedback">
                    {errors.comments}
                  </div>
                </FormGroup>
                <Row>
                  <Col md={6} sm={12}>
                    <FormGroup>
                      <label className="form-control-label">Due</label>
                      <Input
                        type="date"
                        value={task && task.due_date? task.due_date: ''}
                        onChange={(e) => setTask({
                          ...task,
                          due_date: e.target.value,
                        })}
                        invalid={errors && errors.due_date ? true : false}
                      />
                      <div className="invalid-feedback">
                        {errors.due_date}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md={6} sm={12}>
                    <FormGroup>
                      <label className="form-control-label">Status</label>
                      <Input
                        type="select"
                        value={task? task.status: ''}
                        onChange={(e) => setTask({
                          ...task,
                          status: e.target.value,
                        })}
                        invalid={errors && errors.status ? true : false}
                      >
                        <option>-</option>
                        {Object.keys(statuses).map((key, index) => {
                          return <option value={key} key={key}>{statuses[key]}</option>
                        })}
                      </Input>
                      <div className="invalid-feedback">
                        {errors.status}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={12}>
                    <FormGroup>
                      <label className="form-control-label">Assign to</label>
                      <Select2
                        className="form-control"
                        multiple
                        data={dealerMembers}
                        defaultValue={task? task.task_assignee : null}
                        value={task? task.task_assignee : null}
                        onChange={(e) => handleAssigneeChange(e)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <div className="d-flex flex-row justify-content-center">
                  <Button className="w-100" color="success" onClick={() => updateTask()}>Save</Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}