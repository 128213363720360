import React, { useEffect, useState, useRef } from 'react';
import * as constants from 'constants/index.js';
import NotificationAlert from "react-notification-alert";
import { InputArraySelect } from 'views/pages/components/InputSelect';
import { getXeroConfiguration, updateXeroSalesTypeMapping } from 'api/dealer_modules.api';
import TableCustom from 'views/pages/components/TableCustom'
import {
  Container,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
} from "reactstrap";

import CardsHeader from 'layouts/Headers/CardsHeader.js';
import {ButtonContainer} from "@pages/reusable-components/styled-components";

const XeroModule = () => {
  const notificationAlert = useRef(null);

  const [isActive, setIsActive] = useState(false);
  const [connectionMessage, setConnectionMessage] = useState(null);
  const [integrationToken, setIntegrationToken] = useState(false);
  const [products, setProducts] = useState([]);
  const [localDefinedPayments, setLocalDefinedPayments] = useState([]);
  const [loading, setLoading] = useState(true)
  const [mapping, setMapping] = useState({});


  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  const pullAsyncData = () => {
    getXeroConfiguration().then(response => {
      let data = response.data;
      setIsActive(data.modules?.is_active)
      setProducts(data.modules?.products ?? []);
      setConnectionMessage(data.modules?.status.message);
      setIntegrationToken(data.modules?.status.renewal_token);

      if (data.modules?.mappedProducts) {
        let object = {};
        Object.keys(data.modules.mappedProducts).forEach(key => {
          object[key] = data.modules.mappedProducts[key].xero_value;
        })

        setMapping(object)
      }

      let paymentTypes = data.modules?.localDefinedPayments ?? {};
      setLocalDefinedPayments(Object.keys(paymentTypes).map(key => {
        return {
          key,
          value: paymentTypes[key]
        }
      }));

      setTimeout(() => {
        setLoading(false)
      }, 300);
    })
      .catch(error => {
      })
  }

  useEffect(() => {
    pullAsyncData()
  }, [])


  const saveUserInformation = async () => {
    document.location.href = `${constants.BASE_URL}/xero/authorization?token=${integrationToken}`;
  }

  const updateProductConfigurations = async () => {
    updateXeroSalesTypeMapping(mapping).then(response => {
      console.log(response)
      if (response.status === 201) {
        let data = response.data;
        console.log('response data :: ', data);
        notify('success', 'Success', data.message);
      }
    })
      .catch(error => {
        console.log(error.response)
        notify('warning', 'Warning', error.response.statusText);
      })
  }

  return (
    <>
      <CardsHeader name="Admin" parentName="Xero Configuration" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--6 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <h1 className="title mb-0">Xero</h1>
              </CardHeader>
              <CardBody>
                {!isActive ?
                  <Form>
                    <h2 className="surtitle mb-4">Integration steps</h2>
                    {connectionMessage && <h3 className='text-alert'>{connectionMessage}</h3>}
                    <ButtonContainer reverse onClick={() => saveUserInformation()}>
                      Authorize
                    </ButtonContainer>
                  </Form> :
                  <>
                    <h2 className="surtitle mb-4">Check out your configuration mapping</h2>
                    {
                      localDefinedPayments && localDefinedPayments.length > 0 ?
                        <TableCustom
                          loading={loading}
                          items={localDefinedPayments}
                          columns={[
                            {
                              dataField: "value",
                              text: "Name",
                              sort: true,
                              formatter: (rowContent, row) => {
                                return (
                                  <Row className="justify-content-center align-items-stretch">
                                    <Col md={12}>
                                      {row.value}
                                    </Col>
                                  </Row>
                                );
                              },
                            },
                            {
                              dataField: "is_notification_enabled",
                              text: "Map Sales Account",
                              sort: false,
                              formatter: (rowContent, row) => {
                                return (
                                  <>
                                    <InputArraySelect
                                      options={products}
                                      value={mapping && mapping[row.key] ? mapping[row.key] : ''}
                                      valueKey={"code"}
                                      labelKey={"name"}
                                      onChange={(e) => setMapping({
                                        ...mapping,
                                        [row.key]: e.target.value
                                      })}
                                      defaultOption={true}
                                    />
                                  </>
                                );
                              },
                            },
                          ]}
                        /> : null
                    }
                    <ButtonContainer onClick={() => updateProductConfigurations()}>Save</ButtonContainer>
                  </>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default XeroModule;