import * as actionTypes from './actions';
import produce from 'immer';

const initialState = {
  downloadReportError: false,
  downloadReportSuccess: false,
  downloadReportMessage: '',

  getVehicleStatsError: false,
  getVehicleStatsSuccess: false,
  vehicleSats: '',

};

const reportState = produce((draft = initialState, action) => {
  switch (action.type) {
    case actionTypes.DOWNLOAD_REPORT_REQUEST:
      draft.downloadReportError = false;
      draft.downloadReportSuccess = false;
      draft.downloadReportMessage = '';
      break;
    case actionTypes.DOWNLOAD_REPORT_SUCCESS:
      draft.downloadReportError = false;
      draft.downloadReportSuccess = true;
      draft.downloadReportMessage = '';
      break;
    case actionTypes.DOWNLOAD_REPORT_ERROR:
      draft.downloadReportError = true;
      draft.downloadReportSuccess = false;
      draft.downloadReportMessage = action.errorMessage;
      break;
    case actionTypes.GET_VEHICLE_STATS_REQUEST:
      draft.getVehicleStatsError = false;
      draft.getVehicleStatsSuccess = false;
      draft.vehicleSats = [];
      break;
    case actionTypes.GET_VEHICLE_STATS_SUCCESS:
      draft.getVehicleStatsError = false;
      draft.getVehicleStatsSuccess = true;
      draft.vehicleSats = action.data;
      break;
    case actionTypes.GET_VEHICLE_STATS_ERROR:
      draft.getVehicleStatsError = true;
      draft.getVehicleStatsSuccess = false;
      draft.vehicleSats = [];
      break;
    default:
      return draft;
  }
});

export default reportState;
