import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import CardsHeader from 'layouts/Headers/CardsHeader.js';
import { useHistory } from 'react-router-dom';
import useNotify from 'hooks/notify';
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { useDropzone } from "react-dropzone";
import axios from 'axios'
import Cookies from 'js-cookie'
import * as constants from 'constants/index.js'

import { uploadPaymentsDocument, cleanupPayments, savePayments } from '@redux/Payments/reducer';
import ListItems from './ListItems';
import { Text } from "@pages/reusable-components/styled-components";

import {
    Container,
    Row,
    Col,
    Card,
    CardBody
} from "reactstrap";

const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out"
};

const activeStyle = {
    borderColor: "#2196f3"
};

const acceptStyle = {
    borderColor: "#00e676"
};

const rejectStyle = {
    borderColor: "#ff1744"
};

export default function UploadPage() {
    const dispatch = useDispatch();
    const { ref, notify } = useNotify();
    const history = useHistory();

    const [token, setToken] = useState('');
    const [customers, setCustomers] = useState([]);
    const [vehicles, setVehicles] = useState('');

    const {
        uploadedPayments,
        apiError,
        loadingResults,
        savedPayments
    } = useSelector((state) => state.paymentState, shallowEqual);

    useEffect(() => {
        return () => {
            console.log('cleanup')
            dispatch(cleanupPayments())
        };
    }, []);

    useEffect(() => {
        if (apiError) {
            notify(apiError, 'Warning', 'warning');
        }
    }, [apiError])

    const fetchData = async (reg_no) => {
        try {
            const source = axios.CancelToken.source()
            const response = await axios({
                method: 'GET',
                url: `${constants.API_URL}/customer-payments/create/options`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    reg_no: reg_no,
                    withMedia: 1
                },
                cancelToken: source.token,
            })
            const data = response.data

            let _customers = data.customers || [];
            if (_customers.length > 0) {
                _customers.forEach((item) => {
                    item['value'] = item.id;
                    item['label'] = item.text;
                })
            }
            setCustomers(_customers);

            let _vehicles = data.vehicles;
            let _vehicles_array = [];
            Object.keys(_vehicles).map(key => {
                return _vehicles_array.push({
                    ..._vehicles[key],
                    value: _vehicles[key].id,
                    label: _vehicles[key].stock_no,
                })
            })
            setVehicles(_vehicles_array);
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled :: ', error)
            } else {
                console.error('error :: ', error);
            }
        }
    }

    useEffect(() => {
        if (savedPayments) {
            notify('Successfully saved request!', 'success', 'success');
            history.push({
                pathname: '/admin/payments/list'
            });
        }
    }, [savedPayments])

    useEffect(() => {
        const grs_token = Cookies.get('grs_token')
        setToken(grs_token);

        fetchData();
    }, [])

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {})
        }),
        [isDragActive, isDragReject, isDragAccept]
    );

    const onDrop = async (acceptedFiles) => {
        dispatch(uploadPaymentsDocument(acceptedFiles));
    }

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({ onDrop, multiple: false });

    const saveBatchPayments = (payments) => {
        dispatch(savePayments(payments));
    };

    return (
        <>
            <CardsHeader name="Payments Upload" parentName="Payments Management" currentName="List" />
            <div className="rna-wrapper">
                <NotificationAlert ref={ref} />
            </div>
            <Container className="mt--5 admin-main-body" fluid >
                <Row>
                    <Col xl="12">
                        <Card>
                            <CardBody>
                                <Row className={`mb-4 mt-2 ${loadingResults ? 'loading-skeleton' : ''}`}>
                                    {!uploadedPayments ?
                                        <div className="container">
                                            <div {...getRootProps({ style })}>
                                                <input {...getInputProps()} />
                                                <p>Select or drop the documents, then match each of them to the right type</p>
                                            </div>
                                            <aside>
                                               <div>No files yet!</div>
                                            </aside>
                                        </div> :
                                        <div>
                                            {uploadedPayments.payments ?
                                                <CardBody>
                                                    <Text bold>Error payments</Text>
                                                    <ListItems items={uploadedPayments.payments}
                                                        savePayments={saveBatchPayments}
                                                        vehicles={vehicles}
                                                        customers={customers} />
                                                </CardBody>
                                                : null
                                            }
                                        </div>
                                    }
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}