import React, { useEffect, useRef, useState } from 'react'
import {useHistory, useLocation, useParams} from "react-router-dom";
import axios from "axios";
import * as constants from "../../../constants";
import { BASE_URL } from "../../../constants";
import { CardBody, Container } from "reactstrap";


export default function Fleet() {
    const { id } = useParams()

    const [images, setImages] = useState([])
    useEffect(() => {
        if (id) {
            axios({
                method: 'GET',
                url: `${constants.FLEET_MS_API_URL}/fleet/content/${id}`,
            }).then(res => {
                setImages(res.data.data)
            }).catch(err => {

            })
        }
    }, [id])

    return (
        <Container>
            <CardBody>
                <div className={'d-flex flex-wrap'}>
                    {
                        images.map((image,index) => {
                            return <div key={index} className={'w-50 h-50 p-3'}>
                                <img width={'100%'} src={image.url} alt="image" />
                            </div>
                        })
                    }
                </div>
            </CardBody>
        </Container>
    );
}