import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { PropTypes } from "prop-types";
import { FormGroup, Row, Col, Button } from "reactstrap";
import Select from "react-select";

const ListItems = ({ items, savePayments, vehicles, customers }) => {
    const [values, setValues] = useState([]);

    const filterCustomers = inputValue => {
        return customers.filter(i =>
            i.text.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const promiseOptions = inputValue => {
        if (inputValue.length < 2) {
            return
        } else {
            return new Promise(resolve => {
                setTimeout(() => {
                    resolve(filterCustomers(inputValue));
                }, 1000);
            });
        }
    }

    useEffect(() => {
        if (items) {
            const temp = items.map(item => ({
                customer_id: item.customer_id,
                full_name: item.full_name,
                amount: item.amount,
                due_date: item.charge_date,
                deal_invoice_id: item.deal_invoice_id,
                vehicle_id: item.vehicle_id,
                stock_no: item.stock_no,
                invoice_number: item.metadatainvoice_number,
                message: item.message,
                success: item.success,
                notes: item.notes,
            }));
            setValues([...temp]);
        }
    }, items);

    return (
        <div>
            <div className={`py-4 table-responsive ${values ? '' : 'loading-skeleton'}`}>
                <div className="react-bootstrap-table">
                    <table className="table custom-table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Invoice Number</th>
                                <th>Customer Name</th>
                                <th>Vehicle</th>
                                <th>Amount</th>
                                <th>Due Date</th>
                                <th>Observe</th>
                            </tr>
                        </thead>
                        <tbody>
                            {values && values.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.invoice_number}</td>
                                    <td>
                                        <FormGroup style={{ width: "200px" }} className={'d-flex align-items-center'}>
                                            <AsyncSelect
                                                className="w-100"
                                                loadOptions={promiseOptions}
                                                onChange={value => {
                                                    const updatedData = [...values];
                                                    updatedData[index].customer_id = value.id;
                                                    updatedData[index].full_name = value.label;
                                                    setValues(updatedData);
                                                }}
                                                value={item.customer_id ? { label: item.full_name, value: item.customer_id } : null}
                                                placeholder="Search..."
                                                noOptionsMessage={value => {
                                                    if (value.inputValue.length > 1) {
                                                        return "No options"
                                                    }
                                                    return `Please enter ${2 - value.inputValue.length} or more characters`
                                                }}
                                            />
                                        </FormGroup>
                                    </td>
                                    <td>
                                        <FormGroup style={{ width: "200px" }} className={'d-flex align-items-center'}>
                                            <Select
                                                options={vehicles}
                                                className="w-100"
                                                defaultValue={item.vehicle_id ? { label: item.stock_no, value: item.vehicle_id } : null}
                                                onChange={value => {
                                                    const updatedData = [...values];
                                                    updatedData[index].vehicle_id = value.id;
                                                    setValues(updatedData);
                                                }}
                                            />
                                        </FormGroup>
                                    </td>
                                    <td>{item.amount}</td>
                                    <td>{item.due_date}</td>
                                    <td><p>{item.message}</p></td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>
            </div>
            <Row className={'mt-4'}>
                <Col sm={3}>
                    <Button color="primary" disabled={values.filter(item => !item.customer_id && !item.success).length > 0} type="button" onClick={() => {
                        savePayments(values);
                    }}>Save</Button>
                </Col>
            </Row>
        </div>
    )
}

ListItems.propTypes = {
    items: PropTypes.array.isRequired,
    savePayments: PropTypes.func.isRequired,
    vehicles: PropTypes.array,
    customers: PropTypes.array,
}

export default ListItems;