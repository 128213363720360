const index = (permissions, auth_user_team) => {
  if (!auth_user_team) {
    return false
  }
  return permissions.includes('read-automated-reminders')
}

const update = (permissions, auth_user_team, automatedReminder) => {
  if (auth_user_team && auth_user_team.entityable.dealer !== automatedReminder.dealer) {
    return false
  }
  return hasPermission(permissions, 'update-automated-reminders')
}

const deletee = (permissions, auth_user_team, automatedReminder) => {
  if (auth_user_team && auth_user_team.entityable !== automatedReminder.dealer) {
    return false
  }
  return hasPermission(permissions, 'delete-automated-reminders')
}

const hasPermission = (permissions, permission) => {
  return permissions.includes(permission)
}

export {
  index,
  update,
  deletee,
  hasPermission,
};