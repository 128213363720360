import React, { useContext } from 'react'
import { LiveVehicleContext } from "./context";

import InputCustom from 'views/pages/components/InputCustom'
import {
  InputKeySelect, SelectWithoutInput
} from 'views/pages/components/InputSelect'
import TableCustom from 'views/pages/components/TableCustom'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Modal,
} from 'reactstrap'

import ChangeHandler from './ChangeHandler';
import CustomSearch from "@pages/components/CustomSearch";
import {parseToKey, parseToWord} from "constants/index.js";
import {ButtonContainer} from "@pages/reusable-components/styled-components";
import CustomTabs from "@pages/components/CustomTabs";

export default function VehicleInStock({tabs,setActiveTab,activeTab}) {
  const vehicleContext = useContext(LiveVehicleContext);
  const {
    notify,
    history,
    inputStyle,
    formGroupStyle,
    token,
    loading,
    items,
    confirmShow,
    setConfirmShow,
    fundingMethods,
    vehicleStatuses,
    dealStatuses,
    soldTypes,
    filter, setFilter,
    pagination,
    setPageNumber,
    updateDataChanges,
    fetchData,
    filterVehicle,
    resetVehicle,
    removeItem,
    submitItem,
    updateItemsValue,
    soldProcessTypes,
  } = vehicleContext;
  return (
    <>

      <Row>
        <Col xl="12">
          <ChangeHandler notify={notify} updateData={(vehicle) => updateDataChanges(vehicle)} />
        </Col>
         <Col xl="12">
          <Card>
            <CardHeader className={'pt-2 pr-2 pl-2 pb-0'}>
              <Row>
                <Col xs={8}>
                  <h1 className="mb-0">Master GRS Vehicles</h1>
                </Col>
              </Row>
              <CustomTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
            </CardHeader>
            <CardBody>
              <Container className={'ml-0 filters'}>
                <Row className="m-2 justify-content-md-center">
                  <Col md={4} sm={12} xs={12}>
                    <CustomSearch
                        onChange={e => setFilter({
                          ...filter,
                          reg_no: e.target.value
                        })}
                        placeholder={'Search'}
                    />
                  </Col>
                  <Col md={4} sm={12}>
                    <SelectWithoutInput
                        withBorder
                        label={filter && filter.funding_method? parseToWord(filter.funding_method) : `Deal Type`}
                        onSelect={(e) => {
                          setFilter({
                            ...filter,
                            funding_method: parseToKey(e)
                          })
                        }}
                        options={Object.values(fundingMethods)}
                        defaultOption={true}
                        imageLink={'loyalty.png'}
                    />
                  </Col>
                  <Col md={4} sm={12}>
                    <SelectWithoutInput
                        withBorder
                        label={filter && filter.vehicle_status? parseToWord(filter.vehicle_status) : `Vehicle Status`}
                        onSelect={(e) => {
                          setFilter({
                            ...filter,
                            vehicle_status: parseToKey(e)
                          })
                        }}
                        options={Object.values(vehicleStatuses)}
                        defaultOption={true}
                        imageLink={'directions_car_grey.png'}
                    />
                  </Col>
                </Row>
                <Row className="m-2 justify-content-md-start">
                  <Col md={4} sm={12}>
                    <SelectWithoutInput
                        withBorder
                        label={filter && filter.sold_process_type? parseToWord(filter.sold_process_type) : `Sold status`}
                        onSelect={(e) => {
                          setFilter({
                            ...filter,
                            sold_process_type: parseToKey(e)
                          })
                        }}
                        options={Object.values(soldProcessTypes)}
                        defaultOption={true}
                        imageLink={'loyalty.png'}
                    />
                  </Col>
                  <Col md={4} sm={12}>
                    <SelectWithoutInput
                        withBorder
                        label={filter && filter.sold_type? parseToWord(filter.sold_type) : `Sold Type`}
                        onSelect={(e) => {
                          setFilter({
                            ...filter,
                            sold_type: parseToKey(e)
                          })
                        }}
                        options={Object.values(soldTypes)}
                        defaultOption={true}
                        imageLink={'loyalty.png'}
                    />
                  </Col>
                  <Col md={4} sm={12}>
                    <SelectWithoutInput
                        withBorder
                        label={filter && filter.deal_status? parseToWord(filter.deal_status) : `Deal status`}
                        onSelect={(e) => {
                          setFilter({
                            ...filter,
                            deal_status: Object.keys(dealStatuses).find(key => dealStatuses[key] === e)
                          })
                        }}
                        options={Object.values(dealStatuses)}
                        defaultOption={true}
                        imageLink={'loyalty.png'}
                    />
                  </Col>
                </Row>
                <Row className={'justify-content-end mt-5'}>
                    <ButtonContainer  onClick={() => resetVehicle()}>Clear</ButtonContainer>
                    <ButtonContainer reverse onClick={() => filterVehicle()}>Apply</ButtonContainer>
                </Row>
              </Container>
              {items.length > 0 && <Row>
                <Col xs={8}>
                  <h2 className="mb-0">Number of vehicles: {items.length}</h2>
                </Col>
              </Row>
              }
              <TableCustom
                loading={loading}
                sticky={true}
                items={items}
                tableHeaderStyle={'reports-table-header header-text'}
                classes={'table-bordered'}
                pagination={pagination}
                onChange={pageNumber => {
                  setPageNumber(pageNumber)
                  fetchData(pageNumber, token, filter)
                }
                }
                columns={[
                  {
                    dataField: "stock_no",
                    text: "Stock No",
                    style: {
                      "fontWeight": 800
                    },
                    formatter: (rowContent, row) => {
                      return `${row.stock_no}`
                    }
                  },
                  {
                    dataField: "vehicle",
                    text: "Vehicle",
                    formatter: (rowContent, row) => {
                      let vehicle = `${row.vehicle}, ${row.derivative}`;
                      return <a href={`/admin/vehicle/${row.vehicle_id}/edit`} target='_blank'>{vehicle.length > 35 ? `${vehicle.substring(0, 35)}...` : vehicle}</a>;
                    }
                  },
                  {
                    dataField: "deal_id",
                    text: "Deal",
                    formatter: (rowContent, row) => {
                      return row.deal_id ? <a className="text-center" href={`/admin/deals/${row.deal_id}/edit`} target='_blank'>#{row.deal_id}</a> : '-';
                    }
                  },
                  {
                    dataField: "stock_no",
                    text: "Color",
                    formatter: (rowContent, row) => {
                      return `${row.colour}`
                    }
                  },
                  {
                    dataField: "mileage",
                    text: "Mileage",
                    formatter: (rowContent, row) => {
                      return (
                        <InputCustom
                          newLabel={false}
                          value={row.current_mileage ?? ''}
                          style={inputStyle(100)}
                          formGroupStyle={formGroupStyle()}
                          onChange={(e) => {
                            updateItemsValue(row, 'current_mileage', e.target.value)
                          }}
                        />
                      )
                    }
                  },
                  {
                    dataField: "sold_type",
                    text: "Deal type",
                    formatter: (rowContent, row) => {
                      return (
                        <InputKeySelect
                            newLabel={false}
                          options={fundingMethods}
                          value={row.funding_method ?? row.deal_type ?? ''}
                          formGroupStyle={formGroupStyle()}
                          style={inputStyle(100)}
                          onChange={(e) => {
                            updateItemsValue(row, 'funding_method', e.target.value)
                          }}
                          defaultOption={true}
                        />
                      )
                    }
                  },

                  {
                    dataField: "vehicle_status",
                    text: "Vehicle Status",
                    formatter: (rowContent, row) => {
                      let jobsInProgress = Number(row.in_progress_jobs);
                      let totalJobs = Number(row.total_jobs);
                      let label = jobsInProgress > 0 ? `Needs work` : (totalJobs > 0 ? 'Completed' : "No work recorded");
                      return <a href={`/admin/vehicle-details?id=${row.fleet_id}`} target='_blank'>{label}</a>;
                    }
                  },
                  {
                    dataField: "body shop",
                    text: "Body Shop",
                    formatter: (rowContent, row) => {
                      if (row.departments && row.departments.length) {
                        let department = row.departments.find(item=>item.name === 'body shop')
                        let color = department.status === 'in_review' ? '#FFC231' :
                            department.status === 'in_progress' ? '#000' :
                                department.status === 'completed' ? '#00B170' :
                                    department.status === 'needed' ? '#FF1E03' : '#000'
                        return  department ? <div style={{color: color}}>
                          {parseToWord(department.statusLabel)}
                        </div> : '-'
                      } else return <div>-</div>
                    }
                  },
                  {
                    dataField: "valeting",
                    text: "Valeting",
                    formatter: (rowContent, row) => {
                      if (row.departments && row.departments.length) {
                        let department = row.departments.find(item=>item.name === 'valeting')
                        let color = department.status === 'in_review' ? '#FFC231' :
                            department.status === 'in_progress' ? '#000' :
                                department.status === 'completed' ? '#00B170' :
                                    department.status === 'needed' ? '#FF1E03' : '#000'
                        return  department ? <div style={{color: color}}>
                          {parseToWord(department.statusLabel)}
                        </div> : '-'
                      } else return <div>-</div>
                    }
                  },
                  {
                    dataField: "mechanical",
                    text: "Mechanical",
                    formatter: (rowContent, row) => {
                      if (row.departments && row.departments.length) {
                        let department = row.departments.find(item=>item.name === 'mechanical')
                        let color = department.status === 'in_review' ? '#FFC231' :
                            department.status === 'in_progress' ? '#000' :
                                department.status === 'completed' ? '#00B170' :
                                    department.status === 'needed' ? '#FF1E03' : '#000'
                        return  department ? <div style={{color: color}}>
                          {parseToWord(department.statusLabel)}
                        </div> : '-'
                      } else return <div>-</div>
                    }
                  },
                  {
                    dataField: "miscellaneous",
                    text: "Miscellaneous",
                    formatter: (rowContent, row) => {
                      if (row.departments && row.departments.length) {
                        let department = row.departments.find(item=>item.name === 'workshop')
                        let color = department.status === 'in_review' ? '#FFC231' :
                            department.status === 'in_progress' ? '#000' :
                                department.status === 'completed' ? '#00B170' :
                                    department.status === 'needed' ? '#FF1E03' : '#000'
                        return  department ? <div style={{color: color}}>
                          {parseToWord(department.statusLabel)}
                        </div> : '-'
                      } else return <div>-</div>
                    }
                  },
                  {
                    dataField: "Ply",
                    text: "Ply",
                    formatter: (rowContent, row) => {
                      if (row.departments && row.departments.length) {
                        let department = row.departments.find(item=>item.name === 'ply lining')
                        let color = department.status === 'in_review' ? '#FFC231' :
                            department.status === 'in_progress' ? '#000' :
                                department.status === 'completed' ? '#00B170' :
                                    department.status === 'needed' ? '#FF1E03' : '#000'
                        return  department ? <div style={{color: color}}>
                          {parseToWord(department.statusLabel)}
                        </div> : '-'
                      } else return <div>-</div>
                    }
                  },
                  {
                    dataField: "",
                    text: "Action",
                    formatter: (rowContent, row) => {
                      return (
                        <div className="flex-no-wrap">
                          <ButtonContainer onClick={() => {
                            history.push({
                              pathname: `/admin/live-vehicle/${row.live_id}/edit/${row.vehicle_id}/vehicle`
                            })
                          }}>View</ButtonContainer>
                        </div>
                      )
                    }
                  }
                ]}
              />
              {!loading && items && items.length > 0 && <Row>
                <Col sm={12}>
                  <ButtonContainer reverse type="button" onClick={() => submitItem()}>Save</ButtonContainer>
                </Col>
              </Row>}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal
        isOpen={confirmShow}
        toggle={() => setConfirmShow(false)}
        className="modal-dialog-centered modal-secondary"
      >
        <div className="modal-body">
          Are you sure you want to remove this vehicle from the list?
        </div>
        <div className="modal-footer justify-content-end">
          <ButtonContainer
              onClick={() => setConfirmShow(false)}
          >
            Cancel
          </ButtonContainer>
          <ButtonContainer
            reverse
            onClick={() => removeItem()}
          >
            Delete permanently
          </ButtonContainer>

        </div>
      </Modal>
    </>
  )
}