import React, { useState, useEffect } from 'react'
import { Button, Col, Container, Input, Row, FormGroup } from "reactstrap";
import { CalendarInput, Text } from "@pages/reusable-components/styled-components";
import Select from "react-select";
import DatePicker from "react-modern-calendar-datepicker";
import moment from "moment";
import axios from 'axios'
import Cookies from 'js-cookie'
import AsyncSelect from 'react-select/async';
import * as constants from 'constants/index.js'
import { parseToWord } from "constants/index.js";
import CustomLabel from "@pages/components/CustomLabel";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";

export default function AddEditPayment({ setOpen, create, location = null }) {
    const [data, setData] = useState({
        due_date: '',
        vehicle_id: null,
        customer_id: null,
        invoice_number: '',
        amount: '',
        type: '',
        note: '',
        is_debt_collected: 0
    })
    const [token, setToken] = useState('');
    const [clicked, setClicked] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [requiredFields, setRequiredFields] = useState([]);
    const [isNotValid, setIsNotValid] = useState(true);
    const [vehicles, setVehicles] = useState('');
    const options = [
        { value: 'failed_dd', label: 'Failed DD' },
        { value: 'excess_mileage', label: 'Excess mileage' },
        { value: 'payment_plan', label: 'Payment plan' },
        { value: 'vehicle_repairs', label: 'Vehicle repairs' },
        { value: 'fine', label: 'Fine' },
    ];

    const [selectedDay, setSelectedDay] = useState(null);

    const renderCustomInput = ({ ref }) => (
        <CalendarInput
            readOnly
            width={'100%'}
            ref={ref} // necessary
            placeholder="Select date"
            value={selectedDay ? moment({ ...selectedDay, month: selectedDay.month - 1, }).format('DD/MMM/YYYY') : ''}
        />
    )
    const changeData = (obj) => {
        setData({ ...data, [obj.key]: obj.value })
    }
    const selectDate = (obj) => {
        setData({ ...data, [obj.key]: moment({ ...obj.value, month: obj.value.month - 1, }).format('YYYY-MM-DD') })
    }

    const promiseOptions = inputValue => {
        if (inputValue.length < 2) {
            return
        } else {
            return new Promise(resolve => {
                setTimeout(() => {
                    resolve(filterCustomers(inputValue));
                }, 1000);
            });
        }
    }

    const filterCustomers = inputValue => {
        return customers.filter(i =>
            i.text.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const fetchData = async (reg_no) => {
        try {
            const source = axios.CancelToken.source()
            const response = await axios({
                method: 'GET',
                url: `${constants.API_URL}/customer-payments/create/options`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    reg_no: reg_no,
                    withMedia: 1
                },
                cancelToken: source.token,
            })
            const data = response.data

            let _customers = data.customers || [];
            if (_customers.length > 0) {
                _customers.forEach((item, index) => {
                    item['value'] = item.id;
                    item['label'] = item.text;
                })
            }
            setCustomers(_customers);

            let _vehicles = data.vehicles;
            let _vehicles_array = [];
            Object.keys(_vehicles).map(key => {
                return _vehicles_array.push({
                    ..._vehicles[key],
                    value: _vehicles[key].id,
                    label: _vehicles[key].stock_no,
                })
            })
            setVehicles(_vehicles_array);
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled :: ', error)
            } else {
                console.error('error :: ', error);
            }
        }
    }

    useEffect(() => {
        const grs_token = Cookies.get('grs_token')
        setToken(grs_token);

        fetchData();
    }, [])
    useEffect(() => {
        if (location) {
            if (location === 'failed_dd') {
                setData({ ...data, type: 'failed_dd' })
                setRequiredFields(['customer_id', 'vehicle_id', 'invoice_number', 'amount', 'due_date'])
            } else if (location === 'vehicle_repairs') {
                setData({ ...data, type: 'vehicle_repairs' })
                setRequiredFields(['customer_id', 'vehicle_id', 'amount', 'due_date'])
            }
            setIsDisabled(true)
        }
    }, [location])

    useEffect(() => {
        for (let i = 0; i < requiredFields.length; i++) {
            if (!data[requiredFields[i]]) {
                setIsNotValid(true)
                break;
            } else {
                setIsNotValid(false)
            }
        }
    }, [data])

    return (
        <Container>
            <Row>
                <Col lg={6}>
                    <Row className={'dropdown-parent-position z-4'}>
                        <img height='25px' width='25px'
                            src={require(`assets/images/taskEdit/person.png`)} alt="" />
                        <Col>
                            <CustomLabel
                                required
                                text={'Customer Name'}
                            />
                            <FormGroup className={'d-flex align-items-center'}>
                                <AsyncSelect
                                    onMenuOpen={() => {
                                        setClicked(false)
                                    }}
                                    className="w-75"
                                    loadOptions={promiseOptions}
                                    onChange={value => {
                                        setData({
                                            ...data,
                                            customer_id: value.id
                                        })
                                    }}
                                    placeholder="Search..."
                                    noOptionsMessage={value => {
                                        if (value.inputValue.length > 1) {
                                            return "No options"
                                        }
                                        return `Please enter ${2 - value.inputValue.length} or more characters`
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
                <Col lg={6}>
                    <Row className={'dropdown-parent-position z-3'}>
                        <img height='25px' width='25px' src={require(`assets/images/directions_car_grey.png`)} alt="" />
                        <Col>
                            <CustomLabel text={'Vehicle'} required />
                            <FormGroup className={'d-flex align-items-center'}>
                                <Select
                                    onMenuOpen={() => {
                                        setClicked(false)
                                    }}
                                    options={vehicles}
                                    className="w-75"
                                    onChange={value => setData({
                                        ...data,
                                        vehicle_id: value.id
                                    })}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className={'form-group'}>
                {location !== 'vehicle_repairs' && <Col lg={6}>
                    <Row>
                        <img height='25px' width='25px'
                            src={require(`assets/images/person_card.png`)} alt="" />
                        <Col>
                            <CustomLabel text={'Invoice Number'} required />
                            <Input
                                className={'w-75'}
                                value={data.invoice_number}
                                onChange={(e) => changeData({ key: 'invoice_number', value: e.target.value })}
                            />
                        </Col>
                    </Row>
                </Col>}
                <Col lg={6} >
                    <Row >
                        <img height='25px' width='25px'
                            src={require(`assets/images/loyalty.png`)} alt="" />
                        <Col>
                            <CustomLabel text={'Amount'} required />
                            <Input
                                className={'w-75'}
                                value={data.amount}
                                type={`number`}
                                onChange={(e) => changeData({ key: 'amount', value: e.target.value })}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row>
                <Col lg={6}>
                    <Row className={'dropdown-parent-position z-2'}>
                        <IcoMoon
                            iconSet={IconSet}
                            icon={'ballot'}
                            size={25}
                        />
                        <Col>
                            <Text bold>Type: </Text>
                            <Select
                                onMenuOpen={() => {
                                    setClicked(false)
                                }}
                                isDisabled={isDisabled}
                                className={'w-75'}
                                options={options}
                                placeholder={parseToWord(data.type)}
                                onChange={(e) => changeData({ key: 'type', value: e.value })}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col lg={6}>
                    <Row>
                        <img height='25px' width='25px'
                            src={require(`assets/images/date_range.png`)} alt="" />
                        <Col>
                            <CustomLabel text={'Due Date:'} required />
                            <Row className={'pl-3'} onClick={() => setClicked(true)}>
                                <DatePicker
                                    value={selectedDay}
                                    wrapperClassName={'w-75 pr-2' + (clicked ? '' : ' withoutIndex')}
                                    onChange={(e) => {
                                        setSelectedDay(e)
                                        selectDate({ key: 'due_date', value: e })
                                    }}
                                    renderInput={renderCustomInput}
                                    shouldHighlightWeekends
                                />
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col lg={6} className={'mt-4 mb-4'}>
                    <Input
                        className={'w-75'}
                        type={`textarea`}
                        label={`Note`}
                        placeholder={`Note`}
                        value={data.note}
                        onChange={(e) => changeData({ key: 'note', value: e.target.value })}
                        rows={8}
                    />
                </Col>
            </Row>
            <Row className={'mt-4'}>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button disabled={isNotValid} onClick={() => create(data)}>Save</Button>
            </Row>
        </Container>
    )
}