import React, { useState, useMemo } from 'react'
import Pagination from 'react-js-pagination';
import styled from "styled-components";

import {
    CustomSort, SelectWithoutInput
} from 'views/pages/components/InputSelect'

import {
    Row,
    Col,
    Card, Modal, ModalHeader, ModalBody, Button, UncontrolledPopover, PopoverHeader, PopoverBody, ButtonGroup
} from 'reactstrap'
import CustomSearch from "./CustomSearch";

import { FilterButtons, Text } from '../reusable-components/styled-components'
import { parseToWord } from "../../../constants";
import { Calendar } from "react-multi-date-picker";
import CalendarFilter from "@pages/components/CalendarFilter";


import {
    closestCenter,
    DndContext,
    DragOverlay,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";

import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy
} from "@dnd-kit/sortable";

import { SortableTableItem } from "@pages/components/drag/SortableItem";
import { StaticTableRow } from "@pages/components/drag/StaticRow";
import { hasRole } from 'views/policies/PermissionPolicy';
import { useSelector } from 'react-redux';
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";

export const CalendarInput = styled.input`
  cursor: pointer;
  text-align: center;
  align-items: center;
  border: 1px solid #F8F8F9;
  gap: 10px;
  font-size: 1rem;
  padding: 10px;
  color: #808087;
  outline: none;
`
const CalendarSelect = styled.div`
 border: 1px solid #e6e6e7;
 justify-content: space-around;
 border-radius: 4px;
 display: flex;
 padding: 10px;
 font-size: 14px;
 cursor: pointer;
 align-items: center;
 gap: 10px;
`
export default function DepartmentsTable({
    valueKey,
    showClear = false,
    labelKey,
    setOrder,
    showRangeFilter = false,
    selectDateRange,
    makeValue = null,
    modelValue = null,
    selectMake,
    selectModel,
    setData,
    pagination,
    tableData,
    priorities,
    priorityValue,
    make,
    model,
    keys,
    selectPriority,
    showCarFilter = true,
    showSearchButton = false,
    showClearButton = false,
    makeSearch,
    clearFilers,
    showWorkerTasksFilter = false,
    paginate,
    noPriority = true,
    isAdmin = false,
    addButtonText = 'Add New',
    addNew,
    searchPlaceHolder = 'Search',
    onSearchChange,
    showAlphabet = false,
    showDepartment = false,
    sortValue,
    sortParams,
    selectSort,
    setActiveTab,
    activeTab,
    departments,
    showAnyFilter = true,
    searchValue,
    goToDetails,
    removeItem,
    departmentsFilterObject,
    setDepartmentsFilterObject,
    disableDetailsPage = false,
    selectDepartment,
    workerValues = [],
    filterByDate
}) {
    const [funderId, setFunderId] = useState('')
    const [modal, setModal] = useState(false)
    const [clearInput, setClearInput] = useState(false);
    const [values, setValues] = useState([])


    const roles = useSelector(state => state.getState).roles;
    const [selectedDays, setSelectedDays] = useState(null);
    const [activeDevice, setActiveDevice] = useState(null);
    const [activeId, setActiveId] = useState();


    const toggle = () => setModal((prevState) => !prevState);
    const searchData = (event) => {
        onSearchChange({ target: { value: event } })
    }

    const selectFilter = (data) => {
        if (data === 'Clear') {
            return ''
        }
        return data
    }
    const items = useMemo(() => {
        if (tableData && tableData.length) {
            return tableData?.map(({ id }) => id)
        } else return []
    }, [tableData]);

    const sensors = useSensors(
        useSensor(MouseSensor, {}),
        useSensor(TouchSensor, {}),
        useSensor(KeyboardSensor, {})
    );

    function handleDragStart(event) {
        setActiveId(event.active.id);
        setActiveDevice(tableData.find(item => item.id.toString() === event.active.id.toString()));
    }

    function handleDragEnd(event) {
        const { active, over } = event;
        if (active.id !== over.id) {
            const oldIndex = items.indexOf(parseInt(active.id));
            const newIndex = items.indexOf(parseInt(over.id));
            let ordered = arrayMove(tableData, oldIndex, newIndex);
            setData(ordered)
        }
        setOrder()
        setActiveId(null);
        setActiveDevice(null);
    }
    function handleDragOver(event) {
        const { active, over } = event;
        if (active.id !== over.id) {
            const oldIndex = items.indexOf(parseInt(active.id));
            const newIndex = items.indexOf(parseInt(over.id));
            let x = arrayMove(tableData, oldIndex, newIndex);
            setData(x)
        }

    }
    function handleDragCancel() {
        setActiveId(null);
        setActiveDevice(null);
    }

    const driverItem = (value, key, id, keyIndex, itemIndex) => {
        if (key === 'stock_no' || key === 'vehicle') {
            return <th key={key + '' + id}> <Text> {key === 'stock_no' ? value?.vehicle.stock_no : (value?.vehicle?.make?.name + ' ' + value?.vehicle.model?.name)} </Text></th>
        } else if (key === 'index') {
            return <th key={key + '' + id} tabIndex="0" >{itemIndex + 1}</th>
        } else if (key === 'toDetails') {
            return <th key={`${id}_${keyIndex}`} style={{ padding: '5px' }}>
                <Button outline color="success" onClick={() => !disableDetailsPage && goToDetails(id)}>
                    View</Button></th>
        } else if (key === 'status') {
            return <th key={key + '' + id} tabIndex="0" >{parseToWord(value?.statusLabel)}</th>
        }
        return <th key={key + '' + id} tabIndex="0" >{value[key]}</th>
    }
    return (
        <>
            <Modal className={'custom-styles'} isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle} />
                <ModalBody>
                    <div className={'d-flex justify-content-center mb-3'}>
                        <div>
                            <span>From</span>
                            <CalendarInput placeholder="Filter by date"
                                value={values?.length ? values[0].format('DD MMM YYYY') : ''} />
                        </div>
                        <div><span>To</span>

                            <CalendarInput placeholder="Filter by date"
                                value={values?.length > 1 ? values[values.length - 1].format('DD MMM YYYY') : ''} />
                        </div>

                    </div>

                    <div className={'d-flex justify-content-center mb-3'}>
                        <Calendar
                            className={'custom-calendar'}
                            value={values}
                            onChange={(e) => {
                                setValues(e)
                            }}
                            range
                            numberOfMonths={2}
                            showOtherDays
                        />
                    </div>
                    <Button outline={true} style={{
                        backgroundColor: '#ec6409',
                        color: 'white',
                        border: '1px solid #ec6409',
                        float: 'right',
                        marginRight: '15px'
                    }} disabled={values.length < 2} onClick={() => {
                        setSelectedDays([values[0].format('YYYY MMM DD'), values[values.length - 1].format('DD MMM YYYY')])
                        filterByDate({
                            from: values[0].format('YYYY-MM-DD'),
                            to: values[values.length - 1].format('YYYY-MM-DD')
                        })
                        toggle()
                    }}
                    >Save</Button>

                </ModalBody>
            </Modal>
            <Card>
                {showAnyFilter &&
                    <Row className='pd-20 g-10'>
                        <Col sm={12} md={3} className={'align-self-center'}>
                            <CustomSearch
                                clear={clearInput}
                                setClear={() => setClearInput(false)}
                                defaultValue={searchValue}
                                onChange={(e) => searchData(e.target.value)}
                                placeholder={searchPlaceHolder}
                            />
                        </Col>
                        {showDepartment &&
                            <Col sm={12} lg={2} md={4}>
                                <SelectWithoutInput
                                    showClear={showClear}
                                    label={funderId ? funderId : `Department`}
                                    onSelect={e => {
                                        setFunderId(selectFilter(e))
                                        selectDepartment(e)
                                    }}
                                    labelKey={labelKey}
                                    valueKey={valueKey}
                                    options={departments}
                                    defaultOption={true}
                                    imageLink={'group-p.png'}
                                />
                            </Col>
                        }
                        {
                            showRangeFilter && <Col sm={12} lg={4} md={4}>
                                <CalendarFilter filterByDate={(data) => {
                                    selectDateRange(data)
                                }} />
                            </Col>
                        }
                        {showAlphabet &&
                            <Col sm={12} md={2}>
                                <CustomSort imageLink={'sort.png'} label={sortValue === 0 ? 'A - Z' : 'Z - A'}
                                    options={sortParams} value={sortValue} onSelect={selectSort} />
                            </Col>
                        }

                        {noPriority &&
                            <Col className='align-self-center' lg={2} md={2} sm={12}>
                                <SelectWithoutInput
                                    showClear={showClear}
                                    label={priorityValue ? priorityValue : `Priority`}
                                    onSelect={e => {
                                        setFunderId(selectFilter(e))
                                        selectPriority(e)
                                        // fetchData(axios.CancelToken.source(), 1, token, e.target.value)
                                    }}
                                    options={priorities}
                                    defaultOption={true}
                                    valueKey={`value`}
                                    labelKey={`label`}
                                    imageLink={'label_important_outline.png'}
                                />
                            </Col>
                        }
                        {showCarFilter &&
                            <>
                                <Col className='align-self-center' lg={2} md={3} sm={12}>
                                    <SelectWithoutInput
                                        showClear={showClear}
                                        label={makeValue ? makeValue : `Make`}
                                        onSelect={e => {
                                            selectMake(e)
                                        }}
                                        options={make}
                                        defaultOption={true}
                                        valueKey={valueKey}
                                        labelKey={labelKey}
                                        imageLink={'directions_car_grey.png'}
                                    />
                                </Col>
                                <Col className='align-self-center' lg={2} md={3} sm={12}>
                                    <SelectWithoutInput
                                        showClear={showClear}
                                        label={modelValue ? modelValue : `Model`}
                                        onSelect={e => {
                                            selectModel(e)
                                        }}
                                        options={model}
                                        defaultOption={true}
                                        valueKey={valueKey}
                                        labelKey={labelKey}
                                        imageLink={'loyalty.png'}
                                    />
                                </Col>

                            </>
                        }

                        <Col lg={4} md={6} sm={12}>
                            <Row>

                                {
                                    showClearButton &&
                                    <Col>
                                        <FilterButtons className={'mb-2'} background="#fafafa" color="#ec6409"
                                            onClick={() => {
                                                clearFilers()
                                                setClearInput(true)
                                            }}>Clear</FilterButtons>
                                    </Col>
                                }
                                {
                                    isAdmin &&
                                    <Col>
                                        <FilterButtons background="#ec6409" color="#fafafa" onClick={addNew}>
                                            {addButtonText}
                                        </FilterButtons>
                                    </Col>
                                }
                            </Row>
                        </Col>
                        {
                            showSearchButton &&
                            <div className={'d-flex align-items-center ml-5'}>
                                <Button className="w-100" color="warning" type="button"
                                    onClick={() => makeSearch()}>Filter</Button>
                            </div>
                        }


                    </Row>

                }
                {
                    showWorkerTasksFilter &&
                    <Row className='pd-20'>
                        <CalendarSelect>
                            <img onClick={toggle} width='20px' height='20px'
                                src={require("assets/images/date_range.png")}
                                alt="" />
                            <span
                                onClick={toggle}>{selectedDays?.length ? selectedDays[0] + ' - ' + selectedDays[1] : 'Filter by date'}</span>
                            {selectedDays?.length ? <i className="fa fa-times" onClick={(e) => {
                                e.preventDefault()
                                setSelectedDays([])
                                setValues([])
                                filterByDate({
                                    from: '',
                                    to: ''
                                })
                            }}
                            />
                                :
                                <></>
                            }
                            <i onClick={toggle} className="ni ni-up rotate-down" />
                        </CalendarSelect>
                    </Row>
                }

                {isAdmin ? <div className={'d-flex justify-content-center'}>
                    <Row className={'w-50 '}>
                        <Col>
                            <div onClick={() => setActiveTab('work_needed')}
                                className={'d-flex align-items-center ' +
                                    'justify-content-center cursor-pointer ' +
                                    'document-container tab-container ' + (activeTab === 'work_needed' ? 'active' : '')}>
                                <span>Worked need</span>
                            </div>
                        </Col>
                        <Col>
                            <div onClick={() => setActiveTab('work_completed')}
                                className={'d-flex align-items-center' +
                                    ' justify-content-center cursor-pointer ' +
                                    'document-container tab-container ' + (activeTab === 'work_completed' ? 'active' : '')}>
                                <span>Completed Vehicles</span>
                            </div>
                        </Col>
                    </Row>
                </div> : null}
                {isAdmin ?
                    <div className="table-responsive" style={{ maxHeight: 'calc(100vh - 250px)' }}>
                        <div>
                            <DndContext
                                sensors={sensors}
                                onDragEnd={handleDragEnd}
                                onDragStart={handleDragStart}
                                onDragOver={handleDragOver}
                                onDragCancel={handleDragCancel}
                                collisionDetection={closestCenter}
                                modifiers={[restrictToVerticalAxis]}
                            >
                                <table className="table table-bordered">
                                    <thead>
                                        <tr className={'table-header sticky-top sticky-table-position'}>
                                            {activeTab === 'work_needed' && <th className={'header-text'} key={'header'} tabIndex="0">Order</th>}
                                            {
                                                keys.map((column, index) => {
                                                    return <th className={'header-text'} key={index + ''} tabIndex="0">{column?.text}
                                                        {activeTab === 'work_needed' && column?.filterable &&
                                                 <>
                                                 {
                                                     departmentsFilterObject[column?.key] === null ?
                                                         <IcoMoon
                                                             className={'cursor-pointer'}
                                                             id={`PopoverLegacy_${index}`}
                                                             iconSet={IconSet}
                                                             icon={'filter_icon'}
                                                             size={16}

                                                         /> :
                                                         <IcoMoon
                                                             className={'cursor-pointer'}
                                                             id={`PopoverLegacy_${index}`}
                                                             iconSet={IconSet}
                                                             icon={'filter_icon_filled'}
                                                             size={16}
                                                         />
                                                 }

                                                     <UncontrolledPopover
                                                         placement="top"
                                                         target={`PopoverLegacy_${index}`}
                                                         trigger="legacy"
                                                     >
                                                         <PopoverHeader>
                                                             Status Filter
                                                         </PopoverHeader>
                                                         <PopoverBody>
                                                             <ButtonGroup>
                                                                 <Button
                                                                     color="primary"
                                                                     outline
                                                                     onClick={() => setDepartmentsFilterObject({...departmentsFilterObject, [column?.key]: 'to_do'})}
                                                                     active={departmentsFilterObject[column?.key] === 'to_do'}
                                                                 >
                                                                     Needed
                                                                 </Button>
                                                                 <Button
                                                                     color="primary"
                                                                     outline
                                                                     onClick={() => setDepartmentsFilterObject({...departmentsFilterObject, [column?.key]: 'completed'})}
                                                                     active={departmentsFilterObject[column?.key] === 'completed'}
                                                                 >
                                                                     Completed
                                                                 </Button>
                                                                 <Button
                                                                     color="primary"
                                                                     outline
                                                                     onClick={() => setDepartmentsFilterObject({...departmentsFilterObject, [column?.key]: null})}
                                                                     active={!departmentsFilterObject[column?.key]}
                                                                 >
                                                                     Clear
                                                                 </Button>
                                                             </ButtonGroup>
                                                         </PopoverBody>
                                                     </UncontrolledPopover>
                                                 </>
                                                        }

                                                    </th>
                                                })

                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <SortableContext items={items} strategy={verticalListSortingStrategy}>
                                            {
                                                tableData && tableData.length > 0 &&
                                                tableData.map((device, index) => {
                                                    return <React.Fragment key={index}>
                                                        <SortableTableItem
                                                            disable={activeTab !== 'work_needed'}
                                                            device={device}
                                                            index={index}
                                                            keys={keys}
                                                            disableDetailsPage={disableDetailsPage}
                                                            goToDetails={goToDetails}
                                                            removeItem={removeItem}
                                                            deleteItem={hasRole(roles, 'dealer_admin') || hasRole(roles, 'Fleet Manager')}
                                                        />

                                                    </React.Fragment>
                                                })
                                            }

                                        </SortableContext>
                                    </tbody>
                                </table>
                                <DragOverlay>
                                    {activeId && (
                                        <table style={{ width: "100%" }}>
                                            <tbody>
                                                <StaticTableRow device={activeDevice} keys={keys} />
                                            </tbody>
                                        </table>
                                    )}
                                </DragOverlay>
                            </DndContext>
                        </div>
                    </div> :
                    <div>
                        <table className="table table-bordered">
                            <thead>
                                <tr className={'reports-table-header'}>
                                    {
                                        keys.map((column, index) => {
                                            return <th className={'header-text'} key={index + ''} tabIndex="0">{column?.text}</th>
                                        })

                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    workerValues && workerValues.length > 0 &&
                                    workerValues.map((device, index) => {
                                        return <tr className={'cursor-pointer'} key={`${index}_device_${device.vehicle_make}`}>
                                            {
                                                keys.map((item, keyIndex) => {
                                                    return driverItem(device, item.key, device?.id, keyIndex, index)
                                                })
                                            }

                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>}
                {pagination && (isAdmin ? tableData.length > 0 : workerValues.length) &&
                    <Row className="react-bootstrap-table-pagination">
                        <Col lg={6}>
                            <p className="text-sm text-black">
                                {`Showing rows ${(pagination.current_page - 1) * pagination.per_page + 1} to
                                         ${pagination.per_page * pagination.current_page > pagination.total ? pagination.total : pagination.per_page * pagination.current_page} of ${pagination.total}`}
                            </p>
                        </Col>
                        <Col lg={6} className="react-bootstrap-table-pagination-list">
                            <Pagination
                                hideDisabled
                                activePage={pagination.current_page}
                                totalItemsCount={pagination.total}
                                itemsCountPerPage={pagination.per_page}
                                onChange={paginate}
                                itemClass="page-item"
                                linkClass="page-link"
                                innerClass="pagination react-bootstrap-table-page-btns-ul"
                            />
                        </Col>
                    </Row>}
            </Card>

        </>
    )
}