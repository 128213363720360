import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import TableCustom from 'views/pages/components/TableCustom';
import InputCustom from 'views/pages/components/InputCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
} from 'reactstrap';

import {
  update,
  deletee,
  hasPermission
} from 'views/policies/CountyPolicy';
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";
import {ButtonContainer} from "@pages/reusable-components/styled-components";

export default function Counties() {
  const notificationAlert = useRef(null);
  const permissions = useSelector(state => state.getState).permissions;

  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState('');

  const [county, setCounty] = useState('');
  const [countyModal, setCountyModal] = useState({
    show: false,
    header: '',
    division: ''
  })
  const [deleteModal, setDeleteModal] = useState({
    show: false,
    header: '',
    body: '',
    onClick: null
  });

  const [errors, setErrors] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    fetchData(source, 1, grs_token)

    return () => {
      source.cancel()
    }
  }, [])

  const fetchData = async (source, pageNumber, token) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/county`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page: pageNumber,
        },
        cancelToken: source.token,
      })
      console.log('response :: ', response.data)
      const data = response.data
      setItems(data.data)

      setTimeout(() => {
        setLoading(false)
      }, 500);
    } catch (error) {
      setLoading(false)
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
      }
    }
  }
  const addCounty = () => {
    axios({
      method: 'POST',
      url: `${constants.API_URL}/county`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: county
    })
      .then(response => {
        setErrors('')
        setCountyModal({
          ...countyModal,
          show: false
        })
        setCounty('')
        let data = response.data
        setItems([...items,data.data])
        notify('success', 'Success', data.message)
      })
      .catch(error => {
        setErrors('')
        console.error('error :: ', error)
        if (error.response && error.response.status === 422) {
          setErrors(error.response.data.errors)
          return
        }
        setCountyModal({
          ...countyModal,
          show: false
        })
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      })
  }

  const updateCounty = () => {
    axios({
      method: 'PUT',
      url: `${constants.API_URL}/county/${county.id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: county
    })
      .then(response => {
        setErrors('')
        setCountyModal({
          ...countyModal,
          show: false
        })
        setCounty('')
        let data = response.data
        let idx = items.findIndex(item=>item.id === data.data.id)
        if (idx > -1) {
          let tempItems = [...items]
          tempItems[idx] = data.data
          setItems(tempItems)
        }
        notify('success', 'Success', data.message)
      })
      .catch(error => {
        setErrors('')
        console.error('error :: ', error)
        if (error.response && error.response.status === 422) {
          setErrors(error.response.data.errors)
          return
        }
        setCountyModal({
          ...countyModal,
          show: false
        })
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      })
  }
  const deleteCountry = (countryId) => {
    axios({
      method: 'DELETE',
      url: `${constants.API_CORE_URL}/county/${countryId}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
        .then(response => {
          setDeleteModal({
            ...deleteModal,
            show: false
          })
          notify('success', 'Success')
          let tempItems = items.filter(country=>country.id !== countryId)
          setItems(tempItems)
        })
        .catch(error => {
          console.error('error :: ', error)
          setDeleteModal({
            ...deleteModal,
            show: false
          })
          notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
        })
  }

  return (
    <>
      <CardsHeader name="Settings" parentName="County Management" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Counties</h1>
                    <p className="text-sm mb-0">You can manage Counties</p>
                  </Col>
                  <Col xs={4} className="text-right">
                    <ButtonContainer reverse onClick={() => {
                      setCounty('')
                      setErrors('')
                      setCountyModal({
                        ...countyModal,
                        show: true,
                        header: 'County information',
                        division: 'create'
                      })
                    }}>Add county</ButtonContainer>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <TableCustom
                  loading={loading}
                  items={items}
                  pagination={false}
                  onChange={(pageNumber) => fetchData(axios.CancelToken.source(), pageNumber, token)}
                  columns={[
                    {
                      dataField: "name",
                      text: "Name",
                      sort: true
                    },
                    {
                      dataField: "",
                      text: "Action",
                      style: {
                        textAlign: 'right'
                      },
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            {(hasPermission(permissions, 'create-counties') || hasPermission(permissions, 'delete-counties')) &&
                                <>
                                  {update(permissions, row) &&
                                  <IcoMoon
                                      className={'cursor-pointer'}
                                      iconSet={IconSet}
                                      icon={'edit'}
                                      size={24}
                                      onClick={e => {
                                        setCounty(row)
                                        setCountyModal({
                                          ...countyModal,
                                          show: true,
                                          header: 'Edit county',
                                          division: 'udpate'
                                        })
                                      }}
                                  />
                                 }
                                  {deletee(permissions, row) &&
                                  <IcoMoon
                                      className={'cursor-pointer'}
                                      iconSet={IconSet}
                                      icon={'delete_outline'}
                                      size={24}
                                      onClick={() => setDeleteModal({
                                        ...deleteModal,
                                        show: true,
                                        header: 'Delete Country',
                                        body: 'Are you sure you want to delete Country?',
                                        onClick: () => deleteCountry(row.id)
                                      })}
                                  />
                                  }
                                </>
                              }
                          </>
                        )
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={countyModal.show}
          toggle={() => setCountyModal({
            ...countyModal,
            show: false
          })}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title">{countyModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setCountyModal({
                ...countyModal,
                show: false
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <InputCustom
              label={`Name`}
              value={county && county.name ? county.name : ''}
              onChange={e => setCounty({
                ...county,
                name: e.target.value,
              })}
              invalid={errors && errors.name ? true : false}
              errorMessage={errors.name}
            />
          </div>
          <div className="modal-footer">
            <ButtonContainer
              className="new-event--add"
              color="primary"
              type="button"
              onClick={() => countyModal.division === 'create' ? addCounty() : updateCounty()}
            >
              {`Save`}
            </ButtonContainer>
            <ButtonContainer
              reverse
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setCountyModal({
                ...countyModal,
                show: false
              })}
            >
              {`Close`}
            </ButtonContainer>
          </div>
        </Modal>
        <Modal
            isOpen={deleteModal.show}
            toggle={() => setDeleteModal({
              ...deleteModal,
              show: false
            })}
            className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title">{deleteModal.header}</h6>
            <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setDeleteModal({
                  ...deleteModal,
                  show: false
                })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {`Are you sure you want to delete this country?`}
          </div>
          <div className="modal-footer">
            <Button
                className="new-event--add"
                color="danger"
                type="button"
                onClick={deleteModal.onClick}
            >
              {`Delete`}
            </Button>
            <Button
                className="ml-auto"
                color="link"
                type="button"
                onClick={() => setDeleteModal({
                  ...deleteModal,
                  show: false
                })}
            >
              {`Close`}
            </Button>
          </div>
        </Modal>
      </Container>
    </>
  )
}