import React, {useMemo} from 'react'

import {
    Col, Button,
} from 'reactstrap'

import  {
    highlightText,
    cutEmail, parseGmailDate,
} from "../../../../constants";

import {ClipLoader} from "react-spinners";
import {override} from "@pages/reusable-components/styled-components";



export default function EmailsTable({
                                        keys,
                                        tableData,
                                        loading = true,
                                        nextPageToken,
                                        goToDetails,
                                        paginate,
                                        searchValue = '',
                                    }) {


    const generateCustomItem = (email, key, index) => {

        const matchedHeader = email.headers.find(header => header.name === key);

        if (matchedHeader) {
            if (key === 'From') {
                return <td key={index}>{cutEmail(matchedHeader.value)}</td>;
            } else if (key === 'Subject') {
                return <td key={index}>{highlightText(matchedHeader.value, searchValue)}</td>;
            } else if (key === 'Date') {
                return <td key={index}>{parseGmailDate(matchedHeader.value)}</td>;
            } else if (key === 'Delivered-To') {
                return <td key={index}>{matchedHeader.value}</td>;
            }
        } else {
            return <td key={index}>-</td>;
        }

    }

    const columns = useMemo(() => {
        return  tableData && tableData.length > 0 ?
                tableData.map((email,index) => {
                    return <tr className={'cursor-pointer'} onClick={()=>goToDetails(email.id)} key={`${index}_email`}>
                        {
                            keys.map((key, keyIndex) => {
                                return <React.Fragment key={index + '^' + keyIndex}>
                                    {generateCustomItem(email,key.key,`_header_${keyIndex}`)}
                                </React.Fragment>

                            })


                        }
                    </tr>
                })
                : <tr>
                <td colSpan={keys?.length}><h3>No Data</h3></td>
            </tr>
    }, [tableData,keys,searchValue]);
    return (
        <>
            {loading ?
                <ClipLoader
                    css={override}
                    size={40}
                    color={`#7B61E4`}
                    loading={true}
                /> :
                <div className={'w-100'}>
                    <div className={"table-responsive  overflow-y-hidden "} >
                        <table className="table table-bordered">
                            <thead>
                            <tr className={'reports-table-header'}>
                                {
                                    keys.map((column, index) => {
                                        return  <th key={index + 'X'} className={'header-text'}  tabIndex="0">{column?.label}

                                        </th>
                                    })

                                }
                            </tr>
                            </thead>
                            <tbody>
                            {
                                columns
                            }
                            </tbody>
                        </table>
                    </div>
                     {tableData.length > 0 && nextPageToken &&
                    <Col lg={2} sm={6} className={'mt-3'}>
                        <Button color="warning" onClick={paginate}>
                            {`Next page `}
                        </Button>
                    </Col>
                    }
                </div>

            }


        </>
    )
}