import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col
} from "reactstrap";
import {ButtonContainer} from "@pages/reusable-components/styled-components";

export default function CardsHeader({children, name, backButtonText='', showBackButton = false,
                                      redirect}) {
  return (
    <>
      <div className="header pb-6" style={{height: '130px'}}>
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center my-n-4 justify-content-between">
              <Col lg="6" sm="10" xs="7">
                <h6 className="h2 d-inline-block mb-0">
                  {name}
                </h6>{" "}

              </Col>
              {showBackButton && <ButtonContainer className={'ml-3'}  onClick={() => {
                redirect()
              }}>{backButtonText}</ButtonContainer>}
            </Row>

            <Row>
              {children}
            </Row>

          </div>

        </Container>
      </div>
    </>
  );
}

CardsHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string
};