import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import InputSideCustom from 'views/pages/components/InputSideCustom';
import TableCustom from 'views/pages/components/TableCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from 'reactstrap';

export default function UpcomingPayments() {
  const notificationAlert = useRef(null);
  const history = useHistory();

  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [defaultDate, setDefaultDate] = useState('');
  const [paymentDescriptors, setPaymentDescriptors] = useState('');
  const [filter, setFilter] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {

    if (Cookies.get('grs_token')) {
      let grs_token = Cookies.get('grs_token')
      setToken(grs_token)

      axios({
        method: 'GET',
        url: constants.API_URL + '/payments/upcoming',
        headers: {
          Authorization: 'Bearer ' + grs_token
        },
      })
        .then(response => {
          console.log('res :: ', response.data)
          let data = response.data
          setItems(data.data.items)
          setDefaultDate(data.data.defaultDate)
          setPaymentDescriptors(data.data.paymentDescriptors)

          setFilter({
            ...filter,
            upcoming_date: data.data.defaultDate? constants.formatDate(data.data.defaultDate): ''
          })

          setTimeout(() => {
            setLoading(false)
          }, 500);
        })
        .catch(error => {
          setLoading(false)
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
        })
    }

  }, [])

  const filterPayments = () => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/payments/upcoming',
      headers: {
        Authorization: 'Bearer ' + token
      },
      params: filter
    })
      .then(response => {
        setLoading(true)
        console.log('res :: ', response.data)
        let data = response.data
        setItems(data.data.items)

        setTimeout(() => {
          setLoading(false)
        }, 500);
      })
      .catch(error => {
        setLoading(false)
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      })
  }

  const resetPayments = () => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/payments/upcoming',
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then(response => {
        setLoading(true)
        console.log('res :: ', response.data)
        let data = response.data
        setItems(data.data.items)
        setFilter({
          ...filter,
          upcoming_date: defaultDate? constants.formatDate(defaultDate): '',
          customer: ''
        })

        setTimeout(() => {
          setLoading(false)
        }, 500);
      })
      .catch(error => {
        setLoading(false)
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      })
  }

  const goPayment = (deal) => {
    history.push({
      pathname: '/admin/deals/' + deal.id + '/payment'
    })
  }

  return (
    <>
      <CardsHeader name="Payments" parentName="Home" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <div>
                    <h1 className="title mb-0">List of upcoming payments</h1>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <Container>
                  <Row>
                    <Col md={6}>
                      <InputSideCustom
                        label={`Customer`}
                        value={filter && filter.customer ? filter.customer : ''}
                        onChange={e => setFilter({
                          ...filter,
                          customer: e.target.value
                        })}
                      />
                    </Col>
                    <Col md={6}>
                      <InputSideCustom
                        type={`date`}
                        label={`To`}
                        value={filter && filter.upcoming_date ? filter.upcoming_date : ''}
                        onChange={e => setFilter({
                          ...filter,
                          upcoming_date: e.target.value
                        })}
                      />
                    </Col>
                  </Row>
                  <div className="d-flex flex-row justify-content-center">
                    <Button className="w-50" color="success" onClick={() => filterPayments()}>Filter</Button>
                    <Button className="w-50" color="danger" onClick={() => resetPayments()}>Reset</Button>
                  </div>
                </Container>
                <TableCustom 
                  loading={loading}
                  items={items}
                  keyField={`id`}
                  searchField={false}
                  columns={[
                    {
                      dataField: "deal",
                      text: "Deal",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return <span onClick={() => goPayment(row.deal)}>{row.deal && row.deal.id? `Deal #${row.deal.id}`: ''}</span>
                      }
                    },
                    {
                      dataField: "full_name",
                      text: "Customer",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.deal && row.deal.full_name? row.deal.full_name: ''
                      }
                    },
                    {
                      dataField: "amount",
                      text: "Amount",
                      sort: true
                    },
                    {
                      dataField: "full_name",
                      text: "Due Date",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return constants.formatDateShortGB(row.scheduled_at)
                      }
                    },
                    {
                      dataField: "full_name",
                      text: "Due Date",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return paymentDescriptors[row.payment_descriptor]
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}