import React from "react";
import CustomTable from "@pages/components/Table/CustomTable";
import CloseArrow from "@pages/components/icons/CloseArrow";
import {Col, Row} from "reactstrap";
import {formatDateShortGB} from "../../../../constants";
import SortingArrow from "@pages/components/icons/SortingArrow";
import Pagination from "react-js-pagination";

export default function NestedTable({
                                        parentKeys,
                                        tableData,
                                        keys,
                                        selectDate,
                                        openModal,
                                        toDetails,
                                        pagination,
                                        paginate,
                                        isNestedOpened,
                                        setIsNestedOpened,
                                        openMailModal,
                                        viewMails,
                                        payAction,
                                        removeAll = false,
                                        deleteAll,
                                        deleteRow,
                                        tabName,
                                        downloadInvoice,
                                        selectAction,
                                        sort,
                                        sortObject,
                                        changeGroupStatus,
                                        tableActions = null,
                                        selectedAction = null,
                                        ownerDetails,
                                        nestedTableDataKey,
                                    }) {
    const generateTableItems = (item,column,parentIndex,columnIndex) => {

        if (column.key === 'open_nested') {
            return (
                <td key={columnIndex+'_columnIndex_'+item.id}>
                    <CloseArrow
                        order={isNestedOpened[item.id] ? 'asc' : null}
                        color={'#000000'}
                        setOrder={() => setIsNestedOpened(item.id)}
                    />
                </td>
            )
        } else if(column.key === 'openModal') {
            return (
                <td key={columnIndex+'_columnIndex_'+item.id}>
                    <span className={`table-button white`} onClick={()=>openModal(item.id)}>View notes</span>
                </td>

            )
        }  else if (column.key === 'openMailModal') {
            return (
                <td key={columnIndex+'_columnIndex_'+item.id}>
                    <div className={'mb-3'}>
                      <span className={`table-button blue`} onClick={()=>openMailModal({index: parentIndex})}>Send Mail</span>
                    </div>
                    <div>
                        <span className={`table-button blue`} onClick={()=>viewMails({index: parentIndex})}>View Sent Emails</span>
                    </div>
                </td>

            )
        } else if ( column.key === 'show_diff') {
            return (
                <td key={columnIndex+'_columnIndex_'+item.id}>
                 <span>{ (item.payments_sum_amount - item.partials_sum_amount)?.toFixed(2)}</span>
                </td>
            )
        } else if (column.key === 'missed_payments_count') {
            return (
                <td key={columnIndex+'_columnIndex_'+item.id}>
                <span>{tableData[parentIndex][nestedTableDataKey]?.length}</span>
                </td>
            )
        }
        else if (column.key === 'payments_sum_amount') {
            return (
                <td key={columnIndex+'_columnIndex_'+item.id}>
                    <span>{item[column.key]?.toFixed(2)}</span>
                </td>
            )
        }
        else if (column.key === 'partials_sum_amount') {
            return (
                <td key={columnIndex+'_columnIndex_'+item.id}>
                    <span>{item[column.key]?.toFixed(2)}</span>
                </td>

            )
        } else if (column.key === 'due_date') {
            const nestedTable = tableData[parentIndex][nestedTableDataKey]
            let maxDate = null;
            let latestObject = null;
            nestedTable.forEach(obj => {
                const date = new Date(obj.due_date);
                if (!maxDate || date > maxDate) {
                    maxDate = date;
                    latestObject = obj;
                }
            });
            const value = latestObject ? latestObject.due_date : null;
            return (
                <td key={columnIndex+'_columnIndex_'+item.id}>
                    <span>{formatDateShortGB(value)}</span>
                </td>

            )
        } else if (column.key === 'expected_payment_date') {
            const nestedTable = tableData[parentIndex][nestedTableDataKey];
            let minDate = null;
            let firstObj = null;

            nestedTable.forEach(obj => {
                const date = obj.expected_payment_date ? new Date(obj.expected_payment_date) : null;

                if (date && (!minDate || date < minDate)) {
                    minDate = date;
                    firstObj = obj;
                }
            });
            const value = firstObj ? firstObj.expected_payment_date : null;
            return (
                <td key={`${columnIndex}_columnIndex_${item.id}`}>
                    <span>{value ? formatDateShortGB(value) : '-'}</span>
                </td>
            );
        }else if (column.key === 'immobilised_date') {
            const nestedTable = tableData[parentIndex][nestedTableDataKey]
            let maxDate = null;
            let latestObject = null;
            nestedTable.forEach(obj => {
                const date = new Date(obj.immobilised_date);
                if (!maxDate || date > maxDate) {
                    maxDate = date;
                    latestObject = obj;
                }
            });
            const value = latestObject ? latestObject.immobilised_date : null;
            return (
                <td key={columnIndex+'_columnIndex_'+item.id}>
                    <span>{formatDateShortGB(value)}</span>
                </td>

            )
        } else return  <td><span>{item[column.key]}</span></td>

    }
   return (
       <div className="table-responsive" >
           <table className="table table-bordered">
               <thead>
               <tr className={'table-header'}>
                   {
                       parentKeys.map((parentColumn, index) => {
                           return <th key={index + 'X'} className={'header-text'}
                                      tabIndex="0">{parentColumn?.label}
                               {parentColumn?.sort ?
                                   <>
                                       &nbsp;
                                       <SortingArrow
                                           order={sortObject[parentColumn.key]}
                                           setOrder={(value)=>{
                                               sort({[parentColumn.key]: value})
                                           }}
                                       />
                                   </>
                                   : <></>}
                           </th>
                       })
                   }
               </tr>
               </thead>
               <tbody>
               {
                   tableData.map((customer, index) => {
                       return <React.Fragment key={`${index}_parent`}>

                           <tr  className={`multicolor ${index % 2 === 0 ? 'even' : 'odd'}`} key={`${index}_customer_${customer?.id}`}>
                               {
                                   parentKeys.map((column, keyIndex) => {
                                       return <React.Fragment
                                           key={index + '^' + keyIndex}>
                                           {generateTableItems(customer,column,index,keyIndex)}
                                       </React.Fragment>
                                   })
                               }

                           </tr>
                           {isNestedOpened[customer.id] &&
                           <tr key={`${index}_payments_${customer?.id}`}>
                               <td key={`${index}_payments_${customer?.id}`} colSpan="9">
                                   <CustomTable
                                       keys={keys}
                                       multicolor
                                       customClassName={'table-header'}
                                       selectDate={(data)=>selectDate(index,data)}
                                       toDetails={toDetails}
                                       openModal={()=>openModal(index)}
                                       payAction={payAction}
                                       removeAll={removeAll}
                                       deleteAll={()=>deleteAll(index)}
                                       deleteRow={(id)=>deleteRow(id,index)}
                                       ownerDetails={(id) => ownerDetails(id)}
                                       downloadInvoice={downloadInvoice}
                                       tableData={tableData[index][nestedTableDataKey]}
                                       selectedAction={selectedAction}
                                       selectAction={selectAction}
                                       tableActions={tableActions}
                                       changeGroupStatus={changeGroupStatus}
                                       isPaymentsPage={true} tabName={tabName}/>
                               </td>
                           </tr>

                           }
                       </React.Fragment>
                   })
               }
               </tbody>
           </table>
           {pagination && tableData.length > 0 &&
           <Row className="react-bootstrap-table-pagination">
               <Col lg={6}>
                   <p className="text-sm text-black">
                       {`Showing rows ${(pagination.current_page - 1) * pagination.per_page + 1} to
                                         ${pagination.per_page * pagination.current_page > pagination.total ? pagination.total : pagination.per_page * pagination.current_page} of ${pagination.total}`}
                   </p>
               </Col>
               <Col lg={6} className="react-bootstrap-table-pagination-list">
                   <Pagination
                       hideDisabled
                       activePage={pagination.current_page}
                       totalItemsCount={pagination.total}
                       itemsCountPerPage={pagination.per_page}
                       onChange={paginate}
                       itemClass="page-item"
                       linkClass="page-link"
                       innerClass="pagination react-bootstrap-table-page-btns-ul"
                   />
               </Col>
           </Row>}
       </div>
   )
}
