import React, { useContext } from 'react'
import { LiveVehicleContext } from "./context";

import InputCustom from 'views/pages/components/InputCustom'
import {
   InputKeySelect
} from 'views/pages/components/InputSelect'
import TableCustom from 'views/pages/components/TableCustom'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from 'reactstrap'

import ChangeHandler from './ChangeHandler';

export default function ForRentVehicleList() {
  const vehicleContext = useContext(LiveVehicleContext);
  const {
    notify,
    inputStyle, formGroupStyle,
    token,
    loading,
    items,
    vehicleStatuses,
    dealStatuses,
    filter, setFilter,
    pagination,
    setPageNumber,
    updateDataChanges,
    fetchData,
    filterVehicle,
    resetVehicle,
    submitItem,
    updateItemsValue
  } = vehicleContext;
  
  Object.keys(dealStatuses)
    .filter(key => ['sold'].includes(key))
    .forEach(key => delete dealStatuses[key]);

  return (
    <>

      <Row>
        <Col xl="12">
          <ChangeHandler notify={notify} updateData={(vehicle) => updateDataChanges(vehicle)} />
        </Col>
        <Col xl="12">
          <Card>
            <CardHeader>
              <Row>
                <Col xs={8}>
                  <h1 className="mb-0">Vehicles For Rent</h1>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Container className={'ml-0 filters'}>
                <Row className="m-2 justify-content-md-center">
                  <Col md={4} sm={12} xs={12}>
                    <InputCustom
                      label={`Search`}
                      value={filter && filter.reg_no ? filter.reg_no : ''}
                      onChange={e => setFilter({
                        ...filter,
                        reg_no: e.target.value
                      })}
                    />
                  </Col>
                  <Col md={4} sm={12}>
                    <InputKeySelect
                      label={'Vehicle Status'}
                      options={vehicleStatuses}
                      value={filter.vehicle_status ?? ''}
                      onChange={(e) => {
                        setFilter({
                          ...filter,
                          vehicle_status: e.target.value
                        })
                      }}
                      defaultOption={true}
                    />
                  </Col>
                  <Col md={4} sm={12}>
                    <InputKeySelect
                      label={'Deal status'}
                      options={{
                        'available': 'Available',
                        'paid_reserve_fee': 'Paid reserve fee',
                        'paid_initial': 'Paid initial',
                        'order_sent': 'Order sent',
                      }}
                      value={filter.deal_status ?? ''}
                      onChange={(e) => {
                        setFilter({
                          ...filter,
                          deal_status: e.target.value
                        })
                      }}
                      defaultOption={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={12}>
                    <Button className="w-100" color="success" style={{ margin: 5 }} type="button" onClick={() => filterVehicle()}>Filter</Button>
                  </Col>
                  <Col md={6} sm={12}>
                    <Button className="w-100" color="warning" style={{ margin: 5 }} type="button" onClick={() => resetVehicle()}>Reset</Button>
                  </Col>
                </Row>
              </Container>
              <TableCustom
                loading={loading}
                items={items}
                // stickyContent={(
                //   !loading && items && items.length > 0 && <Row>
                //     <Col sm={12}>
                //       <Button className="w-100" color="success" style={{ margin: 5 }} type="button" onClick={() => submitItem()}>Save</Button>
                //     </Col>
                //   </Row>
                // )}
                classes={'custom-table-striped'}
                pagination={pagination}
                onChange={pageNumber => {
                  setPageNumber(pageNumber)
                  fetchData(pageNumber, token, filter)
                }
                }
                columns={[
                  {
                    dataField: "vehicle_status",
                    text: "Vehicle Status",
                    formatter: (rowContent, row) => {
                      return `${vehicleStatuses[row.vehicle_status] ?? ''}`
                    }
                  },
                  {
                    dataField: "stock_no",
                    style: {
                      "fontWeight": 800
                    },
                    text: "Stock No",
                    formatter: (rowContent, row) => {
                      return `${row.vehicle.stock_no}`
                    }
                  },
                  {
                    dataField: "vehicle",
                    text: "Vehicle",
                    formatter: (rowContent, row) => {
                      let vehicle = `${row.vehicle.vehicle_make.name} ${row.vehicle.vehicle_model.name}, ${row.vehicle.derivative}`;
                      return vehicle.length > 35 ? `${vehicle.substring(0, 35)}...` : vehicle;
                    }
                  },
                  {
                    dataField: "color",
                    text: "Color",
                    formatter: (rowContent, row) => {
                      return `${row.vehicle.colour}`
                    }
                  },
                  {
                    dataField: "mileage",
                    text: "Mileage"
                  },
                  {
                    dataField: "deal_status",
                    text: "Deal Status",
                    formatter: (rowContent, row) => {
                      return (
                        <InputKeySelect
                          label={false}
                          options={dealStatuses}
                          value={row.deal_status ?? ''}
                          style={inputStyle(250)}
                          formGroupStyle={formGroupStyle()}
                          onChange={(e) => {
                            updateItemsValue(row, 'deal_status', e.target.value)
                          }}
                          defaultOption={true}
                        // invalid={errors && errors.job_category ? true : false}
                        // errorMessage={errors.job_category}
                        />
                      )
                    }
                  },
                  {
                    dataField: "sold_to",
                    text: "Customer Name",
                    formatter: (rowContent, row) => {
                      return (
                        <InputCustom
                          label={false}
                          value={row.sold_to ?? ''}
                          style={inputStyle(100)}
                          formGroupStyle={formGroupStyle()}
                          onChange={(e) => {
                            updateItemsValue(row, 'sold_to', e.target.value)
                          }}
                        />
                      )
                    }
                  },
                  {
                    dataField: "collecting_on",
                    text: "Collecting Date"
                  },
                  {
                    dataField: "notes",
                    text: "Notes"
                  }
                ]}
              />
              {!loading && items && items.length > 0 && <Row>
                <Col sm={12}>
                  <Button className="w-100" color="success" style={{ margin: 5 }} type="button" onClick={() => submitItem()}>Save</Button>
                </Col>
              </Row>}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}