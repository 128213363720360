export const GET_PAYMENT_DETAILS_REQUEST = 'GET_PAYMENT_DETAILS_REQUEST';
export const GET_PAYMENT_DETAILS_SUCCESS = 'GET_PAYMENT_DETAILS_SUCCESS';
export const GET_PAYMENT_DETAILS_ERROR = 'GET_PAYMENT_DETAILS_ERROR';

export const CREATE_INVOICE_NOTE_REQUEST = 'CREATE_INVOICE_NOTE_REQUEST';
export const CREATE_INVOICE_NOTE_SUCCESS = 'CREATE_INVOICE_NOTE_SUCCESS';
export const CREATE_INVOICE_NOTE_ERROR = 'CREATE_INVOICE_NOTE_ERROR';

export const SEND_PAYMENT_INVOICE_EMAIL_REQUEST = 'SEND_PAYMENT_INVOICE_EMAIL_REQUEST';
export const SEND_PAYMENT_INVOICE_EMAIL_SUCCESS = 'SEND_PAYMENT_INVOICE_EMAIL_SUCCESS';
export const SEND_PAYMENT_INVOICE_EMAIL_ERROR = 'SEND_PAYMENT_INVOICE_EMAIL_ERROR';

export const GET_SALES_ACCOUNTS_REQUEST = 'GET_SALES_ACCOUNTS_REQUEST';
export const GET_SALES_ACCOUNTS_SUCCESS = 'GET_SALES_ACCOUNTS_SUCCESS';
export const GET_SALES_ACCOUNTS_ERROR = 'GET_SALES_ACCOUNTS_ERROR';

export const GET_TAXES_REQUEST = 'GET_TAXES_REQUEST';
export const GET_TAXES_SUCCESS = 'GET_TAXES_SUCCESS';
export const GET_TAXES_ERROR = 'GET_TAXES_ERROR';


export const UPDATE_INVOICE_REQUEST = 'UPDATE_INVOICE_REQUEST';
export const UPDATE_INVOICE_SUCCESS = 'UPDATE_INVOICE_SUCCESS';
export const UPDATE_INVOICE_ERROR = 'UPDATE_INVOICE_ERROR';

export const GET_BUSINESS_ACTIVITY_REQUEST = 'GET_BUSINESS_ACTIVITY_REQUEST';
export const GET_BUSINESS_ACTIVITY_SUCCESS = 'GET_BUSINESS_ACTIVITY_SUCCESS';
export const GET_BUSINESS_ACTIVITY_ERROR = 'GET_BUSINESS_ACTIVITY_ERROR';

export const GET_COMPANY_TYPE_REQUEST = 'GET_COMPANY_TYPE_REQUEST';
export const GET_COMPANY_TYPE_SUCCESS = 'GET_COMPANY_TYPE_SUCCESS';
export const GET_COMPANY_TYPE_ERROR = 'GET_COMPANY_TYPE_ERROR';

export const GET_DEAL_STATISTICS_REQUEST = 'GET_DEAL_STATISTICS_REQUEST';
export const GET_DEAL_STATISTICS_SUCCESS = 'GET_DEAL_STATISTICS_SUCCESS';
export const GET_DEAL_STATISTICS_ERROR = 'GET_DEAL_STATISTICS_ERROR';

export const UPLOAD_DEALS_TO_GOOGLE_SHEETS_REQUEST = 'UPLOAD_DEALS_TO_GOOGLE_SHEETS_REQUEST';
export const UPLOAD_DEALS_TO_GOOGLE_SHEETS_SUCCESS = 'UPLOAD_DEALS_TO_GOOGLE_SHEETS_SUCCESS';
export const UPLOAD_DEALS_TO_GOOGLE_SHEETS_ERROR = 'UPLOAD_DEALS_TO_GOOGLE_SHEETS_ERROR';