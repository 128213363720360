import React, { useEffect, useState, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as emailTemplateActions from '../../../../redux/Email/actions';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useDropzone } from "react-dropzone";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Row,
} from 'reactstrap';
import InputCustom from '../../components/InputCustom';

import * as constants from '../../../../constants';
import CardsHeader from '../../../../layouts/Headers/CardsHeader';
import usePrevious from '../../../../hooks/useprevious';
import CreatableSelect from 'react-select/creatable';
import { pluck } from '../../../../utils';
import Select from 'react-select';
import axios from 'config/axios';
import { getDocument, deleteDocument, uploadDocuments } from '@api/document.api'
import useNotify from './../../../../hooks/notify';
import NotificationAlert from 'react-notification-alert';

import EditorComponent from "@pages/components/template";
import {parseToWord} from "../../../../constants";


const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out"
};

const activeStyle = {
  borderColor: "#2196f3"
};

const acceptStyle = {
  borderColor: "#00e676"
};

const rejectStyle = {
  borderColor: "#ff1744"
};

function CreateForm() {
  const requireFields = {
    to: 'To field is required',
    subject: 'Subject field is required',
    message: 'Content field is required',
  };

  const EmailFields = {
    to: 'to fields should be emails',
    cc: 'cc fields should be emails',
    bcc: 'bcc fields should be emails',
  };

  const { ref, notify } = useNotify();

  /*
   * redux variables
   * */
  const emailTemplateState = useSelector(
    (state) => state.emailTemplateState,
    shallowEqual
  );

  const dispatch = useDispatch();
  const { search } = useLocation();
  let params = queryString.parse(search)
  const [editorState, setEditorState] = useState(null);
  const sendEmailPrev = usePrevious(emailTemplateState.sendEmailSuccess);
  const history = useHistory();
  const prevResourceEmailSuccess = usePrevious(emailTemplateState.getResourceEmailSuccess)
  const [sendEmailData, setSendEmailData] = useState({});
  const [resource,setResource] = useState('')
  const [fileLists, setFileLists] = useState([]);
  const [sendEmailErrors, setSendEmailErrors] = useState({
    to: { email: false, required: false },
    subject: { required: false },
    message: { required: false },
    bcc: { email: false },
    cc: { email: false },
  });

  const removeDocument = async (uuid) => {
    await deleteDocument(uuid).then(res => {
      if (res.success) {
        let items = fileLists.filter(file => file.newUuid !== uuid);
        setFileLists(items);
        notify("File successfully deleted!")
      }
    })
  }

  const onDrop = async (acceptedFiles) => {
    await uploadDocuments(acceptedFiles)
      .then((res) => {
        let files = res.filter(item => {
          return !fileLists.some(file => file.newUuid === item.newUuid)
        }).map(item => {
          return {
            ...item
          }
        });
        setFileLists(prev => [...prev, ...files]);
        notify("File successfully uploaded!")
      })
      .catch(err => {
        console.log(err)
      })
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  );


  useEffect(() => {
    setResource(params.resource)
    dispatch({
      type: emailTemplateActions.GET_EMAIL_DETAILS_BY_RESOURCE_REQUEST,
      payload: {
        id: params.id,
        resource: params.resource,
      }
    });
    setSendEmailData({
      ...sendEmailData,
      resource_id: params.id,
      resource_type: params.resource,
    });
  }, [params.id, params.resource]);
  useEffect(() => {
    if (resource) {
      dispatch({
        type: emailTemplateActions.GET_EMAIL_TEMPLATES_REQUEST,
        data: {
          forSelection: true,
          mail_type: parseToWord(resource)
        }
      });
    }
  }, [resource]);

  useEffect(() => {
    if (
      emailTemplateState.sendEmailSuccess !== sendEmailPrev &&
      emailTemplateState.sendEmailSuccess === true &&
      sendEmailPrev !== undefined
    ) {
      // notify('Email Sent Successfully');
      history.goBack();
    }
  }, [emailTemplateState.sendEmailSuccess]);

  useEffect(() => {
    if (
        emailTemplateState.getResourceEmailSuccess && !prevResourceEmailSuccess
    ) {
     if (emailTemplateState.resourceForEmail.hasOwnProperty('to') && emailTemplateState.resourceForEmail.to) {
       handleInputChange('to', [emailTemplateState.resourceForEmail.to]);
     }
    }
  }, [emailTemplateState.getResourceEmailSuccess]);

  function handleInputChange(field, value) {
    setSendEmailData({ ...sendEmailData, [field]: value });
  }

  function handleSelectChange(field, emails) {
    if (emails) {
      emails = pluck(emails, 'value');
    }

    handleInputChange(field, emails);
  }

  const validateEmails = (emails) => {
    let isValid = true;
    const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    emails.forEach((email) => {
      if (!EMAIL_PATTERN.test(email)) {
        isValid = false;
      }
    });
    return isValid;
  };

  function validateInputs() {
    let errorFree = true;
    const sendEmailErrorsCopy = { ...sendEmailErrors };

    Object.keys(requireFields).forEach((field) => {
      if (!sendEmailData[field] || sendEmailData[field]?.length === 0) {
        errorFree = false;
        sendEmailErrorsCopy[field]['required'] = requireFields[field];
      } else {
        sendEmailErrorsCopy[field]['required'] = false;
      }
    });

    Object.keys(EmailFields).forEach((field) => {
      if (sendEmailData[field] && !validateEmails(sendEmailData[field])) {
        errorFree = false;

        sendEmailErrorsCopy[field]['email'] = EmailFields[field];
      } else {
        sendEmailErrorsCopy[field]['email'] = false;
      }
    });

    setSendEmailErrors(sendEmailErrorsCopy);

    return errorFree;
  }

  function sendEmail() {
    if (validateInputs()) {
      dispatch({
        type: emailTemplateActions.SEND_EMAIL_REQUEST,
        data: {...sendEmailData,
          fileLists,
          resource_id: params.id,
          resource_type: params.resource,
        },
      });
    }
  }

  const handleTemplateChange = async ({ id }) => {
    const template = emailTemplateState.templates.find((item) => id === item.id);
    let requestParam = null;
    if (resource === 'deal') {
      requestParam = `deal_id=${params.id}`
    } else  {
      requestParam = `customer_id=${params.id}`
    }
    if (template) {
      let response = await axios({
        method: 'GET',
        url: `/settings/emails/${id}?${requestParam}`
      }).then(response => response.data);

      let messageContent = template.content;
      if (response.success === true) {
        messageContent = response.data.content;
      }

      setSendEmailData({
        ...sendEmailData,
        subject: template.name,
        message: messageContent,
      });
      setEditorState(messageContent)
    }
  };

  const onEditorStateChange = (content) => {
    handleInputChange('message', content);
  };

  return (
    <>
      <CardsHeader name="Emails" parentName="Email" currentName="Send" />
      <Container className="mt--5 admin-main-body" fluid>
        <NotificationAlert ref={ref} />
        <Card>
          <CardHeader>
            <Row>
              <Col xs={8}>
                <h1 className="mb-0">Send Email</h1>
              </Col>
            </Row>
          </CardHeader>

          <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
            <FormGroup>
              <label className="form-control-label">Recipient(s) To</label>
              <CreatableSelect
                isMulti
                value={
                  sendEmailData.to && sendEmailData.to.map(item => {
                    return {
                      value: item,
                      label: item
                    }
                  })}
                onChange={(data) => {
                  handleSelectChange('to', data);
                }}
              />
              <div
                className="invalid-feedback"
                style={{
                  display:
                    sendEmailErrors.to.required || sendEmailErrors.to.email
                      ? 'block'
                      : 'none',
                }}
              >
                {sendEmailErrors.to.required || sendEmailErrors.to.email}
              </div>
            </FormGroup>
            <FormGroup>
              <label className="form-control-label">Recipient(s) Cc</label>
              <CreatableSelect
                isMulti
                onChange={(data) => {
                  handleSelectChange('cc', data);
                }}
              />
              <div
                className="invalid-feedback"
                style={{
                  display: sendEmailErrors.cc.email ? 'block' : 'none',
                }}
              >
                {sendEmailErrors.cc.email}
              </div>
            </FormGroup>
            <FormGroup>
              <label className="form-control-label">Recipient(s) Bcc</label>
              <CreatableSelect
                isMulti
                onChange={(data) => {
                  handleSelectChange('bcc', data);
                }}
              />
              <div
                className="invalid-feedback"
                style={{
                  display: sendEmailErrors.bcc.email ? 'block' : 'none',
                }}
              >
                {sendEmailErrors.bcc.email}
              </div>
            </FormGroup>
            {/*emailTemplateState.templates*/}

            <FormGroup>
              <label className="form-control-label">
                Choose from Templates
              </label>
              <Select
                options={emailTemplateState.templates}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                onChange={(value) => handleTemplateChange(value)}
              />
            </FormGroup>
            <InputCustom
              label={`Subject`}
              value={sendEmailData.subject || ''}
              onChange={(e) => handleInputChange('subject', e.target.value)}
              invalid={!!(sendEmailErrors && sendEmailErrors.subject.required)}
              errorMessage={
                sendEmailErrors?.subject ? sendEmailErrors.subject.required : ''
              }
            />

            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="example3cols2Input"
              >
                Content
              </label>

              <EditorComponent
                  editorStateInitial={editorState}
                  onEditorStateChange={onEditorStateChange}
              />

              <div
                className="invalid-feedback"
                style={{
                  display: sendEmailErrors.message.required ? 'block' : 'none',
                }}
              >
                {sendEmailErrors.message.required}
              </div>
            </FormGroup>
            <Row className="mb-4 mt-2">
              <div className="container">
                <label className="form-control-label">Attachments</label>

                <div {...getRootProps({ style })}>
                  <input {...getInputProps()} />
                  <p>Select or drop the documents, then match each of them to the right type</p>
                </div>
                <aside>
                  {fileLists.map((item, index) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          borderBottom: '1px solid #CCC',
                          alignItems: 'center',
                          padding: 20,
                        }}
                        key={index}
                      >
                        <Col md={3}>
                          <span>{item.originalName}</span>
                        </Col>
                        <Col md={3}>
                          <a className="btn btn-sm btn-default text-white" onClick={() => getDocument(item.newUuid, item.originalName)}>{`View`}</a>
                          <a className="btn btn-sm btn-primary text-white" onClick={() => removeDocument(item.newUuid)}>{`Remove`}</a>
                        </Col>
                      </div>
                    )
                  })}
                </aside>
              </div>
            </Row>
            <Button
              className="w-100"
              color="success"
              type="button"
              onClick={sendEmail}
            >
              Send
            </Button>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}
export default CreateForm;
