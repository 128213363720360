import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import NotificationAlert from 'react-notification-alert'
import axios from 'axios'
import Cookies from 'js-cookie'

import * as constants from 'constants/index.js'
import CardsHeader from 'layouts/Headers/CardsHeader.js'

import TableCustom from 'views/pages/components/TableCustom'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from 'reactstrap'

export default function Notifications() {
  const notificationAlert = useRef(null)
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])
  const [activeStatus, setActiveStatus] = useState([])

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    }
    notificationAlert.current.notificationAlert(options)
  }

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/all-notifications?new=1&category=vehicles`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data
        
        setItems(data.data.items)
        setActiveStatus(data.data.activeStatus)

        setTimeout(() => {
          setLoading(false)
        }, 300);
      } catch (error) {
        setLoading(false)
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const editDeal = (link) => {
    history.push({
      pathname: link
    })
  }

  return (
    <>
      <CardsHeader name="Vehicles" parentName="Notifications Management" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Notifications</h1>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <TableCustom 
                  loading={loading}
                  items={items}
                  columns={[
                    {
                      dataField: "message",
                      text: "Message",
                      sort: true
                    },
                    {
                      dataField: "state",
                      text: "Is Active",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return (
                          <span className={`badge badge-${row.state ? 'success' : 'dark'}`}>{activeStatus[row.state? 1: 0]}</span>
                        )
                      }
                    },
                    {
                      dataField: "created_at",
                      text: "Created at",
                      sort: true
                    },
                    {
                      dataField: "action",
                      text: "Edit",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return (
                          <Button className="w-100" color="success" onClick={() => editDeal(row.link)}>Edit</Button>
                        )
                      }
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}