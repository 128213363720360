import React, {useEffect, useState} from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'
import SignatureCanvas from 'react-signature-canvas'
import * as constants from 'constants/index.js'

import {
    Container,
    Row,
    Col,
} from 'reactstrap'
import {useLocation} from "react-router";
import {
    LoaderContainer, override,
    ReportContainer, SignContainer,
    StatusWrapper,
    TaskDetailsContainer,
    Text,
    TextContainer
} from "@pages/reusable-components/styled-components";
import {ClipLoader} from "react-spinners";
import {parseToWord} from "constants/index.js";
import InspectionForm from "@pages/fleet/pages/components/InspectionForm";



export default function DeliveryDetails() {
    const location = useLocation()
    const [loading, setLoading] = useState(true)
    const [taskDetails, setTaskDetails] = useState({})
    const [vehicle, setVehicle] = useState({})

    useEffect(() => {
        let id = location.search
        let numb = id.match(/\d/g);
        id = numb.join("");
        const grs_token = Cookies.get('grs_token')
        fetchJobs(id, grs_token)
    }, [])


    const fetchJobs = async (id, token) => {
        try {
            const response = await axios({
                method: 'GET',
                url: `${constants.FLEET_URL}/job-booker/delivery-task-details/${id}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            if (response?.data.data) {
                setTaskDetails(response?.data.data)
                setVehicle(response?.data.data.vehicle[0])
                setLoading(false)
            }

        } catch (error) {

        }
    }

    // const checkData = (data) => {
    //     let changedData = null
    //     changedData = Object.keys(data).find(key => {
    //         if (key === 'customer_name') {
    //             return data[key] !== jobData['full_name']
    //         } else return (data[key].toString() !== jobData[key].toString() )
    //     })
    //     let isDisabled = []
    //     setSendingData(data)
    //     isDisabled = Object.values(data).filter(item => {
    //         return (Array.isArray(item) && item.length === 0) || item === ''
    //     })
    //     setDisabled(!!isDisabled.length || !changedData)
    // }
    // const sendData = async () => {
    //     let tempData = sendingData
    //     tempData.customer_id = jobData.customer_id
    //     tempData.vehicle_id = jobData.vehicle_id
    //     tempData.id = id
    //     setLoading(true)
    //     const response = await axios({
    //         method: 'POST',
    //         url: `${constants.FLEET_URL}/job-booker/update-delivery-task`,
    //         data: tempData,
    //         headers: {
    //             Authorization: 'Bearer ' + token
    //         }
    //     })
    //     if (response.status === 200) {
    //         history.push(`/admin/schedule`)
    //     }
    //     // setLoading(false)
    // }
    return (

        loading ?
            <LoaderContainer>
                <ClipLoader
                    css={override}
                    size={40}
                    color={`#7B61E4`}
                    loading={loading}
                />
            </LoaderContainer> :
            <Container>
                <TaskDetailsContainer>
                    <Text size='22px' color='#ec6409'>{taskDetails.title}</Text>
                </TaskDetailsContainer>
                <ReportContainer>
                    <img width={'219px'} height={'165px'}
                         src={vehicle.featured_image}
                         alt=""/>
                    <TaskDetailsContainer>
                        <TextContainer>
                            <Text>No.</Text>
                            <img height='25px' width='25px'
                                 src={require(`assets/images/waves.png`)}
                                 alt=""/>
                            <img height='25px' width='25px'
                                 src={require(`assets/images/date_range.png`)}
                                 alt=""/>
                            <img height='25px' width='25px'
                                 src={require(`assets/images/taskEdit/person.png`)}
                                 alt=""/>
                            <img height='25px' width='25px'
                                 src={require(`assets/images/taskEdit/status_grey.png`)}
                                 alt=""/>
                        </TextContainer>
                        <TextContainer>
                            <Text bold>Reg.number:</Text>
                            <Text bold>Colour:</Text>
                            <Text bold>Execution Time:</Text>
                            <Text bold>Driver:</Text>
                            <Text bold>Status:</Text>
                        </TextContainer>
                        <TextContainer>
                            <Text>{vehicle.stock_no}</Text>
                            <Text>{vehicle.colour}</Text>
                            <Text>{taskDetails.execution_date}</Text>
                            <Text>{taskDetails.driver?.name}</Text>
                            <StatusWrapper width={'fit-content'}
                                           status={taskDetails.status}>{parseToWord(taskDetails.status)}</StatusWrapper>
                        </TextContainer>

                    </TaskDetailsContainer>
                </ReportContainer>
                <TaskDetailsContainer width='30%'>
                    <img height='25px' width='25px'
                         src={require(`assets/images/taskEdit/person.png`)} alt=""/>
                    <TextContainer width='100%'>
                        <Text bold>Customer Info:</Text>
                        <Text>{taskDetails.customer?.full_name || taskDetails.customer_name + ', '
                        + (taskDetails.customer?.address || taskDetails.customer_address) +
                        ', ' + (taskDetails.customer?.postal_code || '')}</Text>
                    </TextContainer>
                </TaskDetailsContainer>
                <TaskDetailsContainer width='30%'>
                    <img height='25px' width='25px'
                         src={require(`assets/images/taskEdit/edit.png`)} alt=""/>
                    <TextContainer width='100%'>
                        <Text bold>Description:</Text>
                        <Text>{taskDetails.description}</Text>
                    </TextContainer>
                </TaskDetailsContainer>

                <TaskDetailsContainer>
                    <Text bold>Vehicle Inspection Form</Text>
                </TaskDetailsContainer>
                <Row>
                    {taskDetails.all_tasks.map(task => {
                        return (<Col md={5}>
                                <InspectionForm task={task}/>
                            </Col>
                        )

                    })}
                </Row>
                <TaskDetailsContainer width='30%'>
                    <img height='25px' width='25px'
                         src={require(`assets/images/taskEdit/edit.png`)} alt=""/>
                    <TextContainer width='100%'>
                        <Text bold>Notes:</Text>
                        <Text>{taskDetails.note}</Text>
                    </TextContainer>
                </TaskDetailsContainer>
                <TaskDetailsContainer>
                    <img height='25px' width='25px'
                         src={require(`assets/images/taskEdit/edit.png`)} alt=""/>
                    <TextContainer>
                        <Text bold>Customer E-sign:</Text>
                        <SignContainer>
                            <SignatureCanvas
                                canvasProps={{width: 500, height: 200, className: 'sigCanvas'}}/>
                        </SignContainer>
                    </TextContainer>
                </TaskDetailsContainer>
                <TaskDetailsContainer>
                    <img height='25px' width='25px'
                         src={require(`assets/images/taskEdit/attachment.png`)} alt=""/>
                    <TextContainer>
                        <Text bold>Receipt Photo:</Text>
                        <Text>There is no receipt</Text>
                    </TextContainer>
                </TaskDetailsContainer>
            </Container>

    )
}