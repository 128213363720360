import { put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from './actions';
import axios from '../../config/axios';
import * as constants from '../../constants';

function* downloadReport() {
  try {
    const response = yield axios.get(
      `${
        constants.API_URL
      }/report/fleet-management/export?version=${Date.now()}`,
      { responseType: 'arraybuffer' }
    );
    if (response?.status === 200) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      let filename = '';
      let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      let matches = filenameRegex.exec(response.headers['content-disposition']);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
      link.setAttribute('download', `${filename}.xlsx`);
      document.body.appendChild(link);
      link.click();
      yield put({ type: actionTypes.DOWNLOAD_REPORT_SUCCESS });
    }
  } catch (error) {
    let errorMessage = error.response.statusText;
    yield put({ type: actionTypes.DOWNLOAD_REPORT_ERROR, errorMessage });
  }
}

function* getVehicleStats(action) {
  try {
    let params = action.data;
    const response = yield axios.get(`${constants.API_URL}/report/vehicle/statistics`, {
      params: params
    });
    if (response?.status === 200) {
      const data = response?.data?.data;
      yield put({
        type: actionTypes.GET_VEHICLE_STATS_SUCCESS,
        data
      });
    }
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.GET_VEHICLE_STATS_ERROR, payload });
  }
}

export default function* watcherSaga() {
  yield takeLatest(actionTypes.DOWNLOAD_REPORT_REQUEST, downloadReport);
  yield takeLatest(actionTypes.GET_VEHICLE_STATS_REQUEST, getVehicleStats);
}
