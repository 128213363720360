import React from 'react';
import Pagination from 'react-js-pagination';

import { css } from '@emotion/core';
// import useStickyHeader from 'views/pages/components/useStickyHeader';

import { ClipLoader } from 'react-spinners';
import { Row, Col } from 'reactstrap';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: blue;
`;

export default function TableCustom({ loading = true, sticky = false,delay = true, noOverflow = false, items = [], columns = [], pagination, onChange, classes = null, tableHeaderStyle = null, className = null }) {
  return (
    <React.Fragment>
      <div className="py-4">
        {!loading && delay && items.length > 0 ? (
          <div className={"table-responsive " + (noOverflow ? " overflow-y-hidden" : '')} style={{ maxHeight: noOverflow ? '' : 'calc(100vh - 250px)' }}>
            <table className={`table table-bordered ${classes}`} /*ref={tableRef}*/>
              <thead className={(sticky ? ' position-sticky' : '')} style={{ top: sticky ? '-1px' : '' }}>
                <tr className={'reports-table-header header-white-text'}>
                  {columns &&
                    columns.length > 0 &&
                    columns.map((column, index) => {
                      return (
                        <th
                          tabIndex="0"
                          key={index}
                          style={column.headerStyle ? column.headerStyle : {}}
                          className={tableHeaderStyle}
                        >
                          {column.text}
                        </th>
                      );
                    })}
                </tr>
              </thead>
              <tbody>
                {items &&
                  items.length > 0 &&
                  items.map((item, index) => {
                    return (
                      <tr key={index}>
                        {columns &&
                          columns.length > 0 &&
                          columns.map((column, index2) => {
                            return (
                              <td
                                key={index2}
                                style={column.style ? column.style : {}}
                              >
                                {column.formatter ? column.formatter('', item) : item[column.dataField]}
                              </td>
                            );
                          })}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        ) : (
          <ClipLoader
            css={override}
            size={40}
            color={`#7B61E4`}
            loading={loading}
          />)}
      </div>
      {pagination && items.length > 0 &&
        <Row className="react-bootstrap-table-pagination">
          <Col lg={6}>
            <p className="text-sm text-black">
              {`Showing rows ${(pagination.current_page - 1) * pagination.per_page + 1} to ${pagination.per_page * pagination.current_page > pagination.total ? pagination.total : pagination.per_page * pagination.current_page} of ${pagination.total}`}
            </p>
          </Col>
          <Col lg={6} className="react-bootstrap-table-pagination-list">
            <Pagination
              hideDisabled
              activePage={pagination.current_page}
              totalItemsCount={pagination.total}
              itemsCountPerPage={pagination.per_page}
              onChange={onChange}
              itemClass="page-item"
              linkClass="page-link"
              innerClass="pagination react-bootstrap-table-page-btns-ul"
            />
          </Col>
        </Row>}
    </React.Fragment>
  );
}