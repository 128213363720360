import React, { useEffect, useState, useRef } from 'react';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';

export default function Integration() {
  const notificationAlert = useRef(null);

  const [dealer, setDealer] = useState('');
  const [appUrl, setAppUrl] = useState('');
  const [appFrontend, setAppFrontend] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/dealer/integration`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })

        const data = response.data
        setDealer(data.dealer)
        setAppUrl(data.appUrl)
        setAppFrontend(data.appFrontend)
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  return (
    <>
      <CardsHeader name="3rd Party integration steps" parentName="3rd Party integration steps" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="6">
            <Card>
              <CardHeader>
                <h1 className="title mb-0">Lead Form:</h1>
              </CardHeader>
              <CardBody>
                <p style={{ marginTop: '20px', fontWeight: 500 }}><b>Step One:</b><br />
                  Place this element anywhere in your page where you want the Lead form to show:
                </p>
                  &#x3C;div class=&#x22;col-25&#x22; id=&#x22;flexiDealersWidget&#x22;&#x3E;
                  &#x3C;/div&#x3E;
                <p style={{ marginTop: '20px', fontWeight: 500 }}><b>Step Two:</b><br />
                  Place the below script at the bottom of the page just above the page just above your closing body tag:
                </p>
                  &#x3C;script src=&#x22;{appUrl}js/{appFrontend}/widget/flexi-widget.js&#x22;&#x3E;&#x3C;/script&#x3E;
                <p style={{ marginTop: '20px', fontWeight: 500 }}><b>Step Three:</b><br />
                  Place this script this AFTER the script above but still before the closing body tag:
                </p><br />
                  &#x3C;script type=&#x22;text/javascript&#x22;&#x3E;
                  window.FLEXI_DOMAIN = &#x27;{appUrl}&#x27;;
                  var widgetOptions = &#x7B;
                  source: &#x27;flexi-dealers-widget&#x27;,
                  type: &#x27;short&#x27;,
                  flexi: &#x27;{dealer.uuid}&#x27;,
                  onWidgetReady: function () &#x7B;
                  flexiDealerEmbedWidget.show();
                  &#x7D;,
                  embedMode: &#x27;inline&#x27;,
                  &#x7D;;
                  widgetOptions[&#x27;callback&#x27;] = function (d) &#x7B;
                  &#x7D;;
                  flexiDealerEmbedWidget.init(widgetOptions);
                  &#x3C;/script&#x3E;
              </CardBody>
            </Card>
          </Col>
          <Col xl="6">
            <Card>
              <CardHeader>
                <h1 className="title mb-0">Application Form:</h1>
              </CardHeader>
              <CardBody>
                <p style={{ marginTop: '20px', fontWeight: 500 }}><b>Step One:</b><br />
                  Place this element anywhere in your page where you want the Lead form to show:
                </p>
                  &#x3C;div class=&#x22;col-25&#x22; id=&#x22;flexiDealersWidget&#x22;&#x3E;
                  &#x3C;/div&#x3E;
                <p style={{ marginTop: '20px', fontWeight: 500 }}><b>Step Two:</b><br />
                  Place the below script at the bottom of the page just above the page just above your closing body tag:
                </p>
                  &#x3C;script src=&#x22;{appUrl}js/{appFrontend}/widget/flexi-widget.js&#x22;&#x3E;&#x3C;/script&#x3E;
                <p style={{ marginTop: '20px', fontWeight: 500 }}><b>Step Three:</b><br />
                  Place this script AFTER the script above but still before the closing body tag:
                </p><br />
                  &#x3C;script type=&#x22;text/javascript&#x22;&#x3E;
                  window.FLEXI_DOMAIN = &#x27;{appUrl}&#x27;;
                  var widgetOptions = &#x7B;
                  source: &#x27;flexi-dealers-widget&#x27;,
                  type: &#x27;long&#x27;,
                  flexi: &#x27;{dealer.uuid}&#x27;,
                  onWidgetReady: function () &#x7B;
                  flexiDealerEmbedWidget.show();
                  &#x7D;,
                  embedMode: &#x27;inline&#x27;,
                  &#x7D;;
                  widgetOptions[&#x27;callback&#x27;] = function (d) &#x7B;
                  &#x7D;;
                  flexiDealerEmbedWidget.init(widgetOptions);
                  &#x3C;/script&#x3E;
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}