import React, {useEffect, useState} from 'react';

import { EditorState, ContentState,convertToRaw } from 'draft-js';

import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export default  function  EditorComponent({editorStateInitial = null,onEditorStateChange}) {

    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const change = (state) => {
        setEditorState(state)
        const content = draftToHtml(convertToRaw(state.getCurrentContent()));
        onEditorStateChange(content);
    };
    useEffect(()=>{
        if (editorStateInitial) {
            const contentBlock = htmlToDraft(editorStateInitial);

            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                setEditorState(EditorState.createWithContent(contentState));
            }
        }
    },[editorStateInitial])
    return <>

        <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={change}
        />
    </>
}