import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import * as constants from 'constants/index.js';
import actions from 'actions/index';
import * as actionsTypes from '../../../redux/Vehicle/actions';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import TableCustom from 'views/pages/components/TableCustom';
import {
  SelectWithoutInput,
} from 'views/pages/components/InputSelect';

import {
  update,
  deletee,
  hasPermission,
} from 'views/policies/VehiclePolicy.js';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Modal,
} from 'reactstrap';
import CustomSearch from "@pages/components/CustomSearch";
import { parseToKey, parseToWord, vehicleStatuses, activeStatuses } from "constants/index.js";
import { ButtonContainer } from "@pages/reusable-components/styled-components";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";
import usePrevious from "../../../hooks/useprevious";

export default function Vehicle() {
  const notificationAlert = useRef(null);
  const history = useHistory();

  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.getState).permissions;
  const authUserTeam = useSelector((state) => state.getState).authUserTeam;

  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [delay, setDelay] = useState(false);
  const [clearInput, setClearInput] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [filter, setFilter] = useState('');
  const [siteCode] = useState('');
  const [items, setItems] = useState('');



  const [deleteModal, setDeleteModal] = useState({
    show: false,
    header: '',
    onClick: null
  })


  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)
    dispatch(actions.getProfileData(grs_token));

    const source = axios.CancelToken.source()

    let savedPageNumber = 1
    let savedFilter = ''

    if (localStorage.getItem('grms_vehicle_page_number')) {
      savedPageNumber = localStorage.getItem('grms_vehicle_page_number')
    }
    if (localStorage.getItem('grms_vehicle_search_filter')) {
      savedFilter = JSON.parse(localStorage.getItem('grms_vehicle_search_filter'))
      setFilter(savedFilter)
    }

    fetchData(source, savedPageNumber, grs_token, savedFilter)
  }, [])

  const fetchData = async (source, pageNumber = 1, token, filter) => {
    localStorage.setItem('grms_vehicle_page_number', pageNumber)
    localStorage.setItem('grms_vehicle_search_filter', JSON.stringify(filter))


    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/vehicle`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ...filter,
          page: pageNumber,
        },
        cancelToken: source.token,
      })
      console.log('response :: ', response.data)
      const data = response.data

      setVehicles(data.data)
      setItems(data.meta)

      setTimeout(() => {
        setDelay(true)
        setLoading(false)
      }, 500);
    } catch (error) {
      setLoading(false)
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
      }
    }
  }

  const addVehicle = () => {
    history.push({
      pathname: `/admin/vehicle/create`
    })
  }

  const filterVehicle = (data) => {
    data.search = data && data.search ? data.search : ''
    const source = axios.CancelToken.source()
    fetchData(source, 1, token, data)
  }

  const selectFilter = (key, val) => {
    let tempData = {
      ...filter,
      [key]: val
    }
    setFilter(tempData)
    filterVehicle(tempData)
  }
  const resetVehicle = () => {
    setFilter('')
    setClearInput(true)
    const source = axios.CancelToken.source()
    localStorage.removeItem('grms_vehicle_page_number')
    localStorage.removeItem('grms_vehicle_search_filter')
    fetchData(source, 1, token, '')
  }

  const editVehicle = (row) => {
    history.push({
      pathname: `/admin/vehicle/${row.id}/edit`
    })
  }

  const deleteVehicle = (vehicleId) => {
    axios({
      method: 'DELETE',
      url: `${constants.API_URL}/vehicle/${vehicleId}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
      .then(response => {
        setLoading(true)
        setDeleteModal({
          ...deleteModal,
          show: false
        })
        console.log('res :: ', response.data)

        setTimeout(() => {
          setLoading(false);
          const grs_token = Cookies.get('grs_token')
          setToken(grs_token)
          dispatch(actions.getProfileData(grs_token));

          const source = axios.CancelToken.source()

          let savedPageNumber = 1
          let savedFilter = ''

          if (localStorage.getItem('grms_vehicle_page_number')) {
            savedPageNumber = localStorage.getItem('grms_vehicle_page_number')
          }
          if (localStorage.getItem('grms_vehicle_search_filter')) {
            savedFilter = JSON.parse(localStorage.getItem('grms_vehicle_search_filter'))
            setFilter(savedFilter)
          }
          fetchData(source, savedPageNumber, grs_token, savedFilter)
        }, 1000)
      })
      .catch(error => {
        setDeleteModal({
          ...deleteModal,
          show: false
        })
        console.error('error :: ', error)
        if (error.response && error.response.data && error.response.data.message) {
          notify('warning', 'Warning', error.response.data.message)
          return
        }
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      })
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className=" admin-main-body" fluid >
        <Row className={'mt-3 mb-3'}>
          <Col lg="6" sm="10" xs="7">
            <h6 className="h2 d-inline-block mb-0">
              Vehicle Management
            </h6>{" "}

          </Col>

        </Row>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Vehicles</h1>
                    <p className="text-sm mb-0">Manage all your vehicles here.</p>
                  </Col>
                  <Col xs={4} className="text-right">
                    <ButtonContainer onClick={() => addVehicle()}>
                      <i className="fa fa-plus" aria-hidden="true"></i>
                      Add vehicle</ButtonContainer>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row className={'align-items-center'}>
                  <Col md={2}>
                    <CustomSearch
                      clear={clearInput}
                      setClear={() => setClearInput(false)}
                      defaultValue={filter['search']}
                      onChange={(e) => selectFilter('search', e.target.value)}
                      placeholder={'Search'}
                    />
                  </Col>
                  <Col md={3}>
                    <SelectWithoutInput
                      showClear
                      label={filter && filter.vehicle_status ? parseToWord(filter.vehicle_status) : `Vehicle Status`}
                      onSelect={(e) => {
                        selectFilter('vehicle_status', parseToKey(e))
                      }}
                      options={Object.values(vehicleStatuses)}
                      defaultOption={true}
                      imageLink={'group-p.png'}
                    />
                  </Col>
                  <Col md={3} className={'d-flex align-items-center ml-3 mr-3'}>
                    <ButtonContainer
                      onClick={() => {
                        resetVehicle()
                      }}>Clear</ButtonContainer>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <TableCustom
                  loading={loading}
                  delay={delay}
                  noOverflow
                  items={vehicles}
                  keyField={`id`}
                  searchField={false}
                  pagination={items}
                  onChange={(pageNumber) => fetchData(axios.CancelToken.source(), pageNumber, token, filter)}
                  columns={siteCode === 'Uk' ? [
                    {
                      dataField: "vehicle",
                      text: "Vehicle",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return `${row.vehicle_make.name} ${row.vehicle_model.name}`
                      }
                    },
                    {
                      dataField: "stock_no",
                      text: "Stock No",
                      sort: true
                    },
                    {
                      dataField: "active",
                      text: "Active",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return activeStatuses[row.enabled]
                      }
                    },
                    {
                      dataField: "status",
                      text: "Status",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return vehicleStatuses[row.status]
                      }
                    },
                    {
                      dataField: "",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <>

                            {(hasPermission(permissions, 'access-vehicles')
                              || hasPermission(permissions, 'delete-vehicles')) &&
                              <>
                                {update(permissions, authUserTeam, row) &&
                                <IcoMoon
                                    className={'cursor-pointer'}
                                    iconSet={IconSet}
                                    icon={'edit'}
                                    size={24}
                                    onClick={() => editVehicle(row)}
                                />
                                }

                                {deletee(permissions, authUserTeam, row) &&
                                <IcoMoon
                                    className={'cursor-pointer'}
                                    iconSet={IconSet}
                                    icon={'delete_outline'}
                                    size={24}
                                    onClick={e => setDeleteModal({
                                      ...deleteModal,
                                      show: true,
                                      header: 'Delete vehicle',
                                      onClick: () => deleteVehicle(row.id)
                                    })}
                                />
                                }

                              </>
                            }

                          </>
                        )
                      }
                    }
                  ] : [
                    {
                      dataField: "vehicle",
                      text: "Vehicle",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return `${row.vehicle_make.name} ${row.vehicle_model.name}`
                      }
                    },
                    {
                      dataField: "stock_no",
                      text: "Stock No",
                      sort: true
                    },
                    {
                      dataField: "active",
                      text: "Active",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return activeStatuses[row.enabled]
                      }
                    },
                    {
                      dataField: "status",
                      text: "Status",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return vehicleStatuses[row.status]
                      }
                    },
                    {
                      dataField: "",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            {(hasPermission(permissions, 'access-vehicles')
                              || hasPermission(permissions, 'delete-vehicles'))
                              && <>
                                {update(permissions, authUserTeam, row)
                                  &&
                                <IcoMoon
                                    className={'cursor-pointer'}
                                    iconSet={IconSet}
                                    icon={'edit'}
                                    size={24}
                                    onClick={() => editVehicle(row)}
                                />
                                }
                                {deletee(permissions, authUserTeam, row) &&
                                <IcoMoon
                                    className={'cursor-pointer'}
                                    iconSet={IconSet}
                                    icon={'delete_outline'}
                                    size={24}
                                    onClick={() => setDeleteModal({
                                      ...deleteModal,
                                      show: true,
                                      header: 'Delete vehicle',
                                      onClick: () => deleteVehicle(row.id)
                                    })}
                                />
                                }
                              </>}
                          </>
                        )
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={deleteModal.show}
          toggle={() => setDeleteModal({
            ...deleteModal,
            show: false
          })}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title main-color">{deleteModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setDeleteModal({
                ...deleteModal,
                show: false
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {`Are you sure you want to delete this vehicle?`}
          </div>
          <div className="modal-footer justify-content-end">

            <ButtonContainer

              onClick={() => setDeleteModal({
                ...deleteModal,
                show: false
              })}
            >
              {`Close`}
            </ButtonContainer>
            <ButtonContainer
              reverse
              onClick={deleteModal.onClick}
            >
              {`Delete`}
            </ButtonContainer>
          </div>
        </Modal>
      </Container>
    </>
  )
}