import React from 'react';

export default function Shortcodes({ items }) {
    const showCategories = () => {
        if (!items || Object.keys(items).length === 0) {
            return null;
        }

        return Object.keys(items).map((key) => {
            let name = key[0].toUpperCase() + key.slice(1);
            return (
                <div>
                    <h2>{name}</h2>
                    {
                        items[key].map((item, index) => {
                            return (<span key={index}>
                                    {item},
                                </span>
                            )
                        })
                    }
                </div>
            )
        });
    }

    return (
        <>
            {items && showCategories()}
        </>
    )
}